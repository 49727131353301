import { Checkbox } from '@motion/ui/forms'
import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

export const ConnectedShowPastDeadlineButton = () => {
  const [filter, setFilter] = useActiveFilter()

  if (filter.target !== 'tasks') return null

  const toggle = (checked: boolean) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_SHOW_PAST_DEADLINE', {
      checked,
    })
    setFilter((prev) => ({
      ...prev,
      tasks: {
        ...prev.tasks,
        filters: {
          ...prev.tasks.filters,
          scheduledStatus: checked
            ? { operator: 'in', value: ['PAST_DUE', 'UNFIT_PAST_DUE'] }
            : null,
        },
      },
    }))
  }

  const isChecked = Boolean(
    filter.tasks.filters.scheduledStatus?.value.includes('PAST_DUE') ||
      filter.tasks.filters.scheduledStatus?.value.includes('UNFIT_PAST_DUE')
  )

  return (
    <Checkbox
      checked={isChecked}
      onChange={toggle}
      label='Only show scheduled past deadline'
      size='small'
    />
  )
}
