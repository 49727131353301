import { type CalendarList } from '@motion/rpc/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { errorInDev } from '@motion/web-base/logging'
import { stats } from '@motion/web-common/performance'
import { type TaskSchema } from '@motion/zod/client'

import {
  type Action,
  type AnyAction,
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import { showErrorToast } from '~/global/toasts'

import { type AppDispatch, type RootState, store } from './proxy'

interface EmbedModalType {
  visible: boolean
  link?: string
}

interface NewCalendarAccountType {
  email: string
  calendarList?: CalendarList
}

// TODO: Make more strongly typed result key, should be inferred from the type.
type Prompt = 'phoneNumber' | 'customLocation' | 'customRecurringRule'
type PromptsType = Record<
  Prompt,
  { visible: boolean; result?: string | null; inputs?: any }
>

type ConfirmationModalState = 'open' | 'confirmed' | 'closed'

export interface ModalsState {
  bookingContainerVisible: boolean
  calendarEmailGuestsVisible: boolean
  calendarTurnIntoFixedTimeTaskState: ConfirmationModalState
  calendarUpdateRecurringEventVisible: boolean
  embedModal: EmbedModalType
  scheduledAfterDeadlineVisible: boolean
  newCalendarAccount: {
    visible: boolean
    calendarAccount?: NewCalendarAccountType
  }
  prompts: Partial<PromptsType>
}

export const initialModalsState: ModalsState = {
  bookingContainerVisible: false,
  calendarEmailGuestsVisible: false,
  calendarTurnIntoFixedTimeTaskState: 'closed',
  calendarUpdateRecurringEventVisible: false,
  embedModal: {
    link: '',
    visible: false,
  },
  newCalendarAccount: {
    visible: false,
  },
  prompts: {},
  scheduledAfterDeadlineVisible: false,
}

interface RejectedAction extends Action {
  error: Error
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected')
}

export const modalsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(isRejectedAction, (state, action) => {
      const message = action.error.message
      if (
        message &&
        !message.startsWith("Can't find variable") &&
        // Abort controller rejects promise intentionally with 'Aborted' message
        // This is to provent async clashes, its not something that the user needs to know about
        !message.startsWith('Aborted') &&
        // Don't show a notification for throttler errors, the user does not need to know about them.
        !message.startsWith('ThrottlerException:')
      ) {
        stats.increment('errors.fetch', 1)

        errorInDev(action.error)
        recordAnalyticsEvent('ERROR_CAPTURED_EXCEPTION', {
          error: action.error,
        })

        showErrorToast(action.error)
      }
    })
  },
  initialState: initialModalsState,
  name: 'modals',
  reducers: {
    rejectPrompt: (state: ModalsState, action: PayloadAction<Prompt>) => {
      state.prompts[action.payload] = {
        result: null,
        visible: false,
      }
    },
    reset: () => initialModalsState,
    resolvePrompt: (
      state: ModalsState,
      action: PayloadAction<{
        type: Prompt
        result: string | null
      }>
    ) => {
      state.prompts[action.payload.type] = {
        result: action.payload.result,
        visible: false,
      }
    },
    setBookingContainerVisible: (
      state: ModalsState,
      action: PayloadAction<boolean>
    ) => {
      state.bookingContainerVisible = action.payload
    },
    setCalendarTurnIntoFixedTimeTaskState: (
      state: ModalsState,
      action: PayloadAction<ConfirmationModalState>
    ) => {
      state.calendarTurnIntoFixedTimeTaskState = action.payload
    },
    setEmbedModal: (
      state: ModalsState,
      action: PayloadAction<EmbedModalType>
    ) => {
      state.embedModal = action.payload
    },
    // Never call directly, use the showPromptModal thunk instead.
    setPrompt: (
      state: ModalsState,
      action: PayloadAction<{ type: Prompt; inputs?: any }>
    ) => {
      state.prompts[action.payload.type] = {
        inputs: action.payload.inputs,
        visible: true,
      }
    },
    setScheduledAfterDeadlineVisible: (
      state: ModalsState,
      action: PayloadAction<boolean>
    ) => {
      state.scheduledAfterDeadlineVisible = action.payload
    },
  },
})

export const {
  setBookingContainerVisible,
  setScheduledAfterDeadlineVisible,
  setCalendarTurnIntoFixedTimeTaskState,
  rejectPrompt,
  reset,
  resolvePrompt,
} = modalsSlice.actions

export const selectScheduledAfterDeadlineVisible = (state: RootState) =>
  state.modals.scheduledAfterDeadlineVisible
export const selectCalendarTurnIntoFixedTimeTaskState = (state: RootState) =>
  state.modals.calendarTurnIntoFixedTimeTaskState
export const selectPrompt = (state: RootState, type: Prompt) =>
  state.modals.prompts[type]
export const modalsReducer = modalsSlice.reducer

/**
 * Dispatch and action and listen to changes in the store so that we can
 * await the user to confirm the action. The motivation was to avoid storing
 * non serializable state (functions) in the store
 */
export const confirmCalendarTurnIntoFixedTimeTask = createAsyncThunk<
  Promise<boolean>,
  { task: TaskSchema },
  { state: RootState; dispatch: AppDispatch }
>(`modals/confirmCalendarTurnIntoFixedTimeTask`, async ({ task }, thunkAPI) => {
  if (task.isFixedTimeTask || task.completedTime) {
    return new Promise<boolean>((resolve) => resolve(true))
  }
  thunkAPI.dispatch(setCalendarTurnIntoFixedTimeTaskState('open'))
  return new Promise<boolean>((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state: RootState = thunkAPI.getState()
      if (state.modals.calendarTurnIntoFixedTimeTaskState === 'confirmed') {
        // Important to remove the listener
        unsubscribe()
        resolve(true)
      }
      if (state.modals.calendarTurnIntoFixedTimeTaskState === 'closed') {
        // Important to remove the listener
        unsubscribe()
        resolve(false)
      }
    })
  })
})

/**
 * Generic prompt action. It can be used for any modal that acts like a prompt and has to return
 * the user's choice asynchronously.
 *
 * If the result == null, it means the prompt was cancelled.
 *
 * Always use the resolvePrompt action to close the modal, never set prompt.visible to false directly.
 */
export const showPromptModal = createAsyncThunk<
  Promise<unknown | null>,
  { type: Prompt; inputs?: any },
  { state: RootState; dispatch: AppDispatch }
>(`modals/showPromptModal`, async ({ type, inputs }, thunkAPI) => {
  thunkAPI.dispatch(modalsSlice.actions.setPrompt({ inputs, type }))
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state: RootState = thunkAPI.getState()
      if (state.modals.prompts[type]?.visible === false) {
        // Important to remove the listener
        unsubscribe()
        resolve(state.modals.prompts[type]?.result)
      }
    })
  })
})
