import { useNavigateByRouteId } from '~/routing'

import { PageSelector } from '../header/page-selector'
import { useViewState } from '../view-state'

export const ConnectedPageSelector = () => {
  const [viewState] = useViewState()

  const navigate = useNavigateByRouteId()

  return (
    <PageSelector
      value={viewState.page}
      onChange={(value) => {
        if (value === 'gantt') {
          // Only allow project groupings in Gantt
          navigate('parent', { type: 'projects', variant: value })
          return
        }

        navigate('parent', { variant: value })
      }}
    />
  )
}
