import { EyeSolid } from '@motion/icons'
import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { type CalendarEvent } from '../../../../../../types/CalendarEvent'
import { useEventFormOptions } from '../event-form-options-context'
import { useEventFormVisibilityField } from '../hooks'

const visibilityTypeMap: Record<CalendarEvent['visibility'], string> = {
  CONFIDENTIAL: 'Confidential',
  DEFAULT: 'Default visibility',
  PRIVATE: 'Private',
  PUBLIC: 'Public',
}

const options = Object.entries(visibilityTypeMap).map(([type, text]) => ({
  label: text,
  value: type,
}))

export const EventFormVisibilityField = () => {
  const { isReadOnly, hostCalendar } = useEventFormOptions()
  const { field, onChange } = useEventFormVisibilityField()
  if (hostCalendar.providerType === 'APPLE') {
    return null
  }

  return (
    <EventFormRowContainer className='px-2 mb-2'>
      <EventFormRowIconContainer>
        <EyeSolid />
      </EventFormRowIconContainer>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            items={options}
            renderItem={(item) => item.label}
            computeKey={(item) => item.label}
            computeSelected={(item) => item.value === field.value}
            onSelect={(item) => {
              onChange(item.value)
              close()
            }}
          />
        )}
      >
        <FieldButton
          size='small'
          variant='muted'
          fullWidth
          readOnly={isReadOnly}
        >
          {visibilityTypeMap[field.value]}
        </FieldButton>
      </PopoverTrigger>
    </EventFormRowContainer>
  )
}
