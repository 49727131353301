import { type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskPriorityUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      priorityLevel: TaskSchema['priorityLevel']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the deadline of a Chunk task')
      }
      if (task.type === 'RECURRING_INSTANCE') {
        throw new Error('Cannot update the priority of a recurring instance')
      }

      await updateTask(task, { priorityLevel })
    },
    [readTask, updateTask]
  )
}
