import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCallback } from 'react'

import { type ActionArgs } from './types'
import { useBulkUpdateRows } from './use-bulk-update-rows'

// Given multiple task IDs
export const useDeleteTasks = (args: ActionArgs) => {
  const { type, ...rest } = args

  const bulkUpdateRows = useBulkUpdateRows()

  return useCallback(async () => {
    recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_DELETE', {
      type,
      numTasks: rest.tasks.length,
    })

    return bulkUpdateRows({
      ...rest,
      updates: {
        type: 'bulk-delete',
      },
    })
  }, [type, rest, bulkUpdateRows])
}
