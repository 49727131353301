import { EventFormAllDayRecurrenceAndTravelTimeField } from './event-form-allday-recurrence-traveltime-field'
import { EventFormConferencingField } from './event-form-conferencing-field'
import { EventFormDateTimeField } from './event-form-datetime-field'
import { EventFormDescriptionField } from './event-form-description-field'
import { EventFormFooter } from './event-form-footer'
import { EventFormGuestsField } from './event-form-guests-field'
import { EventFormHeader } from './event-form-header'
import { EventFormHostField } from './event-form-host-field'
import { EventFormLocationField } from './event-form-location-field'
import { EventFormNameField } from './event-form-name-field'
import { EventFormStatusField } from './event-form-status-field'
import { EventFormVisibilityField } from './event-form-visibility-field'

import { EventOrTaskToggle } from '../../common'
import { usePeekModalCommonContext } from '../../hooks'
import { useEventForm } from '../hooks'
import { useEventFormSubmit } from '../hooks/use-event-form-submit'

export const EventFormContent = () => {
  const { watch, getValues } = useEventForm()
  const { handleSubmit } = useEventFormSubmit()
  const { setSharedValues } = usePeekModalCommonContext()

  const id = watch('id')
  return (
    <form
      className='w-80 overflow-hidden rounded-[3px] flex flex-col'
      onSubmit={handleSubmit}
    >
      <EventFormHeader />
      <div className='overflow-y-auto overflow-hidden'>
        <EventFormNameField />
        {!id && (
          <EventOrTaskToggle
            onChange={() => {
              setSharedValues({ name: getValues('title') })
            }}
          />
        )}
        <EventFormDateTimeField />
        <EventFormAllDayRecurrenceAndTravelTimeField />
        <EventFormGuestsField />
        <EventFormDescriptionField />
        <EventFormConferencingField />
        <EventFormLocationField />
        <EventFormHostField />
        <EventFormStatusField />
        <EventFormVisibilityField />
      </div>
      <EventFormFooter />
    </form>
  )
}
