import { byProperty, Compare } from '@motion/utils/array'

import { type NormalTaskWithRelations } from '~/global/proxies'

export const sortByBlockers = (
  tasks: NormalTaskWithRelations[],
  getIsTaskBlocked: (task: NormalTaskWithRelations) => boolean
): NormalTaskWithRelations[] => {
  const visitedNodes = new Set<string | undefined>()
  const taskMap = new Map<string, NormalTaskWithRelations>(
    tasks.map((task) => [task.id, task])
  )

  tasks.sort(byProperty('createdTime', Compare.string.desc))

  const sortedTasks = tasks.reduce<NormalTaskWithRelations[]>((acc, task) => {
    if (!getIsTaskBlocked(task)) {
      visitedNodes.add(task.id)
      return [...acc, task]
    }
    return acc
  }, [])

  const dfs = (task: NormalTaskWithRelations) => {
    visitedNodes.add(task.id)
    task.blockedByTaskIds.forEach((id) => {
      const blockedByTask = taskMap.get(id)
      if (blockedByTask == null) {
        return
      }

      const task = taskMap.get(blockedByTask.id)

      if (task && !visitedNodes.has(task.id)) {
        dfs(task)
      }
    })
    sortedTasks.push(task)
  }

  tasks.forEach((task) => {
    if (!visitedNodes.has(task.id)) {
      dfs(task)
    }
  })

  return sortedTasks
}
