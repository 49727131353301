import { type NormalTaskSchema } from '@motion/zod/client'

import { SidebarTaskLine, type SidebarTaskLineProps } from './sidebar-task-line'

export type SidebarTaskListProps = {
  tasks: NormalTaskSchema[]
  onRemove?: (blocker: NormalTaskSchema) => void
  size?: SidebarTaskLineProps['size']
}

export function SidebarTaskList({
  tasks,
  onRemove,
  size,
}: SidebarTaskListProps) {
  if (tasks.length === 0) {
    return null
  }

  return (
    <ul className='flex flex-col whitespace-nowrap'>
      {tasks.map((task) => (
        <li key={task.id} className='flex flex-row items-center'>
          <SidebarTaskLine task={task} onRemove={onRemove} size={size} />
        </li>
      ))}
    </ul>
  )
}
