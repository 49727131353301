import { classed } from '@motion/theme'

import {
  SharedGridShell,
  SidebarShellStyles,
} from '~/areas/task-project/components/styled'

export const GridShell = classed('div', SharedGridShell, {
  base: '',
  variants: {
    layout: {
      archived: `grid-rows-[min-content_min-content_1fr_min-content]`,
      normal: `grid-rows-[min-content_1fr_min-content]`,
    },
    withFooter: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      layout: 'normal',
      withFooter: false,
      className: `[grid-template-areas:'header_aside''main_aside''mainfooter_aside']`,
    },
    {
      layout: 'normal',
      withFooter: true,
      className: `[grid-template-areas:'header_aside''main_aside''mainfooter_asidefooter']`,
    },
    {
      layout: 'archived',
      withFooter: false,
      className: `[grid-template-areas:'banner_banner''header_aside''main_aside''mainfooter_aside']`,
    },
    {
      layout: 'archived',
      withFooter: true,
      className: `[grid-template-areas:'banner_banner''header_aside''main_aside''mainfooter_asidefooter']`,
    },
  ],
  defaultVariants: {
    layout: 'normal',
  },
})

const MainContentPaddings = classed('div', {
  base: `
    px-6
    modal-lg:px-10
    `,
})

export const MainHeader = classed(MainContentPaddings, {
  base: `
    [grid-area:header]
    py-4
    flex flex-col gap-2
    modal-lg:pt-6
  `,
})

export const MainScrollableContent = classed(MainContentPaddings, {
  base: `
    [grid-area:main]
    overflow-auto
    flex flex-col gap-3
    modal-lg:pt-0 modal-lg:pb-6
  `,
})

export const ShellMainFooter = classed('footer', {
  base: `
    py-3
    px-6
    modal-lg:py-4
    modal-lg:px-10
    flex items-center
  `,
})

export const SidebarFooter = classed('div', SidebarShellStyles, {
  base: `
    [grid-area:asidefooter]
    flex gap-3 items-center justify-end
    rounded-none rounded-br-lg

    p-3
    modal-lg:px-5
    `,
})

export const SidebarRecurringTaskBanner = classed('div', {
  base: `
    px-4 py-2 flex justify-between items-center bg-semantic-neutral-surface-raised-bg-subtle
    modal-lg:px-5
  `,
})

export const RecurringTaskBannerText = classed('span', {
  base: `
    text-2xs text-semantic-neutral-text-subtle font-medium
  `,
})

export const GridBanner = classed(
  'div',
  '[grid-area:banner] rounded-t-lg overflow-hidden'
)

export const SubItemIndentMarker = classed(
  'div',
  'w-2 h-4 ml-2',
  'self-start',
  'shrink-0',
  'border-l border-l-semantic-neutral-border-strong',
  'border-b border-b-semantic-neutral-border-strong',
  'rounded-bl-sm',
  'pointer-events-none'
)
