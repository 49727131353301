import { Button, PopoverTrigger } from '@motion/ui/base'
import { CalendarColorIcon, CalendarColorPicker } from '@motion/ui/calendar'
import { colorClassIdToColorId, type ColorId } from '@motion/ui-logic/calendar'

import { useEventColorId } from '../hooks'

type EventFormColorPickerProps = {
  enabledColors: ColorId[]
}

export const EventFormColorPicker = ({
  enabledColors,
}: EventFormColorPickerProps) => {
  const { value, onChange } = useEventColorId()
  if (enabledColors.length === 0) {
    // don't render if there's no colors
    return null
  }

  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={({ close }) => (
        <div className='p-3'>
          <CalendarColorPicker
            options={enabledColors}
            value={value}
            onSelect={(colorClass) => {
              const colorId = colorClassIdToColorId[colorClass]
              onChange(colorId)
              close()
            }}
          />
        </div>
      )}
    >
      <Button size='small' variant='muted' sentiment='neutral' iconOnly>
        <CalendarColorIcon colorId={value} size='small' />
      </Button>
    </PopoverTrigger>
  )
}
