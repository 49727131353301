import { PencilSolid, TrashSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { useParams } from 'react-router-dom'

import { useDeleteCustomField } from '../../hooks/custom-fields'

type CustomFieldRowProps = {
  field: WorkspaceCustomField
}

export const CustomFieldRow = ({ field }: CustomFieldRowProps) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const modalApi = useModalApi()
  const deleteField = useDeleteCustomField()

  if (!workspaceId) {
    throw new Error('No workspaceId')
  }

  return (
    <div className='flex flex-1 justify-between items-center'>
      <div className='flex gap-2 items-center'>
        <CustomFieldBadge type={field.type} />
        {field.name}
      </div>

      <div className='flex gap-3 items-center'>
        <Button
          iconOnly
          variant='muted'
          sentiment='neutral'
          size='small'
          onClick={() =>
            modalApi.open('edit-custom-field', {
              workspaceId,
              customField: field,
            })
          }
        >
          <PencilSolid className='w-4 h-4' />
        </Button>
        <Button
          iconOnly
          variant='muted'
          sentiment='neutral'
          size='small'
          onClick={() =>
            deleteField({
              workspaceId,
              customField: field,
            })
          }
        >
          <TrashSolid className='w-4 h-4' />
        </Button>
      </div>
    </div>
  )
}
