import { type CalendarOptions, type DateSelectArg } from '@fullcalendar/core'
import { useCalendarCommonProps } from '~/areas/calendar/hooks/use-calendar-common-props'
import { useCalendarFcEvents } from '~/areas/calendar/main-calendar/hooks'
import { AvailabilityEvent } from '~/components/Calendar/components/Main/CalendarEvents/AvailabilityEvent'
import { CalendarEventContainer } from '~/components/Calendar/components/Main/CalendarEvents/CalendarEventContainer'
import { selectMainCalendar } from '~/state/calendar-list/calendar-list-slice'
import {
  addAvailabilityEvent,
  calendarAvailabilitiesSelectors,
} from '~/state/calendarEvents/calendarEventsSlice'
import { parseFullCalendarEventId } from '~/state/calendarEvents/calendarEventsUtils'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { useCallback, useMemo } from 'react'

export const useTimeslotMainCalendarProps = (): CalendarOptions => {
  const commonProps = useCalendarCommonProps()
  const calendarEvents = useCalendarFcEvents()

  const dispatch = useAppDispatch()
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const mainCalendar = useAppSelector(selectMainCalendar)
  const availabilityEvents = useAppSelector((state) =>
    calendarAvailabilitiesSelectors.selectAll(state)
  )

  /**
   * FullCalendar event that fires when a user selects a (free) time block on
   * the calendar. This also fires after the user click-drags an event.
   */
  const onSelectDate = useCallback(
    (arg: DateSelectArg) => {
      const api = arg.view.calendar
      if (!api || !mainEmailAccount || !mainCalendar) return

      // remove all events in case a main calendar event is already created.
      api.removeAllEvents()
      // Remove selected time chunk (as we'll insert a new event that we can
      // control the rendering of)
      api.unselect()

      dispatch(
        addAvailabilityEvent({
          calendarId: mainCalendar.providerId,
          email: mainEmailAccount.email,
          event: {
            end: arg.endStr,
            resourceId: 'mine',
            start: arg.startStr,
          },
        })
      )
    },
    [dispatch, mainEmailAccount, mainCalendar]
  )

  const events = useMemo(() => {
    return [...availabilityEvents, ...calendarEvents]
  }, [availabilityEvents, calendarEvents])

  return useMemo(() => {
    return {
      ...commonProps,
      select: onSelectDate,
      events,
      eventContent: (event) => {
        const { type } = parseFullCalendarEventId(event.event.id)

        if (type === 'new_event') {
          return <AvailabilityEvent event={event} />
        }

        return <CalendarEventContainer {...event} />
      },
    }
  }, [commonProps, onSelectDate, events])
}
