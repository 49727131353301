import { type DateRangeColorData } from '@motion/ui/base'
import {
  getEnabledStagesWithDates,
  type StageArg,
} from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import { StageBadge } from '~/global/components/badges'
import { StageLabel } from '~/global/components/labels/stage'
import { useStageDefinitionsByProjectDefinitionId } from '~/global/hooks'
import { DateTime } from 'luxon'
import { type ReactNode } from 'react'

export type StageDateRangeColorData = DateRangeColorData<{
  endDateTooltipContent?: ReactNode | string
  endDateBadgeIcon?: ReactNode
}>

export type ProjectDatesContextData = {
  stageDateRangeColors: StageDateRangeColorData[]
}

type ProjectInput = Pick<
  ProjectSchema,
  'startDate' | 'dueDate' | 'projectDefinitionId'
> & { stages: StageArg[] }

export function useProjectDatesContextData(
  project: ProjectInput | null | undefined
): ProjectDatesContextData {
  const stagesDefinitions = useStageDefinitionsByProjectDefinitionId(
    project?.projectDefinitionId
  )

  if (project?.startDate == null || project?.dueDate == null) {
    return {
      stageDateRangeColors: [],
    }
  }

  const stageDataWithDates = getEnabledStagesWithDates(project.stages, {
    start: project.startDate,
    due: project.dueDate,
  })

  const stageDateRangeColors: StageDateRangeColorData[] = []
  for (const stageData of stageDataWithDates) {
    const stageDefinition = stagesDefinitions.find(
      (sd) => sd.id === stageData.stage.stageDefinitionId
    )

    if (stageDefinition?.color != null) {
      stageDateRangeColors.push({
        startDate: DateTime.fromISO(stageData.start),
        endDate: DateTime.fromISO(stageData.due),
        color: stageDefinition.color,
        metadata: {
          endDateTooltipContent: (
            <span className='flex flex-row gap-1 items-center'>
              <StageLabel
                value={{
                  name: stageDefinition.name,
                  color: stageDefinition.color,
                }}
                size='small'
              />
              stage deadline
            </span>
          ),
          endDateBadgeIcon: (
            <StageBadge value={stageDefinition} hideTooltip size='xxxsmall' />
          ),
        },
      })
    }
  }

  if (stageDateRangeColors.length === 0) {
    // If there are no stages, send a single date range color for the project with no color
    stageDateRangeColors.push({
      startDate: DateTime.fromISO(project.startDate),
      endDate: DateTime.fromISO(project.dueDate),
    })
  }

  return {
    stageDateRangeColors,
  }
}
