import {
  BellSolid,
  CalendarSolid,
  CheckCircleSolid,
  ClockSolid,
  CodeSolid,
  CreditCardSolid,
  DesktopComputerSolid,
  ExclamationCircleSolid,
  EyeSolid,
  FingerPrintOutline,
  GlobeSolid,
  LightningBoltSolid,
  LinkSolid,
  PlusSolid,
  TaskSolid,
  UserSolid,
  UsersSolid,
  VideoCameraSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { makeTeamResubscribeCtaStr } from '@motion/ui-logic/billing'
import { HasExperiment } from '@motion/web-common/flags'

import { useIsBookingOnboarded } from '~/areas/calendar/booking/hooks'
import { WorkspaceBadge } from '~/global/components/badges'
import {
  useIsAdmin,
  useIsEnterpriseSubscription,
  useOrderedWorkspaceProjectList,
} from '~/global/hooks'
import { useActiveMemberCount, useIsTeamExpired } from '~/global/hooks/team'
import { type SettingPageOptions } from '~/pages/settings/types'
import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'

import { SettingsSidebarButton } from './sidebar-button'
import { SettingsSidebarItem } from './sidebar-item'

import { useInNoExternalCalendarsMode } from '../../../hooks/use-in-no-external-calendars-mode'

export interface SettingsSidebarItemsProps {
  settingsPage: SettingPageOptions
  selectedWorkspaceId: string | undefined
  onCreateWorkspace: () => void
}

export const SettingsSidebarItems = ({
  settingsPage,
  selectedWorkspaceId,
  onCreateWorkspace,
}: SettingsSidebarItemsProps) => {
  const { data: workspaces } = useOrderedWorkspaceProjectList()
  const team = useAppSelector(selectTeam)
  const isTeamExpired = useIsTeamExpired()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const isBookingOnboarded = useIsBookingOnboarded()
  const isAdmin = useIsAdmin()
  const isValidTeam = !!team?.id && (!isTeamExpired || isAdmin)
  const activeMemberCount = useActiveMemberCount()
  const { isEnterprise, isLoading: isEnterpriseLoading } =
    useIsEnterpriseSubscription()

  const showBookingSettings = !noExternalCalendarsMode && isBookingOnboarded

  return (
    <div className='flex flex-col justify-between flex-grow overflow-y-auto overflow-x-hidden'>
      <div className='flex flex-col gap-6 px-2 py-3'>
        <SettingsSidebarGroup>
          <SettingsSidebarHeading>General</SettingsSidebarHeading>
          <SettingsItems>
            <SettingsSidebarItem
              title='Calendars'
              icon={CalendarSolid}
              isSelected={settingsPage === 'calendar'}
              to='calendar'
            />
            <SettingsSidebarItem
              title='Auto-scheduling'
              icon={CheckCircleSolid}
              isSelected={settingsPage === 'task'}
              to='task'
            />
            <HasExperiment name='task-defaults'>
              <SettingsSidebarItem
                title='Task defaults'
                icon={TaskSolid}
                isSelected={settingsPage === 'task-defaults'}
                to='task-defaults'
              />
            </HasExperiment>
            <SettingsSidebarItem
              title='Theme'
              icon={EyeSolid}
              isSelected={settingsPage === 'view'}
              to='view'
            />
            <SettingsSidebarItem
              title='Conference settings'
              icon={VideoCameraSolid}
              isSelected={settingsPage === 'conference'}
              to='conference'
            />
            <SettingsSidebarItem
              title='Timezone'
              icon={GlobeSolid}
              isSelected={settingsPage === 'timezone'}
              to='timezone'
            />
            <SettingsSidebarItem
              title='Notifications'
              icon={BellSolid}
              isSelected={settingsPage === 'notifications'}
              to='notifications'
            />
            <SettingsSidebarItem
              title='Schedules'
              icon={ClockSolid}
              isSelected={settingsPage === 'schedule'}
              to='schedule'
            />
            <SettingsSidebarItem
              title='Desktop app'
              icon={DesktopComputerSolid}
              isSelected={settingsPage === 'desktop'}
              to='desktop'
            />
            <SettingsSidebarItem
              title='Integrations'
              icon={LightningBoltSolid}
              isSelected={settingsPage === 'integration'}
              to='integration'
            />
            <SettingsSidebarItem
              title='API'
              icon={CodeSolid}
              to='api'
              isSelected={settingsPage === 'api'}
            />
            <SettingsSidebarItem
              title='Privacy'
              icon={FingerPrintOutline}
              to='privacy'
              isSelected={settingsPage === 'privacy'}
            />
            {showBookingSettings && (
              <SettingsSidebarItem
                title='Booking'
                icon={LinkSolid}
                isSelected={
                  settingsPage === 'scheduler' || settingsPage === 'booking'
                }
                to='booking'
              />
            )}
          </SettingsItems>
        </SettingsSidebarGroup>
        <SettingsSidebarGroup>
          <SettingsSidebarHeading>Account</SettingsSidebarHeading>
          <SettingsItems>
            <SettingsSidebarItem
              title='Account settings'
              icon={UserSolid}
              isSelected={settingsPage === 'account'}
              to='account'
            />
            {!isEnterpriseLoading && !isEnterprise && (
              <SettingsSidebarItem
                title='Billing'
                icon={CreditCardSolid}
                isSelected={settingsPage === 'billing'}
                to='billing'
                dataTestId='settings-sidebar-billing'
              />
            )}
          </SettingsItems>
        </SettingsSidebarGroup>
        <SettingsSidebarGroup>
          <SettingsSidebarHeading>Team</SettingsSidebarHeading>
          <SettingsItems>
            {isValidTeam ? (
              <SettingsSidebarItem
                title={
                  <span className='flex flex-row items-center gap-2'>
                    Team settings
                    {isTeamExpired && (
                      <Tooltip
                        content={makeTeamResubscribeCtaStr(
                          (activeMemberCount ?? 1) - 1
                        )}
                      >
                        <ExclamationCircleSolid className='h-4 w-4 text-semantic-error-icon-default' />
                      </Tooltip>
                    )}
                  </span>
                }
                icon={UsersSolid}
                isSelected={
                  settingsPage === 'team/members' || settingsPage === 'team'
                }
                to='team'
              />
            ) : (
              <SettingsSidebarItem
                title='Create Team'
                icon={UsersSolid}
                isSelected={settingsPage === 'team/create'}
                to='team/create'
              />
            )}
          </SettingsItems>
        </SettingsSidebarGroup>
        <SettingsSidebarGroup>
          <SettingsSidebarHeading>Workspaces</SettingsSidebarHeading>
          <SettingsItems>
            {workspaces.map(({ item: workspace }) => {
              return (
                <SettingsSidebarItem
                  key={workspace.id}
                  title={workspace.name}
                  icon={WorkspaceBadge}
                  isSelected={
                    settingsPage === 'workspace' &&
                    selectedWorkspaceId === workspace.id
                  }
                  to={`workspace/${workspace.id}/overview`}
                />
              )
            })}

            <SettingsSidebarButton
              onClick={() => onCreateWorkspace()}
              icon={PlusSolid}
              title='Add workspace'
            />
          </SettingsItems>
        </SettingsSidebarGroup>
      </div>
    </div>
  )
}

const SettingsSidebarHeading = classed('div', {
  base: 'text-semantic-neutral-text-disabled ml-2 text-xs font-medium',
})

const SettingsSidebarGroup = classed('div', {
  base: 'flex flex-col gap-2',
})

const SettingsItems = classed('div', {
  base: 'flex flex-col gap-1.5',
})
