import { RefreshSolid } from '@motion/icons'
import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  getDefaultCustomRecurrenceString,
  getRecurrenceRulesForDate,
  recurrenceRuleToText,
} from '@motion/ui-logic'

import { CustomRecurrencePanel } from '~/areas/event/modals/event-modal/components'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useCalendarStartDay } from '../../../../hooks'
import { useEventFormRecurrenceField } from '../hooks'

export const EventFormRecurrenceField = () => {
  const {
    field,
    onChange,
    formValues: { start },
  } = useEventFormRecurrenceField()
  const calendarStartDay = useCalendarStartDay()

  const { value } = field
  const recurrenceRules = getRecurrenceRulesForDate(start)

  const defaultRecurrenceOptions = recurrenceRules.map((rule) => {
    return {
      value: rule.toString(),
      label: recurrenceRuleToText(rule.toString()),
    }
  })

  const isCustomOptionSelected =
    Boolean(value) && !defaultRecurrenceOptions.some((o) => o.value === value)

  const recurrenceOptions = [
    { label: 'Does not repeat', value: '' },
    ...defaultRecurrenceOptions,
    { label: 'Custom', value: 'custom' },
  ]

  const defaultCustomRecurrence = getDefaultCustomRecurrenceString(start)

  return (
    <EventFormRowContainer className='mb-1'>
      <EventFormRowIconContainer>
        <RefreshSolid />
      </EventFormRowIconContainer>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <div className='flex'>
            <SearchableList
              items={recurrenceOptions}
              renderItem={(item) => item.label}
              computeKey={(item) => item.label}
              computeSelected={(item) =>
                item.value === value ||
                (item.value === 'custom' && isCustomOptionSelected)
              }
              onSelect={(item) => {
                if (item.value === 'custom') {
                  if (!isCustomOptionSelected) {
                    onChange(defaultCustomRecurrence)
                  }
                  return
                }
                onChange(item.value)
                close()
              }}
            />
            {isCustomOptionSelected && (
              <CustomRecurrencePanel
                refDate={start}
                initialValue={value}
                calendarStartDay={calendarStartDay}
                onChange={onChange}
              />
            )}
          </div>
        )}
      >
        <FieldButton size='small' variant='muted' fullWidth>
          {recurrenceRuleToText(value) || 'Does not repeat'}
        </FieldButton>
      </PopoverTrigger>
    </EventFormRowContainer>
  )
}
