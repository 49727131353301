import type {
  FolderFolderItemSchema,
  FolderWithItemsSchema,
  RecursiveFolderItemSchema,
} from '@motion/zod/client'

import { useFolders } from '~/global/rpc/folders'
import { useCallback } from 'react'

type UseFindFolderItemSearchItem =
  | FolderWithItemsSchema
  | RecursiveFolderItemSchema

type FindFolderItemCallback = (item: RecursiveFolderItemSchema) => boolean

type UseFindFolderItemReturn = [
  RecursiveFolderItemSchema,
  ...FolderFolderItemSchema[],
]

export const useFindFolderItem = () => {
  const { data: folders } = useFolders()

  return useCallback<
    (cb: FindFolderItemCallback) => UseFindFolderItemReturn | null
  >(
    (cb) => {
      const search = (
        item: UseFindFolderItemSearchItem,
        tree: UseFindFolderItemSearchItem[] = []
      ): UseFindFolderItemReturn | null => {
        const newTree = [item, ...tree] as UseFindFolderItemReturn

        if ('items' in item) {
          for (const child of item.items) {
            if (cb(child)) {
              return [child, ...newTree] as UseFindFolderItemReturn
            }

            const result = search(child, newTree)

            if (result) {
              return result
            }
          }
        }

        return null
      }

      if (!folders?.models.systemFolders.workspaces) return null

      return search(folders.models.systemFolders.workspaces)
    },
    [folders]
  )
}
