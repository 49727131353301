import {
  type BookingConflictCalendar,
  type BookingLinkTemplate,
  type Calendar,
  type EmailAccount,
} from '@motion/rpc/types'

import { DateTime } from 'luxon'

import { startsInMap, startsInMapReverse } from './template-form.types'

import { type User } from '../../../../state/userSlice'
import { defaultBookingTemplate } from '../../../../storageConstants'
import { type ConflictCalendar } from '../../../../types/bookingTypes'

export const createDaysString = (dayNum: number) => {
  if (dayNum === 1) {
    return '1 day'
  } else if (dayNum < 30) {
    return `${dayNum} days`
  } else if (dayNum === 30) {
    return '1 month'
  } else if (dayNum === 365) {
    return '1 year'
  }
  return `${dayNum / 30} months`
}

export const parseDaysString = (dayString: string) => {
  const [numString, unit] = dayString.split(' ')
  const num = parseInt(numString)
  const unitDays = unit.includes('month') ? 30 : unit.includes('year') ? 365 : 1
  return num * unitDays
}

export const createStartsInString = (startsInInput: string) => {
  return startsInMap[startsInInput] || 'Tomorrow'
}

export const parseStartsInString = (startsInString: string) => {
  return startsInMapReverse[startsInString] || 'tomorrow'
}

export const doesAllowMeetInCalendar = (calendar: Calendar): boolean => {
  return !!(calendar.allowedConferenceTypes ?? []).find(
    (t) => t === 'hangoutsMeet'
  )
}

export const calendarToConflictCalendar = (
  calendar: Calendar,
  emailAccount: EmailAccount
) => ({
  email: emailAccount.email,
  calendarId: calendar.providerId,
  inCalendarList:
    !calendar.isInFrequentlyMet && calendar.type !== 'FREQUENTLY_MET',
  title: calendar.title,
})

export const resolvePrimaryConflictCalendars = (
  calendars: Calendar[],
  emailAccountsMap: Map<string, EmailAccount>
) => {
  // Default to all primary calendars
  const conflictCalendars: ConflictCalendar[] = []

  for (const calendar of calendars) {
    if (!calendar.isPrimary) continue

    const emailAccount = emailAccountsMap.get(calendar.emailAccountId)
    if (!emailAccount) {
      continue
    }

    conflictCalendars.push(calendarToConflictCalendar(calendar, emailAccount))
  }

  return conflictCalendars
}

export const formatRange = (range: string) => {
  const [start, end] = range.split('-')
  return [normalizeTimeFormat(start), normalizeTimeFormat(end)].join('-')
}

export function parseTime(value: string): DateTime {
  try {
    return DateTime.fromFormat(value, 'h:mma')
  } catch (err) {}

  try {
    return DateTime.fromFormat(value, 'ha')
  } catch (err) {}

  return DateTime.fromISO(value)
}

export function normalizeTimeFormat(value: string) {
  try {
    return parseTime(value).toFormat('H:mm')
  } catch (err) {
    return value
  }
}

export const createDefaultTemplate = (
  hostEmailAccount: EmailAccount,
  hostCalendar: Calendar,
  user: User,
  calendars: Calendar[],
  emailAccountsMap: Map<string, EmailAccount>
) => {
  return {
    ...defaultBookingTemplate,
    conflictCalendars: resolvePrimaryConflictCalendars(
      calendars,
      emailAccountsMap
    ) as BookingConflictCalendar[],
    createdByUserId: user.id,
    hostDisplayName: user.displayName,
    hostEmail: hostEmailAccount.email,
    hostCalendarId: hostCalendar.providerId,
    order: 0,
    linkSlug: 'meeting',
  } as Partial<BookingLinkTemplate>
}
