import { useProjectUpdater } from '~/areas/project/hooks'
import { useCachedItem } from '~/global/cache'
import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'
import { BreadcrumbsLabel } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellAction } from './components'

type ProjectFolderCellProps = {
  item: ProjectWithRelations
}

export const ProjectFolderCell = ({ item }: ProjectFolderCellProps) => {
  const folder = item.folder
  const updateFolder = useProjectUpdater()
  const selectedId = folder
    ? folder.type === 'USER'
      ? folder.id
      : folder.targetId
    : item.workspaceId

  return (
    <WorkspacesTreeDropdown
      selectedId={selectedId}
      onChange={({ folderId }) => {
        updateFolder(item, { folderId })
      }}
      leafNodeType='FOLDER'
      computeDisabled={(workspaceId) => workspaceId !== item.workspaceId}
    >
      <CellAction>
        <BreadcrumbsLabel
          crumbs={[
            { type: 'workspace', value: folder?.targetId ?? item.workspaceId },
            { type: 'folder', value: folder?.id },
          ]}
        />
      </CellAction>
    </WorkspacesTreeDropdown>
  )
}

type TaskFolderCellProps = {
  item: TaskWithRelations
}

export const TaskFolderCell = (props: TaskFolderCellProps) => {
  const { item } = props
  const folder = useCachedItem('folders', item.project?.folderId)

  return (
    <BreadcrumbsLabel
      crumbs={[
        { type: 'workspace', value: folder?.targetId ?? item.workspaceId },
        { type: 'folder', value: folder?.id },
      ]}
    />
  )
}
