import { type CalendarSchema } from '@motion/zod/client'

import { useGetCalendars } from '~/global/rpc/v2'
import { useMemo } from 'react'

export function useActiveCalendars(): CalendarSchema[] {
  const { data: calendarsData } = useGetCalendars()

  return useMemo(() => {
    const calendars = calendarsData?.calendars ?? []

    return calendars.filter((c) => c.isEnabled || c.isInMyCalendars)
  }, [calendarsData?.calendars])
}
