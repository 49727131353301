import { useHasWorkspaceFoldersTreatment } from '~/areas/sidebar/hooks'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import { useCustomFieldBaseDefinitions } from './custom-fields'
import { PROJECT_FIELD_LIST } from './project-fields'
import { TASK_FIELD_LIST } from './task-fields'
import { type FieldDefinition } from './types'

export const useTaskFields = (): FieldDefinition<TaskWithRelations>[] => {
  const hasFolders = useHasWorkspaceFoldersTreatment()
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  return useMemo(() => {
    const filteredTaskFields = hasFolders
      ? TASK_FIELD_LIST
      : TASK_FIELD_LIST.filter((field) => field.id !== 'folder')
    return [...filteredTaskFields, ...customFieldDefinitions]
  }, [customFieldDefinitions, hasFolders])
}

export const useProjectFields = (): FieldDefinition<ProjectWithRelations>[] => {
  const hasFolders = useHasWorkspaceFoldersTreatment()
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  return useMemo(() => {
    const filteredProjectFields = hasFolders
      ? PROJECT_FIELD_LIST
      : PROJECT_FIELD_LIST.filter((field) => field.id !== 'folder')
    return [...filteredProjectFields, ...customFieldDefinitions]
  }, [customFieldDefinitions, hasFolders])
}
