import { type UploadedFileSchema } from '@motion/rpc-types'

import {
  createContext,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

export type ActiveFileUpload = {
  tempId: string
  targetId: UploadedFileSchema['targetId'] | null
  targetType: UploadedFileSchema['targetType'] | null
  workspaceId: UploadedFileSchema['workspaceId']
  fileName: string
  progress: number
}

export interface FileUploadValue {
  activeFileUploads: ActiveFileUpload[]
  registerActiveFileUpload: (activeFileUpload: ActiveFileUpload) => void
  unregisterActiveFileUpload: (tempId: string) => void
  trackActiveFileUploadProgress: (tempId: string, progress: number) => void
}

const defaultValue: FileUploadValue = {
  activeFileUploads: [],
  registerActiveFileUpload: () => {},
  unregisterActiveFileUpload: () => {},
  trackActiveFileUploadProgress: () => {},
}

export const FileUploadContext = createContext<FileUploadValue>(defaultValue)

type FileUploadProviderProps = {
  children: ReactNode
}

export function FileUploadProvider({ children }: FileUploadProviderProps) {
  const [activeFileUploads, setActiveFileUploads] = useState<
    ActiveFileUpload[]
  >([])

  const registerActiveFileUpload = useCallback(
    (activeFileUpload: ActiveFileUpload) => {
      setActiveFileUploads((prev) => [...prev, activeFileUpload])
    },
    [setActiveFileUploads]
  )

  const unregisterActiveFileUpload = useCallback(
    (tempId: string) => {
      setActiveFileUploads((prev) =>
        prev.filter((activeFileUpload) => activeFileUpload.tempId !== tempId)
      )
    },
    [setActiveFileUploads]
  )

  const trackActiveFileUploadProgress = useCallback(
    (tempId: string, progress: number) => {
      setActiveFileUploads((prev) =>
        prev.map((activeFileUpload) =>
          activeFileUpload.tempId === tempId
            ? {
                ...activeFileUpload,
                progress,
              }
            : activeFileUpload
        )
      )
    },
    [setActiveFileUploads]
  )

  const value = useMemo(
    () => ({
      activeFileUploads,
      registerActiveFileUpload,
      unregisterActiveFileUpload,
      trackActiveFileUploadProgress,
    }),
    [
      activeFileUploads,
      registerActiveFileUpload,
      unregisterActiveFileUpload,
      trackActiveFileUploadProgress,
    ]
  )

  return (
    <FileUploadContext.Provider value={value}>
      {children}
    </FileUploadContext.Provider>
  )
}
