import { API, type ApiTypes, useQueryOptionsFactory } from '@motion/rpc'
import { useStoreModelsFn } from '@motion/rpc-cache'
import { createLookupById } from '@motion/utils/object'

import { useQuery } from '@tanstack/react-query'

export type GetCalendarsApi = ApiTypes<typeof API.calendars.getCalendars>

export const useGetCalendars = (opts?: GetCalendarsApi['UseQueryOptions']) => {
  const storeModels = useStoreModelsFn()

  const queryOptionsOf = useQueryOptionsFactory(API.calendars.getCalendars)
  const queryArgs = queryOptionsOf(void 0, opts as any)

  return useQuery({
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    ...queryArgs,
    async queryFn(ctx) {
      const value = await queryArgs.queryFn(ctx as any)

      storeModels({
        calendars: createLookupById(value.calendars),
      })
      return value
    },
  })
}
