import { PlusSolid } from '@motion/icons'
import { IconButton, Shortcut, Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import {
  useCalendarState,
  useMyCalendars,
  useSendCalendarState,
} from '~/areas/calendar/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router'

import { NEW_EVENT_ID } from '../../utils'

export const CreateEventButton = () => {
  const hasEventModalEnabled = useHasTreatment('event-modal')
  const modalApi = useModalApi()
  const [{ selectedDate }] = useCalendarState()
  const myCalendars = useMyCalendars()
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const setCalendarState = useSendCalendarState()

  const handleCreateEvent = () => {
    recordAnalyticsEvent('CALENDAR_TOOLBAR_CREATE_EVENT_CLICK', {
      hasCalendars: myCalendars.length > 0,
    })

    if (hasEventModalEnabled) {
      if (myCalendars.length > 0) {
        modalApi.open('event-modal', {})
      } else {
        navigate(buildTaskModalUrl())
      }
      return
    }

    let dateToUse = selectedDate
    let now = DateTime.now()

    // If the date is in the past, use the current date
    if (dateToUse < now) {
      dateToUse = now
      void setCalendarState({
        selectedDate: dateToUse,
      })
    }

    dateToUse.set({ minute: now.minute, hour: now.hour })

    let start = dateToUse.plus({ minutes: 30 - (now.minute % 30) })
    // If not current date, just set it to 12:00
    if (!dateToUse.hasSame(now, 'day')) {
      start = dateToUse.set({ hour: 12, minute: 0 })
    }

    const end = start.plus({ minutes: 30 })

    void setCalendarState({
      selectedCalendarEvent: {
        id: NEW_EVENT_ID,
        new: {
          allDay: false,
          start: start.toISO(),
          end: end.toISO(),
        },
      },
    })
  }

  return (
    <Tooltip
      renderContent={() =>
        myCalendars.length
          ? templateStr('Create event {{shortcut}}', {
              shortcut: <Shortcut shortcut='e' />,
            })
          : 'Create task'
      }
      asChild
    >
      <IconButton
        variant='outlined'
        sentiment='neutral'
        size='small'
        icon={PlusSolid}
        onClick={handleCreateEvent}
      />
    </Tooltip>
  )
}
