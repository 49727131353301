import { type COLOR } from '@motion/shared/common'
import { Tooltip } from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'
import { type ProjectSchema } from '@motion/zod/client'

import { useProject } from '~/global/hooks'
import { type ComponentRef, forwardRef } from 'react'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type ProjectBadgeProps = {
  value: Pick<ProjectSchema, 'color' | 'name'> | null
  size?: BadgeSize
  hideTooltip?: boolean
}

export const ProjectBadge = forwardRef<
  ComponentRef<typeof ProjectColoredIcon>,
  ProjectBadgeProps
>(function ProjectBadge({ value, size, hideTooltip }, ref) {
  const pixels = getBadgeSizeInPixels(size)

  const colorOption = (value?.color ?? 'gray') as COLOR

  const icon = (
    <ProjectColoredIcon
      ref={ref}
      className='shrink-0'
      width={pixels}
      height={pixels}
      color={colorOption}
    />
  )

  if (hideTooltip) return icon

  return (
    <Tooltip asChild content={`Project: ${value?.name ?? 'None'}`}>
      {icon}
    </Tooltip>
  )
})

type ConnectedProjectBadgeProps = Omit<ProjectBadgeProps, 'value'> & {
  id: string | null
}
export const ConnectedProjectBadge = ({
  id,
  ...rest
}: ConnectedProjectBadgeProps) => {
  const project = useProject(id)
  return <ProjectBadge value={project} {...rest} />
}
