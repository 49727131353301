import { classed } from '@motion/theme'
import { type TaskSchema } from '@motion/zod/client'

import { ScheduledAfterDeadlineRow } from './scheduled-after-deadline-row'

const SectionContainer = classed('div', {
  base: `
    flex flex-col
  `,
  variants: {
    deadlineType: {
      hard: 'themevar-[highlight=semantic-error-text-default]',
      soft: 'themevar-[highlight=semantic-warning-text-default]',
    },
  },
})

const SectionTitle = classed('span', {
  base: `
    capitalize
    text-[var(--highlight)]
    font-bold text-sm
    pb-2
  `,
})

type SectionProps = {
  tasks: TaskSchema[]
  deadlineType: 'hard' | 'soft'
}

export const Section = (props: SectionProps) => {
  if (props.tasks.length === 0) return null

  const title = `Scheduled after ${props.deadlineType} deadline`

  return (
    <SectionContainer deadlineType={props.deadlineType}>
      <SectionTitle>{title}</SectionTitle>
      {props.tasks.map((task) => (
        <ScheduledAfterDeadlineRow key={task.id} task={task} />
      ))}
    </SectionContainer>
  )
}
