import { isCompletedStatus } from '@motion/shared/common'
import { type Group } from '@motion/ui/base'
import { defaultTaskSortFn } from '@motion/ui-logic/pm/data'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  groupBy,
  groupInto,
  ordered,
} from '@motion/utils/array'
import { createLookup, createLookupByKey } from '@motion/utils/object'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StatusSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useMemo } from 'react'

import { useIsTaskBlockedFn } from './use-is-task-blocked-fn'

import { useSidebarTaskContext } from '../components/side-panel/context'
import { type SortBy, sortByBlockers } from '../utils'

export const NO_GROUP_KEY = 'NO_GROUP'

type UseSortedGroupedTasksArgs<SK extends keyof typeof SortBy> = {
  tasks: NormalTaskWithRelations[]
  sort: SK
  projectDefinitionId: ProjectDefinitionSchema['id'] | null
}

export const useSortedGroupedTasks = <SK extends keyof typeof SortBy>({
  tasks,
  sort,
  projectDefinitionId,
}: UseSortedGroupedTasksArgs<SK>): SidebarTasksGroup[] => {
  const { workspaceId, projectId } = useSidebarTaskContext()

  const {
    getStatusById,
    getWorkspaceStatuses,
    getWorkspaceProjectById,
    getWorkspaceProjectDefinitionById,
  } = useWorkspaceFns()
  const getIsTaskBlocked = useIsTaskBlockedFn(tasks)

  return useMemo(() => {
    const sortLookup = lookupBySort(sort) as (
      args: LookupArgs
    ) => SidebarTasksGroup[]
    return sortLookup({
      tasks,
      workspaceId,
      projectId,
      projectDefinitionId,
      getProject: getWorkspaceProjectById,
      getStatusById,
      getStatuses: getWorkspaceStatuses,
      getIsTaskBlocked,
      getWorkspaceProjectDefinitionById,
    })
  }, [
    sort,
    tasks,
    workspaceId,
    projectId,
    projectDefinitionId,
    getWorkspaceProjectById,
    getStatusById,
    getWorkspaceStatuses,
    getIsTaskBlocked,
    getWorkspaceProjectDefinitionById,
  ])
}

type LookupArgs = {
  tasks: NormalTaskWithRelations[]
  workspaceId: WorkspaceSchema['id']
  projectId: ProjectSchema['id']
  projectDefinitionId: ProjectDefinitionSchema['id'] | null
  getProject: (projectId: ProjectSchema['id']) => ProjectSchema | undefined
  getStatuses: (workspaceId: WorkspaceSchema['id']) => StatusSchema[]
  getStatusById: (statusId: StatusSchema['id']) => StatusSchema | undefined
  getIsTaskBlocked: (task: NormalTaskWithRelations) => boolean
  getWorkspaceProjectDefinitionById: (
    id: ProjectDefinitionSchema['id']
  ) => ProjectDefinitionSchema | undefined
}

export type SidebarTasksGroup = Group<NormalTaskWithRelations>

const lookupBySort = createLookup<
  {
    [SK in keyof typeof SortBy]: (args: LookupArgs) => SidebarTasksGroup[]
  } & {
    default: () => never
  }
>({
  STATUS: ({
    tasks,
    workspaceId,
    projectDefinitionId,
    getStatuses,
    getWorkspaceProjectDefinitionById,
  }) => {
    const pd =
      projectDefinitionId != null
        ? getWorkspaceProjectDefinitionById(projectDefinitionId)
        : null

    const orderedStageDefIds = pd?.stages.map((s) => s.id) ?? []
    const orderedTaskDefIdsPerStageId = createLookupByKey(
      pd?.stages ?? [],
      'id',
      (s) => s.tasks.map((t) => t.id)
    )

    const taskGroups = groupBy(tasks, (task) => task.statusId)

    return getStatuses(workspaceId).map((status) => {
      const items = (taskGroups[status.id] ?? []).sort(
        cascade(
          byProperty(
            'stageDefinitionId',
            ordered<string | null>(
              orderedStageDefIds,
              Compare.string.with({ null: 'at-end', empty: 'at-end' })
            )
          ),
          byValue(
            (t) => {
              if (t.stageDefinitionId == null) return null
              if (t.taskDefinitionId == null) return null

              const index =
                orderedTaskDefIdsPerStageId[t.stageDefinitionId]?.indexOf(
                  t.taskDefinitionId
                ) ?? -1
              return index === -1 ? null : index
            },
            Compare.numeric.with({ null: 'at-end' })
          ),
          byProperty('createdTime', Compare.string)
        )
      )

      return {
        key: status.id,
        items,
      }
    })
  },
  STAGES: ({
    tasks,
    projectId,
    projectDefinitionId,
    getProject,
    getWorkspaceProjectDefinitionById,
  }) => {
    const project = getProject(projectId)
    const pd =
      projectDefinitionId != null
        ? getWorkspaceProjectDefinitionById(projectDefinitionId)
        : null
    if (project == null || pd == null) return []

    const taskGroups = groupBy(
      tasks,
      (task) => task.stageDefinitionId ?? 'none'
    )

    return pd.stages.map((stage) => {
      const orderedTaskDefIds = stage.tasks.map((t) => t.id)

      const items = (taskGroups[stage.id] ?? []).sort(
        cascade(
          byProperty(
            'taskDefinitionId',
            ordered<string | null>(
              orderedTaskDefIds,
              Compare.string.with({ null: 'at-end', empty: 'at-end' })
            )
          ),
          byProperty('createdTime', Compare.string)
        )
      )

      return {
        key: stage.id,
        items,
      }
    })
  },
  BLOCKERS: ({ tasks, getStatusById, getIsTaskBlocked }) => {
    const sortedTasks = sortByBlockers(
      tasks.sort(
        byProperty('statusId', (a, b) => {
          const aStatus = getStatusById(a)
          const bStatus = getStatusById(b)

          if (aStatus && isCompletedStatus(aStatus)) {
            return -1
          }

          if (bStatus && isCompletedStatus(bStatus)) {
            return 1
          }

          return 0
        })
      ),
      getIsTaskBlocked
    )
    return groupInto(sortedTasks, () => NO_GROUP_KEY)
  },
  DEFAULT_NONE: ({ tasks }) => {
    tasks.sort(defaultTaskSortFn)

    return groupInto(tasks, () => NO_GROUP_KEY)
  },
  default: () => {
    throw new Error(`Unknown sort type`)
  },
})
