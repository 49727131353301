import { type SearchableTreeNode } from '@motion/ui/base'

export type FolderItemType = 'WORKSPACE' | 'FOLDER' | 'PROJECT' | 'NONE'

export function openPath(
  node: SearchableTreeNode,
  path?: string[]
): SearchableTreeNode {
  if (!path || path.length === 0) return { ...node }

  if (node.id === path[0]) {
    return {
      ...node,
      defaultExpanded: true,
      nodes: node.nodes
        ? node.nodes.map((child) => openPath(child, path.slice(1)))
        : node.nodes,
    }
  }

  return { ...node }
}

const SEPARATOR = '|'

export function generateWorkspaceTreeNodeId(type: FolderItemType, id: string) {
  return `${type}${SEPARATOR}${id}`
}

export function parseWorkspaceTreeNodeId(nodeId: string) {
  const parts = nodeId.split(SEPARATOR)
  if (parts.length < 2) {
    throw new Error('Workspace tree node id malformed')
  }
  const type = parts[0] as FolderItemType
  const id = parts[1]
  return { id, type }
}

export function idEqualsTreeNodeId(nodeId: string, itemId: string | null) {
  const { id } = parseWorkspaceTreeNodeId(nodeId)
  return id === itemId
}

export function extractWorkspaceTreeNodePath(node: SearchableTreeNode) {
  let workspaceId = null
  let folderId = null
  let projectId = null

  for (const nodeId of node.path) {
    if (nodeId === '[ROOT]') continue

    const { id, type } = parseWorkspaceTreeNodeId(nodeId)
    if (type === 'PROJECT') {
      projectId = id
    } else if (type === 'FOLDER') {
      folderId = id
    } else if (type === 'WORKSPACE') {
      workspaceId = id
    }
  }
  if (!workspaceId) {
    throw new Error('Workspace not found')
  }
  return { workspaceId, projectId, folderId }
}
