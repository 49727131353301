import { ArrowRightSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { parseDate } from '@motion/utils/dates'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'

import { getTextForDuration } from './DurationOptionsDropdown'

import { CheckmarkIcon } from '../../../../components/Common/Icons/CheckmarkIcon'
import { type Workspace } from '../types'

export type WorkspaceTableProps = {
  workspace: Workspace
  step: 'workspaceName' | 'projectName' | 'tasks'
}

export function WorkspaceTable({
  workspace,
  step = 'workspaceName',
}: WorkspaceTableProps) {
  const { selectIsOnboarding } = useOnboardingApi()

  const isOnboarding = selectIsOnboarding()
  const { workspaceName, projectName, tasks } = workspace

  return (
    <div className='border-list-card-border-default border-r-none h-full flex-1 rounded-tl-lg rounded-bl-lg border bg-list-card-bg-default'>
      <div className='relative flex h-11 items-center px-3 border-b border-semantic-neutral-border-subtle'>
        {step === 'workspaceName' && (
          <div className='absolute left-0 top-0 bottom-0 flex -translate-x-6 items-center'>
            <ArrowRightSolid className='text-semantic-neutral-icon-default h-4 w-4' />
          </div>
        )}
        {workspaceName ? (
          <span className='text-semantic-neutral-text-default text-base font-semibold'>
            {workspaceName}
          </span>
        ) : (
          <div className='bg-semantic-neutral-border-subtle h-4 w-[150px] rounded-lg' />
        )}
      </div>
      <div className='bg-semantic-neutral-bg-disabled relative px-3 h-9 flex items-center border-b border-semantic-neutral-border-subtle'>
        {step === 'projectName' && (
          <div className='absolute left-0 top-0 bottom-0 flex -translate-x-6 items-center'>
            <ArrowRightSolid className='text-semantic-neutral-icon-default h-4 w-4' />
          </div>
        )}
        {projectName ? (
          <span className='text-light-1100 text-sm font-semibold'>
            {projectName}
          </span>
        ) : (
          <div className='bg-semantic-neutral-bg-active-default h-2.5 w-[72px] rounded-lg' />
        )}
      </div>
      <div className='relative'>
        <table className='min-w-full divide-y'>
          <thead className='relative'>
            <tr className='h-6'>
              <th
                scope='col'
                className='text-semantic-neutral-text-subtle border-semantic-neutral-border-subtle border-r px-3 text-left text-[10px] font-medium'
              >
                {step === 'tasks' && (
                  <span className='absolute left-0 top-0 bottom-0 flex -translate-x-6 translate-y-[38px] items-center'>
                    <ArrowRightSolid className='text-semantic-neutral-icon-default h-4 w-4' />
                  </span>
                )}
                Name
              </th>
              <th
                scope='col'
                className='text-semantic-neutral-text-subtle border-semantic-neutral-border-subtle border-r px-3 text-left text-[10px] font-medium'
              >
                Duration
              </th>
              <th
                scope='col'
                className='text-semantic-neutral-text-subtle px-3 text-left text-[10px] font-medium'
              >
                Deadline
              </th>
            </tr>
          </thead>
          <tbody className=''>
            {tasks.map((task, index) => (
              <tr key={`${task.id}-${index}`} className='h-8'>
                <td className='text-light-1100 border-semantic-neutral-border-subtle w-2/3 max-w-[480px] overflow-hidden border border-l-0 px-3'>
                  <div className='flex items-center gap-3'>
                    {isOnboarding && (
                      <span className='text-light-900 border-light-900 flex items-center justify-center rounded-full border p-1'>
                        <CheckmarkIcon />
                      </span>
                    )}
                    {task.description ? (
                      <span className='text-semantic-neutral-text-default tw-full break-words text-sm font-normal'>
                        {task.description}
                      </span>
                    ) : (
                      <div className='bg-semantic-neutral-bg-active-default h-[10px] w-[100px] rounded-lg' />
                    )}
                  </div>
                </td>
                <td className='text-semantic-neutral-text-default border-semantic-neutral-border-subtle w-[150px] border text-xs'>
                  <Tooltip
                    asChild
                    content={getTextForDuration(task.durationMinutes)}
                  >
                    <span className='block max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap px-3'>
                      {getTextForDuration(task.durationMinutes)}
                    </span>
                  </Tooltip>
                </td>
                <td className='text-semantic-neutral-text-default border-semantic-neutral-border-subtle whitespace-nowrap border border-r-0 text-xs'>
                  <span className='py-2 px-3 font-normal'>
                    {parseDate(task.deadlineISO).toFormat('LLL dd, yyyy')}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
