import { type ProjectWithRelations } from '~/global/proxies'
import { useMemo } from 'react'

import { convertCustomFieldToColumn } from './custom-fields'
import { getHeaderColumn } from './lookup-headers'
import { type TreeListColumn } from './type'
import { minSizeOf, sizeOf } from './utils'

import {
  type CustomFieldDefinition,
  type FieldDefinition,
  isCustomFieldColumn,
  type ProjectFieldId,
  useProjectFields,
} from '../../fields'
import { CELLS } from '../cells'

export const useProjectColumns = () => {
  const projectFields = useProjectFields()

  return useMemo(() => {
    return projectFields.map((f) => {
      if (isCustomFieldColumn(f.id)) {
        return convertCustomFieldToColumn(
          f as CustomFieldDefinition<ProjectWithRelations>
        )
      }
      return convertFieldToColumn(f)
    })
  }, [projectFields])
}

function convertFieldToColumn(
  f: FieldDefinition<ProjectWithRelations>
): TreeListColumn {
  return {
    id: f.id,
    header: getHeaderColumn(f.id),
    size: sizeOf(f),
    minSize: minSizeOf(f),
    cell: CELLS[f.id as ProjectFieldId],
    enableResizing: !f.disableResizing,
    enableSorting: f.sortFn != null,
    enableHiding: !f.disableHiding,
    enablePinning: f.enablePinning ?? false,
    accessorFn: (row) => row,

    meta: {
      name: f.name,
      disableReordering: f.disableReordering ?? false,
      customFields: undefined,
    },
  }
}
