import { logInDev } from '@motion/web-base/logging'

import { H } from 'highlight.run'

// copied from highlight lib
interface Metadata {
  [key: string]: string | boolean | number
}

class HighlightService {
  initialized = false
  started = false

  initialize() {
    logInDev('initialized Highlight')
    H.init(__IS_PROD__ ? '0dqzo6do' : 'kevq03g3', {
      backendUrl: 'https://highlight.usemotion.com',
      environment: __IS_PROD__ ? 'prod' : 'dev',
      manualStart: true,
      networkRecording: {
        enabled: false,
      },
      tracingOrigins: getTracingOrigins(),
    })
    this.initialized = true
  }

  identify(email: string, metadata: Metadata) {
    if (!this.initialized) {
      this.initialize()
    }
    H.identify(email, metadata)
  }

  track = (event: string, metadata?: Metadata) => {
    // ensure this won't be called from service worker which doesn't have Highlight
    if (this.initialized) {
      H.track(event, metadata)
    }
  }

  start = () => {
    if (this.initialized && !this.started) {
      logInDev('started Highlight')
      H.start()
      this.started = true
    }
  }
}

export const Highlight = new HighlightService()

function getTracingOrigins() {
  switch (import.meta.env.MODE) {
    case 'localhost':
      return ['localhost']
    case 'production':
      return ['app.usemotion.com']
    case 'qa':
      return ['qa-app.usemotion.com']
    case 'development':
      return ['dev-app.usemotion.com', 'staging.dev-usemotion.com']
    default:
      return undefined
  }
}
