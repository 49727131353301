import { type MeetingUrlSearchParams } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { useSearchParams } from '~/routing'

import { ConnectedMeetingModal } from '../event/modals/event-modal'

export const GlobalMeetingModal = () => {
  const hasEventModalEnabled = useHasTreatment('event-modal')
  const { mTask } = useSearchParams<MeetingUrlSearchParams>()

  const showMeetingModal = mTask != null

  if (!hasEventModalEnabled) return null

  return <ConnectedMeetingModal open={showMeetingModal} />
}
