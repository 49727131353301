import { ProjectPalette } from '@motion/ui/project'
import { type ProjectSchema } from '@motion/zod/client'

import { type ProjectWithRelations } from '~/global/proxies'
import { memo } from 'react'

import { ResizeableProjectItem } from '../../resizeable-project-item'
import { DragRowContainer } from '../drag-row-container'

type ProjectContainerProps = {
  project: ProjectWithRelations | ProjectSchema
}

export const ProjectContainer = memo(function ProjectContainer({
  project,
}: ProjectContainerProps) {
  return (
    <div className='w-full h-12 flex flex-row items-center relative'>
      <DragRowContainer project={project}>
        <ProjectPalette color={project.color}>
          <ResizeableProjectItem project={project} />
        </ProjectPalette>
      </DragRowContainer>
    </div>
  )
})
