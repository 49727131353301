import { TaskType } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { VirtualizedRows } from '@motion/ui/helpers'

import { type DraggableSyntheticListeners } from '@dnd-kit/core'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

import { SortableItem } from '../../../../components/sortable/sortable-item'
import { TabHeader } from '../../components'
import { type GroupedNode, type Tree } from '../../grouping'
import { KanbanCard } from '../card'

const CARD_HEIGHT = 138
const COLUMN_WIDTH = 266
const CARD_MARGIN_BOTTOM = 8
const DISABLED_DRAG_GROUPS = [
  'completedAt',
  'createdAt',
  'createdBy',
  'updatedAt',
  'projectDefinition',
  'stage',
]
const ENABLED_RECURRING_DRAG_GROUPS = ['status']

type KanbanColumnProps<T extends GroupedNode> = {
  item: Tree<T>
  setActivatorNodeRef?: (node: HTMLElement | null) => void
  listeners?: DraggableSyntheticListeners
  activeId?: string
  draggingOver?: boolean
  dragInDisabled?: boolean
}
export const KanbanColumn = <T extends GroupedNode>(
  props: KanbanColumnProps<T>
) => {
  const {
    setActivatorNodeRef,
    listeners,
    activeId,
    item,
    draggingOver,
    dragInDisabled,
  } = props
  return (
    <KanbanColumnContainer dragInDisabled={props.dragInDisabled}>
      <div
        className='py-1.5 px-2 cursor-grab'
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <TabHeader
          item={props.item}
          shouldShowAdd
          active
          size='normal'
          shouldShowCountType
        />
      </div>
      <KanbanColumnItems
        activeId={activeId}
        item={item}
        draggingOver={draggingOver}
        dragInDisabled={dragInDisabled}
      />
    </KanbanColumnContainer>
  )
}

const KanbanColumnItems = memo(
  <T extends GroupedNode>(
    props: Omit<KanbanColumnProps<T>, 'listeners' | 'setActivatorNodeRef'>
  ) => {
    const items = props.item.values

    let groupType = props.item?.item?.value.type ?? ''

    let isDisabledDragGroup = DISABLED_DRAG_GROUPS.includes(groupType)
    let isDisabledRecurringDragGroup =
      !ENABLED_RECURRING_DRAG_GROUPS.includes(groupType)

    return (
      <>
        <VirtualizedRows
          items={items}
          containerClassName={twMerge(
            'px-2',
            // Hack to prevent jank with DnD kit
            props.activeId && 'overflow-y-hidden'
          )}
          estimateHeight={() => CARD_HEIGHT + CARD_MARGIN_BOTTOM}
          renderItem={({ item }) => {
            let draggingDisabled = props.dragInDisabled || isDisabledDragGroup

            if (
              !draggingDisabled &&
              item?.value?.type === 'task' &&
              item.value.value.type === TaskType.RECURRING_INSTANCE &&
              isDisabledRecurringDragGroup
            ) {
              // Check if its a recurring task and if it is, check if it can be dragged
              draggingDisabled = true
            }

            return (
              <SortableItem
                id={item.qualifiedKey}
                disabled={draggingDisabled}
                data={{
                  type: 'item',
                  width: COLUMN_WIDTH,
                  contain: 'strict',
                }}
                style={{
                  position: 'relative',
                  paddingBottom: 8,
                }}
                renderItem={() => (
                  <KanbanCard key={item.qualifiedKey} item={item} />
                )}
              />
            )
          }}
        />
        {props.draggingOver && !props.dragInDisabled && (
          <div className='border border-semantic-primary-border-active absolute inset-0 rounded pointer-events-none' />
        )}
      </>
    )
  }
)
KanbanColumnItems.displayName = 'KanbanColumnItems'

const KanbanColumnContainer = classed('div', {
  base: 'py-2 overflow-hidden flex flex-col w-full h-full gap-1 bg-calendar-bg-default rounded ',
  variants: {
    dragInDisabled: {
      true: 'opacity-20',
      false: '',
    },
  },
  defaultVariants: {
    dragInDisabled: false,
  },
})
