import { PlusSolid } from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useProjectModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

type WorkspaceActionMenuProps = {
  workspaceId: string
}

export const WorkspaceCreateMenu = (props: WorkspaceActionMenuProps) => {
  const { workspaceId } = props
  const modalApi = useModalApi()
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()

  return (
    <ActionDropdown
      sections={[
        {
          items: [
            {
              prefix: <PlusSolid />,
              content: 'New project',
              onAction: () => {
                navigate(buildProjectModalUrl({ forWorkspace: workspaceId }))
              },
            },
            {
              prefix: <PlusSolid />,
              content: 'New folder',
              onAction: () => {
                modalApi.open('create-new-folder', {
                  workspaceId,
                })
              },
            },
          ],
        },
      ]}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={PlusSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'WORKSPACE',
            button: 'CREATE',
          })
        }}
      />
    </ActionDropdown>
  )
}
