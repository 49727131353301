import { Button } from '@motion/ui/base'

import { useTaskForm } from '~/areas/tasks/modals/task-modal/hooks'

import { FormSaveButton, PeekModalFooter } from '../common'
import { useClosePeekModal } from '../hooks'

type TaskFormFooterProps = {
  onSubmit: () => void
}
export const TaskFormFooter = (props: TaskFormFooterProps) => {
  const { onSubmit } = props
  const { form } = useTaskForm()
  const { isSubmitting } = form.formState
  const closePeekModal = useClosePeekModal()

  const {
    formState: { isDirty },
  } = form
  const id = form.watch('id')
  const name = form.watch('name')
  return (
    <PeekModalFooter className='justify-end'>
      {(isDirty || !id) && (
        <div className='flex shrink-0 gap-1'>
          <Button
            disabled={isSubmitting}
            onClick={closePeekModal}
            sentiment='neutral'
            variant='muted'
            size='small'
            shortcut='esc'
          >
            Cancel
          </Button>
          <FormSaveButton
            mode={id ? 'edit' : 'create'}
            loading={isSubmitting}
            disabled={!isDirty || name.trim().length < 1}
            onSubmit={onSubmit}
          />
        </div>
      )}
    </PeekModalFooter>
  )
}
