import {
  ConnectedAccountSettings,
  ConnectedBillingSettings,
} from '~/areas/settings/pages'
import { ConnectedAPISettings } from '~/components/Settings/Pages/APISettings'
import { BookingSettings } from '~/components/Settings/Pages/BookingSettings/BookingSettings'
import { ConnectedCalendarSettings } from '~/components/Settings/Pages/calendar-settings'
import { ConferenceSettings } from '~/components/Settings/Pages/ConferenceSettings/ConferenceSettings'
import { ConnectedDesktopSettings } from '~/components/Settings/Pages/DesktopSettings'
import { IntegrationSettings } from '~/components/Settings/Pages/IntegrationSettings'
import { NotificationSettings } from '~/components/Settings/Pages/NotificationSettings/NotificationSettings'
import { PrivacySettings } from '~/components/Settings/Pages/privacy'
import { Referrals } from '~/components/Settings/Pages/referrals/referrals'
import { ConnectedScheduleSettings } from '~/components/Settings/Pages/ScheduleSettings'
import { ConnectedTaskManagerSettings } from '~/components/Settings/Pages/TaskManagerSettings/ConnectedTaskManagerSettings'
import { TimezoneSettings } from '~/components/Settings/Pages/TimezoneSettings/TimezoneSettings'
import { ViewSettings } from '~/components/Settings/Pages/ViewSettings/ViewSettings'
import { ConnectedTeamLandingPage } from '~/components/Team'
import { ConnectedCreateTeamPage } from '~/components/Team/connected-create-team-page'
import { ConnectedTeamMembersPage } from '~/components/Team/connected-team-members-page'
import { type MotionRoute } from '~/routing/api'
import { redirect } from 'react-router'

import { TaskDefaultsSettingsPage } from './pages/task-defaults'
import { workspaceRoutes } from './pages/workspace/routes'
import { SettingsPage } from './settings-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    settings: undefined
  }
}

export const settingsRoutes: MotionRoute[] = [
  {
    id: 'settings',
    path: 'settings',
    element: <SettingsPage />,
    loader: (args) => {
      if (
        args.request.url.endsWith('/scheduler') ||
        args.request.url.endsWith('/scheduler/')
      ) {
        return redirect('/web/booking')
      }

      if (
        args.request.url.endsWith('/web/settings') ||
        args.request.url.endsWith('/web/settings/')
      ) {
        return redirect('/web/settings/calendar')
      }
      return {}
    },
    children: [
      {
        path: 'calendar',
        element: <ConnectedCalendarSettings />,
      },
      { path: 'api', element: <ConnectedAPISettings /> },
      { path: 'privacy', element: <PrivacySettings /> },
      { path: 'view', element: <ViewSettings /> },
      { path: 'conference', element: <ConferenceSettings /> },
      { path: 'timezone', element: <TimezoneSettings /> },
      { path: 'schedule', element: <ConnectedScheduleSettings /> },
      { path: 'desktop', element: <ConnectedDesktopSettings /> },
      {
        path: 'integration',
        element: <IntegrationSettings />,
        metric: 'INTEGRATION_SETTINGS_PAGE_VIEWED',
      },
      { path: 'booking', element: <BookingSettings /> },
      { path: 'task', element: <ConnectedTaskManagerSettings /> },
      { path: 'notifications', element: <NotificationSettings /> },
      {
        path: 'account',
        element: <ConnectedAccountSettings />,
        metric: 'ACCOUNT_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'team',
        element: <ConnectedTeamLandingPage />,
      },
      {
        path: 'team/members',
        element: <ConnectedTeamMembersPage />,
      },
      { path: 'team/create', element: <ConnectedCreateTeamPage /> },
      ...workspaceRoutes,
      { path: 'referrals', element: <Referrals /> },
      {
        path: 'billing',
        element: <ConnectedBillingSettings />,
        metric: 'BILLING_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'task-defaults',
        element: <TaskDefaultsSettingsPage />,
        featureFlag: 'task-defaults',
        metric: 'TASK_DEFAULT_SETTINGS_PAGE_VIEWED',
      },
    ],
    errorElement: <div>error</div>,
  },
]
