import { classed } from '@motion/theme'

export const PeekModalFooter = classed(
  'div',
  'h-9 border-t border-t-semantic-neutral-border-subtle flex items-center px-2 text-xs shrink-0'
)

export const PeekModalHeader = classed(
  'div',
  'h-7 bg-calendar-palette-bg-default flex items-center pr-2 text-[10px] font-semibold shrink-0 border-l-[3px] border-calendar-palette-highlight-default',
  { variants: { hasOtherCalendars: { true: 'border-l-[2px]' } } }
)
