import { FolderSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { type FolderSchema, type IdFilterSchema } from '@motion/rpc-types'
import { groupInto } from '@motion/utils/array'

import { FolderLabel } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { FilterItem } from './filter-item/filter-item'
import { GroupedItemDropdown } from './grouped-item-dropdown'

import { useFieldFilter } from '../../../context'
import { buildIdFilter } from '../../../utils'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedFolderFilter = (props: DropdownFilterProps) => {
  const [filter, setFilter] = useFieldFilter(props.target, 'folderIds')
  const ids = filter?.value ?? null
  const [ctx] = useSharedState(AppWorkspaceContext)

  const items = useMemo(() => {
    const items = ctx.folders.all.map((x) => ({
      ...x,
      workspaceId: x.targetId,
    }))

    const groups = groupInto(items, (p) => p.targetId).map((g) => ({
      key: g.key,
      items: g.items,
    }))

    return {
      all: items,
      groups,
    }
  }, [ctx.folders.all])

  const selected =
    filter == null
      ? []
      : (filter as IdFilterSchema).value
          .map((id) => items.all.find((u) => u.id === id))
          .filter(Boolean)

  const onSelect = (
    values: (FolderSchema & { workspaceId: string })[] | null
  ) => {
    setFilter(buildIdFilter(values, filter as IdFilterSchema))
  }

  return (
    <FilterItem
      label='Folder'
      canInvert
      inverted={filter?.inverse}
      onInvertChanged={(invert) => {
        if (filter == null) return
        setFilter({ ...filter, inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setFilter(null)}
      renderDropdown={({ close }) => {
        return (
          <GroupedItemDropdown
            items={items}
            selected={selected}
            onSelect={onSelect}
            close={close}
            placeholder='Choose folders...'
            renderItem={(item) => <FolderLabel value={item} />}
            renderHeader={(group) => ctx.workspaces.byId[group.key]?.name}
          />
        )
      }}
    >
      <MultiSelectFilterValue Icon={FolderSolid} type='folders' ids={ids} />
    </FilterItem>
  )
}
