import { useHasTreatment } from '@motion/web-common/flags'

import {
  useGetTutorialPayload,
  useGetUserTutorialCompletion,
} from '~/components/Settings/Pages/tutorials/rpc-hooks'
import { useUtilizedSubscription } from '~/global/rpc'
import { useMemo } from 'react'

export const useTutorialSidebarState = () => {
  const tutorialsSidebarFlagEnabled = useHasTreatment('show-tutorials-sidebar')
  const subscription = useUtilizedSubscription()

  const { data: tutorialData } = useGetTutorialPayload()
  const { data: userCompletionData } = useGetUserTutorialCompletion()

  const firstSection = tutorialData?.sections[0]
  const completedLessonIds = useMemo(
    () => userCompletionData?.completedTutorialIds ?? [],
    [userCompletionData]
  )

  const firstSectionCompletedPercent = useMemo(() => {
    if (!firstSection) {
      return 0
    }

    const totalLessons = firstSection.lessons.length
    const completedLessons = firstSection.lessons.filter((lesson) =>
      completedLessonIds.includes(lesson.id)
    ).length
    return Math.round((completedLessons / totalLessons) * 100)
  }, [firstSection, completedLessonIds])

  const showTutorialSidebar = useMemo(() => {
    return (
      tutorialsSidebarFlagEnabled &&
      subscription?.status === 'trialing' &&
      (firstSectionCompletedPercent ?? 0) !== 100
    )
  }, [tutorialsSidebarFlagEnabled, subscription, firstSectionCompletedPercent])

  return {
    showTutorialSidebar,
    firstSectionCompletedPercent,
    completedLessonIds,
    firstSection,
  }
}
