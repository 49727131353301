import { CheckSolid, XSolid } from '@motion/icons'
import { PMTeamMemberRole } from '@motion/rpc/types'
import { Button, LoadingSpinner } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  getMinimumBucket,
  TEAM_DEFAULT_BUCKET_SEATS,
} from '@motion/ui-logic/billing'
import { isEmailValid } from '@motion/utils/string'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { TEAM_DEFAULT_TRIAL_LENGTH } from '~/areas/onboarding/constants'
import { useAllWorkspaces } from '~/global/hooks'
import {
  useCurrentTeam,
  useGetTeamPrices,
  useInviteTeamMembers,
  useRenameTeam,
} from '~/global/rpc/team'
import { useCallback, useEffect, useRef, useState } from 'react'

import type { SkipCreateTeamReasons } from './utils'

import { useAppSelector } from '../../../../state/hooks'
import { fetchTeam } from '../../../../state/projectManagement/teamThunks'
import { dispatch } from '../../../../state/proxy'
import {
  getSubscription,
  selectStripeSubscription,
} from '../../../../state/userSlice'
import { useOnboardingApi } from '../../state/use-onboarding-api'
import { CreateTeamDoubleConfirmationModal } from '../shared/CreateTeamDoubleConfirmationModal'
import { EmailAutoComplete } from '../shared/EmailAutoComplete'
import { SkipTeamInvitesConfirmationModal } from '../shared/skip-team-invites-confirmation'
import { SkipTeamDoubleConfirmationModal } from '../shared/SkipTeamDoubleConfirmationModal'
import { type OnboardingType } from '../types'

type KycCreateTeamProps = {
  onCreateTeam?: (props: {
    teamName: string
    emails: string[]
    isMonthly: boolean
    seats: number
  }) => Promise<void>
  goToNextPage: () => void
  onSkip?: () => void
  onboardingType?: OnboardingType
  noCcCopyEnabled: boolean
}

export function KycCreateTeam({
  onCreateTeam = () => Promise.resolve(),
  goToNextPage,
  onSkip = () => void 0,
  onboardingType = 'individual',
  noCcCopyEnabled = false,
}: KycCreateTeamProps) {
  const [teamName, _setTeamName] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState<any>(null)
  const [guests, _setGuests] = useState<string[]>([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showSkipTeamModal, setShowSkipTeamModal] = useState(false)
  const [showSkipTeamInvitesModal, setShowSkipTeamInvitesModal] =
    useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [resetCounter, setResetCounter] = useState(0)
  const [teamNameError, setTeamNameError] = useState('')
  const [guestsError, setGuestsError] = useState('')
  const [pageLoading, setPageLoading] = useState(true)
  const pmWorkspaces = useAllWorkspaces()
  const { data: teamPrices } = useGetTeamPrices()
  const { data: team, isLoading: isLoadingTeam } = useCurrentTeam()

  const { mutateAsync: renameTeam } = useRenameTeam()
  const { mutateAsync: inviteTeamMembers } = useInviteTeamMembers()

  const { checkGuestEligibility } = useOnboardingApi()

  useOnMountAnalyticsEvent('KYC_CREATE_TEAM')

  useEffect(() => {
    async function refreshSubscription() {
      if (onboardingType === 'team_with_card') {
        await dispatch(getSubscription())
      }
      setPageLoading(false)
    }
    void refreshSubscription()
  }, [onboardingType])

  function setTeamName(name: string) {
    if (teamNameError) {
      setTeamNameError('')
    }

    _setTeamName(name)
  }

  const setGuests = useCallback(
    (guests: string[]) => {
      if (guests.length > 0 && guestsError) {
        setGuestsError('')
      }

      _setGuests(guests)
    },
    [guestsError]
  )

  const newTeamSize = guests.length + 1
  const minimumBucket = getMinimumBucket(newTeamSize)

  const stripeSubscription = useAppSelector(selectStripeSubscription)
  const interval = stripeSubscription?.plan?.interval
  const isAnnual = interval === 'year'
  const [seats, setSeats] = useState<number | null>(null)

  const inputElement = useRef<null | HTMLInputElement>(null)

  const modalApi = useModalApi()

  useEffect(() => {
    if (stripeSubscription?.quantity) {
      setSeats(stripeSubscription.quantity)
    }
  }, [stripeSubscription])

  useEffect(
    function autoFocus() {
      inputElement.current?.focus()
      // we don't want to recompute focus if the feature flag value changes
    },
    [inputElement]
  )

  const onAddEmail = useCallback(
    (email: string) => {
      const trimmedEmail = email.trim()

      if (!isEmailValid(trimmedEmail)) {
        setGuestsError('Please enter a valid email.')
        setSelected(null)
        return
      }

      if (
        guests.some(
          (guest) => guest.toLowerCase() === trimmedEmail.toLowerCase()
        )
      ) {
        setSelected(null)
        return
      }

      void checkGuestEligibility(trimmedEmail).then((res) => {
        if (res.isEligible) {
          setGuests([...guests, trimmedEmail].filter(Boolean))
          setGuestsError('')
        } else {
          setGuestsError(
            `This member can't be invited because they're already part of an active team.`
          )
        }
        setSelected(null)
        setInputValue('')
        setError('')
        setResetCounter(resetCounter + 1)
        return
      })
    },
    [checkGuestEligibility, guests, resetCounter, setGuests]
  )

  function onRemoveEmail(email: string) {
    setGuests(guests.filter((g) => g !== email))
  }

  function onCreateInvite() {
    let isValid = true

    setTeamNameError('')
    setGuestsError('')

    if (!teamName) {
      setTeamNameError('Please enter a team name.')
      isValid = false
    }

    if (!isValid) {
      return
    }
    if (guests.length) {
      recordAnalyticsEvent('NEW_ONBOARDING_TEAM_INVITE')
    }

    if (noCcCopyEnabled && guests.length === 0) {
      setShowSkipTeamInvitesModal(true)
    } else if (!noCcCopyEnabled && seats && guests.length >= seats) {
      modalApi.open('manage-team-seats', {
        title: `Switch to ${minimumBucket} seats to invite ${guests.length} or more members`,
        isAnnual: isAnnual,
        isTrial: true,
        onSubmit: async (seats: number) => {
          await onSubmit(seats)
          recordAnalyticsEvent('NEW_ONBOARDING_TEAM_UPGRADE_SEATS', {
            num_seats: seats,
          })
        },
        teamSize: newTeamSize,
        initialSelection: minimumBucket,
        minSeats: minimumBucket,
      })
    } else if (noCcCopyEnabled) {
      void onSubmit(minimumBucket)
    } else {
      void onSubmit()
    }
  }

  async function onSubmit(newSeats?: number) {
    setLoading(true)
    setError('')
    setGuestsError('')
    setTeamNameError('')

    if (team) {
      try {
        await renameTeam({ name: teamName, id: team.id })

        if (guests.length) {
          const teamWorkspace = pmWorkspaces.find(
            (workspace) => workspace.teamId === team.id
          )
          const invitees = guests.map((guest) => ({
            email: guest,
            role: PMTeamMemberRole.MEMBER,
            workspaces: teamWorkspace?.id ? [teamWorkspace.id] : [],
          }))
          await inviteTeamMembers({ id: team.id, invitees, seats: newSeats })
        }
        await dispatch(fetchTeam())
        goToNextPage()
      } catch (error) {
        setError('There was an error creating your team, please try again')
        setLoading(false)
        setShowConfirmModal(false)
      }
    } else {
      await onCreateTeam({
        teamName,
        emails: guests,
        isMonthly: !isAnnual,
        seats: Math.max(TEAM_DEFAULT_BUCKET_SEATS, getMinimumBucket(newSeats)),
      }).catch((error) => {
        setError(error.message)
        setLoading(false)
        setShowConfirmModal(false)
      })
      await dispatch(fetchTeam())
      await dispatch(getSubscription())
    }
  }

  function onSetSelected(args: { email?: string }) {
    if (args?.email) {
      onAddEmail(args.email)
    }
  }

  const hasEnteredFormData = guests.length > 0 || teamName.length > 0

  if (
    (pageLoading && onboardingType !== 'team') ||
    !teamPrices ||
    isLoadingTeam
  ) {
    return (
      <div className='flex flex-row items-center justify-center w-full h-full gap-3 text-semantic-neutral-text-default font-semibold'>
        <LoadingSpinner />
        Loading...
      </div>
    )
  }

  function handleSkipTeam(reason: SkipCreateTeamReasons) {
    recordAnalyticsEvent('KYC_CREATE_TEAM_SKIP', {
      reason,
    })
    if (onboardingType === 'team' || hasEnteredFormData) {
      setShowSkipTeamModal(true)
      return
    }
    onSkip()
  }

  return (
    <div className='h-full w-full flex'>
      <div className='mx-auto max-w-[600px] lg:py-12 px-6 lg:px-0 py-6'>
        <div className='mb-6 lg:mb-8'>
          <div className='mb-3 lg:mb-2 flex w-full flex-col items-start'>
            <h1 className='mb-0 text-lg lg:text-[32px] lg:leading-snug font-bold select-none'>
              🦸‍♂️ Give superpowers to your team. Unlock team features.
              {noCcCopyEnabled && ' Get a 40% discount.'}
            </h1>
          </div>
          <p className='text-light-1100 text-base font-normal'>
            {onboardingType === 'individual' && interval
              ? templateStr(
                  'Assign tasks and collaborate on projects with teammates. All team members receive a free trial, then ${{cost}}/month per user (billed {{frequency}})',
                  {
                    cost:
                      interval === 'year'
                        ? teamPrices.annualPricePerMonth
                        : teamPrices.monthlyPrice,
                    interval,
                    frequency: interval === 'year' ? 'annually' : 'monthly',
                  }
                )
              : noCcCopyEnabled
                ? 'Assign tasks and collaborate on projects with teammates. Motion is most useful when used with a team. All team members are free during the trial! Invite your entire team.'
                : `Assign tasks and collaborate on projects with teammates. All team members receive a ${TEAM_DEFAULT_TRIAL_LENGTH}-day free trial. Additional team members can be added after onboarding.`}
          </p>
        </div>
        <div className='border-light-500 flex w-full flex-col items-start rounded border bg-white p-4'>
          <div className='mb-4 lg:mb-8 w-full'>
            <p className='text-light-1100 mb-2 text-sm font-semibold'>
              What&apos;s your team called?
            </p>

            <input
              ref={inputElement}
              className='mb-2 w-full rounded border py-1.5 px-3 text-base lg:text-sm'
              placeholder='Team name'
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            {teamNameError && (
              <div className='relative'>
                <p className='absolute text-sm text-red-600'>{teamNameError}</p>
              </div>
            )}
          </div>
          <div className='flex flex-row items-center w-full justify-between'>
            <p className='text-light-1100 mb-2 text-sm font-semibold'>
              Invite team members
            </p>
            {team && (
              <p className='text-xs text-semantic-neutral-text-subtle'>
                {templateStr('{{usedSeats}}/{{totalSeats}} seats used', {
                  usedSeats: guests.length + 1,
                  totalSeats: team.teamSubscription?.bucketSeats || 0,
                })}
              </p>
            )}
          </div>
          <div className='w-full'>
            <div className='mb-2 flex w-full items-center gap-x-2'>
              <EmailAutoComplete
                key={resetCounter}
                selected={selected}
                setSelected={onSetSelected}
                inputValue={inputValue}
                setInputValue={setInputValue}
                placeholder='Add teammates'
              />
              <Button
                onClick={() => onAddEmail(selected?.email || inputValue)}
                sentiment='neutral'
              >
                Add
              </Button>
            </div>

            <div className='flex flex-wrap items-center gap-1'>
              {guests.map((email) => (
                <button
                  key={email}
                  onClick={() => onRemoveEmail(email)}
                  className='bg-light-300 border-light-400 text-light-1100 my-1 flex items-center gap-1 rounded border py-0.5 px-1 text-[11px] font-medium'
                >
                  {email} <XSolid className='text-light-900 h-3 w-3' />
                </button>
              ))}
            </div>

            {guestsError && (
              <div className='relative mb-4'>
                <p className='absolute text-sm text-red-600'>{guestsError}</p>
              </div>
            )}
          </div>

          {error && (
            <div className='mt-2 break-words py-2'>
              <p className='text-sm text-red-600'>{error}</p>
            </div>
          )}
        </div>
        <div className='mt-4 flex flex-col gap-y-4'>
          {noCcCopyEnabled ? (
            guests.length > 0 ? (
              <Button onClick={onCreateInvite} loading={loading}>
                Create team and send invites
              </Button>
            ) : (
              <Button
                variant='muted'
                sentiment='neutral'
                onClick={onCreateInvite}
                loading={loading}
              >
                Continue and invite team members later
              </Button>
            )
          ) : (
            <Button onClick={onCreateInvite} loading={loading}>
              {guests.length > 0
                ? 'Create team and send invites'
                : 'Continue and invite team members later'}
            </Button>
          )}

          {onboardingType === 'individual' && !team && (
            <>
              <Button
                fullWidth
                onClick={() =>
                  handleSkipTeam('will_try_motion_with_team_in_future')
                }
                sentiment='neutral'
                variant='muted'
              >
                Continue as individual (I&apos;d like to use Motion with my
                team, but not right now)
              </Button>
              <Button
                fullWidth
                onClick={() => handleSkipTeam('never_use_motion_with_team')}
                sentiment='neutral'
                variant='muted'
              >
                Continue as individual (I will never want to use Motion with my
                team)
              </Button>
              <Button
                fullWidth
                onClick={() => handleSkipTeam('no_team')}
                sentiment='neutral'
                variant='muted'
              >
                Continue as individual (I don&apos;t have a team)
              </Button>
            </>
          )}
        </div>
      </div>
      <UpsellBanner />

      <SkipTeamInvitesConfirmationModal
        onSubmit={() => {
          void onSubmit()
          setShowSkipTeamInvitesModal(false)
        }}
        isOpen={noCcCopyEnabled && showSkipTeamInvitesModal}
        onClose={() => setShowSkipTeamInvitesModal(false)}
      />
      <SkipTeamDoubleConfirmationModal
        onSkip={() => {
          onSkip()
          setShowSkipTeamModal(false)
        }}
        isOpen={showSkipTeamModal}
        onClose={() => setShowSkipTeamModal(false)}
      />
      <CreateTeamDoubleConfirmationModal
        isOpen={showConfirmModal}
        emails={guests}
        loading={loading}
        onCreate={onSubmit}
        onClose={() => setShowConfirmModal(false)}
        onboardingType={onboardingType}
      />
    </div>
  )
}

function UpsellBanner() {
  return (
    <div className='flex w-[490px] flex-col items-center self-stretch bg-[#11144F]'>
      <img
        alt='team-working'
        src='https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/66317223531e8e7d9b4f231e_aaa.png'
      />
      <div className='flex p-8 flex-col gap-6 flex-[1_0_0] self-stretch items-start'>
        <h4 className='text-white text-base font-semibold'>
          Collaborate on projects, assign tasks, and automate prioritization for
          the entire team 🦸‍♂
        </h4>
        <div className='flex flex-col items-start gap-2'>
          <UpsellBullet text='Automatically prioritize what each team member should work on' />
          <UpsellBullet text='Ensure entire team never misses deadlines' />
          <UpsellBullet text='Tells you exactly when every task and project is expected to finish' />
          <UpsellBullet text='Shows progress and what each team member is working on' />
          <UpsellBullet text='Automatically resolve blockers' />
          <UpsellBullet text='Centralized billing' />
        </div>
      </div>
    </div>
  )
}

function UpsellBullet({ text }: { text: string }) {
  return (
    <div className='flex w-[411px] items-start gap-2'>
      <CheckSolid className='text-[#2C77E7] h-4 w-4' />
      <span className='text-white text-sm font-normal'>{text}</span>
    </div>
  )
}
