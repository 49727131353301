import {
  Button,
  ButtonGroup,
  Tooltip,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'

import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'

import {
  FormShell,
  GridShell,
  LeftPanel,
  MainContent,
  MainContentFooter,
  MainContentScrollable,
  SetupProjectTabPanels,
  SetupProjectTabs,
  ShellVars,
} from './components'
import {
  SetupProjectFormNavigationProvider,
  useCloseSetupProjectModal,
  useSetupProjectForm,
  useSetupProjectFormNavigationContext,
  useSetupProjectModalSubmitHandler,
} from './hooks'
import { SetupProjectForm } from './setup-project-form'

type SetupProjectModalProps = {
  open: boolean
}

export function ConnectedSetupProjectModal({ open }: SetupProjectModalProps) {
  const closeModal = useCloseSetupProjectModal()

  return (
    <UnstyledModal
      data-testid='setup-project-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <SetupProjectForm>
        <SetupProjectModalBody close={closeModal} />
      </SetupProjectForm>
    </UnstyledModal>
  )
}

type SetupProjectModalBodyProps = { close: () => void }

export function SetupProjectModalBody({ close }: SetupProjectModalBodyProps) {
  return (
    <SetupProjectFormNavigationProvider>
      <SetupProjectModalContent close={close} />
    </SetupProjectFormNavigationProvider>
  )
}

function SetupProjectModalContent({ close }: SetupProjectModalBodyProps) {
  const onSubmit = useSetupProjectModalSubmitHandler()
  const { isDisabled, disabledMessage, goForward, goBack, isContinuing } =
    useSetupProjectFormNavigationContext()

  const {
    form: {
      formState: { isSubmitting, isDirty },
    },
  } = useSetupProjectForm()
  useRouteConfirmationPromptBeforeLeaving({
    when: isDirty && !isSubmitting && !isContinuing,
  })

  useShortcut('mod+s', goForward)

  return (
    <ShellVars>
      <FormShell onSubmit={onSubmit}>
        <GridShell>
          <LeftPanel>
            <h2 className='px-2 font-semibold text-semantic-neutral-text-subtle text-[14px] leading-[20px]'>
              Set up your project
            </h2>

            <SetupProjectTabs />
          </LeftPanel>

          <MainContent>
            <MainContentScrollable>
              <SetupProjectTabPanels />
            </MainContentScrollable>

            <MainContentFooter>
              {goBack != null && (
                <Button sentiment='neutral' variant='outlined' onClick={goBack}>
                  Back
                </Button>
              )}
              <div className='flex flex-1 justify-end'>
                <ButtonGroup size='medium'>
                  <Button
                    sentiment='neutral'
                    variant='muted'
                    shortcut='esc'
                    onClick={close}
                  >
                    Cancel
                  </Button>

                  <Tooltip content={disabledMessage} asChild>
                    <Button
                      sentiment='primary'
                      variant='solid'
                      shortcut='mod+s'
                      disabled={isDisabled}
                      onClick={() => goForward()}
                    >
                      Continue
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </div>
            </MainContentFooter>
          </MainContent>
        </GridShell>
      </FormShell>
    </ShellVars>
  )
}
