import { type COLOR } from '@motion/shared/common'
import { ProjectTreeviewItem, useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ProjectActionList } from '~/areas/project/components/project-action-list'
import { Link } from 'react-router-dom'

import { HIGHLIGHTABLE_DATA_ATTRIBUTE } from '../../../constants'
import { type WorkspaceTreeItem } from '../workspace-treeview.types'

type ProjectItemProps = {
  item: WorkspaceTreeItem
  highlighted: boolean
  selected: boolean
  disabledMoveActions?: { up: boolean; down: boolean }
}

export function ProjectItem({
  highlighted,
  item,
  selected,
  disabledMoveActions,
}: ProjectItemProps) {
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_SIDEBAR_PROJECT_RIGHT_CLICK')
    },
  })

  return (
    <>
      <Link
        to={item.url}
        className='h-full min-w-0'
        draggable='false'
        onContextMenu={handleContextMenu}
        {...{ [HIGHLIGHTABLE_DATA_ATTRIBUTE]: item.id }}
      >
        <ProjectTreeviewItem
          color={item.project!.color as COLOR}
          label={item.label}
          selected={selected}
          highlighted={highlighted}
        />
      </Link>

      {item.project && (
        <ContextMenuPopover
          renderContent={({ close }) =>
            item.project ? (
              <ProjectActionList
                close={close}
                options={{
                  showColors: true,
                  showCompleteProject: true,
                  showMoves: true,
                  disabledMoveActions,
                }}
                project={item.project}
              />
            ) : null
          }
        />
      )}
    </>
  )
}
