import { ScheduleSolid } from '@motion/icons'
import { API, useQuery } from '@motion/rpc'
import { PopoverTrigger } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'
import {
  CustomSchedulePanel,
  type CustomSchedulePanelProps,
} from '~/areas/tasks/modals/task-modal/components'
import { ScheduleDropdownContent } from '~/global/components/dropdowns'
import { useState } from 'react'

type ScheduleFieldProps = {
  value: string | null
  onChange: (value: string) => void
}

export const ScheduleField = ({ value, onChange }: ScheduleFieldProps) => {
  const { data: firestoreSettings, refetch } = useQuery(
    API.userSettings.getFirestoreSettings,
    undefined
  )

  const schedules = firestoreSettings?.schedules ?? {}

  const selectedSchedule =
    value != null ? schedules[value as keyof typeof schedules] : null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ScheduleFieldContent
          close={close}
          onUpdate={refetch}
          value={value}
          onChange={onChange}
        />
      )}
    >
      <ModalFieldButton label='Schedule'>
        <ScheduleSolid />
        {selectedSchedule?.title}
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

type ScheduleFieldContentProps = {
  close: () => void
  onUpdate: () => void
  value: string | null
  onChange: (value: string) => void
}

function ScheduleFieldContent({
  value,
  close,
  onUpdate,
  onChange,
}: ScheduleFieldContentProps) {
  const [scheduleStartTime, setScheduleStartTime] = useState('')
  const [scheduleEndTime, setScheduleEndTime] = useState('')
  const [scheduleIdealTime, setScheduleIdealTime] = useState<string | null>(
    null
  )

  const timeOnChange: CustomSchedulePanelProps['onChange'] = (values) => {
    setScheduleStartTime(values.start)
    setScheduleEndTime(values.end)
    setScheduleIdealTime(values.ideal)
  }

  return (
    <ScheduleDropdownContent
      close={close}
      selectedScheduleId={value ?? ''}
      onChange={onChange}
      onUpdate={onUpdate}
      renderCustomSchedulePanel={() => (
        <div className='border-l border-dropdown-border p-3'>
          <CustomSchedulePanel
            startTime={scheduleStartTime}
            endTime={scheduleEndTime}
            idealTime={scheduleIdealTime}
            onChange={timeOnChange}
          />
        </div>
      )}
    />
  )
}
