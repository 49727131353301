import { memo, useMemo } from 'react'

import { useScrollPosition } from '../context'
import { useScreenValues, useTimelineValues } from '../hooks'

export const TimeLineBar = memo(function TimeLineBar() {
  const [scrollPosition] = useScrollPosition()
  const { positionOffset } = useScreenValues()

  const { weekCols, monthCols } = useTimelineValues()

  const weekElements = useMemo(() => {
    return weekCols.map(({ date, position }, index) => (
      <div
        key={`week-${index}`}
        className='absolute text-xs whitespace-nowrap -translate-x-1/2'
        style={{ left: position }}
      >
        <div className='relative flex flex-col z-[2]'>
          {date.toFormat('d')}
          <div className='w-px h-1 -translate-y-1/4 bg-semantic-neutral-border-subtle self-center' />
        </div>
      </div>
    ))
  }, [weekCols])

  const monthElements = useMemo(() => {
    return monthCols.map(({ date, position }, index) => (
      <div
        key={`month-${index}`}
        className='absolute text-xs whitespace-nowrap'
        style={{ left: position }}
      >
        <div className='sticky translate-y-2/4 -translate-x-1/2 left-1'>
          {date.toFormat('LLL yyyy')}
        </div>
        <div className='w-px h-5 translate-y-2/4 bg-semantic-neutral-border-light self-center z-[1]' />
      </div>
    ))
  }, [monthCols])

  return (
    <div className='relative z-[5] h-10 shrink-0 bg-semantic-neutral-bg-disabled border-b border-semantic-neutral-border-subtle text-semantic-neutral-text-subtle'>
      <div
        style={{
          transform: `translateX(${scrollPosition + positionOffset}px)`,
        }}
      >
        <div className='relative h-5 flex items-center'>{monthElements}</div>
        <div className='relative h-5 flex items-center'>{weekElements}</div>
      </div>
    </div>
  )
})
