import { type FilterDefinition } from '~/areas/project-management/filters'
import { ConnectedIsUnvisitedStageFilter } from '~/areas/project-management/filters/components/v2'
import { useMemo } from 'react'

import { useCustomFieldFilterDefinitions } from './custom-fields/use-custom-field-filter-definitions'
import { defaultProjectFilters, defaultTaskFilters } from './definitions'

export const useTaskFilterDefinitions = (): FilterDefinition<'tasks'>[] => {
  const customFieldFilters = useCustomFieldFilterDefinitions<'tasks'>()

  return useMemo(
    () =>
      [...defaultTaskFilters, ...customFieldFilters].concat([
        {
          key: 'isUnvisitedStage',
          label: 'Unvisited Stage',
          renderDropdown: (props) => (
            <ConnectedIsUnvisitedStageFilter {...props} />
          ),
        },
      ]),
    [customFieldFilters]
  )
}

export const useProjectFilterDefinitions =
  (): FilterDefinition<'projects'>[] => {
    const customFieldFilters = useCustomFieldFilterDefinitions<'projects'>()

    return useMemo(
      () => [...defaultProjectFilters, ...customFieldFilters],
      [customFieldFilters]
    )
  }
