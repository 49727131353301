import {
  ExternalLinkSolid,
  PlusSolid,
  SwitchVerticalSolid,
} from '@motion/icons'
import { useDelayedUnmount, useDependantState } from '@motion/react-core/hooks'
import {
  Button,
  IconButton,
  PopoverTrigger,
  SearchableList,
  Tooltip,
  UnstyledCollapsableContainer,
} from '@motion/ui/base'
import { keys } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useSortedGroupedTasks } from '~/areas/task-project/hooks'
import { SortBy } from '~/areas/task-project/utils'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useProject } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useUriByRouteId } from '~/routing'
import { useState } from 'react'

import { useSidebarTaskContext } from '../../context'
import { InlineTaskCreationForm } from '../../inline-create'
import { SidebarTasks } from '../../sidebar-tasks'

type TaskTabProps = {
  tasks: NormalTaskWithRelations[]
  projectDefinitionId: ProjectDefinitionSchema['id'] | null
}

export function TaskTab({ tasks, projectDefinitionId }: TaskTabProps) {
  const { enableInlineAdd, projectId, workspaceId, isProjectModal } =
    useSidebarTaskContext()

  const [sort, setSort] = useDependantState<keyof typeof SortBy>(
    () => (projectDefinitionId ? 'STAGES' : 'STATUS'),
    [projectDefinitionId]
  )

  const groupedTasks = useSortedGroupedTasks({
    tasks,
    sort,
    projectDefinitionId,
  })

  return (
    <div className='flex flex-col h-full'>
      <TaskTabHeader
        workspaceId={workspaceId}
        projectId={projectId}
        projectDefinitionId={projectDefinitionId}
        sort={sort}
        setSort={setSort}
        enableInlineAdd={enableInlineAdd}
        isEmpty={tasks.length === 0}
        isProjectModal={isProjectModal}
      />
      <SidebarTasks
        groupedTasks={groupedTasks}
        sort={sort}
        projectId={projectId}
      />
    </div>
  )
}

export type TaskTabHeaderProps = {
  workspaceId: string
  projectId: string
  projectDefinitionId: ProjectDefinitionSchema['id'] | null
  sort: keyof typeof SortBy
  setSort: (sort: keyof typeof SortBy) => void
  enableInlineAdd: boolean
  isEmpty: boolean
  isProjectModal?: boolean
}

function TaskTabHeader({
  workspaceId,
  projectId,
  projectDefinitionId,
  sort,
  setSort,
  enableInlineAdd,
  isEmpty,
  isProjectModal,
}: TaskTabHeaderProps) {
  const getRouteUri = useUriByRouteId()

  const [expanded, setExpanded] = useState(false)
  const toggle = () => setExpanded((prev) => !prev)
  const context = useRouteAnalyticsMetadata()
  const project = useProject(projectId)

  const mounted = useDelayedUnmount(expanded, 200)

  // sort can only 'STAGES' if the project is created via a project template, in which case it will ghost tasks.
  const displayAddTaskButton =
    enableInlineAdd &&
    isEmpty &&
    sort !== 'STAGES' &&
    sort !== 'STATUS' &&
    !expanded

  const sortItems = keys(SortBy).filter(
    (sort) => sort !== 'STAGES' || projectDefinitionId != null
  )

  if (!project) {
    throw new Error('Project not found')
  }

  return (
    <>
      <UnstyledCollapsableContainer
        expanded={expanded}
        toggle={toggle}
        renderHeader={() => (
          <div className='min-w-0 p-3 flex flex-row items-center justify-between'>
            <div className='flex gap-2'>
              <h3 className='font-bold text-[15px] text-semantic-neutral-text-default'>
                Tasks
              </h3>

              {isProjectModal && (
                <Tooltip content='Open task list view' asChild>
                  <IconButton
                    icon={ExternalLinkSolid}
                    variant='muted'
                    sentiment='neutral'
                    size='small'
                    url={getRouteUri('workspace-project', {
                      workspaceId,
                      projectId,
                      variant: 'list',
                    })}
                  />
                </Tooltip>
              )}
            </div>

            <div className='flex gap-2'>
              <PopoverTrigger
                placement='bottom-start'
                renderPopover={({ close }) => (
                  <SearchableList
                    searchable={false}
                    itemType='select'
                    items={sortItems}
                    computeSelected={(item) => item === sort}
                    onSelect={(item) => {
                      if (item !== sort) {
                        recordAnalyticsEvent('TASK_SIDEBAR_SORT_BY_CHANGE', {
                          sortBy: SortBy[item],
                          ...context,
                        })
                        setSort(item)
                      }
                      close()
                    }}
                    renderItem={(item) => SortBy[item]}
                    computeKey={(item) => SortBy[item]}
                  />
                )}
              >
                <Button variant='muted' sentiment='neutral' size='small'>
                  <SwitchVerticalSolid />
                  <span className='text-xs font-semibold'>{SortBy[sort]}</span>
                </Button>
              </PopoverTrigger>

              {enableInlineAdd && (
                <Tooltip content='Add new task' asChild>
                  <Button
                    variant='muted'
                    sentiment='neutral'
                    size='small'
                    onClick={toggle}
                    iconOnly
                  >
                    <PlusSolid />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      >
        {mounted && enableInlineAdd && (
          <InlineTaskCreationForm
            close={() => setExpanded(false)}
            workspaceId={workspaceId}
            projectId={projectId}
          />
        )}
      </UnstyledCollapsableContainer>
      {displayAddTaskButton && (
        <div className='pl-4'>
          <Button
            variant='outlined'
            sentiment='neutral'
            size='small'
            onClick={() => setExpanded(true)}
          >
            <PlusSolid />
            <span>Add a task</span>
          </Button>
        </div>
      )}
    </>
  )
}
