import { Tooltip } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import {
  isEnabledStage,
  type SetupProjectFormFields,
  type StageArg,
} from '@motion/ui-logic/pm/project'

import { type ReactNode, useCallback } from 'react'
import { type UseFormSetValue } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export type ControlledSkipStageCheckboxProps = {
  index: number
  valuePath: `stageDueDates.${number}.skipped`
  label: NonNullable<ReactNode>
}

export function ControlledSkipStageCheckbox({
  index,
  valuePath,
  label,
}: ControlledSkipStageCheckboxProps) {
  const {
    form: { control, watch, setValue },
  } = useSetupProjectForm()

  const stages = watch('stageDueDates')

  const { field } = useController({
    name: valuePath,
    control,
  })

  const isLastRemaining =
    stages.filter(isEnabledStage).length === 1 && !field.value

  const onChange = useCallback(
    (checked: boolean) => {
      // Do before changing
      const isLatestEnabledStage =
        stages.findLastIndex(isEnabledStage) === index

      field.onChange(!checked)

      updateProjectDueDateOnStageSkip(
        isLatestEnabledStage,
        checked,
        stages,
        index,
        setValue
      )
    },
    [stages, index, field, setValue]
  )

  return (
    <Tooltip
      content={
        isLastRemaining ? 'At least one stage must be active' : undefined
      }
      asChild
    >
      <Checkbox
        label={label}
        checked={!field.value}
        onChange={onChange}
        disabled={isLastRemaining}
      />
    </Tooltip>
  )
}

function updateProjectDueDateOnStageSkip(
  prevLatestEnabledStage: boolean,
  checked: boolean,
  stages: StageArg[],
  index: number,
  setValue: UseFormSetValue<SetupProjectFormFields>
) {
  const newLatestEnabledStageIndex = stages.findLastIndex(isEnabledStage)

  // If this was the latest enabled stage and we are unchecking it, set the project due date to the previous stage's due date
  if (prevLatestEnabledStage && !checked) {
    const prevStageIndex = stages.slice(0, index).findLastIndex(isEnabledStage)
    if (prevStageIndex !== -1) {
      const prevStageDueDate = stages[prevStageIndex].dueDate
      setValue('dueDate', prevStageDueDate)
    }
    // If this is now the latest enabled stage, set the project due date to this stage's due date
  } else if (checked && newLatestEnabledStageIndex === index) {
    const currentStageDueDate = stages[index].dueDate
    setValue('dueDate', currentStageDueDate)
  }
}
