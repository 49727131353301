import { FieldButton } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

import { EventFormRowContainer } from './event-form-row-container'

import { useEventFormAllDayField } from '../hooks'

export const EventFormAllDayField = () => {
  const { field, onChange } = useEventFormAllDayField()

  return (
    <EventFormRowContainer className='mb-1'>
      <Checkbox
        label='All day'
        checked={field.value}
        onChange={onChange}
        labelHidden
      />
      <FieldButton
        size='small'
        variant='muted'
        fullWidth
        onClick={() => onChange(!field.value)}
      >
        All day
      </FieldButton>
    </EventFormRowContainer>
  )
}
