import { type UploadedFileSchema } from '@motion/rpc-types'
import { createTempId } from '@motion/shared/identifiers'

import { AttachmentUploadButton } from '~/areas/attachments'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { useTaskForm } from '~/areas/tasks/modals/task-modal/hooks'
import { useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

import { AttachmentTag } from './components/attachment-tag'
import { AttachmentUploadingTag } from './components/attachment-uploading-tag'

export const TaskAttachmentsUploader = () => {
  const [tempTaskId] = useState(createTempId(uuid()))

  const {
    form: { watch, control },
  } = useTaskForm()

  const { append, remove } = useFieldArray({
    control: control,
    name: 'uploadedFileIds' as any, // ToDo: Fix this
  })

  const workspaceId = watch('workspaceId')
  const uploadedFileIds = watch('uploadedFileIds')

  const { activeFileUploads } = useFileUploadState()

  const handleUploadSettled = (uploadedFile: UploadedFileSchema) =>
    append(uploadedFile.id)

  const handleAttachmentDelete = (id: UploadedFileSchema['id']) =>
    remove(uploadedFileIds.findIndex((fileId) => fileId === id))

  const filteredActiveFileUploads = activeFileUploads.filter(
    (upload) => upload.targetId === tempTaskId
  )

  const attachmentsCount =
    uploadedFileIds.length + filteredActiveFileUploads.length

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center justify-between'>
        <span className='text-sm text-semantic-neutral-text-default font-semibold'>
          Attachments
          {attachmentsCount > 0 && ` (${attachmentsCount})`}
        </span>
        <AttachmentUploadButton
          targetId={tempTaskId}
          targetType='TEAM_TASK'
          workspaceId={workspaceId}
          onUploadSettled={handleUploadSettled}
        />
      </div>
      <div className='flex flex-wrap justify-start gap-2'>
        {uploadedFileIds.map((uploadedFileId) => (
          <AttachmentTag
            key={uploadedFileId}
            attachmentId={uploadedFileId}
            onDelete={handleAttachmentDelete}
          />
        ))}

        {filteredActiveFileUploads.map((upload) => (
          <AttachmentUploadingTag
            key={upload.fileName}
            fileName={upload.fileName}
            progress={upload.progress}
          />
        ))}
      </div>
    </div>
  )
}
