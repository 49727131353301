import { DotsHorizontalSolid, DuplicateOutline } from '@motion/icons'
import { classed } from '@motion/theme'
import {
  FileIcon,
  IconButton,
  type IconButtonProps,
  PopoverTrigger,
  Tooltip,
} from '@motion/ui/base'
import {
  formatTime,
  formatToCompactReadableWeekDayMonth,
  prettyDateDay,
  templateStr,
} from '@motion/ui-logic'

import { useUser } from '~/global/hooks'
import { useFileById } from '~/global/rpc/v2/files'
import { DateTime } from 'luxon'
import { type MouseEvent, useState } from 'react'

import { AttachmentActionList } from './components/attachment-action-list'

import { useAttachmentUrl } from '../../hooks'
import { useAttachmentActions } from '../../hooks/use-attachment-actions'

const VARIANT_ICON_SIZE = {
  default: 24,
  compact: 16,
}

const VARIANT_ICON_BUTTON_SIZE: Record<
  'default' | 'compact',
  IconButtonProps['size']
> = {
  default: 'small',
  compact: 'xsmall',
}

type AttachmentItemProps = {
  attachmentId: string
  variant?: 'default' | 'compact'
}

export function AttachmentItem({
  attachmentId,
  variant = 'default',
}: AttachmentItemProps) {
  const [actionMenuOpen, setActionMenuOpen] = useState(false)

  const {
    data: attachment,
    isLoading: isAttachmentLoading,
    isError: isAttachmentError,
  } = useFileById({
    id: attachmentId,
  })

  const { navigateToAttachment } = useAttachmentUrl()
  const { copyAttachmentLink } = useAttachmentActions()

  const user = useUser(attachment?.createdByUserId)

  if (isAttachmentLoading) {
    return <ItemLoader />
  }

  if (isAttachmentError) {
    return <ItemError>Error loading attachment</ItemError>
  }

  if (!attachment) {
    return null
  }

  const handleAttachmentClick = () => {
    navigateToAttachment(attachmentId)
  }

  const handleCopyLinkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    copyAttachmentLink(attachment)
  }

  const handleActionMenuOpen = () => setActionMenuOpen(true)

  const handleActionMenuClose = () => setActionMenuOpen(false)

  const formattedTime = formatTime(attachment.createdTime)
  const formattedDate = prettyDateDay(attachment.createdTime)
  const formattedDateCompact = formatToCompactReadableWeekDayMonth(
    attachment.createdTime
  )
  const isUploadedToday = DateTime.now().hasSame(
    DateTime.fromISO(attachment.createdTime),
    'day'
  )

  return (
    <ItemContainer
      role='button'
      aria-label={`Open preview for ${attachment.fileName || 'attachment'}`}
      onClick={handleAttachmentClick}
      variant={variant}
    >
      <FileIcon
        mimeType={attachment.mimeType}
        width={VARIANT_ICON_SIZE[variant]}
        height={VARIANT_ICON_SIZE[variant]}
      />
      <div className='flex-1 overflow-hidden text-ellipsis whitespace-nowrap'>
        {attachment.fileName}
      </div>
      <Tooltip
        content={templateStr(
          'Uploaded {{formattedDatePrefix}}{{formattedDate}} at {{formattedTime}} by {{authorName}}',
          {
            formattedDatePrefix: isUploadedToday ? '' : 'on ',
            formattedDate,
            formattedTime,
            authorName: user.name,
          }
        )}
      >
        <span>
          {variant === 'default' ? formattedDate : formattedDateCompact}
        </span>
      </Tooltip>
      {variant === 'default' && (
        <IconButton
          size={VARIANT_ICON_BUTTON_SIZE[variant]}
          variant='muted'
          sentiment='neutral'
          aria-label='Copy attachment link'
          icon={DuplicateOutline}
          onClick={handleCopyLinkClick}
        />
      )}
      <PopoverTrigger
        placement='bottom-end'
        renderPopover={({ close }) => (
          <AttachmentActionList close={close} attachment={attachment} />
        )}
        onClose={handleActionMenuClose}
      >
        <div
          className={
            variant === 'compact' && !actionMenuOpen
              ? 'opacity-0 group-hover:opacity-100'
              : ''
          }
        >
          <IconButton
            size={VARIANT_ICON_BUTTON_SIZE[variant]}
            variant='muted'
            sentiment='neutral'
            aria-label='Open attachment actions'
            icon={DotsHorizontalSolid}
            onClick={handleActionMenuOpen}
          />
        </div>
      </PopoverTrigger>
    </ItemContainer>
  )
}

const ItemContainer = classed('div', {
  base: `
    group flex items-center p-2 gap-2 
    text-sm text-semantic-neutral-text-subtle
    rounded cursor-pointer

    hover:bg-semantic-neutral-surface-bg-subtlest
  `,
  variants: {
    variant: {
      default: ``,
      compact: `
        text-xs
      `,
    },
  },
})

const ItemLoader = classed(
  'div',
  {
    base: `
      animate-pulse

      after:content-[''] after:rounded after:bg-neutral-200 after:w-[200px] after:h-[16px]
      before:content-[''] before:rounded before:bg-neutral-200 before:size-[23px]
    `,
  },
  ItemContainer
)

const ItemError = classed(
  'div',
  {
    base: `
      !text-semantic-error-text-default
    `,
  },
  ItemContainer
)
