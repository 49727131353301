import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { useEmptyResultsIcon } from '~/global/hooks'

import { isSearchingTasks, isValidSearch, type SearchType } from '../utils'

interface EmptyResultsProps {
  searchQuery: string
  searchType: SearchType
  disableSearchTypeEvents: boolean
  onClickTasks: () => void
  onClickArchive: () => void
  onClickEvents: () => void
}

export const EmptyResults = (props: EmptyResultsProps) => {
  const {
    searchQuery,
    searchType,
    disableSearchTypeEvents,
    onClickTasks,
    onClickArchive,
    onClickEvents,
  } = props

  const Illustration = useEmptyResultsIcon()

  return (
    <div className='grid place-items-center h-full'>
      <div className='flex flex-col gap-4 items-center'>
        <Illustration className='w-32 h-32' />
        {isValidSearch(searchQuery) ? (
          <>
            <div className='flex flex-col gap-2 items-center'>
              <span className='text-semantic-neutral-text-default font-semibold text-center break-all'>
                {templateStr('No {{type}} for “{{ searchQuery }}”', {
                  type: isSearchingTasks(searchType) ? 'tasks' : 'events',
                  searchQuery,
                })}
              </span>
              <span className='text-semantic-neutral-text-subtle'>
                {isSearchingTasks(searchType)
                  ? searchType.archived
                    ? "The task you're looking for might not be archived"
                    : "The task you're looking for might be archived."
                  : 'Are you searching for a task?'}
              </span>
            </div>
            <div className='w-full flex flex-col gap-3 items-center'>
              {isSearchingTasks(searchType) ? (
                <>
                  <Button
                    sentiment='primary'
                    variant='muted'
                    onClick={
                      searchType.archived ? onClickTasks : onClickArchive
                    }
                  >
                    {searchType.archived
                      ? 'Search tasks'
                      : 'Search archived tasks'}
                  </Button>
                  {!disableSearchTypeEvents && (
                    <>
                      <div className='w-full h-px border-b border-semantic-neutral-border-subtle' />

                      <Button
                        sentiment='neutral'
                        variant='muted'
                        onClick={onClickEvents}
                      >
                        Search events instead
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <Button
                  sentiment='neutral'
                  variant='muted'
                  onClick={onClickTasks}
                >
                  Search tasks instead
                </Button>
              )}
            </div>
          </>
        ) : (
          <span className='text-semantic-neutral-text-subtle font-semibold'>
            {disableSearchTypeEvents
              ? 'Search for a task or project above'
              : 'Search for a task, project or event above'}
          </span>
        )}
      </div>
    </div>
  )
}
