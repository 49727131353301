import { CalendarEvent } from '@motion/ui/calendar'
import { getColorClassForColorId } from '@motion/ui-logic'

import { type EventContentArg } from '@fullcalendar/core'
import { selectMainCalendar } from '~/state/calendar-list/calendar-list-slice'
import { useAppSelector } from '~/state/hooks'

type Props = {
  event: EventContentArg
}

/**
 * Component for rendering a calendar event placeholder onto FullCalendar.
 * @param props
 * @constructor
 */
export const NewCalendarEvent = (props: Props) => {
  const mainCalendar = useAppSelector(selectMainCalendar)
  const { event: eventArg } = props
  const { event } = eventArg
  const { start, end } = event

  if (!start) return null
  return (
    <CalendarEvent
      colorId={getColorClassForColorId(mainCalendar?.colorId)}
      startTime={start}
      endTime={end ?? start}
      allDay={event.allDay}
      availability='free'
      isRecurring={false}
    />
  )
}
