import { PopoverContainer } from '@motion/ui/base'
import { focusFirstFocusableNode } from '@motion/ui/utils'

import { type Editor } from '@tiptap/core'
import { type KeyboardEventHandler, useEffect, useRef } from 'react'

import { type SuggestionItem } from './item'

import { FlowVariableFormSearchableList } from '../flow-variable-form-searchable-list'

interface MentionListProps {
  items: SuggestionItem[]
  command: ({ id, label }: { id: string; label: string }) => void
  close: () => void
  editor: Editor
}

export const SuggestionDropdown = ({
  items = [],
  command,
  close,
  editor,
}: MentionListProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (wrapperRef.current) {
        focusFirstFocusableNode(wrapperRef.current)
      }
    }, 0)
  }, [])

  function onClose(
    evt: React.KeyboardEvent<HTMLDivElement>,
    close: () => void,
    editor: Editor
  ) {
    evt.preventDefault()
    evt.stopPropagation()
    close()
    editor.commands.focus()
  }

  const handleKeys: KeyboardEventHandler<HTMLDivElement> = (evt) => {
    const target = evt.target as HTMLInputElement
    if (!wrapperRef.current?.contains(target)) return

    if (
      evt.key === 'Escape' ||
      (evt.key === 'Backspace' && target.value === '')
    ) {
      onClose(evt, close, editor)
    }
  }

  return (
    <PopoverContainer ref={wrapperRef} onKeyDown={handleKeys}>
      <FlowVariableFormSearchableList
        items={items.map((item) => ({
          id: item.key,
          name: item.label,
          color: 'blue' as const,
          key: item.key,
          type: 'text',
        }))}
        onChange={(item) => {
          if (item) {
            command({ id: item.key, label: item.name })
          }
        }}
      />
    </PopoverContainer>
  )
}
