import {
  type FolderSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { ConnectedFolderLabel } from './folder'
import { ConnectedProjectLabel } from './project'
import { ConnectedWorkspaceLabel } from './workspace'

export type BreadWrapperPiece =
  | {
      type: 'workspace'
      value: WorkspaceSchema['id']
    }
  | {
      type: 'project'
      value: ProjectSchema['id']
    }
  | {
      type: 'folder'
      value?: FolderSchema['id']
    }
  | {
      type: 'text'
      value: string
    }

export type BreadcrumbsLabelProps = {
  crumbs: BreadWrapperPiece[]
}

export function BreadcrumbsLabel({ crumbs }: BreadcrumbsLabelProps) {
  return (
    <Wrapper>
      {crumbs.map(({ type, value }, index) => {
        let label

        switch (type) {
          case 'workspace': {
            label = <ConnectedWorkspaceLabel id={value} />
            break
          }
          case 'project': {
            label = <ConnectedProjectLabel id={value} />
            break
          }
          case 'folder': {
            label = <ConnectedFolderLabel id={value} />
            break
          }
          case 'text': {
            label = value
            break
          }
          default:
            return null
        }

        return (
          <Crumb
            key={`${type}-${value}`}
            withSeparator={index < crumbs.length - 1}
          >
            {label}
          </Crumb>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = classed('li', {
  base: `
    inline-flex items-center gap-1
  `,
})

const Crumb = classed('ul', {
  base: `
    inline-flex
  `,
  variants: {
    withSeparator: {
      true: `
        gap-1
        after:content-['/']
      `,
      false: '',
    },
  },
})
