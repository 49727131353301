import { useMemo } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'

const SetupProjectTabOptions = [
  'name',
  'dates',
  'roles',
  'textVariables',
] as const

export type SetupProjectTabOption = (typeof SetupProjectTabOptions)[number]

export function useTabOptions() {
  const {
    form: { getValues },
  } = useSetupProjectForm()
  const { roleAssignees, textReplacements } = getValues()

  return useMemo(
    () =>
      SetupProjectTabOptions.filter((option) => {
        if (option === 'roles') {
          return roleAssignees.length > 0
        }
        if (option === 'textVariables') {
          return textReplacements.length > 0
        }

        return true
      }),
    [roleAssignees, textReplacements]
  )
}
