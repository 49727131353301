import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { type VariableDefinitionSchema } from '@motion/zod/client'

import { type Node } from '@tiptap/pm/model'
import { NodeViewWrapper } from '@tiptap/react'
import { VariableLabel } from '~/global/components/labels'

import { FlowVariableFormDropdown } from './flow-variable-form-dropdown'

import { useTaskNameVariableUpdater } from '../../../../fields'
import { useFlowTemplateForm } from '../../../../hooks'
import { createNewTextVariable } from '../../../../utils'

export const NodeVariableLabel = ({ node: { attrs } }: { node: Node }) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const updater = useTaskNameVariableUpdater()

  const stages = watch('stages')
  const textVariables = watch('textVariables')

  const { id, taskId } = attrs
  if (id == null || taskId == null) {
    return null
  }

  const idWithPlaceholder = createPlaceholderId(taskId)
  const stageIndex = stages.findIndex((s) =>
    s.tasks.some((t) => t.id === taskId || t.id === idWithPlaceholder)
  )
  if (stageIndex === -1) {
    return null
  }

  const taskIndex = stages[stageIndex].tasks.findIndex(
    (t) => t.id === taskId || t.id === idWithPlaceholder
  )
  if (taskIndex === -1) {
    return null
  }

  const variable: FlowTemplateFormFields['textVariables'][number] =
    textVariables.find((v) => v.key === id) ?? createNewTextVariable('Unknown')

  const replaceHandler = (item: VariableDefinitionSchema) => {
    const wrappedKey = wrapVariableInDelimiters(variable.key)
    const replacementKey = wrapVariableInDelimiters(item.key)
    updater({
      wrappedKey,
      replacementKey,
      operation: 'replace',
      stageIndex,
      taskIndex,
    })
  }

  return (
    <FlowVariableFormDropdown
      items={textVariables}
      onChange={replaceHandler}
      selectedItem={variable}
    >
      <NodeViewWrapper className='inline-flex'>
        <button>
          <VariableLabel value={variable} />
        </button>
      </NodeViewWrapper>
    </FlowVariableFormDropdown>
  )
}
