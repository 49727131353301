import { isDateBetweenDates, safeParseDate } from '@motion/utils/dates'
import { HasExperiment } from '@motion/web-common/flags'
import { type ProjectSchema } from '@motion/zod/client'

import { useStageDefinition } from '~/global/hooks'
import { DateTime } from 'luxon'
import { type FC } from 'react'

import { StageConnector } from './stage-connector'
import { StageTimelineItem } from './stage-timeline-item'
import { DeprecatedStageConnector } from './styled'
import { TodayMarker } from './today-marker'
import { type StageGroup } from './utils'

type StageTimelineSegmentProps = {
  stageGroup: StageGroup
  project: ProjectSchema
  prevGroup: StageGroup | undefined
  nextGroup: StageGroup | undefined
  isLastGroup: boolean
}

export const StageTimelineSegment: FC<StageTimelineSegmentProps> = ({
  stageGroup,
  project,
  prevGroup,
  nextGroup,
  isLastGroup,
}) => {
  const today = DateTime.now().startOf('day')

  const stageDate = safeParseDate(stageGroup.dueDateKey)
  const prevStageDate =
    prevGroup != null ? safeParseDate(prevGroup.dueDateKey) : null
  const nextStageDate =
    nextGroup != null ? safeParseDate(nextGroup.dueDateKey) : null

  const progress = nextGroup?.stages[0].completion ?? 0
  const stageDefinitionId = nextGroup?.stages[0]?.stageDefinitionId ?? ''
  const stageDefinition = useStageDefinition(stageDefinitionId)

  const isTodayDirectlyBeforeThisStage = isDateBetweenDates(
    prevStageDate?.plus({ days: 1 }) ?? today,
    stageDate,
    today,
    { inclusive: true }
  )

  const isTodayDirectlyAfterThisStage = isDateBetweenDates(
    stageDate,
    nextStageDate,
    today,
    { inclusive: false }
  )

  return (
    <>
      {isTodayDirectlyBeforeThisStage && (
        <TodayMarker stageGroup={stageGroup} />
      )}

      <StageTimelineItem stageGroup={stageGroup} project={project} />

      {!isLastGroup && (
        <HasExperiment
          fallback={
            <DeprecatedStageConnector half={isTodayDirectlyAfterThisStage} />
          }
          name='flows-better-resize-stages'
        >
          <StageConnector
            color={stageDefinition.color}
            half={isTodayDirectlyAfterThisStage}
            progress={isTodayDirectlyAfterThisStage ? progress * 2 : progress}
          />
        </HasExperiment>
      )}
    </>
  )
}
