import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useCloseAttachmentModal = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(() => {
    if (!searchParams.has('attachment')) return

    setSearchParams((prev) => {
      prev.delete('attachment')
      return prev
    })
  }, [searchParams, setSearchParams])
}
