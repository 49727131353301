import { FastForwardSolid, RewindSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import { getStageTense } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useMoveProjectToStage } from '~/areas/project/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'

export type GoToStageButtonProps = {
  project: ProjectSchema
  stageDefinition: StageDefinitionSchema
}

export function GoToStageButton({
  project,
  stageDefinition,
}: GoToStageButtonProps) {
  const context = useRouteAnalyticsMetadata()
  const moveProjectToStage = useMoveProjectToStage()
  const stageTense = getStageTense(project, stageDefinition.id)

  return (
    <Tooltip
      asChild
      content={stageTense === 'past' ? 'Go back to stage' : 'Advance to stage'}
    >
      <IconButton
        icon={stageTense === 'past' ? RewindSolid : FastForwardSolid}
        size='xsmall'
        variant='muted'
        sentiment='neutral'
        onClick={() => {
          recordAnalyticsEvent('TASK_SIDEBAR_MOVE_STAGE', {
            ...context,
            stageTense,
          })
          moveProjectToStage(project, stageDefinition.id)
        }}
      />
    </Tooltip>
  )
}
