import { BriefcaseSolid } from '@motion/icons'
import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useEventFormOptions } from '../event-form-options-context'
import { useEventFormStatusField } from '../hooks'

const options = ['FREE', 'BUSY']
const optionLabels: Record<(typeof options)[number], string> = {
  FREE: 'Free',
  BUSY: 'Busy',
}
export const EventFormStatusField = () => {
  const { isReadOnly } = useEventFormOptions()
  const { field, onChange } = useEventFormStatusField()
  return (
    <EventFormRowContainer className='px-2 mb-2'>
      <EventFormRowIconContainer>
        <BriefcaseSolid />
      </EventFormRowIconContainer>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            items={options}
            renderItem={(item) => optionLabels[item]}
            computeKey={(item) => item}
            computeSelected={(item) => item === field.value}
            onSelect={(item) => {
              onChange(item)
              close()
            }}
          />
        )}
      >
        <FieldButton
          size='small'
          variant='muted'
          fullWidth
          readOnly={isReadOnly}
        >
          {optionLabels[field.value] ?? 'Add status'}
        </FieldButton>
      </PopoverTrigger>
    </EventFormRowContainer>
  )
}
