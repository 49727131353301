import { logEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { useAutoScheduleSettings } from '~/global/hooks'
import { useUpdateMyAutoScheduleSettingsMutation } from '~/global/rpc/v2'

import { Events } from '../../../../../analyticsEvents'
import { useInNoExternalCalendarsMode } from '../../../../../hooks/use-in-no-external-calendars-mode'
import { selectMainEmailAccount } from '../../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../../state/hooks'
import {
  RadioGroup,
  type RadioGroupOption,
} from '../../../../Common/RadioGroup/RadioGroup'

const DoNotTimeBlockOption: RadioGroupOption = {
  label: 'Only show tasks on Motion Calendar',
  value: 'no-events',
}

const TimeBlockWithFreeEventsOption: RadioGroupOption = {
  label:
    'Show Motion on your external calendar (Main Calendar Email), but keep tasks as free',
  value: 'free',
}

const DefaultTimeBlockOption: RadioGroupOption = {
  label:
    'Show Motion on your external calendar (Main Calendar Email); tasks that are at risk of missing deadline are marked as busy',
  value: 'default',
}

const AllOptions: RadioGroupOption[] = [
  DoNotTimeBlockOption,
  TimeBlockWithFreeEventsOption,
  DefaultTimeBlockOption,
]

export const TimeBlockSettings = () => {
  const selectedValue = useAutoScheduleSettings().taskTimeBlockMode
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const isIcloudFullTimeBlockingOptions = useHasTreatment(
    'icloud-full-time-blocking-options'
  )
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const { mutate: updateAutoScheduleSettings } =
    useUpdateMyAutoScheduleSettingsMutation()

  const handleChange = async (value: string) => {
    switch (value) {
      case 'default':
        void logEvent(Events.TIME_BLOCK_SETTINGS_SET_DEFAULT)
        break
      case 'free':
        void logEvent(Events.TIME_BLOCK_SETTINGS_SET_FREE)
        break
      case 'no-events':
        void logEvent(Events.TIME_BLOCK_SETTINGS_SET_NO_EVENTS)
        break
      default:
        return
    }

    void updateAutoScheduleSettings({ taskTimeBlockMode: value })
  }

  const availableOptions =
    noExternalCalendarsMode ||
    (mainEmailAccount?.providerType === 'APPLE' &&
      !isIcloudFullTimeBlockingOptions)
      ? [DoNotTimeBlockOption]
      : AllOptions

  return (
    <div className='flex flex-col gap-3'>
      <p className='pb-1 text-base font-semibold'>
        Show Motion tasks on your external main calendar (Google, Outlook or
        iCloud)
      </p>
      {mainEmailAccount?.providerType === 'APPLE' && (
        <p className='text-sm text-semantic-neutral-text-subtle'>
          Motion does not support showing tasks on iCloud calendar yet.
        </p>
      )}
      <RadioGroup
        options={availableOptions}
        selectedValue={selectedValue}
        onChange={handleChange}
        borderless
        optionClassName='pl-0'
      />
    </div>
  )
}
