import { CalendarSolid } from '@motion/icons'
import { formatDateTime, templateStr } from '@motion/ui-logic'

import { ActivityValue } from '../components'

export function handleScheduledDate(
  oldDate: string | null,
  newDate: string | null,
  type: 'start' | 'end'
) {
  if (oldDate == null || newDate == null) {
    return null
  }

  return {
    icon: <CalendarSolid />,
    children: templateStr(
      "rescheduled the event's {{type}} from {{oldDate}} to {{newDate}}.",
      {
        type: type === 'start' ? 'start date' : 'end date',
        oldDate: <ActivityValue>{formatDateTime(oldDate, ', ')}</ActivityValue>,
        newDate: <ActivityValue>{formatDateTime(newDate, ', ')}</ActivityValue>,
      }
    ),
  }
}
