import { API, useQuery } from '@motion/rpc'
import { Modal } from '@motion/ui/base'
import { logInDev } from '@motion/web-base/logging'
import { useMyUserSettings } from '@motion/web-common/settings'

import { useAppSelector } from '~/state/hooks'
import { selectUser } from '~/state/userSlice'
import { useState } from 'react'

import { BackLabel } from './common-labels'
import { type DevToolsPages, type ItemsHookReturn } from './types'

export const useFirebaseSettingsItems = (
  setCurrentPage: (page: DevToolsPages) => void
): ItemsHookReturn => {
  const [copied, setCopied] = useState(false)
  const user = useAppSelector(selectUser)

  const { data: firestoreSettings } = useQuery(
    API.userSettings.getFirestoreSettings,
    undefined
  )

  const { data: postgresCtaSettings } = useMyUserSettings()

  const [modalOpen, setModalOpen] = useState<'cta' | 'user' | null>(null)

  const modalContent =
    modalOpen != null
      ? modalOpen === 'cta'
        ? postgresCtaSettings?.ctas
        : modalOpen === 'user'
          ? firestoreSettings
          : null
      : null
  if (modalContent) {
    logInDev('[devtools]:', modalContent)
  }
  const goToUserFirebaseSettings = () => {
    window.open(
      `https://console.firebase.google.com/u/0/project/motion-dev-366719/firestore/databases/-default-/data/~2Fsettings~2F${user.id}`
    )
  }
  const copyUserId = async () => {
    setCopied(true)
    try {
      await navigator.clipboard.writeText(user.id)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    } catch (err) {
      setCopied(false)
    }
  }

  return [
    [
      {
        onSelect: () => {
          setCurrentPage('main')
        },
        label: <BackLabel />,
        key: 'Back',
        clearSearch: true,
      },
      {
        onSelect: () => {
          copyUserId()
        },
        label: `Copy user id ${user.id} ${
          copied ? '(Copied to clipboard)' : ''
        }`,
        key: 'Copy user id',
      },
      {
        onSelect: () => {
          goToUserFirebaseSettings()
        },
        label: 'Go to user Firebase settings',
        key: 'Go to user Firebase settings',
      },
      {
        onSelect: () => {
          setModalOpen('cta')
        },
        label: 'View CTA settings',
        key: 'View CTA settings',
      },
      {
        onSelect: async () => {
          setModalOpen('user')
        },
        label: 'View and log User settings',
        key: 'View and log User settings',
      },
    ],
    <Modal
      key='firebase-settings'
      visible={Boolean(modalContent) && modalOpen != null}
      onClose={() => setModalOpen(null)}
    >
      <div className='whitespace-pre p-8 font-mono'>
        {JSON.stringify(modalContent, undefined, 2)}
      </div>
    </Modal>,
  ]
}
