import { getCacheEntry } from '@motion/rpc-cache'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { fuzzySearch } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'

import { useQueryClient } from '@tanstack/react-query'

type UseFilterAttachmentsParams = {
  query?: string
  attachmentIds: UploadedFileSchema['id'][]
}

export function useFilterAttachments({
  query,
  attachmentIds,
}: UseFilterAttachmentsParams) {
  const queryClient = useQueryClient()

  const attachments: UploadedFileSchema[] = attachmentIds
    .map((id) => {
      const cachedFile = getCacheEntry(queryClient, 'uploadedFiles', id)

      // The file should always be in the cache, but if it's not, log an error
      if (!cachedFile) {
        Sentry.captureMessage('Attachment not found in cache', {
          extra: { attachmentId: id },
        })
        return
      }

      return cachedFile.value
    })
    .filter(Boolean)

  const filteredAttachments = !query
    ? attachments
    : fuzzySearch({
        query,
        items: attachments,
        keys: ['fileName'],
      })

  const filteredAttachmentIds = filteredAttachments.map(({ id }) => id)

  return {
    filteredAttachments,
    filteredAttachmentIds,
  }
}
