import { Button } from '@motion/ui/base'
import { RichTextField } from '@motion/ui/forms'
import { RichTextContent, RichTextImageBubbleMenu } from '@motion/ui/rte'

import { useRef } from 'react'

import { useCommentBoxRte } from '../../hooks'
import { type CommentSubmitPayload } from '../../types'

type SlimCommentBoxProps = {
  onSubmit: (comment: CommentSubmitPayload) => void
  onChange?: (value: string) => void
}

export const SlimCommentBox = ({ onSubmit, onChange }: SlimCommentBoxProps) => {
  const richTextFieldRef = useRef<HTMLSpanElement>(null)

  const { editor, handleSubmit } = useCommentBoxRte({
    commentBoxRef: richTextFieldRef,
    onSubmit,
    onChange,
    isSlim: true,
  })

  return (
    <>
      <RichTextImageBubbleMenu editor={editor} />

      <RichTextField
        ref={richTextFieldRef}
        editor={editor}
        suffix={({ active }) => (
          <Button
            onClick={() => editor && handleSubmit(editor)}
            shortcut='mod+enter'
            size='small'
            disabled={editor?.isEmpty}
            variant={active ? 'solid' : 'muted'}
          >
            {active && 'Send'}
          </Button>
        )}
      >
        <RichTextContent editor={editor} />
      </RichTextField>
    </>
  )
}
