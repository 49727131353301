import { classed } from '@motion/theme'
import { PopoverButton, Toggle, type ToggleProps } from '@motion/ui/base'
import {
  calendarStartDayLabels,
  calendarStartDayOptions,
} from '@motion/ui-logic/calendar'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  useCompletedTasksDisplaySetting,
  useShowProjectedEntities,
} from '~/areas/calendar/hooks'
import { BaseDropdown, type BaseDropdownProps } from '~/global/components'
import { type ReactNode } from 'react'

import api from '../../../../../chromeApi/chromeApiBackground'
import { useInNoExternalCalendarsMode } from '../../../../../hooks/use-in-no-external-calendars-mode'
import { persistSettings } from '../../../../../localServices/firebase/web'
import { selectCalendarStartDay } from '../../../../../state/calendar/calendarSlice'
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks'
import {
  selectShowAgendaEvents,
  setShowEventsInAgenda,
} from '../../../../../state/mainSlice'

export const CalendarSettings = () => {
  const dispatch = useAppDispatch()
  const calendarStartDay = useAppSelector(selectCalendarStartDay)
  const [showCompletedTasks, onChangeShowCompletedTasks] =
    useCompletedTasksDisplaySetting()
  const agendaEventsShown = useAppSelector(selectShowAgendaEvents)
  const [showProjectedTasks, onChangeShowProjectedTasks] =
    useShowProjectedEntities()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const hasRemoveAgendaSidebar = useHasTreatment('remove-agenda-sidebar')

  const onChangeStartDay: BaseDropdownProps<
    (typeof calendarStartDayOptions)[number]
  >['onChange'] = async (value) => {
    await api.storage.local.set({ calendarStartDay: value })
  }

  const onChangeEventsAgenda: ToggleProps['onChange'] = (e) => {
    const checked = e.currentTarget.checked

    if (checked) {
      recordAnalyticsEvent('CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_ON')
    } else {
      recordAnalyticsEvent('CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_OFF')
    }

    dispatch(setShowEventsInAgenda(checked))
    // To save the setting to firebase. Should be cleaned up.

    void persistSettings({ showAgendaEvents: checked })
  }

  return (
    <Section title='Calendar'>
      <SettingGrid>
        <SettingRow label='Start week on'>
          <BaseDropdown
            items={calendarStartDayOptions}
            renderItem={(item) => calendarStartDayLabels[item]}
            onChange={onChangeStartDay}
          >
            <PopoverButton>
              {calendarStartDayLabels[calendarStartDay]}
            </PopoverButton>
          </BaseDropdown>
        </SettingRow>

        <SettingRow label='Show completed tasks'>
          <Toggle
            checked={showCompletedTasks}
            onChange={onChangeShowCompletedTasks}
          />
        </SettingRow>

        {!hasRemoveAgendaSidebar && !noExternalCalendarsMode && (
          <SettingRow label='Show events in Agenda'>
            <Toggle
              checked={agendaEventsShown}
              onChange={onChangeEventsAgenda}
            />
          </SettingRow>
        )}

        <SettingRow label='Hide tasks in future stages'>
          <Toggle
            checked={!showProjectedTasks}
            onChange={onChangeShowProjectedTasks}
          />
        </SettingRow>
      </SettingGrid>
    </Section>
  )
}

interface SectionProps {
  title: ReactNode
  children: ReactNode
}
function Section({ title, children }: SectionProps) {
  return (
    <div className='p-4'>
      <h4 className='text-semantic-neutral-text-default text-sm font-medium'>
        {title}
      </h4>
      {children}
    </div>
  )
}

const SettingGrid = classed(
  'div',
  'grid grid-cols-[1fr_auto] gap-y-4 items-center'
)

type SettingRowProps = {
  label: string
  children: ReactNode
}
function SettingRow({ label, children }: SettingRowProps) {
  return (
    <>
      <div className='text-sm text-semantic-neutral-text-subtle'>{label}</div>
      <div className='justify-self-end'>{children}</div>
    </>
  )
}
