import {
  BlockersField,
  type BlockersFieldProps,
} from '~/areas/task-project/fields'

import { useEventModalState } from '../contexts'

export type ControlledBlockersFieldProps = {
  type: BlockersFieldProps['type']
}

export const ControlledEventBlockersField = ({
  type,
}: ControlledBlockersFieldProps) => {
  const modalState = useEventModalState()

  if (modalState.isLoading || modalState.initialMeetingTask == null) return null

  const { initialMeetingTask } = modalState

  const taskId = initialMeetingTask.id
  const blockerIds =
    initialMeetingTask[
      type === 'blocks' ? 'blockingTaskIds' : 'blockedByTaskIds'
    ]

  return <BlockersField type={type} taskId={taskId} blockerIds={blockerIds} />
}
