import { RefreshSolid } from '@motion/icons'
import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  getDefaultCustomRecurrenceString,
  getRecurrenceRulesForDate,
  handleRecurrenceChange,
  recurrenceRuleToText,
} from '@motion/ui-logic'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import { useController } from 'react-hook-form'

import { CustomRecurrencePanel } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventRecurrenceField = () => {
  const { isLoading, isReadOnly } = useEventModalState()
  const { form, hiddenFields } = useEventForm()
  const { control, watch } = form

  const eventStart = watch('start')

  const { field } = useController({
    name: 'recurrence',
    control,
  })

  if (isLoading || hiddenFields.has('recurrence')) return null

  const onChange = async (value: CalendarEventSchemaV2['recurrence']) => {
    const { colorId, recurrence } = handleRecurrenceChange(
      value,
      form.getValues()
    )
    form.setValue('recurrence', recurrence, { shouldDirty: true })
    form.setValue('colorId', colorId, { shouldDirty: true })
  }

  const recurrenceText = field.value ? recurrenceRuleToText(field.value) : null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <RecurrenceDropdownContent
          close={close}
          eventStart={eventStart}
          selectedRecurrence={field.value}
          onChange={onChange}
        />
      )}
    >
      <Button
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        disabled={isReadOnly}
      >
        <RefreshSolid />
        {recurrenceText != null ? recurrenceText : 'Does not repeat'}
      </Button>
    </PopoverTrigger>
  )
}

type RecurrenceDropdownContentProps = {
  close: () => void
  eventStart: CalendarEventSchemaV2['start']
  selectedRecurrence: CalendarEventSchemaV2['recurrence']
  onChange: (value: CalendarEventSchemaV2['recurrence']) => void
}

function RecurrenceDropdownContent({
  close,
  eventStart,
  selectedRecurrence,
  onChange,
}: RecurrenceDropdownContentProps) {
  const recurrenceRules = getRecurrenceRulesForDate(eventStart)
  const calendarStartDay = useCalendarStartDay()

  const defaultRecurrenceOptions = recurrenceRules.map((rule) => {
    return {
      value: rule.toString(),
      label: recurrenceRuleToText(rule.toString()),
    }
  })

  const recurrenceOptions = [
    { label: 'Does not repeat', value: null },
    ...defaultRecurrenceOptions,
    { label: 'Custom', value: 'custom' },
  ]

  const isCustomOptionSelected =
    selectedRecurrence != null &&
    !defaultRecurrenceOptions.some((o) => o.value === selectedRecurrence)
  const defaultCustomRecurrence = getDefaultCustomRecurrenceString(eventStart)

  return (
    <div className='flex'>
      <SearchableList
        items={recurrenceOptions}
        renderItem={(item) => item.label}
        computeKey={(item) => item.label}
        computeSelected={(item) =>
          item.value === selectedRecurrence ||
          (item.value === 'custom' && isCustomOptionSelected)
        }
        onSelect={(item) => {
          if (item.value === 'custom') {
            if (!isCustomOptionSelected) {
              onChange(defaultCustomRecurrence)
            }
            return
          }
          onChange(item.value)
          close()
        }}
      />
      {isCustomOptionSelected && (
        <CustomRecurrencePanel
          refDate={eventStart}
          initialValue={selectedRecurrence}
          calendarStartDay={calendarStartDay}
          onChange={onChange}
        />
      )}
    </div>
  )
}
