import { useModalApi } from '@motion/web-common/modals'

import { useIsAdmin, useIsIapSubscription } from '~/global/hooks'
import { useIsTeamExpired } from '~/global/hooks/team'
import { type SettingPageOptions } from '~/pages/settings/types'
import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { SettingsSidebar } from './SettingsSidebar'

import { routeFrom, useSearchParams } from '../../../routing'

export const ConnectedSettingsSidebar = () => {
  const modalApi = useModalApi()
  const navigate = useNavigate()
  const params = useParams()
  const team = useAppSelector(selectTeam)
  const isTeamExpired = useIsTeamExpired()
  const isAdmin = useIsAdmin()
  const isValidTeam = !!team?.id && (!isTeamExpired || isAdmin)
  const isIapSubscription = useIsIapSubscription()

  const query = useSearchParams<{
    workspaceId?: string
  }>()

  const location = useLocation()
  const routeUri = new URL(routeFrom(location), window.location.origin)
  const routeMatch = matchPath(
    { path: '/settings/:page', end: false },
    routeUri.pathname
  )

  let page = (routeMatch?.params.page ?? 'calendar') as SettingPageOptions

  if (page === 'team') {
    const nestedRouteMatch = matchPath(
      { path: '/settings/team/:page', end: false },
      routeUri.pathname
    )
    if (nestedRouteMatch?.params.page) {
      page = ('team/' + nestedRouteMatch?.params.page) as SettingPageOptions
    }
  }

  return (
    <SettingsSidebar
      navigate={navigate}
      settingsPage={page}
      selectedWorkspaceId={query.workspaceId ?? params.workspaceId}
      showTeamUpsell={!isValidTeam && !isIapSubscription}
      onCreateWorkspace={() =>
        modalApi.prompt('add-workspace', {
          onValue: (workspaceId) => {
            return navigate(`/web/settings/workspace/${workspaceId}/overview`)
          },
        })
      }
    />
  )
}
