import { FieldTypes } from '@motion/shared/custom-fields'
import { type Section } from '@motion/ui/base'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'
import { byProperty, cascade, Compare, ordered } from '@motion/utils/array'

import { useMemo } from 'react'

import { type FieldSelectProps } from './types'

export function useGroupBySections<T extends { id: string; label: string }>({
  items,
}: {
  items: FieldSelectProps<T>['available']
}) {
  return useMemo(() => {
    const groups = items.reduce<Section<T>[]>(
      (acc, item) => {
        if (
          item.id === 'none' ||
          !FieldTypes.includes(getPrefixFromMaybeCustomFieldKey(item.id) ?? '')
        ) {
          acc[0].items.push(item)
        } else {
          acc[1].items.push(item)
        }

        return acc
      },
      [
        { key: 'fields', label: 'Fields', items: [], initialExpanded: true },
        {
          key: 'custom-fields',
          label: 'Custom fields',
          items: [],
          initialExpanded: true,
        },
      ]
    )

    groups.forEach((g) => {
      g.items.sort(
        cascade(
          byProperty('id', ordered(['none'])),
          byProperty('label', Compare.string)
        )
      )
    })

    if (groups[1].items.length === 0) {
      delete groups[1]
    }

    return groups
  }, [items])
}
