import { PMTeamMemberRole } from '@motion/rpc/types'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useTeamMembers } from './team'

export const useIsAdmin = () => {
  const { uid: userId } = useAuthenticatedUser()

  const teamMembers = useTeamMembers()

  return (
    teamMembers.find((teamMember) => teamMember.userId === userId)?.role ===
    PMTeamMemberRole.ADMIN
  )
}
