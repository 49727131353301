import { isFlowProject as isFlowProjectFn } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useIsFlowsM4Enabled } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import {
  useCompleteProject as useCompleteProjectMutation,
  useReadTasksFn,
  useResolveProject,
} from '~/global/rpc/v2'
import { useCallback } from 'react'

/**
 * Hook to update a project's status as complete.
 * This hook will prompt the user to confirm the action regarding its tasks' statuses before proceeding.
 * @returns a function that initiates the process of marking a project as complete
 */
export function useCompleteProject() {
  const { mutateAsync: completeProject } = useCompleteProjectMutation()
  /**
   * After Flows M4 fully rolled out, use only this.
   */
  const { mutateAsync: resolveProject } = useResolveProject()

  const modalApi = useModalApi()
  const {
    getWorkspaceProjectById,
    getStageDefinition,
    getWorkspaceCompletedStatus,
  } = useWorkspaceFns()
  const readTasks = useReadTasksFn()

  const isFlowsM4Enabled = useIsFlowsM4Enabled()

  return useCallback(
    /**
     * @param projectId
     * @returns {(Promise<boolean>)} Whether the user confirmed the action or not
     */
    async (projectId: string) => {
      const project = getWorkspaceProjectById(projectId)
      const isFlowProject = isFlowProjectFn(project)

      if (!project) {
        throw new Error(`Project with id ${projectId} not found`)
      }

      const incompleteTasks = await readTasks({
        filters: [
          {
            workspaceIds: [project.workspaceId],
            projectIds: {
              operator: 'in',
              value: [project.id],
            },
            completed: 'exclude',
          },
        ],
      })

      const completedStatusId = getWorkspaceCompletedStatus(
        project.workspaceId
      ).id

      if (incompleteTasks.length === 0) {
        await completeProject({
          projectId,
          statusId: completedStatusId,
          complete: 'none',
        })
        return true
      }

      const stage = isFlowProject
        ? getStageDefinition(project.activeStageDefinitionId)
        : undefined

      const hasStagesRemaining = project.stages.some(
        (s) => !s.canceledTime && !s.completedTime
      )

      try {
        if (isFlowsM4Enabled && isFlowProject) {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_RESOLVE_PROJECT_MODAL', {
            state: 'open',
            type: 'complete',
          })

          if (!hasStagesRemaining) {
            await resolveProject({
              projectId,
              statusId: completedStatusId,
              status: 'completed',
              tasks: 'noop',
            })
            return true
          }

          const modalResponse = await modalApi.prompt('resolve-project', {
            type: 'complete',
            onDismiss: () =>
              recordAnalyticsEvent('PROJECT_MANAGEMENT_RESOLVE_PROJECT_MODAL', {
                state: 'dismissed',
                type: 'complete',
              }),
          })

          if (modalResponse === ModalDismissed) {
            return false
          }

          await resolveProject({
            projectId,
            statusId: completedStatusId,
            status: 'completed',
            tasks: modalResponse,
          })
        } else {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_COMPLETE_PROJECT_MODAL', {
            state: 'open',
            isFlowProject,
          })

          if (!hasStagesRemaining) {
            await completeProject({
              projectId,
              statusId: completedStatusId,
              complete: 'none',
            })
            return true
          }

          const modalResponse = await modalApi.prompt('complete-project', {
            stage,
            onDismiss: () =>
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_COMPLETE_PROJECT_MODAL',
                {
                  state: 'dismissed',
                  isFlowProject,
                }
              ),
          })

          if (modalResponse === ModalDismissed) {
            return false
          }

          await completeProject({
            projectId,
            statusId: completedStatusId,
            complete: modalResponse,
          })
        }
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            position: 'useCompleteProject',
          },
        })
        return false
      }

      return true
    },
    [
      completeProject,
      getStageDefinition,
      getWorkspaceCompletedStatus,
      getWorkspaceProjectById,
      isFlowsM4Enabled,
      modalApi,
      readTasks,
      resolveProject,
    ]
  )
}
