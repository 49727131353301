import { type Group } from '@motion/ui/base'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import { byValue, Compare, ordered } from '@motion/utils/array'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  useCompletedTasksDisplaySetting,
  useShowProjectedEntities,
} from '~/areas/calendar/hooks'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import {
  type AgendaTreeCompletedTasksItem,
  type AgendaTreeEmptyDateItem,
  type AgendaTreeFeedbackItem,
  type AgendaTreeItem,
  type AgendaTreeLoadingItem,
  type AgendaTreeTaskItem,
} from './types'

type UseAgendaTreeProps<T> = {
  tasksByDate: Group<T>[]
  isLoading?: boolean
}

export function useAgendaTree<T extends ScheduledTaskWithRelation>({
  tasksByDate,
  isLoading,
}: UseAgendaTreeProps<T>) {
  const showAgendaSchedulingFeedback = useHasTreatment(
    'agenda-scheduling-feedback'
  )

  const [showCompletedTasks] = useCompletedTasksDisplaySetting()
  const [showProjectedEntities] = useShowProjectedEntities()

  const now = DateTime.local()

  return useMemo(() => {
    const agendaItems: AgendaTreeItem[] = tasksByDate
      .sort(byValue((item) => item.key, Compare.string))
      .map(({ key: isoDate, items: tasks }) => {
        const date = DateTime.fromISO(isoDate)
        const isToday = date.hasSame(now, 'day')

        const tasksToShow = showProjectedEntities
          ? tasks
          : tasks.filter((task) => !isGhostTask(task.task))

        let taskItems: AgendaTreeTaskItem[] = tasksToShow.map((task) => ({
          id: task.id,
          label: task.task?.name ?? 'Task',
          date,
          task,
          type: 'TASK',
          isContainer: false,
          expanded: false,
          children: [],
          parentId: isoDate,
          url: `/tasks/${task.id}`,
          projectId: task.task?.projectId,
        }))

        // move completed tasks to the bottom
        taskItems.sort(byValue((item) => item.task.completed, ordered([false])))
        const completedTasks = taskItems.filter((item) => item.task.completed)

        const addTaskItem: AgendaTreeItem = {
          id: `${isoDate}-add-task`,
          label: 'Add task',
          date,
          isContainer: false,
          type: 'ADD_TASK',
          expanded: false,
          children: [],
          parentId: isoDate,
          url: '',
          disableDrag: true,
        }

        const emptyDateItem: AgendaTreeEmptyDateItem = {
          id: `${isoDate}-empty`,
          date,
          label: 'No tasks',
          isContainer: false,
          type: 'EMPTY_DATE',
          numTasks: 0,
          expanded: false,
          children: [],
          parentId: isoDate,
          url: '',
          disableDrag: true,
        }

        const feedbackItem: AgendaTreeFeedbackItem = {
          id: `${isoDate}-feedback`,
          date,
          label: 'Feedback',
          isContainer: false,
          type: 'FEEDBACK',
          expanded: false,
          children: [],
          parentId: isoDate,
          url: '',
          disableDrag: true,
        }

        const loadingItem: AgendaTreeLoadingItem = {
          id: `${isoDate}-loading`,
          date,
          label: 'Loading',
          isContainer: false,
          type: 'LOADING',
          expanded: false,
          children: [],
          parentId: isoDate,
          url: '',
          disableDrag: true,
        }

        const completedTasksItem: AgendaTreeCompletedTasksItem = {
          id: `${isoDate}-completed-tasks`,
          date,
          label: 'Completed tasks',
          numTasks: taskItems.length,
          numCompletedTasks: completedTasks.length,
          type: 'COMPLETED_TASKS',
          expanded: false,
          children: [],
          parentId: isoDate,
          url: '',
          disableDrag: true,
        }

        const children: AgendaTreeItem[] = []
        if (showCompletedTasks) {
          children.push(...taskItems)
        } else {
          children.push(...taskItems.filter((item) => !item.task.completed))
          if (completedTasks.length > 0) {
            children.push(completedTasksItem)
          }
        }

        if (taskItems.length === 0) {
          if (isLoading) {
            children.push(loadingItem)
          } else {
            children.push(emptyDateItem)
          }
        }

        if (showAgendaSchedulingFeedback && isToday && taskItems.length > 0) {
          children.push(feedbackItem)
        }

        children.push(addTaskItem)

        return {
          id: isoDate,
          label: isoDate,
          date,
          isContainer: true,
          type: 'DATE',
          children,
          expanded: true,
          url: '',
          disableDrag: true,
        }
      })
    return agendaItems
  }, [
    tasksByDate,
    now,
    showProjectedEntities,
    showCompletedTasks,
    showAgendaSchedulingFeedback,
    isLoading,
  ])
}
