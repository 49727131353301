import { useDebouncedCallback } from '@motion/react-core/hooks'
import type { Contact } from '@motion/rpc/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useAddCalendarIds,
  useCalendarState,
  useRemoveCalendarIds,
} from '~/areas/calendar/hooks/use-calendar-state'
import { useCalendarChanges } from '~/components/Calendar/components/Sidebar/SidebarMode/sidebar-calendars/use-calendar-changes'
import { selectNewTeamSearchValues } from '~/state/calendar-list/calendar-list-selectors'
import { selectAllCalendars } from '~/state/calendar-list/calendar-list-slice'
import { updateSelectedTeammateContacts } from '~/state/calendar-list/calendar-list-thunks'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { useEffect } from 'react'

export const useSelectTeammates = () => {
  const dispatch = useAppDispatch()
  const allCalendars = useAppSelector(selectAllCalendars)
  const [calendarState] = useCalendarState()

  const { setCalendarChanges } = useCalendarChanges()
  const addCalendarIds = useAddCalendarIds()
  const removeCalendarIds = useRemoveCalendarIds()
  const selectedTeammates = useAppSelector(selectNewTeamSearchValues)

  const handleSelectedTeammates = useDebouncedCallback(
    async (_: string[], options: Contact[] | undefined) => {
      void dispatch(updateSelectedTeammateContacts(options ?? []))

      if (selectedTeammates.length < (options ?? []).length) {
        void recordAnalyticsEvent('CALENDAR_TEAMMATE_SEARCH_SELECT')
      }
    },
    1_000,
    { leading: true, maxWait: 1_000 }
  )

  // Keep the calendar selected state in sync with the selected teammates
  useEffect(() => {
    allCalendars.forEach((calendar) => {
      // Add calendar id to includedCalendarIds if the calendar is selected
      if (
        selectedTeammates.includes(calendar.providerId) &&
        !calendarState.includedCalendarIds.includes(calendar.id)
      ) {
        addCalendarIds([calendar.id])

        setCalendarChanges(calendar.id, {
          id: calendar.id,
          isEnabled: true,
          emailAccountId: calendar.emailAccountId,
        })
      }

      // Remove calendar id from includedCalendarIds if the calendar is not selected
      if (
        !selectedTeammates.includes(calendar.providerId) &&
        calendarState.includedCalendarIds.includes(calendar.id)
      ) {
        removeCalendarIds([calendar.id])

        setCalendarChanges(calendar.id, {
          id: calendar.id,
          isEnabled: false,
          emailAccountId: calendar.emailAccountId,
        })
      }
    })
  }, [
    addCalendarIds,
    allCalendars,
    calendarState.includedCalendarIds,
    removeCalendarIds,
    selectedTeammates,
    setCalendarChanges,
  ])

  // TODO: remove this once teammate contacts is removed from redux
  useEffect(() => {
    return () => {
      void dispatch(updateSelectedTeammateContacts([]))
    }
  }, [dispatch])

  return [selectedTeammates, handleSelectedTeammates] as const
}
