import { twMerge } from 'tailwind-merge'

interface SubHeaderProps {
  className?: string
}

export const SubHeader = ({
  className,
  children,
}: SubHeaderProps & JSX.IntrinsicElements['h2']) => {
  return (
    <p
      className={twMerge(
        'text-light-1100 dark:text-dark-300 mb-0 text-lg font-normal',
        className
      )}
    >
      {children}
    </p>
  )
}
