import { getWindowData } from '@motion/web-base/env'
import { logInDev } from '@motion/web-base/logging'

import { createContext, type ReactNode, useMemo } from 'react'

import { type IpcCallback, type Unsubscribe } from './types'

import {
  type ReceivableChannels,
  type SendableChannels,
} from '../../state/desktopSlice'

type DesktopIpcContextType = {
  send(message: SendableChannels, ...args: any[]): void
  on(message: ReceivableChannels, cb: IpcCallback): Unsubscribe
}

const THROW_NO_CONTEXT = () => {
  throw new Error('Context not found')
}
const noop: () => void = () => undefined

export const DesktopIpcContext = createContext<DesktopIpcContextType>({
  on: THROW_NO_CONTEXT,
  send: THROW_NO_CONTEXT,
})

export type DesktopIpcProviderProps = {
  children: ReactNode
}

export const DesktopIpcProvider = (props: DesktopIpcProviderProps) => {
  const { isElectron } = getWindowData()

  const api = useMemo(() => {
    if (!isElectron) {
      return { on: () => noop, send: noop }
    }

    const handlers = new Map<string, IpcCallback[]>()

    return {
      on(channel: ReceivableChannels, cb: IpcCallback) {
        if (handlers.has(channel)) {
          handlers.get(channel)?.push(cb)
        } else {
          handlers.set(channel, [cb])
          window.ipcRender?.receive(channel, (...args) => {
            logInDev('IPC: From Electron', channel, ...args)
            handlers.get(channel)?.forEach((cb) => cb(...args))
          })
        }

        return () => {
          handlers.set(
            channel,
            handlers.get(channel)?.filter((x) => x !== cb) ?? []
          )
        }
      },
      send(channel: SendableChannels, ...args: any[]) {
        logInDev('IPC: To Electron', channel, ...args)
        window.ipcRender?.send(channel, ...args)
      },
    }
  }, [isElectron])

  return (
    <DesktopIpcContext.Provider value={api}>
      {props.children}
    </DesktopIpcContext.Provider>
  )
}
