import { useSharedState } from '@motion/react-core/shared-state'
import { NumberChart } from '@motion/web-charts/number'

import { PageTasksContextKey } from '~/areas/tasks/context'

import { GridTile } from '../components/grid-tile'

export const TaskCount = () => {
  const [tasks] = useSharedState(PageTasksContextKey)

  return (
    <GridTile title='Tasks' width={2} height={1}>
      <NumberChart value={tasks?.length} unit='Tasks' position='bottom' />
    </GridTile>
  )
}
