import { Button } from '@motion/ui/base'

// eslint-disable-next-line no-restricted-imports
import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  DarkThemeSvg,
  LightThemeSvg,
  SystemSettingsSvgs,
} from './shared/ThemeSvgs'
import { type MotionTheme } from './types'

type ChooseThemeProps = {
  onComplete: (theme: MotionTheme) => void
}

export function ChooseTheme({ onComplete }: ChooseThemeProps) {
  const [selectedTheme, setSelectedTheme] =
    useState<MotionTheme>('systemSettings')

  return (
    <div className='h-full w-full py-12'>
      <RadioGroup value={selectedTheme} onChange={setSelectedTheme}>
        <div>
          <RadioGroup.Label className='text-light-1200 block text-center text-[32px] font-semibold leading-[42px]'>
            Choose your theme
          </RadioGroup.Label>

          <p className='text-light-1100 mt-4 text-center text-base'>
            You can change this later in settings.
          </p>
        </div>

        <div className='mt-8 flex items-center justify-center gap-6'>
          <div>
            <RadioGroup.Option
              value='systemSettings'
              className={({ checked }) =>
                twMerge(
                  checked ? 'border-primary-400 ring-primary-400 ring-4' : '',
                  'h-36 w-64 cursor-pointer overflow-hidden rounded-lg shadow-sm focus:outline-none'
                )
              }
            >
              <SystemSettingsSvgs />
            </RadioGroup.Option>
            <div className='mt-4 flex justify-center'>
              <label className='text-light-1200 text-center text-base font-medium'>
                System settings
              </label>
            </div>
          </div>

          <div>
            <RadioGroup.Option
              value='dark'
              className={({ checked }) =>
                twMerge(
                  checked ? 'border-primary-400 ring-primary-400 ring-4' : '',
                  'bg-dark-900 flex h-36 w-64 cursor-pointer overflow-hidden rounded-lg  shadow-sm focus:outline-none'
                )
              }
            >
              <div className='mx-auto translate-y-4'>
                <DarkThemeSvg />
              </div>
            </RadioGroup.Option>
            <div className='mt-4 flex justify-center'>
              <label className='text-light-1200 text-center text-base font-medium'>
                Dark
              </label>
            </div>
          </div>
          <div>
            <RadioGroup.Option
              value='light'
              className={({ checked }) =>
                twMerge(
                  checked ? 'border-primary-400 ring-primary-400 ring-4' : '',
                  'bg-light-500 flex h-36 w-64 cursor-pointer overflow-hidden rounded-lg shadow-sm focus:outline-none'
                )
              }
            >
              <div className='mx-auto translate-y-4'>
                <LightThemeSvg />
              </div>
            </RadioGroup.Option>
            <div className='mt-4 flex justify-center'>
              <label className='text-light-1200 text-center text-base font-medium'>
                Light
              </label>
            </div>
          </div>
        </div>
      </RadioGroup>

      <div className='mt-12 flex justify-center'>
        <Button onClick={() => onComplete(selectedTheme)}>Continue</Button>
      </div>
    </div>
  )
}
