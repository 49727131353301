import { wrapVariableInDelimiters } from '@motion/shared/flows'
import type { FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import type {
  StageDefinitionSchema,
  TaskDefinitionSchema,
  VariableDefinitionSchema,
} from '@motion/zod/client'

export const getVariableKeyByName = (
  name: string,
  variables: VariableDefinitionSchema[]
) => {
  return variables.find((variable) => variable.name === name)?.key ?? 'Unknown'
}

export const getWrappedVariableKeyByName = (
  name: string,
  variables: VariableDefinitionSchema[]
) => {
  return wrapVariableInDelimiters(getVariableKeyByName(name, variables))
}

export type PresetWithoutWorkspaceFields = Omit<
  FlowTemplateFormFields,
  'workspaceId' | 'stages' | 'id'
> & {
  stages: (Omit<StageDefinitionSchema, 'tasks'> & {
    tasks: Omit<TaskDefinitionSchema, 'statusId'>[]
  })[]
}

export type PresetTemplateOptions = {
  /**
   * Whether to use the new event type that comes with the Meetings in Projects feature.
   * To be removed once the feature is fully released.
   */
  useEventType: boolean
}
