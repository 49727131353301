import { type CalendarEventAttendeeSchema } from '@motion/rpc-types'
import { type EventConferenceType } from '@motion/shared/common'
import { type SharedEventFormFields } from '@motion/ui-logic'
import { shiftDateToZone } from '@motion/utils/dates'

import { type ReduxCalendarEvent } from '../../../../../../state/calendarEvents/calendarEventsTypes'
import { type CalendarEvent } from '../../../../../../types/CalendarEvent'

export type EventFormFields = SharedEventFormFields & {
  id: string | undefined
  title: string
  recurrence: string
  travelTimeAfter: number | null
  travelTimeBefore: number | null
  attendees: CalendarEventAttendeeSchema[]
  email: string
  calendarId: string
  visibility: CalendarEvent['visibility']
  conferenceType: EventConferenceType
  description: string
  location: string
  conferenceLink?: string
  colorId?: string
  organizerEmail: string

  otherCalendarEventIdByEmail?: {
    [email: string]: string
  }
}

type EventFormInitialDataProps = {
  event: ReduxCalendarEvent
  recurringEventParent?: ReduxCalendarEvent
  defaultValues?: { title?: string }
}

export const useEventFormInitialData = ({
  event,
  recurringEventParent,
  defaultValues,
}: EventFormInitialDataProps): EventFormFields => {
  const startStr = event.isAllDay
    ? shiftDateToZone(event.start, 'utc').toISODate()
    : event.start
  const endStr = event.isAllDay
    ? shiftDateToZone(event.end, 'utc').toISODate()
    : event.end
  return {
    id: event.id ?? undefined,
    title: event.title ?? '',
    start: startStr,
    end: endStr,
    isAllDay: event.isAllDay ?? false,
    recurrence: event.recurrence ?? recurringEventParent?.recurrence ?? '',
    travelTimeAfter: event.travelTimeAfter ?? null,
    travelTimeBefore: event.travelTimeBefore ?? null,
    attendees: (event.attendees ?? []) as CalendarEventAttendeeSchema[],
    email: event.email ?? '',
    calendarId: event.calendarId ?? '',
    status: event.status ?? (event?.isAllDay ? 'FREE' : 'BUSY'),
    visibility: event.visibility ?? 'DEFAULT',
    conferenceType: event.conferenceType ?? ('none' as const),
    description: event.description ?? '',
    location: event.location ?? '',
    conferenceLink: event.conferenceLink,
    colorId: event.colorId,
    organizerEmail: event.organizer?.email ?? event.email,
    otherCalendarEventIdByEmail: event.otherCalendarEventIdByEmail,
    ...(!event?.id ? defaultValues : undefined),
  }
}
