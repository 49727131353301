import { ArrowLeftSolid, ArrowRightOutline } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { SidebarFooter } from '~/areas/sidebar/components'
import { useLastNonSettingsPage } from '~/global/contexts/navigation-context'
import { Link, type NavigateFunction } from 'react-router-dom'

import {
  SettingsSidebarItems,
  type SettingsSidebarItemsProps,
} from './sidebar-items'

type SettingsSidebarProps = SettingsSidebarItemsProps & {
  navigate: NavigateFunction
  showTeamUpsell: boolean
}

export const SettingsSidebar = ({
  navigate,
  settingsPage,
  selectedWorkspaceId,
  showTeamUpsell,
  onCreateWorkspace,
}: SettingsSidebarProps) => {
  const lastNonSettingsPage = useLastNonSettingsPage()

  return (
    <div className='h-full w-[220px] flex-shrink-0 bg-semantic-neutral-bg-disabled flex flex-col min-h-0 border-r border-semantic-neutral-border-default'>
      <div className='flex pt-3 px-2 flex-col items-start gap-2 self-stretch'>
        <Button
          size='small'
          sentiment='neutral'
          variant='muted'
          onClick={() => navigate(lastNonSettingsPage)}
        >
          <ArrowLeftSolid />
          Back to Motion
        </Button>
      </div>

      <SettingsSidebarItems
        {...{
          settingsPage,
          selectedWorkspaceId,
          onCreateWorkspace,
        }}
      />

      <div>
        {showTeamUpsell && (
          <div className='bg-semantic-primary-bg-default border border-semantic-primary-border-default rounded p-2 gap-1 m-2 hover:cursor-pointer'>
            <Link to='/web/settings/referrals'>
              <div className='flex flex-row items-center gap-1 w-full justify-between'>
                <h1 className='text-sm'>
                  {templateStr('{{blackText}} {{blueText}}', {
                    blackText: (
                      <span className='text-semantic-neutral-text-default font-medium'>
                        Invite friends,
                      </span>
                    ),
                    blueText: (
                      <span className='text-semantic-primary-text-default font-bold'>
                        Get $100
                      </span>
                    ),
                  })}
                </h1>

                <ArrowRightOutline className='w-[18px] h-[18px] text-semantic-primary-icon-default' />
              </div>

              <p className='text-xs text-semantic-neutral-text-default'>
                Get $100 credit when a friend you invite subscribes to Motion.
              </p>
            </Link>
          </div>
        )}

        <SidebarFooter />
      </div>
    </div>
  )
}
