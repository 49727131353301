import { type PriorityLevelType } from '@motion/rpc/types'

import { PriorityLabel } from '~/global/components/labels'
import { type ReactNode } from 'react'

import { BaseDropdown } from '../base-dropdown'

export type PriorityDropdownProps = {
  selectedItem?: PriorityLevelType
  items: PriorityLevelType[]
  onChange(item: PriorityLevelType): void
  children?: ReactNode
}

export const PriorityDropdown = (props: PriorityDropdownProps) => {
  const { items, selectedItem, onChange, children } = props

  return (
    <BaseDropdown
      searchable
      searchPlaceholder='Choose priority...'
      computeSearchValue={(item) => item}
      items={items}
      selectedItem={selectedItem}
      onChange={onChange}
      renderItem={(item) => <PriorityLabel value={item} />}
    >
      {children}
    </BaseDropdown>
  )
}
