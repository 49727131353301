import { ArrowDownSolid, ArrowUpSolid } from '@motion/icons'
import { IconButton, Tooltip, useShortcut } from '@motion/ui/base'
import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useNavigate } from 'react-router'

export interface PreviousNextModalButtonsProps {
  currentId: TaskSchema['id'] | ProjectSchema['id']
  getPrevId: () => string | null
  getNextId: () => string | null
  buildUrl: (arg0: string) => string
  renderTooltip: (
    id: TaskSchema['id'] | ProjectSchema['id'],
    shortcut: string,
    isNext?: boolean
  ) => React.ReactNode
}

export const PreviousNextModalButtons = ({
  currentId,
  getPrevId,
  getNextId,
  buildUrl,
  renderTooltip,
}: PreviousNextModalButtonsProps) => {
  const prevId = getPrevId()
  const nextId = getNextId()

  const canGoPrev = prevId != null
  const canGoNext = nextId != null

  const prevUrl = canGoPrev ? buildUrl(prevId) : undefined
  const nextUrl = canGoNext ? buildUrl(nextId) : undefined

  if (!canGoPrev && !canGoNext) return null

  return (
    <InnerArrowButtons
      canGoPrev={canGoPrev}
      canGoNext={canGoNext}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      prevId={prevId}
      nextId={nextId}
      renderTooltip={renderTooltip}
    />
  )
}

interface InnerArrowButtonsProps
  extends Pick<PreviousNextModalButtonsProps, 'renderTooltip'> {
  canGoPrev: boolean
  canGoNext: boolean
  prevUrl: string | undefined
  nextUrl: string | undefined
  prevId: string | null
  nextId: string | null
}

const InnerArrowButtons = ({
  canGoPrev,
  canGoNext,
  prevUrl,
  nextUrl,
  prevId,
  nextId,
  renderTooltip,
}: InnerArrowButtonsProps) => {
  const navigate = useNavigate()

  useShortcut(
    'mod+arrowup',
    () => {
      prevUrl && navigate(prevUrl)
    },
    {
      enabled: canGoPrev,
    }
  )
  useShortcut(
    'mod+arrowdown',
    () => {
      nextUrl && navigate(nextUrl)
    },
    { enabled: canGoNext }
  )

  return (
    <div className='flex flex-col gap-1'>
      <Tooltip
        asChild
        renderContent={
          prevId && canGoPrev
            ? () => renderTooltip(prevId, 'mod+arrowup')
            : undefined
        }
      >
        <IconButton
          icon={ArrowUpSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          disabled={!canGoPrev}
          url={prevUrl}
        />
      </Tooltip>
      <Tooltip
        asChild
        renderContent={
          nextId && canGoNext
            ? () => renderTooltip(nextId, 'mod+arrowdown', true)
            : undefined
        }
      >
        <IconButton
          icon={ArrowDownSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          disabled={!canGoNext}
          url={nextUrl}
        />
      </Tooltip>
    </div>
  )
}
