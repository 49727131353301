import {
  getDeadlineText,
  isMeetingTask,
  isValidTaskDeadlineDateOption,
} from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { type DateTime } from 'luxon'
import { useCallback } from 'react'

import { useTaskDeadlineUpdater, useTaskUpdater } from '../updaters'

export function useTaskDeadlineData(task: TaskSchema) {
  const updateTask = useTaskUpdater()
  const updateTaskDeadline = useTaskDeadlineUpdater()

  const onChange = useCallback(
    (dueDate: TaskSchema['dueDate']) => {
      void updateTaskDeadline(task, dueDate)
    },
    [task, updateTaskDeadline]
  )

  const onChangeDeadlineType = useCallback(
    (deadlineType: TaskSchema['deadlineType']) => {
      void updateTask(task, {
        deadlineType,
      })
    },
    [task, updateTask]
  )

  const isMeeting = isMeetingTask(task)

  const disabled =
    task.priorityLevel === 'ASAP' || task.archivedTime != null || isMeeting
  const hasValidData = !isMeeting

  const formattedValue = getDeadlineText({
    priorityLevel: task.priorityLevel,
    dueDate: task.dueDate,
  })

  const isValidDateOption = useCallback(
    (date: DateTime) => {
      return isValidTaskDeadlineDateOption(task, date)
    },
    [task]
  )

  return {
    onChange,
    onChangeDeadlineType,
    disabled,
    formattedValue,
    isValidDateOption,
    hasValidData,
  }
}
