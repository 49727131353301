import { readLocalStorageValue } from '@motion/web-common/storage'

import { type LoaderFunction, redirect } from 'react-router'

import { v3Routes } from './pm-v3/routes/routes'

import { lazyRoute, type MotionRoute } from '../../../routing/api'

const entryPoint = () => import('./pm-revamp')

const DEFAULT_URL_V3 = '/web/pm/all-tasks/tasks/list'

function safePmRedirect(uri: string | null | undefined, fallback: string) {
  if (uri == null || uri === '/web/pm' || uri === '/web/pm/') {
    return redirect(fallback)
  }
  return redirect(uri)
}

const indexRedirectLoader: LoaderFunction = () => {
  const lastUri = readLocalStorageValue('motionLastPmUri')
  return safePmRedirect(lastUri, DEFAULT_URL_V3)
}

export const route: MotionRoute = {
  id: 'pm-root',
  path: 'pm',
  lazy: lazyRoute(entryPoint, 'Shell'),
  metric: 'PROJECT_MANAGEMENT_VISIT_PAGE',
  children: [
    {
      path: '',
      index: true,
      loader: indexRedirectLoader,
    },
    {
      id: 'team-schedule',
      path: 'team-schedule',
      lazy: lazyRoute(entryPoint, 'TeamScheduleShellWithContext'),
      tags: {
        page: 'team-schedule',
      },
    },
    ...v3Routes,
  ],
}
