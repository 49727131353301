import { EyeSolid } from '@motion/icons'
import { PopoverButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useController } from 'react-hook-form'

import { PopoverButtonIcon } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventVisibilityField = () => {
  const { isLoading, isReadOnly, hostCalendar } = useEventModalState()
  const { form } = useEventForm()
  const { control } = form

  const { field } = useController({
    name: 'visibility',
    control,
  })

  if (
    isLoading ||
    hostCalendar == null ||
    hostCalendar.providerType === 'APPLE'
  ) {
    return null
  }

  const onChange = async (value: CalendarEventSchemaV2['visibility']) => {
    field.onChange(value)
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={options}
          renderItem={(item) => optionLabels[item]}
          computeKey={(item) => item}
          computeSelected={(item) => item === field.value}
          onSelect={(item) => {
            onChange(item)
            close()
          }}
        />
      )}
    >
      <PopoverButton
        aria-label='Event visibility'
        readOnly={isReadOnly}
        size='small'
      >
        <div className='flex items-center gap-2 overflow-hidden'>
          <PopoverButtonIcon icon={EyeSolid} />
          <span className='truncate'>{optionLabels[field.value]}</span>
        </div>
      </PopoverButton>
    </PopoverTrigger>
  )
}

const optionLabels: Record<CalendarEventSchemaV2['visibility'], string> = {
  CONFIDENTIAL: 'Confidential',
  DEFAULT: 'Default visibility',
  PRIVATE: 'Private',
  PUBLIC: 'Public',
}
const options = keys(optionLabels)
