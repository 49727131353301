import { useOnceWhen } from '@motion/react-core/hooks'
import { API } from '@motion/rpc'
import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { type ProviderType, type StripeSubscription } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { type BillingPrices } from '@motion/ui-logic/billing'
import { isEmailValid } from '@motion/utils/string'
import {
  getTrackingCookies,
  recordAnalyticsEvent,
} from '@motion/web-base/analytics'
import { getWindowData, isMobileExperience } from '@motion/web-base/env'
import { makeLog } from '@motion/web-base/logging'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'
import { type OnboardingType } from '@motion/web-common/onboarding'
import {
  getLatestNavigationTiming,
  stats,
  useMountMeasureOnce,
} from '@motion/web-common/performance'

import { useQueryClient } from '@tanstack/react-query'
import {
  BillingContainer,
  type CompleteSubscriptionArgs,
} from '~/areas/billing'
import { ConnectCalendars } from '~/areas/modals/calendars/connect-calendars'
import { SelectMainCalendar } from '~/areas/modals/calendars/select-main-calendar'
import { SelectMyCalendars } from '~/areas/modals/calendars/select-my-calendars'
import { INDIVIDUAL_DEFAULT_TRIAL_LENGTH } from '~/areas/onboarding/constants'
import { useCreateProject, useProjectUpdater } from '~/areas/project/hooks'
import api from '~/chromeApi/chromeApiContentScript'
import { useIsIapSubscription } from '~/global/hooks'
import { useCurrentTeam, useGetTeamPrices } from '~/global/rpc/team'
import { navigateToMobileSplashScreen } from '~/routing/utils'
import { createSubscription } from '~/state/corrilySlice'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { CheckoutSurvey } from './checkout-survey'
import { ChooseWorkHours } from './choose-work-hours'
import { ChoosePlan } from './ChoosePlan'
import { ChooseTheme } from './ChooseTheme'
import { CreateTasksBulk } from './create-tasks-bulk'
import { CreateProject } from './CreateProject'
import { CreateRecurringTasks } from './CreateRecurringTasks'
import { CreateTasks } from './CreateTasks'
import { CreateWorkspace } from './CreateWorkspace'
import { defaultWorkspace } from './default-workspace'
import { IndividualTeamInterception } from './individual-team-interception'
import {
  KycBookOnboardingCall,
  KycCompanyQuestionnaire,
  KycCreateTeam,
} from './know-your-customer'
import { OnboardingDesktopDisabled } from './OnboardingDesktopDisabled'
import { OneMinuteVideo } from './OneMinuteVideo'
import {
  trackCheckoutSurveyOption,
  trackCreateTeam,
  trackNoCcOnboardingComplete,
  trackTeamSurveyOption,
  useOnboardingAnalytics,
  useSegmentUser,
  useTrackingScripts,
} from './shared/onboardingAnalytics'
import { OnboardingHeader } from './shared/OnboardingHeader'
import { useApiStorage } from './shared/useApiStorage'
import {
  type MotionTheme,
  type OnboardingRecurringTask,
  type OnboardingScreen,
  type Task,
  validOnboardingScreens,
  type Workspace,
} from './types'

import { SecondaryButton } from '../../../components/Common'
import { LoadingSvg } from '../../../components/Common/Icons/LoadingSvg'
import { useInNoExternalCalendarsMode } from '../../../hooks/use-in-no-external-calendars-mode'
import { useAuth } from '../../../localServices/auth'
import { persistSettings } from '../../../localServices/firebase/web'
import { updateCalendarList } from '../../../state/calendar/calendarThunks'
import { selectAllCalendars } from '../../../state/calendar-list/calendar-list-slice'
import { updateCalendars } from '../../../state/calendar-list/calendar-list-thunks'
import { selectEmailAccounts } from '../../../state/email-accounts/email-accounts-slice'
import {
  deleteEmailAccount,
  fetchAllEmailAccounts,
  setMainCalendar,
} from '../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { fetchTeam } from '../../../state/projectManagement/teamThunks'
import { setTheme } from '../../../state/settingsSlice'
import {
  selectDisplayName,
  setStripeSubscription,
} from '../../../state/userSlice'
import { addAccount } from '../../../utils/auth'
import { useOnboardingApi } from '../state/use-onboarding-api'

// These indices are used as one off instances where we need the frontend to show certain screens regardless of the ordering specified on the backend:

const BILLING_INTERCEPTION_INDEX = -1
// BillingInterception is shown when a user starts a "team" onboarding type but doesn't actually create a team
// Note: with recent changes to the BillingContainer, we check for a stripe subscription and skip the screen if they already have one
// i.e We can add a "team_billing" screen from the backend and remove the frontend logic around this
// This would be clearer than the current implementation

const defaultOnboardingScreensByType: Record<
  OnboardingType,
  OnboardingScreen[]
> = {
  individual: [
    'individual_or_team_prompt',
    'billing',
    '1_min_video',
    'connect_calendar',
    'select_main_calendar',
    'select_my_calendars',
    'choose_work_hours',
    'create_recurring_tasks',
    'create_first_workspace',
    'create_first_project',
    'create_first_tasks',
    'kyc_company_questionnaire',
    'team_interception',
    'create_team',
    'kyc_book_onboarding_call',
    'choose_theme',
  ],
  individual_no_card: [
    'individual_or_team_prompt',
    '1_min_video',
    'connect_calendar',
    'select_main_calendar',
    'select_my_calendars',
    'choose_work_hours',
    'create_recurring_tasks',
    'create_first_workspace',
    'create_first_project',
    'create_first_tasks',
    'kyc_company_questionnaire',
    'team_interception',
    'create_team',
    'kyc_book_onboarding_call',
    'choose_theme',
  ],
  team: [
    'individual_or_team_prompt',
    '1_min_video',
    'connect_calendar',
    'select_main_calendar',
    'select_my_calendars',
    'choose_work_hours',
    'create_recurring_tasks',
    'create_first_workspace',
    'create_first_project',
    'create_first_tasks',
    'create_team',
    'kyc_company_questionnaire',
    'kyc_book_onboarding_call',
    'choose_theme',
  ],
  team_with_card: [
    'individual_or_team_prompt',
    'billing',
    '1_min_video',
    'connect_calendar',
    'select_main_calendar',
    'select_my_calendars',
    'choose_work_hours',
    'create_recurring_tasks',
    'create_first_workspace',
    'create_first_project',
    'create_first_tasks',
    'create_team',
    'kyc_company_questionnaire',
    'kyc_book_onboarding_call',
    'choose_theme',
  ],
  team_invitee: [
    '1_min_video',
    'connect_calendar',
    'select_main_calendar',
    'select_my_calendars',
    'choose_work_hours',
    'create_recurring_tasks',
    'choose_theme',
  ],
}

const log = makeLog('[onboarding]')

type OnboardingProps = {
  userEmail: string
  onLogout: () => void
  initialOnboardingType?: OnboardingType
  initialOnboardingScreen?: OnboardingScreen
  initialWorkspace?: Workspace
  teamPrices: BillingPrices & { individualToTeamSavingsPercent: number }
  didWebflowSurvey: boolean
}

function isValidOnboardingScreen(screen: OnboardingScreen) {
  const hiddenScreens: OnboardingScreen[] = ['kyc_book_onboarding_call']

  return !hiddenScreens.includes(screen)
}

function OnboardingLoadingContainer(
  props: Pick<OnboardingProps, 'userEmail' | 'onLogout'>
) {
  const dispatch = useAppDispatch()
  const cookieData = useMemo(() => getTrackingCookies(), [])
  const surveyCookieData = cookieData.survey_selection ?? undefined

  const { getOnboardingState, setOnboardingType } = useOnboardingApi()

  useEffect(() => {
    stats.measure('onboarding.load', 'extensionStart')
  }, [])

  useEffect(
    function setupOnboardingLayout() {
      // Force light theme for onboarding (user can update this on the last screen "Choose Theme")
      dispatch(setTheme('light'))
      document.body.classList.remove('dark')
    },
    [dispatch]
  )

  const [isLoading, setIsLoading] = useState(true)
  const [initialOnboardingType, setInitialOnboardingType] = useState<
    OnboardingType | undefined
  >(undefined)
  const [didFetchOnboardingState, setDidFetchOnboardingState] = useState(false)
  const [initialOnboardingScreen, setInitialOnboardingScreen] = useState<
    OnboardingScreen | undefined
  >(undefined)
  const { isElectron } = getWindowData()

  const [initialWorkspace, setInitialWorkspace] =
    useState<Workspace>(defaultWorkspace)

  const initializeOnboardingType = useCallback(
    (type: OnboardingType) => {
      setInitialOnboardingType(type)
      void setOnboardingType(type)
    },
    [setOnboardingType]
  )

  const { data: team, isSuccess: isTeamFetched } = useCurrentTeam()

  const [stripeSubscription, setStripeSubscription] =
    useState<StripeSubscription | null>(null)

  useEffect(
    function getInitialOnboardingState() {
      getOnboardingState()
        .then((onboardingState) => {
          setInitialOnboardingScreen(onboardingState.onboardingScreen)
          setInitialOnboardingType(onboardingState.onboardingType)
          // @ts-expect-error - legacy
          setStripeSubscription(onboardingState.stripeSubscription)
          setInitialWorkspace((state) => {
            return {
              ...state,
              ...onboardingState.initialWorkspace,
            }
          })

          setDidFetchOnboardingState(true)
          return
        })
        .catch(() => {
          setDidFetchOnboardingState(true)
        })

      void dispatch(fetchAllEmailAccounts())
    },
    [getOnboardingState, dispatch]
  )

  useEffect(
    function detectOnboardingType() {
      if (isLoading) {
        // Ensure both team and onboarding state have been fetched before determining onboarding type
        if (!isTeamFetched || !didFetchOnboardingState) {
          return
        }

        const isTeamOnboarding =
          initialOnboardingType === 'team' ||
          initialOnboardingType === 'team_with_card'
        if (isTeamOnboarding && !team) {
          const isIndividualStripeSub =
            stripeSubscription &&
            'plan' in stripeSubscription &&
            'metadata' in (stripeSubscription.plan as any) &&
            'ItemCode' in (stripeSubscription.plan as any).metadata &&
            (stripeSubscription.plan as any).metadata.ItemCode === 'INDI'
          if (isIndividualStripeSub) {
            initializeOnboardingType('individual')
            setIsLoading(false)
            return
          }
        }

        if (initialOnboardingType) {
          setIsLoading(false)
          return
        }

        const hasTeam = team && Object.keys(team).length > 0

        if (hasTeam) {
          // If they are the only team member, we assume they are a team coming from mobile web
          if (team.members.length === 1) {
            // `team_with_card` onboarding type
            initializeOnboardingType('team_with_card')
          } else {
            // `team_invitee` onboarding type
            initializeOnboardingType('team_invitee')
          }
        } else if (initialOnboardingType === undefined && stripeSubscription) {
          // `individual` onboarding type
          initializeOnboardingType('individual')
        } else if (surveyCookieData) {
          let initialScreenFromSurvey: OnboardingScreen | undefined

          switch (surveyCookieData) {
            case 'team':
              initializeOnboardingType('team_with_card')
              initialScreenFromSurvey = 'billing'
              break
            case 'individual':
              initializeOnboardingType('individual')
              initialScreenFromSurvey = 'billing'
              break
            case 'enterprise':
              initializeOnboardingType('team')
              initialScreenFromSurvey = '1_min_video'
              break
            default:
              break
          }
          if (initialScreenFromSurvey && !initialOnboardingScreen) {
            setInitialOnboardingScreen(initialScreenFromSurvey)
          }
        }
        setIsLoading(false)
      }
    },
    [
      isTeamFetched,
      team,
      didFetchOnboardingState,
      initialOnboardingType,
      isLoading,
      stripeSubscription,
      surveyCookieData,
      initializeOnboardingType,
      initialOnboardingScreen,
    ]
  )

  const { data: teamPrices } = useGetTeamPrices()
  const isReady = !isLoading && teamPrices != null

  useOnceWhen(isReady && !isElectron, () => {
    const navigationEntry = getLatestNavigationTiming()
    if (navigationEntry == null) return

    // Only record if they originally landed on the /checkout page
    if (!navigationEntry.name.includes('/checkout')) return

    const loginMark = stats.getLatestMark('checkout.login')

    if (loginMark) {
      stats.measure('checkout.onboarding', 'checkout.login', [
        'flow:checkout',
        'auth:manual',
      ])
    } else {
      stats.distribution(
        'checkout.onboarding',
        performance.now() - navigationEntry.requestStart,
        ['flow:checkout', 'auth:cookie']
      )
    }
  })

  if (!isReady) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <LoadingSvg />
      </div>
    )
  }

  if (isElectron) {
    return <OnboardingDesktopDisabled onLogout={props.onLogout} />
  }

  return (
    <OnboardingScreens
      {...props}
      initialOnboardingType={initialOnboardingType}
      initialOnboardingScreen={initialOnboardingScreen}
      initialWorkspace={initialWorkspace}
      teamPrices={teamPrices}
      didWebflowSurvey={!!surveyCookieData}
    />
  )
}

export { OnboardingLoadingContainer as Onboarding }

// It's possible that a screen defined in amplitude has no matching screen on the client
// Filter these out to be safe
function filterInvalidOnboardingScreen(screen: OnboardingScreen) {
  return validOnboardingScreens.includes(screen)
}

function OnboardingScreens({
  userEmail,
  onLogout,
  initialOnboardingType,
  initialOnboardingScreen,
  initialWorkspace = defaultWorkspace,
  teamPrices,
  didWebflowSurvey,
}: OnboardingProps) {
  const dispatch = useAppDispatch()

  const createProject = useCreateProject()
  const updateProject = useProjectUpdater()
  const { uid: currentUserId } = useAuthenticatedUser()

  useMountMeasureOnce({
    name: 'onboarding.ready',
    from: 'extensionStart',
  })

  const apiCalls = useOnboardingApi()

  const createOrUpdateTasks = apiCalls.useCreateOrUpdateTasks()
  const createRecurringTasks = apiCalls.useCreateRecurringTasks()
  const createOrUpdateWorkspace = apiCalls.useCreateOrUpdateWorkspace()
  const createTeamWithInvites = apiCalls.useCreateTeamWithInvites()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const modalApi = useModalApi()
  const isIapSubscription = useIsIapSubscription()

  const navigate = useNavigate()
  const auth = useAuth()

  const client = useQueryClient()

  log('render', {
    userEmail,
    initialOnboardingType,
    initialOnboardingScreen,
  })

  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')

  // We show the new onboarding flow if
  // 1. The user is not on mobile
  // 2. The user has not already seen the create first workspace screen
  // 3. Or created a workspace (similar to 3, but they may have gone further in the flow)
  const shouldSkipCreateWorkspaceShowBulkTasks =
    initialOnboardingScreen !== 'create_first_workspace' &&
    !initialWorkspace.workspaceId

  // For certain feature flags, we want to couple changing which screens we show
  // with other feature flag behavior. Instead of using the onboarding flag to change which
  // screens we show, we filter the screens after they are returned.
  const filterAndUpdateOnboardingScreensForFeatureFlag = useCallback(
    (screens: OnboardingScreen[]) => {
      return screens.filter((screen) => {
        return (
          filterInvalidOnboardingScreen(screen) &&
          (!shouldSkipCreateWorkspaceShowBulkTasks ||
            (screen !== 'create_first_workspace' &&
              screen !== 'create_first_project')) &&
          (!isIapSubscription ||
            (!screen.includes('team') && !screen.includes('kyc'))) &&
          (!didWebflowSurvey || !screen.includes('kyc'))
        )
      })
    },
    [
      shouldSkipCreateWorkspaceShowBulkTasks,
      isIapSubscription,
      didWebflowSurvey,
    ]
  )

  const selectedOnboardingScreensByType: Record<
    OnboardingType,
    OnboardingScreen[]
  > = defaultOnboardingScreensByType

  const onboardingScreensByType: typeof selectedOnboardingScreensByType =
    useMemo(() => {
      return {
        individual: filterAndUpdateOnboardingScreensForFeatureFlag(
          selectedOnboardingScreensByType.individual
        ),
        individual_no_card: filterAndUpdateOnboardingScreensForFeatureFlag(
          selectedOnboardingScreensByType.individual_no_card
        ),
        team: filterAndUpdateOnboardingScreensForFeatureFlag(
          selectedOnboardingScreensByType.team
        ),
        team_invitee: filterAndUpdateOnboardingScreensForFeatureFlag(
          selectedOnboardingScreensByType.team_invitee
        ),
        team_with_card: filterAndUpdateOnboardingScreensForFeatureFlag(
          selectedOnboardingScreensByType.team_with_card
        ),
      }
    }, [
      selectedOnboardingScreensByType,
      filterAndUpdateOnboardingScreensForFeatureFlag,
    ])
  const userDisplayName = useAppSelector(selectDisplayName)

  const [onboardingType, setOnboardingType] = useState<
    OnboardingType | undefined
  >(initialOnboardingType)

  const screens = useMemo(() => {
    let filteredScreens = [
      ...onboardingScreensByType[onboardingType || 'individual'],
    ]

    filteredScreens = filteredScreens.filter((screen) =>
      isValidOnboardingScreen(screen)
    )
    filteredScreens[BILLING_INTERCEPTION_INDEX] = 'billing_interception'
    return filteredScreens
  }, [onboardingType, onboardingScreensByType])

  const initialIndex = initialOnboardingScreen
    ? screens.indexOf(initialOnboardingScreen)
    : 0

  const [activeIndex, setActiveIndex] = useState(Math.max(initialIndex, 0))
  const activeScreen = screens[activeIndex]
  const [emailConnectionErrors, setEmailConnectionErrors] = useState<
    Record<string, boolean>
  >({})

  const nextIndex = Math.min(activeIndex + 1, screens.length - 1)
  const prevIndex = Math.max(activeIndex - 1, 0)

  useTrackingScripts()
  useSegmentUser(userEmail, userDisplayName)
  useOnboardingAnalytics(activeScreen, onboardingType)

  function onChangeOnboardingType(type: OnboardingType) {
    setOnboardingType(type)
    void apiCalls.setOnboardingType(type)
  }

  function goToNextPage() {
    const isLastScreen = activeIndex === screens.length - 1

    const currentScreen = screens[activeIndex]
    if (currentScreen === 'billing' && isMobileExperience()) {
      return navigateToMobileSplashScreen()
    }

    if (isLastScreen) {
      log('completing')
      void apiCalls.completeOnboarding().then(async (res) => {
        log('completed', res)
        if (res.refresh) {
          window.location.reload()
          return res
        }

        void client.invalidateQueries({
          queryKey: API.workspacesV2.queryKeys.root,
        })
        if (shouldUseNoCcTrial) {
          trackNoCcOnboardingComplete(userEmail)
        }

        log('refreshing auth')
        await auth.refresh({ silent: true, force: true })
        log('navigating to calendar')
        navigate('/web/calendar')
        await api.storage.local.set({ forceOnboarding: false })
      })
      return
    }

    setActiveIndex(nextIndex)
  }

  function skipBookingPage() {
    // Currently we'll never be on the last screen when this is called, but for
    // future safety, check if we are, and if so let goToNextPage handle it
    if (activeIndex === screens.length - 1) {
      goToNextPage()
      return
    }

    if (screens[nextIndex] === 'kyc_book_onboarding_call') {
      setActiveIndex(nextIndex + 1)
      return
    }
    goToNextPage()
  }

  function goToPreviousPage() {
    setActiveIndex(prevIndex)
  }

  function goToConnectCalendarsPage() {
    setActiveIndex(screens.indexOf('connect_calendar'))
  }

  useEffect(
    function persistLatestOnboardingScreen() {
      const screen = screens[activeIndex]
      if (screen) {
        void apiCalls.setLatestOnboardingScreen(screen)
      }
    },
    [activeIndex, apiCalls, screens]
  )

  function onConnectAccount(provider?: ProviderType) {
    addAccount(provider)
    setEmailConnectionErrors({})
  }

  const emailAccounts = useAppSelector(selectEmailAccounts)
  const calendars = useAppSelector(selectAllCalendars)

  function onRemoveAccount(email: string) {
    setEmailConnectionErrors((prevState) => {
      if (prevState[email]) {
        return {}
      }
      return prevState
    })

    const emailAccount = emailAccounts.find((e) => e.email === email)
    if (!emailAccount) {
      log(`Error: could not find email account for email ${email}`)
      return
    }

    void dispatch(deleteEmailAccount(emailAccount.id))
    return
  }

  async function onSelectMainCalendar(emailAccount: EmailAccount) {
    const res = await dispatch(setMainCalendar(emailAccount.id)).unwrap()
    if (res.assigned === false) {
      throw new Error(
        `The calendar for ${emailAccount.email} is currently the main calendar for another Motion account. ` +
          'Please make this calendar a non-main calendar in any other Motion accounts you may own if you want to use it here. ' +
          'You can contact our friendly support team if you have any further questions.'
      )
    }
    goToNextPage()
  }

  function onOneMinuteVideoSkip() {
    recordAnalyticsEvent('NEW_ONBOARDING_1_MIN_VIDEO_SKIP')

    goToNextPage()
  }

  /**
   * @deprecated remove when kill firestore - onboarding is complete
   */
  async function onConnectCalendarsComplete() {
    try {
      await dispatch(updateCalendarList({ updateFrequentlyMet: true })).unwrap()
      goToNextPage()
    } catch (err) {
      if (err instanceof Error && err.message) {
        const failedEmail = err.message.split(' ').pop()

        if (failedEmail && isEmailValid(failedEmail)) {
          showToast(
            'error',
            `We couldn’t connect "${failedEmail}". Please try re-connecting that calendar.`,
            { duration: 15_000 }
          )

          setEmailConnectionErrors((state) => ({
            ...state,
            [failedEmail]: true,
          }))
        }
      }
    }
  }

  async function onConnectCalendarsSkip() {
    recordAnalyticsEvent('NEW_ONBOARDING_CONNECT_CALENDAR_SKIP_MODAL')
    const confirmed = await modalApi.prompt('confirm', {
      analytics: {
        name: 'skip-connect-calendar',
      },
      title: 'Are you sure you want to skip connecting your calendar?',
      description: (
        <div>
          Motion&apos;s AI uses your calendar events to build you a realistic
          schedule without conflicts.
        </div>
      ),
      confirmButtonText: 'Skip for now',
    })
    if (confirmed !== true) return

    recordAnalyticsEvent('NEW_ONBOARDING_CONNECT_CALENDAR_SKIP')
    setActiveIndex(screens.indexOf('choose_work_hours'))
  }

  async function onSelectedMyCalendars(updatedCalendars: Calendar[]) {
    if (updatedCalendars.length) {
      await dispatch(
        updateCalendars(
          updatedCalendars.map((c) => ({
            id: c.id,
            emailAccountId: c.emailAccountId,
            isInMyCalendars: true,
          }))
        )
      )
    }

    goToNextPage()
  }

  const [schedules, setSchedules] = useApiStorage('schedules', {})

  const [workspace, setWorkspace] = useState<Workspace>(initialWorkspace)

  function onUpdateWorkspaceName(name: string) {
    setWorkspace({
      ...workspace,
      workspaceName: name,
    })
  }

  function onUpdateProjectName(name: string) {
    setWorkspace({
      ...workspace,
      projectName: name,
    })
  }

  function onUpdateTask(task: Task) {
    setWorkspace({
      ...workspace,
      tasks: workspace.tasks.map((t) => {
        if (task.id === t.id) {
          return {
            ...t,
            ...task,
          }
        }
        return t
      }),
    })
  }

  async function onCreateTeam({
    teamName,
    emails,
    isMonthly,
    seats,
  }: {
    teamName: string
    emails: string[]
    isMonthly: boolean
    seats: number
  }) {
    recordAnalyticsEvent('NEW_ONBOARDING_TEAM_INVITE')
    const createdTeam = await createTeamWithInvites({
      teamName,
      emails,
      isMonthly,
      workspaceId: workspace.workspaceId,
      seats,
    })
    trackCreateTeam(teamName, emails, userEmail, userDisplayName)
    await dispatch(fetchTeam())
    await dispatch(
      setStripeSubscription(createdTeam.pmTeamSubscription?.subscription)
    )

    if (!hasCompletedOnboardingSurvey) {
      onShowSurvey('team')
      return
    }

    goToNextPage()
  }

  async function onCreateWorkspace() {
    const response = await createOrUpdateWorkspace(workspace)
    if (response == null) return

    setWorkspace((state) => {
      return {
        ...state,
        workspaceId: response.id,
      }
    })

    if (response.id) {
      void apiCalls.setOnboardingExtraData({
        projectId: workspace.projectId || '',
        workspaceId: response.id,
      })
    }

    goToNextPage()
  }

  async function onCreateProject() {
    if (!workspace.projectName) {
      throw new Error(`Please provide a project title.`)
    }

    if (!workspace.workspaceId) {
      throw new Error(
        'Something went wrong when creating your workspace. Go back to the previous step and try again'
      )
    }

    // Update existing project instead of creating a new one
    const project = workspace.projectId
      ? await updateProject(workspace.projectId, {
          name: workspace.projectName,
          workspaceId: workspace.workspaceId,
        })
      : await createProject({
          managerId: currentUserId,
          name: workspace.projectName,
          workspaceId: workspace.workspaceId,
        })

    if (!project) {
      throw new Error('Failed to create onboarding project')
    }

    setWorkspace((state) => {
      return {
        ...state,
        projectId: project.id,
      }
    })

    if (project.id && workspace.workspaceId) {
      void apiCalls.setOnboardingExtraData({
        projectId: project.id,
        workspaceId: workspace.workspaceId,
      })
    }

    goToNextPage()
  }

  async function onCreateTasks() {
    const response = await createOrUpdateTasks(workspace)

    setWorkspace((state) => {
      return {
        ...state,
        tasks: response.map((task, index) => {
          return {
            taskId: task.id,
            ...state.tasks[index],
            ...task,
            description: task.description ?? '',
          }
        }),
      }
    })

    goToNextPage()
  }

  async function onCreateRecurringTasks(tasks: OnboardingRecurringTask[]) {
    if (tasks.length > 0) {
      await createRecurringTasks(tasks)
    }

    goToNextPage()
  }

  function onChooseTheme(theme: MotionTheme) {
    dispatch(setTheme(theme))
    recordAnalyticsEvent('THEME_CHANGED', {
      theme: theme === 'systemSettings' ? 'system' : theme,
    })
    void persistSettings({ theme })
    goToNextPage()
  }

  function onSkipCreateTeam() {
    if (onboardingType !== 'team') {
      recordAnalyticsEvent('NEW_ONBOARDING_TEAM_SKIP')
    }

    const chooseThemeIndex = screens.indexOf('choose_theme')
    const questionnaireIndex = screens.indexOf('kyc_company_questionnaire')

    // if individual user has already seen kyc questionnaire, skip to choose theme
    if (questionnaireIndex < activeIndex) {
      setActiveIndex(chooseThemeIndex)
      return
    }
    setActiveIndex(questionnaireIndex)
  }

  function onSelectTeamPlan() {
    if (shouldUseNoCcTrial) {
      onChangeOnboardingType('team')
      onShowSurvey('team')
    } else {
      onChangeOnboardingType('team_with_card')
      goToNextPage()
    }
  }

  async function onSelectIndividualPlan() {
    if (shouldUseNoCcTrial) {
      onChangeOnboardingType('individual_no_card')
      onShowSurvey('checkout')
      await createIndividualSubscription()
    } else {
      onChangeOnboardingType('individual')
      setActiveIndex(1)
    }
  }

  async function createIndividualSubscription() {
    return await dispatch(
      createSubscription({
        isMonthly: true,
        trialLength: INDIVIDUAL_DEFAULT_TRIAL_LENGTH,
      })
    ).unwrap()
  }

  function onCompleteBilling(args: CompleteSubscriptionArgs) {
    if (!args.newSubscription) {
      return goToNextPage()
    }

    if (onboardingType === 'individual') {
      onShowSurvey('checkout')
      return
    } else if (onboardingType === 'team_with_card') {
      onShowSurvey('team')
      return
    }

    goToNextPage()
  }

  function onCompleteTeamSkippedBilling() {
    const indexOfTeamScreen = screens.indexOf('create_team')
    const nextScreenIndex = indexOfTeamScreen + 1
    setActiveIndex(nextScreenIndex)
  }

  const [showSurvey, setShowSurvey] = useState(false)
  const currentSurveyType = useRef<'checkout' | 'team'>('checkout')
  const [hasCompletedOnboardingSurvey, setHasCompletedOnboardingSurvey] =
    useState(false)

  useEffect(
    function fetchOnboardingSurveyStatus() {
      void apiCalls
        .getOnboardingCompletedSurvey()
        .then((hasCompletedOnboardingSurvey: boolean) => {
          setHasCompletedOnboardingSurvey(hasCompletedOnboardingSurvey)
          return hasCompletedOnboardingSurvey
        })
    },
    [apiCalls]
  )

  function onShowSurvey(surveyType: 'checkout' | 'team') {
    // Skip survey for users who have already completed it
    // Users who started on mobile don't have hasCompletedOnboardingSurvey
    // but might get re-prompted if they switch to team.
    if (
      hasCompletedOnboardingSurvey ||
      ((onboardingType === 'individual' ||
        onboardingType === 'individual_no_card') &&
        surveyType === 'team')
    ) {
      onCompleteSurvey()
      return
    }

    currentSurveyType.current = surveyType
    setShowSurvey(true)
  }

  function onSubmitSurvey(option: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentSurveyType.current === 'checkout'
      ? trackCheckoutSurveyOption(option)
      : trackTeamSurveyOption(option)
  }

  function onCompleteSurvey() {
    void apiCalls.setOnboardingCompletedSurvey(true)
    setHasCompletedOnboardingSurvey(true)
    setShowSurvey(false)
    goToNextPage()
  }

  async function onCreateBucketTeam(seats: number, isMonthly: boolean) {
    await onCreateTeam({ teamName: 'My team', emails: [], isMonthly, seats })
    await dispatch(fetchTeam())
    goToNextPage()
  }

  return (
    <div className='bg-semantic-neutral-bg-subtle relative h-screen w-screen'>
      <OnboardingScreen
        name='individual_or_team_prompt'
        activeScreen={activeScreen}
        header={null}
      >
        <ChoosePlan
          onSelectIndividual={onSelectIndividualPlan}
          onSelectTeam={onSelectTeamPlan}
          teamSavings={teamPrices.individualToTeamSavingsPercent}
          teamAnnualPricePerMonth={teamPrices.annualPricePerMonth}
        />
      </OnboardingScreen>

      <OnboardingScreen name='billing' activeScreen={activeScreen}>
        <BillingContainer
          onChange={() => {
            onLogout()
            navigate('/checkout')
          }}
          onComplete={onCompleteBilling}
          isTeam={onboardingType === 'team_with_card'}
          // If the user is coming from the webflow survey, we hide the option to go back
          onBack={!didWebflowSurvey ? goToPreviousPage : undefined}
        />
      </OnboardingScreen>

      <OnboardingScreen
        name='1_min_video'
        activeScreen={activeScreen}
        header={
          <OnboardingHeader
            rightEl={
              <SecondaryButton
                onClick={onOneMinuteVideoSkip}
                data-testid='skip-video'
              >
                Skip video
              </SecondaryButton>
            }
          />
        }
      >
        <OneMinuteVideo onComplete={goToNextPage} />
      </OnboardingScreen>

      <OnboardingScreen name='connect_calendar' activeScreen={activeScreen}>
        <ConnectCalendars
          accounts={emailAccounts}
          onConnectAccount={onConnectAccount}
          onRemoveAccount={onRemoveAccount}
          onComplete={onConnectCalendarsComplete}
          onSkip={onConnectCalendarsSkip}
          emailConnectionErrors={emailConnectionErrors}
        />
      </OnboardingScreen>

      <OnboardingScreen name='select_main_calendar' activeScreen={activeScreen}>
        <SelectMainCalendar
          emailAccounts={emailAccounts}
          onBack={goToPreviousPage}
          onSelectMainCalendar={onSelectMainCalendar}
        />
      </OnboardingScreen>

      <OnboardingScreen name='select_my_calendars' activeScreen={activeScreen}>
        <SelectMyCalendars
          calendars={calendars}
          emailAccounts={emailAccounts}
          onBack={goToPreviousPage}
          onComplete={onSelectedMyCalendars}
        />
      </OnboardingScreen>

      <OnboardingScreen name='choose_work_hours' activeScreen={activeScreen}>
        <ChooseWorkHours
          schedules={schedules}
          setSchedules={setSchedules}
          onComplete={goToNextPage}
          goBack={
            noExternalCalendarsMode
              ? goToConnectCalendarsPage
              : goToPreviousPage
          }
        />
      </OnboardingScreen>

      <OnboardingScreen
        name='create_recurring_tasks'
        activeScreen={activeScreen}
      >
        <CreateRecurringTasks
          onSkip={goToNextPage}
          onCreateRecurringTasks={onCreateRecurringTasks}
        />
      </OnboardingScreen>

      <OnboardingScreen
        name='create_first_workspace'
        activeScreen={activeScreen}
      >
        <CreateWorkspace
          workspace={workspace}
          onUpdateWorkspaceName={onUpdateWorkspaceName}
          onComplete={onCreateWorkspace}
        />
      </OnboardingScreen>

      <OnboardingScreen name='create_first_project' activeScreen={activeScreen}>
        <CreateProject
          workspace={workspace}
          onUpdateProjectName={onUpdateProjectName}
          onComplete={onCreateProject}
          onBack={goToPreviousPage}
        />
      </OnboardingScreen>

      <OnboardingScreen name='create_first_tasks' activeScreen={activeScreen}>
        {shouldSkipCreateWorkspaceShowBulkTasks ? (
          <CreateTasksBulk goToNextPage={goToNextPage} />
        ) : (
          <CreateTasks
            workspace={workspace}
            onUpdateTask={onUpdateTask}
            onComplete={onCreateTasks}
            onBack={goToPreviousPage}
          />
        )}
      </OnboardingScreen>

      <OnboardingScreen name='team_interception' activeScreen={activeScreen}>
        <IndividualTeamInterception
          onSkip={onSkipCreateTeam}
          goToTeamCreate={goToNextPage}
          onCreateBucketTeam={onCreateBucketTeam}
          individualTeamSavings={teamPrices.individualToTeamSavingsPercent}
        />
      </OnboardingScreen>

      <OnboardingScreen name='create_team' activeScreen={activeScreen}>
        <KycCreateTeam
          onCreateTeam={onCreateTeam}
          onSkip={onSkipCreateTeam}
          onboardingType={onboardingType as OnboardingType}
          goToNextPage={goToNextPage}
          noCcCopyEnabled={shouldUseNoCcTrial}
        />
      </OnboardingScreen>
      <OnboardingScreen
        name='kyc_company_questionnaire'
        activeScreen={activeScreen}
      >
        <KycCompanyQuestionnaire
          goToNextPage={goToNextPage}
          skipBookingPage={skipBookingPage}
        />
      </OnboardingScreen>
      <OnboardingScreen
        name='kyc_book_onboarding_call'
        activeScreen={activeScreen}
      >
        <KycBookOnboardingCall
          goToNextPage={goToNextPage}
          userEmail={userEmail}
        />
      </OnboardingScreen>

      <OnboardingScreen name='choose_theme' activeScreen={activeScreen}>
        <ChooseTheme onComplete={onChooseTheme} />
      </OnboardingScreen>

      <OnboardingScreen name='billing_interception' activeScreen={activeScreen}>
        <BillingContainer
          onChange={() => {
            onLogout()
            navigate('/checkout')
          }}
          onComplete={onCompleteTeamSkippedBilling}
          isTeam={false}
        />
      </OnboardingScreen>

      {showSurvey && (
        <CheckoutSurvey
          message={`Thanks for choosing Motion${
            onboardingType === 'individual' ||
            onboardingType === 'individual_no_card'
              ? ''
              : ' for Teams'
          }!`}
          onSurveySubmit={(option: string) => onSubmitSurvey(option)}
          onComplete={onCompleteSurvey}
        />
      )}
    </div>
  )
}

type OnboardingScreenProps = {
  name: OnboardingScreen
  activeScreen: OnboardingScreen
  children: React.ReactNode
  header?: React.ReactElement | null
}

function OnboardingScreen({
  name,
  activeScreen,
  children,
  header = <OnboardingHeader />,
}: OnboardingScreenProps) {
  if (name !== activeScreen) {
    return null
  }

  return (
    <div className='flex h-full w-full flex-col'>
      {header}
      <div className='mx-auto w-full flex-1 overflow-y-auto'>{children}</div>
    </div>
  )
}
