import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { BaseDropdown } from '~/global/components'

import { useEffectiveView, useSaveView } from '../../views'

export const ConnectedSaveViewButton = () => {
  const modalApi = useModalApi()

  const info = useEffectiveView()
  const save = useSaveView()

  if (info.state === 'clean') {
    return null
  }

  async function onSaveView(args: { asNew: boolean }) {
    if (args.asNew) {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_SAVED_VIEW', args)
      return modalApi.prompt('save-view-v2', {
        view: info.view,
        asNew: true,
      })
    }

    return save({ view: info.view })
  }

  if (info.isDefaultView) {
    return (
      <Button
        onClick={() => onSaveView({ asNew: true })}
        size='small'
        variant='muted'
      >
        Save
      </Button>
    )
  }

  const selectableOptions = [
    {
      id: 'saveToCurrentView',
      label: 'Save changes to current view',
      newView: false,
    },
    {
      id: 'newWorkspaceView',
      label: 'Save as new view',
      newView: true,
    },
  ]

  return (
    <BaseDropdown
      items={selectableOptions}
      renderItem={(item) => item.label}
      onChange={(item) => onSaveView({ asNew: item.newView })}
    >
      <Button onClick={() => null} size='small' variant='muted'>
        Save
      </Button>
    </BaseDropdown>
  )
}
