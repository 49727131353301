import { type CalendarSchema } from '@motion/zod/client'

import { useGetCalendars } from '~/global/rpc/v2'
import { useMemo } from 'react'

export function useMyCalendars(opts?: { enabled?: boolean }): CalendarSchema[] {
  const { data: calendarsData } = useGetCalendars(opts)

  return useMemo(() => {
    const calendars = calendarsData?.calendars ?? []

    return calendars.filter((c) => c.isInMyCalendars)
  }, [calendarsData?.calendars])
}
