import { useGetProfilePicture } from '~/global/rpc/user'
import { request } from '~/services/apiUtils'
import { useCallback } from 'react'

export async function uploadProfilePicture(userId: string, file: File) {
  const data = new FormData()
  data.append('file', file)

  return await request(
    `users/${userId}/profile/picture`,
    'POST',
    __BACKEND_HOST__,
    data,
    false
  )
}

export function useUploadProfilePicture(userId: string | undefined) {
  const { refetch } = useGetProfilePicture(
    { id: userId ?? '' },
    { enabled: !!userId }
  )
  return useCallback(
    async (file: File) => {
      if (!userId) {
        return
      }

      await uploadProfilePicture(userId, file)
      await refetch()
    },
    [refetch, userId]
  )
}
