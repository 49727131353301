import { PlusSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useNavigateByRouteId } from '~/routing'

import { ExpandableSection } from './components/expandable-section'
import { FolderTreeview } from './folder-treeview'
import { TeamViewsSection } from './team-views-section'
import { UserViewsSection } from './user-views-section'

import {
  useFolderTreeviewContext,
  useGlobalSidebarState,
  useHasPinnedViewsTreatment,
} from '../hooks'

export const Treeviews = () => {
  const modalApi = useModalApi()
  const navigateByRouteId = useNavigateByRouteId()
  const { hasSearch } = useSidebarSearchContext()
  const { items } = useFolderTreeviewContext()
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const hasPinnedViews = useHasPinnedViewsTreatment()

  return (
    <div className='space-y-6'>
      {hasPinnedViews && (
        <>
          <UserViewsSection />
          <TeamViewsSection />
        </>
      )}

      <ExpandableSection
        title='Workspaces'
        isExpanded={sidebarState.sections.workspaces}
        onToggleExpand={(state) =>
          void toggleSidebarSection('workspaces', state)
        }
        actionButton={{
          icon: PlusSolid,
          onClick: () => {
            modalApi.prompt('add-workspace', {
              onValue: (workspaceId) =>
                navigateByRouteId('workspace-detail', { workspaceId }),
            })
          },
        }}
      >
        <FolderTreeview items={items} />

        {hasSearch && items.length === 0 && (
          <div
            className='h-full flex flex-col gap-1 overflow-auto text-sm text-semantic-neutral-text-subtle p-4'
            aria-live='polite'
          >
            <div className='font-semibold'>No results</div>
            <div>Try a different search term.</div>
            <div className='pt-2'>
              <Button
                variant='outlined'
                sentiment='neutral'
                size='small'
                onClick={() => modalApi.open('search')}
              >
                Search tasks instead
              </Button>
            </div>
          </div>
        )}
      </ExpandableSection>
    </div>
  )
}
