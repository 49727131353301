import { type COLOR } from '@motion/shared/common'
import { Button, FormModal, showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { FolderColoredIcon } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { ColorDropdown } from '~/global/components/dropdowns'
import { useFindFolder } from '~/global/hooks/folders'
import { useCreateFolder, useFolders } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useMemo, useState } from 'react'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'create-new-folder': {
      workspaceId: string
    }
  }
}

export const NewFolderModal = ({
  workspaceId,
  close,
}: ModalTriggerComponentProps<'create-new-folder'>) => {
  const [selectedColor, setSelectedColor] = useState<COLOR>('gray')
  const [folderName, setFolderName] = useState('')
  const { data: folders, isInitialLoading } = useFolders()
  const { mutateAsync: createFolder } = useCreateFolder()
  const findFolder = useFindFolder()

  const parentFolderId = useMemo(() => {
    if (isInitialLoading || !folders) return null

    const workspaceFolder = findFolder(
      (folder) => folder.targetId === workspaceId && folder.type === 'WORKSPACE'
    )

    if (!workspaceFolder) {
      Sentry.captureException(new Error('Could not find workspace folder'), {
        extra: {
          workspaceId,
        },
        tags: {
          position: 'NewFolderModal',
        },
      })

      return null
    }

    return workspaceFolder.id
  }, [findFolder, folders, isInitialLoading, workspaceId])

  const onAction = async () => {
    if (!parentFolderId) return

    recordAnalyticsEvent('FOLDERS_CREATED_FOLDER', {
      color: selectedColor, // Determine if users are setting colors or not
    })

    try {
      await createFolder({
        name: folderName,
        color: selectedColor,
        parentFolderId,
      })

      showToast('success', 'Folder created')

      close()
    } catch (e) {
      Sentry.captureException(
        new Error('Could not create workspace folder', {
          cause: e,
        }),
        {
          extra: {
            workspaceId,
          },
          tags: {
            position: 'NewFolderModal',
          },
        }
      )

      showErrorToast(e, 'Could not create folder')
    }
  }

  return (
    <FormModal
      visible
      title='New folder'
      onClose={close}
      submitAction={{
        onAction,
        disabled: !folderName || !parentFolderId,
        text: 'Save',
      }}
    >
      <div className='flex items-center gap-2 self-stretch min-w-[400px]'>
        <ColorDropdown
          selectedColor={selectedColor}
          onChange={setSelectedColor}
        >
          <Button size='small' variant='outlined' sentiment='neutral' iconOnly>
            <FolderColoredIcon color={selectedColor} className='w-4 h-4' />
          </Button>
        </ColorDropdown>

        <div className='w-full'>
          <TextField
            autoFocus
            placeholder='Folder name'
            size='normal'
            value={folderName}
            onChange={setFolderName}
          />
        </div>
      </div>
    </FormModal>
  )
}
