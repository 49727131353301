import { Button } from '@motion/ui/base'
import { type AttendeeStatus } from '@motion/zod/client'

import { useEventFormRSVPField } from '../hooks'

const optionsLabels: Record<AttendeeStatus, string> = {
  accepted: 'Yes',
  declined: 'No',
  tentative: 'Maybe',
  needsAction: 'No RSVP',
}

const options: AttendeeStatus[] = ['accepted', 'declined', 'tentative']

export const EventFormRSVPField = () => {
  const { value, visible, onChange } = useEventFormRSVPField()
  if (!visible) return null
  return (
    <div className='flex items-center gap-1'>
      {options.map((option) => {
        const isSelected = option === value
        if (isSelected) {
          return (
            <Button
              key={`rsvp-option-${option}`}
              size='xsmall'
              variant='outlined'
            >
              {optionsLabels[option]}
            </Button>
          )
        }
        return (
          <Button
            key={`rsvp-option-${option}`}
            size='xsmall'
            variant='outlined'
            sentiment='neutral'
            onClick={() => {
              onChange(option)
            }}
          >
            {optionsLabels[option]}
          </Button>
        )
      })}
    </div>
  )
}
