import { type RecursiveFolderItemSchema } from '@motion/rpc-types'
import { type SearchableTreeNode } from '@motion/ui/base'
import { byProperty, cascade, Compare } from '@motion/utils/array'

import { useAllProjects, useGetWorkspaceByFolderId } from '~/global/hooks'
import { useGetFolderById } from '~/global/hooks/folders'
import { useFolders } from '~/global/rpc/folders'
import { useCallback, useMemo } from 'react'

import { generateWorkspaceTreeNodeId, openPath } from '../utils'

export type LeafNodeType = 'WORKSPACE' | 'FOLDER' | 'PROJECT'

interface UseWorkspacesDropdownTreeArgs {
  selectedId: string | null
  leafNodeType: LeafNodeType
  noneItemLabel?: string
  computeDisabled?: (workspaceId: string) => boolean
  hideNoProject: boolean
}

interface SelectedNode {
  id: string | null
  path?: string[]
}

export const useWorkspacesDropdownTree = ({
  selectedId,
  leafNodeType,
  noneItemLabel,
  computeDisabled,
  hideNoProject,
}: UseWorkspacesDropdownTreeArgs): SearchableTreeNode => {
  const { data: folders } = useFolders()
  const getWorkspaceByFolderId = useGetWorkspaceByFolderId()
  const getFolderById = useGetFolderById()
  const allProjects = useAllProjects()

  const formatFolderTree = useCallback(
    (
      item: RecursiveFolderItemSchema,
      selectedNode: SelectedNode,
      parentPath: string[] = []
    ): SearchableTreeNode | null => {
      const formatChildren = (currentPath: string[]) =>
        'items' in item
          ? item.items
              .sort(cascade(byProperty('order', Compare.string)))
              .map((child) =>
                formatFolderTree(child, selectedNode, currentPath)
              )
              .filter(Boolean)
          : []

      if (item.itemType === 'FOLDER') {
        const folderModel = getFolderById(item.itemId)
        if (!folderModel) return null

        const workspaceModel = getWorkspaceByFolderId(folderModel.id)
        if (!workspaceModel) return null

        if (folderModel.type === 'WORKSPACE') {
          const id = generateWorkspaceTreeNodeId('WORKSPACE', workspaceModel.id)
          const currentPath = [...parentPath, id]
          const children =
            noneItemLabel && !hideNoProject
              ? [
                  {
                    id: `NONE|${workspaceModel.id}`,
                    label: noneItemLabel,
                    path: currentPath,
                  },
                  ...formatChildren(currentPath),
                ]
              : formatChildren(currentPath)

          if (selectedNode.id && workspaceModel.id === selectedNode.id) {
            selectedNode.path = currentPath
          }

          return {
            id,
            label: workspaceModel.name,
            path: currentPath,
            disabled: computeDisabled?.(workspaceModel.id) ?? false,
            nodes:
              leafNodeType === 'WORKSPACE' && children.length === 0
                ? undefined
                : children,
          }
        }

        if (leafNodeType === 'WORKSPACE') return null

        const id = generateWorkspaceTreeNodeId('FOLDER', folderModel.id)
        const currentPath = [...parentPath, id]
        const children = formatChildren(currentPath)

        if (selectedNode.id && folderModel.id === selectedNode.id) {
          selectedNode.path = currentPath
        }

        return {
          id,
          label: folderModel.name ?? '(no name)',
          path: currentPath,
          nodes:
            leafNodeType === 'FOLDER' && children.length === 0
              ? undefined
              : children,
        }
      }

      if (leafNodeType !== 'PROJECT') return null

      if (item.itemType === 'PROJECT') {
        const projectModel = allProjects.find(
          (project) => item.itemId === project.id
        )
        if (!projectModel) return null

        const id = generateWorkspaceTreeNodeId('PROJECT', projectModel.id)
        const currentPath = [...parentPath, id]
        if (selectedNode.id && projectModel.id === selectedNode.id) {
          selectedNode.path = currentPath
        }
        return {
          id,
          label: projectModel.name,
          path: currentPath,
        }
      }

      return null
    },
    [
      leafNodeType,
      getFolderById,
      getWorkspaceByFolderId,
      noneItemLabel,
      hideNoProject,
      computeDisabled,
      allProjects,
    ]
  )

  return useMemo(() => {
    if (!folders?.models.systemFolders.workspaces) {
      return { id: '[ROOT]', label: '[ROOT]', path: ['[ROOT]'], nodes: [] }
    }

    const selectedNode: SelectedNode = { id: selectedId }
    const tree = {
      id: '[ROOT]',
      label: '[ROOT]',
      path: ['[ROOT]'],
      nodes: folders.models.systemFolders.workspaces.items
        .sort(cascade(byProperty('order', Compare.string)))
        .map((item) => formatFolderTree(item, selectedNode, ['[ROOT]']))
        .filter(Boolean),
    }

    return openPath(tree, selectedNode.path)
  }, [selectedId, folders, formatFolderTree])
}
