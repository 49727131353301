import { API, type ApiUseMutationOptions, createUseMutation } from '@motion/rpc'
import { type OptimisticUpdateValue, useStoreModelsFn } from '@motion/rpc-cache'
import { type CustomFieldSchema } from '@motion/shared/custom-fields'

import { useQueryClient } from '@tanstack/react-query'
import { updateCacheCustomFields } from '~/global/cache'

const useCreateCustomFieldMutation = createUseMutation(
  API.customFields.createCustomField
)
export const useCreateCustomField = (
  opts?: ApiUseMutationOptions<typeof API.customFields.createCustomField>
) => {
  const client = useQueryClient()
  const storeModels = useStoreModelsFn()

  return useCreateCustomFieldMutation({
    onSuccess: (data) => {
      storeModels(data.models)

      const newField = data.models.customFields[data.id]

      if (newField == null) {
        return
      }

      updateCacheCustomFields(client, newField)
    },
    ...opts,
  })
}

const useUpdateCustomFieldMutation = createUseMutation(
  API.customFields.updateCustomField
)
export const useUpdateCustomField = (
  opts?: ApiUseMutationOptions<typeof API.customFields.updateCustomField>
) => {
  const client = useQueryClient()
  const storeModels = useStoreModelsFn()

  return useUpdateCustomFieldMutation({
    onMutate: async ({ customFieldId: id, ...args }) => {
      return updateCacheCustomFields(client, {
        id,
        ...args,
      } as Partial<CustomFieldSchema> & { id: string })
    },
    onSuccess: (data) => {
      storeModels(data.models)

      const updatedField = data.models.customFields[data.id]

      if (updatedField == null) {
        return
      }

      updateCacheCustomFields(client, updatedField)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
    ...opts,
  })
}

export const useDeleteCustomField = createUseMutation(
  API.customFields.deleteCustomField
)
