import { type MeetingUrlSearchParams } from '@motion/ui-logic'

import { useSearchParams } from '~/routing'

import { ConnectedEventModal } from './event-modal'
import { useCloseMeetingModal } from './hooks'

type ConnectedMeetingModalProps = {
  open: boolean
}

export function ConnectedMeetingModal({ open }: ConnectedMeetingModalProps) {
  const closeModal = useCloseMeetingModal()
  const { mTask } = useSearchParams<MeetingUrlSearchParams>()

  return (
    <ConnectedEventModal open={open} close={closeModal} meetingTaskId={mTask} />
  )
}
