import { Checkbox } from '@motion/ui/forms'
import { cloneDeep } from '@motion/utils/core'

import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import { useCallback, useState } from 'react'

import {
  Paragraph,
  Popover,
  PrimaryButton,
  SecondaryButton,
  SubParagraph,
} from '../../../../../Common'
import { type SlotType } from '../AvailabilityTimePickerModal'

const initialCopyDays = {
  Friday: false,
  Monday: false,
  Saturday: false,
  Sunday: false,
  Thursday: false,
  Tuesday: false,
  Wednesday: false,
}

const dayIndices: { [day: string]: number } = {
  Friday: 5,
  Monday: 1,
  Saturday: 6,
  Sunday: 0,
  Thursday: 4,
  Tuesday: 2,
  Wednesday: 3,
}

const DaysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export interface TimePickerCopyTimesDropdownProps {
  currentDay: string
  slots: SlotType[]
  setSlotsHandler: (slots: SlotType[]) => void
}

export const TimePickerCopyTimesDropdown = ({
  currentDay,
  slots,
  setSlotsHandler = () => {},
}: TimePickerCopyTimesDropdownProps) => {
  const [copyDays, setCopyDays] = useState<{ [day: string]: boolean }>(
    initialCopyDays
  )

  const copyToDays = useCallback(
    (sourceDay: string, closeHandler: () => void) => {
      const targetDays = Object.keys(copyDays).filter((day) => copyDays[day])
      let newSlots = [...slots]
      const sourceDayIdx = dayIndices[sourceDay]
      const sourceDaySlots = newSlots.filter(
        (slot) => DateTime.fromISO(slot.start).toFormat('cccc') === sourceDay
      )
      targetDays.forEach((day) => {
        const translatedSlots = sourceDaySlots.map((slot) => {
          const newSlot = cloneDeep(slot)
          const diff = dayIndices[day] - sourceDayIdx
          newSlot.id = nanoid()
          newSlot.start = DateTime.fromISO(newSlot.start)
            .plus({ days: diff })
            .toISO()
          newSlot.end = DateTime.fromISO(newSlot.end)
            .plus({ days: diff })
            .toISO()
          return newSlot
        })
        newSlots = newSlots.filter(
          (slot) => DateTime.fromISO(slot.start).toFormat('cccc') !== day
        )
        newSlots = newSlots.concat(translatedSlots)
      })
      setSlotsHandler(newSlots)
      closeHandler()
    },
    [copyDays, slots] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const toggleDay = useCallback((day: string) => {
    setCopyDays((prev) => {
      const newObj = { ...prev }
      newObj[day] = !newObj[day]
      return newObj
    })
  }, [])

  return (
    <Popover
      className='w-auto'
      trigger={
        <SecondaryButton
          size='small'
          onClick={() => setCopyDays(initialCopyDays)}
        >
          Copy
        </SecondaryButton>
      }
    >
      {({ close }: { close: () => void }) => (
        <div className='flex w-44 flex-col py-2'>
          <Paragraph className='px-2'>Copy times to...</Paragraph>
          {DaysOfWeek.map((day) => {
            return (
              <label
                key={`${currentDay}-${day}`}
                className='dark:hover:bg-dark-400 hover:bg-light-300 flex cursor-pointer items-center justify-between p-2 px-2'
              >
                <SubParagraph>{day}</SubParagraph>
                <Checkbox
                  checked={currentDay === day || copyDays[day]}
                  onChange={() => {
                    if (currentDay !== day) {
                      toggleDay(day)
                    }
                  }}
                  label='Copy to {day}'
                  labelHidden
                />
              </label>
            )
          })}
          <PrimaryButton
            onClick={() => copyToDays(currentDay, close)}
            className='mx-2 mt-2'
          >
            Apply
          </PrimaryButton>
        </div>
      )}
    </Popover>
  )
}
