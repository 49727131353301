import { getDefaultChunkDuration } from '@motion/ui-logic'

import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useFlowTemplateForm, useInitialFormData } from './hooks'
import { getIndexFromName } from './utils'

export type FlowTemplateFormProps = {
  children: ReactNode
}

export const FlowTemplateForm = ({ children }: FlowTemplateFormProps) => {
  const initialFormData = useInitialFormData()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  return (
    <FormProvider {...form}>
      <FlowFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const FlowFormValuesUpdater = () => {
  const { form } = useFlowTemplateForm()

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name == null) return

      if (
        name.includes('assigneeUserId') ||
        name.includes('assigneeVariableKey')
      ) {
        const { stageIndex, taskIndex } = getIndexFromName(name)

        if (stageIndex == null || taskIndex == null) return

        const isUnassigned = !(
          form.getValues(
            `stages.${stageIndex}.tasks.${taskIndex}.assigneeUserId`
          ) ??
          form.getValues(
            `stages.${stageIndex}.tasks.${taskIndex}.assigneeVariableKey`
          )
        )

        if (isUnassigned) {
          form.setValue(
            `stages.${stageIndex}.tasks.${taskIndex}.isAutoScheduled`,
            false
          )
        }
      }

      // Set the minimum duration to the default chunk duration when the duration changes
      if (name.includes('duration')) {
        const { stageIndex, taskIndex } = getIndexFromName(name)
        if (stageIndex == null || taskIndex == null) return

        const duration = form.getValues(
          `stages.${stageIndex}.tasks.${taskIndex}.duration`
        )

        form.setValue(
          `stages.${stageIndex}.tasks.${taskIndex}.minimumDuration`,
          getDefaultChunkDuration(duration)
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [form])

  return null
}
