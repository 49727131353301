import {
  CalendarPlusSolid,
  CalendarSolid,
  CheckSolid,
  DuplicateOutline,
  RefreshSolid,
  StatusCanceledSolid,
  TaskSolid,
} from '@motion/icons'
import { isCanceledStatus } from '@motion/shared/common'
import {
  Button,
  ButtonGroup,
  IconButton,
  Tag,
  Tooltip,
  useShortcut,
} from '@motion/ui/base'
import { isSchedulingTask, isTaskSchema } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import {
  LastSavedIndicator,
  SchedulingEventDropdown,
} from '~/areas/task-project/components'
import { useDuplicateTask, useResolveTask } from '~/areas/tasks/hooks'
import {
  useCopyTaskLinkToClipboard,
  useWorkspaceStatusById,
} from '~/global/hooks'
import { useMeetingModalUrl } from '~/global/navigation'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useController } from 'react-hook-form'

import { TemplateDropdown } from './template-dropdown'

import { DotsButton } from '../components'
import { useTaskModalState } from '../contexts'
import { useTaskForm } from '../hooks'

export const TaskHeader = () => {
  const { form } = useTaskForm()
  const {
    initialTaskData: { task: initialTask },
  } = useTaskModalState()

  const { watch } = form

  const taskId = watch('id')

  const isArchived = isTaskSchema(initialTask)
    ? initialTask.archivedTime != null
    : null
  const isCompleted = isTaskSchema(initialTask)
    ? initialTask.completedTime != null
    : null
  const isSchedulingTaskType = isSchedulingTask(initialTask)

  const statusId = watch('statusId')
  const status = useWorkspaceStatusById(statusId)
  const showAs =
    isCompleted && !isArchived
      ? 'completed'
      : isCanceledStatus(status)
        ? 'canceled'
        : 'normal'

  return (
    <header className='grid grid-cols-[1fr_auto] auto-rows-max electron-drag'>
      <div className='flex flex-row items-center gap-1 text-semantic-neutral-text-disabled text-xs'>
        <TaskTag showAs={showAs} isScheduleTask={isSchedulingTaskType} />
        {taskId != null && <LastSavedIndicator />}
      </div>
      {taskId == null ? (
        <CreateTaskHeaderActions />
      ) : (
        <EditTaskHeaderActions taskId={taskId} />
      )}
    </header>
  )
}

type TaskTagProps = {
  showAs: 'completed' | 'canceled' | 'normal'
  isScheduleTask?: boolean
}

const TaskTag = ({ showAs, isScheduleTask }: TaskTagProps) => {
  if (showAs === 'completed')
    return (
      <Tag variant='subtle' color='green'>
        <div className='flex items-center gap-1 !font-medium'>
          <CheckSolid className='h-4 w-4' /> Task completed
        </div>
      </Tag>
    )
  if (showAs === 'canceled')
    return (
      <Tag variant='subtle'>
        <div className='flex items-center gap-1 !font-medium'>
          <StatusCanceledSolid className='size-4' /> Task canceled
        </div>
      </Tag>
    )
  return (
    <>
      <TaskSolid className='w-4 h-4' /> Task
      {isScheduleTask && ' (schedule event)'}
    </>
  )
}

function CreateTaskHeaderActions() {
  const { form } = useTaskForm()

  const { control } = form

  const { field } = useController({
    name: 'type',
    control,
  })

  const toggleType = () => {
    field.onChange(
      field.value === 'RECURRING_TASK' ? 'NORMAL' : 'RECURRING_TASK'
    )
  }

  return (
    <ButtonGroup size='small'>
      <TemplateDropdown />
      <Button
        sentiment={field.value === 'RECURRING_TASK' ? 'primary' : 'neutral'}
        size='small'
        variant={field.value === 'RECURRING_TASK' ? 'outlined' : 'muted'}
        onClick={() => toggleType()}
      >
        <RefreshSolid />
        Recurring
      </Button>
    </ButtonGroup>
  )
}

type EditTaskHeaderActionsProps = {
  taskId: TaskSchema['id']
}
function EditTaskHeaderActions({ taskId }: EditTaskHeaderActionsProps) {
  const { form } = useTaskForm()
  const {
    initialTaskData: { task: initialTask },
  } = useTaskModalState()
  const { completeTask } = useResolveTask()
  const duplicateTask = useDuplicateTask()
  const readTask = useReadTaskFn()
  const copyTaskLinkToClipboard = useCopyTaskLinkToClipboard()

  const { watch } = form

  const type = watch('type')
  const archivedTime = isTaskSchema(initialTask)
    ? initialTask.archivedTime
    : null
  const completedTime = isTaskSchema(initialTask)
    ? initialTask.completedTime
    : null

  const isArchived = archivedTime != null
  const isCompleted = completedTime != null

  const isSchedulingTaskType = isSchedulingTask(initialTask)
  const meetingTaskId =
    initialTask?.type === 'NORMAL' ? initialTask.meetingTaskId : null

  const copyTaskLink = () => {
    copyTaskLinkToClipboard({
      workspaceId: form.getValues('workspaceId'),
      taskName: form.getValues('name'),
      taskId,
    })
  }

  useShortcut('mod+l', copyTaskLink)
  useShortcut('mod+d', async () => {
    const task = await readTask(taskId)
    if (task == null || task?.type === 'RECURRING_TASK') return
    duplicateTask(task)
  })

  const renderButton = () => {
    if (meetingTaskId != null) {
      return <GoToMeetingButton meetingTaskId={meetingTaskId} />
    }

    if (isSchedulingTaskType) {
      return (
        <SchedulingEventDropdown taskId={taskId}>
          <Button size='small' sentiment='primary' variant='outlined'>
            <CalendarPlusSolid />
            Schedule event
          </Button>
        </SchedulingEventDropdown>
      )
    }

    if (!isArchived && !isCompleted && type !== 'RECURRING_TASK') {
      return (
        <Button
          size='small'
          variant='outlined'
          sentiment='neutral'
          onClick={() => completeTask(taskId)}
        >
          <CheckSolid />
          Mark complete
        </Button>
      )
    }
  }

  return (
    <ButtonGroup size='small'>
      {renderButton()}

      <Tooltip asChild content='Copy task link'>
        <IconButton
          icon={DuplicateOutline}
          onClick={copyTaskLink}
          variant='muted'
          sentiment='neutral'
          size='small'
        />
      </Tooltip>
      <DotsButton taskId={taskId} />
    </ButtonGroup>
  )
}

type GoToMeetingButtonProps = {
  meetingTaskId: string
}

function GoToMeetingButton({ meetingTaskId }: GoToMeetingButtonProps) {
  const buildMeetingModalUrl = useMeetingModalUrl()

  return (
    <Button
      size='small'
      sentiment='primary'
      variant='outlined'
      url={buildMeetingModalUrl({
        mTask: meetingTaskId,
      })}
    >
      <CalendarSolid />
      Go to meeting
    </Button>
  )
}
