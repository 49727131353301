import { type Calendar } from '@motion/rpc/types'

import {
  selectAllCalendars,
  selectFrequentlyMetCalendars,
  selectMyCalendars,
} from '~/state/calendar-list/calendar-list-slice'
import { updateCalendars } from '~/state/calendar-list/calendar-list-thunks'
import {
  selectEmailAccounts,
  selectEmailAccountsMap,
  selectMainEmailAccountId,
} from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'

export function useCalendarList() {
  const dispatch = useAppDispatch()

  const calendars = useAppSelector(selectAllCalendars)
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const mainEmailAccountId = useAppSelector(selectMainEmailAccountId)
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const frequentlyMetCalendars = useAppSelector(selectFrequentlyMetCalendars)
  const myCalendars = useAppSelector(selectMyCalendars)

  const onCreateNewCalendars = async (
    calendars: Calendar[]
  ): Promise<Calendar[]> => {
    const updatedCalendars = await dispatch(
      updateCalendars(
        calendars.map((c) => ({
          id: c.id,
          emailAccountId: c.emailAccountId,
          isEnabled: false,
          isInFrequentlyMet: true,
          isNew: true,
          title: c.title,
        }))
      )
    ).unwrap()

    const ids = calendars.map((c) => `${c.emailAccountId}${c.title}`)

    // `updatedCalendars` are ALL calendars for the specific user. Filter down
    // to the calendars we just created
    return updatedCalendars.filter((c) =>
      ids.includes(`${c.emailAccountId}${c.title}`)
    )
  }

  return {
    calendars,
    emailAccounts,
    emailAccountsMap,
    frequentlyMetCalendars,
    mainEmailAccountId,
    myCalendars,
    onCreateNewCalendars,
  }
}
