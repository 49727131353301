import { SearchSolid } from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { TextField } from '@motion/ui/forms'

import { AttachmentUploadButton } from '~/areas/attachments'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useState } from 'react'

import { AttachmentsTabProjectTargetItem } from './components/attachments-tab-project-target-item'
import { AttachmentsTabTaskTargetItem } from './components/attachments-tab-task-target-item'

type AttachmentsTabProps = {
  project: ProjectSchema
  tasks: NormalTaskWithRelations[]
}

export function AttachmentsTab({ project, tasks }: AttachmentsTabProps) {
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <div className='w-full h-full overflow-auto rounded-b-lg shadow-lg'>
      <div className='flex gap-2 p-3'>
        <TextField
          size='small'
          rounded='default'
          prefix={<SearchSolid />}
          placeholder='Search'
          value={searchQuery}
          onChange={setSearchQuery}
          fullWidth
          showClearButton
        />

        <AttachmentUploadButton
          targetId={project.id}
          targetType='PROJECT'
          workspaceId={project.workspaceId}
          label='Add'
          size='small'
        />
      </div>
      <div className='flex flex-col px-[6px] pb-[6px]'>
        <AttachmentsTabProjectTargetItem
          project={project}
          searchQuery={searchQuery}
        />
        {tasks.map((task) => (
          <AttachmentsTabTaskTargetItem
            key={task.id}
            task={task}
            searchQuery={searchQuery}
          />
        ))}
      </div>
    </div>
  )
}
