import {
  EyeSolid,
  MenuAlt2Solid,
  PencilSolid,
  PlannerSolid,
  PlusSolid,
  TrashSolid,
  ViewBoardsSolid,
} from '@motion/icons'
import {
  ActionList,
  Button,
  IconButton,
  PopoverTrigger,
  SearchableList,
  Tooltip,
} from '@motion/ui/base'
import { type SearchableListSectionedSelectProps } from '@motion/ui/base'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  ordered,
} from '@motion/utils/array'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useMemo } from 'react'

import { isDefaultView } from '../defaults'

type SelectViewButtonProps = {
  views: VersionedViewV2[]
  selected: VersionedViewV2

  onSelect(view: VersionedViewV2): void
  onDelete(view: VersionedViewV2): void
  onEdit(view: VersionedViewV2): void
  onCreate(): void

  computeDisabled?(view: VersionedViewV2): boolean
}

interface SectionedViews {
  team: VersionedViewV2[]
  personal: VersionedViewV2[]
}

export const SelectViewButton = (props: SelectViewButtonProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ViewDropdownContent close={close} {...props} />
      )}
    >
      <Button variant='mutedBg' sentiment='neutral' size='small'>
        <EyeSolid />
        <div className='whitespace-nowrap'>{props.selected.name}</div>
      </Button>
    </PopoverTrigger>
  )
}

type ViewDropdownContentProps = SelectViewButtonProps & { close: () => void }
function ViewDropdownContent({
  close,
  views,
  selected,
  onSelect,
  onDelete,
  onCreate,
  onEdit,
  computeDisabled,
}: ViewDropdownContentProps) {
  const sections: SearchableListSectionedSelectProps<VersionedViewV2>['sections'] =
    useMemo(() => {
      const sectionedViews = views.reduce<SectionedViews>(
        (acc, view) => {
          if (isDefaultView(view) || !view.isPrivate) {
            acc.team.push(view)
          } else {
            acc.personal.push(view)
          }
          return acc
        },
        { team: [], personal: [] }
      )

      return [
        {
          key: 'team-views',
          label: 'Team views',
          items: sectionedViews.team.sort(
            cascade(
              byValue((v) => isDefaultView(v), ordered([true, false])),
              byProperty('name', Compare.string)
            )
          ),
          initialExpanded: true,
        },
        {
          key: 'personal-views',
          label: 'Personal views',
          items: sectionedViews.personal.sort(
            byProperty('name', Compare.string)
          ),
          initialExpanded: true,
        },
      ]
    }, [views])

  return (
    <>
      <SearchableList
        itemType='sectioned'
        sections={sections}
        hideEmptySections
        computeKey={(item) => item.id}
        computeSelected={(item) => item.id === selected.id}
        computeSearchValue={(item) => item.name}
        computeDisabled={computeDisabled}
        onSelect={async (item) => {
          onSelect(item)
          close()
        }}
        renderItem={(item) => (
          <ViewItem
            view={item}
            disabled={computeDisabled?.(item) ?? false}
            onDelete={() => {
              onDelete(item)
              close()
            }}
            onEdit={() => {
              onEdit(item)
              close()
            }}
          />
        )}
        inputProps={{ placeholder: 'Search views...' }}
      />
      <div className='flex flex-col border-t border-dropdown-border mt-1'>
        <ActionList
          onActionAnyItem={close}
          items={[
            {
              prefix: <PlusSolid />,
              content: 'Create view',
              onAction: onCreate,
            },
          ]}
        />
      </div>
    </>
  )
}
type ViewItemProps = {
  view: VersionedViewV2
  disabled: boolean
  onEdit(): void
  onDelete(): void
}
function ViewItem(props: ViewItemProps) {
  return (
    <Tooltip
      asChild
      renderContent={
        props.disabled && (() => 'Project views are not valid on Project pages')
      }
    >
      <div className='flex items-center gap-2 w-[250px]'>
        <ViewIcon view={props.view} />
        <div className='flex-1 truncate'>{props.view.name}</div>
        {!props.disabled && (
          <ViewActions
            view={props.view}
            disabled={props.disabled}
            onDelete={(v) => {
              props.onDelete()
            }}
            onEdit={(v) => {
              props.onEdit()
            }}
          />
        )}
      </div>
    </Tooltip>
  )
}

function ViewIcon({ view }: { view: VersionedViewV2 }) {
  if (view.type === 'team-schedule') {
    return <EyeSolid />
  }

  let Icon
  switch (view.definition.layout) {
    case 'list':
      Icon = MenuAlt2Solid
      break
    case 'kanban':
      Icon = ViewBoardsSolid
      break
    case 'gantt':
      Icon = PlannerSolid
      break
    case 'planner':
      Icon = PlannerSolid
      break
    default:
      Icon = MenuAlt2Solid
  }

  return (
    <Icon className='shrink-0 w-4 h-4 text-semantic-neutral-icon-default' />
  )
}

interface ViewActionsProps {
  view: VersionedViewV2
  onDelete: (view: VersionedViewV2) => void
  onEdit?: (view: VersionedViewV2) => void
  disabled?: boolean
}
function ViewActions(props: ViewActionsProps) {
  const { view, onDelete, onEdit } = props
  if (isDefaultView(view)) return null
  if (props.disabled === true) return null

  return (
    <div className='hidden group-hover/menuitem:block'>
      {onEdit && (
        <IconButton
          icon={PencilSolid}
          onClick={(evt) => {
            evt.preventDefault()
            evt.stopPropagation()
            onEdit(view)
          }}
          size='small'
          sentiment='neutral'
          variant='muted'
        />
      )}
      <IconButton
        icon={TrashSolid}
        onClick={(evt) => {
          evt.preventDefault()
          evt.stopPropagation()
          onDelete(view)
        }}
        size='small'
        sentiment='neutral'
        variant='muted'
      />
    </div>
  )
}
