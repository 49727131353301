import { dereferenceToken } from '@motion/rpc'

import { type HandlerContext } from './types'

export function buildQueryContext(ctx: HandlerContext) {
  return {
    token: () => dereferenceToken(ctx.rpcContext.token),
    baseUri: ctx.rpcContext.baseUri,
    headers: ctx.rpcContext.headers,
    executor: ctx.rpcContext.executor,
    client: ctx.client,
  }
}

export type MutationIds = {
  create: string[]
  update: string[]
  delete: string[]
}

export function mergeMutations(mutations: Partial<MutationIds>[]): MutationIds {
  return mutations.reduce(
    (acc: MutationIds, cur) => {
      cur.create && acc.create.push(...cur.create)
      cur.update && acc.update.push(...cur.update)
      cur.delete && acc.delete.push(...cur.delete)
      return acc
    },
    { create: [], update: [], delete: [] } as MutationIds
  )
}
