import { BlockedBySolid, BlocksSolid } from '@motion/icons'
import { templateStr } from '@motion/ui-logic'

import { Fragment } from 'react'

import { ActivityTaskLink } from '../components'

export function handleBlocks(
  type: 'blockedBy' | 'blocking',
  newBlocks: string[],
  oldBlocks: string[]
) {
  if (newBlocks.length === 0 && oldBlocks.length === 0) {
    return null
  }

  const Icon = type === 'blockedBy' ? BlockedBySolid : BlocksSolid

  const isSet = newBlocks.length > oldBlocks.length

  const actionText =
    (isSet ? 'set' : 'removed') +
    ' this task as ' +
    (type === 'blockedBy' ? 'being blocked by' : 'blocking')

  const blocks = isSet ? newBlocks : oldBlocks

  return {
    icon: (
      <Icon
        className={
          // These icons stay the same color when field is disabled
          type === 'blockedBy'
            ? '!text-semantic-error-icon-default'
            : '!text-semantic-warning-icon-default'
        }
      />
    ),
    children: templateStr(`{{action}} {{task}}`, {
      action: actionText,
      task: (
        <>
          {blocks.map((taskId, index) => (
            <Fragment key={taskId}>
              {index > 0 && ', '}
              <ActivityTaskLink key={taskId} taskId={taskId} />
            </Fragment>
          ))}
        </>
      ),
    }),
  }
}
