import { useSharedState } from '@motion/react-core/shared-state'
import { ActiveFilterKey, type FilterTarget } from '@motion/ui-logic/pm/data'

import { useProxyLookup } from '~/global/cache'
import { AppWorkspaceContext } from '~/global/contexts'
import {
  type ProjectWithRelations,
  type UnchunkedTaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import { groupByCustomField } from '../custom-fields'
import { useAvailableCustomFieldsGroupedByTypedName } from '../custom-fields/hooks'
import {
  type GroupArgs,
  groupByActiveStage,
  groupByAssignee,
  groupByCompletedAt,
  groupByCreatedAt,
  groupByCreatedBy,
  groupByDeadline,
  groupByFolder,
  groupByLabel,
  groupByManager,
  groupByPriority,
  groupByProject,
  groupByProjectDefinition,
  groupByScheduledDate,
  groupByStage,
  groupByStartDate,
  groupByStatus,
  groupByUpdatedAt,
  groupByWorkspace,
} from '../definitions'
import { type GroupDefinition } from '../utils'

export const useAvailableGroups = (entity: FilterTarget) => {
  const taskGroups = useAvailableTaskGroups()
  const projectGroups = useAvailableProjectGroups()

  return entity === 'projects' ? projectGroups : taskGroups
}

export const useAvailableTaskGroups = () => {
  const lookup = useProxyLookup()
  const [ctx] = useSharedState(AppWorkspaceContext)
  const [filter] = useSharedState(ActiveFilterKey)

  const args: GroupArgs = useMemo(
    () => ({ ctx, lookup, filter }),
    [ctx, lookup, filter]
  )
  const groupedCustomFields = useGroupByCustomFields(args)

  return useMemo(
    () => [...getAvailableBuiltInTaskGroups(args), ...groupedCustomFields],
    [args, groupedCustomFields]
  )
}

const getAvailableBuiltInTaskGroups = (
  args: GroupArgs
): GroupDefinition<UnchunkedTaskWithRelations>[] => {
  return [
    groupByWorkspace(args),
    groupByProject(args),
    groupByStage(args),
    groupByStatus<UnchunkedTaskWithRelations>(args),
    groupByPriority<UnchunkedTaskWithRelations>(args),
    groupByAssignee<UnchunkedTaskWithRelations>(args),
    groupByCreatedBy<UnchunkedTaskWithRelations>(args),
    groupByLabel(args),
    groupByDeadline(args),
    groupByCompletedAt(args),
    groupByCreatedAt(args),
    groupByUpdatedAt(args),
    groupByStartDate(args),
    groupByScheduledDate(args),
  ]
}

export const useAvailableProjectGroups = () => {
  const lookup = useProxyLookup()
  const [ctx] = useSharedState(AppWorkspaceContext)
  const [filter] = useSharedState(ActiveFilterKey)

  const args: GroupArgs = useMemo(
    () => ({ ctx, lookup, filter }),
    [ctx, lookup, filter]
  )
  const groupedCustomFields = useGroupByCustomFields(args)

  return useMemo(
    () => [...getAvailableBuiltInProjectGroups(args), ...groupedCustomFields],
    [args, groupedCustomFields]
  )
}

const getAvailableBuiltInProjectGroups = (
  args: GroupArgs
): GroupDefinition<ProjectWithRelations>[] => {
  return [
    groupByWorkspace(args),
    groupByActiveStage(args),
    groupByStatus(args),
    groupByPriority(args),
    groupByCreatedBy(args),
    groupByStartDate(args),
    groupByLabel(args),
    groupByManager(args),
    groupByDeadline(args),
    groupByCreatedAt(args),
    groupByUpdatedAt(args),
    groupByProjectDefinition(args),
    groupByFolder(args),
  ]
}

function useGroupByCustomFields(args: GroupArgs) {
  const availableCustomFieldsByTypedName =
    useAvailableCustomFieldsGroupedByTypedName()

  return useMemo(
    () =>
      Object.values(availableCustomFieldsByTypedName)
        .map((fields) => groupByCustomField(args, fields))
        .filter(Boolean),
    [args, availableCustomFieldsByTypedName]
  )
}
