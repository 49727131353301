import { createContext, type ReactNode, useMemo, useState } from 'react'

export type ProjectModalState = {
  hasPendingComment: boolean
  setHasPendingComment: (value: boolean) => void
}

const defaultValue: ProjectModalState = {
  hasPendingComment: false,
  setHasPendingComment: () => {},
}

export const ProjectModalStateContext = createContext(defaultValue)

export type ProjectModalStateProviderProps = {
  children: ReactNode
}

export function ProjectModalStateProvider({
  children,
}: ProjectModalStateProviderProps) {
  const [hasPendingComment, setHasPendingComment] = useState(false)

  const value = useMemo(() => {
    return {
      hasPendingComment,
      setHasPendingComment,
    }
  }, [hasPendingComment])

  return (
    <ProjectModalStateContext.Provider value={value}>
      {children}
    </ProjectModalStateContext.Provider>
  )
}
