import { type FieldDefinition } from '../../fields'

export function sizeOf(field: FieldDefinition<any>) {
  if (typeof field.size === 'number') return field.size
  return field.size.default
}

const SMALLEST_SUPPORTED_SIZE = 40

export function minSizeOf(field: FieldDefinition<any>) {
  if (typeof field.size === 'number')
    return Math.max(field.size, SMALLEST_SUPPORTED_SIZE)
  return Math.max(field.size.min, SMALLEST_SUPPORTED_SIZE)
}
