import { useSharedState } from '@motion/react-core/shared-state'
import { useShortcut } from '@motion/ui/base'
import {
  getEnabledStagesWithDates,
  type StageWithDates,
} from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import {
  useAdjustProjectDates,
  useUpdateProjectStageDueDate,
} from '~/areas/project/hooks'
import {
  ScrollDeltaKey,
  usePlannerProps,
} from '~/areas/project-management/pages/pm-v3/planner/context'
import { useResizable } from '~/areas/project-management/pages/pm-v3/planner/hooks'
import { useCallback } from 'react'

import { useResizeStageState } from './use-resize-stage-state'

import {
  getStageDistanceFromProjectStart,
  getStageLocation,
  isLastStage,
} from '../utils'

export const useResizeStages = (
  stage: StageWithDates,
  project: ProjectSchema
) => {
  const [plannerProps] = usePlannerProps()
  const updateStage = useUpdateProjectStageDueDate()
  const [scrollDelta] = useSharedState(ScrollDeltaKey)
  const [resizingStagesState, setResizingStagesState] = useResizeStageState()
  const adjustProjectDates = useAdjustProjectDates()

  const { stageDetails: resizingStageDetails } = resizingStagesState

  const handleResizeStart = () => {
    setResizingStagesState({
      isResizing: true,
      stageDetails: {
        stageId: stage.stage.id,
        projectId: project.id,
        originalLeft: getStageDistanceFromProjectStart({
          projectStart: project.startDate || stage.start,
          stageDate: stage.start,
          dayPx: plannerProps.dayPx,
        }),
        originalRight: getStageDistanceFromProjectStart({
          projectStart: project.startDate || stage.start,
          stageDate: stage.due,
          dayPx: plannerProps.dayPx,
        }),
        delta: 0,
      },
    })
  }

  const updateDelta = useCallback(
    (delta: number) => {
      setResizingStagesState((prevState) => {
        if (!prevState.isResizing || !prevState.stageDetails) return prevState
        return {
          ...prevState,
          stageDetails: {
            ...prevState.stageDetails,
            delta: delta + scrollDelta,
          },
        }
      })
    },
    [scrollDelta, setResizingStagesState]
  )

  const handleResizeReset = useCallback(() => {
    setResizingStagesState({
      isResizing: false,
    })
  }, [setResizingStagesState])

  const handleResizeEnd = useCallback(async () => {
    if (
      !resizingStagesState.stageDetails ||
      resizingStagesState.stageDetails.delta === 0
    ) {
      handleResizeReset()
      return
    }

    recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_STAGE_RESIZE_END')

    const stagesWithDates = getEnabledStagesWithDates(project.stages ?? [], {
      start: project.startDate,
      due: project.dueDate,
    })

    const stageToUpdateIndex = stagesWithDates.findIndex(
      (stageWithDates) =>
        stageWithDates.stage.id === resizingStagesState.stageDetails?.stageId
    )

    // If the stage is the last stage, we need to update the project due date
    if (isLastStage(stage.stage.id, project.stages)) {
      let { adjustedEndDate } = getStageLocation({
        dayPx: plannerProps.dayPx,
        stageWithDates: stagesWithDates[stageToUpdateIndex],
        project,
        stageDetails: resizingStagesState.stageDetails,
      })

      await adjustProjectDates(project.id, {
        dueDate: adjustedEndDate.toISODate(),
      })

      handleResizeReset()

      return
    }

    // We are always adjusting the current stages due date
    const stageToUpdate = stagesWithDates[stageToUpdateIndex]

    if (!stageToUpdate) {
      // LOG ERROR
      handleResizeReset()
      return
    }

    // Get the updated stage dates
    let { adjustedEndDate } = getStageLocation({
      dayPx: plannerProps.dayPx,
      stageWithDates: stageToUpdate,
      project,
      stageDetails: resizingStagesState.stageDetails,
    })

    // Update the stage
    await updateStage(
      project.id,
      stageToUpdate?.stage.stageDefinitionId,
      adjustedEndDate.toISODate()
    )
    handleResizeReset()
  }, [
    adjustProjectDates,
    handleResizeReset,
    plannerProps.dayPx,
    project,
    resizingStagesState.stageDetails,
    stage.stage.id,
    updateStage,
  ])

  useShortcut('escape', handleResizeReset, {
    enabled:
      resizingStagesState.isResizing &&
      resizingStageDetails?.stageId === stage.stage.id,
  })

  const [ref] = useResizable({
    onResize: updateDelta,
    onResizeEnd: handleResizeEnd,
    onResizeStart: handleResizeStart,
  })

  return {
    resizingStagesState,
    ref,
  }
}
