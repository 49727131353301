import { Portal } from '@motion/ui/base'
import { type Nullable } from '@motion/utils/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  type CollisionDetection,
  DndContext,
  type DragEndEvent,
  type DragMoveEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  type DroppableContainer,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useMoveProjectInFolder } from '~/areas/folders/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { pastVerticalCenter } from '~/utils/pm-revamp/collision-detection-utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConnectedSortableTreeviewItem, ProjectPlaceholder } from './components'
import {
  adjustTranslate,
  dropAnimationConfig,
  indentationWidth,
  measuringConfiguration,
} from './constants'
import { useGetDragProjection } from './hooks'
import {
  type CollisionData,
  type FlattenedSidebarWorkspacesTreeItem,
  type ItemIdentifier,
  type SortableItemData,
} from './types'
import { flattenTree } from './utils'

import { type SidebarWorkspacesTreeItem } from '../../types'

type FolderTreeviewProps = {
  items: SidebarWorkspacesTreeItem[]
}

export const FolderTreeview = ({
  items: defaultItems,
}: FolderTreeviewProps) => {
  const treeviewRef = useRef<HTMLDivElement>(null)
  const moveProjectInFolder = useMoveProjectInFolder()
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()
  const getDragProjection = useGetDragProjection()

  const [items, setItems] = useState<FolderTreeviewProps['items']>(defaultItems)
  const [optimisticItems, setOptimisticItems] =
    useState<Nullable<FlattenedSidebarWorkspacesTreeItem[]>>(null)

  const [activeId, setActiveId] = useState<ItemIdentifier | null>(null)
  const [overId, setOverId] = useState<ItemIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)

  const flattenedItems = useMemo(() => {
    if (optimisticItems) return optimisticItems

    return flattenTree(items)
  }, [items, optimisticItems])

  const projected =
    activeId && overId
      ? getDragProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null

  const sensors = useSensors(useSensor(PointerSensor))

  const activeItem: Nullable<FlattenedSidebarWorkspacesTreeItem> =
    (activeId ? flattenedItems.find(({ id }) => id === activeId) : undefined) ??
    null

  const collisionDetection: CollisionDetection = useCallback(
    (args) => {
      if (!activeItem) return []

      const activeItemType = activeItem.type
      const activeItemWorkspaceId = activeItem.item.workspaceId

      const filterContainers = (container: DroppableContainer) => {
        const containerData = container.data.current as SortableItemData

        if (activeItemType === 'WORKSPACE') {
          // allowed in root only and the end of a workspace only
          return containerData.type === 'WORKSPACE'
        }

        // We do not support moving folders/projects between workspaces at this time
        if (containerData.item.workspaceId !== activeItemWorkspaceId) {
          return false
        }

        if (activeItemType === 'PROJECT') {
          // allowed inside parent workspace or child folders
          return containerData.type !== 'WORKSPACE'
        }

        if (activeItemType === 'FOLDER') {
          return (
            containerData.type !== 'WORKSPACE' &&
            containerData.parentType !== 'FOLDER'
          )
        }

        return false
      }

      const pointerCollisions = pointerWithin({
        ...args,
        droppableContainers: args.droppableContainers.filter(filterContainers),
      })

      if (pointerCollisions.length > 0) {
        return pointerCollisions.map((col) => {
          col.data = {
            ...col.data,
            collisionType: 'pointerWithin',
          } satisfies CollisionData

          return col
        })
      }

      return pastVerticalCenter({
        ...args,
        droppableContainers: args.droppableContainers.filter(filterContainers),
      }).map((col) => {
        col.data = {
          ...col.data,
          collisionType: 'pastVerticalCenter',
        } satisfies CollisionData

        return col
      })
    },
    [activeItem]
  )

  useEffect(() => {
    setItems(defaultItems)
    setOptimisticItems(null)
  }, [defaultItems])

  return (
    <div ref={treeviewRef} className='space-y-1'>
      <DndContext
        collisionDetection={collisionDetection}
        measuring={measuringConfiguration}
        onDragCancel={resetState}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <SortableContext
          items={flattenedItems}
          strategy={verticalListSortingStrategy}
        >
          {flattenedItems.map((item) => {
            const level =
              item.id === activeId && projected ? projected.level : item.level

            if (
              activeItem &&
              activeItem.item.isContainer &&
              level > activeItem.level
            ) {
              return null
            }

            if (item.type === 'PLACEHOLDER') {
              return <ProjectPlaceholder key={item.id} item={item} />
            }

            return (
              <ConnectedSortableTreeviewItem
                key={item.id}
                item={item}
                level={level}
                projection={projected}
              />
            )
          })}

          <Portal container={document.body}>
            <DragOverlay
              modifiers={[adjustTranslate]}
              dropAnimation={dropAnimationConfig}
            >
              {activeItem ? (
                <ConnectedSortableTreeviewItem
                  item={activeItem}
                  level={activeItem.level}
                  projection={projected}
                  disableDrag
                  isGhost
                />
              ) : null}
            </DragOverlay>
          </Portal>
        </SortableContext>
      </DndContext>
    </div>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    document.body.style.setProperty('cursor', 'grabbing')

    if (!treeviewRef.current) return

    // Prevent the tree from jumping when dragging and the parent container size
    // changes, like when moving a folder or workspace
    treeviewRef.current.style.setProperty(
      'min-height',
      `${treeviewRef.current.scrollHeight}px`
    )
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  async function handleDragEnd({ active }: DragEndEvent) {
    resetState()

    const activeItemData = active.data.current as SortableItemData | undefined

    if (!activeItemData) {
      return void Sentry.captureException(
        new Error('handleDragEnd active item data is undefined'),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    if (!projected) {
      return void Sentry.captureException(
        new Error('handleDragEnd projected state is undefined'),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    if (!projected.hasMoved) return

    setOptimisticItems(projected.newItems)

    const itemType = activeItemData.type

    if (!projected.parentId) {
      // Folders and projects must have a parent ID to continue
      return void Sentry.captureException(
        new Error(
          'Parent folder ID was not set when attempting to move folder/project'
        ),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    const { order, parentId } = projected

    if (itemType === 'WORKSPACE') {
      const parentFolderId = projected.parentId.toString()
      const itemId = activeItemData.item.id

      recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

      try {
        await updateItemInFolder({
          parentFolderId,
          itemId,
          order,
        })
      } catch (e) {
        Sentry.captureException(
          new Error('Failed to move workspace', { cause: e }),
          {
            extra: {
              parentFolderId,
              itemId,
              order,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )
      }

      return
    }

    const parentFolder = projected.newItems.find(
      ({ item }) => item.id === parentId.toString()
    )

    if (!parentFolder) {
      return void Sentry.captureException(
        new Error('Parent folder could not be found in flattened folder tree'),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    const parentFolderId = parentFolder.item.itemId

    if (itemType === 'FOLDER') {
      if (projected.level > 1 || parentFolder.type !== 'WORKSPACE') {
        // You can't nest folders yet
        return void Sentry.captureException(
          new Error('Nested folders are not allowed'),
          {
            extra: {
              activeId,
              overId,
              projected,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )
      }

      const itemId = activeItemData.item.id

      recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

      try {
        await updateItemInFolder({
          parentFolderId,
          itemId,
          order,
        })
      } catch (e) {
        Sentry.captureException(
          new Error('Failed to update item in folder', { cause: e }),
          {
            extra: {
              parentFolderId,
              itemId,
              order,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )
      }

      return
    }

    if (itemType === 'PROJECT') {
      const projectId = activeItemData.item.itemId

      recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

      try {
        await moveProjectInFolder(projectId, parentFolderId, order)
      } catch (e) {
        Sentry.captureException(
          new Error('Failed to update project in folder', { cause: e }),
          {
            extra: {
              projectId,
              parentFolderId,
              order,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )
      }
    }
  }

  function resetState() {
    setActiveId(null)
    setOffsetLeft(0)
    setOptimisticItems(null)
    setOverId(null)

    document.body.style.setProperty('cursor', '')

    treeviewRef.current?.style.removeProperty('min-height')
  }
}
