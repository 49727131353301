import {
  CheckCircleSolid,
  FilledChevronDownSolid,
  MinusCircleSolid,
  UserOutline,
  UserSolid,
  UsersSolid,
  XCircleSolid,
  XSolid,
} from '@motion/icons'
import { type Contact } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { FieldButton, IconButton, UserAvatarList } from '@motion/ui/base'
import { Label } from '@motion/ui/pm'
import { type AttendeeStatus } from '@motion/zod/client'

import { ContactLabel } from '~/areas/event/components'
import { type ReactNode, useEffect, useState } from 'react'

import { EventFormContactsSearch } from './event-form-contacts-autocomplete'
import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { type CalendarEventAttendee } from '../../../../../../types/CalendarEventAttendee'
import { useConferenceSettingsApi } from '../../../../../settings/state'
import { useEventFormOptions } from '../event-form-options-context'
import {
  useEventForm,
  useEventFormConferencingField,
  useEventGuestField,
} from '../hooks'

const MAX_NUM_ATTENDEES_TO_DEFAULT_EXPANDED = 7

export const EventFormGuestsField = () => {
  const form = useEventForm()
  const { watch } = form
  const eventId = watch('id')
  const { selectConferenceSettings } = useConferenceSettingsApi()
  const conferenceSettings = selectConferenceSettings()

  const { isReadOnly, hostEmailAccount } = useEventFormOptions()
  const { field, onChange } = useEventGuestField()
  const { field: conferencingField, onChange: conferencingOnChange } =
    useEventFormConferencingField()
  const attendees = field.value
  // only used if attendees.length > MAX_NUM_ATTENDEES_TO_DEFAULT_EXPANDED
  const [isExpanded, setIsExpanded] = useState(false)
  const isCollapsed =
    attendees.length > MAX_NUM_ATTENDEES_TO_DEFAULT_EXPANDED && !isExpanded

  const handleAddContactAsAttendee = (contact: Contact) => {
    if (!hostEmailAccount) return
    const currentGuests = [...field.value]

    if (currentGuests.length === 0) {
      currentGuests.unshift({
        email: hostEmailAccount.email,
        displayName: hostEmailAccount.name ?? hostEmailAccount.email,
        isOptional: false,
        isOrganizer: true,
        status: 'accepted',
      })

      if (conferencingField && conferencingField.value === 'none') {
        void conferencingOnChange(conferenceSettings?.conferenceType ?? 'none')
      }
    }

    const alreadyGuest = currentGuests.some(
      (attendee) => attendee.email === contact.email
    )

    if (!alreadyGuest) {
      currentGuests.push({
        email: contact.email,
        displayName: contact.displayName ?? contact.email,
        isOptional: false,
        isOrganizer: false,
        status: 'needsAction',
      })
    }
    setIsExpanded(true)
    onChange(currentGuests)
  }

  const handleRemoveGuest = (email: string) => {
    onChange(field.value.filter((x) => x.email !== email))
  }

  useEffect(() => {
    if (
      !conferenceSettings ||
      conferenceSettings.conferenceType === 'none' ||
      Boolean(eventId) ||
      field.value.length === 0 ||
      conferencingField.value !== 'none'
    ) {
      return
    }
    void conferencingOnChange(conferenceSettings.conferenceType ?? 'none')
    // We really only want this to be executed when the peek modal mounts or the conference zoom account is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceSettings?.zoomAccount])

  const handleEditGuest = (
    email: string | undefined,
    updates: Partial<CalendarEventAttendee>
  ) => {
    if (!email) return
    onChange(
      field.value.map((x) => {
        if (x.email !== email) return x
        return { ...x, ...updates }
      })
    )
  }

  if (attendees.length === 0 && isReadOnly) return null

  if (isCollapsed) {
    return (
      <div className='px-2 my-2'>
        <EventFormGuestsFieldCollapsed
          attendees={attendees}
          onExpand={() => setIsExpanded(true)}
        />
      </div>
    )
  }
  return (
    <div className='px-2 my-2'>
      <EventFormGuestsFieldExpanded
        attendees={attendees}
        readOnly={isReadOnly}
        onAddContactAsAttendee={handleAddContactAsAttendee}
        onRemoveGuest={handleRemoveGuest}
        onEditGuest={handleEditGuest}
      />
    </div>
  )
}

type EventFormGuestsFieldContainerProps = {
  children: ReactNode
}

const EventFormGuestsFieldContainer = (
  props: EventFormGuestsFieldContainerProps
) => {
  const { children } = props
  return (
    <EventFormRowContainer>
      <EventFormRowIconContainer>
        <UsersSolid />
      </EventFormRowIconContainer>
      {children}
    </EventFormRowContainer>
  )
}

type EventFormGuestsFieldCollapsedProps = {
  onExpand: () => void
  attendees: CalendarEventAttendee[]
}

const EventFormGuestsFieldCollapsed = (
  props: EventFormGuestsFieldCollapsedProps
) => {
  const { attendees, onExpand } = props

  return (
    <EventFormGuestsFieldContainer>
      <FieldButton size='small' fullWidth variant='muted' onClick={onExpand}>
        <UserAvatarList
          size={16}
          maxNumberOfAvatars={8}
          users={attendees.map((attendee) => ({
            id: attendee.email,
            name: attendee.displayName ?? (attendee.email as string),
            profileUrl: attendee.profilePic,
          }))}
        />
        <FilledChevronDownSolid className='text-semantic-neutral-icon-subtle h-4 -ml-1.5' />
      </FieldButton>
    </EventFormGuestsFieldContainer>
  )
}

type EventFormGuestsFieldExpandedProps = {
  readOnly: boolean
  attendees: CalendarEventAttendee[]
  onAddContactAsAttendee: (contact: Contact) => void
  onRemoveGuest: (email: string) => void
  onEditGuest: (
    email: string | undefined,
    updates: Partial<CalendarEventAttendee>
  ) => void
}

const EventFormGuestsFieldExpanded = (
  props: EventFormGuestsFieldExpandedProps
) => {
  const {
    readOnly,
    attendees,
    onAddContactAsAttendee,
    onRemoveGuest,
    onEditGuest,
  } = props

  return (
    <>
      <EventFormGuestsFieldContainer>
        {readOnly ? (
          <div className='px-2 text-field-text-placeholder text-xs'>Guests</div>
        ) : (
          <EventFormContactsSearch
            attendees={attendees}
            onSelect={onAddContactAsAttendee}
          />
        )}
      </EventFormGuestsFieldContainer>
      {attendees.length > 0 && <div className='h-1' />}
      {attendees.map((attendee) => (
        <GuestItem
          item={attendee}
          key={attendee.email}
          onRemove={onRemoveGuest}
          onUpdate={onEditGuest}
          readOnly={readOnly}
        />
      ))}
    </>
  )
}

type GuestItemProps = {
  item: CalendarEventAttendee
  onRemove: (email: string) => void
  onUpdate: (
    email: string | undefined,
    updates: Partial<CalendarEventAttendee>
  ) => void
  readOnly?: boolean
}

const GuestItem = (props: GuestItemProps) => {
  const { item, onRemove, onUpdate, readOnly = false } = props
  if (!item.email) return null
  return (
    <GuestItemRow disabled={readOnly}>
      <EventFormRowIconContainer>
        <RsvpIcon variant={item.status} />
      </EventFormRowIconContainer>
      <div className='pl-2 pr-0.5 rounded group-hover:bg-semantic-neutral-bg-hover flex-grow min-w-0 h-6 flex items-center justify-between'>
        <ContactLabel item={item} size='xsmall' />
        {item.isOrganizer && <Label>Host</Label>}
        <div className='hidden items-center gap-0.5 ml-0.5 group-hover:flex text-semantic-neutral-icon-default'>
          <IconButton
            icon={item.isOptional ? UserOutline : UserSolid}
            size='xsmall'
            variant='muted'
            sentiment='neutral'
            onClick={() =>
              onUpdate(item.email, { isOptional: !item.isOptional })
            }
          />
          <IconButton
            icon={XSolid}
            size='xsmall'
            variant='muted'
            sentiment='neutral'
            onClick={() => (item.email ? onRemove(item.email) : null)}
          />
        </div>
      </div>
    </GuestItemRow>
  )
}

const GuestItemRow = classed(EventFormRowContainer, {
  base: `
   items-center pb-1
  `,
  variants: {
    disabled: {
      false: `group`,
    },
  },
})

type RsvpIconProps = {
  variant?: AttendeeStatus
}
const RsvpIcon = (props: RsvpIconProps) => {
  const { variant } = props
  switch (variant) {
    case 'accepted':
      return (
        <CheckCircleSolid className='text-semantic-success-bg-strong-default w-3' />
      )
    case 'declined':
      return (
        <XCircleSolid className='text-semantic-error-bg-strong-default w-3' />
      )
    default:
      return (
        <MinusCircleSolid className='text-semantic-neutral-bg-strong-default w-3' />
      )
  }
}
