import { AutoscheduleSolid, CalendarXSolid } from '@motion/icons'
import { PopoverTrigger, SearchableList, Tooltip } from '@motion/ui/base'

import { useLookup } from '~/global/cache'
import { twMerge } from 'tailwind-merge'

import { FieldButton } from '../components'
import { isBulkOpsTaskGroups, useBulkOpsField } from '../contexts'
import { type BulkOpsFieldValue } from '../contexts'
import { useBulkFieldValidation } from '../hooks'

export function AutoscheduleField() {
  const lookup = useLookup()
  const [isAutoScheduled, setIsAutoScheduled] =
    useBulkOpsField('isAutoScheduled')
  const [assigneeUserId] = useBulkOpsField('assigneeUserId')
  const [statusIds] = useBulkOpsField('statusIds')

  const active = isAutoScheduled != null

  const { isValid: canUpdateAutoschedule, errMsg } = useBulkFieldValidation([
    ({ target, groups }) => ({
      isValid: isBulkOpsTaskGroups(groups, target)
        ? groups.every((group) =>
            group.items.every((task) => task.assigneeUserId != null)
          )
        : true,
      errMsg: 'One or more selected tasks do not have an assignee',
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <BulkAutoscheduleDropdownContent
            close={close}
            isAutoScheduled={isAutoScheduled}
            computeDisabled={(item) => {
              // Disabling autoschedule option if unassigned is selected
              if (assigneeUserId === null) {
                return item.value
              }

              // Disabling autoschedule option if the selected status cannot be scheduled
              if (statusIds.length > 0) {
                return (
                  item.value &&
                  statusIds.some(
                    (id) => !lookup('statuses', id)?.autoScheduleEnabled
                  )
                )
              }

              return false
            }}
            onChange={(value) => {
              setIsAutoScheduled(value)
            }}
          />
        )}
      >
        <FieldButton
          active={active}
          analyticsName='autoschedule'
          onRemove={() => {
            setIsAutoScheduled(undefined)
          }}
          disabled={!canUpdateAutoschedule}
        >
          {isAutoScheduled === false ? (
            <CalendarXSolid />
          ) : (
            <AutoscheduleSolid
              className={twMerge(
                isAutoScheduled && '!text-semantic-purple-icon-default'
              )}
            />
          )}
          {isAutoScheduled === false ? 'Not auto-scheduled' : 'Auto-schedule'}
        </FieldButton>
      </PopoverTrigger>
    </Tooltip>
  )
}

type BulkAutoscheduleDropdownContentProps = {
  close: () => void
  isAutoScheduled: BulkOpsFieldValue<'isAutoScheduled'>
  onChange: (value: BulkOpsFieldValue<'isAutoScheduled'>) => void
  computeDisabled?: (item: (typeof autoScheduleItems)[number]) => boolean
}
function BulkAutoscheduleDropdownContent({
  close,
  isAutoScheduled,
  onChange,
  computeDisabled,
}: BulkAutoscheduleDropdownContentProps) {
  return (
    <SearchableList
      searchable={false}
      items={autoScheduleItems}
      computeKey={(item) => item.label}
      computeSearchValue={(item) => item.label}
      computeSelected={(item) => item.value === isAutoScheduled}
      computeDisabled={computeDisabled}
      onSelect={(item) => {
        close()
        onChange(item.value)
      }}
      renderItem={(item) => (
        <div className='flex gap-1.5'>
          {item.icon}
          {item.label}
        </div>
      )}
    />
  )
}

const autoScheduleItems = [
  {
    value: true,
    label: `Auto-schedule`,
    icon: (
      <AutoscheduleSolid className='w-4 h-4 shrink-0 text-semantic-purple-icon-default' />
    ),
  },
  {
    value: false,
    label: `Don't auto-schedule`,
    icon: (
      <CalendarXSolid className='w-4 h-4 shrink-0 text-semantic-neutral-icon-default' />
    ),
  },
]
