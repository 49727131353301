import { WorkspaceSolid } from '@motion/icons'
import { type SearchableTreeNode } from '@motion/ui/base'
import { FolderColoredIcon, ProjectColoredIcon } from '@motion/ui/project'

import { useCachedItem } from '~/global/cache'

import { type LeafNodeType } from './hooks'
import { parseWorkspaceTreeNodeId } from './utils'

type WorkspacesTreeNodeLabelProps = {
  node: SearchableTreeNode
  leafNodeType: LeafNodeType
}

export const WorkspacesTreeNodeLabel = (
  props: WorkspacesTreeNodeLabelProps
) => {
  const { node, leafNodeType } = props
  const { id, type } = parseWorkspaceTreeNodeId(node.id)

  const icon =
    type === 'FOLDER' ? (
      <ConnectedFolderIcon id={id} />
    ) : type === 'PROJECT' ? (
      <ConnectedProjectIcon id={id} />
    ) : type === 'WORKSPACE' ? (
      <WorkspaceSolid className='size-4 mx-1 text-semantic-neutral-icon-default shrink-0' />
    ) : type === 'NONE' && leafNodeType === 'FOLDER' ? (
      <FolderColoredIcon className='size-4 mx-1' />
    ) : type === 'NONE' && leafNodeType === 'PROJECT' ? (
      <ProjectColoredIcon className='size-4 mx-1' />
    ) : null
  return (
    <>
      {icon}
      <span className='truncate px-0.5'>{node.label}</span>
    </>
  )
}

const ConnectedFolderIcon = (props: { id: string }) => {
  const folder = useCachedItem('folders', props.id)

  return <FolderColoredIcon className='size-4 mx-1' color={folder?.color} />
}

const ConnectedProjectIcon = (props: { id: string }) => {
  const project = useCachedItem('projects', props.id)

  return <ProjectColoredIcon className='size-4 mx-1' color={project?.color} />
}
