import { CheckSolid } from '@motion/icons'
import { IndividualOrTeam, PMTeamMemberRole } from '@motion/rpc/types'
import type { TeamWithRelationsSerializer } from '@motion/rpc-types'
import { Button, FormModal, showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { templateStr } from '@motion/ui-logic'
import {
  type BillingPrices,
  getMinimumBucket,
  makeTeamBillingStr,
  teamBulletsShort,
} from '@motion/ui-logic/billing'
import { uniqueBy } from '@motion/utils/array'
import { isEmailValid } from '@motion/utils/string'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useShouldShowSalesTaxMessage } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { unwrapResult } from '@reduxjs/toolkit'
import {
  ConnectedTeamMembersTable,
  type TeamMembersTableUser,
} from '~/areas/settings/components'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useI18N } from '~/global/contexts'
import {
  useAllWorkspaces,
  useIsEnterpriseSubscription,
  useIsTeamTrialSetToCancel,
} from '~/global/hooks'
import { useActiveMemberCount } from '~/global/hooks/team'
import { useGetSubscriptionPaymentMethod } from '~/global/rpc'
import {
  useCurrentTeam,
  useGetTeamPrices,
  useInviteTeamMembers,
} from '~/global/rpc/team'
import { type ReactNode, useRef, useState } from 'react'

import { useAppDispatch } from '../../../state/hooks'
import {
  fetchTeam,
  inviteTeamMembers,
} from '../../../state/projectManagement/teamThunks'
import { checkTeamEligibility } from '../../../state/teamSlice'
import { type TeamMemberInvite } from '../../../state/TeamTypes'

function convertUsersToInvites(
  users: TeamMembersTableUser[]
): TeamMemberInvite[] {
  return users.map((user) => ({
    email: user.email,
    role: user.role,
    workspaces: user.workspaces.map((workspace) => workspace.value),
  }))
}

type InviteTeammateModalProps = {
  team: Readonly<TeamWithRelationsSerializer>
  teamPrices: BillingPrices
}

const InviteTeammateModal = ({
  team,
  teamPrices,
}: InviteTeammateModalProps) => {
  const context = useRouteAnalyticsMetadata()

  const isMonthly = !!team?.teamSubscription?.isMonthly
  const dispatch = useAppDispatch()
  const emailAddressToInvite = useRef('')
  const [invalidMessage, setInvalidMessage] = useState<ReactNode | null>(null)
  const [ineligibilityMessage, setIneligibilityMessage] =
    useState<ReactNode | null>(null)
  const [usersToInvite, setUsersToInvite] = useState<TeamMembersTableUser[]>([])
  const pmWorkspaces = useAllWorkspaces()
  const simplifiedWorkspaces = pmWorkspaces
    .filter((workspace) => workspace.type === IndividualOrTeam.TEAM)
    .map((workspace) => ({ label: workspace.name, value: workspace.id }))
  const [disableSubmit, setDisableSubmit] = useState(false)

  const pricePerMember = isMonthly
    ? teamPrices.monthlyPrice
    : teamPrices.annualPricePerMonth

  const isTrial = useIsTeamTrialSetToCancel(team?.teamSubscription)
  const teamHasBucketPricing = team?.hasBucketPricing
  const bucketSeats = teamHasBucketPricing
    ? team.teamSubscription?.bucketSeats
    : undefined
  const pmTeamSubscription = team.teamSubscription
  const activeMemberCount = useActiveMemberCount()

  const { data: paymentMethod, isLoading: isLoadingPaymentMethod } =
    useGetSubscriptionPaymentMethod(
      {
        subscriptionId: pmTeamSubscription?.id ?? '',
      },
      { enabled: !!pmTeamSubscription }
    )

  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')
  const { pluralize } = useI18N()

  const [emailAddressesRaw, setEmailAddressesRaw] = useState('')
  const { isEnterprise, isLoading: isEnterpriseLoading } =
    useIsEnterpriseSubscription()
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()

  const needsResize =
    !!teamHasBucketPricing &&
    (bucketSeats ?? 0) < (activeMemberCount ?? 0) + usersToInvite.length

  const isEnterpriseNeedsResize = isEnterprise && needsResize

  const modalApi = useModalApi()

  const onClose = () => {
    modalApi.dismiss()
  }

  const { mutateAsync: inviteTeamMembersWithSeats } = useInviteTeamMembers()

  if (activeMemberCount === undefined) {
    return null
  }

  const onSubmitResize = async (seats: number) => {
    const userInvites = convertUsersToInvites(usersToInvite)
    await inviteTeamMembersWithSeats({
      id: team.id,
      invitees: userInvites,
      seats,
    })
    recordAnalyticsEvent('TEAM_BILLING_UPDATE_SEATS', {
      old_seats: activeMemberCount,
      num_seats: seats,
      action: seats > activeMemberCount ? 'upgrade' : 'downgrade',
    })
    await dispatch(fetchTeam())
    onClose()
  }

  const queueUpEmailInvite = (unvalidatedEmail: string) => {
    if (!unvalidatedEmail) {
      return
    }
    const email = unvalidatedEmail.trim()
    if (!isEmailValid(email)) {
      return
    }

    if (
      usersToInvite.some(
        (user) => user.email.toLowerCase() === email.toLowerCase()
      )
    ) {
      return
    }

    void dispatch(checkTeamEligibility(email))
      .then(unwrapResult)
      .then((res) => {
        if (res.isEligible) {
          setIneligibilityMessage('')
          setUsersToInvite(
            usersToInvite.concat({
              email,
              key: email,
              role: PMTeamMemberRole.MEMBER,
              editableRole: true,
              workspaces: simplifiedWorkspaces,
            })
          )
        } else if (
          team.invites?.map((invite) => invite.email).includes(email)
        ) {
          setIneligibilityMessage(
            'This person has already been invited to the team.'
          )
        } else {
          setIneligibilityMessage(
            'This member can’t be invited because they’re already part of an active team.'
          )
        }
        return
      })
  }

  const handleSubmit = async () => {
    if (
      simplifiedWorkspaces.length > 0 &&
      usersToInvite.filter((user) => user.workspaces.length === 0).length > 0
    ) {
      setIneligibilityMessage(
        'Please choose at least one workspace to invite each team member to'
      )
      return
    }

    if (emailAddressToInvite.current.length > 0) {
      queueUpEmailInvite(emailAddressToInvite.current)
      modalApi.open('invite-teammate-success')
    } else if (usersToInvite.length) {
      if (!teamHasBucketPricing || !needsResize) {
        const usersToInviteClean = convertUsersToInvites(usersToInvite)

        let inviteResponse
        if (teamHasBucketPricing) {
          await inviteTeamMembersWithSeats({
            id: team.id,
            invitees: usersToInviteClean,
          })
        } else {
          inviteResponse = await dispatch(
            inviteTeamMembers(usersToInviteClean)
          ).unwrap()
        }

        if (inviteResponse) {
          recordAnalyticsEvent('TEAM_INVITE', context)
        }

        showToast('loading', 'Loading...')
        await dispatch(fetchTeam())
        if (teamHasBucketPricing) {
          onClose()
          modalApi.open('invite-teammate-success')
          return
        }
      }

      if (teamHasBucketPricing && pmTeamSubscription) {
        const currentSeats = bucketSeats
        const newTeamSize = activeMemberCount + usersToInvite.length
        const minBucket = getMinimumBucket(newTeamSize)

        // If they are in a team trial without a payment method we should skip
        // prompting them to change buckets and just resize the team
        if (
          shouldUseNoCcTrial &&
          pmTeamSubscription.status === 'trialing' &&
          !paymentMethod &&
          !isLoadingPaymentMethod
        ) {
          await onSubmitResize(minBucket)
          modalApi.open('invite-teammate-success')
          return
        }

        return modalApi.open('manage-team-seats', {
          title: `Switch to ${minBucket} seats to invite more members`,
          makeSubtext: (seats: number) => (
            <p className='text-semantic-neutral-text-subtle text-xs'>
              {templateStr(
                'Your team currently has {{currentSeats}} seats & {{activeMemberCount}} {{currentMember}}. To invite more members, switch to {{minBucket}} seats.',
                {
                  currentSeats,
                  activeMemberCount,
                  currentMember: activeMemberCount > 1 ? 'members' : 'member',
                  minBucket,
                }
              )}
              <br />
              {makeTeamBillingStr({
                isAnnual: !pmTeamSubscription.isMonthly,
                quantity: seats,
                isSeats: true,
                prorationTextParams: {
                  isTrial: pmTeamSubscription.status === 'trialing',
                },
                teamPrices,
                shouldShowSalesTaxMessage,
              })}
            </p>
          ),
          cancelText: 'Go back',
          teamSize: newTeamSize,
          isAnnual: !pmTeamSubscription.isMonthly,
          onSubmit: onSubmitResize,
          initialSelection: minBucket,
          minSeats: minBucket,
        })
      }
      onClose()
    }
  }

  const addEmailsBulk = async () => {
    const sanitizedEmails = uniqueBy(
      emailAddressesRaw
        .split(',')
        .map((email) => email.trim().toLowerCase())
        .filter(
          (email) =>
            !usersToInvite.filter((invitee) => invitee.email === email).length
        ),
      (email) => email
    )

    const { validEmails, invalidEmails } = sanitizedEmails.reduce(
      (acc, current) => {
        if (isEmailValid(current)) {
          acc.validEmails.push(current)
        } else {
          acc.invalidEmails.push(current)
        }
        return acc
      },
      { validEmails: [] as string[], invalidEmails: [] as string[] }
    )

    const emailEligibilities = await Promise.all(
      validEmails.map((email) =>
        dispatch(checkTeamEligibility(email))
          .then(unwrapResult)
          .then((res) => {
            return { email, isEligible: res.isEligible }
          })
      )
    )

    const { eligibleEmails, ineligibleEmails } = emailEligibilities.reduce(
      (acc, current) => {
        if (current.isEligible) {
          acc.eligibleEmails.push(current.email)
        } else {
          acc.ineligibleEmails.push(current.email)
        }
        return acc
      },
      { eligibleEmails: [] as string[], ineligibleEmails: [] as string[] }
    )

    if (invalidEmails.length > 0) {
      setInvalidMessage(
        templateStr(
          'The following {{emailStr}} not valid to be invited: {{invalidEmails}}',
          {
            emailStr: pluralize(invalidEmails.length, 'email is', 'emails are'),
            invalidEmails: invalidEmails.join(', '),
          }
        )
      )
    } else {
      setInvalidMessage(null)
    }

    if (ineligibleEmails.length > 0) {
      setIneligibilityMessage(
        templateStr(
          'The following {{user}} not eligible to be invited: {{emails}}',
          {
            user: pluralize(ineligibleEmails.length, 'user is', 'users are'),
            emails: ineligibleEmails.join(', '),
          }
        )
      )
    } else {
      setIneligibilityMessage(null)
    }

    const newInvitees = eligibleEmails.map((email) => ({
      key: email,
      email: email,
      role: PMTeamMemberRole.MEMBER,
      editableRole: true,
      workspaces: [],
    }))

    setUsersToInvite(usersToInvite.concat(newInvitees))
    setEmailAddressesRaw('')
  }

  return (
    <FormModal
      onClose={onClose}
      submitAction={{
        disabled:
          disableSubmit ||
          usersToInvite.length === 0 ||
          isEnterpriseLoading ||
          isEnterpriseNeedsResize,
        onAction: handleSubmit,
        text: 'Send invites',
      }}
      title='Invite member'
      visible
    >
      <div className='block lg:flex gap-5 min-w-[416px] w-[calc(100vw-230px)] font-normal max-w-full'>
        <div className='hidden lg:flex flex-col gap-1 min-w-[250px] w-[250px] p-4 bg-semantic-blue-bg-default -my-4 -ml-4'>
          {teamBulletsShort.map((item) => (
            <div key={item} className='flex gap-2 place-content-start'>
              <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
              <p className='text-semantic-neutral-text-subtle mb-0 text-xs font-normal'>
                {item}
              </p>
            </div>
          ))}
        </div>
        <div className='flex flex-col w-full'>
          <div className='flex flex-col gap-2.5 w-full'>
            <div className='flex gap-4 items-end'>
              <TextField
                label='Email Addresses (comma separated)'
                placeholder='Add teammates (enter email addresses separated by commas)'
                fullWidth
                value={emailAddressesRaw}
                onChange={setEmailAddressesRaw}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter') {
                    evt.preventDefault()
                    addEmailsBulk()
                  }
                }}
              />
              <Button onClick={addEmailsBulk} disabled={!emailAddressesRaw}>
                Add
              </Button>
            </div>
            {usersToInvite.length > 0 && (
              <div className='flex flex-col gap-2.5'>
                <h2 className='text-semantic-neutral-text-subtle font-semibold text-xs'>
                  Invitees
                </h2>

                <ConnectedTeamMembersTable
                  users={usersToInvite}
                  teamWorkspaces={simplifiedWorkspaces}
                  onTeamMembersUpdate={(updatedUsers, type) => {
                    recordAnalyticsEvent(
                      'TEAM_MEMBERS_TABLE_BULK_EDIT_APPLIED',
                      {
                        type,
                        count: Object.keys(updatedUsers).length,
                        location: 'invite-modal',
                      }
                    )

                    setUsersToInvite(
                      usersToInvite.map((user) => {
                        if (user.email in updatedUsers) {
                          return updatedUsers[user.email]
                        }
                        return user
                      })
                    )
                  }}
                  onRemove={(email) => {
                    setUsersToInvite(
                      usersToInvite.filter((invitee) => invitee.email !== email)
                    )
                  }}
                  onSelectionChange={(selection) => {
                    setDisableSubmit(selection.length !== 0)
                  }}
                />
              </div>
            )}
            {invalidMessage && (
              <div className='rounded border bg-banner-error-subtle-bg border-banner-error-subtle-border p-3'>
                <p className='text-xs'>{invalidMessage}</p>
              </div>
            )}
            {ineligibilityMessage && (
              <div className='rounded border bg-banner-error-subtle-bg border-banner-error-subtle-border p-3'>
                <p className='text-xs'>{ineligibilityMessage}</p>
              </div>
            )}
          </div>
          {!!usersToInvite.length && !isTrial && !teamHasBucketPricing && (
            <div className='bg-semantic-warning-bg-default mt-2 mb-0 p-1.5'>
              <p className='text-semantic-warning-text-default text-xs font-medium'>
                {templateStr(
                  "You'll be charged an additional ${{newAmount}}/month (${{pricePerMember}}/month per additional member).",
                  {
                    newAmount: usersToInvite.length * pricePerMember,
                    pricePerMember,
                  }
                )}
              </p>
            </div>
          )}
          {isEnterpriseNeedsResize && bucketSeats && (
            <div className='bg-semantic-warning-bg-default mt-2 p-2 rounded border border-semantic-warning-border-default'>
              <p className='text-xs text-semantic-warning-text-default font-semibold'>
                {templateStr(
                  'Please reach out to your sales rep, or sales@usemotion.com to invite more {{members}}',
                  {
                    members:
                      activeMemberCount === bucketSeats
                        ? 'members'
                        : `than ${bucketSeats - activeMemberCount} additional team members.`,
                  }
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </FormModal>
  )
}

export const ConnectedAddTeammateModal = () => {
  const { data: team } = useCurrentTeam()
  const { data: teamPrices } = useGetTeamPrices()

  if (!team?.teamSubscription || !teamPrices) {
    return null
  }

  return <InviteTeammateModal team={team} teamPrices={teamPrices} />
}
