import { Toggle } from '@motion/ui/base'

import { Combobox } from '~/components/Common'
import { bufferChoices } from '~/storageConstants'
import { timeStringToMin } from '~/utils'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const AvailabilityBufferTimeRow = () => {
  const {
    template: {
      state: { bufferMins, hasBlocking },
      setters: { setBufferMins, setHasBlocking },
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow title='Buffer time' subtitle='Prevent back-to-back meetings'>
      <div className='space-y-3'>
        <Combobox
          className='max-w-[250px]'
          autoComplete
          value={bufferMins}
          onChange={setBufferMins}
          options={bufferChoices.map((time) => ({
            label: time,
            value: timeStringToMin(time),
          }))}
        />

        {!!bufferMins && bufferMins ? (
          <div className='flex w-full gap-2'>
            <Toggle
              checked={hasBlocking}
              onChange={(e) => setHasBlocking(e.target.checked)}
            >
              Create time block event following the meeting
            </Toggle>
          </div>
        ) : null}
      </div>
    </SectionRow>
  )
}
