import { RadioButton } from '@motion/ui/forms'

import React from 'react'

import { type SkipStagesResponse } from './skip-stages-modal'
import { Container, Question } from './styled'

interface NextStageSkipContentProps {
  value: SkipStagesResponse
  setValue: (value: SkipStagesResponse) => void
}

export const NextStageSkipContent: React.FC<NextStageSkipContentProps> = ({
  value,
  setValue,
}) => {
  return (
    <Container>
      <Question>
        What do you want to do for the tasks in the current stage that are not
        in the Completed or Canceled status?
      </Question>

      <RadioButton
        checked={value === 'COMPLETE'}
        onChange={() => setValue('COMPLETE')}
        label='Complete Tasks'
      />

      <RadioButton
        checked={value === 'CANCEL'}
        onChange={() => setValue('CANCEL')}
        label='Cancel Tasks'
      />

      <RadioButton
        checked={value === 'NOOP'}
        onChange={() => setValue('NOOP')}
        label='Do Nothing'
      />
    </Container>
  )
}
