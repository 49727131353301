import { classed } from '@motion/theme'
import {
  CustomEventHandlers,
  KeyboardShortcuts,
  type KeyboardShortcutsOptions,
  RichTextContent,
  RichTextImageBubbleMenu,
  RichTextToolbar,
  useRichTextEditor,
} from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type TaskType } from '@motion/zod/client'

import { useUploadWorkspaceImage } from '~/areas/project-management/hooks'
import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

export type DescriptionEditorProps = {
  workspaceId: string
  taskId?: string
  projectId?: string
  taskType?: TaskType
  value: string | null
  onChange: (value: string) => void
  onBlur?: (value: string) => void
  disabled?: boolean
  autoFocus?: boolean
  editorClassName?: string
  disableImageUpload?: boolean
  shortcuts?: KeyboardShortcutsOptions['shortcuts']
  variant?: 'default' | 'sidepanel'
}

export const DescriptionEditor = ({
  workspaceId,
  taskId,
  projectId,
  taskType,
  value,
  onChange,
  onBlur,
  disabled,
  autoFocus = true,
  editorClassName,
  disableImageUpload,
  shortcuts,
  variant = 'default',
}: DescriptionEditorProps) => {
  const uploadWorkspaceImage = useUploadWorkspaceImage(workspaceId, {
    taskId,
    projectId,
    isRecurringTask: taskType === 'RECURRING_TASK',
  })

  const modalApi = useModalApi()

  const openLinkModalPrompt = async (initialLink: string = '') => {
    const response = await modalApi.prompt('link-modal', {
      initialLink,
    })

    if (response !== ModalDismissed) {
      return response
    }
    return null
  }

  const editorContainerRef = useRef<HTMLDivElement>(null)

  const editor = useRichTextEditor({
    className: twMerge('h-full overflow-y-hidden', editorClassName),
    placeholder: 'Description',
    value: value ?? '',
    disabled,
    disableImageUpload,
    onChange,
    onBlur: onBlur
      ? (v, event) => {
          if (editorContainerRef.current?.contains(event.relatedTarget as Node))
            return

          onBlur(v)
        }
      : undefined,
    onImgExpandClick: () => {
      recordAnalyticsEvent('IMAGE_EXPAND_CLICK')
    },
    extensions: [
      !disableImageUpload ? CustomEventHandlers(uploadWorkspaceImage) : null,
      shortcuts != null ? KeyboardShortcuts.configure({ shortcuts }) : null,
    ].filter(Boolean),
  })

  useEffect(() => {
    editor?.setOptions({ editable: !disabled })
  }, [editor, disabled])

  useEffect(() => {
    if (disabled || !autoFocus) return

    editor?.commands.focus()
  }, [editor, disabled, autoFocus])

  return (
    <EditorContainer ref={editorContainerRef}>
      {!disableImageUpload && <RichTextImageBubbleMenu editor={editor} />}
      <div
        className={twMerge(
          'py-1 sticky top-0 z-[1]',
          variant === 'default'
            ? 'bg-modal-bg'
            : 'bg-semantic-neutral-surface-overlay-bg-default'
        )}
      >
        <RichTextToolbar
          editor={editor}
          disabled={disabled}
          disableImageUpload={disableImageUpload}
          onFileUpload={!disableImageUpload ? uploadWorkspaceImage : undefined}
          onLinkClick={openLinkModalPrompt}
          size={variant === 'sidepanel' ? 'small' : 'normal'}
        />
      </div>
      <StyledRichTextContent editor={editor} disabled={disabled} />
    </EditorContainer>
  )
}

const EditorContainer = classed('div', {
  base: `
    relative
    gap-3
    flex-1
    flex flex-col
  `,
})

// min-height prevents the editor from collapsing when empty
const StyledRichTextContent = classed(RichTextContent, {
  base: `
    outline-0
    bg-transparent
    px-0.5
    w-full
    [&>.ProseMirror]:min-h-[300px]
    flex flex-col
    flex-grow shrink-0
  `,
})
