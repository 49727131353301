import { Sentry } from '@motion/web-base/sentry'

import YouTube from 'react-youtube'

import { muteAndPlay } from './mute-and-play'

interface YoutubeVideoProps {
  videoId: string
  onEnd?: () => void
  skipAutoPlay?: boolean
  muteOnStart?: boolean
}

export const YoutubeVideo = ({
  videoId,
  onEnd = () => {},
  skipAutoPlay = false,
  muteOnStart = true,
}: YoutubeVideoProps) => {
  return (
    <YouTube
      videoId={videoId}
      opts={{
        playerVars: { autoplay: 1, cc_load_policy: 1, rel: 0 },
      }}
      className='flex aspect-video w-full max-w-full lg:max-w-[1100px]'
      iframeClassName='aspect-video w-full h-full'
      onReady={async ({ target: player }) => {
        try {
          void player.playVideo()
        } catch (e) {
          Sentry.captureException(e, { tags: { position: 'youtubePlay' } })
        }

        await muteAndPlay(player, muteOnStart)

        if (skipAutoPlay) {
          setTimeout(() => {
            void player.pauseVideo()
          }, 500)
        }
      }}
      onEnd={onEnd}
    />
  )
}
