import { TrashSolid } from '@motion/icons'

import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Input, Paragraph, TextButton } from '../../../Common'

export interface EditableSettingsRowProps {
  icon?: React.ReactNode
  id: string
  name: string
  canDelete?: boolean
  indent?: boolean
  onDelete?: (id: string) => void
  onChange: (id: string, value: string) => void
  disabled?: boolean
  className?: string
}

export const EditableSettingsRow = ({
  icon,
  id,
  name,
  className = '',
  canDelete = true,
  disabled = false,
  onDelete = () => {},
  onChange = () => {},
}: EditableSettingsRowProps) => {
  const [currentName, setCurrentName] = useState('')

  useEffect(() => {
    setCurrentName(name)
  }, [name])

  return (
    <div className={twMerge('flex w-full py-2', className)}>
      {icon && (
        <div className='mr-2.5 flex items-center justify-center'>{icon}</div>
      )}
      {disabled ? (
        <Paragraph className='ml-1'>{currentName}</Paragraph>
      ) : (
        <Input
          containerClassName={twMerge('flex-grow')}
          disabled={disabled}
          value={currentName}
          onChange={(e) => setCurrentName(e.target.value)}
          onBlur={(e) => {
            onChange(id, e.target.value)
            setCurrentName(e.target.value)
          }}
        />
      )}
      {canDelete && (
        <TextButton
          className='ml-3'
          icon={TrashSolid}
          onClick={() => onDelete(id)}
        />
      )}
    </div>
  )
}
