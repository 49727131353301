import { useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ProjectActionList } from '~/areas/project/components/project-action-list'
import { useProjectModalUrl } from '~/global/navigation'
import { type ProjectWithRelations } from '~/global/proxies'
import { useNavigate } from 'react-router'

import { ItemAssignee } from './item-assignee'

import { RowNameContainer } from '../../../common'

type ProjectRowNameProps = {
  project: ProjectWithRelations
}

export const ProjectRowName = ({ project }: ProjectRowNameProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()

  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RIGHT_CLICK')
    },
  })

  return (
    <RowNameContainer
      className='flex items-center justify-between cursor-pointer'
      onClick={() => navigate(buildProjectModalUrl({ project: project.id }))}
      onContextMenu={handleContextMenu}
    >
      <div className='flex items-center border-l-2 border-palette-highlight-default pl-2'>
        <div className='w-[140px] truncate text-xs'>{project.name}</div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <ItemAssignee project={project} />
      </div>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ProjectActionList
            close={close}
            options={{ showColors: true, showCompleteProject: true }}
            project={project}
          />
        )}
      />
    </RowNameContainer>
  )
}
