import { PlayTriangleSolid } from '@motion/icons'
import { formatMonthDay } from '@motion/ui-logic'
import { HasExperiment } from '@motion/web-common/flags'

import { useStageDefinition } from '~/global/hooks'

import { StageConnector } from './stage-connector'
import {
  DeprecatedStageConnector,
  Dot,
  StageDate,
  TodayMarkerContainer,
  TodayText,
} from './styled'
import { type StageGroup } from './utils'

type TodayMarkerProps = {
  stageGroup?: StageGroup
}
export function TodayMarker({ stageGroup }: TodayMarkerProps) {
  const today = new Date()
  const nextStage = stageGroup?.stages[0]
  const stageDefinition = useStageDefinition(nextStage?.stageDefinitionId)
  const progress = nextStage?.completion ?? 0
  return (
    <>
      <TodayMarkerContainer>
        <TodayText>TODAY</TodayText>

        <HasExperiment
          fallback={<Dot color='red' />}
          name='flows-better-resize-stages'
        >
          <PlayTriangleSolid className='text-semantic-error-text-default rotate-90 size-[8px] mt-[-5px]' />
        </HasExperiment>

        <StageDate className='relative left-[-8px]'>
          {formatMonthDay(today, { numeric: true })}
        </StageDate>
      </TodayMarkerContainer>

      <HasExperiment
        fallback={<DeprecatedStageConnector half />}
        name='flows-better-resize-stages'
      >
        <StageConnector
          half
          color={stageDefinition.color}
          progress={progress}
        />
      </HasExperiment>
    </>
  )
}
