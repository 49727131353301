import type { GetCustomFieldCategories } from '@motion/zod/client'

import { CUSTOM_FIELD_CATEGORIES_RESPONSE } from './consts'

import {
  type CustomFieldCategoriesConfig,
  getCustomFieldCategoryConfigList,
} from '../../custom-fields/utils'

export type GetCustomFieldsCategoriesResponse = {
  categoryList: GetCustomFieldCategories['ids']
  categoryMap: GetCustomFieldCategories['models']['customFieldCategories']
  categoriesConfigList: CustomFieldCategoriesConfig[]
}

export const useGetAvailableCustomFieldCategories =
  (): GetCustomFieldsCategoriesResponse => {
    return {
      categoryList: CUSTOM_FIELD_CATEGORIES_RESPONSE.ids,
      categoryMap:
        CUSTOM_FIELD_CATEGORIES_RESPONSE.models.customFieldCategories,
      categoriesConfigList: getCustomFieldCategoryConfigList(
        CUSTOM_FIELD_CATEGORIES_RESPONSE.ids,
        CUSTOM_FIELD_CATEGORIES_RESPONSE.models.customFieldCategories
      ),
    }
  }
