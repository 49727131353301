import { API, createUseMutation, createUseQuery } from '@motion/rpc'

// TODO: update this once endpoint is ready
export const useUpdateEmail = createUseMutation(API.users.updateEmail)
export const useUpdateName = createUseMutation(API.users.updateName)
export const useUpdatePassword = createUseMutation(API.users.changePasswordApi)
export const useGetProfilePicture = createUseQuery(API.users.getProfilePic)
export const useDeleteProfilePicture = createUseMutation(
  API.users.deleteProfilePic
)
export const useDisconnectCalendars = createUseMutation(
  API.users.disconnectCalendars
)
export const useDeleteAccount = createUseMutation(API.users.deleteAccount)
