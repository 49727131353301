import { QuestionMarkCircleSolid } from '@motion/icons'
import { type TaskDeadlineType } from '@motion/rpc-types'
import { Toggle, Tooltip } from '@motion/ui/base'

export type DeadlineToggleProps = {
  deadlineType: TaskDeadlineType
  disabled?: boolean
  onChange: (deadlineType: TaskDeadlineType) => void
}

export const DeadlineToggle = (props: DeadlineToggleProps) => {
  const { deadlineType, disabled = false, onChange } = props
  return (
    <div className='flex items-center gap-1'>
      <Toggle
        checked={deadlineType === 'HARD'}
        disabled={disabled}
        size='small'
        onChange={(e) => onChange(e.currentTarget.checked ? 'HARD' : 'SOFT')}
      >
        Hard Deadline
      </Toggle>
      <Tooltip content='If this is toggled on, Motion will prioritize this task and make sure it schedules before deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'>
        <QuestionMarkCircleSolid className='w-4 h-4 text-semantic-neutral-icon-subtle' />
      </Tooltip>
    </div>
  )
}
