import { CalendarSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import {
  getAdjustStageDueDatesFn,
  type StageArg,
} from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { DeadlineDropdown } from '~/areas/project-management/components'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export type Props = {
  stageDueDatesPath: `stageDueDates.${number}`
  isError?: boolean
}

export const ControlledStageDeadlineField = ({
  stageDueDatesPath,
  isError: isErrorProp,
}: Props) => {
  const { form } = useSetupProjectForm()
  const adjustStageDueDates = getAdjustStageDueDatesFn()

  const { control, watch, getValues, setValue } = form

  const { field } = useController({
    name: `${stageDueDatesPath}.dueDate`,
    control,
  })

  const projectId = watch('projectId')
  const stageDueDates = watch('stageDueDates')

  const stageDueDate = watch(stageDueDatesPath)
  const { dueDate: currentValue, stageDefinitionId } = stageDueDate

  const dateButtonString = getDateButtonText(currentValue, {
    placeholder: 'None',
  })

  const index = stageDueDates.findIndex(
    (stage) => stage.stageDefinitionId === stageDefinitionId
  )

  const isError =
    isErrorProp || doesLaterStageHaveEarlierDueDate(stageDueDates, index)

  return (
    <DeadlineDropdown
      value={currentValue}
      onChange={(value) => {
        if (value == null) return

        // Strip time from date
        const date = parseDate(value).toISODate()

        try {
          const {
            newProjectStart,
            newProjectDeadline,
            adjustedStagesDueDates,
          } = adjustStageDueDates({
            projectStart: getValues('startDate'),
            stageDueDates,
            updatedStage: {
              stageDefinitionId,
              dueDate: date,
            },
          })

          if (newProjectStart != null) {
            setValue('startDate', newProjectStart)
          }
          setValue('dueDate', newProjectDeadline)
          setValue('stageDueDates', adjustedStagesDueDates)
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'ControlledStageDeadlineField',
            },
          })
        } finally {
          field.onChange(date)
        }
      }}
      hideTimeOptions
      disableClearing
      dropdownTarget='stage'
      contextProps={{
        projectId,
        stageDefinitionId,
      }}
    >
      <Button
        variant='outlined'
        sentiment={isError ? 'error' : 'neutral'}
        size='small'
      >
        <div className='text-nowrap text-left font-normal flex flex-row items-center gap-[6px] min-w-[90px]'>
          <CalendarSolid />
          {dateButtonString}
        </div>
      </Button>
    </DeadlineDropdown>
  )
}

function doesLaterStageHaveEarlierDueDate(
  stageDueDates: StageArg[],
  index: number
): boolean {
  return stageDueDates.some(
    (stage, i) =>
      i > index &&
      stage.dueDate != null &&
      stage.dueDate < stageDueDates[index].dueDate
  )
}
