import { RefreshSolid, XSolid } from '@motion/icons'
import { SHORTCUT_DELIMITER, Tooltip } from '@motion/ui/base'
import { getWindowData } from '@motion/web-base/env'

import React, { type KeyboardEvent, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { useSendToDesktop } from '../../../../localServices/desktop'
import {
  defaultDesktopShortcuts,
  selectDesktopSettings,
} from '../../../../state/desktopSlice'
import { useAppSelector } from '../../../../state/hooks'
import { Paragraph, SubParagraph, TextButton } from '../../../Common'
import { SelectButton } from '../../../Common/Button/SelectButton'
import { type DesktopShortcutKeys } from '../../Pages/DesktopSettings'
import { DesktopShortcutKey } from '../DesktopShortcutKey/DesktopShortcutKey'

const ShortcutTitleMap: {
  [key in DesktopShortcutKeys]: string
} = {
  addTask: 'Add task',
  openCalendar: 'Open calendar',
}

enum ShortcutErrorMap {
  DUPLICATE = 'DUPLICATE',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_KEY = 'INVALID_KEY',
}

type DesktopShortcutErrorType = {
  type: ShortcutErrorMap | null
  keys?: string[]
  shortcutKeyIssue?: DesktopShortcutKeys
} | null

export interface DesktopShortcutRowProps {
  disabled: boolean
  shortcutKey: DesktopShortcutKeys
  currentShortcut?: string
  onShortcutChange: (shortcut: string) => void
}

export const DesktopShortcutRow = ({
  disabled,
  shortcutKey,
  currentShortcut,
  onShortcutChange = () => void 0,
}: DesktopShortcutRowProps) => {
  const desktopSettings = useAppSelector(selectDesktopSettings)

  const [isTyping, setIsTyping] = React.useState(false)
  const [error, setError] = React.useState<DesktopShortcutErrorType>(null)
  const [newShortcut, setNewShortcut] = React.useState<string[]>([])
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const sendToDesktop = useSendToDesktop()

  const defaultShortcut = defaultDesktopShortcuts[shortcutKey]

  const { isMac } = getWindowData()

  const areShortcutsSame = (shortcut1: string, shortcut2: string) => {
    if (
      shortcut1?.split(SHORTCUT_DELIMITER).sort().toString() !==
      shortcut2?.split(SHORTCUT_DELIMITER).sort().toString()
    ) {
      return false
    }

    return true
  }

  // Revert shortcuts to last saved state
  const setBackShortcuts = useCallback(() => {
    setIsTyping(false)
    setNewShortcut([])
    buttonRef.current?.blur()
    sendToDesktop('updateSettings', desktopSettings)
  }, [desktopSettings, sendToDesktop])

  // Parse keyboard event to how electron expects it
  const parseKeyboardEvent = (e: KeyboardEvent<HTMLButtonElement>): string => {
    const returnKey = e.key

    if (returnKey === 'Meta' || returnKey === 'CommandOrControl') {
      // Set to 'CommandOrControl' for electron
      return 'CommandOrControl'
    } else if (
      returnKey === 'Alt' ||
      returnKey === 'Control' ||
      returnKey === 'Shift'
    ) {
      return returnKey
    }

    return e.code.replace('Key', '').replace('Digit', '')
  }

  // Register key down events and show them in real time
  const registerNewShortcutKey = (e: KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isMac && e.key === 'Meta') {
      // Cannot set shortcut to cmd on windows
      return
    }

    const key = parseKeyboardEvent(e)

    if (newShortcut.includes(key)) {
      return
    }

    setNewShortcut((prevKeysPressed) => [...prevKeysPressed, key])
  }

  // Check if new shortcut is already taken by another shortcut
  const checkIfDuplicate = useCallback(() => {
    const currentDesktopShortcuts: Record<DesktopShortcutKeys, string> =
      desktopSettings?.shortcuts || defaultDesktopShortcuts

    // Check if shortcut is already taken
    const otherShortcutKeys = Object.keys(currentDesktopShortcuts).filter(
      (key) => key !== shortcutKey
    ) as DesktopShortcutKeys[]

    const isDuplicate = otherShortcutKeys.find((key: DesktopShortcutKeys) => {
      if (
        areShortcutsSame(
          newShortcut.join(SHORTCUT_DELIMITER),
          currentDesktopShortcuts[key]
        )
      ) {
        return key
      }
    })

    if (isDuplicate) {
      setError({
        keys: currentDesktopShortcuts[isDuplicate]?.split(SHORTCUT_DELIMITER),
        shortcutKeyIssue: isDuplicate,
        type: ShortcutErrorMap.DUPLICATE,
      })
      setBackShortcuts()
      return true
    }

    return false
  }, [desktopSettings?.shortcuts, newShortcut, setBackShortcuts, shortcutKey])

  // Check if shortcut is valid (not just one key, starts with ctrl, alt, or command (on mac)) has a letter and includes a letter/number/space/enter
  const checkIfValidFormat = useCallback(() => {
    const isValid =
      newShortcut.length > 1 &&
      newShortcut.some((key) => {
        return ['Control', 'Alt', 'CommandOrControl'].includes(key)
      }) &&
      newShortcut.some((key) => {
        return key.match(/^[a-zA-Z0-9]$/) || key === 'Space' || key === 'Enter'
      })

    if (!isValid) {
      setError({
        type: ShortcutErrorMap.INVALID_FORMAT,
      })
      setBackShortcuts()
      return false
    }

    return true
  }, [newShortcut, setBackShortcuts])

  // Check if shortcut has any invalid keys, keys can only be ctrl, alt, command, shift or a letter/number
  const checkIfValidKeys = useCallback(() => {
    const invalidKeys: string[] = []

    newShortcut.forEach((key) => {
      if (
        !['Control', 'Alt', 'CommandOrControl'].includes(key) &&
        !key.match(/^[a-zA-Z0-9]$/) &&
        !key.match('Shift') &&
        !key.match('Space') &&
        !key.match('Enter')
      ) {
        invalidKeys.push(key)
      }
    })

    if (invalidKeys.length > 0) {
      setError({
        keys: invalidKeys,
        type: ShortcutErrorMap.INVALID_KEY,
      })
      setBackShortcuts()
      return false
    }

    return true
  }, [newShortcut, setBackShortcuts])

  const submitNewShortcut = useCallback(() => {
    onShortcutChange(newShortcut.join(SHORTCUT_DELIMITER))
    setNewShortcut([])
    setIsTyping(false)
  }, [newShortcut, onShortcutChange])

  // Check parameters of shortcut (it has more than one key, not a duplicate, and starts with ctrl, alt, or command)
  const validateNewShortcut = useCallback(() => {
    // Check if any keys pressed
    if (newShortcut.length === 0) {
      setBackShortcuts()
      return
    }

    if (checkIfDuplicate() || !checkIfValidKeys() || !checkIfValidFormat()) {
      return
    }

    // If valid, set shortcut
    submitNewShortcut()
  }, [
    checkIfDuplicate,
    checkIfValidFormat,
    checkIfValidKeys,
    newShortcut.length,
    setBackShortcuts,
    submitNewShortcut,
  ])

  // If any of the keys are released, check if the shortcut is valid
  const checkKeysUnPressed = useCallback(
    (e: KeyboardEvent<HTMLButtonElement>) => {
      const key = parseKeyboardEvent(e)
      if (newShortcut.includes(key)) {
        validateNewShortcut()
      }
    },
    [newShortcut, validateNewShortcut]
  )

  const errorShortcutKeyClass = 'bg-light-100 max-w-max'

  return (
    <div className='flex w-full flex-col gap-1'>
      <Paragraph>{ShortcutTitleMap[shortcutKey]}</Paragraph>
      <div className='flex w-full gap-2'>
        <SelectButton
          as='button'
          ref={buttonRef}
          disabled={disabled}
          className={twMerge('max-w-[200px]', disabled && 'cursor-not-allowed')}
          showChevron={false}
          onClick={() => {
            if (disabled) return

            setIsTyping(true)
            setError({ type: null })
            sendToDesktop('removeShortcuts')
            buttonRef.current?.focus()
          }}
          onBlur={() => {
            setIsTyping(false)
            setBackShortcuts()
          }}
          onKeyDown={registerNewShortcutKey}
          onKeyUp={checkKeysUnPressed}
        >
          {!isTyping && currentShortcut === '' && (
            <SubParagraph className=''>Add shortcut</SubParagraph>
          )}
          {isTyping && newShortcut.length === 0 && (
            <SubParagraph>Type shortcut</SubParagraph>
          )}
          {!isTyping &&
            currentShortcut !== '' &&
            currentShortcut
              ?.split(SHORTCUT_DELIMITER)
              ?.map((shortcutKey) => (
                <DesktopShortcutKey key={shortcutKey} keyName={shortcutKey} />
              ))}
          {isTyping &&
            newShortcut.map((shortcutKey) => (
              <DesktopShortcutKey key={shortcutKey} keyName={shortcutKey} />
            ))}
        </SelectButton>
        {currentShortcut &&
        areShortcutsSame(currentShortcut, defaultShortcut) ? (
          <Tooltip asChild content='Remove'>
            <TextButton
              disabled={disabled}
              icon={XSolid}
              onClick={() => {
                setNewShortcut([])
                setError({ type: null })
                onShortcutChange('')
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip asChild content='Reset'>
            <TextButton
              icon={RefreshSolid}
              disabled={disabled}
              onClick={() => {
                setNewShortcut([])
                setError({ type: null })
                onShortcutChange(defaultShortcut)
              }}
            />
          </Tooltip>
        )}
      </div>
      <div className='text-alert-400 dark:text-alert-400 flex items-center gap-1 text-[11px]'>
        {error && (
          <>
            {error.type === ShortcutErrorMap.DUPLICATE &&
              error.shortcutKeyIssue && (
                <>
                  {error.keys?.map((key) => {
                    return (
                      <DesktopShortcutKey
                        className={errorShortcutKeyClass}
                        key={key}
                        keyName={key}
                      />
                    )
                  })}{' '}
                  <span className='text-[11px]'>
                    is already used for{' '}
                    {ShortcutTitleMap[error.shortcutKeyIssue]}
                  </span>
                </>
              )}
            {error.type === ShortcutErrorMap.INVALID_FORMAT && (
              <>
                Shortcuts must start with{' '}
                <DesktopShortcutKey
                  className={errorShortcutKeyClass}
                  keyName='Alt'
                />{' '}
                {isMac && (
                  <>
                    or{' '}
                    <DesktopShortcutKey
                      className={errorShortcutKeyClass}
                      keyName='CommandOrControl'
                    />
                  </>
                )}
                or{' '}
                <DesktopShortcutKey
                  className={errorShortcutKeyClass}
                  keyName='Control'
                />
              </>
            )}
            {error.type === ShortcutErrorMap.INVALID_KEY && (
              <>
                {error.keys?.map((key) => {
                  return (
                    <DesktopShortcutKey
                      className={errorShortcutKeyClass}
                      key={key}
                      keyName={key}
                    />
                  )
                })}
                can’t be used. Please try again.
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
