import { type Calendar } from '@motion/rpc/types'

import { type ReduxCalendarEvent } from '../../../../../state/calendarEvents/calendarEventsTypes'
import { resolveCalendarId } from '../../../../../utils/calendarUtils'

export const getHostCalendar = (
  event: { calendarId: ReduxCalendarEvent['calendarId'] },
  calendars: Calendar[]
): Calendar => {
  const calendar = calendars.find(
    (c) => resolveCalendarId(c) === event.calendarId
  )
  if (!calendar) {
    throw new Error('Could not find calendar')
  }
  return calendar
}
