import { type WorkspaceCustomField } from '~/areas/custom-fields/types'

import { CustomFieldRow } from './custom-field-row'

type CustomFieldSortProps = {
  fields: WorkspaceCustomField[]
}

export const CustomFieldSort = ({ fields }: CustomFieldSortProps) => {
  return (
    <div className='flex flex-col overflow-y-auto overflow-x-hidden rounded border border-semantic-neutral-border-default [&>*]:border-semantic-neutral-border-default [&>*]:border-b [&>*:last-child]:border-none'>
      {fields.map((field) => (
        <div key={field.id} className='flex items-center gap-3 pl-3 pr-4 py-3'>
          <CustomFieldRow field={field} />
        </div>
      ))}
    </div>
  )
}
