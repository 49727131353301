import { QuestionMarkCircleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import { useIsFlowsM4Enabled } from '~/areas/flows'
import { StageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { twMerge } from 'tailwind-merge'

import { useStageLength } from './use-stage-length'

import { useSetupProjectForm } from '../../../hooks'
import { StepHeader } from '../../styled'
import {
  ControlledDeadlineField,
  ControlledSkipStageCheckbox,
  ControlledStageDeadlineField,
  ControlledStartDateField,
} from '../fields'

export const DateStepContent = () => {
  const { form } = useSetupProjectForm()
  const stageArgs = form.watch('stageDueDates')

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-row items-center gap-4'>
        <ControlledStartDateField />

        <span className='text-semantic-neutral-text-default relative top-2'>
          -
        </span>

        <ControlledDeadlineField />
      </div>

      <div className='flex flex-col gap-5'>
        <StepHeader>Stage deadlines</StepHeader>

        <div className='flex flex-col gap-3'>
          {stageArgs.map((_, i) => {
            return <StageDeadline key={i} i={i} />
          })}
        </div>
      </div>
    </div>
  )
}

function StageDeadline({ i }: { i: number }) {
  const { form } = useSetupProjectForm()
  const { pluralize } = useI18N()
  const stageLength = useStageLength(i)
  const stage = form.watch(`projectDefinition.stages.${i}`)
  const numStages = form.watch('stageDueDates').length
  const skipped = form.watch(`stageDueDates.${i}.skipped`)
  const isFlowsM4Enabled = useIsFlowsM4Enabled()

  const isError = stageLength < 0

  const errorTooltipContent =
    i === 0
      ? `Deadline must be after the project's start date and before the next stage's deadline`
      : i === numStages - 1
        ? `Deadline must be after the previous stage's deadline and before the project's deadline`
        : `Deadline must be after the previous stage's deadline and before the next stage's deadline`

  const SHOW_NAME_TOOLTIP_AFTER_LENGTH = 14
  const nameTooltipContent =
    stage.name.length > SHOW_NAME_TOOLTIP_AFTER_LENGTH ? stage.name : undefined

  const isSkippingStage = isFlowsM4Enabled && skipped

  const stageLabel = (
    <Tooltip content={nameTooltipContent} asChild>
      <div className='flex truncate'>
        <StageLabel
          value={stage}
          variant={isSkippingStage ? 'skipped' : 'default'}
        />
      </div>
    </Tooltip>
  )

  return (
    <div className='flex flex-row items-center justify-between gap-2'>
      <div className='flex flex-row items-center h-6 gap-2 truncate'>
        {isFlowsM4Enabled ? (
          <ControlledSkipStageCheckbox
            index={i}
            valuePath={`stageDueDates.${i}.skipped`}
            label={stageLabel}
          />
        ) : (
          stageLabel
        )}

        <span
          className={twMerge(
            'text-semantic-neutral-text-subtle',
            isError && 'text-semantic-error-text-default'
          )}
        >
          {isError ? (
            <Tooltip content={errorTooltipContent} asChild>
              <div className='flex flex-row items-center gap-1'>
                Invalid stage deadline
                <QuestionMarkCircleSolid className='w-4 h-4 text-semantic-error-text-default cursor-pointer' />
              </div>
            </Tooltip>
          ) : isSkippingStage ? (
            <em>Stage will be skipped</em>
          ) : (
            pluralize(
              stageLength,
              '{{ count }} business day',
              '{{ count }} business days'
            )
          )}
        </span>
      </div>

      {!isSkippingStage && (
        <div className='flex shrink-0 justify-end'>
          <ControlledStageDeadlineField
            isError={isError}
            stageDueDatesPath={`stageDueDates.${i}`}
          />
        </div>
      )}
    </div>
  )
}
