import { classed } from '@motion/theme'
import { getEnabledStagesWithDates } from '@motion/ui-logic/pm/project'
import { useHasTreatment } from '@motion/web-common/flags'
import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { DeprecatedProjectStageItem } from './deprecated-project-stage-item'
import { ResizeableProjectStageItem } from './stage-item'

import { type Side } from '../resize-handle'

type StagesBarProps = {
  project: ProjectSchema
  // Amount the project has been resized
  deltaWidth?: number
  // Side that the project is currently being resized from
  currentSide?: Side
}

export const StagesBar = ({
  project,
  deltaWidth = 0,
  currentSide = 'left',
}: StagesBarProps) => {
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')

  const stagesWithDates = getEnabledStagesWithDates(project.stages ?? [], {
    start: project.startDate,
    due: project.dueDate,
  })

  if (hasBetterResizeStages) {
    return (
      <div className='w-full h-2.5 relative'>
        <BarOverlay />
        <div className='flex items-center w-full h-full max-w-full overflow-hidden rounded-b-md px-0.5'>
          {stagesWithDates.map((stage, stageIndex) => {
            if (!stage.stage?.id) return null

            return (
              <ResizeableProjectStageItem
                projectDeltaWidth={deltaWidth}
                currentSide={currentSide}
                key={stage.stage?.id ?? `stage-key-${stageIndex}`}
                project={project}
                stageWithDates={stage}
              />
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className='w-full h-2.5 relative'>
      <BarOverlay />
      <div className='flex items-center w-full h-full max-w-full overflow-hidden rounded-b-md px-0.5'>
        {stagesWithDates.map((stage, index) => {
          if (!stage.stage?.id) return null

          let isPrevStageDone = false
          if (index > 0) {
            const prevStage = stagesWithDates[index - 1]
            isPrevStageDone =
              !!prevStage.stage?.completedDuration &&
              prevStage.stage?.completedDuration === prevStage.stage?.duration
          }

          return (
            <DeprecatedProjectStageItem
              key={stage.stage?.id ?? 'stage-key'}
              stageIndex={index}
              currentSide={currentSide}
              project={project}
              projectStage={stage.stage}
              prevStageDone={isPrevStageDone}
              stageStart={DateTime.fromISO(stage.start)}
              stageEnd={DateTime.fromISO(stage.due)}
            />
          )
        })}
      </div>
    </div>
  )
}

const BarOverlay = classed('div', {
  base: `
    absolute top-0 left-0
    w-full h-full
    bg-semantic-neutral-text-default opacity-5 dark:opacity-10
    rounded-b-md
  `,
})
