import { isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { Button, Tooltip } from '@motion/ui/base'
import { createNoneProject } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSchema } from '@motion/zod/client'

import { getGhostDisabledTooltipContent } from '~/areas/task-project/components'
import {
  FolderField,
  WorkspaceField,
  WorkspaceProjectField,
} from '~/areas/task-project/fields'
import { useMoveTaskToWorkspaceProject } from '~/areas/tasks/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useTaskModalState } from '../contexts'
import { useTaskForm } from '../hooks'

export function ControlledWorkspaceFolderProjectField() {
  const { toggleSidePanel, sidePanelOpen } = useTaskModalState()
  const { form, disabledFields, hiddenFields } = useTaskForm()
  const { control, watch } = form
  const context = useRouteAnalyticsMetadata()
  const moveTaskTo = useMoveTaskToWorkspaceProject()

  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control,
  })

  const taskId = watch('id')

  const selectedWorkspace = useWorkspaceById(workspaceIdField.value)
  const selectedProject =
    useProject(projectIdField.value) ??
    createNoneProject(workspaceIdField.value)

  const isUnvisitedStage = watch('isUnvisitedStage')
  const isGhost =
    isUnvisitedStage &&
    disabledFields.has('workspaceId') &&
    disabledFields.has('projectId')

  if (selectedWorkspace == null) return null

  const hasProject = !isNoneId(selectedProject.id)
  const showProjectField = !hiddenFields.has('projectId')

  const updateWorkspaceProject = async (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => {
    if (
      newWorkspaceId === selectedWorkspace.id &&
      newProjectId === selectedProject.id
    ) {
      return
    }

    // Creating a new task
    if (taskId == null) {
      if (newWorkspaceId !== workspaceIdField.value) {
        workspaceIdField.onChange(newWorkspaceId)
      }
      if (newProjectId !== projectIdField.value) {
        projectIdField.onChange(newProjectId)
      }

      return
    }

    if (newProjectId == null && sidePanelOpen) {
      toggleSidePanel()
    }

    // When updating an existing task, we trigger the update right away
    moveTaskTo(taskId, newWorkspaceId, newProjectId)
  }

  const onWorkspaceChange = (newWorkspace: WorkspaceSchema) =>
    updateWorkspaceProject(newWorkspace.id, null)

  const onWorkspaceProjectChange = (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => updateWorkspaceProject(newWorkspaceId, newProjectId)

  return (
    <LocationSectionContainer>
      <LocationFieldTitle>Location</LocationFieldTitle>

      <Tooltip
        content={!isGhost ? null : getGhostDisabledTooltipContent('workspace')}
      >
        <WorkspaceField
          workspaceId={workspaceIdField.value}
          onChange={onWorkspaceChange}
          disabled={disabledFields.has('workspaceId')}
        />
      </Tooltip>

      <FolderField
        workspaceId={workspaceIdField.value}
        folderId={selectedProject.folderId ?? null}
        onChange={() => null}
        // TODO: Disabled for now, still working on the implentation
        disabled
      />

      {showProjectField && (
        <Tooltip
          content={!isGhost ? null : getGhostDisabledTooltipContent('project')}
        >
          <WorkspaceProjectField
            workspaceId={workspaceIdField.value}
            projectId={projectIdField.value}
            onChange={onWorkspaceProjectChange}
            disabled={disabledFields.has('projectId')}
            suffix={
              hasProject ? (
                <Button
                  size='xsmall'
                  variant='outlined'
                  sentiment='neutral'
                  onClick={() => {
                    toggleSidePanel()
                    recordAnalyticsEvent(
                      sidePanelOpen
                        ? 'TASK_SIDEBAR_CLOSE'
                        : 'TASK_SIDEBAR_OPEN',
                      context
                    )
                  }}
                >
                  {sidePanelOpen ? 'Close' : 'Open'}
                </Button>
              ) : undefined
            }
          />
        </Tooltip>
      )}
    </LocationSectionContainer>
  )
}

const LocationSectionContainer = classed('div', {
  base: 'flex flex-col gap-0 modal-lg:gap-1.5',
})

const LocationFieldTitle = classed('div', {
  base: 'text-semantic-neutral-text-subtle text-2xs pb-1 px-1',
})
