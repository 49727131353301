import { twMerge } from 'tailwind-merge'

import { parseShortcutKey } from '../../../../utils/desktop'

export interface DesktopShortcutKeyProps {
  keyName: string
  className?: string
}

export const DesktopShortcutKey = ({
  keyName,
  className = '',
}: DesktopShortcutKeyProps) => {
  return (
    <div
      className={twMerge(
        'bg-light-400 dark:bg-dark-1000 flex h-4 items-center justify-center rounded-sm p-1',
        className
      )}
    >
      <span className='text-light-1100 dark:text-dark-400 text-[11px] font-semibold'>
        {parseShortcutKey(keyName)}
      </span>
    </div>
  )
}
