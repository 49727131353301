import { API } from '@motion/rpc'
import { MotionCache } from '@motion/rpc-cache'
import { type SocketEvent } from '@motion/shared/websockets'
import { Sentry } from '@motion/web-base/sentry'
import { client } from '@motion/web-common/rpc'
import { type CustomFieldSchema } from '@motion/zod/client'

import { getCustomFieldQueryFilters } from '~/global/cache'
import { websocketsService } from '~/services/websockets-service'

import {
  bulkTaskOperations,
  feedRefresh,
  invalidateFolders,
  invalidateUserSettings,
  invalidateWorkspacesV2,
  refreshPastDueTasks,
  refreshScheduledEntities,
  taskRefresh,
} from './listeners'
import { type Context } from './types'
import { formatWSEventData } from './utils'

import { log } from '../log'

export function handlePmWebsocketEvent(ctx: Context, event: SocketEvent) {
  Sentry.addBreadcrumb({
    category: 'websocket',
    message: event.type,
    level: 'info',
    data: formatWSEventData(event),
  })

  log.info('received pm event', event)
  switch (event.type) {
    case 'workspace.created':
      return void websocketsService.refreshRooms()
    case 'workspace.task.update':
    case 'workspace.recurringTask.update':
      return ctx.batch.append(taskRefresh({ update: [event.data.task.id] }))
    case 'workspace.task.create':
    case 'workspace.recurringTask.create':
      return ctx.batch.append(taskRefresh({ create: [event.data.task.id] }))
    case 'workspace.task.delete':
    case 'workspace.recurringTask.delete':
      return ctx.batch.append(taskRefresh({ delete: [event.data.task.id] }))
    case 'workspace.tasksScheduled':
      ctx.batch.append(
        refreshScheduledEntities({ workspaceId: event.data.workspaceId })
      )
      ctx.batch.append(refreshPastDueTasks())
      return ctx.batch.append(taskRefresh(event.data.mutations))
    case 'workspace.deleted':
      invalidateWorkspacesV2()
      invalidateUserSettings()
      break
    case 'feed.updated':
      return ctx.batch.append(
        feedRefresh({
          [event.data.modelType === 'task' ? 'tasks' : 'projects']: [
            event.data.id,
          ],
        })
      )
    case 'workspace.stage.visited':
      return ctx.batch.append(taskRefresh({ update: event.data.taskIds }))
    case 'workspace.tasks.bulk.updated':
      ctx.batch.append(bulkTaskOperations(event.data, 'update'))

      return ctx.batch.append(
        taskRefresh({
          update: event.data.taskIds,
        })
      )
    case 'workspace.tasks.bulk.deleted':
      ctx.batch.append(bulkTaskOperations(event.data, 'delete'))

      return ctx.batch.append(
        taskRefresh({
          delete: event.data.taskIds,
        })
      )

    case 'team.memberJoined':
      invalidateWorkspacesV2()
      void client.invalidateQueries({
        queryKey: API.teams.queryKeys.currentTeam,
      })
      break
    case 'workspace.customField.create':
    case 'workspace.customField.update': {
      const { customField } = event.data as { customField: CustomFieldSchema }

      MotionCache.upsert(client, getCustomFieldQueryFilters(), {
        models: { customFields: { [customField.id]: customField } },
      })
      break
    }
    case 'workspace.customField.delete': {
      MotionCache.delete(
        client,
        getCustomFieldQueryFilters(),
        'customFields',
        event.data.customField
      )
      break
    }

    case 'workspace.status.create':
    case 'workspace.status.update':
    case 'workspace.status.delete':
    case 'workspace.label.create':
    case 'workspace.label.update':
    case 'workspace.label.delete':
      invalidateWorkspacesV2()
      break
    case 'workspace.project.create':
      invalidateWorkspacesV2()
      break
    case 'workspace.project.update':
      invalidateWorkspacesV2()
      break
    case 'workspace.project.delete':
      invalidateWorkspacesV2()
      break
    case 'workspace.stage.create':
    case 'workspace.stage.update':
      invalidateWorkspacesV2()
      break
    case 'folder.created':
    case 'folder.updated':
    case 'folder.deleted':
    case 'folder.item.created':
    case 'folder.item.updated':
    case 'folder.item.deleted':
      invalidateFolders()
      invalidateWorkspacesV2()
    case 'workspace.definition.reconciled':
      // TODO
      break
    default:
      break
  }
}
