import { type EventConferenceTypes } from '@motion/shared/common'
import {
  formatDurationTime,
  isValidConferenceTypeForProviderType,
  recurrenceRuleToText,
  updateDateOnly,
} from '@motion/ui-logic'
import {
  disabledGoogleEventColorIds,
  getColorClassForColorId,
  handleIsAllDayChange,
  handleStartDateChange,
  handleStartTimeChange,
  isColorId,
} from '@motion/ui-logic/calendar'
import { parseDate } from '@motion/utils/dates'
import { logInDev } from '@motion/web-base/logging'
import { type AttendeeStatus } from '@motion/zod/client'

import { useGenerateConferencingData } from '~/areas/event/modals/event-modal/hooks'
import { type DateTime } from 'luxon'
import { useController } from 'react-hook-form'

import { useEventForm } from './use-event-form-hook'
import { useManualEventFormSubmit } from './use-event-form-submit'

import { useEventFormOptions } from '../event-form-options-context'

export const useEventFormAllDayField = () => {
  const { control, getValues, setValue } = useEventForm()
  const { field } = useController({
    name: 'isAllDay',
    control,
  })

  const onChange = (checked: boolean) => {
    const start = getValues('start')
    const end = getValues('end')
    const updates = handleIsAllDayChange(checked, { start, end })
    field.onChange(updates.isAllDay)
    setValue('start', updates.start, { shouldDirty: true })
    setValue('end', updates.end, { shouldDirty: true })
    setValue('status', updates.status, { shouldDirty: true })
  }
  return { field, onChange }
}

export const useEventFormTitleField = () => {
  const { control } = useEventForm()
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'title',
    control,
    rules: {
      required: true,
    },
  })

  return { field, onChange: field.onChange, error }
}

export const useEventFormRecurrenceField = () => {
  const { watch, control, getValues, setValue } = useEventForm()
  const { hostCalendar } = useEventFormOptions()

  const start = watch('start')
  const { field } = useController({
    name: 'recurrence',
    control,
  })
  const recurrenceText = recurrenceRuleToText(field.value)
  const hasRecurrence = Boolean(recurrenceText)

  const onChange = (recurrence: string) => {
    const colorId = getValues('colorId')
    field.onChange(recurrence)
    if (
      Boolean(recurrence) &&
      isColorId(colorId) &&
      disabledGoogleEventColorIds.includes(colorId)
    ) {
      // reset color id to host calendar color when user sets recurrence value
      setValue('colorId', hostCalendar.colorId)
    }
  }

  return {
    field,
    onChange: onChange,
    formValues: { start },
    hasRecurrence,
    recurrenceText,
  }
}

export const useEventStartDateField = () => {
  const { control, watch, setValue, getValues } = useEventForm()
  const isAllDay = watch('isAllDay')
  const { field } = useController({
    name: 'start',
    control,
  })

  const onChange = (value: DateTime) => {
    const start = getValues('start')
    const end = getValues('end')
    const updates = handleStartDateChange(value, { start, end, isAllDay })
    field.onChange(updates.start)
    setValue('end', updates.end, { shouldDirty: true })
  }

  return {
    field,
    onChange,
  }
}

export const useEventStartTimeField = () => {
  const { control, setValue, getValues } = useEventForm()

  const { field } = useController({
    name: 'start',
    control,
  })

  const onChange = (value: DateTime) => {
    const start = getValues('start')
    const end = getValues('end')
    const updates = handleStartTimeChange(value, { start, end })
    field.onChange(updates.start)
    setValue('end', updates.end, { shouldDirty: true })
  }

  return {
    field,
    onChange,
  }
}

export const useEventEndTimeField = () => {
  const { control, getValues } = useEventForm()

  const { field } = useController({
    name: 'end',
    control,
  })

  const onChange = (value: DateTime) => {
    field.onChange(value.toUTC().toISO())
  }

  return {
    field,
    onChange,
    formValues: { start: getValues('start') },
  }
}

export const useEventEndDateField = () => {
  const { control, watch, getValues, setValue } = useEventForm()
  const isAllDay = watch('isAllDay')
  const { field } = useController({
    name: 'end',
    control,
  })

  const onChange = (value: DateTime) => {
    const start = getValues('start')
    const end = getValues('end')
    const startDateTime = parseDate(start)
    const endDateTime = parseDate(end)
    const duration = startDateTime.until(endDateTime).toDuration()

    const newEndDateTime = isAllDay
      ? value.plus({ days: 1 })
      : updateDateOnly(endDateTime, value)

    setValue(
      'end',
      isAllDay ? newEndDateTime.toISODate() : newEndDateTime.toUTC().toISO(),
      { shouldDirty: true }
    )
    if (newEndDateTime > startDateTime) return
    const newStartDateTime = newEndDateTime.minus(duration)
    setValue(
      'start',
      isAllDay
        ? newStartDateTime.toISODate()
        : newStartDateTime.toUTC().toISO(),
      { shouldDirty: true }
    )
  }

  return {
    value: isAllDay
      ? parseDate(field.value).minus({ days: 1 }).toISODate()
      : field.value,
    onChange,
  }
}

export const useEventGuestField = () => {
  const { control } = useEventForm()
  const { field } = useController({
    name: 'attendees',
    control,
  })

  return {
    field,
    onChange: field.onChange,
  }
}

export const useEventTravelTimeField = () => {
  const { control } = useEventForm()

  const { field: travelTimeAfter } = useController({
    name: 'travelTimeAfter',
    control,
  })

  const { field: travelTimeBefore } = useController({
    name: 'travelTimeBefore',
    control,
  })

  const hasTravelTime =
    travelTimeBefore.value != null || travelTimeAfter.value != null
  const duration: number = travelTimeBefore.value ?? travelTimeAfter.value ?? 0
  const durationText = formatDurationTime(duration)
  const suffix = [
    travelTimeBefore.value ? 'before' : null,
    travelTimeAfter.value ? 'after' : null,
  ]
    .filter(Boolean)
    .join(' & ')
  const travelTimeText = `${durationText} travel time (${suffix})`

  return {
    travelTimeAfter,
    travelTimeBefore,
    hasTravelTime,
    travelTimeText,
  }
}

export const useEventHostField = () => {
  const { setValue, watch } = useEventForm()

  const onChange = (
    values: {
      email: string
      calendarId: string
      organizerEmail?: string
    },
    { shouldDirty } = { shouldDirty: true }
  ) => {
    setValue('email', values.email, { shouldDirty })
    setValue('calendarId', values.calendarId, { shouldDirty })
    values.organizerEmail && setValue('organizerEmail', values.organizerEmail)
  }

  return {
    onChange,
    isEditable: !watch('id'),
  }
}

export const useEventFormConferencingField = () => {
  const { control, watch, setValue, getValues } = useEventForm()
  const { setLoading, hostCalendar } = useEventFormOptions()
  const generateConferenceData = useGenerateConferencingData()
  const { field } = useController({
    name: 'conferenceType',
    control,
  })

  const onChange = async (
    conferenceType: (typeof EventConferenceTypes)[number]
  ) => {
    if (
      !isValidConferenceTypeForProviderType(
        conferenceType,
        hostCalendar.providerType
      )
    ) {
      return
    }
    setLoading(true)
    try {
      const { description, location } = await generateConferenceData(
        conferenceType,
        getValues('description'),
        {
          endTime: getValues('end'),
          recurringRule: getValues('recurrence'),
          startTime: getValues('start'),
          topic: getValues('title'),
        }
      )
      setValue('conferenceType', conferenceType, { shouldDirty: true })
      setValue('description', description, { shouldDirty: true })
      setValue('location', location, { shouldDirty: true })
    } catch (err) {
      logInDev('Error setting conference type', err)
      setValue('conferenceType', 'none', { shouldDirty: true })
    }
    setLoading(false)
  }

  return {
    field,
    onChange,
    formValues: {
      conferenceLink: watch('conferenceLink'),
    },
  }
}

export const useEventFormDescriptionField = () => {
  const { control } = useEventForm()

  const { field } = useController({
    name: 'description',
    control,
  })

  return {
    field,
    onChange: field.onChange,
  }
}

export const useEventFormLocationField = () => {
  const { control } = useEventForm()

  const { field } = useController({
    name: 'location',
    control,
  })

  return {
    field,
    onChange: field.onChange,
  }
}

export const useEventFormStatusField = () => {
  const { control } = useEventForm()

  const { field } = useController({
    name: 'status',
    control,
  })

  return {
    field,
    onChange: field.onChange,
  }
}

export const useEventFormVisibilityField = () => {
  const { control } = useEventForm()

  const { field } = useController({
    name: 'visibility',
    control,
  })

  return {
    field,
    onChange: field.onChange,
  }
}

// TODO: after sch-ent-calendar confirm any other changes for this
export const useEventFormRSVPField = () => {
  const { hostEmailAccount } = useEventFormOptions()
  const { watch, formState, getValues, setValue } = useEventForm()
  const { handleSubmit } = useManualEventFormSubmit()
  const attendees = watch('attendees')
  const calendarOwnerAttendee =
    attendees?.find((a) => a.email === hostEmailAccount?.email) ?? null

  const onChange = (status: AttendeeStatus) => {
    if (!calendarOwnerAttendee) return
    const newAttendees = attendees.map((attendee) => {
      if (attendee.email === calendarOwnerAttendee.email) {
        return { ...attendee, status }
      }
      return { ...attendee }
    })
    setValue('attendees', newAttendees, { shouldDirty: true })
    const id = getValues('id')
    if (id) {
      void handleSubmit({ id, attendees: newAttendees })
    }
  }

  return {
    value: calendarOwnerAttendee?.status ?? 'needsAction',
    visible:
      calendarOwnerAttendee != null && !formState.isDirty && getValues('id'),
    onChange,
  }
}

export const useEventColorId = () => {
  const { control } = useEventForm()
  const { hostCalendar } = useEventFormOptions()
  const { field } = useController({
    name: 'colorId',
    control,
  })
  return {
    value: getColorClassForColorId(field.value ?? hostCalendar.colorId),
    onChange: field.onChange,
  }
}
