import { useMyUserSettings } from '@motion/web-common/settings'

export const useAutoScheduleSettings = () => {
  const { data: settings } = useMyUserSettings()

  return {
    taskBreaksEnabled:
      settings?.autoScheduleSettings?.taskBreaksEnabled ?? true,
    taskBreakDuration: settings?.autoScheduleSettings?.taskBreakDuration ?? 15,
    taskBreakIntervalHours:
      settings?.autoScheduleSettings?.taskBreakIntervalHours ?? 2,
    taskTimeBlockMode:
      settings?.autoScheduleSettings?.taskTimeBlockMode ?? 'no-events',
    showTaskNamesInEvents:
      settings?.autoScheduleSettings?.showTaskNamesInEvents ?? true,
  }
}
