import { byProperty, cascade, Compare } from '@motion/utils/array'
import { cloneDeep } from '@motion/utils/core'
import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { DEFAULT_PROJECT_LENGTH_IN_WEEKS } from './project'

/*
 * Given a list of projects, we need to stack them based on the start and end date
 * We return an array of arrays, where each array is a row of projects
 * A row of projects is a list of projects that do not overlap with each other
 * When a project overlaps with another project, we create a new row
 *
 * @param rowProjects - An array of projects
 * @returns - Array of arrays of projects (where each array is a row of non-overlapping projects)
 */
export function organizeProjectsIntoRows(
  rowProjects: ProjectSchema[]
): ProjectSchema[][] {
  // Add project map (so you can always place the original project in the row)
  const projectsMap = new Map<string, ProjectSchema>()
  rowProjects.forEach((project) => {
    projectsMap.set(project.id, project)
  })

  // Sort the projects by start date, filter out items with no start and end date
  const cleanedProjects = rowProjects
    .filter((project) => project.startDate || project.dueDate)
    .map((p) => {
      const project = cloneDeep(p)

      // If it has no start date, set it to -DEFAULT_PROJECT_LENGTH_IN_WEEKS week from the end date
      if (project.startDate == null && project.dueDate != null) {
        project.startDate = DateTime.fromISO(project.dueDate)
          .minus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
          .toISO()
      }

      // If it has no end date, set it to +DEFAULT_PROJECT_LENGTH_IN_WEEKS week from the start date
      if (project.dueDate == null && project.startDate != null) {
        project.dueDate = DateTime.fromISO(project.startDate)
          .plus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
          .toISO()
      }

      return project
    })
    .filter((project) => project.startDate && project.dueDate)
    .sort(
      cascade(
        byProperty('startDate', Compare.string),
        byProperty('dueDate', Compare.string)
      )
    )

  const rows: ProjectSchema[][] = []

  for (const item of cleanedProjects) {
    let placed = false
    for (const row of rows) {
      // Check for collision in the current row
      const hasCollision = row.some(
        (rowItem) =>
          !!item.startDate &&
          !!item.dueDate &&
          !!rowItem.startDate &&
          !!rowItem.dueDate &&
          DateTime.fromISO(item.startDate).endOf('day').toISO() <
            DateTime.fromISO(rowItem.dueDate).startOf('day').toISO() &&
          DateTime.fromISO(item?.dueDate).startOf('day').toISO() >
            DateTime.fromISO(rowItem.startDate).endOf('day').toISO()
      )

      if (!hasCollision && item) {
        row.push(item)
        placed = true
        break
      }
    }

    // If item could not be placed in any existing row, create a new row
    if (!placed && item) {
      rows.push([item])
    }
  }

  // Go through the rows and replace the projects with the original projects
  return rows.map((row) =>
    row.map((project) => projectsMap.get(project.id) as ProjectSchema)
  )
}
