import { MagicWandSolid } from '@motion/icons'
import { GradientButton } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useSelectView } from '../../views'

export const ConnectedAiViewGenerationButton = () => {
  const modalApi = useModalApi()
  const selectView = useSelectView()

  return (
    <GradientButton
      onClick={() => {
        modalApi.open('ai-view-generation-modal', {
          selectView,
        })
      }}
      sentiment='promotion'
    >
      <MagicWandSolid />
      Generate view
    </GradientButton>
  )
}
