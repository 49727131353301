import {
  Banner,
  Button,
  ButtonTabs,
  LoadingSpinner,
  Modal,
  Shortcut,
  useShortcut,
} from '@motion/ui/base'
import { Checkbox, TextField } from '@motion/ui/forms'
import { templateStr } from '@motion/ui-logic'
import { getWindowData } from '@motion/web-base/env'

import { DateTime } from 'luxon'
import { useCallback } from 'react'

import {
  EmptyResults,
  SearchResults,
  type SearchResultsProps,
} from './components'
import { useListNavigation } from './hooks'
import {
  isSearchingEvents,
  isSearchingTasks,
  isValidSearch,
  type SearchType,
} from './utils'

export type SearchModalProps = {
  searchQuery: string
  searchType: SearchType
  disableSearchTypeEvents: boolean
  onSearch: (query: string, searchType: SearchType) => void
  onClose: () => void
  searchResults?: SearchResultsProps['items']
  loading?: boolean
  pending?: boolean
}

export const SearchModal = ({
  searchQuery,
  searchType,
  disableSearchTypeEvents,
  onSearch,
  onClose,
  searchResults = [],
  loading = false,
  pending = false,
}: SearchModalProps) => {
  const { isElectron } = getWindowData()

  const getFirstActiveIndex = useCallback(
    (items: SearchResultsProps['items']) => {
      if (searchType.type !== 'events') return 0
      const now = DateTime.now().startOf('day').toUTC().toISO()
      return items.findIndex((item) => {
        if (item.entityType !== 'event') return true
        return item.start >= now
      })
    },
    [searchType.type]
  )

  const { listRef, activeIndex } = useListNavigation({
    items: searchResults,
    getFirstActiveIndex,
  })

  const handleSearchArchive = () => {
    onSearch(searchQuery, {
      type: 'tasks',
      archived: true,
      showCompleted: isSearchingTasks(searchType)
        ? searchType.showCompleted
        : true,
    })
  }

  const handleSearchTasks = () => {
    onSearch(searchQuery, {
      type: 'tasks',
      archived: false,
      showCompleted: isSearchingTasks(searchType)
        ? searchType.showCompleted
        : true,
    })
  }

  const handleSearchEvents = () => {
    onSearch(searchQuery, { type: 'events' })
  }

  const shouldShowTabs =
    !disableSearchTypeEvents &&
    (isSearchingEvents(searchType) ||
      (isSearchingTasks(searchType) && !searchType.archived))

  useShortcut('mod+1', handleSearchTasks, {
    enabled: isElectron && shouldShowTabs,
  })
  useShortcut('mod+2', handleSearchEvents, {
    enabled: isElectron && shouldShowTabs,
  })

  const isNotReallyPendingSearching = pending && !isValidSearch(searchQuery)
  const shouldShowEmptyResults =
    !loading &&
    searchResults.length === 0 &&
    (!pending || isNotReallyPendingSearching)

  return (
    <Modal visible onClose={onClose}>
      <div className='flex flex-col gap-2'>
        {isSearchingTasks(searchType) && searchType.archived && (
          <Banner
            sentiment='error'
            actions={[
              {
                label: 'Back to regular search',
                onAction: () =>
                  onSearch(searchQuery, {
                    type: 'tasks',
                    archived: false,
                    showCompleted: true,
                  }),
              },
            ]}
          >
            Searching archived tasks
          </Banner>
        )}
        <div className='bg-modal-bg flex w-[800px] flex-col'>
          <div className='flex p-3 border-b border-semantic-neutral-border-subtle'>
            <div className='w-full flex gap-4 items-center'>
              <TextField
                autoFocus
                selectTextOnFocus
                showClearButton
                variant='muted'
                label={
                  disableSearchTypeEvents
                    ? 'Search tasks and projects'
                    : 'Search tasks, projects and events'
                }
                labelHidden
                size='large'
                fullWidth
                value={searchQuery}
                placeholder='Search'
                onChange={(value) => {
                  onSearch(value, searchType)
                }}
              />
              {shouldShowTabs && (
                <div>
                  <ButtonTabs
                    size='small'
                    activeValue={searchType.type}
                    items={[
                      {
                        value: 'tasks',
                        content: isElectron
                          ? templateStr('Tasks {{shortcut}}', {
                              shortcut: <Shortcut shortcut='mod+1' />,
                            })
                          : 'Tasks',
                        onAction: handleSearchTasks,
                      },
                      {
                        value: 'events',
                        content: isElectron
                          ? templateStr('Events {{shortcut}}', {
                              shortcut: <Shortcut shortcut='mod+2' />,
                            })
                          : 'Events',
                        onAction: handleSearchEvents,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
          {isSearchingTasks(searchType) && (
            <div className='bg-semantic-neutral-surface-overlay-bg-subtlest py-2 px-3'>
              <Checkbox
                checked={searchType.showCompleted}
                onChange={(checked) => {
                  onSearch(searchQuery, {
                    ...searchType,
                    showCompleted: checked,
                  })
                }}
                label='Show completed'
                size='small'
              />
            </div>
          )}
          <div className='h-[350px] p-2 overflow-auto scroll-py-2'>
            {loading ? (
              <div className='grid place-items-center h-full'>
                <LoadingSpinner />
              </div>
            ) : shouldShowEmptyResults ? (
              <EmptyResults
                searchQuery={searchQuery}
                searchType={searchType}
                disableSearchTypeEvents={disableSearchTypeEvents}
                onClickArchive={handleSearchArchive}
                onClickEvents={handleSearchEvents}
                onClickTasks={handleSearchTasks}
              />
            ) : (
              <>
                <SearchResults
                  ref={listRef}
                  items={searchResults}
                  onItemClick={onClose}
                  activeIndex={activeIndex}
                />
                {isValidSearch(searchQuery) &&
                  isSearchingTasks(searchType) &&
                  !searchType.archived &&
                  !pending && (
                    <div className='grid gap-2 justify-items-center text-semantic-neutral-text-subtle pt-5 pb-3'>
                      <div className='font-semibold text-s'>
                        No more results
                      </div>
                      <div className='text-xs'>
                        {`Can't find what you're looking for? `}
                        <Button
                          variant='link'
                          sentiment='primary'
                          size='small'
                          onClick={handleSearchArchive}
                        >
                          Search archived tasks
                        </Button>
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
