import { PlusCircleSolid, XSolid } from '@motion/icons'
import { findDefaultStatus } from '@motion/shared/common'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import { calculateNextFridayDueDate } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type TasksV2CreateSchema } from '@motion/zod/client'

import { useCreateTask } from '~/areas/tasks/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useRef, useState } from 'react'

import {
  type CreateBulkTasksRowRef,
  CreateTasksBulkRow,
} from './create-tasks-bulk-row'

import { LabelTag } from '../../../components/Common/LabelTag/LabelTag'
import { useExperiment } from '../../../localServices/experiments'

export type CreateTasksBulkProps = {
  goToNextPage: () => void
}

const defaultSuggestedTasks = [
  'Create Youtube video',
  'Draft plan for team',
  'Send proposal',
  'Contact leads',
  'Learn DIY project',
  'Do meal prep',
  'Book flights',
  'Read article',
]

type taskCreateObject = {
  title: string
  duration: number
  deadline: string
}

const defaultTask: taskCreateObject = {
  title: '',
  duration: 30,
  deadline: calculateNextFridayDueDate(),
}

const makeTooltipString = (minTasks: number, numTasks: number) => {
  if (numTasks >= minTasks) return null
  const plural = numTasks > 1 ? 's' : ''
  return `Add ${minTasks} more task${plural} to continue!`
}

export const CreateTasksBulk = ({ goToNextPage }: CreateTasksBulkProps) => {
  const createTask = useCreateTask()

  const { getWorkspaceStatuses, getMyTasksWorkspace } = useWorkspaceFns()

  const [tasks, setTasks] = useState<taskCreateObject[]>([])
  const [activeRow, setActiveRow] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const [stagedTask, setStagedTask] = useState<taskCreateObject>({
    ...defaultTask,
  })
  const taskRowRef = useRef<CreateBulkTasksRowRef>(null)

  const { uid: currentUserId } = useAuthenticatedUser()

  const minimumTasksToContinue =
    Number(useExperiment('bulk_add_tasks_minimum_tasks').value) || 1

  const addTask = (task: taskCreateObject) => {
    if (task.title.trim() === '') return
    setTasks([...tasks, task])
    setStagedTask({ ...defaultTask })
  }

  const deleteTask = (index: number) => {
    setTasks(tasks.slice(0, index).concat(tasks.slice(index + 1)))
  }

  async function onSubmit() {
    const myTasksWorkspace = getMyTasksWorkspace()
    if (!myTasksWorkspace) {
      throw new Error('My tasks workspace not set')
    }
    setLoading(true)

    const workspaceStatuses = getWorkspaceStatuses(myTasksWorkspace.id)
    const defaultStatus = findDefaultStatus(workspaceStatuses)

    try {
      await Promise.all(
        tasks.map(async (task) => {
          const taskToCreate: TasksV2CreateSchema['data'] = {
            type: 'NORMAL',
            assigneeUserId: currentUserId,
            deadlineType: 'SOFT',
            description: '',
            dueDate: task.deadline,
            duration: task.duration,
            name: task.title.trim(),
            priorityLevel: 'MEDIUM',
            scheduleId: 'work',
            startDate: DateTime.now().toISODate(),
            statusId: defaultStatus?.id,
            isAutoScheduled: true,
            workspaceId: myTasksWorkspace?.id ?? '',
          }

          recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_TASK', {
            autoScheduled: true,
            workspaceSize: myTasksWorkspace?.members.length || 1,
            taskDuration: task.duration,
          })

          await createTask(taskToCreate)
        })
      )
      goToNextPage()
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='bg-semantic-neutral-bg-subtle h-full w-full'>
      <div className='flex h-full'>
        {/* Left Column */}
        <div className='m-8 flex flex-col 2xl:justify-between lg:min-w-[500px] flex-[1.5_1.5_0%]'>
          <div className='mx-auto max-w-[550px]'>
            <div className='lg:my-4'>
              <h1 className='text-lg lg:text-[28px] font-semibold lg:leading-[42px]'>
                What are you working on this week?
              </h1>
              <div className='mt-2 mb-6'>
                <strong>
                  Add at least 5 tasks to get the most out of Motion.
                </strong>
                <p>
                  The more tasks you add the better our AI can help you
                  prioritize!
                </p>
              </div>
              <form
                className='flex flex-row items-center gap-2'
                onSubmit={(e) => {
                  e.preventDefault()
                  addTask(stagedTask)
                  setStagedTask({ ...defaultTask })
                  taskRowRef.current?.focusInput()
                }}
              >
                <div className='grow group'>
                  <CreateTasksBulkRow
                    task={stagedTask}
                    ref={taskRowRef}
                    updateTask={(task: taskCreateObject) => setStagedTask(task)}
                    isStagedTask
                  />
                </div>
                <div className='w-[78px]'>
                  <Button
                    disabled={stagedTask.title === ''}
                    shortcut='enter'
                    type='submit'
                    fullWidth
                  >
                    Add
                  </Button>
                </div>
              </form>
              <div className='mt-4 mb-3 w-full'>
                {tasks.length > 0 && (
                  <div>
                    {tasks.map((task, index) => {
                      return (
                        <div
                          key={index}
                          className='flex flex-row items-center gap-2 w-full justify-between group'
                          onMouseEnter={() => {
                            setActiveRow(index)
                          }}
                          onMouseLeave={() => {
                            setActiveRow(null)
                          }}
                        >
                          <div className='grow'>
                            <CreateTasksBulkRow
                              {...{
                                task,
                                updateTask: (task: taskCreateObject) =>
                                  setTasks([
                                    ...tasks.slice(0, index),
                                    task,
                                    ...tasks.slice(index + 1),
                                  ]),
                              }}
                            />
                          </div>

                          <div className='w-[78px]'>
                            {index === activeRow && (
                              <IconButton
                                icon={XSolid}
                                sentiment='neutral'
                                size='small'
                                variant='muted'
                                onClick={() => deleteTask(index)}
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <div className='flex flex-row items-center flex-wrap gap-1'>
                {defaultSuggestedTasks.map((task) => {
                  return (
                    <LabelTag
                      key={task}
                      muted
                      onClick={() => {
                        setStagedTask({ ...defaultTask, title: task })
                        taskRowRef.current?.focusInput()
                      }}
                      text={task}
                      icon={
                        <PlusCircleSolid className='text-semantic-neutral-icon-default w-3 h-3' />
                      }
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div className='w-full flex flex-row items-center justify-end'>
            <Tooltip
              content={makeTooltipString(minimumTasksToContinue, tasks.length)}
            >
              <Button
                loading={loading}
                onClick={onSubmit}
                disabled={tasks.length < minimumTasksToContinue}
              >
                Next
              </Button>
            </Tooltip>
          </div>
        </div>
        {/* Right Column */}
        <div className='h-full min-w-[600px] flex-1 flex-col justify-center gap-6 bg-semantic-neutral-bg-default pl-[66px] hidden lg:flex'>
          <img
            src='https://storage.googleapis.com/external-files.usemotion.com/onboarding-add-task-animation.gif'
            alt='graphic'
            className='object-contain'
          />
          <h2 className='text-semantic-neutral-text-subtle'>
            Motion&apos;s AI will automatically schedule these tasks on your
            calendar!
          </h2>
        </div>
      </div>
    </div>
  )
}
