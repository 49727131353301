import { MotionCache } from '@motion/rpc-cache'
import { type UserSettingsSchema } from '@motion/rpc-types'
import {
  useMyUserSettings,
  useMyUserSettingsId,
  userSettingsQueryFilter,
} from '@motion/web-common/settings'

import { type QueryClient, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

/**
 * Task Default Settings are deleted on the backend on workspace delete.
 * This hook is used to remove them from the cache to avoid stale data.
 *
 * Other cleanup can go here as well.
 */
export const useCleanupCacheOnWorkspaceDelete = () => {
  const queryClient = useQueryClient()
  const userSettingsId = useMyUserSettingsId()
  const { data: userSettings } = useMyUserSettings()

  return useCallback(
    (workspaceId: string) => {
      checkAndRemoveTaskDefaultSettingsFromCache({
        workspaceId,
        queryClient,
        userSettingsId,
        userSettings,
      })
    },
    [queryClient, userSettingsId, userSettings]
  )
}

const checkAndRemoveTaskDefaultSettingsFromCache = ({
  workspaceId,
  queryClient,
  userSettingsId,
  userSettings,
}: {
  workspaceId: string
  queryClient: QueryClient
  userSettingsId: string | undefined
  userSettings: UserSettingsSchema | undefined
}) => {
  if (
    userSettingsId != null &&
    userSettings?.taskDefaultSettings.global?.workspaceId === workspaceId
  ) {
    // Remove user task default settings if match
    MotionCache.upsert(queryClient, userSettingsQueryFilter, {
      models: {
        userSettings: {
          [userSettingsId]: {
            ...userSettings,
            taskDefaultSettings: {
              ...userSettings.taskDefaultSettings,
              global: null,
            },
          },
        },
      },
    })
  }
}
