import { LocationMarkerSolid } from '@motion/icons'
import { TextField } from '@motion/ui/forms'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useEventFormOptions } from '../event-form-options-context'
import { useEventFormLocationField } from '../hooks'

export const EventFormLocationField = () => {
  const { isReadOnly } = useEventFormOptions()
  const { field, onChange } = useEventFormLocationField()

  if (isReadOnly && !field.value) return null
  return (
    <EventFormRowContainer className='px-2 mb-2'>
      <EventFormRowIconContainer>
        <LocationMarkerSolid />
      </EventFormRowIconContainer>
      <div className='w-full text-xs'>
        <TextField
          disabled={isReadOnly}
          size='small'
          variant='muted'
          placeholder='Add a location'
          value={field.value}
          onChange={onChange}
        />
      </div>
    </EventFormRowContainer>
  )
}
