import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCreateEvent, useUpdateEvent } from '~/areas/event/hooks'
import {
  type FieldValues,
  type FormState,
  useFormContext,
} from 'react-hook-form'

import { type EventFormFields } from './use-event-form-initial-data'

import { updateCalendar } from '../../../../../../state/calendar-list/calendar-list-thunks'
import { useAppDispatch } from '../../../../../../state/hooks'
import { useClosePeekModal } from '../../hooks'
import { useEventFormOptions } from '../event-form-options-context'
import { formFieldsToCalendarV3 } from '../utils/form-fields-to-calendar-v3'

type DirtyFields = FormState<EventFormFields>['dirtyFields']
const extractChangedValuesFromFormData = <T extends FieldValues>(
  allFields: T,
  dirtyFields: DirtyFields
): Partial<T> => {
  const changedFieldValues = Object.keys(dirtyFields).reduce(
    (acc, currentField) => {
      return {
        ...acc,
        [currentField]: allFields[currentField],
      }
    },
    {} as Partial<T>
  )

  return changedFieldValues
}

export const useEventFormSubmit = () => {
  const createCalendarEvent = useCreateEvent()
  const updateCalendarEvent = useUpdateEvent()
  const closePeekModal = useClosePeekModal()
  const { isReadOnly, loading, setLoading, hostCalendar } =
    useEventFormOptions()

  const {
    handleSubmit,
    formState: { dirtyFields },
  } = useFormContext<EventFormFields>()

  const dispatch = useAppDispatch()
  const onSubmit = async (data: EventFormFields) => {
    if (isReadOnly || loading) return
    const hasTravelTime =
      (data?.travelTimeBefore || data?.travelTimeAfter) != null
    if (hasTravelTime && !!data.recurrence) {
      showToast(
        'error',
        'Unable to Save. Travel Time and Recurring events are not compatible.'
      )
      return
    }
    setLoading(true)

    if (data.id) {
      const dirtyValues = extractChangedValuesFromFormData(data, {
        ...dirtyFields,
        // always include start and end fields, the DTO expects them when changing recurring tasks
        start: true,
        end: true,
      })
      updateCalendarEvent(data.id, dirtyValues)
      recordAnalyticsEvent('CALENDAR_PEEK_MODAL_UPDATE_EVENT', {
        isRecurring: Boolean(data.recurrence),
      })
    } else {
      const createCalendarEventData = formFieldsToCalendarV3({
        ...data,
        colorId: data.colorId ?? undefined,
      })
      createCalendarEvent(createCalendarEventData)
      recordAnalyticsEvent('CALENDAR_PEEK_MODAL_CREATE_EVENT', {
        isRecurring: Boolean(data.recurrence),
      })
    }

    // Make the calendar visible if it isn't so that users can see the event they created
    if (!hostCalendar.isEnabled) {
      void dispatch(
        updateCalendar({
          id: hostCalendar.id,
          emailAccountId: hostCalendar.emailAccountId,
          isEnabled: true,
        })
      )
    }

    setLoading(false)
    closePeekModal()
  }

  return { handleSubmit: handleSubmit(onSubmit) }
}

export const useManualEventFormSubmit = () => {
  const updateCalendarEvent = useUpdateEvent()

  const closePeekModal = useClosePeekModal()

  const handleSubmit = (data: Pick<EventFormFields, 'id' | 'attendees'>) => {
    if (!data.id) {
      showToast('error', 'Unable to save. Event not found.')
      return
    }
    const { id, attendees } = data
    updateCalendarEvent(id, {
      attendees,
      sendUpdates: true,
    })
    closePeekModal()
  }
  return { handleSubmit }
}
