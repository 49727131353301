import { type NormalTaskSchema } from '@motion/zod/client'

import { useMemo, useState } from 'react'

import { type SortBy } from '../utils'

export function useSidebarTaskBlockers(
  flattenedGroupedTasks: NormalTaskSchema[],
  sort: keyof typeof SortBy
) {
  const [hoveredBlockedIndex, setHoveredBlockedIndex] = useState<number | null>(
    null
  )

  const hoveredBlockedTask = useMemo(() => {
    if (sort !== 'BLOCKERS') {
      return null
    }
    return getHoveredBlockedTask(hoveredBlockedIndex, flattenedGroupedTasks)
  }, [sort, hoveredBlockedIndex, flattenedGroupedTasks])

  const firstBlockingTaskIndex = useMemo(() => {
    if (sort !== 'BLOCKERS') {
      return -1
    }
    return getFirstBlockingTaskIndex(flattenedGroupedTasks, hoveredBlockedTask)
  }, [flattenedGroupedTasks, hoveredBlockedTask, sort])

  return {
    hoveredBlockedIndex,
    setHoveredBlockedIndex,
    hoveredBlockedTask,
    firstBlockingTaskIndex,
  }
}

function getFirstBlockingTaskIndex(
  flattenedGroupedTasks: NormalTaskSchema[],
  hoveredBlockedTask: NormalTaskSchema | null
) {
  return flattenedGroupedTasks.findIndex(
    (item) =>
      hoveredBlockedTask != null &&
      item.blockingTaskIds.includes(hoveredBlockedTask.id)
  )
}

function getHoveredBlockedTask(
  hoveredBlockedIndex: number | null,
  flattenedGroupedTasks: NormalTaskSchema[]
) {
  if (
    hoveredBlockedIndex == null ||
    flattenedGroupedTasks.length === 0 ||
    flattenedGroupedTasks[hoveredBlockedIndex] == null
  ) {
    return null
  }

  return flattenedGroupedTasks[hoveredBlockedIndex]
}
