import { groupBy } from '@motion/utils/array'
import { useHasTreatment } from '@motion/web-common/flags'

import { useLookup } from '~/global/cache'
import { createTaskProxy, type TaskWithRelations } from '~/global/proxies'
import { usePastDeadlineTasks } from '~/global/rpc/v2'
import { useMemo } from 'react'

export type PastDueTasksBySchedule = {
  hardDeadlineMissed: TaskWithRelations[]
  softDeadlineMissed: TaskWithRelations[]
  // Group recurring tasks by the parent taskID
  recurringTasksMissed: Record<string, TaskWithRelations[]>
  // For data in the modal
  recurringHardDeadlineMissed: TaskWithRelations[]
  recurringSoftDeadlineMissed: TaskWithRelations[]
}

export type PastDueByScheduleOptions = {
  shouldSeparateRecurring?: boolean
}

// Returns the past due tasks grouped by schedule type
// In the control areas (sidebar, calendar), we only show the hard and soft deadline missed tasks
// But in the modal, we show separate instances of recurring tasks into its own section
export const usePastDueTasksBySchedule = (
  args: PastDueByScheduleOptions = {}
): PastDueTasksBySchedule => {
  const { shouldSeparateRecurring = false } = args

  const { data: pastDeadlineTasks } = usePastDeadlineTasks()
  const lookup = useLookup()
  const hasDedupedRecurring = useHasTreatment('past-due-dedupe-recurring')

  const shouldFilterRecurring = shouldSeparateRecurring && hasDedupedRecurring

  return useMemo(() => {
    if (!pastDeadlineTasks) {
      return {
        hardDeadlineMissed: [],
        softDeadlineMissed: [],
        recurringHardDeadlineMissed: [],
        recurringSoftDeadlineMissed: [],
        recurringTasksMissed: {},
      }
    }

    const proxiedPastDeadlineTasks = pastDeadlineTasks.map((task) =>
      createTaskProxy(task, lookup)
    )

    const showWarning = (task: TaskWithRelations) =>
      !('ignoreWarnOnPastDue' in task) || !task.ignoreWarnOnPastDue

    const parsedTasks = proxiedPastDeadlineTasks.filter(
      (task) => showWarning(task) && task.priorityLevel !== 'ASAP'
    )

    const recurringTasksMissed = parsedTasks.filter(
      (task) => task.type === 'RECURRING_INSTANCE'
    )

    const recurringHardDeadlineMissed = recurringTasksMissed.filter(
      (task) => task.deadlineType === 'HARD'
    )

    const recurringSoftDeadlineMissed = recurringTasksMissed.filter(
      (task) => task.deadlineType === 'SOFT'
    )

    const hardDeadlineMissed = parsedTasks.filter(
      (task) =>
        task.deadlineType === 'HARD' &&
        (!shouldFilterRecurring || task.type !== 'RECURRING_INSTANCE')
    )

    const softDeadlineMissed = parsedTasks.filter(
      (task) =>
        task.deadlineType === 'SOFT' &&
        (!shouldFilterRecurring || task.type !== 'RECURRING_INSTANCE')
    )

    // Dedupe the recurring tasks by the parent taskID
    const recurringTasksMissedByParent = groupBy(
      recurringTasksMissed,
      (task) => task.parentRecurringTaskId
    )

    return {
      hardDeadlineMissed,
      softDeadlineMissed,
      recurringHardDeadlineMissed,
      recurringSoftDeadlineMissed,
      recurringTasksMissed: recurringTasksMissedByParent,
    }
  }, [pastDeadlineTasks, lookup, shouldFilterRecurring])
}
