import { XSolid } from '@motion/icons'
import { type Calendar } from '@motion/rpc/types'
import { Button, ButtonGroup, IconButton, Modal } from '@motion/ui/base'
import { isEmailValid } from '@motion/utils/string'

import { useSelectTeammates } from '~/areas/calendar/hooks'
import { useCalendarList } from '~/components/Calendar/components/Modals/calendar-list-picker-modal/hooks/use-calendar-list'
import { useEffect, useRef, useState } from 'react'

import { AvailabilityGuestConfirmationModal } from './availability-guest-confirmation-modal'

import { useStateObject } from '../../../../../hooks/use-state-object'
import { type CalendarPanelMenuSectionType } from '../../../../../state/calendar/calendarSlice'
import { setCalendarListPickerModal } from '../../../../../state/calendar-list/calendar-list-slice'
import { useAppDispatch } from '../../../../../state/hooks'
import { useAddContactCalendar } from '../../../../Calendar/components/Modals/calendar-list-picker-modal/hooks/use-add-contact-calendar'
import { FlatCalendarListPicker } from '../../../../calendar-list-pickers/flat-calendar-list-picker/flat-calendar-list-picker'
import { GroupedCalendarListPicker } from '../../../../calendar-list-pickers/grouped-calendar-list-picker/grouped-calendar-list-picker'
import { ListPickerHeader } from '../../../../CalendarListPickers/ListPickerHeader/ListPickerHeader'
import { ContactsAutocomplete } from '../../../../contacts-autocomplete'

const isGroupCalendar = (id: string) => {
  return id.includes('calendar.google.com')
}

export type AvailabilityCalendarListPickerModalProps = {
  onClose: () => void
  onSelectedCalendars: (
    calendars: Calendar[],
    guestProviderIds: string[]
  ) => void
  selectedCalendars: Calendar[]
  selectedGuests: string[]
}

/**
 * Modal for selecting conflict calendars for a booking template. There are
 * two types of calendars this will return after selection:
 * - Conflict calendars: calendars in which the events are used to block out
 *   time slots
 * - Guest calendars: calendars in which the email address associated to the
 *   calendar will be added to the attendees list when a booking event is
 *   created
 * @param props
 * @returns
 */
export const AvailabilityCalendarListPickerModal = (
  props: AvailabilityCalendarListPickerModalProps
) => {
  const dispatch = useAppDispatch()

  const {
    calendars,
    frequentlyMetCalendars,
    myCalendars,
    emailAccounts,
    emailAccountsMap,
    onCreateNewCalendars,
  } = useCalendarList()
  const [selectedTeammates, handleSelectedTeammates] = useSelectTeammates()

  // Create a map of default checked items based on the provided calendars
  const defaultCheckedItems = props.selectedCalendars.reduce(
    (prev, cur) => {
      prev[cur.id] = true
      return prev
    },
    {} as Record<string, boolean>
  )

  // Checked/selected calendars
  const [checkedItems, setCheckedItems] =
    useStateObject<Record<string, boolean>>(defaultCheckedItems)

  useEffect(
    // temporary fix until this is moved off redux. we need to wait until calendars are created
    // before we can check them
    function checkNewContacts() {
      selectedTeammates.forEach((teammate) => {
        const calendar = calendars.find((c) => c.providerId === teammate)
        if (calendar) {
          setCheckedItems(calendar.id, true)
        }
      })
    },
    [selectedTeammates, setCheckedItems, calendars]
  )

  // Tracks saving state of the modal to prevent double saves
  const [isSaving, setIsSaving] = useState<boolean>(false)

  // Controls whether the My Calendars / Frequently Met sections are expanded or
  // collapsed
  const [expandedPanels, setExpandedPanels] = useStateObject<
    Record<CalendarPanelMenuSectionType, boolean>
  >({ main: true, mine: true, frequent: true, all: true })

  // Calculated when the user presses the "Save" button. This is cached because
  // we may need to show another modal before actually firing the
  // `onSelectedCalendars` callback
  const [selectedCalendars, setSelectedCalendars] = useState<Calendar[]>([])

  // Email addresses from selected calendars that can be added as guests
  // on the booking link event. These emails will be displayed in a
  // confirmation modal
  const [potentialNewGuests, setPotentialNewGuests] = useState<string[]>([])

  // Tracks the guests the user explicitly wants to add to the booking link as
  // attendees of each booking link
  const userConfirmedGuests = useRef<string[]>([])

  const calendarPanelMenuToggle = (
    section: CalendarPanelMenuSectionType,
    expanded: boolean
  ) => {
    setExpandedPanels(section, expanded)
  }

  const {
    isCheckingCalendarAccess,
    onContactSearched,
    permissionWarnings,
    resetState: resetAddContactCalendarState,
    selectedContacts,
  } = useAddContactCalendar({
    calendars,
    createNewCalendars: true,
    emailAccounts,
    onCreateNewCalendars,
    setCalendarChecked: (cal: Calendar, checked: boolean) => {
      setCheckedItems(cal.id, checked)
    },
  })

  const allCalendars = calendars

  const visibleHandler = (visible: boolean) => {
    dispatch(setCalendarListPickerModal({ visible }))
    if (!visible) {
      resetAddContactCalendarState()
      setCheckedItems(defaultCheckedItems)
      setIsSaving(false)
      userConfirmedGuests.current = []
      props.onClose()
    }
  }

  /**
   * Pass the selected calendars + guests to the parent
   * @param selectedCalendars
   * @param selectedGuestProviderIds
   * @returns
   */
  const save = (
    selectedCalendars: Calendar[],
    selectedGuestProviderIds: Set<string>
  ) => {
    if (isSaving) {
      return
    }

    props.onSelectedCalendars(
      selectedCalendars,
      Array.from(selectedGuestProviderIds.values())
    )
    visibleHandler(false)
  }

  /**
   * Callback when the user saves - this resolves the target calendars, and
   * for calendars that could be added as guests on the booking link, calculate
   * whether there are any "new" guests added & display the confirmation modal
   * if so.
   */
  const onSave = () => {
    const calendarsMap = new Map<string, Calendar>(
      allCalendars.map((c) => [c.id, c])
    )

    // Fetch all calendars that were selected
    const selected: Calendar[] = []

    // Build an array of email addresses for the non-primary/teammate calendars
    // that are currently selected but are NOT already guests on the template.
    // These will be displayed to the user who can decide whether to add these
    // emails as guests
    const potentialNewGuests: Calendar[] = []

    for (const [calendarId, checked] of Object.entries(checkedItems)) {
      if (!checked) {
        continue
      }

      const calendar = calendarsMap.get(calendarId)
      if (!calendar) {
        continue
      }

      if (
        !calendar.isPrimary &&
        isEmailValid(calendar.providerId) &&
        !isGroupCalendar(calendar.providerId) &&
        !props.selectedGuests.includes(calendar.providerId)
      ) {
        potentialNewGuests.push(calendar)
      }

      selected.push(calendar)
    }

    if (potentialNewGuests.length) {
      // Buffer calculated calendars & guests and show the confirmation modal
      setSelectedCalendars(selected)
      setPotentialNewGuests(potentialNewGuests.map((c) => c.providerId))
    } else {
      // Update the selected calendars & pass back the original selected guests
      save(selected, new Set(props.selectedGuests))
    }
  }

  /**
   * Callback after the guest confirmation modal is dismissed. The guests array
   * is either populated with the confirmed guests (which is concated with the
   * previously selected guests), or we save with the original selected guests
   * (if any)
   *
   * @param guests
   */
  const onSaveGuests = (guests: string[] | null) => {
    // This hides the confirmation modal
    setPotentialNewGuests([])

    // Pass in the guests that were confirmed IN addition to the previously
    // selected guests
    save(
      selectedCalendars,
      new Set(
        guests && guests.length
          ? [...props.selectedGuests, ...guests]
          : props.selectedGuests
      )
    )
  }

  const resolveCheckedState = (calendar: Calendar) => {
    return !!checkedItems[calendar.id]
  }

  // Buffer checked status of calendar in react state
  const onCalendarChecked = (calendar: Calendar, checked: boolean) => {
    setCheckedItems(calendar.id, checked)

    // this is needed to uncheck teammates found from the contacts autocomplete
    // remove once selected contacts are off redux
    if (!checked && selectedTeammates.includes(calendar.providerId)) {
      handleSelectedTeammates(
        [],
        selectedTeammates
          .map((teammate) => {
            const calendar = calendars.find((c) => c.providerId === teammate)

            if (!calendar || selectedTeammates.includes(calendar.providerId))
              return undefined
            return {
              ...calendar,
              email: calendar?.providerId ?? '',
              displayName: calendar?.title ?? '',
              teamDomain: false,
            }
          })
          .filter(Boolean)
      )
    }
  }

  const toggleAllSectionCalendars = (section: 'mine' | 'frequent') => {
    if (section === 'mine') {
      if (!myCalendars.length) return
      for (const calendar of myCalendars) {
        setCheckedItems(calendar.id, !allMyCalendarsChecked)
      }
    } else {
      if (!frequentlyMetCalendars.length) return
      for (const calendar of frequentlyMetCalendars) {
        setCheckedItems(calendar.id, !allFrequentCalendarsChecked)
      }
    }
  }

  const onClose = () => {
    visibleHandler(false)
  }

  const allFrequentCalendarsChecked = !frequentlyMetCalendars.find(
    (c) => !resolveCheckedState(c)
  )
  const allMyCalendarsChecked = !myCalendars.find(
    (c) => !resolveCheckedState(c)
  )

  return (
    <>
      <Modal onClose={onClose} visible>
        <div className='w-auto min-h-0 flex flex-col'>
          <div className='dark:text-dark-100 text-light-1200 flex justify-between border-b px-4 py-3'>
            <p className='text-base font-semibold text-inherit dark:text-inherit'>
              Set calendars
            </p>
            <IconButton
              icon={XSolid}
              onClick={onClose}
              sentiment='neutral'
              size='small'
              variant='muted'
            />
          </div>

          <div className='flex w-full flex-col min-h-0 overflow-y-scroll py-3'>
            <div className='px-4 h-full'>
              <ContactsAutocomplete
                teamValues={selectedContacts}
                contactsSource='team'
                teamSelectHandler={onContactSearched}
                placeholder='Search calendars'
                className='mb-2 [&_input]:w-full'
              />
              {permissionWarnings.length > 0 && (
                <p className='mb-2 !text-semantic-error-text-default text-sm'>
                  {permissionWarnings.join('\n')}
                </p>
              )}

              <div className='flex w-full flex-col'>
                <ListPickerHeader
                  title='My calendars'
                  setExpanded={(expanded) =>
                    calendarPanelMenuToggle('mine', expanded)
                  }
                  checked={allMyCalendarsChecked}
                  isExpanded={expandedPanels.mine}
                  showCheckbox
                  setChecked={() => toggleAllSectionCalendars('mine')}
                />
                {expandedPanels.mine && myCalendars.length > 0 && (
                  <div className='flex w-full flex-col pr-4 pl-8'>
                    <FlatCalendarListPicker
                      checkboxMode='normal'
                      emailAccountsMap={emailAccountsMap}
                      items={myCalendars}
                      onCalendarChecked={onCalendarChecked}
                      onCalendarUpdated={() => null}
                      resolveCheckedState={resolveCheckedState}
                    />
                  </div>
                )}
                <ListPickerHeader
                  title='Frequently met with'
                  setExpanded={(expanded) =>
                    calendarPanelMenuToggle('frequent', expanded)
                  }
                  isExpanded={expandedPanels.frequent}
                  checked={allFrequentCalendarsChecked}
                  showCheckbox
                  setChecked={() => toggleAllSectionCalendars('frequent')}
                />
                {expandedPanels.frequent && !!frequentlyMetCalendars.length && (
                  <div className='flex w-full flex-col pr-4 pl-8'>
                    <FlatCalendarListPicker
                      checkboxMode='normal'
                      emailAccountsMap={emailAccountsMap}
                      items={frequentlyMetCalendars}
                      onCalendarChecked={onCalendarChecked}
                      onCalendarUpdated={() => null}
                      resolveCheckedState={resolveCheckedState}
                    />
                  </div>
                )}
                <ListPickerHeader
                  title='All calendar accounts'
                  setExpanded={(expanded) =>
                    calendarPanelMenuToggle('all', expanded)
                  }
                  isExpanded={expandedPanels.all}
                />
                {expandedPanels.all && (
                  <div className='flex w-full flex-col gap-1 px-4 py-2'>
                    <GroupedCalendarListPicker
                      calendars={allCalendars}
                      checkboxMode='normal'
                      emailAccounts={emailAccounts}
                      onCalendarChecked={onCalendarChecked}
                      onCalendarUpdated={() => null}
                      onRemoveEmailAccount={() => null}
                      onStartEmailAuth={() => null}
                      resolveCheckedState={resolveCheckedState}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='flex justify-end py-3 px-4 border-t'>
            <ButtonGroup>
              <Button
                sentiment='primary'
                onClick={onSave}
                disabled={
                  isSaving ||
                  !!permissionWarnings.length ||
                  isCheckingCalendarAccess
                }
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Modal>

      {potentialNewGuests.length && (
        <AvailabilityGuestConfirmationModal
          onConfirm={onSaveGuests}
          potentialGuests={potentialNewGuests}
        />
      )}
    </>
  )
}
