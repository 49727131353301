import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { useRegisterFieldArray } from '~/hooks'
import { useMemo } from 'react'
import { type FieldPath, type RegisterOptions } from 'react-hook-form'

import { useFlowTemplateForm } from '../../../../hooks'

export const useStageTaskField = <T extends FieldPath<FlowTemplateFormFields>>(
  path: T,
  options?: RegisterOptions<FlowTemplateFormFields>
) => {
  const {
    form: { register, watch },
  } = useFlowTemplateForm()

  const { onChange } = useRegisterFieldArray(path, register, options)
  const value = watch(path)

  return useMemo(() => [value, onChange] as const, [value, onChange])
}
