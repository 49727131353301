import { classed } from '@motion/theme'

import { type PastDueRow } from '~/@types/analytics'
import { type TaskWithRelations } from '~/global/proxies'
import React, { useMemo } from 'react'

import {
  ChangeDeadlineButton,
  CompleteButton,
  DeleteTaskButton,
  DoEarlierButton,
} from '../buttons'
import { IgnoreWarningButton } from '../buttons/ignore-warning-button'
import { type CommonButtonProps } from '../buttons/type'

type ActionProps = {
  tasks: TaskWithRelations[]
  showAllText?: boolean
  type: PastDueRow
}

type ActionTypes =
  | 'do-earlier'
  | 'change-deadline'
  | 'ignore-warning'
  | 'complete'
  | 'delete'

const SINGLE_TASK_ACTIONS: ActionTypes[] = [
  'do-earlier',
  'change-deadline',
  'ignore-warning',
  'complete',
  'delete',
]

const RECURRING_INSTANCE_ACTIONS: ActionTypes[] = [
  'change-deadline',
  'ignore-warning',
  'delete',
  'complete',
]

export const ActionsContainer = ({
  tasks = [],
  showAllText = false,
  type = 'NORMAL',
}: ActionProps) => {
  const renderAction = (actionType: ActionTypes) => {
    const commonProps: CommonButtonProps = {
      tasks,
      showAllText,
      type,
    }

    switch (actionType) {
      case 'do-earlier':
        return <DoEarlierButton {...commonProps} />
      case 'change-deadline':
        return <ChangeDeadlineButton {...commonProps} />
      case 'ignore-warning':
        return <IgnoreWarningButton {...commonProps} />
      case 'complete':
        return <CompleteButton {...commonProps} />
      case 'delete':
        return <DeleteTaskButton {...commonProps} />
      default:
        return null
    }
  }

  const actions = useMemo(() => {
    // Given the taskIds, find the common actions that can be performed on all tasks
    if (tasks.some((task) => task.type === 'RECURRING_INSTANCE')) {
      return RECURRING_INSTANCE_ACTIONS
    }

    return SINGLE_TASK_ACTIONS
  }, [tasks])

  return (
    <Actions onClick={(e) => e.stopPropagation()}>
      {actions.map((action) => (
        <div key={action} className='flex gap-1'>
          {
            /** For now just render first ID  */
            renderAction(action)
          }
        </div>
      ))}
    </Actions>
  )
}

export const Actions = classed('div', {
  base: `
    flex justify-end items-center gap-1
    [&>button]:shrink-0
  `,
})
