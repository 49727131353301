import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { IconButton, Modal } from '@motion/ui/base'

import { Section } from './components'

import { SecondaryButton, SubParagraph } from '../../../../components/Common'
import { useAppDispatch } from '../../../../state/hooks'
import { setScheduledAfterDeadlineVisible } from '../../../../state/modalsSlice'
import { type PastDueTasksBySchedule } from '../../hooks/use-past-due-tasks-by-schedule'

interface ScheduledAfterDeadlineModalProps {
  pastDeadlineTasks: PastDueTasksBySchedule
}

export const ScheduledAfterDeadlineModal = ({
  pastDeadlineTasks,
}: ScheduledAfterDeadlineModalProps) => {
  const dispatch = useAppDispatch()

  const closeModal = () => {
    dispatch(setScheduledAfterDeadlineVisible(false))
  }

  const { hardDeadlineMissed, softDeadlineMissed } = pastDeadlineTasks

  return (
    <>
      <Modal visible onClose={closeModal}>
        <Container>
          <Header>
            <Title>Tasks scheduled after deadline</Title>
            <IconButton
              onClick={closeModal}
              icon={XSolid}
              variant='muted'
              sentiment='neutral'
            />
          </Header>
          <Body>
            {hardDeadlineMissed.length === 0 &&
              softDeadlineMissed.length === 0 && (
                <div className='flex h-full w-full flex-col items-center justify-center gap-3'>
                  <SubParagraph className='text-sm'>
                    Nice! No more tasks are scheduled after deadline.
                  </SubParagraph>
                  <SecondaryButton size='small' onClick={closeModal}>
                    Close
                  </SecondaryButton>
                </div>
              )}
            {(hardDeadlineMissed.length > 0 ||
              softDeadlineMissed.length > 0) && (
              <>
                <Section deadlineType='hard' tasks={hardDeadlineMissed} />
                <Section deadlineType='soft' tasks={softDeadlineMissed} />
              </>
            )}
          </Body>
        </Container>
      </Modal>
    </>
  )
}

const Container = classed('div', {
  base: `
    grid grid-rows-[auto_1fr]
    w-[800px] h-100 overflow-hidden
  `,
})

const Header = classed('div', {
  base: `
    grid grid-cols-[1fr_auto]
    bg-modal-bg
    pl-4 pr-3 py-3
    items-center
  `,
})

const Title = classed('h2', {
  base: `text-semantic-neutral-text-default text-base font-semibold`,
})

const Body = classed('div', {
  base: `
    flex flex-col gap-5 p-4 overflow-y-auto max-h-[80vh]`,
})
