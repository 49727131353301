import { LexoRank } from 'lexorank'

import {
  type FlattenedSidebarWorkspacesTreeItem,
  type FlattenedSidebarWorkspacesTreeItemType,
} from './types'

import { type SidebarWorkspacesTreeItem } from '../../types'

export function flattenTree(
  items: SidebarWorkspacesTreeItem[]
): FlattenedSidebarWorkspacesTreeItem[] {
  const flattenItem =
    (
      level: FlattenedSidebarWorkspacesTreeItem['level'],
      parent?: SidebarWorkspacesTreeItem
    ) =>
    (
      treeItem: SidebarWorkspacesTreeItem,
      index: number,
      tree: SidebarWorkspacesTreeItem[]
    ): FlattenedSidebarWorkspacesTreeItem[] => {
      const { children, ...item } = treeItem
      const { id, workspaceId, parentId } = item

      const type = getItemContainerType(item.type)
      const parentType = parent ? getItemContainerType(parent.type) : null

      let flattenedItems: FlattenedSidebarWorkspacesTreeItem[] = [
        {
          id,
          canMoveDown: index < tree.length - 1,
          canMoveUp: index !== 0,
          item,
          level,
          parentId: type === 'WORKSPACE' ? parentId : (parent?.id ?? null),
          parentType,
          type,
        },
      ]

      if (treeItem.isContainer && treeItem.expanded) {
        if (children.length) {
          flattenedItems.push(
            ...children
              .flatMap(flattenItem(level + 1, treeItem))
              .map((ci) => ({ ...ci, workspaceId }))
          )
        } else {
          const placeholderId = `${id}:placeholder`

          flattenedItems.push({
            id: placeholderId,
            canMoveDown: false,
            canMoveUp: false,
            item: {
              // We only need a few properties from the item for a placeholder
              ...item,
              id: placeholderId,
              itemId: id,
              order: LexoRank.middle().toString(),
              isContainer: false,
              expanded: false,
            },
            level: level + 1,
            parentId: id,
            parentType: type,
            type: 'PLACEHOLDER',
          })
        }
      }

      return flattenedItems
    }

  return items.flatMap(flattenItem(0))
}

function getItemContainerType(
  type: SidebarWorkspacesTreeItem['type']
): FlattenedSidebarWorkspacesTreeItemType {
  switch (type) {
    case 'INDIVIDUAL_WORKSPACE':
    case 'TEAM_WORKSPACE':
      return 'WORKSPACE'
    case 'FOLDER':
      return 'FOLDER'
    case 'PROJECT':
      return 'PROJECT'
  }
}
