import { Tab, Tabs } from '@motion/ui/base'

import { useProject } from '~/global/hooks'
import { type ReactNode, useState } from 'react'

import { PanelHeader } from './panel-header'
import { ProjectPanelContainer } from './project-panel-container'

export type ProjectPanelTab = {
  title: string
  value: string
  content: ReactNode
}

type TabValue<T extends ProjectPanelTab[]> = T[number]['value']

export type BaseProjectPanelProps<
  T extends ProjectPanelTab[] = ProjectPanelTab[],
> = {
  projectId: string
  variant?: 'default' | 'side-panel'
  header?: ReactNode
  tabs?: T
  /**
   * If tabs aren't provided, children will be rendered instead
   */
  children?: ReactNode
  onTabChange?: (tab: TabValue<T>) => void
}

export function BaseProjectPanel<T extends ProjectPanelTab[]>({
  header,
  variant,
  projectId,
  tabs,
  children,
  onTabChange,
}: BaseProjectPanelProps<T>) {
  const [selectedTab, setSelectedTab] = useState(() =>
    Array.isArray(tabs) ? tabs[0].value : ''
  )

  const onTabChangeHandler = (tab: string) => {
    setSelectedTab(tab)

    onTabChange?.(tab)
  }

  const project = useProject(projectId)

  if (project == null) return null

  return (
    <ProjectPanelContainer variant={variant}>
      {tabs ? (
        <>
          <PanelHeader name={project.name}>{header}</PanelHeader>
          <Tabs
            value={selectedTab}
            onChange={onTabChangeHandler}
            outerPadding='small'
            gap='small'
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                title={tab.title}
                value={tab.value}
                size='xsmall'
              >
                {tab.content}
              </Tab>
            ))}
          </Tabs>
        </>
      ) : (
        children
      )}
    </ProjectPanelContainer>
  )
}
