import { CalendarSolid, CalendarXSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { getDeadlineText } from '@motion/ui-logic/pm/task'

import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineData } from '~/areas/tasks/hooks'

import { RowButton } from './common'
import { type WithTaskProps } from './types'
import { isTaskPastDue } from './utils'

import { useTaskContextApi } from '../../../context/task-context'

export const DeadlineButton = (props: WithTaskProps) => {
  const { task } = props
  const api = useTaskContextApi()
  const isPastDue = isTaskPastDue(api.getTaskData(task))
  const tooltipContent = getDeadlineText(task)

  const {
    onChange: onChangeDeadline,
    isValidDateOption,
    disabled,
    hasValidData,
  } = useTaskDeadlineData(props.task)

  if (!hasValidData) return null

  return (
    <Tooltip content={`Deadline: ${tooltipContent}`}>
      <TaskDeadlineDropdown
        contextProps={{ taskId: task.id }}
        isValidDateOption={isValidDateOption}
        value={task.dueDate}
        onChange={onChangeDeadline}
      >
        <RowButton disabled={disabled}>
          <DueDateIcon pastDue={isPastDue} />
        </RowButton>
      </TaskDeadlineDropdown>
    </Tooltip>
  )
}

type DueDateIconProps = {
  pastDue: boolean
}
const DueDateIcon = (props: DueDateIconProps) => {
  if (props.pastDue) {
    return (
      <CalendarXSolid
        width={10}
        height={10}
        className='text-semantic-error-icon-default hover:text-semantic-error-icon-hover'
      />
    )
  }
  return (
    <CalendarSolid
      width={10}
      height={10}
      className='text-semantic-neutral-icon-default hover:text-semantic-neutral-icon-hover'
    />
  )
}
