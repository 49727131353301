import { useSharedState } from '@motion/react-core/shared-state'
import { byProperty, groupInto, ordered } from '@motion/utils/array'
import { StackedBarChart } from '@motion/web-charts/bar'

import { PageTasksContextKey } from '~/areas/tasks/context'
import { useLookup } from '~/global/cache'
import {
  type NormalTaskWithRelations,
  type RecurringInstanceWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import { colorOfScheduledStatus, labelOfScheduledStatus } from './utils'

import { GridTile } from '../components/grid-tile'
import { type GridTileLayoutProps } from '../components/types'

type TasksByAssigneeAndStatusChartProps = GridTileLayoutProps & {}

export const TasksByAssigneeAndStatusChart = (
  props: TasksByAssigneeAndStatusChartProps
) => {
  const [tasks] = useSharedState(PageTasksContextKey)

  const lookup = useLookup()
  const data = useMemo(() => {
    if (tasks == null) return []

    return groupInto(tasks.filter(isNotChunk), (task) => {
      return task.scheduledStatus ?? 'UNKNOWN'
    })
      .map((group) => {
        return {
          id: group.key,
          key: labelOfScheduledStatus(group.key),
          color: colorOfScheduledStatus(group.key),
          values: groupInto(group.items, (t) => t.assigneeUserId ?? 'null').map(
            (g2) => {
              const name =
                g2.key === 'null' ? 'Unassigned' : lookup('users', g2.key).name
              return {
                key: name,
                value: g2.items.length,
              }
            }
          ),
        }
      })
      .sort(
        byProperty(
          'id',
          ordered([
            'ON_TRACK',
            'PAST_DUE',
            'UNFIT_SCHEDULABLE',
            'UNFIT_PAST_DUE',
          ])
        )
      )
  }, [lookup, tasks])

  if (tasks == null) return null
  return (
    <GridTile title='Tasks by assignee' {...props}>
      <StackedBarChart data={data} />
    </GridTile>
  )
}

function isNotChunk(
  task: TaskWithRelations
): task is NormalTaskWithRelations | RecurringInstanceWithRelations {
  return task.type !== 'CHUNK'
}
