import { Button, type ButtonProps, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useInNoExternalCalendarsMode } from '../../../../../hooks/use-in-no-external-calendars-mode'
import { useClosePeekModal, usePeekModalCommonContext } from '../hooks'

type EventOrTaskToggleProps = {
  onChange: () => void
}

export const EventOrTaskToggle = (props: EventOrTaskToggleProps) => {
  const { mode, setMode } = usePeekModalCommonContext()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const handleToggle = (value: typeof mode) => {
    setMode(value)
    props.onChange()
  }
  return (
    <div className='px-2 pb-2 flex gap-1'>
      {noExternalCalendarsMode && (
        <PopoverTrigger
          placement='top'
          renderPopover={({ close }) => (
            <LinkCalendarsPopover handleClose={() => close()} />
          )}
        >
          <div>
            <ToggleButton active={false} onClick={() => {}}>
              Event
            </ToggleButton>
          </div>
        </PopoverTrigger>
      )}
      {!noExternalCalendarsMode && (
        <ToggleButton
          active={mode === 'event'}
          onClick={() => handleToggle('event')}
        >
          Event
        </ToggleButton>
      )}
      <ToggleButton
        active={mode === 'task'}
        onClick={() => handleToggle('task')}
      >
        Task
      </ToggleButton>
    </div>
  )
}

type ToggleButtonProps = {
  active: boolean
  onClick: () => void
  children: string
}

const activeButtonProps: ButtonProps = {
  variant: 'outlined',
  sentiment: 'primary',
}

const ToggleButton = (props: ToggleButtonProps) => {
  const { onClick, active, children } = props
  return (
    <Button
      variant='muted'
      sentiment='neutral'
      {...(active ? activeButtonProps : {})}
      size='xsmall'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

const LinkCalendarsPopover = ({ handleClose }: { handleClose: () => void }) => {
  const modalApi = useModalApi()
  const closePeekModal = useClosePeekModal()

  return (
    <div className='p-2 flex flex-col gap-1'>
      <p className='text-semantic-neutral-text-default text-[12px] pb-1'>
        Link a calendar to add an event!
      </p>
      <Button
        variant='solid'
        sentiment='primary'
        size='small'
        onClick={() => {
          modalApi.open('link-calendars')
          recordAnalyticsEvent('LINK_CALENDARS_MODAL_OPEN', {
            source: 'peek-modal',
          })
          handleClose()
          closePeekModal()
        }}
      >
        Link a calendar
      </Button>
      <Button
        variant='muted'
        sentiment='neutral'
        size='small'
        onClick={() => handleClose()}
      >
        No thanks
      </Button>
    </div>
  )
}
