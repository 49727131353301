import { cloneDeep } from '@motion/utils/core'
import { clamp } from '@motion/utils/math'
import type { Nullable } from '@motion/utils/types'
import { Sentry } from '@motion/web-base/sentry'

import { useFindFolderItem } from '~/areas/folders/hooks'
import { LexoRank } from 'lexorank'
import { useCallback } from 'react'

import type {
  DragProjection,
  FlattenedSidebarWorkspacesTreeItem,
  ItemIdentifier,
} from '../types'

export function useGetDragProjection() {
  const findFolderItem = useFindFolderItem()

  return useCallback(
    (
      ogItems: FlattenedSidebarWorkspacesTreeItem[],
      activeId: ItemIdentifier,
      overId: ItemIdentifier,
      dragOffset: number,
      indentationWidth: number
    ): DragProjection => {
      const items = cloneDeep(ogItems)

      // Get the item that we're currently "over"
      const overItemIndex = items.findIndex(({ id }) => id === overId)
      const _overItem: FlattenedSidebarWorkspacesTreeItem | undefined =
        items[overItemIndex]

      // Get the item we're currently dragging
      const activeItemIndex = items.findIndex(({ id }) => id === activeId)
      const activeItem: FlattenedSidebarWorkspacesTreeItem | undefined =
        items[activeItemIndex]

      // If the item we're dragging is a workspace or folder, get the count of the
      // number of children it has and move the active item and it's children in the
      // array.
      const childCount = getChildCount()
      const splicedItems = items.splice(activeItemIndex, 1 + childCount)
      const insertIndex =
        overItemIndex > activeItemIndex
          ? overItemIndex - childCount
          : overItemIndex
      const newItems = items.toSpliced(insertIndex, 0, ...splicedItems)

      // Get the item that is previous and next to the active item
      const previousItem: FlattenedSidebarWorkspacesTreeItem | undefined =
        newItems[insertIndex - 1]
      const nextItem: FlattenedSidebarWorkspacesTreeItem | undefined =
        newItems[insertIndex + childCount + 1]

      // Based on the cursor position, get the "level" that the cursor is at, as well
      // as the minimum and maximum levels based on where we're currently "over".
      const dragLevel = getDragLevel(dragOffset, indentationWidth)
      const projectedLevel = activeItem.level + dragLevel
      const maxLevel = getMaxLevel(activeItem, previousItem)
      const minLevel = getMinLevel(activeItem, nextItem)
      const level = clamp(projectedLevel, minLevel, maxLevel)

      // Get the next and previous siblings of the "over" item. By "siblings" we mean
      // items in the same level/container.
      const previousSibling: Nullable<FlattenedSidebarWorkspacesTreeItem> =
        previousItem && level === previousItem.level ? previousItem : null
      const nextSibling: Nullable<FlattenedSidebarWorkspacesTreeItem> =
        nextItem && level === nextItem.level ? nextItem : null

      let newOrder!: LexoRank

      // Determine the new Lexorank for the active item based on its new position
      if (!previousSibling && !!nextSibling) {
        // Placed at the top of an expanded container
        newOrder = LexoRank.parse(nextSibling.item.order).genPrev()
      } else if (!!previousSibling && !!nextSibling) {
        // Placed in-between two existing items
        const prevOrder = LexoRank.parse(previousSibling.item.order)
        const nextOrder = LexoRank.parse(nextSibling.item.order)

        try {
          newOrder = prevOrder.between(nextOrder)
        } catch (e) {
          newOrder = nextOrder

          /*
           * If an item is dragged in between two items that have the same order,
           * an exception is thrown. We don't have a great way to handle this on
           * the client, and probably need to trigger a full re-rank on the server.
           */

          Sentry.captureException(
            new Error(
              'Could not generate new Lexorank while reordering folder tree items',
              { cause: e }
            ),
            {
              extra: {
                activeItem,
                nextItem,
                nextOrder,
                nextSibling,
                prevOrder,
                previousItem,
                previousSibling,
              },
              tags: {
                position: 'useGetDragProjection',
              },
            }
          )
        }
      } else if (!!previousSibling && !nextSibling) {
        // Placed at the end of an expanded container
        newOrder = LexoRank.parse(previousSibling.item.order).genNext()
      } else if (
        previousItem &&
        previousItem.item.isContainer &&
        !previousItem.item.expanded
      ) {
        // Placed inside a collapsed container, append to the bottom
        const searchResult = findFolderItem(({ id }) => id === previousItem.id)

        if (searchResult) {
          const [match] = searchResult

          if ('items' in match && match.items.length) {
            newOrder = LexoRank.parse(
              match.items[match.items.length - 1].order
            ).genNext()
          }
        } else {
          Sentry.captureException(
            new Error('Could not match collapsed folder'),
            {
              extra: {
                activeItem,
                nextItem,
                previousItem,
              },
              tags: {
                position: 'useGetDragProjection',
              },
            }
          )
        }
      } else {
        // Placed in an empty expanded container
        newOrder = LexoRank.middle()
      }

      newOrder = newOrder ?? LexoRank.middle()

      const order = newOrder.toString()
      const parentId = getParentId()
      const hasMovedParent = activeItem.parentId !== parentId
      const hasMoved = hasMovedParent || activeItemIndex !== overItemIndex

      activeItem.item.order = order
      activeItem.level = level
      activeItem.parentId = parentId
      activeItem.parentType =
        newItems.find(({ id }) => id === parentId)?.type ?? null

      return {
        hasMoved,
        hasMovedParent,
        level,
        maxLevel,
        minLevel,
        newItems,
        order,
        parentId,
      }

      function getParentId(): string | null {
        if (level === 0 || !previousItem) {
          return newItems[0]?.parentId ?? null
        }

        if (level === previousItem.level) {
          return previousItem.parentId
        }

        if (level > previousItem.level) {
          return previousItem.id
        }

        const newParent = newItems
          .slice(0, overItemIndex)
          .reverse()
          .find((item) => item.level === level)?.parentId

        return newParent ?? null
      }

      function getChildCount(): number {
        if (!activeItemIndex || !activeItem) return 0

        const slicedItems = items.slice(activeItemIndex)

        const match = slicedItems.findIndex((_item, i, arr): boolean => {
          const nextItem = arr[i + 1]

          if (!nextItem) return false

          return nextItem.level <= activeItem.level
        })

        return clamp(match, 0, slicedItems.length)
      }
    },
    [findFolderItem]
  )
}

function getDragLevel(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth)
}

function getMaxLevel(
  activeItem: FlattenedSidebarWorkspacesTreeItem,
  previousItem?: FlattenedSidebarWorkspacesTreeItem
) {
  if (!previousItem || activeItem.type === 'WORKSPACE') return 0

  if (activeItem.type === 'FOLDER') {
    return 1
  }

  return previousItem.level + Number(previousItem.item.isContainer)
}

function getMinLevel(
  activeItem: FlattenedSidebarWorkspacesTreeItem,
  nextItem?: FlattenedSidebarWorkspacesTreeItem
) {
  if (nextItem) {
    if (nextItem.type === 'WORKSPACE' && activeItem.type !== 'WORKSPACE') {
      return 1
    }

    return nextItem.level
  }

  return 0
}
