import { getConferenceDataForType } from '@motion/ui-logic'

import { Combobox } from '~/components/Common'
import { selectAllCalendars } from '~/state/calendar-list/calendar-list-slice'
import { useAppSelector } from '~/state/hooks'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const EventLocationRow = () => {
  const calendars = useAppSelector(selectAllCalendars)

  const {
    conference: { conferenceType, onConferenceTypeChange, conferenceOptions },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow
      title='Location'
      subtitle='Video conferencing link or custom location'
    >
      <div>
        <Combobox
          autoComplete
          className='max-w-[250px]'
          isLoadingOptions={!calendars.length}
          value={conferenceType}
          onChange={onConferenceTypeChange}
          options={conferenceOptions.map((type) => {
            const data = getConferenceDataForType(type)

            return {
              label: data.title,
              labelIcon: (
                <img
                  alt={data.title}
                  role='presentation'
                  src={data.link}
                  className='h-4 w-4'
                />
              ),
              value: type,
            }
          })}
        />
      </div>
    </SectionRow>
  )
}
