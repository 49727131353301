import { FieldTypes } from '@motion/shared/custom-fields'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'
import {
  type CustomFieldFilters,
  DEFAULT_CUSTOM_FIELD_FILTERS,
  DEFAULT_PROJECT_FILTERS,
  DEFAULT_TASK_FILTERS,
  DEFAULT_WORKSPACE_FILTERS,
  type EntityFilterState,
  formatInclusionFilterToBooleanFilter,
  type ProjectFilter,
  type TaskFilter,
  type WorkspaceFilterKey,
} from '@motion/ui-logic/pm/data'
import { cloneDeep, isEqual, merge, omit, pick } from '@motion/utils/core'
import { diff, entries, stripUndefined } from '@motion/utils/object'
import { type RequiredKeys } from '@motion/utils/types'
import { makeLog } from '@motion/web-base/logging'
import {
  type IdNullableFilterSchema,
  type Inclusion,
  type ViewDefinitionBaseV2,
  type ViewDefinitionTaskFilterV2,
  type ViewDefinitionV2,
  type WorkspaceVersionedViewV2,
  type WorkspaceViewDefinitionV2,
} from '@motion/zod/client'

import { type SortField } from '../fields'
import { type ViewState } from '../view-state'

const log = makeLog('views')

export const areViewsEqual = (
  left: ViewDefinitionV2,
  right: ViewDefinitionV2
) => {
  if (isEqual(left, right)) return true
  if (!isWorkspaceViewDefinition(left) || !isWorkspaceViewDefinition(right))
    return false

  const changes = diff(left, right)

  if (left.columns.length === 0) {
    const withoutColumnChanges = changes.filter(
      (x) => !x.path.startsWith('/columns')
    )
    if (withoutColumnChanges.length === 0) return true
  }

  log.debug('diff', changes)
  return false
}

function isWorkspaceViewDefinition(
  view: ViewDefinitionV2
): view is WorkspaceViewDefinitionV2 {
  return view.type !== 'team-schedule'
}

type ViewTaskFilter = WorkspaceViewDefinitionV2['filters']['tasks']['filters']
type ViewProjectFilter =
  WorkspaceViewDefinitionV2['filters']['projects']['filters']
type ViewWorkspaceFilter =
  WorkspaceViewDefinitionV2['filters']['workspaces']['filters']

type ExactFromViewTaskFilter = ExactToViewTaskFilter & {
  customFields: ViewTaskFilter['customFields']
}

type ExactFromViewProjectFilter = Omit<
  ExactToViewProjectFilter,
  'stageDefinitionIds'
> & {
  customFields: ViewProjectFilter['customFields']
  activeStageDefinitionIds: ViewProjectFilter['activeStageDefinitionIds']
}

export function toViewDefinition(
  type: 'workspace' | 'all-tasks' | 'my-tasks',
  filterState: EntityFilterState,
  viewState: ViewState
): WorkspaceViewDefinitionV2 {
  return {
    $version: 2,
    type,
    layout: viewState.page,
    itemType: filterState.target,
    cardFields: viewState.cardFields,
    providerIds: viewState.providerIds,
    showOOOEventsByAssignee: viewState.showOOOEventsByAssignee,
    filters: toViewDefinitionFilters(filterState),

    sort:
      viewState.sortBy == null
        ? []
        : [
            {
              field: viewState.sortBy.field,
              direction: viewState.sortBy.direction.toUpperCase() as
                | 'ASC'
                | 'DESC',
            },
          ],
    columns: viewState.columns.map((c) =>
      stripUndefined({
        id: c.id,
        visible: c.visible ?? true,
        width: c.width,
        pinned: c.pinned ?? false,
      })
    ),

    grouping: {
      fields: viewState.groupBy.fields,
      order: stripUndefined(viewState.groupBy.order),
      hideEmptyGroups: viewState.groupBy.hideEmpty,
      stackProjects: viewState.groupBy.stackProjects,
      dateRange: viewState.groupBy.dateRange,
    },
  }
}

export function toViewDefinitionFilters(
  filterState: EntityFilterState
): ViewDefinitionBaseV2['filters'] {
  const { tasks, projects, workspaces } = filterState
  return {
    tasks: {
      ordered: tasks.ordered,
      filters: stripUndefined<ExactFromViewTaskFilter>({
        assigneeUserIds: normalizeNoneIdToNull(tasks.filters.assigneeUserIds),
        createdByUserIds: normalizeNoneIdToNull(tasks.filters.createdByUserIds),

        statusIds: normalize(tasks.filters.statusIds),
        labelIds: normalize(tasks.filters.labelIds),

        priorities: normalize(tasks.filters.priorities),

        dueDate: tasks.filters.dueDate ?? undefined,
        scheduledStart: tasks.filters.scheduledDate ?? undefined,
        startDate: tasks.filters.startDate ?? undefined,

        createdTime: tasks.filters.createdTime ?? undefined,
        completedTime: tasks.filters.completedTime ?? undefined,
        updatedTime: tasks.filters.updatedTime ?? undefined,
        lastInteractedTime: tasks.filters.lastInteractedTime ?? undefined,
        recurring: tasks.filters.recurring ?? undefined,

        completed: tasks.filters.completed ?? undefined,
        scheduledStatus: tasks.filters.scheduledStatus ?? undefined,
        isAutoScheduled: formatInclusionFilterToBooleanFilter(
          tasks.filters.autoScheduled
        ),
        isBlocked: formatInclusionFilterToBooleanFilter(
          tasks.filters.isBlocked
        ),
        isBlocking: formatInclusionFilterToBooleanFilter(
          tasks.filters.isBlocking
        ),
        type: tasks.filters.type ?? undefined,

        customFields: customFieldFiltersToViewDefinition(tasks.filters),

        stageDefinitionIds: normalize(tasks.filters.stageDefinitionIds),
        estimatedCompletionTime:
          tasks.filters.estimatedCompletionTime ?? undefined,
        isUnvisitedStage: tasks.filters.isUnvisitedStage ?? undefined,
        folderIds: tasks.filters.folderIds ?? undefined,
      }),
    },
    projects: {
      ordered: projects.ordered,
      filters: stripUndefined<ExactFromViewProjectFilter>({
        ids: normalize(projects.filters.ids),
        labelIds: normalize(projects.filters.labelIds),
        priorities: normalize(projects.filters.priorities),
        statusIds: normalize(projects.filters.statusIds),
        managerIds: normalizeNoneIdToNull(projects.filters.managerIds),

        startDate: projects.filters.startDate ?? undefined,
        dueDate: projects.filters.dueDate ?? undefined,
        createdTime: projects.filters.createdTime ?? undefined,
        updatedTime: projects.filters.updatedTime ?? undefined,
        completed: projects.filters.completed ?? undefined,

        customFields: customFieldFiltersToViewDefinition(projects.filters),

        activeStageDefinitionIds: normalize(
          projects.filters.stageDefinitionIds
        ),
        projectDefinitionIds: normalize(projects.filters.projectDefinitionIds),
        folderIds: normalize(projects.filters.folderIds),
      }),
    },
    workspaces: {
      ordered: workspaces.ordered as WorkspaceFilterKey[],
      filters: stripUndefined<ViewWorkspaceFilter>({
        ids: normalize(workspaces.filters.ids),
      }),
    },
  }
}

function normalizeNullToNoneUser<T extends { value: (string | null)[] }>(
  filter: T | undefined | null
): T | undefined {
  if (filter == null) return undefined
  const ids = filter.value
  return ids.length === 0
    ? undefined
    : {
        ...filter,
        value: ids.map((x) => (x == null ? createNoneId('user') : x)),
      }
}

function normalizeNoneIdToNull<T extends { value: string[] }>(
  filter: T | undefined | null
): T | undefined {
  if (filter == null) return undefined
  const ids = filter.value
  return ids.length === 0
    ? undefined
    : {
        ...filter,
        value: ids.map((x) => (isNoneId(x) ? null : x)),
      }
}

function normalize<T extends { value: any[] }>(
  filter: T | undefined | null
): T | undefined {
  if (filter == null) return undefined
  const ids = filter.value
  return ids.length === 0 ? undefined : filter
}

export function fromViewDefinition(view: WorkspaceVersionedViewV2): {
  filter: EntityFilterState
  view: ViewState
} {
  const def = view.definition

  const filterState: EntityFilterState = fromViewDefinitionFiltersToFilterState(
    def.itemType,
    def.filters
  )
  const viewState: ViewState = {
    $version: 6,
    columns: def.columns.map((x) =>
      stripUndefined({
        id: x.id,
        visible: x.visible ?? true,
        width: x.width,
        pinned: x.pinned ?? false,
      })
    ),
    cardFields: def.cardFields ?? [],
    providerIds: def.providerIds ?? [],
    showOOOEventsByAssignee: def.showOOOEventsByAssignee ?? false,
    page: def.layout === 'planner' ? 'gantt' : def.layout,
    search: '',
    sortBy:
      def.sort.length === 0
        ? null
        : {
            field: def.sort[0].field as SortField,
            direction: def.sort[0].direction.toLowerCase() as 'asc' | 'desc',
          },
    groupBy: {
      order: stripUndefined(def.grouping.order),
      fields: def.grouping.fields,
      hideEmpty: def.grouping.hideEmptyGroups,
      stackProjects: def.grouping.stackProjects ?? true,
      dateRange: def.grouping.dateRange ?? 'quarter',
    },
    viewId: view.id,
  }

  return {
    filter: filterState,
    view: viewState,
  }
}

export function fromViewDefinitionFiltersToFilterState(
  target: ViewDefinitionBaseV2['itemType'],
  filters: ViewDefinitionBaseV2['filters']
): EntityFilterState {
  const { tasks, projects, workspaces } = filters

  return {
    $version: 7,
    target: target,
    tasks: {
      ordered: remapFields(TASK_FIELDS, tasks.ordered),
      filters: fromViewTaskFilter(tasks.filters),
    },
    projects: {
      ordered: remapFields(PROJECT_FIELDS, projects.ordered),
      filters: fromViewProjectFilter(projects.filters),
    },
    workspaces: {
      ordered: workspaces.ordered,
      filters: Object.assign(
        cloneDeep(DEFAULT_WORKSPACE_FILTERS),
        stripUndefined({
          ids: workspaces.filters.ids,
        })
      ),
    },
  }
}

type ExactToViewProjectFilter = RequiredKeys<
  Omit<ViewProjectFilter, 'customFields' | 'activeStageDefinitionIds'> & {
    stageDefinitionIds: IdNullableFilterSchema | undefined
  }
>

function fromViewProjectFilter(filters: ViewProjectFilter): ProjectFilter {
  return Object.assign(
    cloneDeep(DEFAULT_PROJECT_FILTERS),
    stripUndefined<ExactToViewProjectFilter>({
      ids: filters.ids,
      labelIds: filters.labelIds,
      priorities: filters.priorities,
      statusIds: filters.statusIds,

      managerIds: normalizeNullToNoneUser(filters.managerIds),
      dueDate: filters.dueDate,
      createdTime: filters.createdTime,
      updatedTime: filters.updatedTime,
      completed: filters.completed,
      startDate: filters.startDate,

      ...viewDefinitionToCustomFieldFilters(filters.customFields),

      stageDefinitionIds: filters.activeStageDefinitionIds,
      projectDefinitionIds: filters.projectDefinitionIds,
      folderIds: filters.folderIds,
    })
  )
}

type ExactToViewTaskFilter = RequiredKeys<
  Omit<
    ViewTaskFilter,
    | 'ids'
    | 'workspaceIds'
    | 'customFields'
    | 'projectIds'
    | 'archived'
    | 'endDate'
    | 'scheduledEnd'
  >
>

function fromViewTaskFilter(filters: ViewTaskFilter): TaskFilter {
  const filterUpdates = stripUndefined<ExactToViewTaskFilter>({
    assigneeUserIds: normalizeNullToNoneUser(filters.assigneeUserIds),
    createdByUserIds: normalizeNullToNoneUser(filters.createdByUserIds),

    statusIds: filters.statusIds,
    labelIds: filters.labelIds,

    priorities: filters.priorities,

    dueDate: filters.dueDate,
    scheduledStart: filters.scheduledStart,
    startDate: filters.startDate,
    scheduledStatus: filters.scheduledStatus,

    createdTime: filters.createdTime,
    completedTime: filters.completedTime,
    updatedTime: filters.updatedTime,
    lastInteractedTime: filters.lastInteractedTime,
    completed: filters.completed,
    isAutoScheduled: filters.isAutoScheduled,
    isBlocked: filters.isBlocked,
    isBlocking: filters.isBlocking,
    recurring: filters.recurring,
    type: filters.type,

    ...viewDefinitionToCustomFieldFilters(filters.customFields),

    stageDefinitionIds: filters.stageDefinitionIds,
    estimatedCompletionTime: filters.estimatedCompletionTime,
    isUnvisitedStage: filters.isUnvisitedStage,
    folderIds: filters.folderIds,
  })

  const mappedFilterUpdates = {
    ...omit(filterUpdates, 'isAutoScheduled'),
    autoScheduled: booleanToInclusion(filters.isAutoScheduled?.value),
  }

  const newFilters = merge(
    {},
    DEFAULT_TASK_FILTERS,
    mappedFilterUpdates
  ) satisfies TaskFilter

  return newFilters
}

function booleanToInclusion(value: boolean | undefined): Inclusion | null {
  if (value == null) return null

  return value ? 'include' : 'exclude'
}

export function customFieldFiltersToViewDefinition(
  filters: TaskFilter | ProjectFilter
): ViewDefinitionTaskFilterV2['customFields'] {
  const cfFilters = Object.values(
    // @ts-expect-error - fine
    pick(filters, FieldTypes) as CustomFieldFilters
    // @ts-expect-error - fine
  ).reduce((acc, value) => {
    if (value == null || typeof value !== 'object') {
      return acc
    }
    return { ...acc, ...value }
  }, {}) as NonNullable<ViewDefinitionTaskFilterV2['customFields']>

  if (Object.keys(cfFilters).length === 0) {
    return undefined
  }

  return cfFilters
}

export function viewDefinitionToCustomFieldFilters(
  definition: ViewDefinitionTaskFilterV2['customFields']
): CustomFieldFilters {
  if (definition == null) {
    return DEFAULT_CUSTOM_FIELD_FILTERS
  }

  return entries(definition).reduce<CustomFieldFilters>(
    (acc, [typeKey, idsToFilters]) => {
      const customFieldType = getPrefixFromMaybeCustomFieldKey(typeKey)
      if (customFieldType == null) {
        return acc
      }

      return {
        ...acc,
        [customFieldType]: {
          ...acc[customFieldType as keyof CustomFieldFilters],
          [typeKey]: idsToFilters,
        },
      }
    },
    DEFAULT_CUSTOM_FIELD_FILTERS
  )
}

function remapFields(
  fieldMap: Record<string, string>,
  ordered: string[]
): string[] {
  return ordered.map((x) => fieldMap[x] ?? x)
}

const TASK_FIELDS: Record<string, string> = {
  statuses: 'statusIds',
  users: 'assigneeUserIds',
  labels: 'labelIds',
}

const PROJECT_FIELDS: Record<string, string> = {
  statuses: 'statusIds',
  users: 'managerIds',
  labels: 'labelIds',
}
