import {
  AllTasksGradientSolid,
  MyTasksGradientSolid,
  ProjectGradientSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import type {
  FolderSchema,
  ProjectSchema,
  WorkspaceSchema,
} from '@motion/zod/client'

import type {
  PMPageUrlParams,
  RouteData,
} from '~/areas/project-management/pages/pm-v3/routes/types'
import { MotionLink } from '~/global/components'
import {
  FolderBadge,
  ProjectBadge,
  WorkspaceBadge,
} from '~/global/components/badges'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useGetFolderById } from '~/global/hooks/folders'
import { useUriByRouteId } from '~/routing'
import { useParams } from 'react-router-dom'

type HeaderBreadcrumbsProps = {
  page: RouteData['page']
}

export function HeaderBreadcrumbs({ page }: HeaderBreadcrumbsProps) {
  switch (page) {
    case 'workspace':
      return <ConnectedWorkspaceBreadcrumbs />
    case 'folder':
      return <ConnectedFolderBreadcrumbs />
    case 'project':
      return <ConnectedProjectBreadcrumbs />
    case 'my-tasks':
      return <MyTasksBreadcrumbs />
    case 'all-tasks':
      return <AllTasksBreadcrumbs />
    case 'all-projects':
      return <AllProjectsBreadcrumbs />
    default:
      return null
  }
}

function BreadcrumbDivider() {
  return <BreadcrumbPieceLabel>/</BreadcrumbPieceLabel>
}

function MyTasksBreadcrumbs() {
  return (
    <BreadcrumbPieceContainer url='#'>
      <MyTasksGradientSolid className='size-3' />
      <BreadcrumbPieceLabel>My Tasks</BreadcrumbPieceLabel>
    </BreadcrumbPieceContainer>
  )
}

function AllTasksBreadcrumbs() {
  return (
    <BreadcrumbPieceContainer url='#'>
      <AllTasksGradientSolid className='size-3' />
      <BreadcrumbPieceLabel>All Tasks</BreadcrumbPieceLabel>
    </BreadcrumbPieceContainer>
  )
}

function AllProjectsBreadcrumbs() {
  return (
    <BreadcrumbPieceContainer url='#'>
      <ProjectGradientSolid className='size-3' />
      <BreadcrumbPieceLabel>All Projects</BreadcrumbPieceLabel>
    </BreadcrumbPieceContainer>
  )
}

function ConnectedWorkspaceBreadcrumbs() {
  const { workspaceId } = useParams<PMPageUrlParams>()
  const workspace = useWorkspaceById(workspaceId)
  if (workspace == null) return null
  return <WorkspaceBreadcrumbPiece workspace={workspace} />
}

function ConnectedFolderBreadcrumbs() {
  const { folderId, workspaceId } = useParams<PMPageUrlParams>()
  const getFolderById = useGetFolderById()
  const folder = folderId != null ? getFolderById(folderId) : null
  const workspace = useWorkspaceById(workspaceId)
  if (folder == null || workspace == null) return null
  return (
    <BreadcrumbRow>
      <WorkspaceBreadcrumbPiece workspace={workspace} />
      <BreadcrumbDivider />
      <FolderBreadcrumbPiece folder={folder} workspaceId={workspace.id} />
    </BreadcrumbRow>
  )
}

function ConnectedProjectBreadcrumbs() {
  const { projectId } = useParams<PMPageUrlParams>()
  const project = useProject(projectId)
  const getFolderById = useGetFolderById()
  const folder =
    project?.folderId != null ? getFolderById(project.folderId) : null
  const workspace = useWorkspaceById(project?.workspaceId)

  if (project == null) return null

  return (
    <BreadcrumbRow>
      {workspace && (
        <>
          <WorkspaceBreadcrumbPiece workspace={workspace} />
          <BreadcrumbDivider />
        </>
      )}
      {workspace && folder && folder?.type === 'USER' && (
        <>
          <FolderBreadcrumbPiece folder={folder} workspaceId={workspace.id} />
          <BreadcrumbDivider />
        </>
      )}
      <ProjectBreadcrumbPiece project={project} />
    </BreadcrumbRow>
  )
}

type WorkspaceBreadcrumbPieceProps = {
  workspace: Pick<WorkspaceSchema, 'id' | 'name'>
}

function WorkspaceBreadcrumbPiece({
  workspace,
}: WorkspaceBreadcrumbPieceProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const url = getRouteUri('workspace-detail', { workspaceId: workspace.id })

  return (
    <BreadcrumbPieceContainer url={url}>
      <WorkspaceBadge size='small' />
      <BreadcrumbPieceLabel>{workspace.name}</BreadcrumbPieceLabel>
    </BreadcrumbPieceContainer>
  )
}

type FolderBreadcrumbPieceProps = {
  folder: Pick<FolderSchema, 'id' | 'color' | 'name'>
  workspaceId: WorkspaceSchema['id']
}

function FolderBreadcrumbPiece({
  folder,
  workspaceId,
}: FolderBreadcrumbPieceProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const url = getRouteUri('workspace-folder', {
    workspaceId: workspaceId,
    folderId: folder.id,
  })

  return (
    <BreadcrumbPieceContainer url={url}>
      <FolderBadge size='small' color={folder.color} />
      <BreadcrumbPieceLabel>{folder.name}</BreadcrumbPieceLabel>
    </BreadcrumbPieceContainer>
  )
}

type ProjectBreadcrumbPieceProps = {
  project: Pick<ProjectSchema, 'id' | 'color' | 'name' | 'workspaceId'>
}

function ProjectBreadcrumbPiece({ project }: ProjectBreadcrumbPieceProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const url = getRouteUri('workspace-project', {
    workspaceId: project.workspaceId,
    projectId: project.id,
  })

  return (
    <BreadcrumbPieceContainer url={url}>
      <ProjectBadge size='small' value={project} hideTooltip />
      <BreadcrumbPieceLabel>{project.name}</BreadcrumbPieceLabel>
    </BreadcrumbPieceContainer>
  )
}

const BreadcrumbPieceContainer = classed(
  MotionLink,
  'flex flex-row w-fit gap-1 px-1 items-center rounded hover:bg-semantic-neutral-surface-bg-subtlest'
)

const BreadcrumbPieceLabel = classed(
  'span',
  'text-semantic-neutral-text-subtle text-2xs leading-4'
)

const BreadcrumbRow = classed('div', 'flex flex-row gap-1')
