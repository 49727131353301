import { isFlowVariableKey } from '~/areas/flows'

import { useStageTaskField } from './use-stage-task-field'

export const useStageTaskAssigneeField = (
  taskBasePath: `stages.${number}.tasks.${number}`
) => {
  const [assigneeId, onChangeAssigneeId] = useStageTaskField(
    `${taskBasePath}.assigneeUserId`
  )
  const [assigneeVariableKey, onChangeAssigneeVariableKey] = useStageTaskField(
    `${taskBasePath}.assigneeVariableKey`
  )

  const onChangeAssignee = (assigneeId: string | null) => {
    if (isFlowVariableKey(assigneeId) && assigneeId != null) {
      onChangeAssigneeVariableKey(assigneeId)
      onChangeAssigneeId(null)
    } else {
      onChangeAssigneeId(assigneeId)
      onChangeAssigneeVariableKey(null)
    }
  }

  return [assigneeId ?? assigneeVariableKey, onChangeAssignee] as const
}
