import { type Schedule } from '@motion/rpc-types'
import { type EventConferenceType } from '@motion/shared/common'
import { showToast } from '@motion/ui/base'
import { formatDurationTime } from '@motion/ui-logic'
import { createBookingMessageString } from '@motion/ui-logic/booking'
import { logEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import { type BookingLinkTemplate, type BookingSettings } from './types'

import { Events as AnalyticsEvents } from '../../analyticsEvents'
import { calendarToConflictCalendar } from '../../components/Booking/components/template-form/template-form.utils'
import { copyToClipboard } from '../../localServices/clipboard'
import { defaultAvailabilityMessageTemplate } from '../../storageConstants'
import {
  type AvailabilitySettings,
  type ConflictCalendar,
  type CreateTemplateChildLinkDto,
  type GetBookingLinkDto,
  type UpdateTemplateChildLinkDto,
} from '../../types/bookingTypes'
import { getTzAbbr } from '../../utils'
import {
  createTemplateChildUrl,
  genericToConferenceType,
  questionTypeToFirebase,
} from '../../utils/booking-utils'
import { copyToClipboardAsHtml } from '../../utils/window'
import { getProxy } from '../backgroundProxy'
import { setInitialViewingDate } from '../calendar/calendarSlice'
import { selectMyCalendars } from '../calendar-list/calendar-list-slice'
import {
  calendarAvailabilitiesSelectors,
  clearSelectedAvailabilityFullCalendarEvents,
} from '../calendarEvents/calendarEventsSlice'
import { type ReduxCalendarEvent } from '../calendarEvents/calendarEventsTypes'
import { selectEmailAccounts } from '../email-accounts/email-accounts-slice'
import { type LoadingState } from '../projectManagementSlice'
import { selectAvailabilityTimezone } from '../timezone-slice'
import { type RootState } from '../types'

interface CopyBookingLinksHTMLManualType {
  richText: string
  text: string
}

export interface BookingState {
  bookingTemplates: Record<string, BookingLinkTemplate>
  bookingSettings: BookingSettings
  availabilityCalendarId?: string
  availabilityConflictCalendars: ConflictCalendar[]
  availabilityLinkLoading: boolean
  availabilityLoading: boolean
  linkDuration: number
  multiUse: boolean
  availabilityHover: boolean
  availabilityError: string
  availabilityGuests: string[]
  displayLinksText: string
  displayText: string
  bookingPageLoadingState: LoadingState
}

const initialBookingSettings: BookingSettings = {
  id: '',
  userId: null,
  teamId: null,
  maxMeetingHours: 24,
  customAvailabilityMessageTemplate: null,
  isAvailabilityMessageRichText: false,
  urlPrefix: null,
}

export const initialBookingState: BookingState = {
  bookingTemplates: {},
  bookingSettings: initialBookingSettings,
  availabilityConflictCalendars: [],
  availabilityError: '',
  availabilityGuests: [],
  availabilityHover: false,
  availabilityLinkLoading: false,
  availabilityLoading: false,
  displayLinksText: '',
  displayText: '',
  linkDuration: 30,
  multiUse: false,
  bookingPageLoadingState: 'preload',
}

const bookingService = getProxy('BookingService')

export const getBookingLinkById = createAsyncThunk(
  'booking/getBookingLinkById',
  async (dto: GetBookingLinkDto) => {
    return await bookingService.getBookingLink(dto)
  }
)

export const getSettings = createAsyncThunk('booking/getSettings', async () => {
  return await bookingService.getBookingSettings()
})
export const fetchBookingData = createAsyncThunk(
  'booking/fetchBookingData',
  async () => {
    const [templates, settings] = await Promise.all([
      bookingService.getTemplates(),
      bookingService.initializeBookingSettings(),
    ])
    return { settings, templates }
  }
)

export const createBookingLinkTemplate = createAsyncThunk<
  BookingLinkTemplate,
  BookingLinkTemplate
>('booking/createBookingTemplate', async (template) => {
  const res = await bookingService.createTemplateLink({
    hostEmail: template.hostEmail,
    hostCalendarId: template.hostCalendarId,
    hostDisplayName: template.hostDisplayName,
    blockingTimeMins: template.blockingTimeMins || undefined,
    bufferMins: template.bufferMins ?? undefined,
    conferenceType: genericToConferenceType(template.conferenceType),
    conflictCalendars: template.conflictCalendars.map((calendar) => ({
      title: calendar.title as string,
      ...calendar,
    })),
    customSchedule: template.customSchedule as unknown as Schedule,
    daysSpan: template.daysSpan || undefined,
    durationChoices: template.durationChoices,
    externalEventName: template.externalEventName || undefined,
    hasReminderEmail: template.hasReminderEmail,
    linkSlug: template.linkSlug,
    maxDailyMeetings: template.maxDailyMeetings || undefined,
    name: template.name as string,
    guestEmails: template.guestEmails,
    questions: template.questions.map((question) => ({
      ...question,
      choices: question.choices.map((choice) => ({ text: choice })),
      type: questionTypeToFirebase(question.questionType),
    })),
    reminderEmailBody: template.reminderEmailBody || undefined,
    reminderEmailSubject: template.reminderEmailSubject || undefined,
    reminderEmailPreBookingMins:
      template.reminderEmailPreBookingMins || undefined,
    scheduleId: template.scheduleId || undefined,
    startsIn: template.startsIn || undefined,
    templateId: template.id,
  })
  if (!res) {
    throw new Error('Failed to create template')
  }
  return res
})

interface UpdateBookingLinkTemplateArgs {
  template: BookingLinkTemplate
  previousAvailabilitiesLinkId: string
}

export const updateBookingLinkTemplate = createAsyncThunk<
  BookingLinkTemplate,
  UpdateBookingLinkTemplateArgs
>('booking/updateBookingLinkTemplate', async (args) => {
  const { template, previousAvailabilitiesLinkId } = args
  const res = await bookingService.updateTemplateLink(
    {
      hostEmail: template.hostEmail,
      hostCalendarId: template.hostCalendarId,
      hostDisplayName: template.hostDisplayName,
      blockingTimeMins: template.blockingTimeMins ?? undefined,
      bufferMins: template.bufferMins ?? undefined,
      // Todo: once the booking migration is finished we should update the DTO
      // to take the EventConferenceType (Would be better to have the DTO typed)
      conferenceType: genericToConferenceType(template.conferenceType),
      customSchedule: template.customSchedule as unknown as Schedule,
      conflictCalendars: template.conflictCalendars.map((calendar) => ({
        title: calendar.title as string,
        ...calendar,
      })),
      daysSpan: template.daysSpan || undefined,
      durationChoices: template.durationChoices,
      externalEventName: template.externalEventName ?? undefined,
      hasReminderEmail: template.hasReminderEmail,
      linkSlug: template.linkSlug,
      maxDailyMeetings: template.maxDailyMeetings ?? undefined,
      name: template.name as string,
      guestEmails: template.guestEmails,
      questions: template.questions.map((question) => ({
        ...question,
        choices: question.choices.map((choice) => ({ text: choice })),
        type: questionTypeToFirebase(question.questionType),
      })),
      reminderEmailBody: template.reminderEmailBody || undefined,
      reminderEmailSubject: template.reminderEmailSubject || undefined,
      reminderEmailPreBookingMins:
        template.reminderEmailPreBookingMins || undefined,
      scheduleId: template.scheduleId || undefined,
      startsIn: template.startsIn || undefined,
      templateId: template.id,
    },
    previousAvailabilitiesLinkId
  )
  if (!res) {
    throw new Error('Failed to update template')
  }
  return res
})

export const deleteBookingLinkTemplate = createAsyncThunk(
  'booking/deleteBookingLinkTemplate',
  async (linkId: string) => {
    const res = await bookingService.deleteTemplateLink(linkId)
    if (!res) {
      throw new Error('Failed to delete template')
    }
    return linkId
  }
)

export const createTemplateChildLink = createAsyncThunk<
  { linkSlug: string; linkId: string },
  CreateTemplateChildLinkDto
>('booking/createTemplateChildLink', async (dto) => {
  const res = await bookingService.createTemplateChildLink(dto)
  if (!res) {
    throw new Error('Failed to create template child')
  }
  return { linkSlug: res.linkSlug, linkId: res.linkId }
})

export const updateUrlPrefix = createAsyncThunk<
  string | null,
  { urlPrefix?: string; teamId?: string }
>('scheduler/updateUrlPrefix', async ({ urlPrefix }) => {
  const res = await bookingService.setUrlPrefix(urlPrefix)
  if (!res) {
    throw new Error('Failed to update Url prefix')
  }

  return res
})

export const updateBookingSettings = createAsyncThunk<
  void,
  AvailabilitySettings
>('booking/updateBookingSettings', async (bookingSettings) => {
  const res = await bookingService.updateAvailabilitySettings(bookingSettings)
  if (!res) {
    throw new Error('Failed to update booking settings')
  }
})

export const updateRichAvailabilityMessageSetting = createAsyncThunk<
  boolean,
  boolean
>('booking/updateRichAvailabilityMessageSetting', async (enabled) => {
  const res = await bookingService.updateRichAvailabilityMessageSetting(enabled)
  if (!res) {
    throw new Error('Failed to update rich availability message setting')
  }

  return enabled
})

export const updateTemplateChildLink = createAsyncThunk<
  { linkSlug: string },
  UpdateTemplateChildLinkDto
>('booking/updateTemplateChildLink', async (dto) => {
  const res = await bookingService.updateTemplateChildLink(dto)
  if (!res) {
    throw new Error('Failed to update template child')
  }
  return { linkSlug: res.linkSlug }
})

export const bookingSlice = createSlice({
  initialState: initialBookingState,
  name: 'booking',
  reducers: {
    reset: () => initialBookingState,
    setBookingTemplates: (state: BookingState, action) => {
      state.bookingTemplates = action.payload
    },
    setBookingSettings: (state: BookingState, action) => {
      state.bookingSettings = {
        ...state.bookingSettings,
        ...action.payload,
      }
    },
    copyAvailabilitiesHTMLManual: (
      state: BookingState,
      action: PayloadAction<CopyBookingLinksHTMLManualType>
    ) => {
      const { richText, text } = action.payload

      void copyToClipboardAsHtml(richText, text, true)
    },
    copyAvailabilitiesManual: (
      state: BookingState,
      action: PayloadAction<string>
    ) => {
      void copyToClipboard({ text: action.payload })
    },
    setAvailabilityCalendarId: (
      state: BookingState,
      action: PayloadAction<string>
    ) => {
      state.availabilityCalendarId = action.payload
    },
    setAvailabilityConflictCalendars: (
      state: BookingState,
      action: PayloadAction<ConflictCalendar[]>
    ) => {
      state.availabilityConflictCalendars = action.payload
    },
    setAvailabilityError: (
      state: BookingState,
      action: PayloadAction<string>
    ) => {
      state.availabilityError = action.payload
    },
    setAvailabilityGuests: (
      state: BookingState,
      action: PayloadAction<string[]>
    ) => {
      state.availabilityGuests = action.payload
    },
    setAvailabilityHover: (
      state: BookingState,
      action: PayloadAction<boolean>
    ) => {
      state.availabilityHover = action.payload
    },
    setAvailabilityLinkLoading: (
      state: BookingState,
      action: PayloadAction<boolean>
    ) => {
      state.availabilityLinkLoading = action.payload
    },
    setAvailabilityLoading: (
      state: BookingState,
      action: PayloadAction<boolean>
    ) => {
      state.availabilityLoading = action.payload
    },
    setDisplayLinksText: (
      state: BookingState,
      action: PayloadAction<string>
    ) => {
      state.displayLinksText = action.payload
    },
    setDisplayText: (state: BookingState, action: PayloadAction<string>) => {
      state.displayText = action.payload
    },
    setLinkDuration: (state: BookingState, action: PayloadAction<number>) => {
      state.linkDuration = action.payload
    },
    setMultiUse: (state: BookingState, action: PayloadAction<boolean>) => {
      state.multiUse = action.payload
    },
    setAvailabilityMessageTemplate: (
      state: BookingState,
      action: PayloadAction<string>
    ) => {
      state.bookingSettings.customAvailabilityMessageTemplate =
        action.payload || defaultAvailabilityMessageTemplate
    },
    setRichAvailabilityMessage: (
      state: BookingState,
      action: PayloadAction<boolean>
    ) => {
      state.bookingSettings.isAvailabilityMessageRichText = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.bookingSettings = action.payload
      }
    })
    builder.addCase(fetchBookingData.fulfilled, (state, action) => {
      state.bookingSettings = action.payload.settings
      state.bookingTemplates = (action.payload.templates ?? []).reduce(
        (
          acc: Record<string, BookingLinkTemplate>,
          template: BookingLinkTemplate
        ) => {
          acc[template.id] = template
          return acc
        },
        {}
      )
      state.bookingPageLoadingState = 'loaded'
    })
    builder.addCase(fetchBookingData.pending, (state) => {
      if (state.bookingPageLoadingState === 'preload') {
        state.bookingPageLoadingState = 'loading'
      }
    })
    builder.addCase(createBookingLinkTemplate.fulfilled, (state, action) => {
      if (action.payload) {
        state.bookingTemplates[action.payload.id] = action.payload
      }
    })
    builder.addCase(updateBookingLinkTemplate.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.id in state.bookingTemplates) {
          state.bookingTemplates[action.payload.id] = action.payload
        }
      }
    })
    builder.addCase(deleteBookingLinkTemplate.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload in state.bookingTemplates) {
          delete state.bookingTemplates[action.payload]
        }
      }
    })
    builder.addCase(
      updateRichAvailabilityMessageSetting.fulfilled,
      (state, action) => {
        state.bookingSettings.isAvailabilityMessageRichText = action.payload
      }
    )
  },
})

export const {
  setAvailabilityLinkLoading,
  setAvailabilityLoading,
  setLinkDuration,
  setAvailabilityConflictCalendars,
  setAvailabilityError,
  setAvailabilityGuests,
  copyAvailabilitiesManual,
  copyAvailabilitiesHTMLManual,
  setRichAvailabilityMessage,
  reset,
} = bookingSlice.actions

export const selectBookingTemplates = (state: RootState) =>
  state.booking.bookingTemplates
export const selectBookingSettings = (state: RootState) =>
  state.booking.bookingSettings
export const selectAvailabilityLinkLoading = (state: RootState) =>
  state.booking.availabilityLinkLoading
export const selectAvailabilityLoading = (state: RootState) =>
  state.booking.availabilityLoading
export const selectLinkDuration = (state: RootState) =>
  state.booking.linkDuration
export const selectRichAvailabilityMessage = (state: RootState) =>
  state.booking.bookingSettings.isAvailabilityMessageRichText
export const selectUrlPrefix = (state: RootState) =>
  state.booking.bookingSettings.urlPrefix
export const selectAvailabilityError = (state: RootState) =>
  state.booking.availabilityError
export const selectAvailabilityMessageTemplate = (state: RootState) =>
  state.booking.bookingSettings.customAvailabilityMessageTemplate
export const selectAvailabilityGuests = (state: RootState) =>
  state.booking.availabilityGuests
export const selectBookingPageLoadingState = (state: RootState) =>
  state.booking.bookingPageLoadingState
export const selectBookingOnboarded = (state: RootState) => {
  if (!state.booking.bookingSettings?.urlPrefix) return false
  return Object.values(state.booking.bookingTemplates).length > 0
}
const placeholderLink = 'usemotion.com/your-booking-link'

export const selectAvailabilityText = createSelector(
  [
    calendarAvailabilitiesSelectors.selectAll,
    selectLinkDuration,
    selectAvailabilityMessageTemplate,
    selectAvailabilityTimezone,
  ],
  (
    availabilityEvents,
    duration,
    bookingMessageTemplate,
    availabilityTimezone
  ) => {
    const {
      copyablePlainText,
      copyableHtmlText,
      displayablePlainText,
      displayableTextWithLinks,
    } = createBookingMessageString(
      bookingMessageTemplate ?? defaultAvailabilityMessageTemplate,
      {
        ranges: availabilityEvents,
        timezone: availabilityTimezone,
        timezoneAbbr: getTzAbbr(availabilityTimezone) ?? 'PST',
        bookingLinkUrl: placeholderLink,
        durationString: formatDurationTime(duration),
      }
    )
    return {
      displayLinksText: displayableTextWithLinks,
      displayText: displayablePlainText,
      htmlText: copyableHtmlText,
      text: copyablePlainText,
    }
  }
)

/**
 * Gets the provider id's of calendar events that conflict with the dragged
 * availabilities and should therefore be ignored in the booking link
 * @param availabilityList
 * @param buffer
 * @param calendarEvents
 * @returns
 */
export const getConflictingEventIds = async (
  availabilityList: any[],
  buffer: number,
  calendarEvents: ReduxCalendarEvent[]
) => {
  // filter events by day and relevance
  const conflictingEvents = calendarEvents.filter((event) => {
    const curUserAttendee = event.attendees?.find(
      (a) => a.email === event.email
    )
    const isRelevant =
      curUserAttendee?.status !== 'declined' &&
      event.status !== 'FREE' &&
      event.providerId
    if (!isRelevant) {
      return false
    }

    // Check if any of the availability slots conflict with the event
    const hasConflictingSlot = availabilityList.some((slot) => {
      return (
        DateTime.fromISO(slot.start).minus({ minutes: buffer }) <
          DateTime.fromISO(event.end) &&
        DateTime.fromISO(slot.end).plus({ minutes: buffer }) >
          DateTime.fromISO(event.start)
      )
    })
    return hasConflictingSlot
  })
  return conflictingEvents.map((events) => events.providerId)
}

export const createManualLink = createAsyncThunk<
  void,
  {
    linkOnly: boolean
    conferenceType: EventConferenceType
    hostEmail: string
    hostCalendarId: string
    onSuccess: () => void
  },
  { state: RootState }
>(
  'availability/copyAvailabilities',
  async (
    { linkOnly, conferenceType, hostEmail, hostCalendarId, onSuccess },
    thunkAPI
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    linkOnly
      ? thunkAPI.dispatch(setAvailabilityLinkLoading(true))
      : thunkAPI.dispatch(setAvailabilityLoading(true))
    const state = thunkAPI.getState()
    const availabilityList = calendarAvailabilitiesSelectors.selectAll(state)
    const displayName = state.user.displayName
    const myCalendars = selectMyCalendars(state)
    const emailAccounts = selectEmailAccounts(state)
    const calendarEvents: any[] = []
    const {
      linkDuration,
      availabilityConflictCalendars: conflictCalendars,
      availabilityGuests,
    } = state.booking
    try {
      if (!availabilityList.length) {
        thunkAPI.dispatch(
          setAvailabilityError(
            "You haven't selected any times. Drag available times on the calendar."
          )
        )
        return
      }

      // generate new link
      const conflictingEventIds = await getConflictingEventIds(
        availabilityList,
        0,
        calendarEvents
      )

      let completeConflictCalendars: ConflictCalendar[] = []

      for (const calendar of myCalendars) {
        const emailAccount = emailAccounts.find(
          (e) => e.id === calendar.emailAccountId
        )
        if (!emailAccount) {
          continue
        }

        completeConflictCalendars.push(
          calendarToConflictCalendar(calendar, emailAccount)
        )
      }

      completeConflictCalendars =
        completeConflictCalendars.concat(conflictCalendars)

      const res = await bookingService.createManualLink({
        duration: linkDuration,
        manualSlotList: availabilityList,
        hostDisplayName: displayName,
        conferenceType,
        hostEmail,
        hostCalendarId,
        guestEmails: availabilityGuests,
        conflictCalendars: completeConflictCalendars,
        bufferMins: 0,
        ignoredEventProviderIds: conflictingEventIds,
        isSingleUse: false,
      })

      if (!res) {
        throw new Error('Issue trying to create link')
      }
      const newLink = createTemplateChildUrl(res.linkSlug)

      const { htmlText, text } = selectAvailabilityText(state)
      const richAvailabilityMessage = selectRichAvailabilityMessage(state)

      const messageWithLink = text.split(placeholderLink).join(newLink)
      if (linkOnly) {
        thunkAPI.dispatch(copyAvailabilitiesManual(newLink))
      } else if (richAvailabilityMessage) {
        const htmlMessageWithLink = htmlText
          .split(placeholderLink)
          .join(newLink)
        thunkAPI.dispatch(
          copyAvailabilitiesHTMLManual({
            richText: htmlMessageWithLink,
            text: messageWithLink,
          })
        )
      } else {
        thunkAPI.dispatch(copyAvailabilitiesManual(messageWithLink))
      }

      showToast('success', 'Availabilities copied to your clipboard')

      onSuccess()

      thunkAPI.dispatch(setInitialViewingDate(null))
      // clear selected availabilities
      thunkAPI.dispatch(clearSelectedAvailabilityFullCalendarEvents())
      void logEvent(AnalyticsEvents.CALENDAR_AVAILABILITIES_COPY)
    } catch (e: any) {
      if (e.message) {
        thunkAPI.dispatch(setAvailabilityError(e.message))
      }
      Sentry.captureException(e, {
        tags: { position: 'copyAvailabilities' },
      })
    } finally {
      thunkAPI.dispatch(setAvailabilityLoading(false))
      thunkAPI.dispatch(setAvailabilityLinkLoading(false))
    }
  }
)

export const bookingReducer = bookingSlice.reducer
