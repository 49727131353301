import { selectMainCalendar } from '../state/calendar-list/calendar-list-slice'
import { selectEmailAccounts } from '../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../state/hooks'

export const useInNoExternalCalendarsMode = () => {
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const mainCalendar = useAppSelector(selectMainCalendar)
  return {
    noExternalCalendarsMode: !emailAccounts.length || !mainCalendar,
    mainCalendar,
  }
}
