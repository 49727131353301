import { type UploadedFileSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useSearchParams } from 'react-router-dom'

type UseAttachmentsNavigationParams = {
  attachmentIds: UploadedFileSchema['id'][]
  initialAttachmentId: UploadedFileSchema['id']
}

export function useAttachmentsNavigation({
  attachmentIds,
  initialAttachmentId,
}: UseAttachmentsNavigationParams) {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentAttachmentId = searchParams.get('attachment') as string

  const currentAttachmentIndex = attachmentIds.indexOf(currentAttachmentId)

  const hasPrevAttachment = currentAttachmentIndex > 0
  const hasNextAttachment = currentAttachmentIndex < attachmentIds.length - 1

  const goToPrevAttachment = () => {
    if (hasPrevAttachment) {
      const prevAttachmentId = attachmentIds[currentAttachmentIndex - 1]
      setSearchParams((prev) => {
        prev.set('attachment', prevAttachmentId)
        return prev
      })

      recordAnalyticsEvent('ATTACHMENT_FILE_VIEW_PREV')
    }
  }

  const goToNextAttachment = () => {
    if (hasNextAttachment) {
      const nextAttachmentId = attachmentIds[currentAttachmentIndex + 1]
      setSearchParams((prev) => {
        prev.set('attachment', nextAttachmentId)
        return prev
      })

      recordAnalyticsEvent('ATTACHMENT_FILE_VIEW_NEXT')
    }
  }

  return {
    currentAttachmentId,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
  }
}
