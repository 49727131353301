import { OnboardingPageWrapper } from './OnboardingPageWrapper'

export interface OnboardingTerminatedProps {
  type: 'logout' | 'onboarding'
}

export const OnboardingTerminated = ({ type }: OnboardingTerminatedProps) => {
  let message
  switch (type) {
    case 'logout':
      message =
        'You have logged out from another tab. Please switch to that tab, or refresh this page to continue.'
      break
    case 'onboarding':
    default:
      message =
        'You already have a Motion page open. Please switch to that tab, or refresh this page to continue.'
      break
  }

  return (
    <div
      id='motion-onboard-iframe'
      className='motion-iframe'
      style={{ border: 'none' }}
    >
      <OnboardingPageWrapper navbarPositionFixed={false}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p style={{ color: '#a0a0a0', marginBottom: 48, marginTop: 48 }}>
            {message}
          </p>
        </div>
      </OnboardingPageWrapper>
    </div>
  )
}
