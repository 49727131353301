import {
  type VersionedViewV2,
  type WorkspaceVersionedViewV2,
} from '@motion/zod/client'

import { type PageType } from '../routes/types'

const DEFAULT_VIEW_ID = '<default!view>'

export const isDefaultView = (view: Pick<VersionedViewV2, 'id'>) => {
  return view.id === DEFAULT_VIEW_ID
}

type DefaultViewOptions = ViewProjectOptions

export function getDefaultView(
  type: PageType,
  opts: Partial<DefaultViewOptions> = {}
): WorkspaceVersionedViewV2 {
  if (type === 'all-tasks') return getDefaultViewAllTasks(opts)
  if (type === 'all-projects') return getDefaultViewAllProjects(opts)
  if (type === 'my-tasks') return getDefaultViewMyTasks(opts)
  if (type === 'workspace') return getDefaultViewWorkspace(opts)
  if (type === 'project') return getDefaultViewProject(opts)
  if (type === 'folder') return getDefaultViewFolder(opts)

  throw new Error(`Unknown view type [${type}]`)
}

const getDefaultViewAllTasks = (
  options: Partial<DefaultViewOptions>
): WorkspaceVersionedViewV2 => ({
  id: DEFAULT_VIEW_ID,
  isPrivate: true,
  name: 'Default View',
  type: 'all-tasks',
  definition: {
    $version: 2,
    columns: [],
    cardFields: [],
    providerIds: [],
    showOOOEventsByAssignee: false,
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [{ key: 'workspace' }, { key: 'project' }],
      order: {},
      hideEmptyGroups: true,
      stackProjects: true,
      dateRange: 'quarter',
    },
    sort: [],
    itemType: 'tasks',
    layout: 'list',
    type: 'all-tasks',
  },
  createdTime: '',
  updatedTime: null,
  creatorUserId: null,
  deletedTime: null,
  schemaVersion: 2,
  targetType: 'USER',
  targetId: '',
})

const getDefaultViewMyTasks = (
  options: Partial<DefaultViewOptions>
): WorkspaceVersionedViewV2 => ({
  id: DEFAULT_VIEW_ID,
  isPrivate: true,
  name: 'Default View',
  type: 'my-tasks',
  definition: {
    $version: 2,
    columns: [],
    cardFields: [],
    providerIds: [],
    showOOOEventsByAssignee: false,
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [{ key: 'deadline', by: 'week' }],
      order: {},
      hideEmptyGroups: true,
      stackProjects: true,
      dateRange: 'quarter',
    },
    sort: [{ field: 'estimatedCompletionTime', direction: 'ASC' }],
    itemType: 'tasks',
    layout: 'kanban',
    type: 'my-tasks',
  },
  createdTime: '',
  updatedTime: null,
  creatorUserId: null,
  deletedTime: null,
  schemaVersion: 2,
  targetType: 'USER',
  targetId: '',
})

const getDefaultViewWorkspace = (
  options: Partial<DefaultViewOptions>
): WorkspaceVersionedViewV2 => ({
  id: DEFAULT_VIEW_ID,
  isPrivate: true,
  name: 'Default View',
  type: 'workspace',
  definition: {
    $version: 2,
    columns: [],
    cardFields: [],
    providerIds: [],
    showOOOEventsByAssignee: false,
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [{ key: 'project' }, { key: 'stage' }],
      order: {},
      hideEmptyGroups: true,
      stackProjects: true,
      dateRange: 'quarter',
    },
    sort: [],
    itemType: 'tasks',
    layout: 'list',
    type: 'workspace',
  },
  createdTime: '',
  updatedTime: null,
  creatorUserId: null,
  deletedTime: null,
  schemaVersion: 2,
  targetType: 'WORKSPACE',
  targetId: '',
})

const getDefaultViewAllProjects = (
  options: Partial<DefaultViewOptions>
): WorkspaceVersionedViewV2 => ({
  id: DEFAULT_VIEW_ID,
  isPrivate: true,
  name: 'Default View',
  type: 'all-tasks',
  definition: {
    $version: 2,
    columns: [],
    cardFields: [],
    providerIds: [],
    showOOOEventsByAssignee: false,
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [{ key: 'workspace' }],
      order: {},
      hideEmptyGroups: true,
      stackProjects: true,
      dateRange: 'quarter',
    },
    sort: [],
    itemType: 'projects',
    layout: 'gantt',
    type: 'all-tasks',
  },
  createdTime: '',
  updatedTime: null,
  creatorUserId: null,
  deletedTime: null,
  schemaVersion: 2,
  targetType: 'USER',
  targetId: '',
})

type ViewProjectOptions = {
  isFlowProject: boolean
}

const getDefaultViewProject = ({
  isFlowProject = false,
}: Partial<ViewProjectOptions>): WorkspaceVersionedViewV2 => ({
  id: DEFAULT_VIEW_ID,
  isPrivate: true,
  name: 'Default View',
  type: 'workspace',
  definition: {
    $version: 2,
    columns: [],
    cardFields: [],
    providerIds: [],
    showOOOEventsByAssignee: false,
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'include',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [{ key: isFlowProject ? 'stage' : 'status' }],
      order: {},
      hideEmptyGroups: true,
      stackProjects: true,
      dateRange: 'quarter',
    },
    sort: [],
    itemType: 'tasks',
    layout: 'list',
    type: 'workspace',
  },
  createdTime: '',
  updatedTime: null,
  creatorUserId: null,
  deletedTime: null,
  schemaVersion: 2,
  targetType: 'WORKSPACE',
  targetId: '',
})

const getDefaultViewFolder = (
  options: Partial<DefaultViewOptions>
): WorkspaceVersionedViewV2 => ({
  id: DEFAULT_VIEW_ID,
  isPrivate: true,
  name: 'Default View',
  type: 'workspace',
  definition: {
    $version: 2,
    columns: [],
    cardFields: [],
    providerIds: [],
    showOOOEventsByAssignee: false,
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'include',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [{ key: 'status' }],
      order: {},
      hideEmptyGroups: true,
      stackProjects: true,
      dateRange: 'quarter',
    },
    sort: [],
    itemType: 'tasks',
    layout: 'list',
    type: 'workspace',
  },
  createdTime: '',
  updatedTime: null,
  creatorUserId: null,
  deletedTime: null,
  schemaVersion: 2,
  targetType: 'WORKSPACE',
  targetId: '',
})
