import { SearchSolid } from '@motion/icons'
import { useClosure } from '@motion/react-core/hooks'
import { IconButton, useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useUIContext } from '@motion/ui/providers'
import { debounce } from '@motion/utils/core'

import { useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const NameSearchButton = (props: {
  onSearch: (search: string) => void
  value: string
  placeholder?: string
}) => {
  const { hasVisibleModals } = useUIContext()
  const [isFocused, setIsFocused] = useState(false)
  const [searchValue, setSearchValue] = useState(props.value)
  const textRef = useRef<HTMLLabelElement>(null)

  const stableCallback = useClosure(props.onSearch)
  const debouncedCallback = useMemo(
    () => debounce(stableCallback, 400),
    [stableCallback]
  )

  useShortcut(
    'mod+f',
    () => {
      setIsFocused(true)
      textRef.current?.focus()
    },
    { enabled: !hasVisibleModals }
  )

  const isOpened = useMemo(() => {
    return isFocused || !!searchValue
  }, [isFocused, searchValue])

  // Sync the search value with the prop value since the prop value is debounced
  useEffect(
    function syncName() {
      setSearchValue(props.value)
    },
    [props.value]
  )

  return (
    <div
      className={twMerge(
        'w-6 h-[26px] transition-all flex items-center',
        isOpened && 'w-[180px]'
      )}
    >
      {isOpened ? (
        <TextField
          ref={textRef}
          showClearButton
          sentiment={searchValue ? 'active' : 'default'}
          prefix={<SearchSolid />}
          placeholder={props.placeholder}
          value={searchValue}
          onChange={(searchValue) => {
            setSearchValue(searchValue)
            debouncedCallback(searchValue)
          }}
          onBlur={() => setIsFocused(false)}
          autoFocus
          size='small'
        />
      ) : (
        <IconButton
          icon={SearchSolid}
          onClick={() => setIsFocused(true)}
          size='small'
          variant='outlined'
          sentiment='neutral'
        />
      )}
    </div>
  )
}
