import {
  AgendaGradientSolid,
  AllTasksGradientSolid,
  CalendarGradientSolid,
  MyTasksGradientSolid,
  ProjectGradientSolid,
  TeamScheduleGradientSolid,
  TutorialGradientSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCalendarDateNavigation } from '~/areas/calendar/hooks'
import { useSidebarSearchContext } from '~/areas/search/hook'
import { useCompletedTutorialPercentage } from '~/areas/tutorials/hooks'
import { DateTime } from 'luxon'
import { useRef } from 'react'
import { Link, useMatch } from 'react-router-dom'

import { TextCenteredIcon } from './text-centered-icon'
import { TutorialsPopover } from './tutorials-popover'

import {
  AGENDA_URI,
  ALL_PROJECTS_URI,
  ALL_TASKS_URI,
  CALENDAR_URI,
  MY_TASKS_URI,
} from '../constants'
import { useTutorialSidebarState } from '../hooks/use-show-tutorials-sidebar'

export function PageLinks() {
  const currentDate = DateTime.now()
  const { hasSearch } = useSidebarSearchContext()

  const completedTutorialPercentage = useCompletedTutorialPercentage()
  const calendarNavigation = useCalendarDateNavigation()
  const { showTutorialSidebar } = useTutorialSidebarState()

  const isOnCalendar = Boolean(useMatch('/web/calendar/*'))
  const isOnMyTasks = Boolean(useMatch('/web/pm/my-tasks/*'))
  const isOnAllTasks = Boolean(useMatch('/web/pm/all-tasks/*'))
  const isOnAllProjects = Boolean(useMatch('/web/pm/all-projects/*'))
  const isOnTeamSchedule = Boolean(useMatch('/web/pm/team-schedule/*'))
  const isOnTutorials = Boolean(useMatch('/web/tutorials/*'))
  const isOnAgenda = Boolean(useMatch('/web/agenda/*'))

  const tutorialsRef = useRef<HTMLAnchorElement>(null)

  return (
    <>
      <Tooltip content={hasSearch && 'Agenda'} asChild>
        <LinkRow to={AGENDA_URI} isSelected={isOnAgenda}>
          <AgendaGradientSolid className='size-4' />

          {!hasSearch && <LinkText>Agenda</LinkText>}
        </LinkRow>
      </Tooltip>

      <Tooltip content={hasSearch && 'Calendar'} asChild>
        <LinkRow
          to={CALENDAR_URI}
          isSelected={isOnCalendar}
          onClick={() => {
            if (isOnCalendar) {
              recordAnalyticsEvent('ON_CALENDAR_PAGE_RESET_DATE')
              calendarNavigation.today()
            }
          }}
        >
          <TextCenteredIcon
            colorToken='text-[var(--motion-icon-gradient-bg-bottom)]'
            icon={CalendarGradientSolid}
            size='small'
            text={currentDate.day}
          />

          {!hasSearch && (
            <>
              <LinkText>Calendar</LinkText>

              <span className='text-sidebar-item-text-disabled truncate font-light text-xs text-right ml-auto'>
                {currentDate.toFormat('ccc LLL dd')}
              </span>
            </>
          )}
        </LinkRow>
      </Tooltip>

      <Tooltip content={hasSearch && 'My Tasks'} asChild>
        <LinkRow to={MY_TASKS_URI} isSelected={isOnMyTasks}>
          <MyTasksGradientSolid className='size-4  ml-[-1px]' />

          {!hasSearch && <LinkText>My Tasks</LinkText>}
        </LinkRow>
      </Tooltip>

      <Tooltip content={hasSearch && 'All Tasks'} asChild>
        <LinkRow to={ALL_TASKS_URI} isSelected={isOnAllTasks}>
          <AllTasksGradientSolid className='size-4' />

          {!hasSearch && <LinkText>All Tasks</LinkText>}
        </LinkRow>
      </Tooltip>

      <Tooltip content={hasSearch && 'All Projects'} asChild>
        <LinkRow to={ALL_PROJECTS_URI} isSelected={isOnAllProjects}>
          <ProjectGradientSolid className='size-4' />

          {!hasSearch && <LinkText>All Projects</LinkText>}
        </LinkRow>
      </Tooltip>

      <Tooltip content={hasSearch && 'Team Schedule'} asChild>
        <LinkRow to='/web/pm/team-schedule' isSelected={isOnTeamSchedule}>
          <TeamScheduleGradientSolid className='size-4' />

          {!hasSearch && <LinkText>Team Schedule</LinkText>}
        </LinkRow>
      </Tooltip>

      {!showTutorialSidebar && (
        <Tooltip content={hasSearch && 'Tutorials'} asChild>
          <LinkRow
            to='/web/tutorials'
            isSelected={isOnTutorials}
            onClick={() => {
              recordAnalyticsEvent('TUTORIALS_PAGE_CLICKED', {
                version: 'v2',
              })
            }}
            ref={tutorialsRef}
          >
            <TutorialGradientSolid className='size-4' />

            {!hasSearch && (
              <>
                <LinkText className='text-semantic-purple-text-default'>
                  Tutorials
                </LinkText>
                <span className='text-sidebar-item-text-disabled truncate font-light text-xs text-right ml-auto'>
                  {completedTutorialPercentage}%
                </span>
              </>
            )}
          </LinkRow>
          <TutorialsPopover triggerRef={tutorialsRef} />
        </Tooltip>
      )}
    </>
  )
}

const LinkRow = classed(Link, {
  base: `
    flex py-1.5 px-1 items-center gap-2 text-sidebar-item-text-default
    hover:bg-sidebar-item-bg-hover rounded
  `,
  variants: {
    isSelected: {
      true: 'bg-sidebar-item-bg-selected text-sidebar-item-text-selected',
    },
  },
})

const LinkText = classed('span', 'truncate text-sm font-normal')
