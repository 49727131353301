import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { type FC } from 'react'

import { StageTimelineSegment } from './stage-timeline-segment'
import { StageTimelineContainer, StageTimelineInnerContainer } from './styled'
import { groupStagesByDueDateAndFilterSkipped } from './utils'

type StageTimelineProps = {
  stages: StageSchema[]
  project: ProjectSchema
}

const MAX_STAGE_GROUPS_WITHOUT_SCROLL = 6
const STAGE_WIDTH = 55

export const StageTimeline: FC<StageTimelineProps> = ({ stages, project }) => {
  const groupedStages = groupStagesByDueDateAndFilterSkipped(stages)
  if (groupedStages.length === 0) return null

  const needsScroll = groupedStages.length > MAX_STAGE_GROUPS_WITHOUT_SCROLL

  return (
    <StageTimelineContainer needsScroll={needsScroll}>
      <StageTimelineInnerContainer
        style={{
          minWidth: needsScroll
            ? `${groupedStages.length * STAGE_WIDTH}px`
            : '100%',
        }}
      >
        {groupedStages.map((group, index) => (
          <StageTimelineSegment
            key={group.dueDateKey}
            project={project}
            stageGroup={group}
            prevGroup={groupedStages[index - 1]}
            nextGroup={groupedStages[index + 1]}
            isLastGroup={index === groupedStages.length - 1}
          />
        ))}
      </StageTimelineInnerContainer>
    </StageTimelineContainer>
  )
}
