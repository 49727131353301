import { classed } from '@motion/theme'

import { Outlet } from 'react-router'

export const Shell = () => {
  return (
    <div className='grid h-full grid-cols-1 bg-container-bg min-h-0'>
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </div>
  )
}

const OutletContainer = classed('div', {
  base: 'flex overflow-hidden',
})
