import { type Contact } from '@motion/rpc/types'
import { FieldButton } from '@motion/ui/base'

import { ContactsDropdown } from '~/areas/event/components'
import { type CalendarEventAttendee } from '~/types/CalendarEventAttendee'

type EventFormContactsSearchProps = {
  onSelect: (contact: Contact) => void
  attendees: CalendarEventAttendee[]
}

export const EventFormContactsSearch = (
  props: EventFormContactsSearchProps
) => {
  const { onSelect, attendees } = props

  const selectedEmails = attendees.map((a) => a.email).filter(Boolean)

  return (
    <ContactsDropdown selectedEmails={selectedEmails} onChange={onSelect}>
      <FieldButton size='small' variant='muted' fullWidth>
        Add guests
      </FieldButton>
    </ContactsDropdown>
  )
}
