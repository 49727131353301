import { RefreshSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { isMeetingTask } from '@motion/ui-logic/pm/task'

import { TaskNameTooltip } from '~/areas/tasks/tooltips'
import { getParentChunkId } from '~/areas/tasks/utils'
import { useCachedItem } from '~/global/cache'
import { useTaskModalUrl } from '~/global/navigation'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { AssigneeButton } from './assignee'
import { DeadlineButton } from './deadline'
import { DurationButton } from './duration'
import { PriorityButton } from './priority'
import { ProjectButton } from './project'
import { ScheduledDateButton } from './scheduled-date'
import { TaskStatus } from './task-status'
import { type WithTaskProps } from './types'

import { useTaskIsRecurringFn } from '../../../hooks'
import { type TaskSchema } from '../../../types'

export type TaskRowProps = {
  viewBy: 'scheduledStart' | 'dueDate'
  task: TaskSchema
}

export const TaskRow = ({ task, viewBy }: TaskRowProps) => {
  const buildTaskModalUrl = useTaskModalUrl()
  const uri = useMemo(
    () =>
      buildTaskModalUrl({
        task: task.id,
        isMeeting: isMeetingTask(task),
      }),
    [buildTaskModalUrl, task]
  )

  const parentChunkTask = useCachedItem('tasks', getParentChunkId(task))
  // Use the parent chunk as the main task if completed and it only has 1 chunk
  const finalTask =
    parentChunkTask?.completedTime != null &&
    parentChunkTask.type !== 'CHUNK' &&
    parentChunkTask.chunkIds.length === 1
      ? parentChunkTask
      : task

  const isGhost = task.isUnvisitedStage

  return (
    <Container isGhost={isGhost}>
      <TaskStatus task={finalTask} />
      <TaskNameTooltip asChild task={finalTask}>
        <Link
          to={uri}
          className={`
            relative flex-1 overflow-hidden whitespace-nowrap
            flex items-center h-[calc(100%+4px)]
            after:w-[20px] after:bg-gradient-to-r after:from-transparent after:to-kanban-card-bg-default after:absolute after:h-full after:right-0 after:top-0
          `}
        >
          {finalTask.name}
        </Link>
      </TaskNameTooltip>
      <TaskTypeIcon task={finalTask} />

      <DurationButton task={finalTask} />
      <ProjectButton task={finalTask} />
      {viewBy === 'scheduledStart' ? (
        <DeadlineButton task={finalTask} />
      ) : (
        <ScheduledDateButton task={finalTask} />
      )}
      <PriorityButton task={finalTask} />
      <AssigneeButton task={finalTask} />
    </Container>
  )
}

const TaskTypeIcon = (props: WithTaskProps) => {
  const isRecurring = useTaskIsRecurringFn()
  const isTaskRecurring = isRecurring(props.task)
  if (!isTaskRecurring) return null

  return (
    <RefreshSolid
      width={10}
      height={10}
      className='text-semantic-neutral-icon-subtle'
    />
  )
}

const Container = classed('div', {
  base: `
    flex gap-1 items-center
    border rounded border-kanban-card-border-default hover:border-kanban-card-border-hover
    px-1 py-[2px]
    bg-kanban-card-bg-default
    text-[10px] font-semibold leading-[12px]
    cursor-pointer
  `,
  variants: {
    isGhost: {
      true: `
        border-dashed border-semantic-neutral-border-strong text-semantic-neutral-text-subtle
      `,
    },
  },
})
