import { type TaskSchema } from '@motion/rpc-types'

import { Attachments, AttachmentUploadButton } from '~/areas/attachments'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useState } from 'react'

import { UncontrolledCollapsableContainer } from './collapsable-container'

type TaskAttachmentsCollapsableProps = {
  taskId: TaskSchema['id']
}

export const TaskAttachmentsCollapsable = ({
  taskId,
}: TaskAttachmentsCollapsableProps) => {
  const [expanded, setExpanded] = useState(false)

  const { data: task } = useTaskByIdV2({ id: taskId })

  // Attachments are only available for normal and recurring instance tasks
  if (task?.type !== 'NORMAL' && task?.type !== 'RECURRING_INSTANCE') {
    return null
  }

  const handleToggle = () => setExpanded((expanded) => !expanded)

  const handleAttachmentUpload = () => setExpanded(true)

  const attachmentIds = task.uploadedFileIds || []

  return (
    <UncontrolledCollapsableContainer
      title={`Attachments (${attachmentIds.length})`}
      headerActions={
        <AttachmentUploadButton
          targetId={taskId}
          targetType='TEAM_TASK'
          workspaceId={task.workspaceId}
          onUpload={handleAttachmentUpload}
        />
      }
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='pl-7'>
        <Attachments
          attachmentIds={attachmentIds}
          targetId={taskId}
          targetType='TEAM_TASK'
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
