import { isCanceledStatus } from '@motion/shared/common'
import {
  calculateScheduledType,
  getAutoscheduleInfo,
  scheduleTypeToInfo,
  templateStr,
} from '@motion/ui-logic'
import { type TaskSchema } from '@motion/zod/client'

import { useI18N } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { type ReactNode } from 'react'

import { useBlockerTasks } from './use-blocker-tasks'

const PAST_DUE_TYPES = ['pastDue', 'unfit', 'unfitPastDue'] as const

export function useBlockerOverrideTooltip(
  task: TaskSchema | undefined
): ReactNode | undefined {
  const { pluralize } = useI18N()
  const { getStatusById } = useWorkspaceFns()

  const { blockedBy: blockedByTasks, isLoading } = useBlockerTasks({
    taskId: task?.id,
  })

  if (task?.type !== 'NORMAL' || isLoading) {
    return undefined
  }

  const scheduledType = calculateScheduledType(task, {
    isAutoScheduled: task.isAutoScheduled,
    isCompleted: task.completedTime != null,
    scheduledDate: null,
  })
  const scheduleDateInfo = scheduleTypeToInfo[scheduledType]

  // If the task is unfit and its blocker task is canceled
  if (PAST_DUE_TYPES.includes(scheduleDateInfo.type)) {
    const canceledBlockers = blockedByTasks.filter((t) =>
      isCanceledStatus(getStatusById(t.statusId))
    )
    if (canceledBlockers.length > 0) {
      return templateStr(
        `This task cannot be scheduled because its {{ blockerText }} {{ linkingVerb }} cancelled. Please remove the {{ blockerText }} to schedule this task.`,
        {
          blockerText: pluralize(
            canceledBlockers.length,
            'blocker',
            'blockers'
          ),
          linkingVerb: pluralize(canceledBlockers.length, 'is', 'are'),
        }
      )
    }
  }

  const blockersAutoScheduleInfo = blockedByTasks.map((b) =>
    getAutoscheduleInfo(b, b.isAutoScheduled, b.completedTime != null)
  )

  // If the blocker task is not scheduled
  const unscheduledBlockers = blockersAutoScheduleInfo.filter(
    (b) => b.type === 'notScheduled'
  )
  if (unscheduledBlockers.length > 0) {
    return templateStr(
      `This task cannot be scheduled because its {{ blockerText }} {{ linkingVerb }} not scheduled. Please turn on auto-scheduling on the {{ blockerText }}, or remove the {{ blockerText }} to schedule this task.`,
      {
        blockerText: pluralize(
          unscheduledBlockers.length,
          'blocker',
          'blockers'
        ),
        linkingVerb: pluralize(unscheduledBlockers.length, 'is', 'are'),
      }
    )
  }

  // If the blocker task is past due/unfit
  const pastDueBlockers = blockersAutoScheduleInfo.filter((b) =>
    PAST_DUE_TYPES.includes(b.type)
  )
  if (pastDueBlockers.length > 0) {
    return templateStr(
      `This task is scheduled past deadline because its {{ blockerText }} {{ linkingVerb }} scheduled past deadline.`,
      {
        blockerText: pluralize(pastDueBlockers.length, 'blocker', 'blockers'),
        linkingVerb: pluralize(pastDueBlockers.length, 'is', 'are'),
      }
    )
  }
}
