import { byProperty, cascade, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import type { RecursiveFolderItemSchema } from '@motion/zod/client'

import { useAllProjects } from '~/global/hooks'
import { useFolders } from '~/global/rpc/folders'
import { useUriByRouteId } from '~/routing'
import { useCallback, useMemo } from 'react'

import { type WorkspacesTreeItem } from './types'
import { useGetWorkspaceByFolderId } from './use-get-workspace-by-folder-id'

import { useFindFolder, useGetFolderById } from '../folders'

export const useWorkspacesTree = (): WorkspacesTreeItem[] => {
  const { data: folders } = useFolders()
  const getWorkspaceByFolderId = useGetWorkspaceByFolderId()
  const getFolderById = useGetFolderById()
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const allProjects = useAllProjects()
  const findFolder = useFindFolder()

  const formatFolderTree = useCallback(
    (item: RecursiveFolderItemSchema): WorkspacesTreeItem | null => {
      let children: WorkspacesTreeItem[] = []

      if ('items' in item) {
        children.push(
          ...item.items
            .map(formatFolderTree)
            .filter(Boolean)
            .sort(byProperty('order', Compare.string))
        )
      }

      const isContainer = item.itemType === 'FOLDER'

      // These props are the same for workspaces, folders, and projects
      const commonProps = {
        id: item.id,
        order: item.order,
        itemId: item.itemId,
        parentId: item.folderId,
        children,
        isContainer,
      } as const

      // A "folder" can be a folder or a workspace
      if (item.itemType === 'FOLDER') {
        const folderModel = getFolderById(item.itemId)

        if (!folderModel) {
          Sentry.captureException(
            new Error('Could not find folder model when building folder tree'),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        const workspace = getWorkspaceByFolderId(folderModel.id)

        if (!workspace) {
          Sentry.captureException(
            new Error(
              'Could not find workspace model when building folder tree'
            ),
            {
              extra: {
                folderModel,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        if (folderModel.type === 'WORKSPACE') {
          // This is a workspace
          return {
            ...commonProps,
            label: workspace.name,
            workspaceId: workspace.id,
            type: `${workspace.type}_WORKSPACE`,
            url: getRouteUri('workspace-detail', { workspaceId: workspace.id }),
            meta: {},
          }
        }

        // If it's not a workspace, this is a folder
        return {
          ...commonProps,
          color: folderModel.color,
          label: folderModel.name ?? '(no name)',
          workspaceId: folderModel.targetId,
          type: 'FOLDER',
          url: getRouteUri('workspace-folder', {
            workspaceId: workspace.id,
            folderId: folderModel.id,
          }),
          meta: {},
        }
      }

      // This is a project
      if (item.itemType === 'PROJECT') {
        const projectModal = allProjects.find(
          (project) => item.itemId === project.id
        )

        if (!projectModal) {
          Sentry.captureException(
            new Error('Could not find project model when building folder tree'),
            {
              extra: {
                itemId: item.itemId,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        const workspaceFolder = findFolder(
          ({ targetId, type }) =>
            targetId === projectModal.workspaceId && type === 'WORKSPACE'
        )

        if (!workspaceFolder) {
          Sentry.captureException(
            new Error(
              'Could not find workspace folder model for project when building folder tree'
            ),
            {
              extra: {
                itemId: item.itemId,
                projectModal,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        return {
          ...commonProps,
          color: projectModal.color,
          label: projectModal.name ?? '(no name)',
          workspaceId: projectModal.workspaceId,
          type: 'PROJECT',
          url: getRouteUri('workspace-project', {
            workspaceId: projectModal.workspaceId,
            projectId: projectModal.id,
          }),
          meta: {
            workspaceFolderId: workspaceFolder.id,
            projectStatusId: projectModal.statusId,
          },
        }
      }

      Sentry.captureException(
        new Error('No item match found when building folder tree'),
        {
          extra: {
            item,
          },
          tags: {
            position: 'useFolderTree',
          },
        }
      )

      return null
    },
    [
      allProjects,
      findFolder,
      getFolderById,
      getRouteUri,
      getWorkspaceByFolderId,
    ]
  )

  return useMemo(() => {
    if (!folders?.models.systemFolders.workspaces) {
      return []
    }

    return folders.models.systemFolders.workspaces.items
      .map(formatFolderTree)
      .filter(Boolean)
      .sort(cascade(byProperty('order', Compare.string)))
  }, [folders, formatFolderTree])
}
