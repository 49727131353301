type GrowProjectLeftArgs = {
  // Whether the stage we're calculating is the first stage
  isFirstStage: boolean
  amountToGrow: number
  maxStageWidth: number
  stageLeft: number
  maxStageLeft: number
}

/*
 * Given a stage and the delta to the left, determine how the stage moves based on the delta
 */
export function growProjectLeft({
  isFirstStage,
  amountToGrow,
  maxStageWidth,
  stageLeft,
  maxStageLeft,
}: GrowProjectLeftArgs) {
  // Being increased from the left
  // The first stage left should be set to 0, and its max width should be the stage width plus the delta
  // All other stages should have their left increased by the delta, but max width should stay the same
  if (isFirstStage) {
    return {
      stageLeft: 0,
      maxStageWidth: maxStageWidth + amountToGrow,
      maxStageLeft: maxStageLeft + amountToGrow,
    }
  }

  return {
    stageLeft: stageLeft + amountToGrow,
    maxStageWidth,
    maxStageLeft: maxStageLeft + amountToGrow,
  }
}
