import { usePrevious } from '@motion/react-core/hooks'

import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { EventFormOptionsProvider } from './event-form-options-context'
import { useEventFormInitialData } from './hooks/use-event-form-initial-data'

import { type ReduxCalendarEvent } from '../../../../../state/calendarEvents/calendarEventsTypes'
import { useApplySharedValues, usePeekModalCommonContext } from '../hooks'

type EventFormProps = {
  event: ReduxCalendarEvent
  recurringEventParent?: ReduxCalendarEvent
  children: React.ReactNode
  defaultValues?: { title?: string }
}

export const EventForm = (props: EventFormProps) => {
  const { event, recurringEventParent, children, defaultValues } = props
  const { sharedValues } = usePeekModalCommonContext()

  const initialFormData = useEventFormInitialData({
    event,
    recurringEventParent,
    defaultValues,
  })

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })
  const eventSharedValues = useMemo(
    () => ({
      title: sharedValues.name ?? '',
    }),
    [sharedValues]
  )

  useApplySharedValues({
    enabled: form.getValues('id') == null,
    sharedValues: eventSharedValues,
    setValue: form.setValue,
  })

  const previousId = usePrevious(initialFormData.id)
  const previousStart = usePrevious(initialFormData.start)
  const previousEnd = usePrevious(initialFormData.end)

  useEffect(() => {
    if (previousId !== initialFormData.id) {
      form.reset(initialFormData)
    }
    if (initialFormData.start !== previousStart) {
      form.setValue('start', initialFormData.start)
      form.setValue('isAllDay', initialFormData.isAllDay)
    }
    if (initialFormData.end !== previousEnd) {
      form.setValue('end', initialFormData.end)
      form.setValue('isAllDay', initialFormData.isAllDay)
    }
  }, [form, initialFormData, previousEnd, previousId, previousStart])

  return (
    <FormProvider {...form}>
      <EventFormOptionsProvider>{children}</EventFormOptionsProvider>
    </FormProvider>
  )
}
