import { createStateKey, useSharedState } from '@motion/react-core/shared-state'

import { deserialize, serialize } from './serialization'
import { DEFAULT_VIEW_STATE } from './serialization/defaults'
import { type ViewState } from './types'

export const ViewStateKey = createStateKey<ViewState>('view-state', {
  defaultValue: DEFAULT_VIEW_STATE,
  serialize,
  deserialize,
})

export const useViewState = () => useSharedState(ViewStateKey)
