import { type PriorityLevelType } from '@motion/rpc/types'

import { type DeadlineType } from '../components/ProjectManagement/types'

export const resetDeadlineTypeIfIsEmpty = (
  current?: DeadlineType,
  resetTo: DeadlineType = 'SOFT'
): DeadlineType => {
  return !current ? resetTo : current
}

export const resetPriorityIfIsAsap = (
  current: PriorityLevelType,
  resetTo: PriorityLevelType = 'HIGH'
): PriorityLevelType => {
  return current === 'ASAP' ? resetTo : current
}
