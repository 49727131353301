import { CalendarSolid } from '@motion/icons'
import { FieldButton } from '@motion/ui/base'
import { formatTime } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { SearchableTimeDropdown } from '~/areas/project-management/components'

import { EventFormDateField } from './event-form-date-field'
import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useCalendarStartDay } from '../../../../hooks'
import { useEventFormOptions } from '../event-form-options-context'
import {
  useEventEndDateField,
  useEventEndTimeField,
  useEventForm,
  useEventStartDateField,
  useEventStartTimeField,
} from '../hooks'

export const EventFormDateTimeField = () => {
  const { watch } = useEventForm()

  const start = watch('start')
  const end = watch('end')
  const isAllDay = watch('isAllDay')

  const startDateTime = parseDate(start)
  const endDateTime = parseDate(end)

  return (
    <EventFormRowContainer className='px-2 py-2 bg-semantic-neutral-bg-disabled'>
      <EventFormRowIconContainer>
        <CalendarSolid />
      </EventFormRowIconContainer>
      <div className='flex items-center'>
        <EventStartDateField />
        {!isAllDay && <EventStartTimeField />}
        <span className='text-center'>-</span>
        {!startDateTime.hasSame(endDateTime, 'day') && <EventEndDateField />}
        {!isAllDay && <EventEndTimeField />}
      </div>
    </EventFormRowContainer>
  )
}

const EventStartDateField = () => {
  const { field, onChange } = useEventStartDateField()
  const { isReadOnly } = useEventFormOptions()
  const calendarStartDay = useCalendarStartDay()

  return (
    <EventFormDateField
      value={field.value}
      calendarStartDay={calendarStartDay}
      onChange={onChange}
      readOnly={isReadOnly}
    />
  )
}

const EventStartTimeField = () => {
  const { field, onChange } = useEventStartTimeField()
  const { isReadOnly } = useEventFormOptions()
  const startDateTime = parseDate(field.value).startOf('day')
  const formatted = formatTime(field.value)
  return (
    <SearchableTimeDropdown
      startDateTime={startDateTime}
      value={formatted}
      onChange={onChange}
    >
      <FieldButton size='small' variant='muted' readOnly={isReadOnly}>
        {formatted}
      </FieldButton>
    </SearchableTimeDropdown>
  )
}

const EventEndTimeField = () => {
  const { field, onChange, formValues } = useEventEndTimeField()
  const { isReadOnly } = useEventFormOptions()
  const startDateTime = parseDate(formValues.start)
  const formatted = formatTime(field.value)
  return (
    <SearchableTimeDropdown
      startDateTime={startDateTime}
      value={formatted}
      showDuration
      onChange={onChange}
    >
      <FieldButton size='small' variant='muted' readOnly={isReadOnly}>
        {formatted}
      </FieldButton>
    </SearchableTimeDropdown>
  )
}

const EventEndDateField = () => {
  const { value, onChange } = useEventEndDateField()
  const { isReadOnly } = useEventFormOptions()
  const calendarStartDay = useCalendarStartDay()

  return (
    <EventFormDateField
      value={value}
      calendarStartDay={calendarStartDay}
      onChange={onChange}
      readOnly={isReadOnly}
    />
  )
}
