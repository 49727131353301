import { CarOutline } from '@motion/icons'
import {
  FieldButton,
  PopoverTrigger,
  SearchableList,
  Toggle,
} from '@motion/ui/base'
import { createDurationsFromText, formatDurationTime } from '@motion/ui-logic'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useEventFormOptions } from '../event-form-options-context'
import { useEventTravelTimeField } from '../hooks'

type LocalState = {
  duration: number | null
  before: boolean
  after: boolean
}

const EMPTY_STATE = { after: false, before: false, duration: null }

function mergeLocalState(
  state: LocalState,
  value: Partial<LocalState>
): LocalState {
  const merged = { ...state, ...value }

  // If the duration is null, then clear the flags
  if (merged.duration == null) return EMPTY_STATE

  // If both before and after are null, clear the duration
  if (!merged.before && !merged.after) return EMPTY_STATE

  return merged
}

const durationChoicesBeforeTyping = [15, 30, 45, 60].map((o) => ({
  label: formatDurationTime(o),
  value: o,
}))

export const EventFormTravelTimeField = () => {
  const { travelTimeAfter, travelTimeBefore } = useEventTravelTimeField()
  const { hostCalendar } = useEventFormOptions()
  if (hostCalendar.providerType === 'APPLE') {
    return null
  }

  const state: LocalState = {
    after: travelTimeAfter.value != null,
    before: travelTimeBefore.value != null,
    duration: travelTimeBefore.value ?? travelTimeAfter.value ?? null,
  }

  const handleFilter = (search: string) => {
    if (search) {
      return createDurationsFromText(search)
    }
    return durationChoicesBeforeTyping
  }

  const onChange = (value: Partial<LocalState>) => {
    const newValue = mergeLocalState(state, value)

    travelTimeAfter.onChange(newValue.after ? newValue.duration : null)
    travelTimeBefore.onChange(newValue.before ? newValue.duration : null)
  }

  return (
    <EventFormRowContainer className='items-start mb-1'>
      <EventFormRowIconContainer>
        <CarOutline />
      </EventFormRowIconContainer>
      <div className='w-full flex flex-col gap-1'>
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => (
            <SearchableList
              items={durationChoicesBeforeTyping}
              renderItem={(item) => item.label}
              computeKey={(item) => item.label}
              filter={handleFilter}
              computeSelected={(item) => state.duration === item.value}
              onSelect={(item) => {
                const newValue: Partial<LocalState> = { duration: item.value }
                // If we just selected a value, then make sure we have a before or after
                if (item.value && !state.before && !state.after) {
                  newValue.before = true
                  newValue.after = true
                }
                onChange(newValue)
                close()
              }}
            />
          )}
        >
          <FieldButton size='small' variant='muted' fullWidth>
            {state.duration
              ? formatDurationTime(state.duration)
              : 'Add travel time'}
          </FieldButton>
        </PopoverTrigger>

        {state.duration != null && (
          <div className='ml-3 flex gap-4'>
            <Toggle
              checked={state.before}
              size='small'
              onChange={(e) => onChange({ before: e.target.checked })}
            >
              Before
            </Toggle>
            <Toggle
              checked={state.after}
              size='small'
              onChange={(e) => onChange({ after: e.target.checked })}
            >
              After
            </Toggle>
          </div>
        )}
      </div>
    </EventFormRowContainer>
  )
}
