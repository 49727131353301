import { LoadingOutline, VideoCameraSolid } from '@motion/icons'
import {
  type EventConferenceType,
  type EventConferenceTypes,
} from '@motion/shared/common'
import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  getConferenceDataForType,
  isValidConferenceTypeForProviderType,
} from '@motion/ui-logic'

import { useEffect, useState } from 'react'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useEventFormOptions } from '../event-form-options-context'
import { useEventForm, useEventFormConferencingField } from '../hooks'

export const EventFormConferencingField = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { conferencingOptions, isReadOnly, hostCalendar } =
    useEventFormOptions()
  const { setValue } = useEventForm()

  const { field, onChange } = useEventFormConferencingField()

  const onSelect = async (
    conferenceType: (typeof EventConferenceTypes)[number]
  ) => {
    await onChange(conferenceType)
    setLoading(false)
  }

  useEffect(() => {
    if (
      field.value &&
      !isValidConferenceTypeForProviderType(
        field.value,
        hostCalendar.providerType
      )
    ) {
      setValue('conferenceType', 'none', { shouldDirty: false })
      setValue('location', '', { shouldDirty: false })
    }
    // Make sure this is only invoked if the provider type changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostCalendar.providerType])

  if (isReadOnly && field.value === 'none') return null
  return (
    <EventFormRowContainer className='px-2 mb-2'>
      <EventFormRowIconContainer>
        <VideoCameraSolid />
      </EventFormRowIconContainer>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            items={conferencingOptions}
            renderItem={(item) => (
              <div className='flex items-center gap-2'>
                <ConferenceItem value={item} />
              </div>
            )}
            computeKey={(item) => item}
            computeSelected={(item) => item === field.value}
            onSelect={(item) => {
              void onSelect(item)
              close()
            }}
          />
        )}
      >
        <FieldButton
          size='small'
          variant='muted'
          fullWidth
          readOnly={isReadOnly}
        >
          <ConferenceItem value={field.value} />
          {loading && <LoadingOutline className='animate-spin ml-auto' />}
        </FieldButton>
      </PopoverTrigger>
    </EventFormRowContainer>
  )
}

type ConferenceItemProps = {
  value: EventConferenceType
}
const ConferenceItem = (props: ConferenceItemProps) => {
  const { value } = props
  const item = getConferenceDataForType(value)
  if (!item) return null
  return (
    <>
      {item.link && <img className='h-4' role='presentation' src={item.link} />}
      <span>{item.title}</span>
    </>
  )
}
