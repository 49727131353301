import { ClockSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import {
  formatToShortTaskDuration,
  formatToTaskDuration,
} from '@motion/ui-logic'

import { twMerge } from 'tailwind-merge'

import { type BadgeSize } from './utils'

export type DurationBadgeProps = {
  value: number | null
  size?: BadgeSize
  longMode?: boolean
  hideTooltip?: boolean
}

export const DurationBadge = ({
  value,
  size,
  longMode = false,
  hideTooltip,
}: DurationBadgeProps) => {
  const icon = (
    <ClockSolid
      className={twMerge(
        'text-semantic-neutral-icon-subtle',
        size === 'small' && 'w-4',
        size === 'xsmall' && 'w-3'
      )}
    />
  )

  if (hideTooltip) return icon

  const formattedDuration = longMode
    ? formatToTaskDuration(value)
    : formatToShortTaskDuration(value)

  return (
    <Tooltip content={`Duration: ${formattedDuration}`} asChild>
      {icon}
    </Tooltip>
  )
}
