import { Button, LoadingSpinner } from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'

import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'
import { useState } from 'react'

import { PageTitle } from './kyc-company-questionnaire'
import { DEFAULT_BOOKING_CSM } from './utils'

export function KycBookOnboardingCall({
  goToNextPage,
  userEmail,
}: {
  goToNextPage: () => void
  userEmail: string
}) {
  const team = useAppSelector(selectTeam)
  const [isLoading, setIsLoading] = useState(true)

  useOnMountAnalyticsEvent('CSM_BOOKING_SHOWN', {
    enabled: true,
    properties: {
      teamId: team?.id ?? '',
      csm: DEFAULT_BOOKING_CSM,
    },
  })

  const defaultBookingValue =
    'https://usemotion.com/meet/bishopw/customersuccess?d=30'

  const linkWithEmail = new URL(defaultBookingValue)
  linkWithEmail.searchParams.set('userEmail', userEmail)

  function handleSubmit() {
    recordAnalyticsEvent('KYC_BOOKING_PAGE_CONTINUE')
    goToNextPage()
  }

  return (
    <div className='w-full flex justify-center pt-[42px]'>
      <div className='flex w-[1248px] flex-col items-center gap-10 px-6'>
        <div className='flex justify-between w-full'>
          <div className='flex flex-col items-start gap-4 self-stretch [&_h4]:text-start'>
            <PageTitle>📞 Book an onboarding call with our team</PageTitle>
          </div>
          <div className='flex flex-col justify-center'>
            <Button onClick={handleSubmit}>Continue</Button>
          </div>
        </div>
        {isLoading && <LoadingSpinner className='mt-[60px]' />}
        <iframe
          className='flex-shrink-0 max-w-[1200px] w-full h-[700px]'
          loading='lazy'
          onError={() => setIsLoading(false)}
          onLoad={() => setIsLoading(false)}
          src={linkWithEmail.toString()}
        />
      </div>
    </div>
  )
}
