import { API } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { type ProjectSchema } from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

export function getProjectQueryFilters(projectId: ProjectSchema['id']) {
  return createQueryFilter([
    API.workspacesV2.queryKeys.root,
    MODEL_CACHE_KEY,
    API.projectsV2.queryKeys.byId(projectId),
  ])
}

export function applyOptimisticProjectUpdates(
  client: QueryClient,
  projectId: string,
  updates: Partial<ProjectSchema>
) {
  const { rollback } = MotionCache.patch(
    client,
    getProjectQueryFilters(projectId),
    'projects',
    {
      [projectId]: updates,
    }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}

export function applyOptimisticProjectDelete(
  client: QueryClient,
  projectId: ProjectSchema['id']
): OptimisticUpdateValue {
  const { rollback } = MotionCache.delete(
    client,
    getProjectQueryFilters(projectId),
    'projects',
    projectId
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
