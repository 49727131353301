import {
  type CustomFieldSchema,
  type CustomFieldValuesSchema,
} from '@motion/shared/custom-fields'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'

import { AddCustomFieldButton } from '~/areas/project-management/custom-fields/add-custom-field-button'
import { type Control, useFieldArray } from 'react-hook-form'

import { CustomFieldUISwitch } from './custom-field-ui-switch'

export type ControlledCustomFieldsSidebarProps = {
  control: Control<{
    customFieldValuesFieldArray: CustomFieldFieldArrayValue[]
  }>
  onChange?: (
    customFieldId: CustomFieldSchema['id'],
    valueArg: CustomFieldValuesSchema['value']
  ) => void
  workspaceId: string
  disabled?: boolean
}

export function ControlledCustomFieldsSidebar({
  control,
  workspaceId,
  disabled = false,
  onChange,
}: ControlledCustomFieldsSidebarProps) {
  const { fields, update } = useFieldArray({
    control,
    name: 'customFieldValuesFieldArray',
  })

  return (
    <>
      {fields.map((customField, index) => {
        return (
          <CustomFieldUISwitch
            key={customField.instanceId}
            customField={{ ...customField, workspaceId }}
            disabled={disabled}
            value={customField.value}
            onChange={(value) => {
              if (onChange != null) {
                return onChange(
                  customField.instanceId,
                  value as CustomFieldValuesSchema['value']
                )
              }

              update(index, {
                ...customField,
                value: value as any,
              })
            }}
          />
        )
      })}

      {!disabled && (
        <div className='pt-1'>
          <AddCustomFieldButton
            workspaceId={workspaceId}
            sentiment='neutral'
            size='small'
            usePopover
          />
        </div>
      )}
    </>
  )
}
