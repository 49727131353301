import { classed } from '@motion/theme'
import { Button, Shortcut, Tooltip, useShortcut } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { usePastDueTasksBySchedule } from '~/areas/calendar/hooks'
import { useI18N } from '~/global/contexts'
import { useAppDispatch } from '~/state/hooks'
import { setScheduledAfterDeadlineVisible } from '~/state/modalsSlice'
import { twMerge } from 'tailwind-merge'

type PastDueControlProps = {
  onlyRenderCount?: boolean
}

export const PastDueControl = ({
  onlyRenderCount = false,
}: PastDueControlProps) => {
  const dispatch = useAppDispatch()
  const { hardDeadlineMissed, softDeadlineMissed } = usePastDueTasksBySchedule()
  const modalApi = useModalApi()
  const hasNewPastDueModal = useHasTreatment('past-due-dedupe-recurring')
  const { pluralize } = useI18N()

  const handleOpen = () => {
    if (!hardDeadlineMissed && !softDeadlineMissed) return

    if (hasNewPastDueModal) {
      recordAnalyticsEvent('PAST_DUE_MODAL_OPEN')
      modalApi.open('past-due-modal')
      return
    }

    recordAnalyticsEvent('SCHEDULED_AFTER_DEADLINE_MODAL_OPEN')

    dispatch(setScheduledAfterDeadlineVisible(true))
  }

  useShortcut(
    'Alt+KeyD',
    () => {
      recordAnalyticsEvent('SIDEBAR_NAV_SHORTCUT', {
        shortcut: 'past-due',
      })
      handleOpen()
    },
    {
      ignoreInput: true,
    }
  )

  if (!hardDeadlineMissed && !softDeadlineMissed) return null

  const hardDeadlineMissedCount = hardDeadlineMissed.length
  const softDeadlineMissedCount = softDeadlineMissed.length

  const totalIssues = hardDeadlineMissedCount + softDeadlineMissedCount
  const allSoftDeadlines = hardDeadlineMissedCount === 0

  const sentiment = totalIssues
    ? allSoftDeadlines
      ? 'warning'
      : 'error'
    : 'neutral'

  const renderTooltip = () => {
    if (totalIssues === 0) {
      return (
        <div>
          <p>All your tasks are on time</p>
        </div>
      )
    }

    const taskCounts = [
      `${hardDeadlineMissedCount} hard deadline`,
      `${softDeadlineMissedCount} soft deadline`,
    ]
      .filter((str) => !str.startsWith('0 '))
      .join(' and ')

    return (
      <div className='max-w-[246px] text-left text-sm leading-5'>
        <p
          className={twMerge(
            'font-semibold',
            allSoftDeadlines && 'text-primitives-yellow-400',
            !allSoftDeadlines && 'text-primitives-red-700'
          )}
        >
          {taskCounts} {totalIssues > 1 ? 'tasks' : 'task'} past due, please
          resolve it.
        </p>

        <p>
          This is important to help Motion keep your work and deadlines on time
        </p>
      </div>
    )
  }

  return (
    <Wrapper
      sentiment={sentiment}
      countOnly={onlyRenderCount}
      onClick={handleOpen}
    >
      <Tooltip renderContent={renderTooltip} asChild>
        <div className='inline-flex gap-2 items-center'>
          <Counter>
            <div>{totalIssues}</div>
          </Counter>

          {!onlyRenderCount && (
            <span
              className={twMerge('text-sm', totalIssues > 0 && 'font-semibold')}
            >
              Past {pluralize(totalIssues, 'due', 'dues')}
            </span>
          )}
        </div>
      </Tooltip>

      {!onlyRenderCount && !!totalIssues && (
        <Tooltip
          asChild
          renderContent={() =>
            templateStr(
              'Press {{ shortcut }} to quickly resolve your Past Due items',
              { shortcut: <Shortcut shortcut='alt+D' /> }
            )
          }
        >
          <div className='ml-auto inline-flex'>
            <Button size='xsmall' sentiment='neutral'>
              Resolve
            </Button>
          </div>
        </Tooltip>
      )}
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: 'flex group cursor-pointer',
  variants: {
    sentiment: {
      neutral: 'bg-transparent text-sidebar-item-text-default',
      warning:
        'bg-semantic-warning-bg-default text-semantic-warning-text-default',
      error: 'bg-semantic-error-bg-default text-semantic-error-text-default',
    },
    countOnly: {
      false: 'gap-2 py-3.5 px-4 items-center',
      true: 'bg-transparent',
    },
  },
  defaultVariants: {
    countOnly: false,
  },
  dataAttributes: ['sentiment'],
})

const Counter = classed(
  'div',

  // Outer
  'bg-gradient-to-b from-semantic-neutral-surface-raised-bg-default to-[#D7DBDF] dark:to-[#2B2F31] min-w-5 h-5 inline-flex',
  'shadow-[0px_1px_2px_0px_rgba(118,_79,_79,_0.21)] rounded',
  // Outer - Warning
  'group-data-[sentiment=warning]:from-semantic-warning-bg-strong-default group-data-[sentiment=warning]:to-[#FF9F1A]',
  // Outer - Error
  'group-data-[sentiment=error]:from-semantic-error-bg-strong-default group-data-[sentiment=error]:to-[#CE2C31]',

  // Inner
  '[&>div]:min-w-[18px] [&>div]:h-[18px] [&>div]:px-[5px] [&>div]:m-px [&>div]:rounded',
  '[&>div]:font-semibold [&>div]:text-[11px] [&>div]:leading-[18px] [&>div]:text-center',
  '[&>div]:bg-semantic-neutral-surface-raised-bg-default [&>div]:text-semantic-neutral-text-subtle',
  // Inner - Warning
  'group-data-[sentiment=warning]:[&>div]:bg-transparent group-data-[sentiment=warning]:[&>div]:text-semantic-warning-text-strong dark:group-data-[sentiment=warning]:[&>div]:text-semantic-warning-text-onDark',
  // Inner - Error
  'group-data-[sentiment=error]:[&>div]:bg-transparent group-data-[sentiment=error]:[&>div]:text-semantic-error-text-onDark'
)
