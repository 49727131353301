import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { Button, Tooltip } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

import { useStateObject } from '~/hooks'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type SelectMyCalendarsProps = {
  calendars: Calendar[]
  emailAccounts: EmailAccount[]
  /**
   * Callback that supplies an array of calendars that should be added to
   * the My Calendars section.
   * @param updatedCalendars
   * @returns
   */
  onComplete: (updatedCalendars: Calendar[]) => void
  onBack: () => void
  isInModal?: boolean
}

export function SelectMyCalendars(props: SelectMyCalendarsProps) {
  const { calendars, emailAccounts, onBack, isInModal = false } = props

  const [checkedItems, setCheckedItems] = useStateObject<
    Record<string, boolean>
  >(
    calendars.reduce(
      (prev, cur) => {
        // Always have primary calendars checked
        if (cur.isPrimary || cur.isInMyCalendars) {
          prev[cur.id] = true
        }
        return prev
      },
      {} as Record<string, boolean>
    )
  )

  const [isComplete, setIsComplete] = useState(false)

  const onChecked = (
    calendar: Calendar,
    checked: boolean | 'indeterminate'
  ) => {
    setCheckedItems(
      calendar.id,
      checked === 'indeterminate' ? false : Boolean(checked)
    )
  }

  const onComplete = () => {
    // Resolve the checked calendars and add to `updatedCalendars` if not
    // already in My Calendars
    const updatedCalendars: Calendar[] = []
    for (const [calendarId, checked] of Object.entries(checkedItems)) {
      if (!checked) continue

      const calendar = calendars.find((c) => c.id === calendarId)
      if (!calendar) continue

      if (calendar.isInMyCalendars) continue
      updatedCalendars.push(calendar)
    }

    setIsComplete(true)
    props.onComplete(updatedCalendars)
  }

  return (
    <div className='h-full w-full'>
      <div
        className={twMerge(
          'mx-auto max-w-[600px]',
          !isInModal && 'p-6 lg:pt-12'
        )}
      >
        <h1
          className={twMerge(
            'font-semibold',
            isInModal
              ? 'text-semantic-neutral-text-default pb-4 text-[24px]'
              : 'text-light-1200 g:mb-8 text-lg lg:text-3xl select-none'
          )}
        >
          Select calendars that belong to you
        </h1>
        {isInModal && (
          <p className='text-semantic-neutral-text-default pb-2'>
            We&apos;ll use selected calendars to determine your precise schedule
            and availability.
          </p>
        )}
        {!isInModal && (
          <p className='text-light-1100'>
            We&apos;ll use selected calendars to determine your precise schedule
            and availability.
          </p>
        )}
        <div
          className={twMerge(
            'my-4 rounded border px-3',
            isInModal ? 'border-modal-border' : 'border-gray-300 bg-white'
          )}
        >
          {emailAccounts.map((emailAccount) => (
            <EmailAccountCalendars
              key={emailAccount.id}
              calendars={calendars.filter(
                (c) =>
                  c.emailAccountId === emailAccount.id &&
                  c.type !== 'FREQUENTLY_MET'
              )}
              checkedItems={checkedItems}
              emailAccount={emailAccount}
              onChecked={onChecked}
              isInModal={isInModal}
            />
          ))}
        </div>

        <div className='my-4 flex flex-col gap-3 lg:gap-4'>
          <Button onClick={onComplete} disabled={isComplete} fullWidth>
            <span className='hidden lg:block'>
              {isInModal
                ? 'Done'
                : "I've selected all calendars that belong to me"}
            </span>
            <span className='block lg:hidden'>Continue</span>
          </Button>

          <Button
            sentiment='neutral'
            variant='muted'
            fullWidth
            onClick={onBack}
            disabled={isComplete}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  )
}

type EmailAccountCalendarsProps = {
  emailAccount: EmailAccount
  calendars: Calendar[]
  checkedItems: Record<string, boolean>
  onChecked: (calendar: Calendar, checked: boolean | 'indeterminate') => void
  isInModal: boolean
}

function EmailAccountCalendars(props: EmailAccountCalendarsProps) {
  const { emailAccount, calendars, checkedItems, onChecked, isInModal } = props
  return (
    <div className='my-4'>
      <p
        className={twMerge(
          'mb-1',
          isInModal
            ? 'text-semantic-neutral-text-subtle font-semibold text-[12px]'
            : 'text-light-1100 font-medium'
        )}
      >
        {emailAccount.email}
      </p>
      <ul className='mt-2'>
        {calendars.map((calendar, index) => (
          <li
            key={index}
            className='my-0.5 flex flex-row-reverse justify-between lg:justify-start lg:flex-row lg:gap-2'
          >
            <Tooltip
              content={
                calendar.isPrimary
                  ? 'This is one of your primary calendars'
                  : undefined
              }
            >
              <Checkbox
                checked={Boolean(checkedItems[calendar.id])}
                disabled={!!calendar.isPrimary}
                onChange={(checked) => onChecked(calendar, checked)}
                label={
                  <p
                    className={twMerge(
                      'text-sm break-all',
                      isInModal && 'text-semantic-neutral-text-default'
                    )}
                  >{`${calendar.title}`}</p>
                }
              />
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  )
}
