import { type CalendarSchema } from '@motion/zod/client'

import { useGetCalendars } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { useHostEmailAccount } from './use-host-email-account'

export type HostCalendarResponse = {
  hostCalendar: CalendarSchema | null
  isLoading: boolean
}

export function useHostCalendar(
  email: string,
  calendarId: CalendarSchema['id']
): HostCalendarResponse {
  const { data: calendarsData, isInitialLoading } = useGetCalendars()
  const hostEmailAccount = useHostEmailAccount(email)

  return useMemo(() => {
    const calendars = calendarsData?.calendars ?? []

    const hostCalendar = calendars.find(
      (cal) =>
        cal.emailAccountId === hostEmailAccount?.id && cal.id === calendarId
    )

    return { hostCalendar: hostCalendar ?? null, isLoading: isInitialLoading }
  }, [
    calendarId,
    calendarsData?.calendars,
    hostEmailAccount?.id,
    isInitialLoading,
  ])
}
