import { isNoneId } from '@motion/shared/identifiers'
import {
  getProjectStartDateChangedFields,
  isFlowProject,
} from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { useHasTreatment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

import { useShiftProjectDates } from '../actions'

export function useProjectStartDateUpdater() {
  const updateProject = useProjectUpdater()
  const shiftProjectDates = useShiftProjectDates()
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const modalApi = useModalApi()

  return useCallback(
    async (
      project: ProjectSchema,
      newStartDate: ProjectSchema['startDate']
    ) => {
      if (newStartDate === project.startDate) return true

      if (
        newStartDate == null ||
        project.startDate == null ||
        isNoneId(project.id)
      ) {
        const updates = {
          startDate: newStartDate,
          ...getProjectStartDateChangedFields({
            ...project,
            startDate: newStartDate,
          }),
        }
        await updateProject(project, updates)
        return true
      }

      if (isFlowProject(project) && hasBetterResizeStages) {
        const response = await modalApi.prompt('update-project-date', {
          projectId: project.id,
          newDate: newStartDate,
          dateType: 'start',
        })

        if (response === ModalDismissed) return true

        if (response.dateAdjustmentStrategy !== 'SHIFT') {
          await updateProject(project, {
            startDate: newStartDate,
            ...getProjectStartDateChangedFields({
              ...project,
              startDate: newStartDate,
            }),
            options: {
              dateAdjustmentStrategy: response.dateAdjustmentStrategy,
            },
          })
          return true
        }
      }

      const numDays = parseDate(newStartDate).diff(
        parseDate(project.startDate),
        'days'
      ).days

      return shiftProjectDates({ projectId: project.id, numDays })
    },
    [hasBetterResizeStages, shiftProjectDates, updateProject, modalApi]
  )
}
