import { type UploadedFileSchema } from '@motion/rpc-types'
import { FilePreviewModal, type FilePreviewModalProps } from '@motion/ui/base'

import { useProject } from '~/global/hooks'

import { useAttachmentsPreview } from '../hooks/use-attachments-preview'

export type ProjectAttachmentModalProps = Pick<
  FilePreviewModalProps,
  'visible' | 'onClose'
> & {
  attachment: UploadedFileSchema
}

export function ProjectAttachmentModal({
  attachment,
  ...rest
}: ProjectAttachmentModalProps) {
  const project = useProject(attachment.targetId)

  const uploadedFileIds =
    (project?.type === 'NORMAL' && project?.uploadedFileIds) || []

  const {
    currentAttachment,
    currentAttachmentUrl,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
    deleteAttachment,
    downloadAttachment,
    copyAttachmentLink,
  } = useAttachmentsPreview({
    attachmentIds: uploadedFileIds,
    initialAttachmentId: attachment.id,
  })

  return (
    <FilePreviewModal
      fileUrl={currentAttachmentUrl}
      fileName={currentAttachment?.fileName || ''}
      mimeType={currentAttachment?.mimeType || ''}
      hasPrevFile={hasPrevAttachment}
      hasNextFile={hasNextAttachment}
      onPrevFileButtonClick={goToPrevAttachment}
      onNextFileButtonClick={goToNextAttachment}
      onDelete={deleteAttachment}
      onDownload={downloadAttachment}
      onCopyLink={copyAttachmentLink}
      {...rest}
    />
  )
}
