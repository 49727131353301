import { createContext, type ReactNode, useMemo } from 'react'

export type FlowModalState = any

const defaultValue: FlowModalState = {}

export const FlowModalStateContext = createContext(defaultValue)

export type FlowModalStateProviderProps = {
  children: ReactNode
}

// Needed?
export function FlowModalStateProvider({
  children,
}: FlowModalStateProviderProps) {
  const value = useMemo(() => {
    return {}
  }, [])

  return (
    <FlowModalStateContext.Provider value={value}>
      {children}
    </FlowModalStateContext.Provider>
  )
}
