import { FieldButton } from '@motion/ui/base'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { type DateTime } from 'luxon'

type EventFormDateFieldFieldProps = {
  value: string
  calendarStartDay?: CalendarStartDay
  onChange: (date: DateTime) => void
  readOnly?: boolean
}

export const EventFormDateField = (props: EventFormDateFieldFieldProps) => {
  const { value, onChange, calendarStartDay, readOnly } = props
  return (
    <SimpleDateDropdown
      value={value}
      calendarStartDay={calendarStartDay}
      disabledDate={() => false}
      onChange={(value) => {
        if (!value) return
        try {
          onChange(parseDate(value))
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              value,
              position: 'EventFormDateField',
            },
          })
        }
      }}
    >
      <FieldButton size='small' variant='muted' readOnly={readOnly}>
        {parseDate(value).toFormat('MMM d')}
      </FieldButton>
    </SimpleDateDropdown>
  )
}
