import { getProjectInitialDueDate } from '@motion/ui-logic'
import {
  calculateProjectStageDueDates,
  type SetupProjectFormFields,
  type VariableArg,
} from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { type ProjectsV2CreateRequestSchema } from '@motion/zod/client'

import {
  DEFAULT_TEXT_VARIABLE_KEYS,
  isRoleVariable,
  isTextVariable,
  useIsFlowsM4Enabled,
} from '~/areas/flows'
import {
  useMyTasksWorkspace,
  useProject,
  useProjectDefinition,
  useWorkspaceFns,
} from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

import {
  useProjectModalUrlParams,
  useTaskPrefillData,
} from '../../project-modal/hooks'
import { type SetupProjectSearchParams } from '../utils'

export function useInitialFormData(): SetupProjectFormFields {
  const { flowTemplateId, forTaskId } =
    useSearchParams<SetupProjectSearchParams>()

  const { getWorkspaceById } = useWorkspaceFns()
  const defaultWorkspace = useMyTasksWorkspace()
  const { forStartDate, forDueDate, forProjectId } = useProjectModalUrlParams()

  // flowTemplateId (and projectDefinition) should always be defined, except very briefly when closing the modal.
  const projectDefinition = useProjectDefinition(flowTemplateId)

  const forProject = useProject(forProjectId)

  const isFlowsM4Enabled = useIsFlowsM4Enabled()

  const workspaceId = projectDefinition?.workspaceId ?? defaultWorkspace?.id
  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const today = DateTime.now().startOf('day')

  // Prefill the setup project form with some data from a task, if provided
  const taskData = useInternalTaskPrefill(forTaskId)

  const startDateTime = useMemo(() => {
    if (taskData?.startDate) {
      return DateTime.fromISO(taskData.startDate)
    }

    if (forStartDate != null) {
      try {
        const parsedDateTime = DateTime.fromISO(forStartDate)
        return parsedDateTime >= today ? parsedDateTime : today
      } catch (err) {
        return today
      }
    }

    return today
  }, [forStartDate, taskData?.startDate, today])

  const maybeDeadlineDateTime = useMemo(() => {
    if (taskData?.dueDate) {
      return DateTime.fromISO(taskData.dueDate)
    }

    if (forDueDate != null) {
      try {
        const parsedDateTime = DateTime.fromISO(forDueDate)
        return parsedDateTime >= startDateTime.plus({ days: 1 })
          ? parsedDateTime
          : undefined
      } catch (err) {
        return undefined
      }
    }
  }, [forDueDate, startDateTime, taskData?.dueDate])

  return useMemo(() => {
    const workspace = getWorkspaceById(workspaceId)

    if (workspace == null) {
      throw new Error('Cannot find workspace')
    }

    let stageDueDates = calculateProjectStageDueDates(
      projectDefinition?.stages ?? [],
      startDateTime,
      maybeDeadlineDateTime
    )

    if (isFlowsM4Enabled) {
      stageDueDates = stageDueDates.map((stage) => ({
        ...stage,
        skipped: false,
      }))
    }

    const roleAssignees = (projectDefinition?.variables ?? [])
      .filter(isRoleVariable)
      .map((role) => ({
        variableId: role.id,
        value: null,
      })) satisfies VariableArg[]

    const textReplacements = (projectDefinition?.variables ?? [])
      .filter(isTextVariable)
      .filter((variable) => !DEFAULT_TEXT_VARIABLE_KEYS.includes(variable.key))
      .map((variable) => ({
        variableId: variable.id,
        value: '',
      })) satisfies VariableArg[]

    return {
      projectDefinitionId: projectDefinition?.id ?? null,
      projectDefinition,
      workspaceId,
      name: taskData?.name ?? forProject?.name ?? '',
      startDate: startDateTime.toISODate(),
      dueDate:
        maybeDeadlineDateTime?.toISODate() ??
        stageDueDates[stageDueDates.length - 1]?.dueDate ??
        // Shouldn't happen
        getProjectInitialDueDate(),
      stageDueDates,
      roleAssignees,
      textReplacements,
    }
  }, [
    getWorkspaceById,
    workspaceId,
    projectDefinition,
    startDateTime,
    maybeDeadlineDateTime,
    isFlowsM4Enabled,
    taskData?.name,
    forProject?.name,
  ])
}

function useInternalTaskPrefill(forTaskId: string | undefined) {
  const getTaskPrefillData = useTaskPrefillData()
  const [taskData, setTaskData] =
    useState<ProjectsV2CreateRequestSchema | null>(null)

  useEffect(() => {
    if (forTaskId != null) {
      getTaskPrefillData(forTaskId)
        .then((data) => {
          setTaskData(data)
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              position: 'useInternalTaskPrefill',
            },
          })
        })
    }
  }, [forTaskId, getTaskPrefillData])

  return taskData
}
