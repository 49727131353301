import { type NormalTaskSchema } from '@motion/zod/client'

import { type SortBy } from '../../utils'

export const HEADER_GROUP_HEIGHT = 36
export const SPACER_HEIGHT = 16

export const TASK_LINE_HEIGHT = 30
export const INLINE_ADD_TASK_HEIGHT = 142

type BlockerLineData = {
  sort: keyof typeof SortBy
  currentTask: NormalTaskSchema
  currentTaskIndex: number
  hoveredBlockedTask: NormalTaskSchema | null
  hoveredBlockedIndex: number | null
  setHoveredBlockedIndex: (index: number | null) => void
  firstBlockingTaskIndex: number
  getIsTaskBlocked: (task: NormalTaskSchema) => boolean
}

export const NO_BLOCKERS_PROPS = {
  isBlocked: false,
  sortedByBlockers: false,
  showBlockedArrow: false,
  showBlockingArrow: false,
  showLine: false,
  onBlockedHover: () => {},
  onBlockedLeave: () => {},
}

export function getBlockerLineData({
  sort,
  currentTask,
  currentTaskIndex,
  hoveredBlockedTask,
  hoveredBlockedIndex,
  setHoveredBlockedIndex,
  firstBlockingTaskIndex,
  getIsTaskBlocked,
}: BlockerLineData) {
  const sortedByBlockers = sort === 'BLOCKERS'

  if (!sortedByBlockers) {
    return NO_BLOCKERS_PROPS
  }

  const isBlocked = getIsTaskBlocked(currentTask)

  const showBlockingArrow =
    hoveredBlockedTask != null &&
    currentTask.blockingTaskIds.some((id) => id === hoveredBlockedTask.id)

  const showBlockedArrow = hoveredBlockedIndex === currentTaskIndex

  const showLine =
    hoveredBlockedIndex != null &&
    firstBlockingTaskIndex !== -1 &&
    currentTaskIndex > firstBlockingTaskIndex &&
    currentTaskIndex < hoveredBlockedIndex

  const onBlockedHover = () => {
    if (!isBlocked) return
    setHoveredBlockedIndex(currentTaskIndex)
  }

  const onBlockedLeave = () => {
    setHoveredBlockedIndex(null)
  }

  return {
    isBlocked,
    sortedByBlockers,
    showBlockedArrow,
    showBlockingArrow,
    showLine,
    onBlockedHover,
    onBlockedLeave,
  }
}
