import { useSharedState } from '@motion/react-core/shared-state'
import { isFlowProject } from '@motion/ui-logic/pm/project'

import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { useSelectView } from './use-select-view'

import { usePageData } from '../../routes/hooks'
import { ViewStateKey } from '../../view-state'
import { getDefaultView } from '../defaults'

export const useSelectedView = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const [viewState] = useSharedState(ViewStateKey)
  const route = usePageData()

  const update = useSelectView()

  const currentView = useMemo(() => {
    const defaultView = getDefaultView(route.page, {
      isFlowProject:
        route.page === 'project' &&
        route.lock.projectId != null &&
        isFlowProject(ctx.projects.byId[route.lock.projectId]),
    })

    return viewState.viewId == null
      ? defaultView
      : (ctx.views.byId[viewState.viewId] ?? defaultView)
  }, [
    ctx.projects.byId,
    ctx.views.byId,
    route.lock.projectId,
    route.page,
    viewState.viewId,
  ])

  return [currentView, update] as const
}
