import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { type ActionArgs } from './types'
import { useBulkUpdateRows } from './use-bulk-update-rows'

// Given multiple task IDs
export const useUpdateTasksComplete = (args: ActionArgs) => {
  const { type, ...rest } = args
  const bulkUpdateRows = useBulkUpdateRows()
  const { getWorkspaceStatuses } = useWorkspaceFns()

  return useCallback(async () => {
    recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_COMPLETE', {
      type,
      numTasks: rest.tasks.length,
    })

    return bulkUpdateRows({
      ...rest,
      updates: (workspaceId) => {
        const workspaceStatuses = getWorkspaceStatuses(workspaceId)
        const completedStatus = workspaceStatuses.find(
          (status) => status.type === 'COMPLETED'
        )
        if (!completedStatus) {
          throw new Error('No completed status in workspace')
        }

        return {
          type: 'bulk-field-update',
          statusId: completedStatus.id,
        }
      },
    })
  }, [type, rest, bulkUpdateRows, getWorkspaceStatuses])
}
