import { useSharedState } from '@motion/react-core/shared-state'
import { type TeamWithRelationsSerializer } from '@motion/rpc-types'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser, type User } from '@motion/web-common/auth'

import { useCurrentTeam } from '~/global/rpc/team'
import { useCreateView, useUpdateView } from '~/global/rpc/v2'
import { useCallback, useMemo } from 'react'

import { useSelectView } from './use-select-view'
import { useSelectedView } from './use-selected-view'

import { usePageData } from '../../routes'
import { type PageParams } from '../../routes/types'
import { ViewStateKey } from '../../view-state'
import { isDefaultView } from '../defaults'
import { type LocalView } from '../types'
import { areViewsEqual, toViewDefinition } from '../utils'

function createViewTarget(
  page: PageParams,
  user: User,
  team: TeamWithRelationsSerializer | null | undefined
): Pick<LocalView, 'targetId' | 'targetType'> {
  if (page.view.type === 'all-tasks') {
    return {
      targetType: team ? 'TEAM' : 'USER',
      targetId: team ? team.id : user.uid,
    }
  }

  if (page.view.type === 'my-tasks') {
    return {
      targetType: 'USER',
      targetId: user.uid,
    }
  }

  if (page.view.type === 'workspace') {
    return {
      targetType: 'WORKSPACE',
      targetId: page.lock.workspaceId!,
    }
  }

  throw new Error('unknown')
}

export const useEffectiveView = () => {
  const [filterState] = useSharedState(ActiveFilterKey)
  const [viewState] = useSharedState(ViewStateKey)
  const route = usePageData()

  const [selectedView] = useSelectedView()

  const team = useCurrentTeam()
  const user = useAuthenticatedUser()

  const effectiveView = useMemo(() => {
    const info: Pick<LocalView, 'targetId' | 'targetType'> = createViewTarget(
      route,
      user,
      team.data
    )

    const viewType =
      route.view.type === 'unknown' ? 'all-tasks' : route.view.type

    const definition = toViewDefinition(viewType, filterState, viewState)
    const view: LocalView = {
      id: isDefaultView(selectedView) ? null : selectedView.id,
      type: viewType,
      name: isDefaultView(selectedView) ? '' : selectedView.name,
      isPrivate: selectedView.isPrivate,
      definition,
      ...info,
    }

    return view
  }, [filterState, route, selectedView, team.data, user, viewState])

  const state = useMemo(() => {
    const modified = !areViewsEqual(
      selectedView.definition,
      effectiveView.definition
    )
    return modified ? 'dirty' : 'clean'
  }, [effectiveView.definition, selectedView.definition])

  const saveInternal = useSaveView()

  const save = useCallback(
    (args: { asNew?: boolean }) => {
      return saveInternal({ view: effectiveView, asNew: args.asNew })
    },
    [effectiveView, saveInternal]
  )

  return {
    state,
    isDefaultView: effectiveView.id == null,
    view: effectiveView,
    save,
  }
}

type UseSaveViewArgs = {
  view: LocalView
  asNew?: boolean
}

export const useSaveView = () => {
  const createView = useCreateView()
  const updateView = useUpdateView()
  const select = useSelectView()
  const user = useAuthenticatedUser()

  return useCallback(
    async (args: UseSaveViewArgs) => {
      const viewId = args.view.id

      const shouldCreateNew = viewId == null || args.asNew

      recordAnalyticsEvent(
        shouldCreateNew
          ? 'PROJECT_MANAGEMENT_CREATE_SAVED_VIEW'
          : 'PROJECT_MANAGEMENT_UPDATE_SAVED_VIEW',
        {
          isPrivate: Boolean(args.view.isPrivate),
          type: args.view.type,
        }
      )

      const response = await (shouldCreateNew
        ? createView.mutateAsync({
            data: { ...args.view, creatorUserId: user.uid },
          })
        : updateView.mutateAsync({ viewId, data: args.view }))

      const newView = response.models.views[response.id]

      select(newView)

      return newView
    },
    [createView, select, updateView, user.uid]
  )
}
