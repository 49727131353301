import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { useOpenCalendarEvent } from '~/areas/calendar/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { EventResult } from './event-result'

import { setBaseDate } from '../../../../../state/calendar/calendarSlice'
import { useAppDispatch } from '../../../../../state/hooks'
import { useOpenSearchEvent } from '../../hooks'
import { useSearchContext } from '../../search-context'
import { type CalendarEventSearchSchema } from '../../utils'

export interface ConnectedEventResultProps {
  item: CalendarEventSearchSchema
  active: boolean
  onItemClick: () => void
}

export function ConnectedEventResult({
  item,
  onItemClick,
}: ConnectedEventResultProps) {
  const dispatch = useAppDispatch()
  const { activeCalendars } = useSearchContext()
  const navigate = useNavigate()
  const hasEventModalEnabled = useHasTreatment('event-modal')
  const openSearchEvent = useOpenSearchEvent()
  const openCalendarEvent = useOpenCalendarEvent()

  const goToEvent = useCallback(() => {
    if (!hasEventModalEnabled) {
      dispatch(setBaseDate(item.start))

      navigate('/web/calendar')
      openCalendarEvent({
        providerId: item.providerId,
        start: item.start,
        end: item.end,
      })
      return
    }

    openSearchEvent({
      providerId: item.providerId,
      start: item.start,
      end: item.end,
    })
  }, [
    dispatch,
    hasEventModalEnabled,
    item.end,
    item.providerId,
    item.start,
    navigate,
    openCalendarEvent,
    openSearchEvent,
  ])

  const handleClick = async () => {
    recordAnalyticsEvent('SEARCH_RESULT_OPENED', {
      type: item.recurringEventId ? 'recurring_event' : 'event',
    })

    try {
      goToEvent()
      onItemClick()
    } catch (e) {
      showErrorToast(e)
    }
  }

  return (
    <EventResult
      activeCalendars={activeCalendars}
      item={item}
      onClick={handleClick}
    />
  )
}
