import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useGetScheduledEntitiesLazy } from '~/global/rpc'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import {
  useCalendarStartAndEnd,
  useSendCalendarState,
} from './use-calendar-state'

import { getFullCalendarEventId } from '../utils'

type OpenCalendarEventParams = {
  id?: CalendarEventSchemaV2['id']
  providerId?: CalendarEventSchemaV2['providerId']
  start: CalendarEventSchemaV2['start']
  end: CalendarEventSchemaV2['end']
}

/**
 * Handle clicking on a calendar event (whether from agenda, search modal, or anywhere else)
 * And whether the event is in in current week or not
 * @param id - The event id to open
 * @param providerId - The provider id to open (if ID is not provided like in search results)
 * @param start - The start date of the event
 *
 * If the event is not found, OR if event is in current week of calendar state
 * set calendar state to open the event
 *
 * @deprecated Remove when `event-modal` FF is 100% shipped
 */
export const useOpenCalendarEvent = () => {
  const setCalendarState = useSendCalendarState()
  const calendarStartAndEnd = useCalendarStartAndEnd()
  const { execute: getScheduledEntities } = useGetScheduledEntitiesLazy()

  const scheduledEntitiesFromDate = useCallback(
    (date: DateTime) => {
      return getScheduledEntities({
        filters: {
          scheduled: {
            from: date.startOf('day').toISO(),
            to: date.endOf('day').toISO(),
          },
          completed: 'include',
        },
      })
    },
    [getScheduledEntities]
  )

  return useCallback(
    async ({ id, providerId, start, end }: OpenCalendarEventParams) => {
      if (!id && !providerId) {
        throw new Error('Either id or providerId must be provided')
      }

      const startOfStart = DateTime.fromISO(start).startOf('day').toISO()
      const endOfEnd = DateTime.fromISO(end).endOf('day').toISO()

      // If the event starts in the current week
      const isStartInCurrentWeek =
        calendarStartAndEnd.from <= startOfStart &&
        calendarStartAndEnd.to >= startOfStart

      // If the event ends in the current week
      const isEndInCurrentWeek =
        calendarStartAndEnd.from <= endOfEnd &&
        calendarStartAndEnd.to >= endOfEnd

      // If the event is in the current week such that start is before the current week and end is after the current week
      const isEncompassingCurrentWeek =
        calendarStartAndEnd.from >= DateTime.fromISO(start).toISO() &&
        calendarStartAndEnd.to <= DateTime.fromISO(end).toISO()

      // If the event is in the the current week, just navigate directly to the event
      if (
        id &&
        (isStartInCurrentWeek ||
          isEndInCurrentWeek ||
          isEncompassingCurrentWeek)
      ) {
        setCalendarState({
          selectedCalendarEvent: {
            id: getFullCalendarEventId(id),
          },
        })

        return
      }

      // Set selected date to the events start date and refetch scheduled entities
      setCalendarState({
        selectedDate: DateTime.fromISO(start),
      })

      const scheduledEntities = await scheduledEntitiesFromDate(
        DateTime.fromISO(start)
      )

      // Need to wait for calendar to rerender
      setTimeout(() => {
        if (id && scheduledEntities.models.calendarEvents[id]) {
          setCalendarState({
            selectedCalendarEvent: {
              id: getFullCalendarEventId(id),
            },
          })
        } else if (providerId) {
          const foundEvent = Object.values(
            scheduledEntities.models.calendarEvents
          ).find((event) => event.providerId === providerId)

          if (foundEvent) {
            setCalendarState({
              selectedCalendarEvent: {
                id: getFullCalendarEventId(foundEvent.id),
              },
            })
          }
        }
      }, 500)
    },
    [
      calendarStartAndEnd.from,
      calendarStartAndEnd.to,
      scheduledEntitiesFromDate,
      setCalendarState,
    ]
  )
}
