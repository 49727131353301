import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import {
  type VersionedViewV2,
  type WorkspaceVersionedViewV2,
} from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { useNavigateByRouteId } from '~/routing'
import { useCallback } from 'react'

import { usePageData } from '../../routes/hooks'
import { ViewStateKey } from '../../view-state'
import { getDefaultView } from '../defaults'
import { fromViewDefinition } from '../utils'

type SelectViewOptions = {
  updateOnly?: boolean
}

export const useSelectView = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const setViewState = useSharedStateSendOnly(ViewStateKey)
  const setFilterState = useSharedStateSendOnly(ActiveFilterKey)
  const navigate = useNavigateByRouteId()
  const route = usePageData()

  const defaultView = getDefaultView(route.page, {
    isFlowProject:
      route.page === 'project' &&
      route.lock.projectId != null &&
      isFlowProject(ctx.projects.byId[route.lock.projectId]),
  })

  return useCallback(
    (
      maybeIdOrView: VersionedViewV2 | string | null,
      opts: SelectViewOptions = { updateOnly: false }
    ) => {
      const view =
        maybeIdOrView == null
          ? defaultView
          : typeof maybeIdOrView === 'string'
            ? (ctx.views.byId[maybeIdOrView] ?? defaultView)
            : maybeIdOrView

      const local = fromViewDefinition(view as WorkspaceVersionedViewV2)

      setFilterState(local.filter)
      setViewState(local.view)
      if (!opts.updateOnly) {
        navigate('parent', {
          type: local.filter.target,
          variant: local.view.page,
        })
      }
    },
    [ctx.views.byId, defaultView, navigate, setFilterState, setViewState]
  )
}
