import { DocumentTextSolid } from '@motion/icons'

import { EventFormDescriptionEditor } from './event-form-description-editor'
import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { useEventFormOptions } from '../event-form-options-context'
import { useEventFormDescriptionField } from '../hooks'

export const EventFormDescriptionField = () => {
  const { field } = useEventFormDescriptionField()
  const { isReadOnly } = useEventFormOptions()
  if (!field.value && isReadOnly) return null
  return (
    <EventFormRowContainer className='px-2 mb-2'>
      <EventFormRowIconContainer>
        <DocumentTextSolid />
      </EventFormRowIconContainer>
      <EventFormDescriptionEditor
        readOnly={isReadOnly}
        value={field.value}
        placeholder='Add a description'
        onChange={field.onChange}
      />
    </EventFormRowContainer>
  )
}
