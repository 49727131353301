import { classed } from '@motion/theme'
import { Checkbox } from '@motion/ui/forms'
import { type TaskSchema } from '@motion/zod/client'

import {
  ConnectedProjectBadge,
  ConnectedStatusBadge,
  PastDueBadge,
  PriorityBadge,
} from '~/global/components/badges'
import { DurationLabel } from '~/global/components/labels'

export type TaskRowProps = {
  task: TaskSchema
  selected: boolean
  onChecked(value: boolean): void
}
export const TaskRow = (props: TaskRowProps) => {
  const { task, selected, onChecked } = props

  return (
    <Row>
      <CheckboxRow checked={selected} onChange={onChecked}>
        <div className='flex items-center gap-2 whitespace-nowrap'>
          <ConnectedStatusBadge id={props.task.statusId} size='small' />
          <TaskName>{task.name}</TaskName>
        </div>
      </CheckboxRow>
      <DurationLabel value={task.duration} size='small' />
      <ConnectedProjectBadge id={task.projectId} size='small' />
      <PastDueBadge task={task} size='small' />
      <PriorityBadge value={task.priorityLevel} size='small' />
    </Row>
  )
}

const Row = classed('div', {
  base: `
    h-[36px]
    flex gap-2 items-center

    border-t border-button-neutral-outlined-border-disabled
    first:border-t-0
  `,
})

const CheckboxRow = classed(Checkbox, {
  base: `
    pl-2 pr-1 self-stretch flex-1 overflow-hidden
  `,
  variants: {
    leaf: {
      true: 'pl-[32px] hover:bg-button-neutral-outlined-bg-hover rounded pr-2',
    },
  },
  defaultVariants: {
    leaf: false,
  },
})

const TaskName = classed('span', {
  base: `text-xs flex-1`,
})
