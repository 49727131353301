import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import {
  getConferenceTypesForProviderType,
  type LimitedEventConferenceType,
} from '@motion/ui-logic'

import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useEventForm } from './hooks/use-event-form-hook'
import { useMyCalendarsWithEvent } from './hooks/use-my-calendars-with-event'

import { getHostCalendar } from '../../../../../components/Calendar/components/Sidebar/EventContainer/calendarEventUtils'
import {
  selectAllCalendars,
  selectEditableCalendars,
} from '../../../../../state/calendar-list/calendar-list-slice'
import { selectEmailAccountsMap } from '../../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../../state/hooks'

type EventFormOptionsContextType = {
  myCalendarsWithEvent: Calendar[]
  editableCalendars: Calendar[]
  hostEmailAccount: EmailAccount | undefined
  emailAccountsMap: Map<string, EmailAccount>
  hostCalendar: Calendar
  conferencingOptions: LimitedEventConferenceType[]
  isReadOnly: boolean
  isDeletable: boolean
  loading: boolean
  setLoading: (loading: boolean) => void
  otherCalendarEventIdByEmail?: {
    [email: string]: string
  }
}

const EventFormOptionsContext = createContext<
  EventFormOptionsContextType | undefined
>(undefined)

export const EventFormOptionsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false)
  const form = useEventForm()
  const { watch } = form
  const calendars = useAppSelector(selectAllCalendars)
  const editableCalendars = useAppSelector(selectEditableCalendars)
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const eventId = watch('id')
  const calendarId = watch('calendarId')
  const eventEmail = watch('email')
  const event = form.getValues()

  const hostEmailAccount = useMemo(
    () =>
      Array.from(emailAccountsMap.values()).find((e) => e.email === eventEmail),
    [emailAccountsMap, eventEmail]
  )
  const hostCalendar = getHostCalendar(
    { calendarId },
    calendars.filter((cal) => cal.emailAccountId === hostEmailAccount?.id)
  )

  const conferencingOptions = getConferenceTypesForProviderType(
    hostCalendar.providerType
  )

  const attendee =
    event.attendees?.find((a) => a.email === hostEmailAccount?.email) ?? null
  const isEventOrganizer =
    !event.attendees ||
    !event.attendees.length ||
    attendee?.isOrganizer ||
    event.organizerEmail === hostEmailAccount?.email

  const isCalendarEditable = hostCalendar.accessRole !== 'VIEWER'
  const isReadOnly =
    Boolean(eventId) && !(isEventOrganizer && isCalendarEditable)
  const isDeletable = isCalendarEditable

  const myCalendarsWithEvent = useMyCalendarsWithEvent(hostCalendar)

  const { otherCalendarEventIdByEmail } = event

  const api = useMemo(() => {
    return {
      otherCalendarEventIdByEmail,
      myCalendarsWithEvent,
      editableCalendars,
      hostEmailAccount,
      emailAccountsMap,
      hostCalendar,
      conferencingOptions,
      isReadOnly,
      isDeletable,
      loading,
      setLoading,
    }
  }, [
    otherCalendarEventIdByEmail,
    myCalendarsWithEvent,
    editableCalendars,
    hostEmailAccount,
    emailAccountsMap,
    hostCalendar,
    conferencingOptions,
    isReadOnly,
    isDeletable,
    loading,
    setLoading,
  ])

  return (
    <EventFormOptionsContext.Provider value={api}>
      {children}
    </EventFormOptionsContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const useEventFormOptions = (): EventFormOptionsContextType => {
  const context = useContext(EventFormOptionsContext)

  if (!context) {
    throw new Error(
      'useEventFormOptions must be used within a EventFormOptionsProvider'
    )
  }

  return context
}
