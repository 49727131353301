type ShrinkProjectLeftArgs = {
  amountToShrink: number
  initialStageLeft: number
  maxStageWidth: number
  stageLeft: number
}

/*
 * Given a stage and the delta to the left, determine how much it should shrink by (if at all)
 */
export function shrinkProjectLeft({
  amountToShrink,
  initialStageLeft,
  maxStageWidth,
  stageLeft,
}: ShrinkProjectLeftArgs) {
  // Being shrunk from the left
  // Stage maxWidths should be reduced by the delta amount if the stage left is passed (by how MUCH it passed the left by)
  // Stage lefts that are 'passed' (the left is less than the delta) should be set to 0
  if (initialStageLeft < Math.abs(amountToShrink)) {
    let amountToReduce = Math.abs(amountToShrink) - stageLeft
    return {
      maxStageWidth: maxStageWidth - amountToReduce,
      stageLeft: 0,
    }
  }

  return {
    stageLeft: stageLeft + amountToShrink,
    maxStageWidth,
  }
}
