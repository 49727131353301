import { FilledChevronDownSolid, XSolid } from '@motion/icons'
import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import {
  Button,
  IconButton,
  PopoverTrigger,
  SearchableList,
  UserAvatar,
} from '@motion/ui/base'
import {
  CalendarColorIcon,
  CalendarPalette,
  CalendarTitle,
  EventColorBarsGrid,
} from '@motion/ui/calendar'
import {
  colorIds,
  disabledGoogleEventColorIds,
  getColorClassForColorId,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { Events } from '~/analyticsEvents'
import React, { type PropsWithChildren } from 'react'

import { EventActionMenu } from './event-action-menu'
import { EventFormColorPicker } from './event-form-color-picker'
import { EventFormRowIconContainer } from './event-form-row-icon-container'
import { NameAndEmailLabel } from './name-and-email-label'

import { resolveCalendarId } from '../../../../../../utils/calendarUtils'
import { PeekModalHeader } from '../../common'
import { useClosePeekModal } from '../../hooks'
import { useEventFormOptions } from '../event-form-options-context'
import { useEventColorId, useEventForm, useEventHostField } from '../hooks'

export const EventFormHeader = () => {
  const closePeekModal = useClosePeekModal()
  const { value } = useEventColorId()
  const { watch } = useEventForm()

  const isCreated = Boolean(watch('id'))
  const {
    isDeletable,
    myCalendarsWithEvent,
    emailAccountsMap,
    hostEmailAccount,
    hostCalendar,
    isReadOnly,
  } = useEventFormOptions()

  const otherCalendars = myCalendarsWithEvent.filter(
    (calendar) => calendar.id !== hostCalendar.id
  )
  const hasOtherCalendars = otherCalendars.length > 0

  const enabledColors = React.useMemo(() => {
    if (
      isReadOnly ||
      // We only support changing specific event colors for google events
      hostCalendar.providerType !== 'GOOGLE'
    ) {
      // color changes not allowed
      return []
    }

    return colorIds.filter((c) => !disabledGoogleEventColorIds.includes(c))
  }, [hostCalendar.providerType, isReadOnly])

  const hostEmail = emailAccountsMap.get(hostCalendar.emailAccountId)?.email

  const isCalendarsDropdownEnabled = useHasTreatment('peek-calendars-dropdown')

  return (
    <CalendarPalette colorId={value}>
      <PeekModalHeader
        hasOtherCalendars={
          isCalendarsDropdownEnabled ? hasOtherCalendars : false
        }
      >
        {isCalendarsDropdownEnabled ? (
          <>
            {isCreated && hasOtherCalendars && (
              <EventColorBarsGrid
                otherCalendars={
                  otherCalendars.map(({ colorId }) => ({
                    colorId: getColorClassForColorId(colorId),
                  })) as CalendarPalette[]
                }
                size='small'
              />
            )}
            <MyCalendarsWithEventDropdown
              calendars={myCalendarsWithEvent}
              hostEmailAccount={hostEmailAccount}
              hostCalendar={hostCalendar}
              isCreated={isCreated}
            />
          </>
        ) : (
          <TitleWrapper className='pl-2'>
            <NameAndEmailLabel
              name={hostCalendar?.title ?? ''}
              email={hostEmail}
            />
          </TitleWrapper>
        )}

        <div className='shrink-0 flex items-center gap-px'>
          <EventFormColorPicker enabledColors={enabledColors} />
          {isDeletable && <EventActionMenu />}
          <IconButton
            icon={XSolid}
            onClick={closePeekModal}
            sentiment='neutral'
            variant='muted'
            size='xsmall'
          />
        </div>
      </PeekModalHeader>
    </CalendarPalette>
  )
}

type MyCalendarsWithEventDropdownProps = {
  calendars: Calendar[]
  hostCalendar: Calendar
  hostEmailAccount: EmailAccount | undefined
  isCreated: boolean
}

function MyCalendarsWithEventDropdown({
  calendars,
  hostCalendar,
  hostEmailAccount,
  isCreated,
}: MyCalendarsWithEventDropdownProps) {
  if (calendars.length === 0) {
    return null
  }

  if (calendars.length === 1 || !isCreated) {
    return <TitleWrapper className='pl-2'>{hostCalendar.title}</TitleWrapper>
  }

  return (
    <TitleWrapper className='pl-1'>
      <CalendarsDropdown>
        <Button size='xsmall' variant='muted' sentiment='neutral'>
          <div className='mr-1'>
            <UserAvatar
              profileUrl={hostEmailAccount?.profilePictureUrl}
              name={hostEmailAccount?.name}
              size={16}
            />
          </div>

          <CalendarTitle
            calendar={hostCalendar}
            email={hostEmailAccount?.email}
          />
          <FilledChevronDownSolid />
        </Button>
      </CalendarsDropdown>
    </TitleWrapper>
  )
}

type DropdownProps = PropsWithChildren

const CalendarsDropdown = ({ children }: DropdownProps) => {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => <CalendarsPopoverContent close={close} />}
      placement='bottom-start'
    >
      {children}
    </PopoverTrigger>
  )
}

const CalendarsPopoverContent = ({ close }: { close: () => void }) => {
  const { onChange } = useEventHostField()
  const { setValue } = useEventForm()

  const {
    otherCalendarEventIdByEmail,
    hostCalendar,
    emailAccountsMap,
    myCalendarsWithEvent,
  } = useEventFormOptions()

  const onSelectCalendar = (calendar: Calendar): void => {
    const email = emailAccountsMap.get(calendar.emailAccountId)?.email
    if (!email) return
    /**
     * If user switches calendars for the event via the dropdown,
     * we need to update the event id in the form to the distinct id of the event in the selected calendar.
     * Otherwise, changes to the event will be saved to the wrong calendar.
     */
    if (otherCalendarEventIdByEmail) {
      const eventIdFromSelectedCalendar = otherCalendarEventIdByEmail[email]

      if (eventIdFromSelectedCalendar) {
        setValue('id', eventIdFromSelectedCalendar)
      }
    }

    onChange(
      {
        email,
        calendarId: resolveCalendarId(calendar),
      },
      { shouldDirty: false }
    )
    recordAnalyticsEvent(Events.PEEK_MODAL_OPEN_EVENT_FROM_NON_MAIN_CALENDAR)

    close()
  }
  return (
    <CalendarsContainer>
      <Header>View event as</Header>

      <SearchableList
        searchable={false}
        computeKey={(calendar) => calendar.id}
        computeSelected={(calendar) => calendar.id === hostCalendar.id}
        items={myCalendarsWithEvent}
        onSelect={onSelectCalendar}
        renderItem={(calendar) => (
          <div className='flex items-center gap-1' key={calendar.id}>
            <EventFormRowIconContainer>
              <CalendarColorIcon
                colorId={getColorClassForColorId(calendar.colorId)}
                size='small'
              />
            </EventFormRowIconContainer>
            <CalendarTitle
              calendar={calendar}
              emailAccountsMap={emailAccountsMap}
            />
          </div>
        )}
      />
    </CalendarsContainer>
  )
}

const TitleWrapper = classed(
  'div',
  'grow line-clamp-1',
  '[&>button]:text-calendar-palette-text-default'
)

const CalendarsContainer = classed(
  'div',
  `
    bg-dropdown-bg
    border rounded border-field-border-default
  `
)

const Header = classed(
  'div',
  'whitespace-nowrap w-full bg-semantic-neutral-bg-subtle text-semantic-neutral-text-subtle',
  'px-3 py-1 gap-1',
  'text-xs font-semibold'
)
