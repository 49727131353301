import { usePrevious } from '@motion/react-core/hooks'

import { Attachments } from '~/areas/attachments'
import { UncontrolledCollapsableContainer } from '~/areas/task-project/components/collapsable-container'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useEffect, useState } from 'react'

import { AttachmentsTabGroupTitle } from './attachments-tab-group-title'

import { useFilterAttachments } from '../hooks/use-filter-attachments'

type AttachmentsTabTaskTargetItemProps = {
  task: NormalTaskWithRelations
  searchQuery?: string
}

export function AttachmentsTabTaskTargetItem({
  task,
  searchQuery,
}: AttachmentsTabTaskTargetItemProps) {
  const [expanded, setExpanded] = useState(false)

  const attachmentIds = task.uploadedFileIds || []

  const { filteredAttachmentIds } = useFilterAttachments({
    attachmentIds,
    query: searchQuery,
  })

  const prevSearchQuery = usePrevious(searchQuery)

  useEffect(() => {
    // Expand the collapsable list when the search query changes
    // and there are matching attachments
    if (searchQuery !== prevSearchQuery && filteredAttachmentIds.length > 0) {
      setExpanded(true)
    }
  }, [searchQuery, prevSearchQuery, filteredAttachmentIds])

  const handleToggle = () => setExpanded((prev) => !prev)

  if (!filteredAttachmentIds.length) return null

  return (
    <UncontrolledCollapsableContainer
      title={
        <AttachmentsTabGroupTitle
          title={task.name}
          task={task}
          attachmentCount={filteredAttachmentIds.length}
        />
      }
      initialExpanded={!!attachmentIds.length}
      chevronPosition='right'
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='px-3 pb-3'>
        <Attachments
          targetId={task.id}
          targetType='TEAM_TASK'
          attachmentIds={filteredAttachmentIds}
          variant='compact'
          emptyMessage={
            searchQuery != null &&
            searchQuery.length > 0 &&
            attachmentIds.length
              ? 'No matching attachments found.'
              : undefined
          }
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
