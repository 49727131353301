import { DotsVerticalSolid, UserSolid } from '@motion/icons'
import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { IconButton, Tooltip } from '@motion/ui/base'
import {
  CalendarCheckbox,
  CalendarPalette,
  CalendarTitle,
} from '@motion/ui/calendar'
import {
  type ColorClassId,
  colorClassIdToColorId,
  type ColorId,
  getColorClassForColorId,
} from '@motion/ui-logic'

import { CalendarColorDropdown } from '~/areas/calendar/components'
import { twMerge } from 'tailwind-merge'

import { type CheckboxMode } from '../calendar-list-pickers-types'

type Props = {
  /**
   * If enabled, will colorize the checkbox to match the colorId set on the
   * calendar object.
   */
  colorizeCheckbox?: boolean
  /**
   * Controls the rendering and interactivity of the calendar checkboxes
   */
  checkboxMode: CheckboxMode
  emailAccountsMap?: Map<string, EmailAccount>
  /**
   * Calendars to render
   */
  items: Calendar[]
  /**
   * The Main Email Account ID. This is used to prevent the primary calendar
   * that's in the My Calendars section from being removed.
   */
  mainEmailAccountId?: string
  /**
   * Fired when a user checks or unchecks a calendar
   * @param item
   * @param checked
   * @returns
   */
  onCalendarChecked: (item: Calendar, checked: boolean) => void
  /**
   * Fired when a user selects an action under the options menu
   * @param item
   * @param changes
   * @returns
   */
  onCalendarUpdated: (item: Calendar, changes: Partial<Calendar>) => void
  /**
   * Resolves the checked state of a given calendar
   * @param calendar
   * @returns
   */
  resolveCheckedState: (calendar: Calendar) => boolean
  /**
   * If enabled, each calendar item will have an options menu to the right
   * to configure colors/groupings
   */
  showOptions?: boolean
}

/**
 * Renders a list of selectable calendars.
 * @param param0
 * @returns
 */
export const FlatCalendarListPicker = ({
  checkboxMode,
  colorizeCheckbox,
  emailAccountsMap,
  items,
  mainEmailAccountId,
  onCalendarChecked,
  onCalendarUpdated,
  resolveCheckedState,
  showOptions,
}: Props) => {
  return (
    <>
      {items.map((item) => {
        const isCalendarPrimaryMainCalendar =
          item.emailAccountId === mainEmailAccountId && !!item.isPrimary
        const colorClass = getColorClassForColorId(item.colorId as ColorId, '3')
        return (
          <div
            className='group flex w-full items-center justify-between p-2 !pl-0'
            key={`${item.emailAccountId}${item.id}`}
          >
            <CalendarSidebarCheckbox
              colorClass={colorClass}
              colorizeCheckbox={colorizeCheckbox}
              checkboxMode={checkboxMode}
              disabled={isCalendarPrimaryMainCalendar}
              emailAccountsMap={emailAccountsMap}
              isChecked={resolveCheckedState(item)}
              item={item}
              onChecked={onCalendarChecked}
            />

            <div className='w-4 shrink-0'>
              {showOptions && (
                <CalendarOptions
                  colorClass={colorClass}
                  item={item}
                  mainEmailAccountId={mainEmailAccountId}
                  onUpdated={onCalendarUpdated}
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

type CheckboxProps = {
  checkboxMode: CheckboxMode
  colorClass: ColorId
  colorizeCheckbox?: boolean
  disabled?: boolean
  emailAccountsMap?: Map<string, EmailAccount>
  isChecked: boolean
  item: Calendar
  onChecked: (item: Calendar, checked: boolean) => void
}

/**
 * Handles rendering checkboxes. There are 2 modes of rendering checkboxes
 * - normal - A normal checkbox
 * - my-calendars - The checkbox is replaced with an icon, and is not checkable
 */
const CalendarSidebarCheckbox = ({
  checkboxMode,
  colorClass,
  colorizeCheckbox,
  disabled,
  emailAccountsMap,
  isChecked,
  item,
  onChecked,
}: CheckboxProps) => {
  const hideEmail = !emailAccountsMap
  return (
    <>
      {checkboxMode === 'my-calendars' && item.isInMyCalendars ? (
        <div className='flex min-w-0 items-center gap-2'>
          <Tooltip content='To hide this calendar, remove it from My Calendars.'>
            <CalendarPalette colorId={colorClass}>
              <div
                className={twMerge(
                  'flex h-4 w-4 items-center justify-center rounded bg-opacity-20',
                  colorizeCheckbox && 'bg-calendar-palette-bg-default',
                  colorizeCheckbox && 'text-calendar-palette-text-default'
                )}
              >
                <UserSolid className='h-3.5 w-3.5' />
              </div>
            </CalendarPalette>
          </Tooltip>
          <CalendarTitle
            calendar={item}
            emailAccountsMap={emailAccountsMap}
            hideEmail={hideEmail}
          />
        </div>
      ) : (
        <CalendarCheckbox
          colorId={colorClass}
          checked={isChecked}
          disabled={disabled}
          label={
            <CalendarTitle
              calendar={item}
              emailAccountsMap={emailAccountsMap}
              hideEmail={hideEmail}
            />
          }
          onChange={(checked) => onChecked(item, checked)}
        />
      )}
    </>
  )
}

type CalendarOptionsProps = {
  colorClass: ColorClassId
  item: Calendar
  mainEmailAccountId?: string
  onUpdated: (item: Calendar, changes: Partial<Calendar>) => void
}

/**
 * Renders a menu dropdown allowing the user to configure the default calendar
 * colors, as well as control whether the calendar is in "My Calendars" or
 * "Frequently Met With"
 * @returns
 */
const CalendarOptions = ({
  colorClass,
  item,
  mainEmailAccountId,
  onUpdated,
}: CalendarOptionsProps) => {
  const isCalendarPrimaryMainCalendar =
    item.emailAccountId === mainEmailAccountId && !!item.isPrimary

  return (
    <CalendarColorDropdown
      customOptions={[
        !item.isInMyCalendars && {
          onClick: () => onUpdated(item, { isInMyCalendars: true }),
          text: 'Add to "My Calendars"',
        },
        item.isInMyCalendars &&
          !isCalendarPrimaryMainCalendar && {
            onClick: () => onUpdated(item, { isInMyCalendars: false }),
            text: 'Remove from "My Calendars"',
          },
        !item.isInFrequentlyMet &&
          !item.isPrimary && {
            onClick: () => onUpdated(item, { isInFrequentlyMet: true }),
            text: 'Add to "Frequently met with"',
          },
        item.isInFrequentlyMet && {
          onClick: () => onUpdated(item, { isInFrequentlyMet: false }),
          text: 'Remove from "Frequently met with"',
        },
      ]}
      currentColorClass={colorClass}
      colorsToHide={item.isInMyCalendars ? ['11'] : []}
      onChange={(colorClass) =>
        onUpdated(item, { colorId: colorClassIdToColorId[colorClass] })
      }
    >
      <div className='w-0 h-4 overflow-hidden group-hover:w-4'>
        <IconButton icon={DotsVerticalSolid} />
      </div>
    </CalendarColorDropdown>
  )
}
