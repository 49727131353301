import { useI18N } from '~/global/contexts'

export type WorkspaceSectionTitleProps = {
  showProject?: boolean
  showStage?: boolean
}

export const WorkspaceSectionTitle = ({
  showProject = false,
  showStage = false,
}: WorkspaceSectionTitleProps) => {
  const { formatList } = useI18N()

  return (
    <h2 className='text-semantic-neutral-text-subtle text-2xs pb-1 px-1'>
      {formatList(
        ['Workspace', showProject && 'Project', showStage && 'Stage'].filter(
          Boolean
        ),
        'conjunction'
      )}
    </h2>
  )
}
