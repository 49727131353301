import { useMyTasksWorkspace } from '~/global/hooks'
import { useEffect } from 'react'
import {
  type FieldValues,
  type Path,
  type UseFormSetValue,
} from 'react-hook-form'

type UseApplySharedValuesProps<T extends FieldValues> = {
  enabled: boolean
  sharedValues?: Partial<T>
  setValue: UseFormSetValue<T>
}

export const useApplySharedValues = <T extends FieldValues>(
  props: UseApplySharedValuesProps<T>
) => {
  const { enabled, sharedValues, setValue = () => {} } = props
  const defaultWorkspace = useMyTasksWorkspace()

  let workspaceId: string | undefined
  if (defaultWorkspace != null) {
    workspaceId = defaultWorkspace.id
  }

  useEffect(() => {
    if (!enabled || !sharedValues) return
    // We need to delay setting the fields by one frame so that the form detects that it's dirty
    const animationFrameId = requestAnimationFrame(() => {
      for (const key in sharedValues) {
        const typedKey = key as unknown as Path<T>
        const value = sharedValues[typedKey]

        if (workspaceId) {
          // @ts-expect-error - Typescript 5.4
          setValue('workspaceId' as Path<T>, workspaceId, { shouldDirty: true })
        }

        if (value !== undefined) {
          setValue(typedKey, value, { shouldDirty: true })
        }
      }
    })
    return () => cancelAnimationFrame(animationFrameId)
  }, [enabled, setValue, sharedValues, workspaceId])
}
