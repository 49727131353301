import { twMerge } from 'tailwind-merge'

interface ParagraphProps {
  className?: string
}

export const Paragraph = ({
  className = '',
  children,
}: ParagraphProps & JSX.IntrinsicElements['p']) => {
  const colorClasses = 'text-light-1200 dark:text-dark-100'

  return <p className={twMerge('!mb-0', colorClasses, className)}>{children}</p>
}
