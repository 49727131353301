import { useThrottledCallback } from '@motion/react-core/hooks'
import {
  findStageForTask,
  type FlowTemplateFormFields,
  type FlowTemplateStage,
  moveTaskInStages,
} from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  type DragOverEvent,
  type DragStartEvent,
  type UniqueIdentifier,
} from '@dnd-kit/core'
import { useCallback, useRef } from 'react'
import { type UseFieldArrayReplace } from 'react-hook-form'

import { useFlowTemplateForm } from '../../../../hooks'
import { useWarnAndRemoveTemplateBlockersFn } from '../../hooks'

type TemplateDndHooksArgs = {
  setActiveId: (id: UniqueIdentifier | null) => void
  replace: UseFieldArrayReplace<FlowTemplateFormFields, 'stages'>
}

export const useTemplateDndHandlerHooks = ({
  setActiveId,
  replace,
}: TemplateDndHooksArgs) => {
  const warnAndRemoveTemplateBlockers = useWarnAndRemoveTemplateBlockersFn()
  const {
    form: { watch, clearErrors },
  } = useFlowTemplateForm()
  const stages = watch('stages')

  const previousStages = useRef<FlowTemplateStage[] | null>(null)

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      if (previousStages.current == null) {
        previousStages.current = stages
      }

      setActiveId(event.active.id)
      clearErrors('stages')
    },
    [setActiveId, clearErrors, stages]
  )

  const onDragOver = useThrottledCallback(
    ({ active, over }: DragOverEvent) => {
      const fromStage = findStageForTask(stages, active.id as string)
      const toStage = over?.id
        ? findStageForTask(stages, over.id as string)
        : undefined
      if (!fromStage || !toStage) {
        return
      }

      const toStageIndex = stages.indexOf(toStage)
      if (toStageIndex < 0) {
        return
      }

      const fromTaskIndex = fromStage.tasks.findIndex(
        (task) => task.id === active.id
      )
      const toTaskIndex = toStage.tasks.findIndex(
        (task) => task.id === over?.id
      )
      const parsedToTaskIndex =
        toTaskIndex === -1 ? toStage.tasks.length : toTaskIndex

      if (fromTaskIndex < 0) {
        return
      }

      const activeTask = fromStage.tasks[fromTaskIndex]

      const updatedStages = moveTaskInStages(
        activeTask,
        stages,
        parsedToTaskIndex,
        toStageIndex
      )

      replace(updatedStages)
    },
    [replace, stages],
    100
  )

  const onDragCancel = useCallback(() => {
    setActiveId(null)
    if (previousStages.current == null) return

    replace(previousStages.current)
    previousStages.current = null
  }, [replace, setActiveId])

  const onDragEnd = useCallback(
    // Can't use over, it's the same as active
    async ({ active }: DragOverEvent) => {
      await warnAndRemoveTemplateBlockers({
        previousStages: previousStages.current ?? [],
        activeTaskId: active.id as string,
        stages,
        replace,
      })

      recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
        type: 'move',
        method: 'drag',
      })

      previousStages.current = null

      // Delay clearing the activeId to allow for the drop animation to finish and avoid flickering
      setTimeout(() => {
        setActiveId(null)
      }, 300)
    },
    [replace, setActiveId, stages, warnAndRemoveTemplateBlockers]
  )

  return {
    onDragStart,
    onDragOver,
    onDragCancel,
    onDragEnd,
  }
}
