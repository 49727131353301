import { type StageSchema } from '@motion/zod/client'

import { growProjectLeft } from './grow-project-left'
import { growProjectRight } from './grow-project-right'
import { shrinkProjectLeft } from './shrink-project-left'
import { shrinkProjectRight } from './shrink-project-right'

import { type Side } from '../../../resize-handle'
import { isFirstStage } from '../is-first-stage'
import { isLastStage } from '../is-last-stage'

type GetStageLocationProjectResizeArgs = {
  stage: StageSchema
  projectStages: StageSchema[]
  projectWidth: number
  projectDeltaWidth: number
  currentSide?: Side
  initialStageLeft: number
  initialMaxStageWidth: number
}
/**
 * @deprecated
 */
export const deprecatedGetStageLocationProjectResize = ({
  stage,
  projectStages,
  projectDeltaWidth,
  projectWidth,
  currentSide,
  initialStageLeft,
  initialMaxStageWidth,
}: GetStageLocationProjectResizeArgs) => {
  let stageLeft = initialStageLeft
  let maxStageLeft = projectWidth
  let maxStageWidth = initialMaxStageWidth

  if (projectDeltaWidth === 0) {
    return {
      stageLeft,
      maxStageWidth,
      maxStageLeft,
    }
  }

  const isShrinking = projectDeltaWidth < 0
  if (currentSide === 'left') {
    if (isShrinking) {
      let shrinkProjectResult = shrinkProjectLeft({
        amountToShrink: projectDeltaWidth,
        initialStageLeft,
        maxStageWidth,
        stageLeft,
      })

      return {
        ...shrinkProjectResult,
        maxStageLeft,
      }
    }

    return growProjectLeft({
      isFirstStage: isFirstStage(stage.id, projectStages),
      amountToGrow: projectDeltaWidth,
      maxStageWidth,
      stageLeft,
      maxStageLeft,
    })
  } else if (currentSide === 'right') {
    if (isShrinking) {
      return shrinkProjectRight({
        stageLeft,
        maxStageWidth,
        maxStageLeft,
        projectWidth,
        projectDeltaWidth,
      })
    }

    return growProjectRight({
      delta: projectDeltaWidth,
      stageLeft,
      maxStageWidth,
      maxStageLeft,
      isLastStage: isLastStage(stage.id, projectStages),
    })
  }

  return {
    stageLeft,
    maxStageWidth,
    maxStageLeft,
  }
}
