import { selectAllCalendars } from '~/state/calendar-list/calendar-list-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import { useViewState } from '../../view-state'

export function useValidProviderIds() {
  const [viewState] = useViewState()

  const allCalendars = useAppSelector(selectAllCalendars)
  const { providerIds = [] } = viewState

  return useMemo(
    () =>
      providerIds.filter((providerId) => {
        return allCalendars.some(
          (calendar) => calendar.providerId === providerId
        )
      }),
    [allCalendars, providerIds]
  )
}
