import './types'

import { useSharedState } from '@motion/react-core/shared-state'
import { classed } from '@motion/theme'
import { BaseModal, Button } from '@motion/ui/base'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic'
import { UserLoginStateKey } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import { type TaskSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'
import { useState } from 'react'

import { useRescheduleTasks, useStaleTasks } from './rpc'
import { TaskList } from './task-list'

export const StaleTasksModalTrigger = () => {
  const [loginState, setLoginState] = useSharedState(UserLoginStateKey)
  const isEnabled = useHasTreatment('reschedule-stale-tasks-modal')
  if (!isEnabled || !loginState.hasOldAutoScheduledTasks) return null

  return (
    <ConnectedStaleTasksModal
      close={() => {
        setLoginState((prev) => ({ ...prev, hasOldAutoScheduledTasks: false }))
      }}
    />
  )
}

type ConnectedStaleTasksModalProps = {
  close(): void
}
export const ConnectedStaleTasksModal = (
  props: ConnectedStaleTasksModalProps
) => {
  const get = useStaleTasks()
  const update = useRescheduleTasks()

  if (get.status === 'loading') {
    return null
  }

  if (get.status === 'error') {
    props.close()
    return null
  }

  return (
    <StaleTasksModal
      close={props.close}
      tasks={get.data}
      onSubmit={(data) => {
        return update.mutateAsync(data).finally(() => props.close())
      }}
    />
  )
}

type StaleTasksModalProps = {
  close(): void

  tasks: TaskSchema[]

  onSubmit(data: {
    taskIdsToReschedule: string[]
    taskIdsToStopScheduling: string[]
  }): Promise<void>
}

export const StaleTasksModal = (props: StaleTasksModalProps) => {
  const [busy, setBusy] = useState(false)
  const [selected, setSelected] = useState<TaskSchema[]>([])
  const dueDate = DateTime.now().endOf('day').plus({ day: 14 })

  return (
    <BaseModal
      disableEscapeKey
      disableOverlayClick
      visible
      onClose={props.close}
    >
      <Container>
        <FormHeader tasks={props.tasks.length} />
        <Content>
          <ActionButtons>
            <Button
              size='small'
              sentiment='neutral'
              variant='outlined'
              onClick={() => setSelected(props.tasks)}
            >
              Select all
            </Button>
            <Button
              size='small'
              sentiment='neutral'
              variant='outlined'
              onClick={() => setSelected([])}
            >
              Deselect all
            </Button>
          </ActionButtons>
          <TaskList
            tasks={props.tasks}
            selected={selected}
            onChange={setSelected}
          />
        </Content>
        <Instructions>
          <p>
            Unchecked tasks will not be deleted, auto-schedule will be turned
            off
          </p>
          <p>
            Auto-rescheduled tasks will have a new due date of{' '}
            {formatToReadableWeekDayMonth(dueDate)}
          </p>
        </Instructions>
        <Footer>
          <Button
            loading={busy}
            onClick={() => {
              setBusy(true)
              void props
                .onSubmit({
                  taskIdsToReschedule: selected.map((x) => x.id),
                  taskIdsToStopScheduling: props.tasks
                    .filter((t) => !selected.includes(t))
                    .map((x) => x.id),
                })
                .finally(() => setBusy(false))
            }}
          >
            Auto-reschedule {selected.length} tasks
          </Button>
        </Footer>
      </Container>
    </BaseModal>
  )
}

type FormHeaderProps = {
  tasks: number
}
const FormHeader = (props: FormHeaderProps) => {
  return (
    <FormHeaderContainer>
      <HeaderTitle>Welcome back!</HeaderTitle>
      <p>
        You&apos;ve been inactive for more than 14 days, and there&apos;s{' '}
        {props.tasks} tasks stuck in the past.
      </p>
      <p>Would you like to auto-reschedule these tasks?</p>
    </FormHeaderContainer>
  )
}

const Content = classed('div', {
  base: `
  flex flex-col gap-2 px-4
`,
})

const ActionButtons = classed('div', {
  base: `
    flex gap-2
  `,
})

const Container = classed('div', {
  base: `
    bg-modal-bg
    rounded border-modal-border
    text-semantic-neutral-text-default
  `,
})

const FormHeaderContainer = classed('div', {
  base: `
    flex flex-col gap-3
    text-left justify-start
    [&>p]:text-sm [&>p]:font-normal

    px-4 py-4
    `,
})

const HeaderTitle = classed('div', {
  base: `font-semibold text-[16px]`,
})

const Instructions = classed('div', {
  base: `
    flex flex-col gap-2
    py-3 px-4
    text-xs
    bg-semantic-neutral-bg-disabled
    text-semantic-neutral-text-subtle
  `,
})

const Footer = classed('div', {
  base: `
    px-4 py-4 flex justify-end
    border-t-modal-border
  `,
})
