import { ChevronDownSolid, PencilSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'
import { CUSTOM_SCHEDULE_ID } from '@motion/ui-logic/pm/task'
import { useModalApi } from '@motion/web-common/modals'

import { AvailabilityTimePickerModal } from '~/components/Booking/components/Modals/AvailabilityTimePickerModal/AvailabilityTimePickerModal'
import { formatRange } from '~/components/Booking/components/template-form/template-form.utils'
import { ScheduleDropdown } from '~/global/components/dropdowns'
import { useTimezoneSettings } from '~/global/hooks'
import { useAppSelector } from '~/state/hooks'
import { selectSchedules } from '~/state/settingsSlice'
import { Days } from '~/types/settingsTypes'
import { getTzAbbr } from '~/utils'
import { useCallback, useContext, useState } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const AvailabilityScheduleRow = () => {
  const {
    template: { state, setters },
  } = useContext(BookingTemplateContext)
  const modalApi = useModalApi()
  const { defaultTimezone } = useTimezoneSettings({ useDefaults: true })
  const schedules = useAppSelector(selectSchedules)
  const [timepickerVisible, setTimepickerVisible] = useState(false)

  const isSelectedScheduleCustom = state.scheduleId === CUSTOM_SCHEDULE_ID
  const selectedScheduleName = isSelectedScheduleCustom
    ? 'Custom'
    : (schedules[state.scheduleId].title ?? '')

  const isSelectedTimezoneDefault = state.customScheduleTimezone === 'default'
  const selectedTimezone = isSelectedTimezoneDefault
    ? defaultTimezone
    : state.customScheduleTimezone
  const selectedTimezoneAbbr = getTzAbbr(selectedTimezone)

  const openTimezoneModal = useCallback(() => {
    modalApi.prompt('timezone-picker', {
      onTimezoneChange: (tz: string | null) =>
        void setters.setCustomScheduleTimezone(tz),
      showDefault: true,
    })
  }, [modalApi, setters])

  return (
    <>
      <SectionRow
        title='Schedule'
        subtitle={
          "Choose when guests can book meetings. We'll also check for calendar conflicts on top of this (through Attendance requirements below)"
        }
      >
        <div className='[&>label]:inline-block space-y-4'>
          <FieldLabel label='Schedule'>
            <ScheduleDropdown
              selectedScheduleId={state.scheduleId}
              onChange={(id) => void setters.setScheduleId(id)}
              showCustomSchedule
            >
              <div className='[&>button]:min-w-[250px]'>
                <Button variant='outlined' sentiment='neutral'>
                  <div className='flex flex-row items-center justify-between gap-2 w-full'>
                    <div className='text-semantic-neutral-text-default text-sm font-normal text-left'>
                      {selectedScheduleName}
                    </div>
                    <ChevronDownSolid />
                  </div>
                </Button>
              </div>
            </ScheduleDropdown>
          </FieldLabel>

          {isSelectedScheduleCustom && (
            <>
              <div className='overflow-hidden rounded border-b border-semantic-neutral-surface-bg-subtle'>
                <table className='w-full table-fixed border-collapse border-x [&_*]:border-semantic-neutral-surface-bg-subtle'>
                  <tr className='bg-semantic-neutral-surface-bg-subtle text-semantic-neutral-text-default text-xs'>
                    {Days.map((day) => (
                      <th key={`custom-table-${day}`} className='py-0.5'>
                        {day.slice(0, 3).toUpperCase()}
                      </th>
                    ))}
                  </tr>

                  <tr className='[&>td]:border-x [&>td]:text-[10px] [&>td]:text-center [&>td]:py-2 [&>td]:align-top'>
                    {Days.map((day) => {
                      const key = `timepicker-${day}`

                      if (!state.timepickerDays[day])
                        return <td key={key}>&nbsp;</td>

                      return (
                        <td key={key}>
                          {state.timepickerDays[day].map((rangeObj) => (
                            <div
                              key={`${day}-${rangeObj}`}
                              className={
                                rangeObj.preferred ? 'text-primary-400' : ''
                              }
                            >
                              {formatRange(rangeObj.range)}
                            </div>
                          ))}
                        </td>
                      )
                    })}
                  </tr>
                </table>
              </div>

              <div className='flex justify-end'>
                <Button
                  variant='muted'
                  sentiment='neutral'
                  size='small'
                  onClick={() => void setTimepickerVisible(true)}
                >
                  <PencilSolid className='size-4' />{' '}
                  <span>Edit custom schedule</span>
                </Button>
              </div>

              <div className='flex flex-row items-center justify-between'>
                <span className='text-semantic-neutral-text-subtle text-xs'>
                  Timezone
                </span>
                <Button
                  variant='outlined'
                  sentiment='neutral'
                  size='small'
                  onClick={openTimezoneModal}
                >
                  {isSelectedTimezoneDefault
                    ? `Use Default Timezone (${selectedTimezoneAbbr})`
                    : selectedTimezoneAbbr}
                </Button>
              </div>
            </>
          )}
        </div>
      </SectionRow>

      {timepickerVisible && (
        <AvailabilityTimePickerModal
          visibleHandler={setTimepickerVisible}
          days={state.timepickerDays}
          daysHandler={setters.setTimepickerDays ?? (() => void 0)}
          customScheduleTimezone={state.customScheduleTimezone}
          customScheduleTimezoneHandler={
            setters.setCustomScheduleTimezone ?? (() => void 0)
          }
          openTimezoneModal={openTimezoneModal}
          visible // Force changes to be discarded on close with conditional render
        />
      )}
    </>
  )
}
