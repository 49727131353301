import { RefreshOutline } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { isGhostTask } from '@motion/ui-logic/pm/project'

import { StatusBadge } from '~/global/components/badges'
import { GhostStatusDescription } from '~/global/components/tooltips'
import { useWorkspaceStatusById } from '~/global/hooks'
import { type TaskWithRelations } from '~/global/proxies'
import React from 'react'

import { RowCheckbox, RowContainer, ScheduleReason } from './common'
import { ActionsContainer } from './common/actions-container'
import { Details, TaskTextArea, TaskTitle, TaskTitleBar } from './styles'

interface ScheduledAfterDeadlineRowProps {
  task: TaskWithRelations
}

export const ScheduledAfterDeadlineRow = ({
  task,
}: ScheduledAfterDeadlineRowProps) => {
  const workspaceStatus = useWorkspaceStatusById(task.statusId)
  const isGhost = isGhostTask(task)
  const isRecurring = task.type === 'RECURRING_INSTANCE'

  return (
    <RowContainer
      type='NORMAL'
      taskId={task.id}
      actions={<ActionsContainer type='NORMAL' tasks={[task]} />}
    >
      <Tooltip
        asChild
        renderContent={isGhost ? () => <GhostStatusDescription /> : null}
      >
        <Details>
          <RowCheckbox tasks={[task]} type='RECURRING_PARENT' />
          {workspaceStatus && (
            <div className='-mt-[5px]'>
              <StatusBadge
                value={workspaceStatus}
                iconVariant={isGhost ? 'isUnvisitedStage' : undefined}
                hideTooltip={isGhost}
              />
            </div>
          )}
          <TaskTextArea>
            <TaskTitleBar>
              <TaskTitle>{task.name}</TaskTitle>
              {isRecurring ? (
                <RefreshOutline
                  className='text-semantic-neutral-icon-default shrink-0'
                  width={14}
                  height={14}
                />
              ) : null}
            </TaskTitleBar>
            <ScheduleReason task={task} />
          </TaskTextArea>
        </Details>
      </Tooltip>
    </RowContainer>
  )
}
