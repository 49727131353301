import { Button } from '@motion/ui/base'

import { type CommonButtonProps } from './type'

import { useTasksLoadingState, useUpdateTasksWarnings } from '../hooks'

export const IgnoreWarningButton = ({ tasks, type }: CommonButtonProps) => {
  const [isLoading, setIsLoading] = useTasksLoadingState(tasks)
  const updateTasksWarnings = useUpdateTasksWarnings({
    tasks,
    type,
    setIsLoading,
  })

  return (
    <Button
      disabled={isLoading}
      variant='outlined'
      sentiment='neutral'
      size='small'
      onClick={updateTasksWarnings}
    >
      Ignore warning
    </Button>
  )
}
