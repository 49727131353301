import { useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ComponentRef, forwardRef } from 'react'

import { OOOHeaderRow } from './ooo-header-row'

import { TabHeader } from '../../../components'
import { ExpandButton } from '../../../tree-list/cells/components'
import { ExpandAndCollapseMenu } from '../../../tree-list/components'
import { useOOOEventsForRowFn } from '../../hooks'
import { ZIndexMap } from '../../shared-constants'
import { type PlannerRowProps, type StyledPlannerRow } from '../common'
import { StyledPlannerRowContainer } from '../container'

export const HeaderRow = forwardRef<
  ComponentRef<typeof StyledPlannerRow>,
  PlannerRowProps<any> & {
    'data-index': number
    toggleExpandAllRows: (expand: boolean) => void
    eventHeight?: number
  }
>(function PlannerRow(
  { row, toggleExpandAllRows, eventHeight, visible, ...props },
  ref
) {
  const getOOOEventsForRow = useOOOEventsForRowFn()

  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_GROUP_RIGHT_CLICK', {
        depth: row?.depth ?? 0,
      })
    },
  })

  if (!row) return null

  const oooEvents = getOOOEventsForRow(row)
  return (
    <>
      <div className='flex flex-col' data-index={props['data-index']} ref={ref}>
        <StyledPlannerRowContainer
          role='header'
          {...props}
          expandable
          onContextMenu={handleContextMenu}
          style={{ zIndex: ZIndexMap.headerRow, ...props.style }}
        >
          <div className='overflow-hidden flex gap-3 items-center relative px-2.5'>
            <ExpandButton row={row} />
            <TabHeader item={{ ...row.original, item: row.original }} active />
          </div>
        </StyledPlannerRowContainer>
        {oooEvents.length > 0 ? (
          <OOOHeaderRow events={oooEvents} eventHeight={eventHeight} />
        ) : null}
      </div>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ExpandAndCollapseMenu
            close={close}
            onCollapse={() => {
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_PLANNER_GROUP_RIGHT_CLICK_ACTION',
                {
                  depth: row?.depth ?? 0,
                  action: 'collapse-all',
                }
              )
              toggleExpandAllRows(false)
            }}
            onExpand={() => {
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_PLANNER_GROUP_RIGHT_CLICK_ACTION',
                {
                  depth: row?.depth ?? 0,
                  action: 'expand-all',
                }
              )
              toggleExpandAllRows(true)
            }}
          />
        )}
      />
    </>
  )
})
