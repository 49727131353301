import {
  type CalendarEventAttendeeSchemaV2,
  type ScheduleCollection,
} from '@motion/rpc-types'
import { type CustomFieldValuesSchema } from '@motion/shared/custom-fields'
import {
  type AllAvailableCustomFieldSchema,
  formatDurationToShort,
  formatToReadableWeekDayMonth,
  formatToShortTaskDuration,
  getTaskScheduledDateString,
  getTaskStaticURL,
  isCustomFieldKey,
  parseCustomFieldInfoFromMaybeDelimitedKey,
  priorityLabels,
  templateStr,
  type WorkspaceMemberWithUser,
} from '@motion/ui-logic'
import {
  getDeadlineText,
  getStartDateText,
  isMeetingTask,
} from '@motion/ui-logic/pm/task'
import { createLookup } from '@motion/utils/object'

import { getRsvpLabel } from '~/areas/event/utils'
import {
  type CustomFieldWithValue,
  type TaskWithRelations,
} from '~/global/proxies'

import {
  PROJECT_FIELDS,
  type ProjectFieldId,
  TASK_FIELDS,
  type TaskFieldId,
} from '../../fields'

export function getColumnName(column: string): string {
  if (isCustomFieldKey(column)) {
    return parseCustomFieldInfoFromMaybeDelimitedKey(column)?.name ?? 'unknown'
  }
  if (column in TASK_FIELDS) {
    const col = column as TaskFieldId
    return TASK_FIELDS[col].name
  }
  if (column in PROJECT_FIELDS) {
    const col = column as ProjectFieldId
    return PROJECT_FIELDS[col].name
  }

  return 'unknown'
}

export const taskFieldLookup = createLookup<
  Record<
    TaskFieldId,
    (
      item: TaskWithRelations,
      { schedules, asHtml }: { schedules: ScheduleCollection; asHtml?: boolean }
    ) => string
  > & {
    default: () => string
  }
>({
  name: (item, { asHtml }) => {
    const taskURL = getTaskStaticURL({
      workspaceId: item.workspaceId as string,
      taskId: item.id as string,
    })
    if (asHtml) return `<a href="${taskURL}">${item.name}</td>`
    return `[${item.name}](${taskURL})`
  },
  deadline: (item) => getDeadlineText(item),
  assignee: (item) => item.assignee?.name ?? 'Unassigned',
  stage: (item) =>
    item.type === 'NORMAL' ? (item.stageDefinition?.name ?? '') : '',
  status: (item) => item.status.name,
  priority: (item) => priorityLabels.get(item.priorityLevel) ?? 'unknown',
  project: (item) => item.project?.name ?? 'No Project',
  workspace: (item) => item.workspace.name,
  labels: (item) =>
    item.type === 'NORMAL'
      ? item.labels.map((label) => label.name).join(', ')
      : 'None',
  duration: (item) => formatToShortTaskDuration(item.duration),
  completedDuration: (item) =>
    item.type === 'NORMAL' ? formatDurationToShort(item.completedDuration) : '',
  startDate: (item) => getStartDateText(item),
  blockedBy: (item) =>
    item.type === 'NORMAL' && item.blockedByTaskIds.length > 0
      ? String(item.blockedByTaskIds.length)
      : '',
  blocking: (item) =>
    item.type === 'NORMAL' && item.blockingTaskIds.length > 0
      ? String(item.blockingTaskIds.length)
      : '',
  schedule: (item, { schedules }) =>
    item.scheduleId ? (schedules[item.scheduleId]?.title ?? '') : '',
  createdAt: (item) => formatToReadableWeekDayMonth(item.createdTime),
  updatedAt: (item) =>
    item.lastInteractedTime != null
      ? formatToReadableWeekDayMonth(item.lastInteractedTime)
      : '',
  completedAt: (item) =>
    item.completedTime != null
      ? formatToReadableWeekDayMonth(item.completedTime)
      : '',
  createdBy: (item) => item.createdByUser.name,
  estimatedCompletionTime: (item) => getTaskScheduledDateString(item),
  guests: (item) =>
    isMeetingTask(item) && item.meetingEvent != null
      ? item.meetingEvent.attendees.map(getGuestText).join(', ')
      : '',
  folder: (item) => item.project?.name ?? 'No folder',
  default: () => 'unknown',
})

type CustomFieldMapper = {
  [P in CustomFieldValuesSchema['type']]: (
    value: Extract<CustomFieldWithValue, { type: P }>,
    options: {
      definition: Extract<
        AllAvailableCustomFieldSchema,
        {
          type: P
        }
      >
      users: WorkspaceMemberWithUser[]
    }
  ) => string
}

export const customFieldLookup = createLookup<
  CustomFieldMapper & {
    default: () => string
  }
>({
  number: ({ value }) => (value ? value.toString() : ''),
  date: ({ value }) => (value ? formatToReadableWeekDayMonth(value) : ''),
  text: ({ value }) => (value ? value : ''),
  url: ({ value }) => (value ? value : ''),
  person: ({ value }, { users }) =>
    value
      ? (users.find((u) => u.userId === value)?.user.name ?? 'unknown')
      : '',
  multiPerson: ({ value }, { users }) =>
    value
      ? value
          .map((id) => users.find((u) => u.userId === id)?.user.name)
          .filter(Boolean)
          .join(', ')
      : '',
  select: ({ value }, { definition }) =>
    definition.metadata.options.find((opt) => opt.id === value)?.value ?? '',
  multiSelect: ({ value }, { definition }) =>
    value
      ? value
          .map(
            (id) =>
              definition.metadata.options.find((opt) => opt.id === id)?.value
          )
          .filter(Boolean)
          .join(', ')
      : '',
  default: () => 'unknown',
})

function getGuestText(guest: CalendarEventAttendeeSchemaV2) {
  return templateStr('{{email}}{{name}}- {{status}}', {
    name: guest.displayName ? ` (${guest.displayName})` : '',
    email: guest.email,
    status: getRsvpLabel(guest.status ?? 'needsAction'),
  })
}
