import {
  convertFormStagesToStageDefinition,
  type FlowTemplateFormFields,
} from '@motion/ui-logic/pm/project'
import {
  type CreateProjectDefinitionRequestSchema,
  type UpdateProjectDefinitionRequestSchema,
} from '@motion/zod/client'

import { useCallback } from 'react'

import { useFlowTemplateForm } from './use-flow-template-form'
import { useFlowTemplateModalUrlParams } from './use-flows-template-modal-url'

import {
  useCreateProjectDefinition,
  useUpdateProjectDefinition,
} from '../../hooks'
import { recordPresetAnalytics } from '../presets/utils'
import { DEFAULT_TEXT_VARIABLE_KEYS } from '../utils'

export function useSaveFlow() {
  const { form } = useFlowTemplateForm()
  const updateProjectDefinition = useUpdateProjectDefinition()
  const createProjectDefinition = useCreateProjectDefinition()
  const { template: templateOperationParam, fromPresetId } =
    useFlowTemplateModalUrlParams()

  const flowTemplateId = form.watch('id')
  const workspaceId = form.watch('workspaceId')

  const isCreatingFromPreset =
    templateOperationParam === 'new' && fromPresetId != null

  return useCallback(
    async (fields: FlowTemplateFormFields) => {
      if (workspaceId == null) {
        throw new Error('Workspace id not defined')
      }

      if (flowTemplateId != null) {
        const updates = convertFormFieldsForUpdate(fields)

        return updateProjectDefinition(flowTemplateId, workspaceId, updates)
      }
      if (isCreatingFromPreset) {
        recordPresetAnalytics(fromPresetId, fields)
      }

      const createPayload = convertFieldsForCreate(fields)

      return createProjectDefinition(createPayload)
    },
    [
      createProjectDefinition,
      flowTemplateId,
      fromPresetId,
      isCreatingFromPreset,
      updateProjectDefinition,
      workspaceId,
    ]
  )
}

function convertFieldsForCreate(
  fields: FlowTemplateFormFields
): CreateProjectDefinitionRequestSchema {
  return {
    definition: {
      description: fields.description,
      labelIds: fields.labelIds,
      managerId: fields.managerId,
      name: fields.name,
      priorityLevel: fields.priorityLevel,
      stages: convertFormStagesToStageDefinition(fields.stages),
      variables: [...fields.textVariables, ...fields.roles].filter(
        (v) => !DEFAULT_TEXT_VARIABLE_KEYS.includes(v.key)
      ),
      workspaceId: fields.workspaceId,
      color: fields.color,
    },
  }
}

// Unlike other update calls, this is a PUT & requires the full definition to be sent
function convertFormFieldsForUpdate(
  fields: FlowTemplateFormFields
): UpdateProjectDefinitionRequestSchema {
  return {
    definition: {
      description: fields.description,
      labelIds: fields.labelIds,
      managerId: fields.managerId,
      name: fields.name,
      priorityLevel: fields.priorityLevel,
      stages: convertFormStagesToStageDefinition(fields.stages),
      variables: [...fields.textVariables, ...fields.roles],
      workspaceId: fields.workspaceId,
      color: fields.color,
    },
  }
}
