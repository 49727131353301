import {
  ArchiveSolid,
  ArrowDownSolid,
  ArrowUpSolid,
  CogSolid,
  DotsHorizontalSolid,
} from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useMoveFolderItemInDirection } from '~/areas/folders/hooks'
import { useUriByRouteId } from '~/routing'
import { useNavigate } from 'react-router'

type WorkspaceActionMenuProps = {
  // This is the ID of the folder item
  itemId: string
  workspaceId: string
  moveActionAllowed: { up: boolean; down: boolean }
}

export const WorkspaceActionMenu = ({
  workspaceId,
  moveActionAllowed,
  itemId,
}: WorkspaceActionMenuProps) => {
  const navigate = useNavigate()
  const getRouteUri = useUriByRouteId()
  const moveFolderInDirection = useMoveFolderItemInDirection()

  return (
    <ActionDropdown
      sections={[
        {
          items: [
            {
              prefix: <ArchiveSolid />,
              content: 'Archived Tasks',
              onAction: () => {
                navigate(getRouteUri('workspace-archive', { workspaceId }))
              },
            },
          ],
        },
        {
          items: [
            {
              prefix: <ArrowUpSolid />,
              content: 'Move up',
              disabled: !moveActionAllowed.up,
              onAction: async () => {
                recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                  itemType: 'WORKSPACE',
                  direction: 'UP',
                })

                try {
                  await moveFolderInDirection(itemId, -1)
                } catch (e) {
                  Sentry.captureException(
                    new Error('Failed to shift workspace', { cause: e }),
                    {
                      extra: {
                        itemId,
                        direction: 'up',
                      },
                      tags: {
                        position: 'WorkspaceActionMenu',
                      },
                    }
                  )
                }
              },
            },
            {
              prefix: <ArrowDownSolid />,
              content: 'Move down',
              disabled: !moveActionAllowed.down,
              onAction: async () => {
                recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                  itemType: 'WORKSPACE',
                  direction: 'DOWN',
                })

                try {
                  await moveFolderInDirection(itemId, 1)
                } catch (e) {
                  Sentry.captureException(
                    new Error('Failed to shift workspace', { cause: e }),
                    {
                      extra: {
                        itemId,
                        direction: 'down',
                      },
                      tags: {
                        position: 'WorkspaceActionMenu',
                      },
                    }
                  )
                }
              },
            },
          ],
        },
        {
          items: [
            {
              prefix: <CogSolid />,
              content: 'Workspace Settings',
              url: getRouteUri('workspace-settings', { workspaceId }),
            },
          ],
        },
      ]}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'WORKSPACE',
            button: 'ACTIONS',
          })
        }}
      />
    </ActionDropdown>
  )
}
