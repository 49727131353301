import { findDefaultStatus } from '@motion/shared/common'
import { createPlaceholderId } from '@motion/shared/identifiers'
import {
  convertStageDefinitionToFormStage,
  type FlowTemplateFormFields,
} from '@motion/ui-logic/pm/project'
import { convertDaysToDeadlineInterval } from '@motion/ui-logic/pm/project'
import { uniqueId } from '@motion/utils/core'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  useMyTasksWorkspace,
  useProjectDefinition,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useMemo } from 'react'

import { useDefaultFlowStages } from './use-default-flow-stages'
import { useFlowTemplateModalUrlParams } from './use-flows-template-modal-url'

import { isRoleVariable, isTextVariable } from '../../utils'
import { FLOW_TEMPLATE_PRESETS } from '../presets'
import { DEFAULT_TEXT_VARIABLES } from '../utils'

export function useInitialFormData(): FlowTemplateFormFields {
  const {
    template: templateOperationParam,
    templateId,
    workspaceId: workspaceIdParam,
    fromPresetId,
  } = useFlowTemplateModalUrlParams()

  const { uid: currentUserId } = useAuthenticatedUser()
  const defaultWorkspace = useMyTasksWorkspace()

  const projectDefinition = useProjectDefinition(templateId ?? '')

  const usingTemplateData = templateId != null && projectDefinition != null
  const isEditing = usingTemplateData && templateOperationParam === 'edit'
  const isCreatingFromTemplate =
    usingTemplateData && templateOperationParam === 'new'
  const isCreatingFromPreset =
    templateOperationParam === 'new' && fromPresetId != null

  const id = isEditing ? projectDefinition.id : undefined

  const workspaceId = usingTemplateData
    ? projectDefinition.workspaceId
    : (workspaceIdParam ?? defaultWorkspace?.id)

  const stages = useInitialStages(
    workspaceId,
    projectDefinition,
    isCreatingFromTemplate,
    currentUserId
  )

  const { roles, textVariables } = useInitialVariables(
    projectDefinition,
    isCreatingFromTemplate
  )

  const preset = usePresetTemplate(
    workspaceId,
    isCreatingFromPreset,
    fromPresetId
  )

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  return useMemo(() => {
    if (preset != null) {
      return preset
    }

    const assigneeUserId = usingTemplateData
      ? projectDefinition.managerId
      : currentUserId
    const priorityLevel = usingTemplateData
      ? projectDefinition.priorityLevel
      : 'MEDIUM'

    return {
      isLoading: false,
      hasError: false,
      id,
      workspaceId,
      name: projectDefinition?.name ?? '',
      description: projectDefinition?.description ?? '',
      color: projectDefinition?.color ?? 'gray',
      managerId: assigneeUserId,
      priorityLevel,
      labelIds: projectDefinition?.labelIds ?? [],
      roles,
      textVariables,
      stages,
    }
  }, [
    currentUserId,
    id,
    preset,
    stages,
    roles,
    textVariables,
    projectDefinition,
    usingTemplateData,
    workspaceId,
  ])
}

function useInitialStages(
  workspaceId: string | undefined,
  projectDefinition: ProjectDefinitionSchema | null,
  isCreatingFromTemplate: boolean,
  currentUserId: string
) {
  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const defaultStages = useDefaultFlowStages(workspaceId, currentUserId)

  if (projectDefinition == null || projectDefinition.stages.length === 0) {
    return defaultStages
  }

  if (isCreatingFromTemplate) {
    return projectDefinition.stages.map((stage) => ({
      ...convertStageDefinitionToFormStage({
        stage,
        workspaceCustomFields,
        addPlaceholderTaskIds: true,
      }),
      id: createPlaceholderId(uniqueId('stage')),
    }))
  }

  return projectDefinition.stages.map((stage) =>
    convertStageDefinitionToFormStage({
      stage,
      workspaceCustomFields,
    })
  )
}

function useInitialVariables(
  projectDefinition: ProjectDefinitionSchema | null,
  isCreatingFromTemplate: boolean
) {
  const roles = projectDefinition?.variables.filter(isRoleVariable) ?? []
  const userTextVariables =
    projectDefinition?.variables.filter(isTextVariable) ?? []
  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES.filter(
      (defaultVar) => !userTextVariables.some((v) => v.key === defaultVar.key)
    ),
    ...userTextVariables,
  ]

  if (isCreatingFromTemplate) {
    return {
      roles: roles.map((role) => ({
        ...role,
        id: createPlaceholderId(uniqueId('role')),
      })),
      textVariables: textVariables.map((textVariable) => ({
        ...textVariable,
        id: createPlaceholderId(uniqueId('text-variable')),
      })),
    }
  }

  return {
    roles,
    textVariables,
  }
}

function usePresetTemplate(
  workspaceId: string | undefined,
  isCreatingFromPreset: boolean,
  fromPresetId: string | undefined
): FlowTemplateFormFields | undefined {
  const isMeetingsInProjectsEnabled = useHasTreatment('meetings-in-project')
  const workspaceStatuses = useWorkspaceStatuses(workspaceId ?? '')
  const defaultStatus =
    findDefaultStatus(workspaceStatuses) ?? workspaceStatuses[0]

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  return useMemo(() => {
    if (isCreatingFromPreset && fromPresetId != null) {
      const getTemplateFn = FLOW_TEMPLATE_PRESETS.find(
        (p) => p.id === fromPresetId
      )?.template
      const template = getTemplateFn?.({
        useEventType: isMeetingsInProjectsEnabled,
      })

      if (template == null) {
        throw new Error(`Preset with id ${fromPresetId} not found`)
      }

      return {
        ...template,
        id: undefined,
        workspaceId,
        stages: template.stages.map((stage) => ({
          ...stage,
          deadlineInterval: convertDaysToDeadlineInterval(
            stage.deadlineIntervalDays
          ),
          tasks: stage.tasks.map((task) => ({
            ...task,
            customFieldValuesFieldArray: [],
            statusId: defaultStatus.id,
          })),
        })),
      } satisfies FlowTemplateFormFields
    }
  }, [
    isCreatingFromPreset,
    fromPresetId,
    isMeetingsInProjectsEnabled,
    workspaceId,
    defaultStatus.id,
  ])
}
