import { DateTime } from 'luxon'

const dayDiffCache: Record<string, number> = {}
const offsetDateCache: Record<number, DateTime> = {}
let currentDate = DateTime.now().startOf('day')
let lasBust = Date.now()

const isStale = () => Date.now() - lasBust > 60_000

const bustDateCache = () => {
  for (const member in dayDiffCache) delete dayDiffCache[member]
  for (const member in offsetDateCache) delete offsetDateCache[member]
  currentDate = DateTime.now().startOf('day')
  lasBust = Date.now()
}

const getDiffInDays = (isoDate: string) => {
  if (isStale()) {
    bustDateCache()
  }
  if (dayDiffCache[isoDate]) {
    return dayDiffCache[isoDate]
  }
  const date = DateTime.fromISO(isoDate)
  const diff = Math.floor(date.diff(currentDate, 'days').days)
  dayDiffCache[isoDate] = diff
  return dayDiffCache[isoDate]
}

const getDateAtOffset = (
  offset: number,
  currentDate = DateTime.now().startOf('day')
) => {
  if (isStale()) {
    bustDateCache()
  }
  if (offsetDateCache[offset]) {
    return offsetDateCache[offset]
  }
  const date = currentDate.plus({ days: offset })
  offsetDateCache[offset] = date
  return offsetDateCache[offset]
}

export const getDaysFromWeekStart = (
  currentDate: DateTime = DateTime.now().startOf('day')
) => {
  const daysFromWeekStart = getDiffInDays(
    currentDate.startOf('week').toISODate()
  )

  return { daysFromWeekStart }
}

// changes date in between dates, feels better for editing
export const roundedPixelToDate = (position: number, dayPx: number) => {
  const days = Math.round(position / dayPx)
  return getDateAtOffset(days)
}

// changes date at the exact pixel, better for display of timeline dates and (arguably) the cursor position date
export const pixelToDate = (position: number, dayPx: number) => {
  const days = Math.floor(position / dayPx)
  return getDateAtOffset(days)
}

export const dayOffsetToDate = (days: number) => {
  return getDateAtOffset(days)
}

export const isoDateToPixel = (isoDate: string, dayPx: number) => {
  return Math.floor(getDiffInDays(isoDate)) * dayPx
}
