import { FilledChevronDownSolid, FilledChevronRightSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { AddCustomFieldButton } from '~/areas/project-management/custom-fields/add-custom-field-button'
import { CustomFieldUISwitch } from '~/areas/task-project/components'
import { useState } from 'react'

import { useStageTaskField } from './fields'

import { useFlowTemplateForm } from '../../hooks'

export type StageTaskCardProps = {
  taskBasePath: `stages.${number}.tasks.${number}`
}

export function StageTaskCustomFields({ taskBasePath }: StageTaskCardProps) {
  const [customFields, setCustomFields] = useStageTaskField(
    `${taskBasePath}.customFieldValuesFieldArray`
  )
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const workspaceId = watch('workspaceId')

  const [showCustomFields, setShowCustomFields] = useState(false)

  const ExpandIcon = showCustomFields
    ? FilledChevronDownSolid
    : FilledChevronRightSolid

  if (!customFields) {
    Sentry.captureException('Custom fields not instantiated')
    return null
  }

  const customFieldsLabel = customFields.length
    ? `${customFields.length} Custom fields`
    : 'No custom fields'

  return (
    <div className='flex flex-col pt-2 px-2 max-w-full truncate'>
      <div className={showCustomFields ? 'mb-2' : undefined}>
        <Button
          onClick={() => {
            recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_TOGGLE_CUSTOM_FIELD')
            setShowCustomFields(!showCustomFields)
          }}
          fullWidth={false}
          size='xsmall'
          variant='muted'
          sentiment='neutral'
        >
          <ExpandIcon />
          {customFieldsLabel}
        </Button>
      </div>
      {showCustomFields && (
        <div className='flex flex-col gap-2'>
          {customFields.map((field, index) => (
            <CustomFieldUISwitch
              key={field.instanceId}
              customField={{ ...field, workspaceId }}
              value={field.value}
              onChange={(value) => {
                recordAnalyticsEvent(
                  'FLOW_TEMPLATE_MODAL_UPDATE_CUSTOM_FIELD',
                  {
                    fieldType: field.type,
                  }
                )
                setCustomFields([
                  ...customFields.slice(0, index),
                  { ...customFields[index], value },
                  ...customFields.slice(index + 1),
                ])
              }}
              size='xsmall'
            />
          ))}
          <AddCustomFieldButton
            workspaceId={workspaceId}
            sentiment='neutral'
            size='xsmall'
            usePopover
          />
        </div>
      )}
    </div>
  )
}
