import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from '~/areas/task-project/components'
import {
  LabelDropdown,
  type LabelDropdownProps,
} from '~/global/components/dropdowns'
import { PrettyLabels } from '~/global/components/labels'
import { useWorkspaceLabels } from '~/global/hooks'

export type LabelsFieldProps = {
  selectedLabelIds: string[]
  workspaceId: string
  onChange: LabelDropdownProps['onChange']
  disabled?: boolean
  size?: ModalFieldButtonProps['size']
  variant?: ModalFieldButtonProps['variant']
}

export function LabelsField({
  selectedLabelIds,
  workspaceId,
  onChange,
  size,
  variant,
  disabled,
}: LabelsFieldProps) {
  return (
    <LabelDropdown
      selectedLabelIds={selectedLabelIds}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <ModalFieldButton
        size={size}
        variant={variant}
        label='Labels'
        disabled={disabled}
      >
        <LabelContent
          workspaceId={workspaceId}
          selectedIds={selectedLabelIds}
        />
      </ModalFieldButton>
    </LabelDropdown>
  )
}

type LabelContentProps = {
  selectedIds: string[]
  workspaceId: string
}

export const LabelContent = (props: LabelContentProps) => {
  const { workspaceId, selectedIds } = props
  const labels = useWorkspaceLabels(workspaceId)

  if (selectedIds.length === 0) {
    return 'None'
  }

  return (
    <PrettyLabels
      values={labels.filter((label) => selectedIds.includes(label.id))}
    />
  )
}
