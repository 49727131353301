import { PMTeamMemberRole } from '@motion/rpc/types'
import { Modal } from '@motion/ui/base'
import { getMinimumBucket } from '@motion/ui-logic/billing'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { type Stripe } from '@stripe/stripe-js'
import { ElementsWrapper } from '~/components/Common/Stripe/ElementsWrapper'
import { ConnectedPaymentSection } from '~/components/Team/components/Common/PaymentSection'
import { PaymentInterval } from '~/components/Team/types/PaymentInterval'
import { type ProcessPaymentProps } from '~/components/Team/types/process-payment-props'
import {
  useGetTeamPrices,
  useInviteTeamMembers,
  useResubscribeTeam,
} from '~/global/rpc/team'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { fetchTeam } from '~/state/projectManagement/teamThunks'
import { selectTeam } from '~/state/team'
import { reusableSetupIntent, switchBillingCycle } from '~/state/teamSlice'
import { type Team } from '~/state/TeamTypes'
import { selectEmail } from '~/state/userSlice'
import { getStripe } from '~/utils/stripe'
import { getUniqueEmails } from '~/utils/teamUtils'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'pay-team-form': {
      userEmail: string
      team: Team
      onSuccess?: () => void
      trialEndDate?: DateTime
    }
  }
}

export const PayTeamFormModal = ({
  userEmail,
  team,
  onSuccess,
  trialEndDate,
  close,
}: ModalTriggerComponentProps<'pay-team-form'>) => {
  const email = useAppSelector(selectEmail)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stripeError, setStripeError] = useState<undefined | string>(undefined)

  const { mutateAsync: inviteTeamMembers } = useInviteTeamMembers()
  const { mutateAsync: resubscribeTeam } = useResubscribeTeam()

  const dispatch = useAppDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const modalApi = useModalApi()

  const { data: teamPrices } = useGetTeamPrices()

  if (!stripe || !elements || !teamPrices) {
    // Haven't finished loading stripe yet
    return null
  }

  if (!team?.id) {
    Sentry.captureException('Missing team in PayTeamFormModal', {
      tags: { position: 'PayTeamFormModal' },
    })
    return null
  }

  const uniqueEmailsSet = getUniqueEmails(team)
  const numUsers = uniqueEmailsSet.size

  const emails = Array.from(uniqueEmailsSet).filter(
    (email) => email !== userEmail
  )

  const processPayment = async ({
    isAnnual,
    selectedEmails,
    bucketSeats,
  }: ProcessPaymentProps) => {
    setIsSubmitting(true)
    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    if (result.error) {
      setStripeError(result.error.message)
      setIsSubmitting(false)
      errorInDev(result.error)
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setupIntent = result.setupIntent!

    const resubscribeResult = await resubscribeTeam({
      isMonthly: !isAnnual,
      memberEmails: selectedEmails || [],
      setupIntentId: setupIntent.id,
      teamId: team.id,
      seats: bucketSeats,
    })
    if ('error' in resubscribeResult) {
      setStripeError(resubscribeResult.error)
      setIsSubmitting(false)
      errorInDev(resubscribeResult.error)
      return
    }

    const price = isAnnual ? teamPrices.annualPrice : teamPrices.monthlyPrice
    const amount = price * (bucketSeats ?? numUsers)

    const newInvites = selectedEmails?.filter(
      (email: string) => !uniqueEmailsSet.has(email)
    )

    if (newInvites?.length) {
      const invitees = newInvites.map((guest: string) => ({
        email: guest,
        role: PMTeamMemberRole.MEMBER,
        workspaces: [],
      }))
      await inviteTeamMembers({ id: team.id, invitees, seats: bucketSeats })
    }

    if (isAnnual) {
      // Should upgrade to annual billing plan for the team
      await dispatch(switchBillingCycle(team.id))
    }

    setIsSubmitting(false)

    if (onSuccess) {
      onSuccess()
    } else {
      close()
      modalApi.open('pay-team-form-success', {
        chargeAmount: amount,
        endDate:
          trialEndDate ??
          (isAnnual
            ? DateTime.now().plus({ year: 1 })
            : DateTime.now().plus({ month: 1 })),
        paymentInterval: isAnnual
          ? PaymentInterval.Annually
          : PaymentInterval.Monthly,
      })
      await dispatch(fetchTeam())
      modalApi.dismiss('team-trial-ended')
    }
  }

  return (
    <Modal onClose={close} visible>
      <div className='min-w-[490px]'>
        <ConnectedPaymentSection
          buttonText='Start team plan'
          emails={emails}
          isSubmitting={isSubmitting}
          processPayment={processPayment}
          stripeError={stripeError}
          userEmail={email}
          initialSeats={
            team.pmTeamSubscription?.bucketSeats || getMinimumBucket(numUsers)
          }
          isTrial={team.pmTeamSubscription?.status === 'trialing'}
        />
      </div>
    </Modal>
  )
}

export const ConnectedPayTeamFormModal = (
  props: ModalTriggerComponentProps<'pay-team-form'>
) => {
  const dispatch = useAppDispatch()
  const team = useAppSelector(selectTeam)
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [stripe, setStripe] = useState<null | Stripe>(null)
  useEffect(() => {
    const load = async () => {
      const stripe = await getStripe()
      setStripe(stripe)
    }

    load().catch(errorInDev)
  }, [])

  useEffect(() => {
    if (!team?.id) return
    const getSecret = async () => {
      const paymentIntent = await dispatch(
        reusableSetupIntent(team.id)
      ).unwrap()
      setClientSecret(paymentIntent.clientSecret)
    }

    getSecret().catch(errorInDev)
  }, [team]) // eslint-disable-line react-hooks/exhaustive-deps

  if (stripe !== null && clientSecret !== null) {
    return (
      <ElementsWrapper clientSecret={clientSecret} stripe={stripe}>
        <PayTeamFormModal {...props} />
      </ElementsWrapper>
    )
  }
  return null
}
