import { NameTextField } from '../../common'
import { useEventFormOptions } from '../event-form-options-context'
import { useEventForm, useEventFormTitleField } from '../hooks'

export const EventFormNameField = () => {
  const { isReadOnly } = useEventFormOptions()
  const { watch } = useEventForm()
  const { field, onChange, error } = useEventFormTitleField()
  const id = watch('id')

  return (
    <div className='py-2 px-1'>
      <NameTextField
        value={field.value}
        onChange={onChange}
        error={error}
        isReadOnly={isReadOnly}
        autoFocus={!id}
        placeHolder='Event title'
      />
    </div>
  )
}
