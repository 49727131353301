import { type API, type ApiTypes } from '@motion/rpc'
import { type ActivityCustomFieldPropertyUpdatedJson } from '@motion/shared/activity-feed'
import {
  type ActivityAttachmentCreatedSchema,
  type ActivityAttachmentDeletedSchema,
  type ActivityAttachmentPropertyUpdatedSchema,
  type ActivityPropertyUpdatedSchema,
  type ActivitySchema,
  type ActivityTaskOrProjectCommentCreatedSchema,
  type CommentSchema,
  type FieldTypeSchema,
  type ProjectSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { type ReactNode } from 'react'

export type ActivityFeedType =
  | {
      type: 'project'
      target: ProjectSchema
    }
  | {
      type: 'task'
      target: TaskSchema
    }
  | {
      type: 'event'
      target: TaskSchema
    }

// A subset of FeedEntrySchema that the frontend supports
export type FeedEntryViewSchema = CommentSchema | ActivityEntryViewSchema

export type ActivityEntryViewSchema = Exclude<
  ActivitySchema,
  ActivityTaskOrProjectCommentCreatedSchema
>

type PartialExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never

type ActivityPropertyUpdatedMetadata =
  | {
      [K in ActivityAttachmentPropertyUpdatedSchema['metadata']['field']]: {
        field: 'attachmentUpdated'
        type: K
      } & Omit<
        Extract<
          ActivityAttachmentPropertyUpdatedSchema['metadata'],
          {
            field: K
          }
        >,
        'field'
      >
    }[ActivityAttachmentPropertyUpdatedSchema['metadata']['field']]
  | ({
      field: 'attachmentCreated'
    } & ActivityAttachmentCreatedSchema['metadata'])
  | ({
      field: 'attachmentDeleted'
    } & ActivityAttachmentDeletedSchema['metadata'])
  | ActivityPropertyUpdatedSchema['metadata']

// We extend 'created' to allow for the 'created' field to be passed in as a parameter
// This is because the creation event does not belong to the ActivityPropertyUpdatedSchema
type ActivityItemLookupFields =
  | ActivityPropertyUpdatedMetadata['field']
  | 'created'

type ActivityItemParam<T extends ActivityItemLookupFields> = {
  workspaceId: string
  type: ActivityFeedType['type']
  metadata: PartialExtract<ActivityPropertyUpdatedMetadata, { field: T }>
  defaultIcon: ReactNode
  targetId: string
  sourceType: ActivityPropertyUpdatedSchema['sourceType']
  sourceId: ActivityPropertyUpdatedSchema['sourceId']
}

export type ActivityItemConfigLookupType = {
  [K in ActivityItemLookupFields]?: (
    params: ActivityItemParam<K>
  ) => ActivityRenderConfig
} & {
  default: () => null
}

type CustomFieldItemParam<T extends FieldTypeSchema> = {
  workspaceId: string
  defaultIcon: ReactNode
  name: string
  oldValue?: PartialExtract<
    ActivityCustomFieldPropertyUpdatedJson['oldValue'],
    { type: T }
  >
  newValue?: PartialExtract<
    ActivityCustomFieldPropertyUpdatedJson['newValue'],
    { type: T }
  >
  instanceId: string
  sourceType: ActivityPropertyUpdatedSchema['sourceType']
  sourceId: ActivityPropertyUpdatedSchema['sourceId']
}

export type CustomFieldItemConfigLookupType = {
  [K in FieldTypeSchema]?: (params: CustomFieldItemParam<K>) => {
    icon: ReactNode
    children: ReactNode
  } | null
} & {
  default: () => null
}

export type ActivityRenderConfig = {
  icon: ReactNode
  children: ReactNode
} | null

// This is a mapping from the ActivityFeedType type to the type used in the RPC call
// For example, the ActivityFeedType type 'event' is actually a task, so we use 'task' in the RPC call
export const feedTypeToRpcType: Record<
  ActivityFeedType['type'],
  ApiTypes<typeof API.feedV2.getFeedById>['args']['type']
> = {
  project: 'project',
  task: 'task',
  event: 'task',
}
