import { Banner } from '@motion/ui/base'
import { logEvent } from '@motion/web-base/analytics'

import { useEffect } from 'react'

import { Events } from '../../analyticsEvents'
import { selectCalendarIsDisabled } from '../../state/calendar-list/calendar-list-slice'
import { useAppSelector } from '../../state/hooks'

export const CalendarDisabledBanner = ({}) => {
  const isCalendarDisabled = useAppSelector(selectCalendarIsDisabled)

  useEffect(() => {
    if (isCalendarDisabled !== null) {
      void logEvent(Events.CALENDAR_BANNER_SHOW_RATE_LIMIT)
    }
  }, [isCalendarDisabled])

  if (isCalendarDisabled === null) return null

  return (
    <Banner sentiment='warning'>
      Some of your connected calendars are exceeding the maximum allowed quota;
      this happens when there are too many changes happening in a short time.
      This usually recovers after 30 minutes; in the meantime, changes within
      Motion may not reflect externally.
    </Banner>
  )
}
