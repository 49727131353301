import { type DateAdjustmentStrategy } from '@motion/shared/projects'
import { ConfirmationModal } from '@motion/ui/base'
import { formatToReadableWeekDayMonth, templateStr } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { useI18N } from '~/global/contexts'
import { useProject } from '~/global/hooks'
import { useState } from 'react'

import { type ModalTriggerComponentProps } from '../../../modals/modal-trigger'
import { ModalRadioButton } from '../components/modal-radio-button'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'update-project-date': PromptCallbacks<{
      dateAdjustmentStrategy: DateAdjustmentStrategy
    }> & {
      projectId: string
      newDate: string
      dateType: 'start' | 'due'
    }
  }
}

export function UpdateProjectDateModal({
  close,
  projectId,
  newDate,
  dateType,
}: ModalTriggerComponentProps<'update-project-date'>) {
  const project = useProject(projectId)
  const [dateAdjustmentStrategy, setDateAdjustmentStrategy] =
    useState<DateAdjustmentStrategy>(
      dateType === 'start' ? 'SHIFT' : 'DISTRIBUTE'
    )
  const { pluralize } = useI18N()

  if (!project) {
    Sentry.captureException(
      new Error('Project or stage definition not found'),
      {
        tags: {
          position: 'update-project-date-modal',
          projectId,
        },
      }
    )
    close()
    return null
  }

  const projectStartDate = parseDate(project.startDate ?? '')
  const firstStageEndDate = parseDate(project.stages?.[0]?.dueDate ?? '')
  const projectEndDate = parseDate(project.dueDate ?? '')
  const parsedOriginalDate =
    dateType === 'start' ? projectStartDate : projectEndDate
  const parsedNewDate = parseDate(newDate)
  const deadlineDiff = parsedNewDate.diff(parsedOriginalDate, 'days')
  const deadlineDiffDays = deadlineDiff.days
  const disableNonShiftStrategies =
    dateType === 'start' &&
    deadlineDiffDays >=
      firstStageEndDate.diff(projectStartDate, 'days').days - 1
  const daysChangedCopy = `${deadlineDiffDays > 0 ? '+' : ''}${deadlineDiffDays} ${pluralize(deadlineDiffDays, 'day', 'days')}`

  const originalDateLabel = formatToReadableWeekDayMonth(parsedOriginalDate)
  const newDateLabel = formatToReadableWeekDayMonth(parsedNewDate)
  const showShiftStrategy = dateType === 'start'
  const dateTypeLabel = dateType === 'start' ? 'start date' : 'deadline'
  const stageToBeUpdated = dateType === 'start' ? 'first' : 'last'

  return (
    <ConfirmationModal
      action={{
        label: 'Continue',
        onAction: () => {
          close({ dateAdjustmentStrategy })
        },
      }}
      closeLabel='Cancel'
      description={
        <div className='gap-3 flex flex-col'>
          <div className='text-semantic-neutral-text-default text-xs font-normal inline-flex flex-wrap gap-2 flex-row'>
            <span>
              {templateStr(
                `You are changing the project {{dateTypeLabel}} from {{originalDateLabel}} to {{newDateLabel}} ({{daysChangedCopy}})`,
                {
                  dateTypeLabel,
                  originalDateLabel,
                  newDateLabel,
                  daysChangedCopy,
                }
              )}
            </span>
          </div>
          <div className='flex flex-col items-start gap-4 self-stretch'>
            {showShiftStrategy && (
              <ModalRadioButton
                radioTitle='Change start date and move project'
                radioSubtitle={templateStr(
                  `Motion will move the project by {{daysChangedCopy}}. The project deadline will move from {{originalDateLabel}} to {{newDateLabel}}.
                    Motion will move all the stage deadlines and the project
                    deadline by {{daysChangedCopy}}`,
                  {
                    originalDateLabel,
                    newDateLabel,
                    daysChangedCopy,
                  }
                )}
                checked={dateAdjustmentStrategy === 'SHIFT'}
                onChange={() => setDateAdjustmentStrategy('SHIFT')}
              />
            )}
            <ModalRadioButton
              radioTitle={`Change ${dateTypeLabel} and resize stages proportionally`}
              radioSubtitle={`Motion will redistribute the difference (${daysChangedCopy}) across all non-completed stages`}
              checked={dateAdjustmentStrategy === 'DISTRIBUTE'}
              onChange={() => setDateAdjustmentStrategy('DISTRIBUTE')}
              disabled={disableNonShiftStrategies}
              tooltipContent={
                disableNonShiftStrategies
                  ? 'Project cannot be resized if the resizing is greater than the first stage duration'
                  : undefined
              }
            />
            <ModalRadioButton
              radioTitle={`Change ${dateTypeLabel} and resize the ${stageToBeUpdated} stage`}
              radioSubtitle={`Motion will resize the ${stageToBeUpdated} stage by ${daysChangedCopy}`}
              checked={dateAdjustmentStrategy === 'ABSORB'}
              onChange={() => setDateAdjustmentStrategy('ABSORB')}
              disabled={disableNonShiftStrategies}
              tooltipContent={
                disableNonShiftStrategies
                  ? 'Project cannot be resized if the resizing is greater than the first stage duration'
                  : undefined
              }
            />
          </div>
        </div>
      }
      onClose={close}
      title='How should Motion treat the stage deadlines?'
      visible
    />
  )
}
