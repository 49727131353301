import { ExclamationTriangleSolid } from '@motion/icons'
import { type Calendar } from '@motion/rpc/types'
import { SearchableList, SearchableListFooterItem } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { selectAllCalendars } from '~/state/calendar-list/calendar-list-slice'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useCallback, useMemo } from 'react'

import { useViewState } from '../../pages/pm-v3/view-state'

export const ConnectedCalendarsList = () => {
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const allCalendars = useAppSelector(selectAllCalendars)
  const [viewState, setViewState] = useViewState()

  const { providerIds: activeCalendars = [], showOOOEventsByAssignee = false } =
    viewState

  const uniqueCalendars = useMemo(() => {
    const uniqueProviderIds = new Set()
    return allCalendars.filter((calendar) => {
      if (uniqueProviderIds.has(calendar.providerId)) {
        return false
      }
      uniqueProviderIds.add(calendar.providerId)
      return true
    })
  }, [allCalendars])

  const sections = useMemo(
    () =>
      emailAccounts.map((account) => ({
        key: account.id,
        label: account.email ?? '',
        items: uniqueCalendars.filter(
          (calendar) => calendar.emailAccountId === account.id
        ),
      })),
    [emailAccounts, uniqueCalendars]
  )

  const toggleShowOOOEvents = useCallback(
    (checked: boolean) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_SHOW_OOO_BY_ASSIGNEE', {
        checked,
      })

      setViewState((state) => ({
        ...state,
        showOOOEventsByAssignee: checked,
      }))
    },
    [setViewState]
  )

  const onSelect = useCallback(
    (items: Calendar[]) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_TOGGLE_CALENDAR', {
        calendarsLength: items.length,
      })
      setViewState((state) => ({
        ...state,
        providerIds: items.map((item) => item.providerId),
      }))
    },
    [setViewState]
  )

  return (
    <>
      <SearchableList
        computeKey={(item) => item.id}
        computeSelected={(item) => activeCalendars.includes(item.providerId)}
        computeSearchValue={(item) => item.title}
        inputProps={{
          placeholder: 'Search calendars...',
        }}
        onSelect={onSelect}
        sections={sections}
        itemType='sectioned-checkbox'
        renderItem={(item) => item.title}
      />
      <div className='flex p-1 flex-col items-start self-stretch gap-1 border-t'>
        <SearchableListFooterItem
          onClick={() => toggleShowOOOEvents(!showOOOEventsByAssignee)}
        >
          <Checkbox
            checked={showOOOEventsByAssignee}
            onChange={toggleShowOOOEvents}
            label='Select'
            labelHidden
          />
          Show OOO events when grouped by assignee
        </SearchableListFooterItem>
        <div className='flex pr-1 flex-col items-start gap-2 self-stretch'>
          <div className='flex px-3 py-1.5 items-start gap-2 self-stretch'>
            <ExclamationTriangleSolid className='w-4 h-4 text-semantic-neutral-icon-default' />
            <span className='text-xs font-normal text-semantic-neutral-text-subtle'>
              Only events up to 3 months into the future will be shown
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
