import {
  type UploadedFileSchema,
  type UploadFileRequest,
} from '@motion/rpc-types'

import { AttachmentItem, AttachmentItemPlaceholder } from './components'
import { useFileUploadState } from './contexts/file-upload-context'

const FALLBACK_EMPTY_MESSAGE = 'No attachments have been added.'

type AttachmentsProps = {
  attachmentIds: UploadedFileSchema['id'][]
  targetId: UploadFileRequest['targetId']
  targetType: UploadFileRequest['targetType']
  variant?: 'default' | 'compact'
  emptyMessage?: string
}

export function Attachments({
  attachmentIds,
  targetId,
  targetType,
  variant = 'default',
  emptyMessage = FALLBACK_EMPTY_MESSAGE,
}: AttachmentsProps) {
  const { activeFileUploads } = useFileUploadState()

  const filteredActiveFileUploads = activeFileUploads.filter(
    (activeFileUpload) =>
      activeFileUpload.targetId === targetId &&
      activeFileUpload.targetType === targetType
  )

  if (!attachmentIds.length && !filteredActiveFileUploads.length)
    return (
      <div className='text-sm text-semantic-neutral-text-subtle pl-2.5'>
        {emptyMessage}
      </div>
    )

  return (
    <>
      {attachmentIds.map((attachmentId) => (
        <AttachmentItem
          key={attachmentId}
          attachmentId={attachmentId}
          variant={variant}
        />
      ))}
      {filteredActiveFileUploads.map(({ tempId, fileName, progress }) => (
        <AttachmentItemPlaceholder
          key={tempId}
          fileName={fileName}
          variant={variant}
          progress={progress}
        />
      ))}
    </>
  )
}
