import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import {
  type MeetingModalParams,
  useMeetingModalUrl,
} from './use-meeting-modal-url'
import {
  type ProjectModalParams,
  useProjectModalUrl,
} from './use-project-modal-url'
import { type TaskModalParams, useTaskModalUrl } from './use-task-modal-url'

type GlobalModals = {
  task: TaskModalParams
  meeting: MeetingModalParams
  project: ProjectModalParams
}

type UriByModalIdFn = {
  <TModalId extends keyof GlobalModals>(
    id: TModalId,
    params: GlobalModals[TModalId]
  ): string
}

export function useUriByGlobalModalId(): UriByModalIdFn {
  const buildTaskUrl = useTaskModalUrl()
  const buildMeetingUrl = useMeetingModalUrl()
  const buildProjectUrl = useProjectModalUrl()

  return useCallback(
    (name, params) => {
      if (name === 'task') {
        return buildTaskUrl(params as any)
      }
      if (name === 'meeting') {
        return buildMeetingUrl(params as any)
      }
      if (name === 'project') {
        return buildProjectUrl(params as any)
      }

      throw new Error('Invalid global modal id', { cause: { name, params } })
    },
    [buildMeetingUrl, buildProjectUrl, buildTaskUrl]
  )
}

type NavigateUriByModalIdFn = {
  <TModalId extends keyof GlobalModals>(
    id: TModalId,
    params: GlobalModals[TModalId]
  ): void
}

export function useNavigateByGlobalModalId(): NavigateUriByModalIdFn {
  const navigate = useNavigate()
  const buildModalUri = useUriByGlobalModalId()

  return useCallback(
    (name, params) => {
      navigate(buildModalUri(name, params))
    },
    [buildModalUri, navigate]
  )
}
