import {
  SharedStateProvider,
  type StateKey,
  useSharedState,
} from '@motion/react-core/shared-state'
import { classed } from '@motion/theme'
import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  ActiveFilterKey,
  type FilterTarget,
  useActiveFilter,
} from '@motion/ui-logic/pm/data'
import { useTitle } from '@motion/web-common/html'
import { DB, SharedStatePersister } from '@motion/web-common/storage'
import { type VersionedViewV2 } from '@motion/zod/client'

import { ModalTrigger } from '~/areas/modals'
import { ErrorPage } from '~/global/components/error-page'
import {
  ActiveFilterItemsWorkspaceContext,
  AppWorkspaceContext,
} from '~/global/contexts'
import { type ReactNode, useEffect, useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router'

import { ConnectedPageContext } from './connected-page-context'
import { SyncRoute } from './sync-routes'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { TaskContextProvider } from '../../pm-revamp/team-schedule/context/task-context'
import {
  ConnectedTaskLoader,
  type ConnectedTaskLoaderProps,
} from '../components'
import { ConnectedHeader } from '../header/connected-header'
import { useIsValidRoute, usePageData } from '../routes/hooks'
import { type PageParams } from '../routes/types'
import { ViewStateKey } from '../view-state'
import { isDefaultView, useSelectedView } from '../views'
import { ConnectedSaveViewModal } from '../views/modals'

const PRELOAD_KEYS = [ActiveFilterKey, ViewStateKey]

const onlyStateKeys = (key: StateKey<any>) => PRELOAD_KEYS.includes(key)

export const ShellWithContext = () => {
  const route = usePageData()
  const routeValidation = useIsValidRoute()
  const navigate = useNavigate()

  const queryOverrides = useMemo<
    ConnectedTaskLoaderProps['queryOverrides']
  >(() => {
    if (route.variant !== 'archive') {
      return
    }
    return {
      completed: 'include',
      archived: 'only',
    }
  }, [route.variant])

  useEffect(() => {
    if (routeValidation != null) {
      showToast('error', 'The route is invalid. Navigating to the right page.')
      navigate(routeValidation, {
        replace: true,
      })
    }
  }, [navigate, routeValidation])

  if (routeValidation != null) {
    // Rendering loading while it navigates
    return <LoadingPage />
  }

  return (
    <ErrorPage>
      <ConnectedPageContext name='shell' key={route.stateKey}>
        <SharedStateProvider
          name='filter'
          filter={onlyStateKeys}
          initialValues={route.state}
        >
          <SyncRoute route={route} />

          <SharedStatePersister
            prefix={route.stateKey}
            store={DB.state}
            filter={onlyStateKeys}
          />

          <ActiveFilterItemsWorkspaceContext>
            <ConnectedTaskLoader
              overrides={route.overrides}
              queryOverrides={queryOverrides}
            />
            <ConnectedShell />
          </ActiveFilterItemsWorkspaceContext>

          <ModalTrigger
            name='save-view-v2'
            component={ConnectedSaveViewModal}
          />
        </SharedStateProvider>
      </ConnectedPageContext>
    </ErrorPage>
  )
}

const ConnectedShell = () => {
  // need to wait until everything is loaded before calling tasks
  const [ctx] = useSharedState(AppWorkspaceContext)

  const [filter] = useActiveFilter()
  const route = usePageData()

  if (!ctx.loaded) {
    return <LoadingPage />
  }

  return <Shell title={route.title} target={filter.target} />
}

function getTitle(route: PageParams, selectedView: VersionedViewV2) {
  if (isDefaultView(selectedView) || selectedView == null) {
    return route.title
  }

  return templateStr('{{route}} - {{view}}', {
    route: route.title,
    view: selectedView.name,
  })
}

type ShellProps = {
  title: ReactNode
  target: FilterTarget
}

const Shell = (props: ShellProps) => {
  const route = usePageData()
  const [selectedView] = useSelectedView()
  const title = getTitle(route, selectedView)

  useTitle(title)

  return (
    <TaskContextProvider>
      <Container>
        <ConnectedHeader title={props.title} target={props.target} />
        <ErrorPage>
          <Outlet />
        </ErrorPage>
      </Container>
    </TaskContextProvider>
  )
}

const Container = classed('div', {
  base: `
  grid grid-rows-[auto_1fr]
  w-full overflow-hidden
  bg-calendar-bg-default
  isolate
  relative
  `,
})
