import {
  RichTextContent,
  unescapeTiptapOutput,
  useSingleLineRichTextEditor,
} from '@motion/ui/rte'
import { stripHtml, templateStr } from '@motion/ui-logic'
import { capitalize } from '@motion/utils/string'

import { CharacterCount } from '@tiptap/extension-character-count'
import { Paragraph } from '@tiptap/extension-paragraph'
import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { startTransition, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { FlowVariable, renderFlowVariableTextHtml } from './extensions'
import { type SuggestionItem } from './extensions/suggestion'
import { useStageEventField, useStageTaskField } from './hooks'

import { useFlowTemplateForm } from '../../../hooks'

type StageTaskNameFieldProps = {
  taskBasePath: `stages.${number}.tasks.${number}`
}

const CustomLineHeightParagraph = Paragraph.extend({
  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML: () => {
          return {
            class: `leading-[19px]`,
          }
        },
      },
    }
  },
})

const STAGE_TASK_RULES = getTextFieldRules('Task name')

export const StageTaskNameField = ({
  taskBasePath,
}: StageTaskNameFieldProps) => {
  const {
    form: { getFieldState, formState, unregister },
  } = useFlowTemplateForm()
  const [stageCardType] = useStageEventField(taskBasePath)

  const isEvent = stageCardType === 'event'

  const [name, onNameChange] = useStageTaskField(`${taskBasePath}.name`, {
    validate: () => {
      const value = getValues(`${taskBasePath}.name`)
      if (value == null) {
        unregister(`${taskBasePath}.name`)
        return
      }
      const errorMessage = checkTextRuleErrors(value, STAGE_TASK_RULES)
      if (errorMessage) {
        return errorMessage
      }
    },
  })

  const { error: nameError } = getFieldState(`${taskBasePath}.name`, formState)

  const {
    form: { watch, getValues },
  } = useFlowTemplateForm()

  const textVariables = watch('textVariables')
  const options: SuggestionItem[] = textVariables
    .filter((v) => v.name)
    .map((v) => ({
      label: v.name,
      key: v.key,
    }))

  const taskNameHtml = templateStr(name, renderFlowVariableTextHtml)

  const editor = useSingleLineRichTextEditor({
    className: 'text-xs font-medium',
    placeholder: templateStr('{{type}} name ({{description}})', {
      type: capitalize(stageCardType),
      description: isEvent
        ? 'type “/” for variables'
        : 'type “/” to add a text variable',
    }),
    value: `<p>${taskNameHtml}</p>`,

    extensions: [
      FlowVariable({ taskId: getValues(taskBasePath).id }),
      CustomLineHeightParagraph,
      CharacterCount.configure({
        limit: STAGE_TASK_RULES.maxLength.value,
      }),
    ],
    onChange(value) {
      // Update the value in the form without blocking the UI
      startTransition(() => {
        onNameChange(unescapeTiptapOutput(stripHtml(value)))
      })
    },
  })

  useEffect(() => {
    if (editor) {
      editor.storage.flowVariable.suggestions = options
    }
  }, [options, editor])

  return (
    <div className='relative mx-2'>
      {isEvent && (
        <span className='absolute top-[3px] left-0 text-2xs text-semantic-neutral-text-subtle'>
          Schedule
        </span>
      )}
      <RichTextContent
        editor={editor}
        className={twMerge(
          'outline-0 bg-transparent w-full overflow-auto rounded border border-transparent',
          nameError != null && 'border-semantic-error-border-active',
          isEvent && 'indent-[3.25rem]'
        )}
      />
    </div>
  )
}
