import { useActiveFilter } from '@motion/ui-logic/pm/data'

import { VirtualContainer } from './container/container'
import { GridContainer } from './container/grid-container'
import { AddProjectRow } from './row/add-project'
import { CalendarRows } from './row/calendar-row'
import { ScrollingContainer, TimeLineBar } from './timeline'

import { type GroupedNode, type Tree } from '../grouping'

export const PlannerTimeline = <T extends GroupedNode>({
  tree,
}: {
  tree: Tree<T>
}) => {
  const [filterState] = useActiveFilter()

  // If grouped by task, do not allow gantt view
  if (filterState.target !== 'projects') {
    return null
  }

  const isEmptyState =
    tree.values.length === 0 &&
    tree.maxDepth === 0 &&
    tree.allItems.length === 0

  return (
    <ScrollingContainer>
      <GridContainer>
        <TimeLineBar />
        <CalendarRows />
        {isEmptyState ? <AddProjectRow /> : <VirtualContainer tree={tree} />}
      </GridContainer>
    </ScrollingContainer>
  )
}
