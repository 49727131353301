import { useAllWorkspaces } from '~/global/hooks'
import { useGetFolderById } from '~/global/hooks/folders'
import { useCallback } from 'react'

export const useGetWorkspaceByFolderId = () => {
  const workspaces = useAllWorkspaces()
  const getFolderById = useGetFolderById()

  return useCallback(
    (folderId: string) => {
      const folder = getFolderById(folderId)

      if (!folder || folder.targetType !== 'WORKSPACE') return null

      return workspaces.find(({ id }) => id === folder.targetId) ?? null
    },
    [getFolderById, workspaces]
  )
}
