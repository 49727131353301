import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { FormModal, PopoverButton, showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'

import { useUpdatePrimaryCalendar } from '~/components/Settings/Pages/calendar-settings'
import { BaseDropdown } from '~/global/components'
import {
  selectDismissedPrimaryCalendarModal,
  selectEmailAccountCalendars,
  selectEmailAccountsWithMissingPrimaryCalendar,
  setDismissedPrimaryCalendarModal,
  setEmailAccountsWithMissingPrimaryCalendar,
} from '~/state/calendar-list/calendar-list-slice'
import { fetchAllCalendars } from '~/state/calendar-list/calendar-list-thunks'
import { selectEmailAccountById } from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export const SetPrimaryCalendarModalTrigger = () => {
  const isEnabled = useHasTreatment('set-primary-calendar-modal')
  const emailAccountsWithMissingPrimaryCalendar = useAppSelector(
    selectEmailAccountsWithMissingPrimaryCalendar
  )
  const hasDismissedModal = useAppSelector(selectDismissedPrimaryCalendarModal)
  const emailAccountId = emailAccountsWithMissingPrimaryCalendar[0]

  if (!isEnabled || !emailAccountId || hasDismissedModal) return null

  return <ConnectedSetPrimaryCalendarModal emailAccountId={emailAccountId} />
}

const ConnectedSetPrimaryCalendarModal = ({
  emailAccountId,
}: {
  emailAccountId: string
}) => {
  const dispatch = useAppDispatch()
  const emailAccount = useAppSelector((state) =>
    selectEmailAccountById(state, emailAccountId)
  )
  const calendars = useAppSelector((state) =>
    selectEmailAccountCalendars(state, emailAccount?.id ?? 'none')
  )
  const { mutateAsync: updatePrimaryCalendar } = useUpdatePrimaryCalendar()
  const editableCalendars = calendars.filter(
    (cal) => cal.accessRole === 'OWNER' || cal.accessRole === 'EDITOR'
  )

  const handleClose = async (calendar: Calendar) => {
    try {
      recordAnalyticsEvent('CALENDAR_UPDATE_PRIMARY', {
        view: 'primary_calendar_modal',
      })

      dispatch(setEmailAccountsWithMissingPrimaryCalendar([]))
      dispatch(setDismissedPrimaryCalendarModal(true))
      await updatePrimaryCalendar({
        id: calendar.id,
      })

      await dispatch(fetchAllCalendars())

      showToast(
        'success',
        <span>
          {templateStr(
            `Default calendar set to "{{calendarTitle}}". You can
        change this in {{settingsLink}}.`,
            {
              calendarTitle: calendar.title,
              settingsLink: (
                <Link className='underline' to='/web/settings/calendar'>
                  settings
                </Link>
              ),
            }
          )}
        </span>,
        { duration: 10_000 }
      )
    } catch (err) {
      if (err instanceof Error && 'message' in err) {
        Sentry.captureException('Failed to set primary calendar', {
          tags: {
            position: 'ConnectedSetPrimaryCalendarModal',
          },
          extra: {
            err,
          },
        })
      }
    }
  }

  if (!emailAccount || !editableCalendars.length) {
    return null
  }

  return (
    <SetPrimaryCalendarModal
      emailAccount={emailAccount}
      calendars={editableCalendars}
      handleClose={handleClose}
    />
  )
}

type SetPrimaryCalendarModalProps = {
  calendars: Calendar[]
  emailAccount: EmailAccount
  handleClose: (calendar: Calendar) => void
}
const SetPrimaryCalendarModal = ({
  calendars,
  handleClose,
  emailAccount,
}: SetPrimaryCalendarModalProps) => {
  const [newPrimaryCal, setNewPrimaryCal] = useState<Calendar>(calendars[0])

  return (
    <FormModal
      cancelAction={{ hidden: true }}
      onClose={() => {}}
      nonDismissable
      visible
      submitAction={{
        onAction: () => handleClose(newPrimaryCal),
        text: 'Set new default calendar',
      }}
      title={`We've lost access to your default calendar`}
    >
      <div className='flex flex-col gap-4 w-[568px]'>
        <span className='text-sm text-semantic-neutral-text-default whitespace-pre-line font-normal'>
          {templateStr(
            `We’ve lost access to your previous default calendar for {{email}}.
            Select a new default calendar below:`,
            {
              email: emailAccount.email,
            }
          )}
        </span>
        <div className='flex flex-col gap-1.5'>
          <span className='text-xs font-semibold text-semantic-neutral-text-subtle'>
            Create events/tasks on
          </span>
          <BaseDropdown
            items={calendars}
            renderItem={(calendar) => calendar.title}
            onChange={(calendar) => setNewPrimaryCal(calendar)}
          >
            <PopoverButton>{newPrimaryCal.title}</PopoverButton>
          </BaseDropdown>
        </div>
      </div>
    </FormModal>
  )
}
