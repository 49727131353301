import { DotsHorizontalSolid } from '@motion/icons'
import { ActionList, IconButton, PopoverTrigger } from '@motion/ui/base'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { useStageHeaderContextMenuSections } from './use-stage-header-context-menu-sections'

type StageHeaderContextMenuProps = {
  project: ProjectSchema
  projectStage: StageSchema
  stageDefinition: StageDefinitionSchema
}

/**
 * For use in Flows M4 only
 */
export function StageHeaderContextMenu({
  project,
  projectStage,
  stageDefinition,
}: StageHeaderContextMenuProps) {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <StageHeaderContextMenuContent
          close={close}
          project={project}
          projectStage={projectStage}
          stageDefinition={stageDefinition}
        />
      )}
    >
      <IconButton
        icon={DotsHorizontalSolid}
        size='xsmall'
        variant='muted'
        sentiment='neutral'
      />
    </PopoverTrigger>
  )
}

export type StageHeaderContextMenuContentProps = {
  close: () => void
  project: ProjectSchema
  projectStage: StageSchema
  stageDefinition: StageDefinitionSchema
}

export function StageHeaderContextMenuContent({
  close,
  project,
  projectStage,
  stageDefinition,
}: StageHeaderContextMenuContentProps) {
  const sections = useStageHeaderContextMenuSections(
    project,
    projectStage,
    stageDefinition
  )

  return (
    <div className='w-[260px]'>
      <ActionList
        onActionAnyItem={close}
        alignItems='start'
        sections={sections}
      />
    </div>
  )
}
