import { isDateBetweenDates } from '@motion/utils/dates'

import {
  useCompletedTasksDisplaySetting,
  useShowProjectedEntities,
} from '~/areas/calendar/hooks'
import {
  useFilterScheduledEntitiesGhostTasks,
  useGetScheduledEntitiesWithProxies,
} from '~/global/rpc'
import { DateTime } from 'luxon'

import { useWeekOfSelectedDay } from './use-week-of-selected-day'

const SCH_ENT_STALE_TIME = 1000 * 60 * 2

export function useAgendaDayEntities(date: DateTime) {
  // Showing completed or ghost tasks is a setting that is shared between calendar and agenda
  const [showProjectedEntities] = useShowProjectedEntities()
  const [showCompletedTasks] = useCompletedTasksDisplaySetting()
  const weekOfSelectedDay = useWeekOfSelectedDay()
  let scheduled = {
    from: date.startOf('day').toISO(),
    to: date.endOf('day').toISO(),
  }
  if (
    isDateBetweenDates(
      DateTime.fromISO(weekOfSelectedDay.start),
      DateTime.fromISO(weekOfSelectedDay.end),
      date
    )
  ) {
    scheduled = {
      from: weekOfSelectedDay.start,
      to: weekOfSelectedDay.end,
    }
  }

  const result = useGetScheduledEntitiesWithProxies(
    {
      include: ['task', 'event'],
      filters: {
        completed: showCompletedTasks ? 'include' : 'exclude',
        myCalendarsOnly: true,
        scheduled,
      },
    },
    {
      // For some reason invalidating query when not on page and coming back to it does not re-grab latest data
      // This is a workaround for that
      refetchOnMount: 'always',
      keepPreviousData: true,
      staleTime: SCH_ENT_STALE_TIME,
    }
  )

  const filteredScheduledEntities = useFilterScheduledEntitiesGhostTasks(
    result,
    showProjectedEntities
  )

  return {
    ...result,
    data: { ...result.data, scheduledEntities: filteredScheduledEntities },
  }
}
