import { DragSolid, PlusSolid, StackSolid, XSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
  Toggle,
} from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'
import { type FilterTarget } from '@motion/ui-logic/pm/data'
import { createLookupById } from '@motion/utils/object'
import { type GROUP_BY_DATE_OPTIONS } from '@motion/zod/client'

import {
  closestCenter,
  DndContext,
  type DndContextProps,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { RestrictWidth } from '~/areas/project-management/pages/pm-v3/components'
import { useCallback, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { DateGroupOptionsSelect } from './date-group-options-select'
import { GroupByPopoverContext } from './group-by-popover-context'

import {
  type GroupableField,
  isViewStateGroupByDateKey,
  noneField,
} from '../../grouping'
import { useViewState, type ViewStateGroupByField } from '../../view-state'
import { useGroupBySections } from '../hooks'
import { type FieldSelectProps } from '../types'

const LAST_ROW_ITEMS: LastRowItem[] = [
  { id: 'tasks', label: 'Task' },
  { id: 'projects', label: 'Project' },
]

export type GroupByValue = {
  fields: SelectedGroupByField[]
  hideEmptyGroups: boolean
  stackProjects: boolean
  show: 'tasks' | 'projects'
}

export type GroupByButtonProps = {
  getAvailableFields: (type: FilterTarget) => GroupableField[]
  value: GroupByValue
  onChange(value: GroupByValue): void
  max: number
  canChangeLastRow: boolean
  canStackProjects: boolean
}

export const GroupByButton = (props: GroupByButtonProps) => {
  const isActive = props.value.fields.length > 0

  const showLabel =
    props.value.show === 'tasks'
      ? 'Task'
      : props.value.show === 'projects'
        ? 'Project'
        : 'Unknown'

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <GroupByButtonPopover
          close={close}
          getAvailableFields={props.getAvailableFields}
          max={props.max}
          onChange={props.onChange}
          value={props.value}
          canChangeLastRow={props.canChangeLastRow}
          canStackProjects={props.canStackProjects}
        />
      )}
    >
      <Button
        variant='outlined'
        sentiment={isActive ? 'primary' : 'neutral'}
        size='small'
      >
        <StackSolid />
        <div className='font-medium whitespace-nowrap'>
          <RestrictWidth>
            {/* TODO should we truncate on the indiv fields to always show nested? */}
            {isActive
              ? `Group by: ${[
                  ...props.value.fields.map((f) => f.label),
                  showLabel,
                ].join(' › ')}`
              : `Group by: ${showLabel}`}
          </RestrictWidth>
        </div>
      </Button>
    </PopoverTrigger>
  )
}

type GroupByButtonPopoverProps = {
  close: () => void
  getAvailableFields: (type: FilterTarget) => readonly GroupableField[]
  value: GroupByValue
  onChange(value: GroupByValue): void
  max: number
  canChangeLastRow: boolean
  canStackProjects: boolean
}

export type SelectedGroupByField = GroupableField &
  Pick<ViewStateGroupByField, 'by'>
export type FieldWithNone = SelectedGroupByField | typeof noneField

export const GroupByButtonPopover = (props: GroupByButtonPopoverProps) => {
  const {
    onChange,
    value,
    canChangeLastRow,
    canStackProjects,
    getAvailableFields,
  } = props

  const sensors = useSensors(useSensor(PointerSensor))

  const options = getAvailableFields(value.show)
  const fieldsLookup = createLookupById(value.fields)

  const [fields, setFieldsItems] = useDependantState<FieldWithNone[]>(
    () => (value.fields.length === 0 ? [noneField] : value.fields),
    [value.fields]
  )

  const setFields = useCallback(
    (values: FieldWithNone[]) => {
      setFieldsItems(values)

      if (values.length === 1 && values[0].id === 'none') {
        return onChange({
          hideEmptyGroups: value.hideEmptyGroups,
          stackProjects: value.stackProjects,
          fields: [],
          show: value.show,
        })
      }

      const newValues = values as SelectedGroupByField[]

      onChange({
        hideEmptyGroups: value.hideEmptyGroups,
        stackProjects: value.stackProjects,
        fields: newValues,
        show: value.show,
      })
    },
    [onChange, value, setFieldsItems]
  )

  const canAddMore = fields[0].id !== 'none' && fields.length < props.max

  const handleDragEnd: DndContextProps['onDragEnd'] = (event) => {
    const { active, over } = event
    if (over == null) return
    if (active.id === over.id) return

    const oldIndex = fields.findIndex((x) => x.id === active.id)
    const newIndex = fields.findIndex((x) => x.id === over.id)
    const newItems = arrayMove(fields, oldIndex, newIndex)

    setFields(newItems)
  }

  const setFieldOption = useCallback(
    (id: string, by: GROUP_BY_DATE_OPTIONS) => {
      const newFields = fields.map((f) => (f.id === id ? { ...f, by } : f))
      setFields(newFields)
    },
    [setFields, fields]
  )

  const ctx = useMemo(
    () => ({ fields, setFieldOption }),
    [fields, setFieldOption]
  )

  return (
    <GroupByPopoverContext.Provider value={ctx}>
      <div className='bg-modal-bg text-semantic-neutral-text-default w-[320px]'>
        <div className='flex flex-col p-3 gap-3'>
          <div className='flex justify-between items-center'>
            <span className='text-sm'>Groups</span>
            <Button
              sentiment='neutral'
              variant='muted'
              size='small'
              onClick={() => {
                setFields([noneField])
              }}
            >
              Reset
            </Button>
          </div>
          <div className='flex flex-col gap-2'>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={fields}
                strategy={verticalListSortingStrategy}
              >
                {fields.map((f, index) => {
                  const available = options.filter(
                    (x) => x.id === f.id || fieldsLookup[x.id] == null
                  ) as FieldWithNone[]

                  if (index === 0 && value.fields.length < 2) {
                    available.unshift(noneField)
                  }
                  const selected =
                    available.find((x) => x.id === f.id) ?? available[0]

                  return (
                    <SortableRow
                      key={f.id}
                      selected={selected}
                      available={available}
                      onSelect={(item) => {
                        const newFields = [...fields]
                        newFields[index] = item
                        setFields(newFields)
                      }}
                      onRemove={
                        fields.length > 1
                          ? () => {
                              const newFields = [...value.fields]
                              newFields.splice(index, 1)
                              setFields(newFields)
                            }
                          : undefined
                      }
                    />
                  )
                })}
              </SortableContext>
            </DndContext>
            {canAddMore && (
              <Button
                sentiment='neutral'
                variant='muted'
                size='small'
                // @ts-expect-error - alignment
                className='justify-start'
                onClick={() => {
                  setFields([
                    ...value.fields,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    options.find((x) => !fieldsLookup[x.id])!,
                  ])
                }}
              >
                <PlusSolid />
                Add nested row
              </Button>
            )}
          </div>
          {canChangeLastRow && (
            <div className='flex flex-col gap-1.5'>
              <div className='text-semantic-neutral-text-subtle text-xs font-semibold'>
                Data
              </div>
              <LastRowSelect
                value={value.show}
                onChange={(v) => {
                  onChange({
                    ...value,
                    show: v,
                  })
                }}
                items={LAST_ROW_ITEMS}
              />
            </div>
          )}
        </div>
        <div className='border-t border-semantic-neutral-border-default py-[10px] px-3 flex flex-col gap-3 justify-stretch'>
          <Toggle
            side='right'
            checked={value.hideEmptyGroups}
            onChange={(e) => {
              onChange({
                ...value,
                hideEmptyGroups: e.target.checked,
              })
            }}
          >
            <span className='flex-1'>Hide empty groups</span>
          </Toggle>
          {canStackProjects && (
            <Toggle
              side='right'
              checked={value.stackProjects}
              onChange={(e) => {
                onChange({
                  ...value,
                  stackProjects: e.target.checked,
                })
              }}
            >
              <span className='flex-1'>Consolidate Rows</span>
            </Toggle>
          )}
        </div>
      </div>
    </GroupByPopoverContext.Provider>
  )
}

type SortableRowProps<T extends { id: string; label: string }> = {
  selected: FieldSelectProps<T>['selected']
  available: FieldSelectProps<T>['available']
  onRemove?: () => void
  onSelect: FieldSelectProps<T>['onSelect']
}
const SortableRow = <T extends { id: string; label: string }>({
  selected,
  available,
  onRemove,
  onSelect,
}: SortableRowProps<T>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: selected.id })

  return (
    <div
      className='flex gap-1 justify-between'
      ref={setNodeRef}
      {...attributes}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div
        className={twMerge(
          'flex-1',
          isViewStateGroupByDateKey(selected.id) &&
            'grid [grid-template-columns:1fr_112px]'
        )}
      >
        <ButtonGroup segmented nowrap>
          {onRemove != null && (
            <button
              ref={setActivatorNodeRef}
              {...listeners}
              className='h-8 border border-field-border-default bg-semantic-neutral-bg-hover cursor-grab'
            >
              <DragSolid className='w-3 h-3 text-semantic-neutral-icon-default' />
            </button>
          )}
          <RowFieldSelect
            selected={selected}
            available={available}
            onSelect={onSelect}
          />
        </ButtonGroup>
        {isViewStateGroupByDateKey(selected.id) && (
          <DateGroupOptionsSelect id={selected.id} />
        )}
      </div>
      {onRemove != null && (
        <IconButton
          icon={XSolid}
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={onRemove}
        />
      )}
    </div>
  )
}

export const RowFieldSelect = <T extends { id: string; label: string }>(
  props: FieldSelectProps<T>
) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <RowFieldSelectContent close={close} {...props} />
      )}
    >
      <PopoverButton>
        <div className='truncate max-w-[200px]'>
          {truncateAtSpace(props.selected.label)}
        </div>
      </PopoverButton>
    </PopoverTrigger>
  )
}

const RowFieldSelectContent = <T extends { id: string; label: string }>({
  close,
  available,
  selected,
  onSelect,
}: FieldSelectProps<T> & { close: () => void }) => {
  const sections = useGroupBySections<T>({ items: available })

  return (
    <SearchableList
      sections={sections}
      itemType='sectioned'
      hideEmptySections
      computeKey={(item) => item.id}
      computeSelected={(item) => item.id === selected.id}
      computeSearchValue={(item) => item.label}
      onSelect={(item) => {
        onSelect(item)
        close()
      }}
      renderItem={(item) => (
        <div className='truncate max-w-[200px]:'>
          {truncateAtSpace(item.label)}
        </div>
      )}
    />
  )
}

type LastRowItem = {
  id: 'tasks' | 'projects'
  label: string
}
type LastRowSelectProps = {
  items: LastRowItem[]
  value: string
  onChange(value: LastRowItem['id']): void
}
const LastRowSelect = (props: LastRowSelectProps) => {
  const selected =
    props.items.find((x) => x.id === props.value) ?? props.items[0]
  const [viewState] = useViewState()

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => {
        return (
          <SearchableList
            items={props.items}
            itemType='select'
            searchable={false}
            computeKey={(item) => item.id}
            computeSelected={(item) => item.id === props.value}
            onSelect={(item) => {
              props.onChange(item.id)
              close()
            }}
            renderItem={(item) => <div>{item.label}</div>}
          />
        )
      }}
    >
      <PopoverButton disabled={viewState.page === 'gantt'}>
        {selected.label}
      </PopoverButton>
    </PopoverTrigger>
  )
}
