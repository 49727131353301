import { ChevronDownSolid, QuestionMarkCircleSolid } from '@motion/icons'
import { ActionDropdown, Button, Tooltip } from '@motion/ui/base'
import {
  shouldAllowASAP,
  shouldAllowPromoteToHardDeadline,
} from '@motion/ui-logic'
import { type TaskSchema } from '@motion/zod/client'

export type DoEarlierButtonProps = {
  loading?: boolean
  task: TaskSchema
  onDoAsap(): void
  onHardDeadline(): void
}

export const DoEarlierButton = (props: DoEarlierButtonProps) => {
  const allowPromoteToHardDeadline = shouldAllowPromoteToHardDeadline(
    props.task
  )

  const allowAsap = shouldAllowASAP(props.task)

  if (!allowAsap && !allowPromoteToHardDeadline) {
    return null
  }

  if (allowAsap && !allowPromoteToHardDeadline) {
    return (
      <Button
        disabled={props.loading}
        variant='outlined'
        sentiment='neutral'
        size='small'
        onClick={props.onDoAsap}
      >
        Do ASAP
      </Button>
    )
  }

  return (
    <ActionDropdown
      placement='bottom-end'
      items={[
        {
          content: 'Turn on: Hard Deadline',
          suffix: <Help />,
          onAction: props.onHardDeadline,
        },
        allowAsap && {
          onAction: props.onDoAsap,
          content: 'Do ASAP',
        },
      ]}
    >
      <Button
        disabled={props.loading}
        variant='outlined'
        sentiment='neutral'
        size='small'
      >
        Meet Deadline
        <ChevronDownSolid width={16} height={16} />
      </Button>
    </ActionDropdown>
  )
}

const Help = () => {
  return (
    <Tooltip content='This task will be scheduled outside of the assigned schedule to help it to get done on time.'>
      <QuestionMarkCircleSolid
        className='text-semantic-neutral-icon-default'
        width={16}
        height={16}
      />
    </Tooltip>
  )
}
