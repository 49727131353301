import { classed } from '@motion/theme'

export const StageTooltipContainer = classed('div', {
  base: `
    w-[250px]
    flex flex-col
    pb-1
    bg-semantic-neutral-surface-overlay-bg-subtlest
    [&>div:not(:first-child)]:border-t
    [&>div:not(:first-child)]:border-semantic-neutral-border-default
  `,
})

export const HeaderSection = classed('div', {
  base: 'flex flex-col gap-2 p-2',
})

export const MetricsSection = classed('div', {
  base: 'flex flex-col p-2 pt-3 gap-2 [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-semantic-neutral-border-default',
})

export const SpaceBetweenRow = classed('div', {
  base: 'flex justify-between',
})

export const StatusLabel = classed('div', {
  base: 'flex items-center gap-1.5',
})

export const SmallText = classed('span', {
  base: 'text-2xs text-semantic-neutral-text-default',
})

export const SubtleText = classed('span', {
  base: 'text-2xs text-semantic-neutral-text-subtle',
})
