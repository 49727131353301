import {
  CalendarSolid,
  CustomFieldTextSolid,
  type SvgIconProps,
  UserSolid,
} from '@motion/icons'
import { Focus } from '@motion/ui/base'
import { createLookup } from '@motion/utils/object'

import { memo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  DateStepContent,
  NameStepContent,
  RolesStepContent,
  TextReplacementsStepContent,
} from './steps'
import { StepHeader } from './styled'

import {
  type SetupProjectTabOption,
  useActiveTab,
  useTabOptions,
} from '../hooks'

export function SetupProjectTabs() {
  const activeTab = useActiveTab()
  const tabOptions = useTabOptions()

  return (
    <div className='flex flex-col gap-1'>
      {tabOptions.map((tab) => (
        <SetupProjectTab key={tab} tab={tab} activeTab={activeTab} />
      ))}
    </div>
  )
}

export function SetupProjectTab({
  tab,
  activeTab,
}: {
  tab: SetupProjectTabOption
  activeTab: SetupProjectTabOption
}) {
  const isActive = tab === activeTab

  const tabConfig = setupProjectTabLookup(tab)

  if (tabConfig == null) {
    return null
  }

  const Icon = tabConfig.Icon

  return (
    <div
      className={twMerge(
        'flex items-center gap-2 p-2 rounded',
        isActive && 'bg-semantic-neutral-bg-subtle'
      )}
    >
      <Icon className='h-4 w-4 text-semantic-neutral-icon-default' />
      <label
        className={twMerge(
          'text-[14px] font-semibold',
          !isActive
            ? 'text-semantic-neutral-text-subtle'
            : 'text-semantic-neutral-text-default'
        )}
      >
        {tabConfig.label}
      </label>
    </div>
  )
}

export const SetupProjectTabPanels = memo(() => {
  const activeTab = useActiveTab()
  const tabConfig = setupProjectTabLookup(activeTab)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  if (tabConfig == null) {
    return null
  }

  const { Tab } = tabConfig

  return (
    <>
      <StepHeader>{tabConfig.label}</StepHeader>
      {tabConfig.description != null && (
        <p className='pt-2 text-toggle-text-default text-sm leading-[20px]'>
          {tabConfig.description}
        </p>
      )}
      <Focus contentRef={{ current: ref }}>
        <div className='pt-6 pb-4' ref={(ref) => setRef(ref)} key={activeTab}>
          <Tab />
        </div>
      </Focus>
    </>
  )
})

SetupProjectTabPanels.displayName = 'SetupProjectTabPanels'

function setupProjectTabLookup(activeTab: SetupProjectTabOption) {
  return createLookup<
    Record<
      SetupProjectTabOption,
      {
        label: string
        description?: string
        Icon: React.ComponentType<SvgIconProps>
        Tab: () => JSX.Element
      }
    > & { default: null }
  >({
    name: {
      label: 'Project title',
      Icon: CustomFieldTextSolid,
      Tab: NameStepContent,
    },
    dates: {
      label: 'Set Dates',
      description: `Set your project's start date & deadline.`,
      Icon: CalendarSolid,
      Tab: DateStepContent,
    },
    roles: {
      label: 'Assign Roles',
      description: `Choose the assignee for each role. We'll automatically assign tasks to them!`,
      Icon: UserSolid,
      Tab: RolesStepContent,
    },
    textVariables: {
      label: 'Replace text',
      description: `Set your text variables.`,
      Icon: CustomFieldTextSolid,
      Tab: TextReplacementsStepContent,
    },
    default: null,
  })(activeTab)
}
