import { showToast } from '@motion/ui/base'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import {
  useReadProjectFn,
  useUpdateProjectStageDueDate as useUpdateProjectStageDueDateCall,
} from '~/global/rpc/v2'
import { useCallback } from 'react'

export function useUpdateProjectStageDueDate() {
  const { mutateAsync: updateProjectStageDueDate } =
    useUpdateProjectStageDueDateCall()
  const readProject = useReadProjectFn()
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const modalApi = useModalApi()

  return useCallback(
    async (
      projectId: ProjectSchema['id'],
      stageDefinitionId: StageDefinitionSchema['id'],
      dueDate: StageSchema['dueDate']
    ) => {
      if (!isFlowProject(await readProject(projectId))) {
        throw new Error('Project is not a flow project', {
          cause: {
            projectId,
            stageDefinitionId,
            dueDate,
          },
        })
      }

      const formattedDueDate = parseDate(dueDate).toISODate()

      let dateAdjustmentStrategy: 'SHIFT' | 'ABSORB' | undefined = undefined
      try {
        if (hasBetterResizeStages) {
          const response = await modalApi.prompt('update-stage-deadline', {
            projectId,
            stageDefinitionId,
            dueDate,
          })

          if (response === ModalDismissed) {
            return
          }

          dateAdjustmentStrategy = response.updateStageOnly ? 'ABSORB' : 'SHIFT'
        }
        await updateProjectStageDueDate({
          projectId,
          stageDefinitionId,
          dueDate: formattedDueDate,
          dateAdjustmentStrategy,
        })

        showToast('success', 'Stage due date saved')
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'update-project-stage-due-date' },
        })

        showToast('error', 'There was an error updating the stage due date')
      }
    },
    [readProject, updateProjectStageDueDate, hasBetterResizeStages, modalApi]
  )
}
