import { BRACKET_WRAP_RE } from '@motion/shared/common'

import { mergeAttributes, nodePasteRule } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { NodeVariableLabel } from './node-variable-label'
import { suggestion } from './suggestion'
import { type MentionOptions, type SuggestionStorage } from './types'

export const pasteRegex = /\{\{(.*?)\}\}/g

export const FlowVariable = ({ taskId }: { taskId: string }) =>
  Mention.extend<MentionOptions, SuggestionStorage>({
    name: 'flowVariable',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,

    addOptions() {
      return {
        HTMLAttributes: {},
        renderLabel: (item) => '',
        suggestion: suggestion(this.name),
      }
    },

    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-flow-variable-id'),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {}
            }

            return {
              'data-flow-variable-id': attributes.id,
            }
          },
        },
        taskId: {
          // Breaks regex parsing if not removed
          default: taskId.replace('|<placeholder>', ''),
        },
      }
    },

    parseHTML() {
      return [{ tag: 'span[data-flow-variable-id]' }]
    },

    renderHTML({ HTMLAttributes, node }) {
      return [
        'span',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        `{{${node.attrs.id}}}`,
      ]
    },

    addPasteRules() {
      return [
        nodePasteRule({
          find: BRACKET_WRAP_RE,
          type: this.type,
          getAttributes: (match) => ({
            id: match[1],
          }),
        }),
      ]
    },

    addStorage() {
      return {
        suggestions: [],
      }
    },

    addNodeView() {
      return ReactNodeViewRenderer(NodeVariableLabel)
    },
  })

export function renderFlowVariableTextHtml(key: string) {
  return `<span data-flow-variable-id="${key}">{{${key}}}</span>`
}
