import { isObjectNoneId } from '@motion/shared/identifiers'
import { Tooltip, useContextMenu } from '@motion/ui/base'
import {
  getProjectStageFromDefinitionId,
  getStageVariant,
  isStageActive,
} from '@motion/ui-logic/pm/project'

import { StageLabel } from '~/global/components/labels'
import { useProject, useStageDefinition } from '~/global/hooks'

import { type LookupArgs } from './lookup-header'
import { StageHeaderContextMenuContent } from './stage-header-context-menu'
import { StageHeaderEndContent } from './stage-header-end-content'
import { ToggleTasksButton } from './toggle-tasks-button'

import { useSidebarTaskContext } from '../context'

type StageHeaderProps = LookupArgs

const SHOW_NAME_TOOLTIP_AFTER_LENGTH = 14

export function StageHeader({ groupId, toggle, row }: StageHeaderProps) {
  const stageDefinition = useStageDefinition(groupId)
  const { projectId } = useSidebarTaskContext()

  const project = useProject(projectId)
  const projectStage = getProjectStageFromDefinitionId(
    project,
    stageDefinition.id
  )

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()

  if (
    isObjectNoneId(stageDefinition) ||
    project == null ||
    projectStage == null
  ) {
    return null
  }

  const isActive = isStageActive(project, stageDefinition.id)
  const stageVariant = getStageVariant(projectStage)

  const nameTooltipContent =
    stageDefinition.name.length > SHOW_NAME_TOOLTIP_AFTER_LENGTH
      ? stageDefinition.name
      : undefined

  return (
    <>
      <div
        className='flex flex-row items-center gap-1 flex-1 overflow-hidden'
        onContextMenu={
          stageVariant === 'skipped' ? undefined : handleContextMenu
        }
      >
        <Tooltip content={nameTooltipContent} asChild>
          <StageLabel
            value={stageDefinition}
            variant={stageVariant}
            active={isActive}
          />
        </Tooltip>

        {row?.subRows.length ? <ToggleTasksButton row={row} /> : null}
      </div>

      <StageHeaderEndContent
        toggle={toggle}
        projectStage={projectStage}
        project={project}
        stageDefinition={stageDefinition}
        stageVariant={stageVariant}
        isActive={isActive}
      />
      <ContextMenuPopover
        renderContent={({ close }) => (
          <StageHeaderContextMenuContent
            close={close}
            project={project}
            projectStage={projectStage}
            stageDefinition={stageDefinition}
          />
        )}
      />
    </>
  )
}
