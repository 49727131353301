import { type UploadedFileSchema } from '@motion/rpc-types'
import { ActionList } from '@motion/ui/base'

import { useAttachmentActionItems } from './hooks'

export type AttachmentActionListProps = {
  attachment: UploadedFileSchema
  close: () => void
}

export const AttachmentActionList = ({
  attachment,
  close,
}: AttachmentActionListProps) => {
  const { sections } = useAttachmentActionItems(attachment)

  return <ActionList sections={sections} onActionAnyItem={close} />
}
