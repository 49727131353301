import { PopoverTrigger } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { FlowVariableFormSearchableList } from './flow-variable-form-searchable-list'

export type FlowVariableFormDropdownProps<
  T extends FlowTemplateFormFields['textVariables'][number],
> = {
  items: T[]
  onChange: (item: T) => void
  selectedItem?: T | undefined
  children: React.ReactNode
}

export const FlowVariableFormDropdown = <
  T extends FlowTemplateFormFields['textVariables'][number],
>({
  items,
  selectedItem,
  onChange,
  children,
}: FlowVariableFormDropdownProps<T>) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <FlowVariableFormSearchableList
          items={items}
          close={close}
          onChange={onChange}
          selectedItem={selectedItem}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
