import { classed } from '@motion/theme'
import {
  formatToReadableWeekDayMonth,
  getTaskScheduledDateString,
  templateStr,
} from '@motion/ui-logic'

import { type TaskWithRelations } from '~/global/proxies'

export const ScheduleReason = ({ task }: { task: TaskWithRelations }) => {
  const taskScheduledDate = getTaskScheduledDateString(task)

  return (
    <Reason>
      {templateStr('{{scheduledDate}} {{dueDate}}', {
        scheduledDate: taskScheduledDate,
        dueDate: task?.dueDate
          ? templateStr('(due {{date}})', {
              date: formatToReadableWeekDayMonth(task.dueDate),
            })
          : '',
      })}
    </Reason>
  )
}

const Reason = classed('span', {
  base: `text-semantic-neutral-text-subtle text-xs`,
})
