import { ScheduledAfterDeadlineModal } from './scheduled-after-deadline-modal'

import { usePastDueTasksBySchedule } from '../../hooks/use-past-due-tasks-by-schedule'

// @deprecated use PastDueModal instead
export const LegacyScheduledAfterDeadlineModalWrapper = () => {
  const pastDeadlineTasks = usePastDueTasksBySchedule()

  return <ScheduledAfterDeadlineModal pastDeadlineTasks={pastDeadlineTasks} />
}
