import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { createLookupById, pick } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useNavigateByRouteId } from '~/routing/hooks'
import { useMemo } from 'react'

import { useAvailableGroupByFields } from './use-available-group-by-fields'

import {
  ReorderGroupByButton,
  type ReorderGroupByButtonProps,
} from '../../header'
import {
  GroupByButton,
  type GroupByValue,
} from '../../header/group-by/group-by-button'
import { usePageData } from '../../routes/hooks'
import { useViewState, type ViewState } from '../../view-state'

export const ConnectedGroupByButton = () => {
  const [viewState, setViewState] = useViewState()
  const [filterState] = useActiveFilter()

  const data = usePageData()

  const getAvailableGroupByFields = useAvailableGroupByFields()

  const navigate = useNavigateByRouteId()
  const canStackProjects = viewState.page === 'gantt'

  const onChange = (value: GroupByValue) => {
    const availableFields = getAvailableGroupByFields(value.show)
    const fieldsLookup = createLookupById(availableFields)

    recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PIVOT_GROUP_BY', {
      hideEmptyGroups: value.hideEmptyGroups,
      stackProjects: value.stackProjects,
      groups: value.fields.map((v) => v.id),
      show: value.show,
    })

    setViewState((prev) => {
      const fieldKeys = value.fields
        .filter((f) => fieldsLookup[f.id] != null)
        .map((x) => x.id)

      return {
        ...prev,
        groupBy: {
          ...prev.groupBy,
          fields: value.fields.map((f) => ({
            key: f.id,
            by: f.by,
          })),
          order: pick(prev.groupBy.order, fieldKeys),
          hideEmpty: value.hideEmptyGroups,
          stackProjects: value.stackProjects,
        },
      } satisfies ViewState
    })

    navigate('parent', { type: value.show })
  }

  const onOrderChange: ReorderGroupByButtonProps['onChange'] = (
    groupId,
    order
  ) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PIVOT_GROUP_BY_ORDER')
    setViewState((prev) => ({
      ...prev,
      groupBy: {
        ...prev.groupBy,
        order: {
          ...prev.groupBy.order,
          [groupId]: order,
        },
      },
    }))
  }

  const value = useMemo((): GroupByValue => {
    const available = getAvailableGroupByFields(filterState.target)
    return {
      fields: viewState.groupBy.fields
        .map((g) => {
          const groupableField = available.find((x) => x.id === g.key)
          if (groupableField == null) return null

          return {
            ...groupableField,
            by: g.by,
          }
        })
        .filter(Boolean),
      hideEmptyGroups: viewState.groupBy.hideEmpty,
      stackProjects: viewState.groupBy.stackProjects,
      show: filterState.target,
    }
  }, [getAvailableGroupByFields, filterState.target, viewState.groupBy])

  return (
    <>
      <GroupByButton
        getAvailableFields={getAvailableGroupByFields}
        value={value}
        onChange={onChange}
        max={3}
        canChangeLastRow={data.lock.projectId == null}
        canStackProjects={canStackProjects}
      />
      {value.fields.length > 0 && (
        <ReorderGroupByButton
          value={value.fields}
          sortOrder={viewState.groupBy.order}
          target={filterState.target}
          onChange={onOrderChange}
          onResetOrder={(groupId) => onOrderChange(groupId, [])}
        />
      )}
    </>
  )
}
