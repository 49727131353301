import { PlusSolid } from '@motion/icons'
import { Button, Tooltip } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useFieldArray } from 'react-hook-form'

import { RoleField } from './role-field'

import { useFlowTemplateForm } from '../hooks'
import { createNewRole, MAX_NUMBER_VARIABLES } from '../utils'

export const ControlledRoleFields = () => {
  const { form } = useFlowTemplateForm()
  const { control, watch } = form
  const modalApi = useModalApi()

  const { append, remove } = useFieldArray({
    control,
    name: 'roles',
  })

  // watching because useFieldArray `fields` doesn't re-render on append/remove
  const roles = watch('roles')

  const onRemove = async (index: number) => {
    const res = await modalApi.prompt('confirm', {
      analytics: {
        name: 'template-delete-role',
      },
      title: 'Are you sure you want to delete this role?',
      description:
        'All tasks assigned to this role will be marked as Unassigned.',
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete role',
    })
    if (res === ModalDismissed) return
    remove(index)
  }

  function addNewRole() {
    const newRole = createNewRole()
    append(newRole)
  }

  return (
    <>
      <div className='flex flex-col gap-1.5 pl-1 pb-2'>
        {roles.map((field, i) => (
          <RoleField
            key={field.id}
            index={i}
            namePath={`roles.${i}.name`}
            colorPath={`roles.${i}.color`}
            onRemove={() => onRemove(i)}
            addNewRole={addNewRole}
          />
        ))}
      </div>

      <Tooltip
        content={
          roles.length >= MAX_NUMBER_VARIABLES
            ? `You can only add up to ${MAX_NUMBER_VARIABLES} roles`
            : undefined
        }
      >
        <Button
          variant='muted'
          sentiment='neutral'
          alignment='left'
          size='small'
          disabled={roles.length >= MAX_NUMBER_VARIABLES}
          onClick={() => {
            addNewRole()
          }}
        >
          <PlusSolid />
          Add role
        </Button>
      </Tooltip>
    </>
  )
}
