import { HttpConnectionError } from '@motion/rpc/fetch'
import { logEvent } from '@motion/web-base/analytics'
import { errorInDev, logInDev, makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { firebase } from '@motion/web-common/firebase'
import { featureFlags } from '@motion/web-common/flags'
import { stats } from '@motion/web-common/performance'

import { DateTime } from 'luxon'

import * as backgroundRegistrar from './background/backgroundHandlerRegistrar'
import api from './chromeApi/chromeApiBackground'
import { type MessageListener } from './chromeApi/chromeApiTypes'
import { websocketsService } from './services/websockets-service'
import { doCheckStripeSubscription } from './utils'
import { initWebApp, sendBackgroundLoaded } from './webapp/background'

const log = makeLog('loaded-background')

// The statsig SDK relies on the global window object being defined
// It caches all the fetched flags to this object
if (!globalThis.window) {
  Object.defineProperty(globalThis, 'window', {
    value: {},
  })
}

let syncUserID: string | null = null
let firebaseUnsubscribe: undefined | (() => void)

const onStart = async () => {
  // initialize ff before auth callback
  await featureFlags.refresh()

  window.addEventListener('unhandledrejection', (event) => {
    if (
      !event.reason ||
      event.reason instanceof HttpConnectionError ||
      event.reason === 'AbortError'
    ) {
      return stats.increment('errors.fetch', 1)
    }

    Sentry.captureException((event as any)?.reason ?? event, {
      tags: { position: 'unhandledrejection' },
    })

    // Have to refresh the page until it works
    // https://groups.google.com/g/google-cloud-firestore-discuss/c/GMz21VhkidM?pli=1
    if (
      __ENV__ !== 'qa' &&
      event.reason != null &&
      event.reason.toString().startsWith('FirebaseError:')
    ) {
      logInDev('Found firestore connection error - refreshing page')
      window.location.reload()
    }
  })

  Sentry.addBreadcrumb({
    message: 'check sync user',
    data: { hasUser: firebase.auth().currentUser != null },
  })

  firebaseUnsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
    stats.time('loaded_background.on_auth_state_changed', async () => {
      Sentry.addBreadcrumb({
        message: 'firebase-change',
        data: { hasUser: user != null },
      })
      if (user) {
        const localStorageUser = {
          id: user.uid,
          email: user.email ?? '',
          displayName: user.displayName ?? '',
          picture: user.photoURL ?? '',
          uid: user.uid,
          dateCreated: user.metadata.creationTime
            ? DateTime.fromHTTP(user.metadata.creationTime).toISO()
            : DateTime.now().toISO(),
        }

        syncUserID = user.uid

        Sentry.addBreadcrumb({ message: 'initWebApp.start' })

        await log.time('initWebApp', () => initWebApp(syncUserID!))

        Sentry.addBreadcrumb({ message: 'initWebApp.complete' })

        // delay because webapp wrapper might not be ready yet
        setTimeout(() => {
          void api.runtime.sendMessage({
            event: 'identifyUser',
            user: localStorageUser,
          })
        }, 3000)

        logInDev('idToken', await firebase.auth().currentUser?.getIdToken())

        void websocketsService.connect()
        void sendBackgroundLoaded(syncUserID)
      } else {
        syncUserID = null

        Sentry.addBreadcrumb({ message: 'null user' })
        await sendBackgroundLoaded(null)

        if (typeof firebaseUnsubscribe === 'function') {
          Sentry.addBreadcrumb({ message: 'unsubscribe firebase' })
          firebaseUnsubscribe()
          firebaseUnsubscribe = undefined
        }

        await api.storage.local.clear()
      }
    })
  })
}

export const initializeSession = () => {
  return log.time('onStart', () => {
    api.runtime.onMessage.addListener(backgroundListener)

    return onStart()
      .then(() => {
        Sentry.addBreadcrumb({ message: 'loadedBackground.onStart.complete' })
        return
      })
      .catch((ex) => {
        errorInDev(ex)
        const wrapped = new Error('Failed to load app', { cause: ex })
        Sentry.captureException(wrapped, {
          tags: {
            position: 'loadedBackground.onStart',
          },
          extra: {
            message: 'message' in ex ? ex.message : undefined,
          },
        })
        const reloads = parseInt(
          sessionStorage.getItem('motion-reload-count') ?? '0'
        )
        if (reloads < 3) {
          setTimeout(() => {
            sessionStorage.setItem('motion-reload-count', String(reloads + 1))
            window.location.reload()
          }, 1000)
        }
      })
  })
}

export const terminateSession = async () => {
  log('terminate')
  if (typeof firebaseUnsubscribe === 'function') {
    Sentry.addBreadcrumb({ message: 'unsubscribe firebase' })
    firebaseUnsubscribe()
    firebaseUnsubscribe = undefined
  }
  api.runtime.onMessage.removeListener(backgroundListener)
}

const backgroundListener: MessageListener = async (
  request,
  sender,
  sendResponse
) => {
  if (backgroundRegistrar.isActionRegistered(request.event)) {
    try {
      const res = await backgroundRegistrar.fire(request.event, request.args)
      sendResponse({ res })
    } catch (e) {
      sendResponse({ error: e instanceof Error ? e.message : e })
    }
    return
  }

  switch (request.event) {
    case 'logEvent': {
      logEvent(request.eventName, request.properties)
      sendResponse(undefined)
      break
    }

    case 'checkStripeSubscription': {
      const result = await doCheckStripeSubscription()
      sendResponse({ result })
      break
    }
    default: {
      break
    }
  }
}
