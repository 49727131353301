import { LoadingSpinner, showToast } from '@motion/ui/base'
import { type TaskFormFields } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useSaveTask, useTaskForm } from '~/areas/tasks/modals/task-modal/hooks'
import { DateTime } from 'luxon'
import { type FormEvent } from 'react'

import { TaskFormFooter } from './task-form-footer'
import { TaskFormHeader } from './task-form-header'
import { TaskFormNameField } from './task-form-name-field'

import { type ReduxCalendarEvent } from '../../../../../state/calendarEvents/calendarEventsTypes'
import { EventOrTaskToggle } from '../common'
import {
  useApplySharedValues,
  useClosePeekModal,
  usePeekModalCommonContext,
} from '../hooks'

type TaskFormContentProps = {
  event: ReduxCalendarEvent
}

export const TaskFormContent = ({ event }: TaskFormContentProps) => {
  const { form } = useTaskForm()
  const saveTask = useSaveTask()
  const workspaceId = form.watch('workspaceId')

  const closePeekModal = useClosePeekModal()
  const { setSharedValues, sharedValues } = usePeekModalCommonContext()
  useApplySharedValues({
    enabled: form.getValues('id') == null,
    sharedValues,
    setValue: form.setValue,
  })

  const onFormSubmit = async (data: TaskFormFields) => {
    /**
     * Bugfix
     * Determined that deadlineDate being set a day later in reference to the startDate was causing 16-hr tasks to be returned instead of reminders. This sets it to the start and unsets other fields as needed.
     *
     * When we do the task peek modal we should change this
     */
    const modifiedData = event.isAllDay
      ? {
          ...data,
          isFixedTimeTask: false,
          deadlineDate: event.start,
          startDate: event.start,
          scheduledStart: null,
          scheduledEnd: null,
          duration: 0,
        }
      : data

    closePeekModal()
    await saveTask(modifiedData)
    recordAnalyticsEvent('CALENDAR_PEEK_MODAL_CREATE_FIXED_TIME_TASK', {
      isReminder: event.isAllDay,
      isBeforeNow: DateTime.fromISO(event.start) < DateTime.now(),
    })
  }

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    await form.handleSubmit(onFormSubmit, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string') return

      showToast('error', message)
    })(e)
  }
  const isLoading = !workspaceId
  return (
    <form
      className='w-80 overflow-hidden rounded-[3px] flex flex-col'
      onSubmit={onSubmit}
    >
      {isLoading ? (
        <LoadingSpinner className='self-center m-2' />
      ) : (
        <>
          <TaskFormHeader />
          <div className='overflow-y-auto overflow-hidden'>
            <TaskFormNameField />
            <EventOrTaskToggle
              onChange={() => setSharedValues({ name: form.getValues('name') })}
            />
          </div>
          <TaskFormFooter onSubmit={onSubmit} />
        </>
      )}
    </form>
  )
}
