import { PlusSolid } from '@motion/icons'
import { ButtonGroup, GradientButton, Shortcut } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'

export function AddTaskProjectButtons() {
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const { isElectron } = getWindowData()

  return (
    <div className='w-full px-3'>
      <ButtonGroup nowrap stretch>
        <GradientButton
          fullWidth
          url={buildTaskModalUrl()}
          onClick={() => {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
              type: 'task',
            })
          }}
          shortcut='T'
          renderShortcutTooltip={({ shortcut }) =>
            templateStr(
              'Press {{ shortcut }} to quickly create a task' +
                (isElectron
                  ? ' (you can also do this with {{ optSpace }}'
                  : ''),
              {
                shortcut,
                optSpace: <Shortcut shortcut='option+space' />,
              }
            )
          }
        >
          <PlusSolid />
          Task
        </GradientButton>

        <GradientButton
          fullWidth
          url={buildProjectModalUrl()}
          onClick={() => {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
              type: 'project',
            })
          }}
          shortcut='P'
          renderShortcutTooltip={() =>
            'Press {{ shortcut }} to quickly create a new project'
          }
        >
          <PlusSolid />
          Project
        </GradientButton>
      </ButtonGroup>
    </div>
  )
}
