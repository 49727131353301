import {
  CalendarPlusSolid,
  RefreshOutline,
  StatusCompletedSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import {
  formatToReadableWeekDayMonth,
  getTaskScheduledDateString,
  templateStr,
} from '@motion/ui-logic'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import { canTaskBeExtended, isSchedulingTask } from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskSchema } from '@motion/zod/client'

import { SchedulingEventDropdown } from '~/areas/task-project/components'
import {
  useDeleteTask,
  useReadTopmostParentTask,
  useResolveTask,
  useTaskUpdater,
} from '~/areas/tasks/hooks'
import { useDoTaskASAP } from '~/areas/tasks/hooks/actions'
import { StatusBadge } from '~/global/components/badges'
import { GhostStatusDescription } from '~/global/components/tooltips'
import {
  useRecordResolvePastDueTaskUFFeedback,
  useWorkspaceStatusById,
} from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import React, { type MouseEvent, useMemo } from 'react'
import { useNavigate } from 'react-router'

import {
  ChangeDeadlineButton,
  DeleteTaskButton,
  DoEarlierButton,
} from './buttons'
import { IgnoreWarningButton } from './buttons/ignore-warning-button'

import {
  resetDeadlineTypeIfIsEmpty,
  resetPriorityIfIsAsap,
} from '../../../../../utils/teamTaskUtils'

interface ScheduledAfterDeadlineRowProps {
  task: TaskSchema
}

export const ScheduledAfterDeadlineRow = ({
  task,
}: ScheduledAfterDeadlineRowProps) => {
  const [loading, setLoading] = React.useState(false)

  const workspaceStatus = useWorkspaceStatusById(task.statusId)
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()
  const updateTask = useTaskUpdater()
  const { completeTask } = useResolveTask()
  const doTaskASAP = useDoTaskASAP()
  const deleteTask = useDeleteTask()
  const readTopmostParentTask = useReadTopmostParentTask()
  const recordResolvePastDueUFFeedback = useRecordResolvePastDueTaskUFFeedback()

  const isGhost = isGhostTask(task)
  const isRecurring = task.type === 'RECURRING_INSTANCE'
  const isScheduling = isSchedulingTask(task) && task.meetingTaskId == null

  const canExtend = useMemo(() => canTaskBeExtended(task, null), [task])

  if (!task) {
    // Needed to update task in backend
    return null
  }

  const taskScheduledDate = getTaskScheduledDateString(task)

  const doUpdateParentTask = async (
    updates: Partial<TaskSchema> & { ignoreWarnOnPastDue: boolean }
  ) => {
    const parentTask = await readTopmostParentTask(task.id)

    if (parentTask == null) return
    setLoading(true)

    await updateTask(parentTask, updates)
    setLoading(false)
  }

  const doUpdateTask = async (updates: Partial<TaskSchema>) => {
    setLoading(true)

    if (
      updates.hasOwnProperty('dueDate') ||
      updates.hasOwnProperty('startDate')
    ) {
      updates.priorityLevel = resetPriorityIfIsAsap(
        updates.priorityLevel ?? task.priorityLevel
      )
      updates.deadlineType = resetDeadlineTypeIfIsEmpty(
        updates.deadlineType ?? task.deadlineType
      )
    }

    await updateTask(task, updates)

    setLoading(false)
  }

  const doTaskASAPHandler = () => {
    recordAnalyticsEvent('PAST_DEADLINE_DO_ASAP')
    void doTaskASAP(task.id, { source: 'past_deadline' })
    recordResolvePastDueUFFeedback('meet_deadline_asap')
  }

  const changeTaskDueDate = async (
    dateString: string,
    deadline?: TaskSchema['deadlineType']
  ) => {
    const dueDate = parseDate(dateString).endOf('day').toISO()

    await doUpdateTask({
      dueDate,
      deadlineType: deadline,
    })

    recordResolvePastDueUFFeedback('extend')
  }

  const completeTaskHandler = async (e: MouseEvent) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await completeTask(task.id)
      recordResolvePastDueUFFeedback('complete_or_cancel')
    } finally {
      setLoading(false)
    }
  }

  const onDeleteTaskClick = async () => {
    setLoading(true)
    await deleteTask(task.id)
    recordResolvePastDueUFFeedback('complete_or_cancel')
    setLoading(false)
  }

  const editTaskHandler = () => {
    navigate(buildTaskModalUrl({ task: task.id }))
  }

  const setHardDeadline = async () => {
    recordAnalyticsEvent('PAST_DEADLINE_MUST_COMPLETE_BY_DEADLINE')
    await doUpdateTask({ deadlineType: 'HARD' })
    recordResolvePastDueUFFeedback('meet_deadline_hard')
  }

  const ignoreWarningForInstance = async () => {
    recordAnalyticsEvent('PAST_DEADLINE_IGNORE_WARNING_INSTANCE')
    await doUpdateTask({ ignoreWarnOnPastDue: true })
    recordResolvePastDueUFFeedback('ignore')
  }
  const ignoreWarningForParent = async () => {
    if ('parentRecurringTaskId' in task && task.parentRecurringTaskId == null)
      return
    recordAnalyticsEvent('PAST_DEADLINE_IGNORE_WARNING_PARENT')
    await doUpdateParentTask({
      ignoreWarnOnPastDue: true,
    })
    recordResolvePastDueUFFeedback('ignore')
  }

  return (
    <Row
      onClick={() => {
        editTaskHandler()
      }}
    >
      <Tooltip
        asChild
        renderContent={isGhost ? () => <GhostStatusDescription /> : null}
      >
        <Details>
          {workspaceStatus && (
            <div className='-mt-px'>
              <StatusBadge
                value={workspaceStatus}
                iconVariant={isGhost ? 'isUnvisitedStage' : undefined}
                hideTooltip={isGhost}
              />
            </div>
          )}

          <TaskTitleBar>
            <TaskTitle>{task.name}</TaskTitle>
            {isRecurring ? (
              <RefreshOutline
                className='text-semantic-neutral-icon-default shrink-0'
                width={14}
                height={14}
              />
            ) : null}
          </TaskTitleBar>
          <div className='row-start-2 col-start-2'>
            <Reason>{taskScheduledDate}</Reason>{' '}
            {task.dueDate && (
              <span className='text-semantic-neutral-text-subtle'>
                {templateStr('(due {{date}})', {
                  date: formatToReadableWeekDayMonth(task.dueDate),
                })}
              </span>
            )}
          </div>
        </Details>
      </Tooltip>

      <Actions onClick={(e) => e.stopPropagation()}>
        <DoEarlierButton
          loading={loading}
          task={task}
          onDoAsap={doTaskASAPHandler}
          onHardDeadline={setHardDeadline}
        />

        <ChangeDeadlineButton
          loading={loading}
          task={task}
          canBeExtended={canExtend}
          onSetScheduledDate={(value) => {
            recordAnalyticsEvent('PAST_DEADLINE_SCHEDULED_DATE')
            void changeTaskDueDate(value)
          }}
          onSetDeadline={(value) => {
            void recordAnalyticsEvent('PAST_DEADLINE_LATER_DATE')
            void changeTaskDueDate(value.date, value.deadline)
          }}
        />

        <IgnoreWarningButton
          loading={loading}
          task={task}
          onIgnoreInstance={ignoreWarningForInstance}
          onIgnoreAll={ignoreWarningForParent}
        />
        {isScheduling ? (
          <SchedulingEventDropdown taskId={task.id}>
            <Button size='small' sentiment='primary' variant='outlined'>
              <CalendarPlusSolid />
              Schedule
            </Button>
          </SchedulingEventDropdown>
        ) : (
          <IconButton
            icon={StatusCompletedSolid}
            size='small'
            sentiment='neutral'
            disabled={loading}
            onClick={completeTaskHandler}
          />
        )}
        <DeleteTaskButton
          task={task}
          force={!canExtend}
          onClick={onDeleteTaskClick}
          disabled={loading}
        />
      </Actions>
    </Row>
  )
}

const Row = classed('div', {
  base: `
    cursor-pointer
    grid grid-cols-[1fr_auto]
    py-3 pl-2 pr-3 gap-2
    rounded
    bg-semantic-neutral-bg-default
    text-semantic-neutral-text-default
    hover:bg-semantic-neutral-bg-active-default
  `,
})

const Actions = classed('div', {
  base: `
    flex justify-end items-center gap-1
    [&>button]:shrink-0
  `,
})

const Details = classed('div', {
  base: `
    flex-1
    grid grid-cols-[auto_1fr] grid-rows-2 gap-x-2
  `,
})

const TaskTitleBar = classed('div', {
  base: `flex gap-1 items-center overflow-hidden text-xs`,
})

const TaskTitle = classed('span', {
  base: `
    whitespace-nowrap text-ellipsis overflow-hidden
    text-sm font-semibold
  `,
})
const Reason = classed('span', {
  base: `text-semantic-neutral-text-subtle`,
})
