import type { ToggleProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  useMyUserSettings,
  useSaveCalendarDisplaySettings,
} from '@motion/web-common/settings'

export const useShowProjectedEntities = (): [
  boolean,
  ToggleProps['onChange'],
] => {
  const { data: { calendarDisplaySettings } = {} } = useMyUserSettings()
  const showProjectedEntities =
    calendarDisplaySettings?.showProjectedEntities ?? true
  const { mutateAsync: updateCalendarDisplaySettings } =
    useSaveCalendarDisplaySettings()

  const onChangeShowProjectedEntities: ToggleProps['onChange'] = async (e) => {
    const shouldShowProjectedEntities = !e.currentTarget.checked

    recordAnalyticsEvent('CALENDAR_TOGGLE_HIDE_PROJECTED_ENTITIES', {
      hide: !shouldShowProjectedEntities,
    })

    await updateCalendarDisplaySettings({
      showProjectedEntities: shouldShowProjectedEntities,
    })
  }

  return [showProjectedEntities, onChangeShowProjectedEntities]
}
