import { isCompletedStatus } from '@motion/shared/common'
import { getAutoscheduleInfo, scheduleTypeToInfo } from '@motion/ui-logic'
import { getScheduledDate } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useTaskAutoscheduleUpdater } from '~/areas/tasks/hooks'
import { useWorkspaceStatusById } from '~/global/hooks'

export const useAutoSchedule = (task: TaskSchema) => {
  const onChange = useTaskAutoscheduleUpdater()
  const isAutoScheduled = task.isAutoScheduled

  const statusId = task.statusId
  const status = useWorkspaceStatusById(statusId)
  const isCompleted = isCompletedStatus(status)

  const { type } = getAutoscheduleInfo(task, isAutoScheduled, isCompleted)

  const scheduledDateInfo = scheduleTypeToInfo[type]

  const scheduledDate = getScheduledDate(task)
  let formattedScheduledDate = null
  if (
    isAutoScheduled &&
    scheduledDate != null &&
    scheduledDate &&
    type !== 'pending'
  ) {
    formattedScheduledDate = scheduledDate.toFormat('ccccc L/d')
  }

  return {
    isAutoScheduled,
    formattedScheduledDate,
    scheduledDateInfo: scheduledDateInfo,
    toggleChange: () => onChange(task, !isAutoScheduled),
    isError: ['pastDue', 'unfitPastDue'].includes(type),
  }
}
