import { useClosure } from '@motion/react-core/hooks'
import {
  CustomEventHandlers,
  KeyboardShortcuts,
  useRichTextEditor,
} from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { type Editor } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'
import { useWorkspaceActiveMembers } from '~/global/hooks'
import { useMemo, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { suggestion } from '../components/comment/rte-extension/suggestion'
import { useActivityFeedContext } from '../providers'
import { type CommentSubmitPayload, type MentionItem } from '../types'

type useCommentBoxRteOptons = {
  isSlim?: boolean
  initialText?: string
  commentBoxRef: React.RefObject<HTMLElement>
  onSubmit: (comment: CommentSubmitPayload) => void
  onChange?: (value: string) => void
}

export function useCommentBoxRte({
  isSlim,
  initialText = '',
  commentBoxRef,
  onSubmit,
  onChange,
}: useCommentBoxRteOptons) {
  const { onUploadImage, workspaceId } = useActivityFeedContext()
  const { uid: userId } = useAuthenticatedUser()
  const workspaceMembers = useWorkspaceActiveMembers(workspaceId)

  const options = useMemo<MentionItem[]>(
    () =>
      workspaceMembers.map((member) => ({
        label: member.user.name,
        value: member.user.id,
        user: member.user,
      })),
    [workspaceMembers]
  )

  // The suggestions are built the first time this component renders so to be able to mutate the options after the rendering,
  // we need to use an object "reference" which always stay the same, but its content can change
  const optionsRef = useRef(options)
  optionsRef.current = options

  const editor = useRichTextEditor({
    className: twMerge('h-full', isSlim ? '!px-2' : ''),
    placeholder: isSlim ? 'Enter comment' : 'Leave a comment...',
    value: initialText,
    onChange(v) {
      onChange?.(v)
    },
    onImgExpandClick: () => {
      recordAnalyticsEvent('IMAGE_EXPAND_CLICK')
    },
    extensions: [
      CustomEventHandlers(onUploadImage),
      Mention.configure({
        HTMLAttributes: {
          class: 'font-bold',
        },
        suggestion: suggestion({ commentBoxRef, optionsRef }),
      }),
      KeyboardShortcuts.configure({
        shortcuts: {
          'Mod-Enter': ({ editor }) => {
            void handleSubmit(editor)
            return true
          },
        },
      }),
    ],
  })

  // Using a closure because it's used within the tiptap KeydownHandler which doesn't get re-created on rerender
  const handleSubmit = useClosure((editorInstance: Editor) => {
    if (editorInstance.isEmpty) return

    editorInstance.commands.blur()
    const bodyHtml = editorInstance.getHTML()

    onSubmit({ bodyHtml, createdByUserId: userId })
    editorInstance.commands.clearContent(true)
  })

  return {
    editor,
    handleSubmit,
  }
}
