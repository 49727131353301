import { type CustomFieldSchema } from '@motion/shared/custom-fields'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useDeleteCustomField as useDeleteCustomFieldRpc } from './rpc'

export const useDeleteCustomField = () => {
  const modalApi = useModalApi()
  const { mutateAsync: deleteCustomFieldRpc } = useDeleteCustomFieldRpc()

  return useCallback(
    async ({
      workspaceId,
      customField,
    }: {
      workspaceId: string
      customField: Pick<CustomFieldSchema, 'id' | 'name' | 'type'>
    }) => {
      try {
        const response = await modalApi.prompt('confirm-delete-item', {
          analytics: {
            name: 'delete-custom-fields',
          },
          titleValue: customField.name,
          description: (
            <div className='flex flex-col gap-4'>
              <p>This field will be removed from all tasks in the workspace.</p>
              <p>
                <ul className='list-disc list-inside indent-2'>
                  <li>
                    List Views will have the {customField.name} column
                    automatically removed.
                  </li>
                  <li>
                    Kanban Board Cards will not display {customField.name}.
                  </li>
                  <li>
                    If {customField.name} is being used as a filter in any Saved
                    View, it will no longer be filtering by {customField.name}.
                  </li>
                  <li>
                    If {customField.name} is being used as a sort in any Saved
                    View, it will now be sorted by None.
                  </li>
                  <li>
                    If {customField.name} is being used to group by in any Saved
                    View, it will now be grouped by None.
                  </li>
                </ul>
              </p>
            </div>
          ),
          deleteMessage: 'DELETE',
          deleteButtonLabelValue: 'field',
          alignment: 'left',
        })

        if (response === ModalDismissed) return false

        await deleteCustomFieldRpc({
          customFieldId: customField.id,
          workspaceId,
        })
        showToast('success', 'Custom field deleted')
        recordAnalyticsEvent('CUSTOM_FIELD_DELETED', {
          type: customField.type,
        })
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'delete-custom-field' },
        })

        showErrorToast(e)
        throw e
      }
    },
    [deleteCustomFieldRpc, modalApi]
  )
}
