import { PlusSolid } from '@motion/icons'
import { findDefaultStatus } from '@motion/shared/common'
import { Button } from '@motion/ui/base'
import { focusFirstFocusableNode } from '@motion/ui/utils'
import {
  mapCustomFieldToFieldArrayWithValue,
  templateStr,
} from '@motion/ui-logic'
import {
  getEmptyDropzoneId,
  reduceCustomFieldsValuesFieldArrayToRecord,
} from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'

import { useDroppable } from '@dnd-kit/core'
import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { SortableItem } from '~/areas/project-management/components/sortable/sortable-item'
import { useWorkspaceStatuses } from '~/global/hooks'
import { type ComponentRef, type RefObject, useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'

import { DraggableStageTaskWrapper } from './dnd'
import { StageTaskCard } from './task-card'

import { useFlowTemplateForm } from '../../hooks'
import { getInitialStageTaskData, MAX_NUM_STAGE_TASKS } from '../../utils'
import { type StageColumn } from '../styled'

export type StageTasksProps = {
  tasksPath: `stages.${number}.tasks`
  stageColumnRef: RefObject<ComponentRef<typeof StageColumn>>
}

export const StageTasks = ({ tasksPath, stageColumnRef }: StageTasksProps) => {
  const {
    form: { control, watch, getValues },
  } = useFlowTemplateForm()

  const { uid: currentUserId } = useAuthenticatedUser()

  const { append, remove, update } = useFieldArray({
    control,
    name: tasksPath,
    keyName: 'key',
  })

  const fields = watch(tasksPath)

  const workspaceId = watch('workspaceId')
  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(field, {})
  )

  const isMeetingsInProjectsEnabled = useHasTreatment('meetings-in-project')

  useEffect(
    function addNewCustomFieldsToStageTask() {
      const hasNewCustomFields =
        fields.length > 0 &&
        workspaceCustomFields.length !==
          fields[0].customFieldValuesFieldArray.length

      if (hasNewCustomFields) {
        fields.forEach((task, i) => {
          const customFieldValues = reduceCustomFieldsValuesFieldArrayToRecord(
            task.customFieldValuesFieldArray,
            { omitNull: true }
          )
          update(i, {
            ...task,
            customFieldValuesFieldArray: workspaceCustomFields.map((field) =>
              mapCustomFieldToFieldArrayWithValue(field, customFieldValues)
            ),
          })
        })
      }
    },
    [workspaceCustomFields, fields, update]
  )

  const { setNodeRef } = useDroppable({
    id: getEmptyDropzoneId(tasksPath),
  })

  if (fields == null) {
    Sentry.captureException(
      new Error('Stage tasks is null', {
        cause: {
          form: getValues(),
        },
      })
    )
    return null
  }

  return (
    <div className='flex flex-col gap-3 h-full' ref={setNodeRef}>
      {fields.map((task, i) => (
        <SortableItem
          key={task.id}
          id={task.id}
          hasDragHandle
          style={{
            position: 'relative',
          }}
          renderItem={(sortableProps) => {
            return (
              <DraggableStageTaskWrapper {...sortableProps}>
                <StageTaskCard
                  task={task}
                  stageTasksPath={tasksPath}
                  taskBasePath={`${tasksPath}.${i}` as const}
                  onRemove={() => remove(i)}
                />
              </DraggableStageTaskWrapper>
            )
          }}
        />
      ))}
      {MAX_NUM_STAGE_TASKS > fields.length && (
        <Button
          onClick={async () => {
            const newTask = getInitialStageTaskData({
              currentUserId,
              workspaceStatuses,
              defaultStatusId: findDefaultStatus(workspaceStatuses)?.id,
              customFieldValuesFieldArray,
            })
            append(newTask)
            recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
              type: 'add',
            })
            // Sleeping to scroll in a next frame to make sure the item has been added
            await sleep(1)
            stageColumnRef.current?.scrollBy({
              top: 500,
              behavior: 'smooth',
            })

            const newTaskCard = stageColumnRef.current?.querySelector(
              `[id="${newTask.id}"]`
            )

            // Focus task title input
            if (newTaskCard) {
              focusFirstFocusableNode(newTaskCard as HTMLElement)
            }
          }}
          variant='muted'
          sentiment='neutral'
          fullWidth
        >
          <PlusSolid />
          <span className='text-sm'>
            {templateStr('Add {{text}}', {
              text: isMeetingsInProjectsEnabled ? 'task or event' : 'task',
            })}
          </span>
        </Button>
      )}
    </div>
  )
}
