import { type WorkspaceSchema } from '@motion/zod/client'

import { type BadgeSize, WorkspaceBadge } from '~/global/components/badges'
import { useWorkspaceById } from '~/global/hooks'

export type WorkspaceLabelProps = {
  value: WorkspaceSchema | null
  size?: BadgeSize
  nameOnly?: boolean
}

export function WorkspaceLabel({
  size,
  value,
  nameOnly = false,
}: WorkspaceLabelProps) {
  const name = value == null ? 'No workspace' : value.name

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  if (nameOnly) {
    return label
  }

  return (
    <div className='flex gap-1 items-center overflow-hidden leading-4'>
      <WorkspaceBadge size={size} />
      {label}
    </div>
  )
}

export type ConnectedWorkspaceLabelProps = Omit<
  WorkspaceLabelProps,
  'value'
> & {
  id: WorkspaceSchema['id']
}

export function ConnectedWorkspaceLabel({
  id,
  ...rest
}: ConnectedWorkspaceLabelProps) {
  const workspace = useWorkspaceById(id)

  return <WorkspaceLabel value={workspace} {...rest} />
}
