import { useEffect, useMemo } from 'react'

import { useStageTaskField } from './use-stage-task-field'

import { useFlowTemplateForm } from '../../../../hooks'
import { roleToFlowTemplateAssignee } from '../../utils'

export function useFlowTemplateRoles(
  taskBasePath: `stages.${number}.tasks.${number}`
) {
  const {
    form: { watch, setValue },
  } = useFlowTemplateForm()
  const [assigneeVariableKey] = useStageTaskField(
    `${taskBasePath}.assigneeVariableKey`
  )

  const roles = watch('roles')
  const flowTemplateRoles = roles.map(roleToFlowTemplateAssignee)
  const foundRoleIndex = flowTemplateRoles.findIndex(
    (r) => r.key === assigneeVariableKey
  )
  const foundRole =
    foundRoleIndex !== -1 ? flowTemplateRoles[foundRoleIndex] : null

  const selectedRole = useMemo(() => {
    if (foundRole != null && foundRole.name === '') {
      return { ...foundRole, name: `Role ${foundRoleIndex + 1}` }
    }
    return foundRole
  }, [foundRole, foundRoleIndex])

  useEffect(() => {
    if (selectedRole == null) {
      setValue(`${taskBasePath}.assigneeVariableKey`, null)
    }
  }, [selectedRole, setValue, taskBasePath])

  return { flowTemplateRoles, selectedRole }
}
