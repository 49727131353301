import { Header, TextButton } from '../Common'

export interface OpenDesktopAppPageProps {
  imgUrl: string
  onDismiss: () => void
}

export const OpenDesktopAppPage = ({
  imgUrl,
  onDismiss,
}: OpenDesktopAppPageProps) => {
  return (
    <div className='bg-light-300 flex flex-col items-center justify-center gap-12 fixed top-0 left-0 w-screen h-screen'>
      <img src={imgUrl} className='h-[160px] w-[160px]' alt='Motion logo' />
      <div className='flex flex-col gap-5'>
        <Header className='dark:text-light-1200 text-2xl'>
          Open in desktop app
        </Header>
        <TextButton
          buttonType='primary'
          className='underline'
          onClick={onDismiss}
        >
          Open here instead
        </TextButton>
      </div>
    </div>
  )
}
