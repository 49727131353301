import { useSharedState } from '@motion/react-core/shared-state'
import { byProperty, groupInto, ordered } from '@motion/utils/array'
import { PieChart } from '@motion/web-charts/pie'

import { PageTasksContextKey } from '~/areas/tasks/context'
import {
  type NormalTaskWithRelations,
  type RecurringInstanceWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import { colorOfScheduledStatus, labelOfScheduledStatus } from './utils'

import { GridTile } from '../components/grid-tile'
import { type GridTileLayoutProps } from '../components/types'

export type ConnectedScheduledStatusPieChartsProps = GridTileLayoutProps & {}

export const ConnectedScheduledStatusPieChart = (
  props: ConnectedScheduledStatusPieChartsProps
) => {
  const [tasks] = useSharedState(PageTasksContextKey)

  const data = useMemo(() => {
    if (tasks == null) return []

    return groupInto(
      tasks.filter(isNotChunk),
      (task) => task.scheduledStatus ?? 'UNKNOWN'
    )
      .map((g) => {
        return {
          key: g.key,
          name: labelOfScheduledStatus(g.key),
          color: colorOfScheduledStatus(g.key),
          value: g.items.length,
        }
      })
      .sort(
        byProperty(
          'key',
          ordered([
            'ON_TRACK',
            'PAST_DUE',
            'UNFIT_SCHEDULABLE',
            'UNFIT_PAST_DUE',
          ])
        )
      )
  }, [tasks])

  if (tasks == null) return null

  return (
    <GridTile {...props} title='Task Overview'>
      <PieChart data={data} />
    </GridTile>
  )
}

function isNotChunk(
  task: TaskWithRelations
): task is NormalTaskWithRelations | RecurringInstanceWithRelations {
  return task.type !== 'CHUNK'
}
