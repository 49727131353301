import { DotsHorizontalSolid, TrashSolid } from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'

import { useDeleteEvent } from '~/areas/event/hooks'

import { useClosePeekModal } from '../../hooks'
import { useEventForm } from '../hooks'

export const EventActionMenu = () => {
  const closePeekModal = useClosePeekModal()
  const deleteCalendarEvent = useDeleteEvent()
  const form = useEventForm()

  const handleDelete = () => {
    const id = form.getValues('id')
    if (!id) {
      closePeekModal()
      return
    }

    deleteCalendarEvent(id, {
      onConfirmation: async () => {
        closePeekModal()
      },
    })
  }
  return (
    <ActionDropdown
      placement='bottom-end'
      items={[
        {
          content: 'Delete',
          destructive: true,
          prefix: <TrashSolid />,
          onAction: handleDelete,
        },
      ]}
    >
      <IconButton
        size='xsmall'
        variant='muted'
        sentiment='neutral'
        icon={DotsHorizontalSolid}
      />
    </ActionDropdown>
  )
}
