import { ExclamationCircleSolid } from '@motion/icons'
import { isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { Tooltip, UserAvatar } from '@motion/ui/base'
import { type UserInfoSchema } from '@motion/zod/client'

import { useUser } from '~/global/hooks'
import { forwardRef } from 'react'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

export interface UserBadgeProps {
  value: UserInfoSchema | null | undefined
  size?: BadgeSize
}

export const UserBadge = forwardRef<HTMLDivElement, UserBadgeProps>(
  ({ value, size }, ref) => {
    const isUnassigned = !value?.id || isNoneId(value?.id)

    const topRightCorner =
      !isUnassigned &&
      (value.deleted || !value.onboardingComplete || value.isPlaceholder) ? (
        <Tooltip
          asChild
          content={
            value.deleted
              ? 'This user has left the workspace or team.'
              : value.isPlaceholder
                ? 'This user has not yet been invited to Motion. Add them to your team to assign tasks to them and schedule tasks on their Motion calendar!'
                : "The assignee of this task hasn't joined Motion yet - please remind them to join."
          }
        >
          <ExclamationMark data-icon={undefined} />
        </Tooltip>
      ) : undefined

    const pixelSize = getBadgeSizeInPixels(size)

    return (
      <UserAvatar
        id={isUnassigned ? null : value.id}
        name={isUnassigned ? null : value.name}
        size={pixelSize}
        profileUrl={isUnassigned ? null : value.picture}
        topRight={topRightCorner}
        ref={ref}
      />
    )
  }
)
UserBadge.displayName = 'UserBadge'

const ExclamationMark = classed(
  ExclamationCircleSolid,
  'h-full w-full text-semantic-error-icon-default'
)

export type ConnectedUserBadgeProps = Omit<UserBadgeProps, 'value'> & {
  userId: string | null | undefined
}

export const ConnectedUserBadge = forwardRef<
  HTMLDivElement,
  ConnectedUserBadgeProps
>(({ userId, ...rest }, ref) => {
  const user = useUser(userId)

  return <UserBadge value={user} ref={ref} {...rest} />
})
ConnectedUserBadge.displayName = 'ConnectedUserBadge'
