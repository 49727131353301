import { classed } from '@motion/theme'
import { Tag, Tooltip } from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'
import type { ProjectSchema } from '@motion/zod/client'

import type { PMPageUrlParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { useI18N } from '~/global/contexts'
import { useProjectsByFolderId } from '~/global/hooks'
import { forwardRef } from 'react'
import { useParams } from 'react-router-dom'

export const FolderProjectsTag = forwardRef(function FolderProjectsTag() {
  const { folderId } = useParams<PMPageUrlParams>()
  const projects = useProjectsByFolderId(folderId)

  if (projects.length === 0) return null

  return (
    <Tooltip
      placement='bottom-start'
      renderContent={() => (
        <FolderProjectsDropdownContent projects={projects} />
      )}
      noPadding
    >
      <Tag variant='subtle' size='small'>
        <div className='flex flex-row gap-1 items-center justify-center'>
          <ProjectColoredIcon height={12} width={12} />
          {projects.length}
        </div>
      </Tag>
    </Tooltip>
  )
})

type FolderProjectsDropdownContentProps = {
  projects: ProjectSchema[]
}

function FolderProjectsDropdownContent({
  projects,
}: FolderProjectsDropdownContentProps) {
  const { pluralize } = useI18N()

  return (
    <DropdownContainer>
      <ProjectListHeader>
        {pluralize(
          projects.length,
          '1 project in this folder',
          '{{count}} projects in this folder'
        )}
      </ProjectListHeader>
      {projects.map((project) => (
        <ProjectItem key={project.id}>
          <ProjectColoredIcon
            height={12}
            width={12}
            color={project.color}
            className='shrink-0'
          />
          <ProjectNameLabel>{project.name}</ProjectNameLabel>
        </ProjectItem>
      ))}
    </DropdownContainer>
  )
}

const DropdownContainer = classed('div', {
  base: 'flex flex-col w-[224px] items-start self-stretch truncate p-1',
})

const ProjectListHeader = classed('div', {
  base: 'flex px-2 pt-2 pb-0.5 items-center self-stretch text-semantic-neutral-text-subtle text-xs font-semibold',
})

const ProjectItem = classed('div', {
  base: 'flex flex-row gap-1.5 w-full items-center justify-start p-2 text-sm',
})

const ProjectNameLabel = classed('p', {
  base: 'w-full truncate text-left',
})
