import { XSolid } from '@motion/icons'
import { IconButton, Tooltip, UserAvatar, UserCard } from '@motion/ui/base'
import { CalendarCheckbox } from '@motion/ui/calendar'
import { getColorClassForColorId } from '@motion/ui-logic'

import { getTzAbbr } from '~/utils'

type GuestItemProps = {
  checkboxDisabled?: boolean
  name: string
  email: string
  checked: boolean
  onCheckboxChange?: (checked: boolean) => void
  colorId?: string | null
  timezone?: string
  profileUrl?: string
  isMyCalendar?: boolean
  isHost?: boolean
  onRemove?: () => void
}

export function GuestItem({
  checkboxDisabled,
  name,
  email,
  colorId,
  checked,
  onCheckboxChange,
  timezone,
  profileUrl,
  isMyCalendar,
  isHost,
  onRemove,
}: GuestItemProps) {
  return (
    <div className='flex items-start gap-2 p-1'>
      <Tooltip
        asChild
        content={
          checkboxDisabled
            ? 'You don’t have permission to view this calendar'
            : undefined
        }
      >
        <CalendarCheckbox
          colorId={getColorClassForColorId(colorId)}
          disabled={checkboxDisabled}
          labelHidden
          label={`Select ${name}`}
          checked={checked}
          onChange={(checked) => onCheckboxChange?.(checked)}
        />
      </Tooltip>

      <div className='flex-1 overflow-hidden'>
        <UserCard
          name={name}
          email={
            <Tooltip
              placement='top-start'
              asChild
              content={isMyCalendar ? undefined : email}
            >
              {isMyCalendar ? 'My Calendars' : email}
            </Tooltip>
          }
          icon={
            <UserAvatar
              name={name.toUpperCase()}
              id={email}
              profileUrl={profileUrl}
            />
          }
          tag={isHost ? 'Host' : undefined}
        />
      </div>

      {timezone != null && (
        <span className='text-semantic-neutral-text-disabled text-xs'>
          {getTzAbbr(timezone)}
        </span>
      )}

      {!isHost && (
        <Tooltip content='Remove' asChild>
          <IconButton
            icon={XSolid}
            size='xsmall'
            variant='muted'
            sentiment='neutral'
            onClick={onRemove}
          />
        </Tooltip>
      )}
    </div>
  )
}
