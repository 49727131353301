import { type SocketEventType } from '@motion/shared/websockets'
import { throttle } from '@motion/utils/core'
import { makeLog } from '@motion/web-base/logging'
import { websocketsEventSubscriber } from '@motion/web-common/websockets'

import { fetchAllCalendars } from './calendar-list-thunks'

import { store } from '../proxy'

const eventsToSubscribe: SocketEventType[] = [
  'calendarEntry.created',
  'calendarEntry.updated',
  'calendarEntry.deleted',
] as const

type Event = (typeof eventsToSubscribe)[number]

const log = makeLog('[CalendarWebsocketSubscribers]')
let isRegistered = false

const handleMessage = (event: Event) => {
  log(`Received websockets message: ${event}`)
  refetchCalendars()
}

const refetchCalendars = throttle(() => {
  void store.dispatch(fetchAllCalendars())
}, 2_500)

export const registerCalendarWebsocketSubscribers = () => {
  if (isRegistered) {
    return
  }

  isRegistered = true
  log('Registering calendar websocket events')

  const subscriptions = eventsToSubscribe.map((event) => {
    return websocketsEventSubscriber.on(event, () => {
      handleMessage(event)
    })
  })

  return () => {
    subscriptions.forEach((sub) => sub())
    isRegistered = false
  }
}
