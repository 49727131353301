import { type FlowProject } from '@motion/ui-logic/pm/project'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { type OldSkipStagesResponse } from '../../modals/skip-stages-modal'

type OldSkipStagesConfirmModalFn = (
  project: FlowProject,
  stageDefinitionId: StageDefinitionSchema['id']
) => Promise<OldSkipStagesResponse | false>

export function useOldSkipStagesConfirmModalFn(): OldSkipStagesConfirmModalFn {
  const modalApi = useModalApi()

  return useCallback(
    async (project, stageDefinitionId) => {
      let promptResponse: OldSkipStagesResponse = 'create-all'

      const currentStageIndex = project.stages.findIndex(
        (s) => s.stageDefinitionId === project.activeStageDefinitionId
      )
      const nextStageIndex = project.stages.findIndex(
        (s) => s.stageDefinitionId === stageDefinitionId
      )

      if (currentStageIndex < nextStageIndex) {
        const skippedVisitedOrCompleted = project.stages
          .slice(currentStageIndex + 1, nextStageIndex)
          .every((s) => s.visited || s.completedTime != null)

        if (!skippedVisitedOrCompleted) {
          const response = await modalApi.prompt('old-skip-stages-modal', {
            projectDefinitionId: project.projectDefinitionId,
            currentStageDefinitionId: project.activeStageDefinitionId,
            nextStageDefinitionId: stageDefinitionId,
            projectStages: project.stages,
          })
          if (response === ModalDismissed) return false
          promptResponse = response
        }
      }

      return promptResponse
    },
    [modalApi]
  )
}
