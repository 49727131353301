import { AlertModal } from '@motion/ui/base'

interface SkipTeamInvitesConfirmationModalProps {
  onClose: () => void
  isOpen: boolean
  onSubmit: () => void
}

export function SkipTeamInvitesConfirmationModal({
  onClose,
  isOpen,
  onSubmit,
}: SkipTeamInvitesConfirmationModalProps) {
  return (
    <AlertModal
      visible={isOpen}
      onClose={onClose}
      actions={[
        {
          label: 'Invite team members',
          onAction: () => close(),
          variant: 'solid',
          sentiment: 'primary',
        },
        {
          label: 'Continue without inviting anyone',
          onAction: () => onSubmit(),
          variant: 'muted',
          sentiment: 'neutral',
        },
      ]}
      title='Invite your team to get the most out of your trial'
    >
      <p className='whitespace-normal break-words text-sm text-semantic-neutral-text-subtle'>
        It&apos;s free to invite team members during the trial. Many features
        can&apos;t be used without team members. Are you sure you don&apos;t
        want to invite anyone?
      </p>
    </AlertModal>
  )
}
