import { useMemoDeep } from '@motion/react-core/hooks'
import { type CallToAction } from '@motion/shared/call-to-actions'
import { useMyUserSettings } from '@motion/web-common/settings'

import { useCallback } from 'react'

export const useHasSeenCta = () => {
  const { data: settings, status } = useMyUserSettings()

  const newCtas = useMemoDeep(settings?.ctas)

  return useCallback(
    (pgCtaName: CallToAction): { loaded: boolean; seen: boolean } => {
      const ctasLoaded = Boolean(newCtas) && status === 'success'

      return {
        loaded: ctasLoaded,
        seen: Boolean(newCtas?.[pgCtaName]),
      }
    },
    [newCtas, status]
  )
}
