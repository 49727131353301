import { type PMTeamMemberRole } from '@motion/rpc/types'
import { ConfirmationModal } from '@motion/ui/base'
import { titleCase } from '@motion/utils/string'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { Paragraph } from '~/components/Common'
import { useState } from 'react'

import { RoleSelector } from '../components'

export type ChangeRoleModalProps = {
  selectedEmails: string[]
  onChangeRole: (role: PMTeamMemberRole) => void
}

export const ChangeRoleModal = ({
  close,
  selectedEmails,
  onChangeRole,
}: ModalTriggerComponentProps<'change-role'>) => {
  const [bulkRole, setBulkRole] = useState<PMTeamMemberRole | undefined>(
    undefined
  )

  return (
    <ConfirmationModal
      action={{
        label: 'Change role(s)',
        onAction: () => {
          if (bulkRole) {
            onChangeRole(bulkRole)
          }
          close()
        },
        disabled: !bulkRole,
      }}
      description={
        <div className='flex flex-col gap-4 mt-2'>
          <div className='flex items-center gap-4'>
            <span className='text-semantic-neutral-text-subtle font-semibold'>
              New role
            </span>
            <div className='h-8'>
              <RoleSelector onAction={setBulkRole} role={bulkRole} />
            </div>
          </div>
          {bulkRole ? (
            <Paragraph className='text-semantic-neutral-text-subtle'>
              The following users will now have the role{' '}
              <span className='font-semibold'>{titleCase(bulkRole)}</span>:{' '}
              {selectedEmails.join(', ')}
            </Paragraph>
          ) : (
            <Paragraph className='text-semantic-neutral-text-subtle'>
              This operation will apply to the following users:{' '}
              {selectedEmails.join(', ')}
            </Paragraph>
          )}
        </div>
      }
      title='Change user role'
      onClose={close}
      visible
    />
  )
}
