import { calculateDurationInMinutes } from '@motion/ui-logic'

import { useInitialTaskData } from '~/areas/tasks/modals/task-modal/hooks'
import { TaskForm } from '~/areas/tasks/modals/task-modal/task-form'
import { type ReduxCalendarEvent } from '~/state/calendarEvents/calendarEventsTypes'
import { useMemo } from 'react'

import { EventForm } from './event-form'
import { EventFormContent } from './event-form/components'
import { useClosePeekModal, usePeekModalCommonContext } from './hooks'
import { PeekModalPopover } from './peek-modal-popover'
import { TaskFormContent } from './task-form'

type TaskOrEventPeekFormProps = {
  event: ReduxCalendarEvent
  recurringEventParent: ReduxCalendarEvent | undefined
  visible: boolean
  anchor: HTMLElement
}

export const TaskOrEventPeekForm = (props: TaskOrEventPeekFormProps) => {
  const { mode } = usePeekModalCommonContext()
  return mode === 'task' ? (
    <PeekModalTask {...props} />
  ) : (
    <PeekModalEvent {...props} />
  )
}

const PeekModalTask = (props: TaskOrEventPeekFormProps) => {
  const { event, visible, anchor } = props
  const closePeekModal = useClosePeekModal()
  const initialData = useMemo(() => {
    const diffInMinutes = calculateDurationInMinutes(event.start, event.end)
    return {
      name: '',
      scheduledEnd: event.end,
      scheduledStart: event.start,
      isFixedTimeTask: true,
      duration: diffInMinutes ?? 30,
    }
  }, [event.end, event.start])

  const initialTaskData = useInitialTaskData()

  return (
    <TaskForm initialTaskData={initialTaskData} initialValues={initialData}>
      {visible && (
        <PeekModalPopover anchor={anchor} onClose={closePeekModal}>
          <TaskFormContent event={event} />
        </PeekModalPopover>
      )}
    </TaskForm>
  )
}

const PeekModalEvent = (props: TaskOrEventPeekFormProps) => {
  const { event, recurringEventParent, visible, anchor } = props
  const closePeekModal = useClosePeekModal()

  return (
    <EventForm event={event} recurringEventParent={recurringEventParent}>
      {visible && (
        <PeekModalPopover anchor={anchor} onClose={closePeekModal}>
          <EventFormContent />
        </PeekModalPopover>
      )}
    </EventForm>
  )
}
