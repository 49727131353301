import { PlusSolid } from '@motion/icons'

import { useProjectModalUrl } from '~/global/navigation'

import { SortableTreeviewItem } from './sortable-treeview-item'

import { type FlattenedSidebarWorkspacesTreeItem } from '../types'

export type ProjectPlaceholderProps = {
  item: FlattenedSidebarWorkspacesTreeItem
}

export const ProjectPlaceholder = ({
  item: flattenedItem,
}: ProjectPlaceholderProps) => {
  const { item, id, type, level } = flattenedItem

  const buildProjectModalUrl = useProjectModalUrl()

  return (
    <SortableTreeviewItem
      icon={PlusSolid}
      id={item.id}
      label='Add project'
      sortableData={{
        item: item,
        parentId: id,
        parentType: type,
        type: 'PLACEHOLDER',
      }}
      level={level}
      url={buildProjectModalUrl({
        forWorkspace: item.workspaceId,
        // TODO allow predefining folder ID
      })}
      order={item.order}
      disableDrag
    />
  )
}
