import { API, createUseMutation } from '@motion/rpc'

export const useUpdateMyCtaSettings = createUseMutation(
  API.usersV2.updateCtaSettings
)

export const useUpdateMyTimezonesMutation = createUseMutation(
  API.usersV2.updateTimezoneSettings
)

export const useUpdateMyAutoScheduleSettingsMutation = createUseMutation(
  API.usersV2.updateAutoScheduleSettings
)
