import { usePrevious } from '@motion/react-core/hooks'
import {
  convertFormFieldsToTask,
  getTaskFormChangedFields,
  isChangedFieldName,
  type TaskFormFields,
  type TaskUrlSearchParams,
} from '@motion/ui-logic/pm/task'
import { keys } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import { useMyUserSettings } from '@motion/web-common/settings'
import { type RecurringTaskSchema } from '@motion/zod/client'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useAutosaveContext } from '~/areas/task-project/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { type InitialTaskData, useInitialFormData, useTaskForm } from './hooks'

export type TaskFormProps = {
  children: ReactNode
  initialValues?: Partial<TaskFormFields>
  initialTaskData: InitialTaskData
}

export const TaskForm = ({
  children,
  initialValues,
  initialTaskData,
}: TaskFormProps) => {
  const initialFormData = useInitialFormData(initialTaskData, initialValues)

  const { resetLastSavedTime } = useAutosaveContext()
  const { task: taskUrlParam, forTaskId } =
    useSearchParams<TaskUrlSearchParams>()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { isDirty } = form.formState

  const isDuplicateTask = taskUrlParam === 'new' && forTaskId != null
  const previousTaskUrlParam = usePrevious(taskUrlParam)
  useEffect(() => {
    // the url param doesn't exist when closing the modal,
    // so let's keep the previous known state during the full closing animation
    if (taskUrlParam == null) return

    if (!isDirty || previousTaskUrlParam !== taskUrlParam) {
      form.reset(initialFormData, { keepDefaultValues: isDuplicateTask })
    }
  }, [
    form,
    initialFormData,
    isDirty,
    isDuplicateTask,
    previousTaskUrlParam,
    taskUrlParam,
  ])

  useEffect(() => {
    if (previousTaskUrlParam !== taskUrlParam) {
      resetLastSavedTime()
    }
  }, [previousTaskUrlParam, resetLastSavedTime, taskUrlParam])

  return (
    <FormProvider {...form}>
      <TaskFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const TaskFormValuesUpdater = () => {
  const { form } = useTaskForm()
  const { uid: currentUserId } = useAuthenticatedUser()
  const {
    getWorkspaceStatuses,
    getWorkspaceActiveMembers,
    getWorkspaceProjects,
  } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()

  const { data: userSettings } = useMyUserSettings()
  const isTaskDefaultsEnabled = useHasTreatment('task-defaults')
  const userDefinedTaskDefaults = isTaskDefaultsEnabled
    ? userSettings?.taskDefaultSettings
    : undefined

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValues, { name }) => {
      if (name == null) return

      if (isChangedFieldName(name)) {
        const taskCandidate = convertFormFieldsToTask(
          formValues as TaskFormFields
        )
        const wid = formValues.workspaceId
        if (wid == null) {
          throw new Error('Workspace id must be defined')
        }

        const changedFields = getTaskFormChangedFields(taskCandidate, {
          fieldNameBeingUpdated: name,
          currentUserId,
          statuses: getWorkspaceStatuses(wid),
          projects: getWorkspaceProjects(wid),
          members: getWorkspaceActiveMembers(wid),
          customFields: getCustomFields(wid),
          globalTaskDefaults:
            wid === userDefinedTaskDefaults?.global?.workspaceId
              ? userDefinedTaskDefaults?.global
              : undefined,
        })

        keys(changedFields).forEach((key) => {
          const formFieldKey = taskFieldsToFormField[key] ?? key

          if (!(formFieldKey in formValues)) {
            throw new Error(
              `cannot set value of unknown key "${formFieldKey}" in form`
            )
          }

          return form.setValue(formFieldKey, changedFields[key], {
            shouldDirty: true,
          })
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [
    currentUserId,
    form,
    getWorkspaceActiveMembers,
    getCustomFields,
    getWorkspaceProjects,
    getWorkspaceStatuses,
    isTaskDefaultsEnabled,
    userDefinedTaskDefaults,
  ])

  return null
}

const taskFieldsToFormField: Partial<
  Record<keyof RecurringTaskSchema, keyof TaskFormFields>
> = {
  startingOn: 'startDate',
}
