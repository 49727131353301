import { API } from '@motion/rpc'
import { debounce } from '@motion/utils/core'
import { client } from '@motion/web-common/rpc'

import { type MutationIds } from '../handlers/utils'
import {
  type BulkTaskOperations,
  type CalendarsOperations,
  type FeedUpdates,
  type PastDueBatchOperations,
  type ScheduledEntitiesBatchOperations,
  type TaskBatchOperations,
} from '../types'

export function taskRefresh(
  mutation: Partial<MutationIds>
): TaskBatchOperations {
  return { type: 'refresh-tasks', data: mutation }
}

export function feedRefresh(
  feedUpdates: Partial<{ tasks: string[]; project: string[] }>
): FeedUpdates {
  return { type: 'refresh-feed', data: feedUpdates }
}

export function refreshCalendars(): CalendarsOperations {
  return { type: 'refresh-calendars' }
}

export function refreshScheduledEntities(
  scheduledEntityUpdate: Partial<{ workspaceId: string }> = {}
): ScheduledEntitiesBatchOperations {
  return { type: 'refresh-scheduled-entities', data: scheduledEntityUpdate }
}

export function refreshPastDueTasks(): PastDueBatchOperations {
  return { type: 'refresh-past-due-tasks' }
}

export function bulkTaskOperations(
  data: BulkTaskOperations['data'],
  operation: BulkTaskOperations['operation']
): BulkTaskOperations {
  return {
    type: 'bulk-task-operations',
    operation,
    data,
  }
}

export const invalidateWorkspacesV2 = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.workspacesV2.queryKeys.root })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateFolders = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.folders.queryKeys.getAll })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateUserSettings = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.userSettings.queryKeys.root })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)
