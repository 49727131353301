import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type FolderSchema } from '@motion/zod/client'

import { BreadcrumbsLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { useGetFolderById } from '~/global/hooks/folders'
import { useCallback } from 'react'

import { useBulkUpdateSelection } from './use-bulk-update-selection'

import { useBulkOpsState } from '../../contexts'

export function useBulkMoveProjects() {
  const { bulkUpdateProjectSelection } = useBulkUpdateSelection()
  const { pluralize } = useI18N()
  const { selectedIds } = useBulkOpsState()
  const modalApi = useModalApi()

  const getFolderById = useGetFolderById()

  return useCallback(
    async (folderId: FolderSchema['id'] | null) => {
      try {
        const folder = folderId ? getFolderById(folderId) : null

        if (folderId == null || folder == null) {
          throw new Error('Folder cannot be found')
        }

        const response = await modalApi.prompt('confirm', {
          analytics: {
            name: 'bulk-move-projects',
          },
          title: templateStr('Are you sure you want to move {{item}}?', {
            item: pluralize(
              selectedIds.length,
              'this project',
              'these {{count}} projects'
            ),
          }),
          description: (
            <div className='flex flex-col gap-2'>
              <p>
                {`${pluralize(
                  selectedIds.length,
                  'This project',
                  'These {{count}} projects'
                )} will be moved to:`}
              </p>
              <BreadcrumbsLabel
                crumbs={[
                  { type: 'workspace', value: folder.targetId },
                  { type: 'folder', value: folder.id },
                ]}
              />
            </div>
          ),
          confirmButtonText: pluralize(
            selectedIds.length,
            'Move project',
            'Move {{count}} projects'
          ),
          closeButtonText: 'Cancel',
        })

        if (response === ModalDismissed) return undefined

        await bulkUpdateProjectSelection({
          type: 'bulk-project-location-update',
          folderId,
        })
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            selectedIds,
            folderId,
          },
        })

        showToast('error', 'Failed to move projects. Please try again.')
      }
    },
    [
      bulkUpdateProjectSelection,
      getFolderById,
      modalApi,
      pluralize,
      selectedIds,
    ]
  )
}
