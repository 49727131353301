import { Tooltip } from '@motion/ui/base'
import { CalendarEvent } from '@motion/ui/calendar'
import {
  allOtherAttendeesDeclined,
  getConferenceDataForType,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type EventContentArg } from '@fullcalendar/core'
import { ContextMenuPopoverWithButton } from '~/areas/calendar/components'
import { useIsCalendarEventSelected } from '~/areas/calendar/hooks'
import {
  type FullCalendarMotionEvent,
  getRsvpUIEvent,
} from '~/areas/calendar/utils'
import { EventActionList } from '~/areas/event/components'
import { getEventColorHue } from '~/areas/event/utils'
import { ProjectBadge } from '~/global/components/badges'
import { useMemo } from 'react'

type Props = {
  id: string
  event: EventContentArg
}

/**
 * Component for rendering a calendar event onto FullCalendar.
 * Note: this component relies on CSS classes injected in a parent element. See
 * `eventToFullCalendarEvent` for the potential classes available.
 */
export const ConnectedCalendarEvent = (props: Props) => {
  const { id, event: eventArg } = props

  const isSelected = useIsCalendarEventSelected(id)

  const { isPast } = eventArg
  const { start, end, allDay, title, extendedProps } = eventArg.event

  const {
    attendees,
    eventOwnerEmail,
    colorId,
    calendarColorId,
    availability,
    rsvp,
    otherCalendarColorIds,
    eventLocation,
    conferenceType,
    recurringEventId,
    meetingTaskProject,
  } = extendedProps as FullCalendarMotionEvent['extendedProps']

  const allOthersDeclined = useMemo(() => {
    return allOtherAttendeesDeclined(attendees, eventOwnerEmail)
  }, [attendees, eventOwnerEmail])

  const readableConferenceType =
    conferenceType && conferenceType !== 'none'
      ? getConferenceDataForType(conferenceType)?.title
      : null

  if (!start) {
    // TODO: log error
    return null
  }

  const eventColorHue = getEventColorHue(colorId, {
    projectColor: meetingTaskProject?.color,
    calendarColorId: calendarColorId,
  })

  return (
    <div className='flex justify-end h-full'>
      <ContextMenuPopoverWithButton
        renderContextMenu={(close) => (
          <EventActionList close={close} eventId={id} />
        )}
        onMenuOpen={(trigger) => {
          recordAnalyticsEvent('CALENDAR_RIGHT_CLICK_EVENT', { trigger })
        }}
      >
        {({ handleContextMenu }) => (
          <CalendarEvent
            allOthersDeclined={allOthersDeclined}
            colorHue={eventColorHue}
            startTime={start}
            endTime={end ?? start}
            allDay={allDay}
            name={title}
            selected={isSelected}
            availability={availability === 'FREE' ? 'free' : 'default'}
            rsvp={getRsvpUIEvent(rsvp)}
            past={isPast}
            otherCalendars={otherCalendarColorIds.map((colorId) => ({
              colorId,
            }))}
            eventLocation={readableConferenceType || eventLocation}
            isRecurring={!!recurringEventId}
            onContextMenu={handleContextMenu}
            renderTopRightContent={() => {
              if (!meetingTaskProject) {
                return null
              }

              return (
                <Tooltip
                  asChild
                  content={`This event is in the ${meetingTaskProject.name} project`}
                >
                  <ProjectBadge
                    value={isSelected ? null : meetingTaskProject}
                    size='xsmall'
                    hideTooltip
                  />
                </Tooltip>
              )
            }}
          />
        )}
      </ContextMenuPopoverWithButton>
    </div>
  )
}
