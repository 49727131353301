import { ChevronDownSolid } from '@motion/icons'
import { ActionDropdown, Button } from '@motion/ui/base'
import { type TaskSchema } from '@motion/zod/client'

export type IgnoreWarningButtonProps = {
  loading?: boolean
  task: TaskSchema
  onIgnoreInstance(): void
  onIgnoreAll(): void
}

export const IgnoreWarningButton = (props: IgnoreWarningButtonProps) => {
  if (props.task.type === 'RECURRING_INSTANCE') {
    return <IgnoreWarningRecurringTask {...props} />
  }
  return <IgnoreWarningSingleTask {...props} />
}

export const IgnoreWarningRecurringTask = (props: IgnoreWarningButtonProps) => {
  return (
    <ActionDropdown
      placement='bottom-end'
      items={[
        {
          content: 'Ignore warning for this instance',
          onAction: props.onIgnoreInstance,
        },
        {
          content: 'Ignore warning for all instances of this recurring task',
          onAction: props.onIgnoreAll,
        },
      ]}
    >
      <Button
        disabled={props.loading}
        variant='outlined'
        sentiment='neutral'
        size='small'
      >
        Ignore warning
        <ChevronDownSolid width={16} height={16} />
      </Button>
    </ActionDropdown>
  )
}

export const IgnoreWarningSingleTask = (props: IgnoreWarningButtonProps) => {
  return (
    <Button
      disabled={props.loading}
      variant='outlined'
      sentiment='neutral'
      size='small'
      onClick={props.onIgnoreInstance}
    >
      Ignore warning
    </Button>
  )
}
