import { groupBy } from '@motion/utils/array'
import {
  type CalendarEventSchemaV2,
  type CalendarSchema,
} from '@motion/zod/client'

import { getEventUniqueKey } from '~/areas/event/utils'
import { useLookup } from '~/global/cache'
import { useMemo } from 'react'

/**
 * Returns all the events matching a specific event key
 * (computed via `getEventUniqueKey`)
 * from an initial event id
 *
 * @param eventId
 * @returns CalendarEventSchemaV2[] - list of events
 */
export function useAllEventsMatchingKey(
  eventId: CalendarEventSchemaV2['id'] | null | undefined
): CalendarEventSchemaV2[] {
  const lookup = useLookup()

  return useMemo<CalendarEventSchemaV2[]>(() => {
    if (eventId == null) return []

    const initialEvent = lookup('calendarEvents', eventId)
    if (initialEvent == null) return []

    // Doing this could eventually be very expensive depending on the number of events we have in the cache
    const allEvents = lookup('calendarEvents')
    const allEventsByEventKey = groupBy(allEvents, (e) => getEventUniqueKey(e))

    return (
      allEventsByEventKey[getEventUniqueKey(initialEvent)].map(
        (entry) => entry
      ) ?? []
    )
  }, [eventId, lookup])
}

/**
 * Returns all the events (excluding recurring events) matching a specific event key that are in the list of calendars
 * (computed via `getEventUniqueKey`)
 * from an initial event id
 *
 * @param eventId
 * @returns CalendarEventSchemaV2[] - list of events excluding recurring events
 */
export function useAllEventsMatchingKeyInCalendars(
  eventId: CalendarEventSchemaV2['id'] | null | undefined,
  calendars: CalendarSchema[]
) {
  const allEvents = useAllEventsMatchingKey(eventId)

  return useMemo(() => {
    return allEvents.filter(
      (event) =>
        event.type !== 'RECURRING_EVENT' &&
        calendars.some((cal) => cal.id === event.calendarId)
    )
  }, [calendars, allEvents])
}
