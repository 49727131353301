import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'

import {
  getVariableKeyByName,
  getWrappedVariableKeyByName,
  type PresetTemplateOptions,
  type PresetWithoutWorkspaceFields,
} from './utils'

import {
  createNewRole,
  createNewTextVariable,
  DEFAULT_TEXT_VARIABLES,
} from '../../utils'

export const creativeAndDesignPreset = (opt?: PresetTemplateOptions) => {
  const useEventType = opt?.useEventType ?? false

  const roles = [
    createNewRole('Account Manager'),
    createNewRole('Designer'),
    createNewRole('Creative Director'),
    createNewRole('Copywriter'),
    createNewRole('Video Producer'),
  ]
  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES,
    createNewTextVariable('Client Name'),
  ]

  const eventScheduleMeetingWithinDays = useEventType
    ? DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS
    : null

  return {
    hasError: false,
    isLoading: false,
    name: 'Creative Campaign Development',
    description:
      '<p>Use this template if you are a creative or design agency managing client projects</p>',
    managerId: null,
    priorityLevel: 'MEDIUM' as const,
    labelIds: [],
    color: 'gray' as const,
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Briefing and Conceptualization',
        color: 'yellow',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 5,
        tasks: [
          {
            name: `Receive and review client brief for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? `Brainstorming session with ${getWrappedVariableKeyByName('Client Name', textVariables)}`
              : `Conduct brainstorming session for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 240
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: `Develop initial concepts for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Select final concept to develop for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Prepare presentation of concept for client for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? `Present to and gather feedback from ${getWrappedVariableKeyByName('Client Name', textVariables)}`
              : `Present to client and gather feedback for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 60
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Design Development',
        color: 'orange',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 15,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Refine chosen concept based on feedback`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Develop multiple design iterations`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? `Internal design review for ${getWrappedVariableKeyByName('Client Name', textVariables)}`
              : `${getWrappedVariableKeyByName('Client Name', textVariables)} - Internal review of designs`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 60
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: useEventType
              ? `Present design iterations to ${getWrappedVariableKeyByName('Client Name', textVariables)}`
              : `${getWrappedVariableKeyByName('Client Name', textVariables)} - Client presentation of iterations`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 60
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Approve Final Design`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Content Creation',
        color: 'pink',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 12,
        tasks: [
          {
            name: `Write copy for various campaign elements for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Copywriter', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Produce video or multimedia content for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Video Producer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Edit and finalize all multimedia content for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Video Producer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Integrate copy and visuals for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Review content consistency for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: `Client approval of final content for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Production',
        color: 'purple',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 22,
        tasks: [
          {
            name: `Prepare files for production  for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create Production tasks`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Launch and Implementation',
        color: 'blue',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: `Coordinate launch timing with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Create Data and Monitoring for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Review and Reporting',
        color: 'cyan',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 3,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Compile campaign results and metrics`,
            assigneeUserId: null,
            assigneeVariableKey: null,
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create final report on campaign performance`,
            assigneeUserId: null,
            assigneeVariableKey: null,
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? `Review project outcomes with ${getWrappedVariableKeyByName('Client Name', textVariables)}`
              : `${getWrappedVariableKeyByName('Client Name', textVariables)} - Review project outcomes with client`,
            assigneeUserId: null,
            assigneeVariableKey: null,
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
        ],
      },
    ],
    roles,
    textVariables,
  } satisfies PresetWithoutWorkspaceFields
}
