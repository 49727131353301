import { TextField } from '@motion/ui/forms'

import { type FieldError } from 'react-hook-form'

type NameTextFieldProps = {
  value: string
  onChange: (value: string) => void
  autoFocus: boolean
  isReadOnly?: boolean
  error?: FieldError
  placeHolder?: string
}

export const NameTextField = (props: NameTextFieldProps) => {
  const { value, onChange, error, isReadOnly, autoFocus, placeHolder } = props

  return (
    <TextField
      noResize
      readOnly={isReadOnly}
      multiline
      sentiment={error ? 'error' : undefined}
      placeholder={placeHolder}
      variant='muted'
      value={value}
      onChange={onChange}
      autoSize={{ minRows: 1, maxRows: 3 }}
      autoFocus={autoFocus}
    />
  )
}
