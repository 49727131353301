import { ButtonTabs, type ButtonTabsProps } from '@motion/ui/base'
import { type StageCardType } from '@motion/ui-logic/pm/project'

import { type ReactNode, useMemo } from 'react'

type TaskCardHeaderProps = {
  value: StageCardType
  onChange: (type: StageCardType) => void
  suffix?: ReactNode
}

export function TaskCardHeader({
  suffix,
  value,
  onChange,
}: TaskCardHeaderProps) {
  const tabItems = useMemo<ButtonTabsProps['items']>(
    () => [
      {
        value: 'task',
        content: 'Task',
        onAction: () => {
          onChange('task')
        },
      },
      {
        value: 'event',
        content: 'Event',
        onAction: () => {
          onChange('event')
        },
      },
    ],
    [onChange]
  )

  return (
    <div className='flex items-center justify-between bg-semantic-neutral-surface-bg-subtlest p-1'>
      <ButtonTabs items={tabItems} activeValue={value} size='xsmall' />

      {suffix}
    </div>
  )
}
