import { type COLOR, COLORS } from '@motion/shared/common'
import { Button, PopoverTrigger } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { ColorItemBadge } from '../badges'

export type ColorDropdownProps = ColorDropdownContentProps & {
  children: ReactNode
}

export const ColorDropdown = (props: ColorDropdownProps) => {
  const { children, onChange, ...rest } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <div className='p-2'>
          <ColorDropdownContent
            onChange={(value) => {
              onChange(value)
              close()
            }}
            {...rest}
          />
        </div>
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export type ColorDropdownContentProps = {
  selectedColor: COLOR
  onChange: (color: COLOR) => void
}

export function ColorDropdownContent(props: ColorDropdownContentProps) {
  const { selectedColor, onChange } = props

  return (
    <div className='flex max-w-[200px] items-start content-start flex-wrap'>
      {COLORS.map((color) => {
        const isSelected = color === selectedColor
        return (
          <Button
            key={color}
            iconOnly
            onClick={() => onChange(color)}
            size='small'
            sentiment='neutral'
            variant='muted'
          >
            <ColorItemBadge color={color} selected={isSelected} />
          </Button>
        )
      })}
    </div>
  )
}
