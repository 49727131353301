import { MotionCache } from '@motion/rpc-cache'
import { parseDate } from '@motion/utils/dates'
import { sleep } from '@motion/utils/promise'
import { useModalApi } from '@motion/web-common/modals'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { useCalendarStartAndEnd } from '~/areas/calendar/hooks'
import { getCalendarEventsQueryFilters, useLookup } from '~/global/cache'
import { useMeetingModalUrl } from '~/global/navigation'
import { useGetScheduledEntitiesLazy } from '~/global/rpc'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

export const useOpenSearchEvent = () => {
  const modalApi = useModalApi()
  const navigate = useNavigate()
  const buildMeetingModalUrl = useMeetingModalUrl()
  const calendarStartAndEnd = useCalendarStartAndEnd()
  const lookup = useLookup()
  const client = useQueryClient()

  const { execute: getScheduledEntities } = useGetScheduledEntitiesLazy()

  const scheduledEntitiesFromDate = useCallback(
    async (date: DateTime) => {
      const data = await getScheduledEntities({
        filters: {
          scheduled: {
            from: date.startOf('day').toISO(),
            to: date.endOf('day').toISO(),
          },
          completed: 'include',
        },
        include: ['event'],
        hydrate: ['calendarEvents'],
      })
      MotionCache.upsert(client, getCalendarEventsQueryFilters(), data)
      return data
    },
    [client, getScheduledEntities]
  )

  const navigateToEvent = useCallback(
    (event: CalendarEventSchemaV2) => {
      if (event.meetingTaskId != null) {
        navigate(
          buildMeetingModalUrl({
            mTask: event.meetingTaskId,
          })
        )

        return
      }

      modalApi.open('event-modal', { eventId: event.id })
    },
    [buildMeetingModalUrl, modalApi, navigate]
  )

  return useCallback(
    async ({
      providerId,
      start,
      end,
    }: Pick<CalendarEventSchemaV2, 'providerId' | 'start' | 'end'>) => {
      const startDate = parseDate(start)
      const endDate = parseDate(start)

      const startOfStart = startDate.startOf('day').toISO()
      const endOfEnd = endDate.endOf('day').toISO()

      // If the event starts in the current week
      const isStartInCurrentWeek =
        calendarStartAndEnd.from <= startOfStart &&
        calendarStartAndEnd.to >= startOfStart

      // If the event ends in the current week
      const isEndInCurrentWeek =
        calendarStartAndEnd.from <= endOfEnd &&
        calendarStartAndEnd.to >= endOfEnd

      // If the event is in the current week such that start is before the current week and end is after the current week
      const isEncompassingCurrentWeek =
        calendarStartAndEnd.from >= DateTime.fromISO(start).toISO() &&
        calendarStartAndEnd.to <= DateTime.fromISO(end).toISO()

      // If the event is in the the current week, just navigate directly to the event
      if (
        isStartInCurrentWeek ||
        isEndInCurrentWeek ||
        isEncompassingCurrentWeek
      ) {
        const foundEvent = findEvent(lookup('calendarEvents'), providerId)
        navigateToEvent(foundEvent)
        return
      }

      // For old events, or events too far in the future, it's possible the backend doesn't have the event in the DB yet
      // When that happens, the schedule entities response won't contain the event. The backend triggers a calendar sync and
      // we should eventually receive the event via the socket events. Because of this, when the event is not found,
      // we need to refetch until we have it
      try {
        retry(10, async () => {
          try {
            const scheduledEntities = await scheduledEntitiesFromDate(startDate)

            const foundEvent = findEvent(
              Object.values(scheduledEntities.models.calendarEvents),
              providerId
            )
            navigateToEvent(foundEvent)

            return true
          } catch (e) {
            await sleep(800)
            throw new Error('Retrying fetching event')
          }
        })
      } catch {
        throw new Error('Event not found after retrying', {
          cause: {
            providerId,
          },
        })
      }
    },
    [
      calendarStartAndEnd.from,
      calendarStartAndEnd.to,
      lookup,
      navigateToEvent,
      scheduledEntitiesFromDate,
    ]
  )
}

function findEvent(list: CalendarEventSchemaV2[], providerId: string) {
  const foundEvent = Object.values(list).find(
    (event) => event.providerId === providerId
  )
  if (!foundEvent) {
    throw new Error('Event not found', {
      cause: {
        providerId,
        events: JSON.stringify(list),
      },
    })
  }

  return foundEvent
}

function retry<T>(
  maxTries: number,
  handler: () => Promise<T | void>
): Promise<T | void> {
  if (maxTries < 1) return Promise.reject()

  return handler().catch(() => retry(maxTries - 1, handler))
}
