import { WorkspaceSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { ConfirmationModal, PopoverButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useFlowTemplateModalUrl } from '~/areas/flows'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { useProjectDefinition, useWorkspaceById } from '~/global/hooks'
import { useCopyProjectDefinition } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DuplicateFlowCheckboxes } from './duplicate-flow-checkboxes'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'duplicate-flow': {
      flowId: string
      workspaceId: string
    }
  }
}

export function DuplicateFlowModal({
  close,
  flowId,
  workspaceId,
}: ModalTriggerComponentProps<'duplicate-flow'>) {
  const flowTemplate = useProjectDefinition(flowId)
  const initialWorkspaceId = flowTemplate?.workspaceId ?? workspaceId

  const navigate = useNavigate()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const [currentWorkspaceId, setCurrentWorkspaceId] =
    useState(initialWorkspaceId)
  const currentWorkspace = useWorkspaceById(currentWorkspaceId)
  const [copyMissingCustomFields, setCopyMissingCustomFields] = useState(true)
  const [inviteMissingMembers, setInviteMissingMembers] = useState(true)
  const [copyMissingLabels, setCopyMissingLabels] = useState(true)
  const inviteMembersDisabled = currentWorkspace?.isPersonalWorkspace ?? false
  const showCheckboxes = currentWorkspaceId !== initialWorkspaceId
  const getRouteUri = useUriByRouteId()

  const { mutateAsync: duplicateFlowMutation } = useCopyProjectDefinition()

  const duplicateFlow = async () => {
    if (!flowTemplate) return
    // TODO: integrate with duplicate flow hook to be added later.

    recordAnalyticsEvent('COPY_FLOW_TEMPLATE', {
      numStages: flowTemplate.stages.length,
      numTasks: flowTemplate.stages.reduce(
        (acc, stage) => acc + stage.tasks.length,
        0
      ),
    })

    try {
      const newFlow = await duplicateFlowMutation({
        id: flowId,
        workspaceId: initialWorkspaceId,
        destinationWorkspaceId: currentWorkspaceId,
        options: {
          copyMissingCustomFields,
          inviteMissingMembers: inviteMembersDisabled
            ? false
            : inviteMissingMembers,
          copyMissingLabels,
        },
      })
      close()

      navigate(
        buildFlowTemplateModalUrl({
          template: 'edit',
          forWorkspace: currentWorkspaceId,
          templateId: newFlow.id,
          forDuplicate: flowId,
          pathName: getRouteUri('workspace-template-settings', {
            workspaceId: currentWorkspaceId,
          }),
        })
      )
    } catch (e) {
      if (e instanceof Error) {
        showErrorToast(
          `There was an error duplicating the project template: ${e.message}`
        )
      }

      Sentry.captureException(e, {
        tags: { position: 'duplicate-flow-modal' },
      })
    }
  }
  return (
    <ConfirmationModal
      action={{
        label: 'Duplicate template',
        onAction: duplicateFlow,
      }}
      closeLabel='Cancel'
      description={
        <div>
          You will have a chance to edit the template before it becomes live in
          the new workspace.
          <div className='flex flex-col items-start gap-4 self-stretch'>
            <WorkspaceDropdown
              onChange={(workspace) => setCurrentWorkspaceId(workspace.id)}
              selectedWorkspaceId={currentWorkspaceId}
            >
              <div className='w-full mt-5 mb-1'>
                <PopoverButton
                  icon={
                    <ProjectPalette
                      color={
                        currentWorkspace && 'color' in currentWorkspace
                          ? (currentWorkspace.color as COLOR)
                          : 'gray'
                      }
                    >
                      <WorkspaceSolid />
                    </ProjectPalette>
                  }
                >
                  {currentWorkspace?.name}
                </PopoverButton>
              </div>
            </WorkspaceDropdown>
            {showCheckboxes && (
              <DuplicateFlowCheckboxes
                copyMissingCustomFields={copyMissingCustomFields}
                inviteMissingMembers={inviteMissingMembers}
                copyMissingLabels={copyMissingLabels}
                inviteMembersDisabled={inviteMembersDisabled}
                toggleCopyMissingCustomFields={() =>
                  setCopyMissingCustomFields(!copyMissingCustomFields)
                }
                toggleInviteMissingMembers={() =>
                  setInviteMissingMembers(!inviteMissingMembers)
                }
                toggleCopyMissingLabels={() =>
                  setCopyMissingLabels(!copyMissingLabels)
                }
              />
            )}
          </div>
        </div>
      }
      onClose={close}
      title='Duplicate template'
      visible
    />
  )
}
