import { PlusSolid, XSolid } from '@motion/icons'
import { Button, IconButton, UnstyledModal } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import { SharedFormShell, ShellVars } from '~/areas/task-project/components'
import { useAllProjectDefinitions, useProjectDefinitions } from '~/global/hooks'

import { FlowTemplateCardLink } from './components'
import {
  useCloseFlowTemplateModal,
  useFlowTemplateModalUrl,
  useFlowTemplateModalUrlParams,
} from './hooks'
import { FLOW_TEMPLATE_PRESETS } from './presets'

type CreateFlowTemplateModalProps = {
  open: boolean
}

export function ConnectedCreateFlowTemplateModal({
  open,
}: CreateFlowTemplateModalProps) {
  const closeModal = useCloseFlowTemplateModal()

  return (
    <UnstyledModal
      data-testid='create-flow-template-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <CreateFlowTemplateModalContent onClose={closeModal} />
    </UnstyledModal>
  )
}

function CreateFlowTemplateModalContent({ onClose }: { onClose: () => void }) {
  const { workspaceId } = useFlowTemplateModalUrlParams()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const isMeetingsInProjectsEnabled = useHasTreatment('meetings-in-project')

  const workspaceProjectDefinitions = useProjectDefinitions(workspaceId)
  const allProjectDefinitions = useAllProjectDefinitions()

  const projectDefinitions = workspaceId
    ? workspaceProjectDefinitions
    : allProjectDefinitions

  return (
    <ShellVars>
      <SharedFormShell className='p-6'>
        <div className='gap-6 flex flex-col h-full'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-lg text-semantic-neutral-text-default'>
              Create project workflow template
            </h1>
            <div className='flex gap-2'>
              <Button
                sentiment='neutral'
                variant='outlined'
                url={buildFlowTemplateModalUrl({ template: 'new' })}
              >
                <PlusSolid />
                Create from scratch
              </Button>

              <IconButton
                icon={XSolid}
                sentiment='neutral'
                variant='muted'
                onClick={onClose}
              />
            </div>
          </div>

          <div className='gap-6 flex flex-col overflow-auto'>
            <div className='gap-3 flex flex-col'>
              <h2 className='font-bold text-sm text-semantic-neutral-text-default'>
                Create from existing template
              </h2>
              <div className='flex gap-3 flex-wrap'>
                {projectDefinitions.map((definition) => {
                  return (
                    <FlowTemplateCardLink
                      key={definition.id}
                      name={definition.name}
                      stages={definition.stages}
                      url={buildFlowTemplateModalUrl({
                        template: 'new',
                        templateId: definition.id,
                      })}
                    />
                  )
                })}
              </div>
            </div>

            <div className='gap-3 flex flex-col'>
              <h2 className='font-bold text-sm text-semantic-neutral-text-default'>
                Create from Motion predefined templates
              </h2>
              <div className='flex gap-3 flex-wrap'>
                {FLOW_TEMPLATE_PRESETS.map((p) => {
                  return (
                    <FlowTemplateCardLink
                      key={p.id}
                      name={p.name}
                      stages={
                        p.template({
                          useEventType: isMeetingsInProjectsEnabled,
                        }).stages
                      }
                      url={buildFlowTemplateModalUrl({
                        template: 'new',
                        fromPresetId: p.id,
                      })}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </SharedFormShell>
    </ShellVars>
  )
}
