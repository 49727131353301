import { ChevronDownSolid } from '@motion/icons'
import { type PMTaskType } from '@motion/rpc/types'
import { ActionDropdown, type ActionItem, Button } from '@motion/ui/base'
import {
  getExtendDeadlineDateText,
  getStartDateOrToday,
} from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { type TaskSchema } from '@motion/zod/client'

import {
  DeadlineToggle,
  ProjectAwareDateDropdownContent,
} from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'

export type ChangeDeadlineButtonProps = {
  loading?: boolean
  task: TaskSchema
  canBeExtended: boolean
  onSetDeadline(value: {
    date: string
    deadline: PMTaskType['deadlineType']
  }): void
  onSetScheduledDate(value: string): void
}

function getInitialDay(task: TaskSchema) {
  const today = DateTime.now().endOf('day')
  const candidate = task.scheduledStart ?? task.dueDate ?? task.startDate
  if (!candidate) return today

  try {
    const scheduledDate = parseDate(candidate)
    return scheduledDate < today ? today : scheduledDate
  } catch (err) {
    return today
  }
}

export const ChangeDeadlineButton = (props: ChangeDeadlineButtonProps) => {
  const { task, canBeExtended, onSetScheduledDate, onSetDeadline } = props

  const scheduledEnd =
    'estimatedCompletionTime' in task ? task.estimatedCompletionTime : null
  const startDate = getStartDateOrToday(task.startDate)
  const extendedDueDate = scheduledEnd
    ? parseDate(scheduledEnd).endOf('day').toISO()
    : null
  const endDate =
    'endDate' in task && task.endDate ? parseDate(task.endDate) : null

  const [deadlineType, setDeadlineType] = useState(task.deadlineType ?? 'SOFT')
  const isRecurring = task.type === 'RECURRING_INSTANCE'

  const items = useMemo(() => {
    const items: ActionItem[] = []

    if (extendedDueDate) {
      items.push({
        content: getExtendDeadlineDateText(extendedDueDate),
        onAction: () => onSetScheduledDate(extendedDueDate),
      })
    }

    if (canBeExtended) {
      items.push({
        content: 'Pick a date',
        renderPopover({ close }: { close: () => void }) {
          const selected = getInitialDay(task)
          return (
            <ProjectAwareDateDropdownContent
              value={selected.toISO()}
              disabledDate={(value) => {
                if (value < startDate) return true
                if (endDate) return value > endDate
                return false
              }}
              disableClearing
              onChange={(value) => {
                if (!value) return
                onSetDeadline({
                  deadline: deadlineType,
                  date: value,
                })
                close()
              }}
              renderCalendarFooter={
                isRecurring
                  ? undefined
                  : () => (
                      <DeadlineToggle
                        deadlineType={deadlineType}
                        onChange={(value) => setDeadlineType(value)}
                      />
                    )
              }
              contextProps={{ taskId: task.id }}
              dropdownType='deadline'
              dropdownTarget='task'
            />
          )
        },
      })
    }
    return items
  }, [
    extendedDueDate,
    canBeExtended,
    onSetScheduledDate,
    task,
    isRecurring,
    startDate,
    endDate,
    onSetDeadline,
    deadlineType,
  ])

  if (items.length === 0) return null

  return (
    <ActionDropdown placement='bottom-end' items={items}>
      <Button
        disabled={props.loading}
        variant='outlined'
        sentiment='neutral'
        size='small'
      >
        Extend deadline
        <ChevronDownSolid width={16} height={16} />
      </Button>
    </ActionDropdown>
  )
}
