import { getWeekOf } from '@motion/utils/dates'
import { type ScheduledEntitiesIncludeSchema } from '@motion/zod/client'

import {
  useFilterScheduledEntitiesGhostTasks,
  useGetScheduledEntitiesWithProxies,
} from '~/global/rpc'
import { useMemo } from 'react'

import { useCalendarStartDay } from './use-calendar-start-day'
import { useCalendarState } from './use-calendar-state'
import { useShowProjectedEntities } from './use-show-projected-entities'

function getIncludedScheduledEntities(
  obj: Record<ScheduledEntitiesIncludeSchema, boolean>
): ScheduledEntitiesIncludeSchema[] {
  return Object.keys(obj).filter(
    (key) => obj[key as ScheduledEntitiesIncludeSchema] === true
  ) as ScheduledEntitiesIncludeSchema[]
}

const SCH_ENT_STALE_TIME = 1000 * 60 * 2

export const useStateScheduledEntities = () => {
  const [calendarState] = useCalendarState()
  const startDay = useCalendarStartDay()

  const dataToInclude = useMemo(() => {
    return getIncludedScheduledEntities(calendarState.includedEntities)
  }, [calendarState.includedEntities])

  const weekOfSelectedDay = useMemo(() => {
    const weekOf = getWeekOf(calendarState.selectedDate, {
      startingDay: startDay,
    })

    return {
      start: weekOf.start.startOf('day').toISO(),
      end: weekOf.end.plus({ days: 7 }).endOf('day').toISO(),
    }
  }, [calendarState.selectedDate, startDay])

  const result = useGetScheduledEntitiesWithProxies(
    {
      include: dataToInclude,
      filters: {
        completed: calendarState.settings.showCompletedEntities,
        myCalendarsOnly: calendarState.myCalendarsOnly,
        scheduled: {
          from: weekOfSelectedDay.start,
          to: weekOfSelectedDay.end,
        },
        calendarsOptions: {
          alwaysIncludeCalendarIds: calendarState.includedCalendarIds,
        },
      },
    },
    {
      keepPreviousData: true,
      // For some reason invalidating query when not on page and coming back to it does not re-grab latest data
      // This is a workaround for that
      refetchOnMount: 'always',
      staleTime: SCH_ENT_STALE_TIME,
    }
  )

  const [showProjectedEntities] = useShowProjectedEntities()

  const postProcessedScheduledEntities = useFilterScheduledEntitiesGhostTasks(
    result,
    showProjectedEntities
  )

  if (!result.data) return result

  return {
    ...result,
    data: { ...result.data, scheduledEntities: postProcessedScheduledEntities },
  }
}
