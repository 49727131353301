import {
  PopoverTrigger,
  type PopoverTriggerProps,
  SearchableTree,
  type SearchableTreeNode,
} from '@motion/ui/base'

import { type PropsWithChildren } from 'react'

import { type LeafNodeType, useWorkspacesDropdownTree } from './hooks'
import { extractWorkspaceTreeNodePath, idEqualsTreeNodeId } from './utils'
import { WorkspacesTreeNodeLabel } from './workspaces-tree-node-label'

export type WorkspacesTreeDropdownProps = PropsWithChildren<{
  selectedId: string | null
  onChange: (selected: {
    projectId: string | null
    folderId: string | null
    workspaceId: string
  }) => void
  placement?: PopoverTriggerProps['placement']
  leafNodeType?: LeafNodeType
  computeDisabled?: (workspaceId: string) => boolean
  hideNoProject?: boolean
}>

export function WorkspacesTreeDropdown(props: WorkspacesTreeDropdownProps) {
  const {
    children,
    placement = 'bottom-start',
    selectedId,
    onChange,
    leafNodeType,
    computeDisabled,
    hideNoProject,
  } = props
  return (
    <PopoverTrigger
      placement={placement}
      renderPopover={({ close }) => (
        <WorkspacesTreeDropdownContent
          selectedId={selectedId}
          leafNodeType={leafNodeType}
          onChange={(selected) => {
            close()
            onChange(selected)
          }}
          computeDisabled={computeDisabled}
          hideNoProject={hideNoProject}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type WorkspacesTreeDropdownContentProps = Pick<
  WorkspacesTreeDropdownProps,
  | 'selectedId'
  | 'leafNodeType'
  | 'onChange'
  | 'computeDisabled'
  | 'hideNoProject'
>

const WorkspacesTreeDropdownContent = (
  props: WorkspacesTreeDropdownContentProps
) => {
  const {
    selectedId,
    leafNodeType = 'PROJECT',
    onChange,
    computeDisabled,
    hideNoProject = false,
  } = props

  const noneItemLabel =
    leafNodeType === 'PROJECT'
      ? 'No project'
      : leafNodeType === 'FOLDER'
        ? 'No folder'
        : undefined

  const rootNode = useWorkspacesDropdownTree({
    selectedId,
    leafNodeType,
    noneItemLabel,
    computeDisabled,
    hideNoProject,
  })

  const handleChange = (node: SearchableTreeNode) => {
    const selected = extractWorkspaceTreeNodePath(node)

    onChange(selected)
  }

  return (
    <SearchableTree
      rootNode={rootNode}
      onSelect={handleChange}
      computeSelected={(node) => idEqualsTreeNodeId(node.id, selectedId)}
      renderLabel={(node) => (
        <WorkspacesTreeNodeLabel node={node} leafNodeType={leafNodeType} />
      )}
    />
  )
}
