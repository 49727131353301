import { AutoscheduleSolid, CalendarSolid, CalendarXSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { getDeadlineText } from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'
import { type TaskSchema } from '@motion/zod/client'

import { twMerge } from 'tailwind-merge'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type DeadlineBadgeProps = {
  task: Pick<TaskSchema, 'dueDate' | 'priorityLevel' | 'isAutoScheduled'>
  size: BadgeSize
}

export function DeadlineBadge({ task, size }: DeadlineBadgeProps) {
  const formattedDeadlineValue = getDeadlineText({
    priorityLevel: task.priorityLevel,
    dueDate: task.dueDate,
  })

  return (
    <Tooltip
      content={templateStr('Deadline: {{ value }}', {
        value: formattedDeadlineValue ? formattedDeadlineValue : 'No deadline',
      })}
      asChild
    >
      {task.isAutoScheduled ? (
        <AutoscheduleSolid
          className={twMerge(
            'text-semantic-purple-icon-default',
            size === 'small' && 'w-4',
            size === 'xsmall' && 'w-3'
          )}
        />
      ) : (
        <CalendarSolid
          className={twMerge(
            'text-semantic-neutral-icon-subtle',
            size === 'small' && 'w-4',
            size === 'xsmall' && 'w-3'
          )}
        />
      )}
    </Tooltip>
  )
}

type PastDueBadgeProps = {
  task: TaskSchema
  size?: BadgeSize
}

export const PastDueBadge = (props: PastDueBadgeProps) => {
  if (props.task.dueDate == null) return null

  const tooltipContent = getDeadlineText(props.task)
  const isPastDue = parseDate(props.task.dueDate).diffNow('day').days > 0
  return (
    <Tooltip content={`Deadline: ${tooltipContent}`}>
      <DueDateIcon pastDue={isPastDue} size={props.size} />
    </Tooltip>
  )
}

type DueDateIconProps = {
  pastDue: boolean
  size?: BadgeSize
}
const DueDateIcon = (props: DueDateIconProps) => {
  const pixelSize = getBadgeSizeInPixels(props.size)

  if (props.pastDue) {
    return (
      <CalendarXSolid
        width={pixelSize}
        height={pixelSize}
        className='text-semantic-error-icon-default hover:text-semantic-error-icon-hover'
      />
    )
  }
  return (
    <CalendarSolid
      width={pixelSize}
      height={pixelSize}
      className='text-semantic-neutral-icon-default hover:text-semantic-neutral-icon-hover'
    />
  )
}
