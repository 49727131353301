import { useSharedState } from '@motion/react-core/shared-state'
import { type AppDataContext, type EntityCache } from '@motion/ui-logic/pm/data'

import { useMemo } from 'react'

import {
  AppWorkspaceContext,
  type AppWorkspaceDataContext,
} from './app-workspace-context'
import { type EntityCache as WebEntityCache } from './utils'

export function wrapContext(ctx: AppWorkspaceDataContext): AppDataContext {
  return {
    get loaded() {
      return ctx.users.all.length > 0
    },
    labels: wrapedCache(ctx.labels),
    projects: wrapedCache(ctx.projects),
    statuses: wrapedCache(ctx.statuses),
    users: wrapedCache(ctx.users),
    workspaces: wrapedCache(ctx.workspaces),
    customFields: wrapedCache(ctx.customFields),
    priorities: wrapedCache(ctx.priorities),
  }
}

export function wrapedCache<T>(cache: WebEntityCache<T>): EntityCache<T> {
  return {
    all: () => cache.all,
    byId: (id: string) => cache.byId[id],
  }
}

export const useAppDataContext = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const wrapped = useMemo(() => wrapContext(ctx), [ctx])
  return wrapped
}
