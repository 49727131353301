import { showToast } from '@motion/ui/base'
import {
  type NormalTaskSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

import { useHasUnfinishedBlockersFn } from '../helpers'

export const useTaskStageUpdater = () => {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const hasUnfinishedBlockersInStage = useHasUnfinishedBlockersFn()

  return useCallback(
    async (
      taskOrId: NormalTaskSchema | NormalTaskSchema['id'],
      stageDefinition: StageDefinitionSchema
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type !== 'NORMAL') {
        throw new Error('Cannot update a stage for a non NORMAL task')
      }

      const hasUnfinishedBlockers = await hasUnfinishedBlockersInStage(
        task,
        task.stageDefinitionId
      )

      if (hasUnfinishedBlockers) {
        showToast(
          'error',
          'This task cannot be moved to a different stage because it has blockers. Please remove the blockers and try again.'
        )
        return
      }

      await updateTask(task, { stageDefinitionId: stageDefinition.id })
    },
    [hasUnfinishedBlockersInStage, readTask, updateTask]
  )
}
