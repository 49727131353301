import { XSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'
import { IconButton, Modal } from '@motion/ui/base'
import { calendarProviderTypeToName, templateStr } from '@motion/ui-logic'
import { logEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useRefetchEventsOnAddCalendar } from '~/areas/calendar/hooks'
import { useState } from 'react'

import { Events } from '../../../analyticsEvents'
import { setMainCalendar } from '../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch } from '../../../state/hooks'
import { SecondaryButton } from '../../Common'

export type ChangeMainCalendarModalProps = {
  emailAccount: EmailAccount
  mainEmailAccount: EmailAccount
  onComplete?: () => void
}

export const ChangeMainCalendarModal = (
  props: ChangeMainCalendarModalProps
) => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()
  const refetchEventsOnAddCalendar = useRefetchEventsOnAddCalendar()

  const { emailAccount, mainEmailAccount } = props

  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')

  const onComplete = () => {
    props.onComplete?.()
    refetchEventsOnAddCalendar()
    modalApi.dismiss()
  }

  const onChangeMainCalendar = async () => {
    setError('')
    setIsSaving(true)
    void logEvent(Events.CALENDAR_ACCOUNT_MODAL_MAIN_SWITCH)

    const res = await dispatch(setMainCalendar(emailAccount.id)).unwrap()
    if ('assigned' in res && !res.assigned) {
      setError(
        `The calendar for ${emailAccount.email} is currently the main calendar for another Motion account. ` +
          'Please make this calendar a non-main calendar in any other Motion accounts you may own if you want to use it here. ' +
          'You can contact our friendly support team if you have any further questions.'
      )
      setIsSaving(false)
      return
    }

    onComplete()
  }

  return (
    <Modal onClose={onComplete} visible>
      <div className='flex flex-col gap-3'>
        <div className='dark:text-dark-100 text-light-1200 flex justify-between border-b px-4 py-3'>
          <p className='text-base font-semibold text-inherit dark:text-inherit'>
            {templateStr('Set up {{email}} ({{providerType}})', {
              email: emailAccount.email,
              providerType:
                calendarProviderTypeToName[emailAccount.providerType],
            })}
          </p>
          <IconButton
            icon={XSolid}
            onClick={onComplete}
            sentiment='neutral'
            size='small'
            variant='muted'
          />
        </div>

        <div className='px-4'>
          <p className='font-semibold text-sm text-semantic-neutral-text-default mb-2'>
            Would you like to set {emailAccount.email} as your main calendar?
          </p>
          <p className='text-semantic-neutral-text-subtle'>
            Your main calendar is used as the default when creating events. Your
            main calendar is currently {mainEmailAccount.email}.
          </p>
        </div>

        <div className='flex flex-col space-y-2 py-3 px-4'>
          <SecondaryButton
            onClick={onChangeMainCalendar}
            disabled={!!error.length}
            loading={isSaving}
          >
            Set {emailAccount.email} as main calendar
          </SecondaryButton>
          {error.length > 0 && (
            <p className='my-2 !text-semantic-error-text-default text-sm'>
              {error}
            </p>
          )}
          <SecondaryButton onClick={onComplete}>
            Keep {mainEmailAccount.email} as main calendar
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}
