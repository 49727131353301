import { type MeetingUrlSearchParams } from '@motion/ui-logic'

import { useCallback } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

export type MeetingModalParams = MeetingUrlSearchParams

export function useMeetingModalUrl() {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  return useCallback(
    (params: MeetingModalParams) => {
      searchParams.set('mTask', params.mTask)

      // Remove task param because it's not compatible with meeting task. We cannot stack them
      searchParams.delete('task')

      return `${pathname}?${searchParams.toString()}`
    },

    [pathname, searchParams]
  )
}
