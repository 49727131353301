type StringID = {
  id?: string | null
}

export const replaceInPlace = <T extends StringID>(
  arr: T[],
  itemToUpdate: T
) => {
  const arrIndex = arr.findIndex((x) => x.id === itemToUpdate.id)

  if (arrIndex === -1) return arr

  return arr.splice(arrIndex, 1, itemToUpdate)
}

// Sorts array using the Fisher-Yates Algorithm
export const shuffleArray = <T>(arr: T[]): T[] => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }

  return arr
}

type Falsy = false | 0 | '' | null | undefined

// Removes all falsy values from an array while preserving the type
export const excludeFalsy = <T>(arr: T[]): Exclude<T, Falsy>[] => {
  return arr.filter(Boolean) as Exclude<T, Falsy>[]
}
