import { ActionList } from '@motion/ui/base'
import {
  formatDurationTime,
  getAvailableAddTimeOptions,
} from '@motion/ui-logic'

import { useMemo } from 'react'

export type AddTaskDurationActionListProps = {
  close: () => void
  currentDuration: number | null
  onSelect: (duration: number) => void
}

export function AddTaskDurationActionList({
  close,
  currentDuration,
  onSelect,
}: AddTaskDurationActionListProps) {
  const options = useMemo(() => {
    return getAvailableAddTimeOptions(currentDuration)
  }, [currentDuration])

  if (options.length === 0) {
    return (
      <ActionList
        items={[
          {
            content: 'Task cannot have a duraction of more than 16 hours',
            onAction: close,
            disabled: true,
          },
        ]}
      />
    )
  }

  return (
    <ActionList
      onActionAnyItem={close}
      items={options.map((duration) => ({
        content: formatDurationTime(duration),
        onAction: () => onSelect(duration),
      }))}
    />
  )
}
