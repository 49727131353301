import { type FolderSchema } from '@motion/zod/client'

import { useCachedItem } from '~/global/cache'
import { type BadgeSize, FolderBadge } from '~/global/components/badges'

export type FolderLabelProps = {
  value: FolderSchema | null
  size?: BadgeSize
  nameOnly?: boolean
}

export function FolderLabel({
  size,
  value,
  nameOnly = false,
}: FolderLabelProps) {
  const name = value?.name || 'No folder'

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  if (nameOnly) {
    return label
  }

  return (
    <div className='flex gap-1 items-center overflow-hidden leading-4'>
      <FolderBadge color={value?.color} size={size} />
      {label}
    </div>
  )
}

export type ConnectedFolderLabelProps = Omit<FolderLabelProps, 'value'> & {
  id?: FolderSchema['id']
}

export function ConnectedFolderLabel({
  id,
  ...rest
}: ConnectedFolderLabelProps) {
  const folder = useCachedItem('folders', id)

  return <FolderLabel value={folder} {...rest} />
}
