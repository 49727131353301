import {
  AllTasksGradientSolid,
  CogSolid,
  MyTasksGradientSolid,
  ProjectGradientSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, showToast } from '@motion/ui/base'
import { FolderColoredIcon, ProjectColoredIcon } from '@motion/ui/project'
import { ConditionalWrapper } from '@motion/ui/utils'
import { type FilterTarget, useResetFilter } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { HasExperiment, useHasTreatment } from '@motion/web-common/flags'

import { useProjectUpdater } from '~/areas/project/hooks'
import { FilterBar } from '~/areas/project-management/filters'
import {
  ConnectedFilterButtons,
  ConnectedWorkspaceFilter,
  FilterPanel,
} from '~/areas/project-management/filters/components/v2'
import { ConnectedFiltersToggle } from '~/areas/project-management/filters/components/v2/filters-toggle'
import { OpenLeftSidebarButton } from '~/areas/sidebar/components'
import { useHasWorkspaceFoldersTreatment } from '~/areas/sidebar/hooks'
import { MotionLink, TutorialButtonLink } from '~/global/components'
import { WorkspaceBadge } from '~/global/components/badges'
import {
  ColorDropdown,
  type ColorDropdownContentProps,
} from '~/global/components/dropdowns'
import { useProject } from '~/global/hooks'
import { useGetFolderById } from '~/global/hooks/folders'
import { useProjectModalUrl } from '~/global/navigation'
import { useUpdateFolder } from '~/global/rpc/folders'
import { useUriByRouteId } from '~/routing'
import { type ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FolderProjectsTag } from './folder-projects-tag'
import { HeaderBreadcrumbs } from './header-breadcrumbs'

import {
  ConnectectedViewSection,
  ConnectedGroupByButton,
  ConnectedHeaderDotsButton,
  ConnectedNameSearchButton,
  ConnectedPageSelector,
  ConnectedShowCalendarsButton,
  ConnectedSortButton,
  ConnectedViewDetailsButton,
} from '../components'
import { ConnectedAiViewGenerationButton } from '../components/ai-views'
import { ItemCounter } from '../components/connected-item-counter'
import { ConnectedShowCompletedButton } from '../components/connected-show-completed-button'
import { ConnectedShowPastDeadlineButton } from '../components/connected-show-past-deadline-button'
import { ConnectedShowProjectSidebarButton } from '../components/connected-show-project-sidebar-button'
import {
  useProjectFilterDefinitions,
  useTaskFilterDefinitions,
} from '../filtering/hooks'
import { KanbanCardFieldsButton } from '../kanban'
import { PlannerNavigateButtons } from '../planner'
import { usePageData } from '../routes'
import { type PMPageUrlParams, type RouteData } from '../routes/types'
import { useViewState } from '../view-state'

type ConnectedHeaderProps = {
  title: ReactNode
  target: FilterTarget
}
export const ConnectedHeader = (props: ConnectedHeaderProps) => {
  const [filtersVisible, setFiltersVisible] = useState(false)
  const toggleFiltersVisible = () => setFiltersVisible((prev) => !prev)
  const resetFilters = useResetFilter()
  const allAvailableTaskFilters = useTaskFilterDefinitions()
  const allAvailableProjectFilters = useProjectFilterDefinitions()
  const [viewState] = useViewState()
  const hasAiViewGeneration = useHasTreatment('ai-view-generation')
  const hasWorkspaceFolders = useHasWorkspaceFoldersTreatment()

  const getRouteUri = useUriByRouteId({ noDefaults: true })

  const { workspaceId } = useParams<PMPageUrlParams>()

  const isGantt = viewState.page === 'gantt'

  const data = usePageData()
  const filterDefinitions = {
    tasks: allAvailableTaskFilters.filter(data.predicates.tasks.filters),
    projects: allAvailableProjectFilters.filter(
      data.predicates.projects.filters
    ),
  }

  const isArchivePage = data.variant === 'archive'

  const canFilterTasks =
    props.target !== 'projects' && filterDefinitions.tasks.length > 0
  const canFilterProjects = filterDefinitions.projects.length > 0
  const showFilterPanel =
    !isArchivePage && filtersVisible && (canFilterTasks || canFilterProjects)

  return (
    <HeaderBar>
      {hasWorkspaceFolders && <HeaderBreadcrumbs page={data.page} />}
      <div className='flex'>
        <OpenLeftSidebarButton className='mr-2' />
        <TitleBarContainer>
          <TitleBarTitle page={data.page}>{props.title}</TitleBarTitle>
          {!isArchivePage && <ConnectedViewDetailsButton />}
          {!isArchivePage && <ConnectedPageSelector />}
          {!isArchivePage && <ConnectectedViewSection />}
          {!isArchivePage && hasAiViewGeneration && (
            <ConnectedAiViewGenerationButton />
          )}
          {!isArchivePage && <ConnectedHeaderDotsButton />}
          <div className='flex-1' />

          <ButtonGroup>
            <ConnectedShowProjectSidebarButton />

            {workspaceId != null && (
              <Button
                size='small'
                sentiment='neutral'
                url={getRouteUri('workspace-settings', {
                  workspaceId,
                })}
              >
                <CogSolid />
                Workspace Settings
              </Button>
            )}

            {!isArchivePage && !isGantt && (
              <HasExperiment name='pmv3-tutorial-link'>
                <TutorialButtonLink
                  url='https://www.usemotion.com/blog/introducing-motion-pivot-table'
                  rounded
                  text='List View tutorial'
                  onClick={() => {
                    recordAnalyticsEvent('PROJECT_MANAGEMENT_TUTORIAL_CLICK', {
                      target: 'pivot-table-blog-post',
                      source: 'header',
                    })
                  }}
                />
              </HasExperiment>
            )}

            {!isArchivePage && isGantt && (
              <TutorialButtonLink
                lesson='workflow-templates'
                rounded
                text='Gantt View tutorial'
                onClick={() => {
                  recordAnalyticsEvent(
                    'PROJECT_MANAGEMENT_PLANNER_TUTORIAL_CLICK'
                  )
                }}
              />
            )}
          </ButtonGroup>
        </TitleBarContainer>
      </div>
      {!isArchivePage && (
        <SubheaderRow isGantt={isGantt}>
          <FilterBar>
            <ConnectedGroupByButton />
            <ConnectedSortButton />
            {viewState.page === 'kanban' && <KanbanCardFieldsButton />}
            {data.lock.workspaceId == null && <ConnectedWorkspaceFilter />}
            <ConnectedFiltersToggle
              open={filtersVisible}
              onClick={toggleFiltersVisible}
              clearable
              onClear={resetFilters}
            />
            <ConnectedNameSearchButton />
            {isGantt && <ConnectedShowCalendarsButton />}
            <ItemCounter />
          </FilterBar>
          {isGantt ? (
            <>
              <PlannerNavigateButtons />
              <ConnectedShowCompletedButton />
            </>
          ) : (
            <div className='flex flex-wrap items-center gap-3'>
              <ConnectedShowPastDeadlineButton />
              <ConnectedShowCompletedButton />
            </div>
          )}
        </SubheaderRow>
      )}

      {showFilterPanel && (
        <FilterPanel>
          {canFilterProjects && (
            <ConnectedFilterButtons
              definitions={filterDefinitions.projects}
              applyTo='projects'
              title='Projects'
            />
          )}
          {canFilterTasks && (
            <ConnectedFilterButtons
              definitions={filterDefinitions.tasks}
              applyTo='tasks'
              title='Tasks'
            />
          )}
        </FilterPanel>
      )}
    </HeaderBar>
  )
}

type TitleBarTitleProps = {
  children?: ReactNode
  page: RouteData['page']
}

const TitleBarTitle = (props: TitleBarTitleProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const hasWorkspaceFolders = useHasWorkspaceFoldersTreatment()

  return (
    <TitleBarHeader>
      <div className='inline-flex flex-row gap-1.5 items-center'>
        {hasWorkspaceFolders && <HeaderIcon pageType={props.page} />}

        <ConditionalWrapper
          condition={props.page === 'project'}
          wrapper={(children) => (
            <MotionLink
              url={buildProjectModalUrl({ project: 'edit' })}
              className='inline-flex gap-2 items-center'
            >
              {children}
            </MotionLink>
          )}
        >
          {props.children}
        </ConditionalWrapper>
        {props.page === 'folder' && <FolderProjectsTag />}
      </div>
    </TitleBarHeader>
  )
}

const HeaderBar = classed('div', {
  base: `
  flex flex-col
  pl-6 pr-4
  gap-3
  py-4
  border-b border-semantic-neutral-border-default
  `,
})

export const TitleBarContainer = classed('div', {
  base: `
    group/titlebar
    flex flex-wrap flex-1 items-start gap-3
  `,
})

export const TitleBarHeader = classed('h2', {
  base: `
    text-semantic-neutral-text-default
    font-semibold
    text-base
  `,
})

const SubheaderRow = classed('div', {
  base: `
    grid
    gap-3
  `,
  variants: {
    isGantt: {
      true: `
         xl:[grid-template-columns:1fr_auto]
      `,
    },
  },
})

type HeaderIconProps = {
  pageType: RouteData['page']
}

const HeaderIcon = ({ pageType }: HeaderIconProps) => {
  switch (pageType) {
    case 'workspace':
      return (
        <div className='size-6 inline-block p-1'>
          <WorkspaceBadge />
        </div>
      )

    case 'my-tasks':
      return (
        <div className='size-6 inline-block p-1'>
          <MyTasksGradientSolid className='size-4' />
        </div>
      )

    case 'all-tasks':
      return (
        <div className='size-6 inline-block p-1'>
          <AllTasksGradientSolid className='size-4' />
        </div>
      )

    case 'all-projects':
      return (
        <div className='size-6 inline-block p-1'>
          <ProjectGradientSolid className='size-4' />
        </div>
      )

    case 'project':
      return <ProjectIcon />

    case 'folder':
      return <FolderIcon />

    default:
      return null
  }
}

function ProjectIcon() {
  const { projectId } = useParams<PMPageUrlParams>()

  const project = useProject(projectId)
  const updateProject = useProjectUpdater()

  const onChangeProjectColor: ColorDropdownContentProps['onChange'] = (
    color
  ) => {
    if (!projectId) {
      throw new Error('Cannot save project color: projectId undefined')
    }

    return updateProject(projectId, { color })
  }

  if (project == null) return null

  return (
    <ColorDropdown
      selectedColor={project.color}
      onChange={onChangeProjectColor}
    >
      <Button size='small' variant='muted' sentiment='neutral' iconOnly>
        <ProjectColoredIcon className='size-4' color={project.color} />
      </Button>
    </ColorDropdown>
  )
}

function FolderIcon() {
  const { folderId } = useParams<PMPageUrlParams>()

  const getFolderById = useGetFolderById()
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const folder = folderId != null ? getFolderById(folderId) : null

  const onChangeFolderColor: ColorDropdownContentProps['onChange'] = async (
    color
  ) => {
    if (!folderId) {
      throw new Error('Cannot save folder color: folderId undefined')
    }

    recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
      updated: 'COLOR',
      location: 'PM_HEADER',
    })

    await updateFolder({ folderId, color })

    showToast('success', 'Folder color updated')
  }

  if (folder == null) return null

  return (
    <ColorDropdown selectedColor={folder.color} onChange={onChangeFolderColor}>
      <Button size='small' variant='muted' sentiment='neutral' iconOnly>
        <FolderColoredIcon className='size-4' color={folder.color} />
      </Button>
    </ColorDropdown>
  )
}
