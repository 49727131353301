import { DownloadOutline, DuplicateOutline, TrashSolid } from '@motion/icons'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { type ActionSection } from '@motion/ui/base'

import { useAttachmentActions } from '~/areas/attachments/hooks'

export const useAttachmentActionItems = (
  attachment: UploadedFileSchema
): { sections?: ActionSection[] } => {
  const { deleteAttachment, downloadAttachment, copyAttachmentLink } =
    useAttachmentActions()

  const handleDelete = () => {
    deleteAttachment(attachment)
  }

  const handleDownload = () => {
    downloadAttachment(attachment)
  }

  const handleCopyLink = () => {
    copyAttachmentLink(attachment)
  }

  return {
    sections: [
      {
        items: [
          {
            prefix: <DuplicateOutline />,
            content: 'Copy link',
            onAction: handleCopyLink,
          },
          {
            prefix: <DownloadOutline />,
            content: 'Download',
            onAction: handleDownload,
          },
        ],
      },
      {
        items: [
          {
            prefix: <TrashSolid />,
            content: 'Delete',
            destructive: true,
            onAction: handleDelete,
          },
        ],
      },
    ],
  }
}
