import { classed } from '@motion/theme'
import { HasExperiment } from '@motion/web-common/flags'

import { DeprecatedFlowTemplateTable } from '~/areas/project-management/settings/project-templates/deprecated-flow-template-table'
import { DeprecatedProjectTemplateTable } from '~/areas/project-management/settings/project-templates/deprecated-project-template-table'
import { Outlet } from 'react-router-dom'

import { ProjectTemplateTable } from './components'

export function ProjectTemplatesSettingsPage() {
  return (
    <Section>
      <HasExperiment
        fallback={
          <>
            <DeprecatedFlowTemplateTable />
            <DeprecatedProjectTemplateTable />
          </>
        }
        name='flows-m5a-reusable-stages'
      >
        <ProjectTemplateTable />
      </HasExperiment>

      <Outlet />
    </Section>
  )
}

const Section = classed('section', {
  base: 'flex flex-col gap-8 w-full pb-8',
})
