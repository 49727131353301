export const BASE_SIDEBAR_WIDTH = 202
// Extra padding for each grouping level we have (minimum 1 even if no groups)
export const PADDING_PER_GROUP_LEVEL = 16

// The gap for the project items (removed always from every project width)
export const PROJECT_ITEM_GAP = 4

// Stacking projects row height
export const MULTI_PROJECT_ROW_GAP = 8
export const MULTI_PROJECT_ROW_HEIGHT = 56

export const OOO_EVENT_GAP = 16

// Pixels to drag before we consider it a drag (otherwise it's a click)
export const DRAGGABLE_PROJECT_DISTANCE_THRESHOLD = 3

export const LEFT_PROJECT_OFFSET = 16

// The padding for the offscreen date indicator from the edge of the screen
export const OFFSCREEN_DATE_PADDING = 16

export const ONE_HOUR = 60 * 60 * 1_000

export const OOO_CALENDAR_ROW_HEIGHT = 40

// Row heights the project container uses
export const OOO_SUBROW_HEIGHT = 28
export const HEADER_ROW_HEIGHT = 36
export const SINGLE_PROJECT_ROW_HEIGHT = 48
export const ADD_ROW_HEIGHT = 32

// Map of z-index values for different components to ensure order
export const ZIndexMap = {
  // Project item needs to flow under the row name
  oooItem: 1,
  projectItem: 5,
  nameBackgroundContainer: 6,
  gapRow: 6,
  rowName: 6,
  // Offscreen indicator needs to flow under row name
  offscreenIndicator: 6,
  // Header row needs to sticky on top of items above it
  headerRow: 7,
  // Needs to be on top of everything except the name
  dayIndicator: 8,
}
