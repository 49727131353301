import { API } from '@motion/rpc'
import {
  type Calendar,
  type CalendarKillSwitch,
  type Contact,
} from '@motion/rpc/types'
import { getEditableCalendars, sortCalendars } from '@motion/ui-logic'
import { client } from '@motion/web-common/rpc'

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit'

import {
  createCalendar,
  fetchAllCalendars,
  updateCalendar,
  updateCalendars,
} from './calendar-list-thunks'
import { type CalendarListPickerModalProps } from './calendar-list-types'

import { deleteEmailAccount } from '../email-accounts/email-accounts-thunks'
import { type LoadingState } from '../projectManagementSlice'
import { type RootState } from '../store'

export const calendarsAdapter = createEntityAdapter<Calendar>({
  selectId: (c) => c.id,
})

export const initialCalendarListPickerModalState: CalendarListPickerModalProps =
  {
    section: 'mine',
    visible: false,
  }

export type CalendarListState = {
  /**
   * All the calendars for the current user for every email account connected
   */
  calendars: EntityState<Calendar>
  /**
   * Loading state when calling the `fetchAllCalendars` thunk
   */
  calendarsLoadingState: LoadingState
  /**
   * Tracks the loading state of createCalendar calls by the calendar provider
   * ID
   */
  createCalendarLoadingState: Record<string, LoadingState>
  /**
   * Controls the visibility of the calendar list picker modal
   */
  calendarListPickerModal: CalendarListPickerModalProps
  /**
   * Calendar ID of the primary calendar associated to the main email account.
   */
  mainCalendarId?: string
  /**
   * The selected teammate contacts that were selected on the "Meet with
   * teammates" search box, above the calendar. This currently can control
   * which calendars are visible, as well as a shortcut for adding guests to
   * an event.
   */
  selectedTeammateContacts: Contact[]
  /**
   * Whether the calendar is disabled or not
   */
  calendarDisabled: CalendarKillSwitch | null
  emailAccountsWithMissingPrimaryCalendar: string[]
  dismissedPrimaryCalendarModal: boolean
}

export const initialCalendarListState: CalendarListState = {
  calendars: calendarsAdapter.getInitialState(),
  calendarsLoadingState: 'preload',
  calendarListPickerModal: initialCalendarListPickerModalState,
  createCalendarLoadingState: {},
  selectedTeammateContacts: [],
  calendarDisabled: null,
  emailAccountsWithMissingPrimaryCalendar: [],
  dismissedPrimaryCalendarModal: false,
}

export const calendarListSlice = createSlice({
  extraReducers: (builder) => {
    /**
     * Update entity adapter with the latest fetched calendars. The payload
     * should be the latest state, so we can purge our current cache.
     */
    builder.addCase(fetchAllCalendars.fulfilled, (state, action) => {
      calendarsAdapter.removeAll(state.calendars)
      calendarsAdapter.upsertMany(state.calendars, action.payload.calendars)
      state.emailAccountsWithMissingPrimaryCalendar =
        action.payload.emailAccountsWithMissingPrimaryCalendar
      state.mainCalendarId = action.payload.mainCalendarId
      state.calendarsLoadingState = 'loaded'
      state.calendarDisabled = action.payload.isDisabled

      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(fetchAllCalendars.pending, (state) => {
      state.calendarsLoadingState = 'loading'
    })

    builder.addCase(fetchAllCalendars.rejected, (state) => {
      state.calendarsLoadingState = 'error'
    })

    builder.addCase(updateCalendar.fulfilled, (state, action) => {
      calendarsAdapter.upsertOne(state.calendars, action.payload)

      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(deleteEmailAccount.fulfilled, (state, action) => {
      const calendars = calendarsAdapter
        .getSelectors()
        .selectAll(state.calendars)
        .filter((c) => c.emailAccountId === action.meta.arg)

      calendarsAdapter.removeMany(
        state.calendars,
        calendars.map((c) => c.id)
      )
    })

    builder.addCase(updateCalendars.fulfilled, (state, action) => {
      calendarsAdapter.upsertMany(state.calendars, action.payload)

      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(createCalendar.pending, (state, action) => {
      const key = action.meta.arg.providerId
      state.createCalendarLoadingState[key] = 'loading'
    })

    builder.addCase(createCalendar.fulfilled, (state, action) => {
      const key = action.meta.arg.providerId
      state.createCalendarLoadingState[key] = 'loaded'

      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(createCalendar.rejected, (state, action) => {
      const key = action.meta.arg.providerId
      state.createCalendarLoadingState[key] = 'error'
    })
  },
  initialState: initialCalendarListState,
  name: 'calendarList',
  reducers: {
    /**
     * Toggle visibility of the Calendar List Picker Modal
     * @param state
     * @param action
     */
    setCalendarListPickerModal: (
      state: CalendarListState,
      action: PayloadAction<CalendarListPickerModalProps>
    ) => {
      state.calendarListPickerModal = {
        ...state.calendarListPickerModal,
        ...action.payload,
      }
    },
    setEmailAccountsWithMissingPrimaryCalendar: (
      state: CalendarListState,
      action: PayloadAction<string[]>
    ) => {
      state.emailAccountsWithMissingPrimaryCalendar = action.payload
    },
    setDismissedPrimaryCalendarModal: (
      state: CalendarListState,
      action: PayloadAction<boolean>
    ) => {
      state.dismissedPrimaryCalendarModal = action.payload
    },
    reset: () => initialCalendarListState,
    /**
     * Optimistically update a calendar. These changes are temporary, and will
     * be replaced the next time the calendar is updated via the thunk or
     * if all calendars are refetched.
     * @param state
     * @param action
     */
    optimisticallyUpdateCalendar: (
      state: CalendarListState,
      action: PayloadAction<Partial<Calendar> & Pick<Calendar, 'id'>>
    ) => {
      calendarsAdapter.updateOne(state.calendars, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setSelectedTeammateContacts: (
      state: CalendarListState,
      action: PayloadAction<Contact[]>
    ) => {
      state.selectedTeammateContacts = action.payload
    },
  },
})

export const {
  optimisticallyUpdateCalendar,
  reset,
  setCalendarListPickerModal,
  setSelectedTeammateContacts,
  setEmailAccountsWithMissingPrimaryCalendar,
  setDismissedPrimaryCalendarModal,
} = calendarListSlice.actions

export const selectCalendarsLoadingState = (state: RootState) =>
  state.calendarList.calendarsLoadingState

export const calendarSelectors = calendarsAdapter.getSelectors(
  (state: RootState) => state.calendarList.calendars
)

export const selectMainEmailAccountId = (state: RootState) =>
  state.emailAccounts.mainEmailAccountId

export const selectAllCalendars = createSelector(
  [calendarSelectors.selectAll, selectMainEmailAccountId],
  (calendars, mainCalendarAccountId) =>
    sortCalendars(calendars, mainCalendarAccountId)
)

/**
 * @deprecated use `useMyCalendars()` instead
 */
export const selectMyCalendars = createSelector(
  [selectAllCalendars],
  (calendars) => calendars.filter((c) => c.isInMyCalendars)
)

export const selectFrequentlyMetCalendars = createSelector(
  [selectAllCalendars],
  (calendars) =>
    calendars
      .filter((c) => c.isInFrequentlyMet)
      .sort((a, b) => a.title.localeCompare(b.title))
)

export const selectEmailAccountCalendars = createSelector(
  [selectAllCalendars, (_, emailAccountId: string) => emailAccountId],
  (allCalendars, emailAccountId) =>
    allCalendars.filter((c) => c.emailAccountId === emailAccountId)
)

/**
 * Returns a list of calendars that are 'editable', usually meaning the user
 * can place calendar events on the calendar.
 */
export const selectEditableCalendars = createSelector(
  [selectAllCalendars],
  getEditableCalendars
)

/**
 * @deprecated use `useActiveCalendars()` instead
 */
export const selectActiveCalendars = createSelector(
  [selectAllCalendars],
  (calendars) => {
    return calendars.filter((c) => c.isEnabled || c.isInMyCalendars)
  }
)

export const selectMainCalendarId = (state: RootState) =>
  state.calendarList.mainCalendarId

export const selectMainCalendar = createSelector(
  [selectMainCalendarId, selectAllCalendars],
  (mainCalendarId, calendars) => {
    return calendars.find((c) => c.id === mainCalendarId)
  }
)

export const selectCalendarListPickerModal = (state: RootState) =>
  state.calendarList.calendarListPickerModal

export const selectCalendarIsDisabled = (state: RootState) =>
  state.calendarList.calendarDisabled

export const selectEmailAccountsWithMissingPrimaryCalendar = (
  state: RootState
) => state.calendarList.emailAccountsWithMissingPrimaryCalendar

export const selectDismissedPrimaryCalendarModal = (state: RootState) =>
  state.calendarList.dismissedPrimaryCalendarModal

export const calendarListReducer = calendarListSlice.reducer
