import { showToast } from '@motion/ui/base'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useDeleteProject as useDeleteProjectCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useProjectAnalytics } from '../use-project-analytics'

export const useDeleteProject = () => {
  const modalApi = useModalApi()
  const { mutateAsync: deleteProject } = useDeleteProjectCall()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const getProjectAnalyticContext = useProjectAnalytics()

  return useCallback(
    async (projectId: ProjectSchema['id'], onConfirmed?: () => void) => {
      try {
        const project = getWorkspaceProjectById(projectId)
        if (project == null) {
          throw new Error('Project not found')
        }

        const response = await modalApi.prompt('confirm-delete-item', {
          analytics: {
            name: 'delete-project',
          },
          titleValue: 'this project',
          description:
            "This action is permanent and you won't be able to recover this project and all tasks inside of it.",
          deleteMessage: project.name,
          deleteButtonLabelValue: 'project',
        })

        if (response === ModalDismissed) return false

        onConfirmed?.()
        // Sucks but let's sleep 200ms to make sure the project modal closes when it's open
        // So we don't temporarily show a "project not found" screen
        await sleep(200)

        recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_PROJECT', {
          project_type: getProjectAnalyticContext(projectId).project_type,
        })

        await deleteProject({ projectId })

        showToast('success', 'Project deleted')

        return true
      } catch (e) {
        Sentry.captureException(e, { tags: { position: 'delete-project' } })

        showToast('error', 'There was an error deleting the project')
      }

      return false
    },
    [
      deleteProject,
      getProjectAnalyticContext,
      getWorkspaceProjectById,
      modalApi,
    ]
  )
}
