import { useSharedState } from '@motion/react-core/shared-state'

import {
  PlannerPropsKey,
  ScrollDeltaKey,
  ScrollPositionKey,
} from './context-keys'

const DEFAULT_SCOPE = 'shell'

export const usePlannerProps = () => {
  return useSharedState(PlannerPropsKey, {
    scope: DEFAULT_SCOPE,
  })
}

export const useScrollPosition = () => {
  return useSharedState(ScrollPositionKey, {
    scope: DEFAULT_SCOPE,
  })
}

export const useScrollDelta = () => {
  return useSharedState(ScrollDeltaKey, {
    scope: DEFAULT_SCOPE,
  })
}
