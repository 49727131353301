import { type ProjectSchema } from '@motion/rpc-types'

import { Attachments, AttachmentUploadButton } from '~/areas/attachments'
import { UncontrolledCollapsableContainer } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'
import { useState } from 'react'

type ProjectAttachmentsCollapsableProps = {
  projectId: ProjectSchema['id']
}

export const ProjectAttachmentsCollapsable = ({
  projectId,
}: ProjectAttachmentsCollapsableProps) => {
  const [expanded, setExpanded] = useState(false)

  const project = useProject(projectId)

  // Attachments are only available for normal
  if (project?.type !== 'NORMAL') {
    return null
  }

  const handleToggle = () => setExpanded((expanded) => !expanded)

  const handleAttachmentUpload = () => setExpanded(true)

  const attachmentIds = project.uploadedFileIds || []

  return (
    <UncontrolledCollapsableContainer
      title={`Attachments (${attachmentIds.length})`}
      headerActions={
        <AttachmentUploadButton
          targetId={projectId}
          targetType='PROJECT'
          workspaceId={project.workspaceId}
          onUpload={handleAttachmentUpload}
        />
      }
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='pl-7'>
        <Attachments
          attachmentIds={attachmentIds}
          targetId={projectId}
          targetType='PROJECT'
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
