import {
  API,
  type ApiUseMutationOptions,
  createUseMutation,
  createUseQuery,
} from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'

export const folderCacheKeysToUpdate = [
  API.folders.queryKeys.getAll,
  MODEL_CACHE_KEY,
]

const folderCacheQueryFilter = createQueryFilter(folderCacheKeysToUpdate)

export const useFolders = createUseQuery(API.folders.getFolders)

const useCreateFolderMutation = createUseMutation(API.folders.createFolder)

export const useCreateFolder = (
  opts?: ApiUseMutationOptions<typeof API.folders.createFolder>
) => {
  const queryClient = useQueryClient()

  return useCreateFolderMutation({
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, folderCacheQueryFilter, data)

      opts?.onSuccess?.(data, variables, context)
    },
    ...opts,
  })
}

const useUpdateFolderMutation = createUseMutation(API.folders.updateFolder)

export const useUpdateFolder = (
  opts?: ApiUseMutationOptions<typeof API.folders.updateFolder>
) => {
  const queryClient = useQueryClient()

  return useUpdateFolderMutation({
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, folderCacheQueryFilter, data)

      opts?.onSuccess?.(data, variables, context)
    },
    ...opts,
  })
}

export const useDeleteFolderMutation = createUseMutation(
  API.folders.deleteFolder
)

const useUpdateItemInFolderMutation = createUseMutation(
  API.folders.updateItemInFolder
)

export const useUpdateItemInFolder = (
  opts?: ApiUseMutationOptions<typeof API.folders.updateItemInFolder>
) => {
  const queryClient = useQueryClient()

  return useUpdateItemInFolderMutation({
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, folderCacheQueryFilter, data)

      opts?.onSuccess?.(data, variables, context)
    },
    ...opts,
  })
}
