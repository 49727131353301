import { type TaskUrlSearchParams } from '@motion/ui-logic/pm/task'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  ConnectedCreateFlowTemplateModal,
  ConnectedFlowTemplateModal,
  type FlowTemplateUrlSearchParams,
} from '~/areas/flows'
import { type ProjectUrlSearchParams } from '~/areas/project/modals/project-modal/utils'
import { type SetupProjectSearchParams } from '~/areas/project/modals/setup-project-modal/utils'
import {
  TemplateProjectModal,
  TemplateTaskModal,
} from '~/areas/project-management/modals'
import { ConnectedTaskModal } from '~/areas/tasks/modals/task-modal'
import { useSearchParams } from '~/routing'

import {
  type AttachmentSearchParams,
  ConnectedAttachmentModal,
} from '../attachments/modals/attachment-modal'
import { ConnectedCreateProjectModal } from '../project/modals/create-project-modal'
import { ConnectedProjectModal } from '../project/modals/project-modal'
import { ConnectedSetupProjectModal } from '../project/modals/setup-project-modal'

export const GlobalTaskProjectModals = () => {
  const { task, project, template, attachment, ...params } = useSearchParams<
    TaskUrlSearchParams &
      ProjectUrlSearchParams &
      FlowTemplateUrlSearchParams &
      SetupProjectSearchParams &
      AttachmentSearchParams
  >()

  const hasAttachmentsEnabled = useHasTreatment('webapp-attachments')

  const isProjectCreate = project === 'create'
  const isTemplateCreate = template === 'create'

  const showProjectModal = project != null && !isProjectCreate
  const showProjectSetupModal = isProjectCreate && params.flowTemplateId != null
  const showCreateProjectModal = isProjectCreate && !showProjectSetupModal

  const showFlowTemplateModal =
    template != null &&
    template !== 'create' &&
    !showProjectModal &&
    !showProjectSetupModal &&
    task == null
  const showCreateFlowTemplateModal =
    isTemplateCreate && !showProjectModal && task == null

  const shouldRenderTemplateTaskModal = task != null && template === 'new'
  const shouldRenderTemplateProjectModal =
    showProjectModal && template === 'new'

  const showAttachmentModal = hasAttachmentsEnabled && attachment != null

  return (
    <>
      {shouldRenderTemplateProjectModal ? (
        <TemplateProjectModal />
      ) : (
        <ConnectedProjectModal open={showProjectModal} />
      )}

      {shouldRenderTemplateTaskModal ? (
        <TemplateTaskModal />
      ) : (
        <ConnectedTaskModal open={task != null} />
      )}

      <ConnectedCreateProjectModal open={showCreateProjectModal} />
      <ConnectedSetupProjectModal open={showProjectSetupModal} />
      <ConnectedCreateFlowTemplateModal open={showCreateFlowTemplateModal} />
      <ConnectedFlowTemplateModal open={showFlowTemplateModal} />

      <ConnectedAttachmentModal open={showAttachmentModal} />
    </>
  )
}
