import { useHasTreatment } from '@motion/web-common/flags'

import { usePlannerProps } from '~/areas/project-management/pages/pm-v3/planner/context'
import { useMemo } from 'react'

import {
  type ResizeableProjectStageItemProps,
  type ResizeStageDetails,
} from '../types'
import { getStageLocation } from '../utils'

export const useStageLocation = (
  args: ResizeableProjectStageItemProps & { stageDetails?: ResizeStageDetails }
) => {
  const [plannerProps] = usePlannerProps()
  const hasFlowsBetterResizeStages = useHasTreatment(
    'flows-better-resize-stages'
  )

  return useMemo(() => {
    return getStageLocation({
      ...args,
      dayPx: plannerProps.dayPx,
      hasFlowsBetterResizeStages,
    })
  }, [args, plannerProps.dayPx, hasFlowsBetterResizeStages])
}
