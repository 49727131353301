import { CalendarSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'
import { shiftProjectDates } from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'

import { StartDateDropdown } from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export const ControlledStartDateField = () => {
  const { form } = useSetupProjectForm()

  const { control, getValues, setValue, watch } = form

  const { field } = useController({
    name: 'startDate',
    control,
  })

  const projectId = watch('projectId')
  const projectStartDate = watch('startDate')

  const dateButtonString = getDateButtonText(field.value, {
    placeholder: 'None',
  })

  return (
    <FieldLabel label='Start date' fullWidth>
      <div className='mt-1'>
        <StartDateDropdown
          value={field.value}
          onChange={(value) => {
            if (value == null) return

            // If the new start date is after the old start date, shift the project forward
            if (DateTime.fromISO(value) > DateTime.fromISO(projectStartDate)) {
              const { newProjectStart, newProjectDueDate, newStageDueDates } =
                shiftProjectDates({
                  oldProjectStart: projectStartDate,
                  newProjectStart: value,
                  projectDueDate: getValues('dueDate'),
                  stageDueDates: getValues('stageDueDates'),
                })

              setValue('stageDueDates', newStageDueDates)
              setValue('startDate', newProjectStart)
              setValue('dueDate', newProjectDueDate)
            }

            field.onChange(value)
          }}
          disableClearing
          contextProps={{ projectId }}
          dropdownTarget='project'
        >
          <Button
            variant='outlined'
            sentiment='neutral'
            fullWidth
            alignment='left'
            size='small'
          >
            <CalendarSolid />
            {dateButtonString}
          </Button>
        </StartDateDropdown>
      </div>
    </FieldLabel>
  )
}
