import { type CalendarEventSchemaV2 } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { useMeetingModalUrl } from '~/global/navigation'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export function useEventModal() {
  const modalApi = useModalApi()
  const buildMeetingModalUrl = useMeetingModalUrl()
  const navigate = useNavigate()

  /**
   * Open either the event/meeting modal for the given event.
   * If the event has a meetingTaskId, open the meeting modal. Otherwise, open the event modal.
   */
  return useCallback(
    (event: CalendarEventSchemaV2) => {
      if (event.meetingTaskId) {
        navigate(
          buildMeetingModalUrl({
            mTask: event.meetingTaskId,
          })
        )
        return
      }

      modalApi.open('event-modal', {
        eventId: event.id,
      })
    },
    [buildMeetingModalUrl, modalApi, navigate]
  )
}
