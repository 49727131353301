import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'

import {
  getVariableKeyByName,
  getWrappedVariableKeyByName,
  type PresetTemplateOptions,
  type PresetWithoutWorkspaceFields,
} from './utils'

import {
  createNewRole,
  createNewTextVariable,
  DEFAULT_TEXT_VARIABLES,
} from '../../utils'

export const professionalServicesPreset = (opt?: PresetTemplateOptions) => {
  const useEventType = opt?.useEventType ?? false

  const roles = [
    createNewRole('Consultant'),
    createNewRole('Business Analyst'),
    createNewRole('Project Planner'),
    createNewRole('Copywriter'),
    createNewRole('Video Producer'),
  ]
  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES,
    createNewTextVariable('Client'),
    createNewTextVariable('InvoiceNumber'),
  ]

  const eventScheduleMeetingWithinDays = useEventType
    ? DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS
    : null

  return {
    hasError: false,
    isLoading: false,
    labelIds: [],
    name: 'Professional Services Engagement',
    description:
      '<p>Use this template if you are a professional services team</p>',
    managerId: null,
    priorityLevel: 'MEDIUM' as const,
    color: 'gray' as const,
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Client Assessment',
        color: 'cyan',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: useEventType
              ? `Initial consultation with ${getWrappedVariableKeyByName('Client', textVariables)}`
              : `Initial Client Consultation - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 120
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: `Assess Client - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Business Analyst',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Draft and Present Proposal - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Get Client Approval - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 15,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(15),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? `Project kickoff with ${getWrappedVariableKeyByName('Client', textVariables)}`
              : `Schedule Kickoff Meeting - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Planning & Resourcing',
        color: 'yellow',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 6,
        tasks: [
          {
            name: `Develop Project Plan - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Create tasks and assign to team members in the Execution Stage - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Establish Communication Channels',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Execution',
        color: 'blue',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 20,
        tasks: [
          {
            name: useEventType
              ? `Setup check-in with ${getWrappedVariableKeyByName('Client', textVariables)}`
              : `Setup Regular Check-ins - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Review',
        color: 'pink',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 10,
        tasks: [
          {
            name: 'Review Project and Ensure High Quality',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Implement Training Materials - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Delivery',
        color: 'green',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 5,
        tasks: [
          {
            name: useEventType
              ? `Project delivery with ${getWrappedVariableKeyByName('Client', textVariables)}`
              : `Schedule delivery meeting with ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: `Release Project - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Bill Client - ${getWrappedVariableKeyByName('Client', textVariables)} - ${getWrappedVariableKeyByName('InvoiceNumber', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Business Analyst',
              roles
            ),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
    ],
    roles,
    textVariables,
  } satisfies PresetWithoutWorkspaceFields
}
