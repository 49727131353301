import { FLOW_VARIABLE_KEY_PREFIX } from '@motion/shared/flows'
import { type VariableDefinitionSchema } from '@motion/zod/client'

export function isTextVariable(
  field: VariableDefinitionSchema
): field is Omit<VariableDefinitionSchema, 'type'> & { type: 'text' } {
  return field.type === 'text'
}

export function isRoleVariable(
  field: VariableDefinitionSchema
): field is Omit<VariableDefinitionSchema, 'type'> & { type: 'person' } {
  return field.type === 'person'
}

export function isFlowVariableKey(key: string | null): boolean {
  return key != null && key.startsWith(FLOW_VARIABLE_KEY_PREFIX)
}
