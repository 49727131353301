import { Sentry } from '@motion/web-base/sentry'
import { type CreateCalendarEventSchema } from '@motion/zod/client'

import { useCreateCalendarEvent as useCreateCalendarEventCall } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useCreateEvent = () => {
  const { mutateAsync: createCalendarEvent } = useCreateCalendarEventCall()

  return useCallback(
    async (data: CreateCalendarEventSchema & { calendarId: string }) => {
      try {
        const response = await createCalendarEvent(data)
        return response
      } catch (e) {
        Sentry.captureException(e)
        showErrorToast(e)
      }
    },
    [createCalendarEvent]
  )
}
