import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { END_PROJECT_BUFFER } from './constants'
import { deprecatedGetStageLocationProjectResize } from './deprecated-get-stage-location-project-resize'
import { getInitialStageLocation } from './get-initial-stage-location'
import { getStageLocationProjectResize } from './get-stage-location-project-resize'
import { getStageLocationStageResize } from './get-stage-location-stage-resize'

import { type Side } from '../../../resize-handle'
import { type ResizeStageDetails, type StageWithDates } from '../../types'

type GetStageLocationArgs = {
  dayPx: number
  stageWithDates: StageWithDates
  currentSide?: Side
  project: ProjectSchema
  projectDeltaWidth?: number
  stageDetails?: ResizeStageDetails
  hasFlowsBetterResizeStages?: boolean
}

export function getStageLocation({
  dayPx,
  stageWithDates,
  project,
  currentSide,
  projectDeltaWidth = 0,
  stageDetails,
  hasFlowsBetterResizeStages,
}: GetStageLocationArgs) {
  const { stage, start } = stageWithDates

  let {
    maxStageWidth,
    pixelsFromProjectStart,
    completedDuration,
    initialProjectWidth,
  } = getInitialStageLocation({
    dayPx,
    stageWithDates,
    project,
  })

  let maxStageLeft = initialProjectWidth
  let stageLeft = pixelsFromProjectStart

  if (projectDeltaWidth !== 0) {
    let projectResizeResult
    if (hasFlowsBetterResizeStages) {
      projectResizeResult = getStageLocationProjectResize({
        dayPx,
        currentSide,
        stageWithDates,
        projectDeltaWidth,
        stageLeft,
        maxStageLeft,
        maxStageWidth,
        project,
      })
    } else {
      // Project is being resized
      projectResizeResult = deprecatedGetStageLocationProjectResize({
        stage,
        projectStages: project.stages,
        projectWidth: initialProjectWidth,
        projectDeltaWidth,
        currentSide,
        initialStageLeft: pixelsFromProjectStart,
        initialMaxStageWidth: maxStageWidth,
      })
    }

    stageLeft = projectResizeResult.stageLeft
    maxStageWidth = projectResizeResult.maxStageWidth
    maxStageLeft = projectResizeResult.maxStageLeft
  } else if (stageDetails?.delta !== 0) {
    const stageResizeResult = getStageLocationStageResize({
      stage,
      stageLeft,
      maxStageLeft: initialProjectWidth,
      maxStageWidth,
      project,
      stageDetails,
    })

    stageLeft = stageResizeResult.stageLeft
    maxStageWidth = stageResizeResult.maxStageWidth
    maxStageLeft = stageResizeResult.maxStageLeft
  }

  // If the stage left is greater than the maxStageLeft, we should adjust the stage left
  if (stageLeft >= maxStageLeft) {
    stageLeft = maxStageLeft - END_PROJECT_BUFFER
  }

  // If the maxStageWidth is less than 0, we should set it to 0
  if (maxStageWidth < 0) {
    maxStageWidth = 0
  }

  // If the stage left is less than 0, we should set it to 0
  if (stageLeft < 0) {
    stageLeft = 0
  }

  // Get number of days the stage has been shifted
  let adjustedStartDate = DateTime.fromISO(start).plus({
    days: (stageLeft - pixelsFromProjectStart) / dayPx,
  })

  let adjustedEndDate = adjustedStartDate.plus({
    days: maxStageWidth / dayPx,
  })

  return {
    stageLeft,
    stageProgressWidth: (completedDuration / 100) * maxStageWidth,
    maxStageWidth,
    adjustedStartDate,
    adjustedEndDate,
  }
}
