import { API } from '@motion/rpc'
import { client } from '@motion/web-common/rpc'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { type EmailAccountsService } from '../../services/email-accounts-service'
import { getProxy } from '../backgroundProxy'
import { fetchAllCalendars } from '../calendar-list/calendar-list-thunks'
import { type RootState } from '../store'

const THUNK_PREFIX = 'email-accounts'

const emailAccountsService = getProxy('EmailAccountsService')

/**
 * Retrieve calendars across all email accounts for the current user
 */
export const fetchAllEmailAccounts = createAsyncThunk(
  `${THUNK_PREFIX}/fetchAllEmailAccounts`,
  () => emailAccountsService.getAll()
)

export const deleteEmailAccount = createAsyncThunk(
  `${THUNK_PREFIX}/deleteEmailAccount`,
  async (emailAccountId: string) => emailAccountsService.delete(emailAccountId)
)

/**
 * Set a specific email account as the main calendar. This is a unique
 * association across the entire application, so only one user can have an email
 * account marked as "main".
 */
export const setMainCalendar = createAsyncThunk<
  ReturnType<EmailAccountsService['setAsMain']>,
  string,
  { state: RootState }
>(`${THUNK_PREFIX}/setMainCalendar`, async (emailAccountId, thunkAPI) => {
  try {
    const res = await emailAccountsService.setAsMain(emailAccountId)

    if (res.assigned) {
      void thunkAPI.dispatch(fetchAllEmailAccounts())
      void thunkAPI.dispatch(fetchAllCalendars())

      client.invalidateQueries(API.calendars.queryKeys.root)
    }

    return res
  } catch (e) {
    // TODO refactor - should rethrow if the exception thrown does not contain
    // a custom JSON response.
    return { assigned: false, success: false }
  }
})

type CanAccessCalendarsProps = {
  emailAccountId: string
  calendarEmails: string[]
}

export const canAccessCalendars = createAsyncThunk(
  `${THUNK_PREFIX}/canAccessCalendars`,
  async (props: CanAccessCalendarsProps) => {
    const res = await emailAccountsService.canAccessCalendars(
      props.emailAccountId,
      props.calendarEmails
    )
    return res.accessibleCalendars
  }
)
