import { type COLOR } from '@motion/shared/common'
import { ProjectPalette } from '@motion/ui/project'

import { StageConnectorBar, StageProgress } from './styled'

type StageConnectorProps = {
  color: COLOR
  half: boolean
  progress: number
}
export function StageConnector({ color, half, progress }: StageConnectorProps) {
  return (
    <ProjectPalette color={color}>
      <StageConnectorBar half={half}>
        <StageProgress
          style={{
            width: `${Math.min(progress, 100)}%`,
          }}
        />
      </StageConnectorBar>
    </ProjectPalette>
  )
}
