import { twMerge } from 'tailwind-merge'

export interface AvailabilityMessageProps {
  richLinks: boolean
  message: string
  availabilityHoverHandler?: (availability: boolean) => void
}

export const AvailabilityMessage = ({
  richLinks,
  message,
  availabilityHoverHandler = () => {},
}: AvailabilityMessageProps) => {
  return (
    <div
      className='dark:bg-dark-1200 dark:border-dark-900 dark:text-light-100 border-light-600 bg-light-100 text-light-1200 w-full rounded border py-1.5 px-2'
      onMouseEnter={() =>
        availabilityHoverHandler && availabilityHoverHandler(true)
      }
      onMouseLeave={() =>
        availabilityHoverHandler && availabilityHoverHandler(false)
      }
    >
      <div
        dangerouslySetInnerHTML={{ __html: message }}
        className={twMerge(
          richLinks ? 'whitespace-normal' : 'whitespace-pre-wrap'
        )}
      />
    </div>
  )
}
