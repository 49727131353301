import { type AmplitudeExperimentName } from '@motion/web-common/flags'

const permanentExperiments = [
  'release-notes',
  'auto-schedule-range',
  'new_maintenance_mode',
  'feature_flagged_banner',
] as const

export const AmplitudeExperimentNames = [
  ...permanentExperiments,
  'bulk_add_tasks_minimum_tasks',
  'reschedule-stale-tasks-modal',
  'icloud-full-time-blocking-options',
  'cancellation-interception-discount',
  'mobile-push-notifications-release-v3',
  'stripe-trial-length',
  'onboarding-video',
  'email-signin-checkout',
  'onboarding-iframes',
  'peek-calendars-dropdown',
  'no-cc-combined-trial',
  'pmv3-tutorial-link',
  'login-no-email-found-modal',
  'max-calendar-events',
  'skip-firebase-connection-error',
  'set-primary-calendar-modal',
  'cookie-alert',
  'team-pricing',
  'change-email',
  'cookie-auth-only',
  // Flows
  'flows-duplicate-flow',
  'flows-better-resize-stages',
  'flows-m4-skip-stages',
  'flows-m5-reusable-stages',
  'show-team-trial-upsell-videos',
  // KFS
  'kfs-cta-settings',
  'kfs-timezone-settings',
  'kill-firestore-onboarding-settings-dual-write',
  'kill-firestore-onboarding-settings-read-v2',
  'kill-firestore-conference-settings-dual-write',
  'kill-firestore-conference-settings-read',
  // Reactions
  'comment-reactions',
  'team-trial-for-individual',
  'show-prices-on-plan-selection-page',
  'b2b-on-mobile',
  'workspace-folders',
  'pinned-views',
] as const

export type { AmplitudeExperimentName }

export type AmplitudeExperiments = Record<
  AmplitudeExperimentName,
  string | undefined
>
