import { useContext } from 'react'

import {
  PeekModalCommonContext,
  type PeekModalCommonContextState,
} from '../peek-modal-common-context'

export const usePeekModalCommonContext = (): PeekModalCommonContextState => {
  const context = useContext(PeekModalCommonContext)

  if (!context) {
    throw new Error(
      'usePeekModalCommonContext must be used within a PeekModalCommonContextProvider'
    )
  }

  return context
}
