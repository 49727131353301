import { type ProjectSchema } from '@motion/zod/client'

import { type Row } from '@tanstack/react-table'
import { useProjectModalUrl } from '~/global/navigation'
import { memo, type ReactElement, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { ProjectContainer } from './project-container'

import { useInferProjectDataHandler } from '../../../../tree-list/cells/hooks'
import { getProjectInferItem } from '../../../../tree-list/cells/hooks/utils'
import { usePlannerProps, useScrollPosition } from '../../../context'
import { useScreenValues, useSidebarSize } from '../../../hooks'
import {
  LEFT_PROJECT_OFFSET,
  MULTI_PROJECT_ROW_HEIGHT,
  OFFSCREEN_DATE_PADDING,
} from '../../../shared-constants'
import { getProjectStartAndEnd } from '../../../utils'
import { parseProjectValuesToSearchParams } from '../../add-project/utils'
import { SelectableProjectDatesRow } from '../../selectable-project-dates-row'
import { OffscreenDateIndicator } from '../single-planner-row/offscreen-date-indicator'

type MultiProjectLaneProps = {
  hasOOOEvents?: boolean
  row: Row<any>
  projects: ProjectSchema[]
}
export const MultiProjectLane = memo(function MultiProjectLane(
  props: MultiProjectLaneProps
) {
  const { hasOOOEvents, row, projects } = props
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const inferProjectData = useInferProjectDataHandler()
  const projectInferItem = getProjectInferItem({ row: row.original })
  const inferredData = inferProjectData(projectInferItem)
  const inferredSearchParams = useMemo(
    () => parseProjectValuesToSearchParams(inferredData),
    [inferredData]
  )

  const selectProjectDates = useCallback(
    (startDate: string, dueDate: string) => {
      navigate(
        buildProjectModalUrl({
          project: 'new',
          forStartDate: startDate,
          forDueDate: dueDate,
          ...inferredSearchParams,
        })
      )
    },
    [buildProjectModalUrl, inferredSearchParams, navigate]
  )

  return (
    <div
      className='relative w-full h-full'
      style={{
        height: hasOOOEvents
          ? MULTI_PROJECT_ROW_HEIGHT + 8
          : MULTI_PROJECT_ROW_HEIGHT,
      }}
    >
      <div
        className='w-full h-full bg-transparent absolute inset-0'
        data-type='draggable'
      >
        <SelectableProjectDatesRow onSelectDates={selectProjectDates} stacked />
        <MultiProjectScrollingLane
          hasOOOEvents={hasOOOEvents}
          projects={projects}
          rowId={row.id}
        />
      </div>
    </div>
  )
})

type MultiProjectScrollingLane = {
  hasOOOEvents?: boolean
  projects: ProjectSchema[]
  rowId: string
}

type OffScreenProject = {
  project: ProjectSchema
  distance: number
  side: 'left' | 'right'
} | null

const MultiProjectScrollingLane = (props: MultiProjectScrollingLane) => {
  const { hasOOOEvents, projects, rowId } = props
  const [plannerProps] = usePlannerProps()
  const { positionOffset, screenSize } = useScreenValues()
  const [scrollPosition] = useScrollPosition()
  const sidebarSize = useSidebarSize()

  const scrollInterval = -scrollPosition

  const projectStartAndEndMap = useMemo(() => {
    const map = new Map<string, { start: number; end: number }>()

    projects.forEach((project) => {
      const itemStartAndEnd = getProjectStartAndEnd(project, plannerProps.dayPx)
      map.set(project.id, itemStartAndEnd)
    })

    return map
  }, [plannerProps.dayPx, projects])

  const visible: ReactElement[] = []
  const leftEdge = scrollInterval - positionOffset + sidebarSize
  const rightEdge = scrollInterval + screenSize - positionOffset
  let firstToTheLeft: OffScreenProject = null
  let firstToTheRight: OffScreenProject = null
  for (const project of projects) {
    if (project.id === plannerProps.resizingId) {
      visible.push(
        <ProjectContainer
          project={project}
          key={`project-multi-${project.id}-${rowId}`}
        />
      )
      continue
    }
    const itemStartAndEnd = projectStartAndEndMap.get(project.id) ?? {
      start: 0,
      end: 0,
    }
    if (itemStartAndEnd.end < leftEdge + LEFT_PROJECT_OFFSET) {
      firstToTheLeft = {
        project,
        distance: Math.abs(itemStartAndEnd.end - leftEdge),
        side: 'left',
      }
    } else if (itemStartAndEnd.start > rightEdge) {
      if (!firstToTheRight) {
        firstToTheRight = {
          project,
          distance: Math.abs(itemStartAndEnd.start - rightEdge),
          side: 'right',
        }
      }
    } else {
      visible.push(
        <ProjectContainer
          project={project}
          key={`project-multi-${project.id}-${rowId}`}
        />
      )
    }
  }
  // Get the closest project to the left or right of the screen
  let firstOffscreen: OffScreenProject = null
  if (!visible.length) {
    if (firstToTheRight && firstToTheLeft) {
      firstOffscreen =
        firstToTheRight.distance < firstToTheLeft.distance
          ? firstToTheRight
          : firstToTheLeft
    } else {
      firstOffscreen = firstToTheLeft ?? firstToTheRight ?? null
    }
  }

  const offScreenStyle = useMemo(() => {
    if (!firstOffscreen) return {}
    const baseStyles = hasOOOEvents
      ? {
          top: 8,
        }
      : {}
    return firstOffscreen.side === 'left'
      ? { ...baseStyles, left: sidebarSize + OFFSCREEN_DATE_PADDING }
      : { ...baseStyles, right: OFFSCREEN_DATE_PADDING }
  }, [firstOffscreen, sidebarSize, hasOOOEvents])

  return (
    <>
      {firstOffscreen && (
        <OffscreenDateIndicator
          style={offScreenStyle}
          showName
          project={firstOffscreen.project}
          direction={firstOffscreen.side}
        />
      )}
      <div
        className={twMerge(
          'absolute inset-0 z-[2] w-fit flex top-1',
          hasOOOEvents && 'top-4'
        )}
        style={{ transform: `translateX(${scrollPosition}px)` }}
      >
        {visible}
      </div>
    </>
  )
}
