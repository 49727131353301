import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'

import { type ReactNode, useState } from 'react'

import { EventFormAllDayField } from './event-form-allday-field'
import { EventFormRecurrenceField } from './event-form-recurrence-field'
import { EventFormTravelTimeField } from './event-form-traveltime-field'

import { useEventFormOptions } from '../event-form-options-context'
import { useEventFormRecurrenceField, useEventTravelTimeField } from '../hooks'

export const EventFormAllDayRecurrenceAndTravelTimeField = () => {
  const { isReadOnly } = useEventFormOptions()
  const { hasTravelTime, travelTimeText } = useEventTravelTimeField()
  const { hasRecurrence, recurrenceText } = useEventFormRecurrenceField()

  const RecurrenceAndTravelTimeDisplay = () => (
    <div className='font-normal'>
      {hasTravelTime && <div>{travelTimeText}</div>}
      {hasRecurrence && <div>{recurrenceText}</div>}
    </div>
  )

  if (isReadOnly) {
    // Read only and empty render nothing
    if (!hasTravelTime && !hasRecurrence) {
      return null
    }
    // Read only and not empty display only (not interactive)
    return (
      <Container className='pl-9'>
        <RecurrenceAndTravelTimeDisplay />
      </Container>
    )
  }

  return (
    <Container>
      <CollapsedRecurrenceAndTravelTime
        isEmpty={!hasTravelTime && !hasRecurrence}
        renderNonEmptyState={() => <RecurrenceAndTravelTimeDisplay />}
      />
    </Container>
  )
}

type CollapsedRecurrenceAndTravelTimeProps = {
  isEmpty: boolean
  renderNonEmptyState: () => ReactNode
}

type EmptyStateProps = {
  travelTimeDisabled?: boolean
}
const EmptyState = ({ travelTimeDisabled }: EmptyStateProps) => {
  return (
    <div className='flex gap-3 font-normal'>
      <span>All day</span>
      <span>Repeat</span>
      {!travelTimeDisabled && <span>Travel time</span>}
    </div>
  )
}

const CollapsedRecurrenceAndTravelTime = (
  props: CollapsedRecurrenceAndTravelTimeProps
) => {
  const { renderNonEmptyState: NonEmptyState, isEmpty } = props
  const { hostCalendar } = useEventFormOptions()
  const [isExpanded, setIsExpanded] = useState(false)

  if (isExpanded) {
    return (
      <>
        <EventFormAllDayField />
        <EventFormRecurrenceField />
        <EventFormTravelTimeField />
      </>
    )
  }

  return (
    <StyledButton
      size='xsmall'
      sentiment='neutral'
      variant='muted'
      onClick={() => setIsExpanded(true)}
    >
      {isEmpty ? (
        <EmptyState
          travelTimeDisabled={hostCalendar.providerType === 'APPLE'}
        />
      ) : (
        <NonEmptyState />
      )}
    </StyledButton>
  )
}

const StyledButton = classed(Button, {
  base: 'px-2 inline text-left',
})

const Container = classed('div', {
  base: `
   pr-2 pl-7 pb-2 text-xs bg-semantic-neutral-bg-disabled
   text-field-text-placeholder
  `,
})
