import {
  type Calendar,
  type Contact,
  type EmailAccount,
} from '@motion/rpc/types'
import { createTemporaryCalendar } from '@motion/ui-logic/calendar'

import { updateSelectedTeammateContacts } from '~/state/calendar-list/calendar-list-thunks'
import { useState } from 'react'

import { type CalendarListPickerSectionType } from '../../../../../../state/calendar-list/calendar-list-types'
import { canAccessCalendars } from '../../../../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch } from '../../../../../../state/hooks'

type Props = {
  /**
   * Whether or not to allow temporary calendars to be 'created'. By default,
   * this is set to true.
   */
  allowNewCalendars?: boolean
  calendars: Calendar[]
  /**
   * If true, then contacts that are not already calendars in the calendar
   * list will be created
   */
  createNewCalendars?: boolean
  emailAccounts: EmailAccount[]
  /**
   * If `createNewCalendars` is true, then this will be invoked when the user
   * selects a contact that's not already a calendar. This should make a call
   * to persist the contact as a new calendar, and return the calendar.
   *
   * @param calendars
   * @returns
   */
  onCreateNewCalendars?: (calendars: Calendar[]) => Promise<Calendar[]>
  section?: CalendarListPickerSectionType
  setCalendarChecked: (calendar: Calendar, checked: boolean) => void
}

/**
 * Hook that encapsulates logic for validating and generating calendar objects
 * to display on a calendar list picker. The common use case is to search for
 * a particular teammate's calendar that is not already on the user's
 * frequently met list. This generates a temporary calendar that will be
 * persisted to the calendar. This also makes it easier to add a certain
 * teammate to the frequently met list if the user has many frequently met
 * calendars.
 *
 * @param props
 */
export const useAddContactCalendar = (props: Props) => {
  const {
    allowNewCalendars,
    calendars,
    createNewCalendars,
    emailAccounts,
    onCreateNewCalendars,
    section,
    setCalendarChecked,
  } = props
  const dispatch = useAppDispatch()

  // Tracks the selected searched contacts in the contacts auto-complete search
  // bar. The values are email addresses
  const [selectedContacts, setSelectedContacts] = useState<string[]>([])

  // Flag for tracking if we're in the middle of checking calendar access. This
  // prevents the user from pressing Save while the validation is in progress
  const [isCheckingCalendarAccess, setIsCheckingCalendarAccess] =
    useState<boolean>(false)

  // Tracks temporarily generated calendars as a result of searching up
  // contacts. Valid contacts are added into the picker list
  const [temporaryCalendars, setTemporaryCalendars] = useState<Calendar[]>([])

  // Selected search contacts that are not readable by the main calendar will
  // append a warning string here
  const [permissionWarnings, setPermissionWarnings] = useState<string[]>([])

  const resetState = () => {
    setSelectedContacts([])
    setIsCheckingCalendarAccess(false)
    setPermissionWarnings([])
  }

  /**
   * Fired when the contacts auto-complete values change. This will query the
   * backend to see whether the specified calendar emails are readable by the
   * main calendar, and will display an error for those calendars that are not
   * readable.
   *
   * @param values
   * @param contacts
   */
  const onContactSearched = async (
    values: string[],
    contacts: Contact[] | undefined
  ) => {
    setPermissionWarnings([])
    setSelectedContacts(values)

    if (!values.length || !contacts?.length) {
      return
    }

    setIsCheckingCalendarAccess(true)

    // Group the contacts by the owning email account
    const groupedContacts = contacts.reduce(
      (prev: Map<string, Contact[]>, cur: Contact) => {
        if (!cur.account) {
          return prev
        }

        if (!prev.has(cur.account)) {
          prev.set(cur.account, [])
        }

        prev.get(cur.account)?.push(cur)

        return prev
      },
      new Map<string, Contact[]>()
    )

    // Validate read access for all contacts grouped by email account
    const promises = []
    for (const [email, contacts] of groupedContacts.entries()) {
      const emailAccount = emailAccounts.find((e) => e.email === email)
      if (!emailAccount) {
        continue
      }

      promises.push(
        dispatch(
          canAccessCalendars({
            emailAccountId: emailAccount.id,
            calendarEmails: contacts.map((c) => c.email),
          })
        ).unwrap()
      )
    }

    const res = await Promise.allSettled(promises).finally(() => {
      setIsCheckingCalendarAccess(false)
    })

    // Flatten all successful requests so we have an array of accessible
    // calendars
    const accessibleCalendars = res.reduce((prev, cur) => {
      prev.push(...(cur.status === 'fulfilled' ? cur.value : []))
      return prev
    }, [] as string[])

    const warnings: string[] = []
    const tempCalendars: Calendar[] = []

    // For each contact, check whether the contact's calendar is readable.
    // If so, then we want to generate a temporary calendar (if applicable) so
    // the contact is rendered on the calendar list. If not readable, we want
    // to instead display an error message
    const calendarsToCreate: Calendar[] = []
    const contactsToUpdate: Contact[] = []
    for (const contact of contacts) {
      const isAccessible = accessibleCalendars.includes(contact.email)

      if (!isAccessible) {
        warnings.push(
          section === 'mine'
            ? `${contact.email} can’t be added to My Calendars (limited access).`
            : `${contact.email} can’t be added to Frequently Met With (no access).`
        )

        continue
      }

      // If contact already exists as a persisted calendar, then mark the
      // calendar as checked.
      const existing = calendars.find(
        (c) => c.title === contact.email || c.providerId === contact.email
      )
      if (existing) {
        setCalendarChecked(existing, true)
        continue
      }

      const emailAccount = emailAccounts.find(
        (e) => e.email === contact.account
      )
      if (!emailAccount) {
        continue
      }

      if (allowNewCalendars === false) {
        warnings.push(`The new calendar ${contact.email} can’t be added`)
        continue
      }

      // Since the contact doesn't exist as a calendar yet, create a temporary
      // one
      const calendar = createTemporaryCalendar(contact.email, emailAccount)

      if (createNewCalendars) {
        calendarsToCreate.push(calendar)
        contactsToUpdate.push(contact)
      } else {
        tempCalendars.push(calendar)
        setCalendarChecked(calendar, true)
      }
    }

    if (
      createNewCalendars &&
      onCreateNewCalendars &&
      calendarsToCreate.length
    ) {
      const newCalendars = await onCreateNewCalendars(calendarsToCreate)
      void dispatch(updateSelectedTeammateContacts(contactsToUpdate))

      newCalendars.map((c) => {
        setCalendarChecked(c, true)
      })
    }

    setPermissionWarnings(warnings)
    setTemporaryCalendars(tempCalendars)
  }

  return {
    isCheckingCalendarAccess,
    onContactSearched,
    permissionWarnings,
    resetState,
    selectedContacts,
    temporaryCalendars,
  }
}
