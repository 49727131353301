import { classed } from '@motion/theme'

import { useEventFormConferencingField } from '../hooks'

// eslint-disable-next-line unused-imports/no-unused-vars
const validConferenceTypes = [
  'hangoutsMeet',
  'zoom',
  'teamsForBusiness',
] as const

type ValidConferenceType = (typeof validConferenceTypes)[number]
const nameMap: Record<ValidConferenceType, { label: string }> = {
  hangoutsMeet: { label: 'Meet' },
  zoom: { label: 'Zoom' },
  teamsForBusiness: { label: 'Teams' },
}

export const EventFormJoinConferenceButton = () => {
  const { field, formValues } = useEventFormConferencingField()
  let validType: ValidConferenceType | undefined

  if (field.value === 'hangoutsMeet' || field.value === 'meet') {
    validType = 'hangoutsMeet'
  } else if (field.value === 'zoom') {
    validType = 'zoom'
  } else if (field.value === 'teamsForBusiness') {
    validType = 'teamsForBusiness'
  }

  if (!formValues.conferenceLink || !validType) return null
  return (
    <ConfButton
      href={formValues.conferenceLink}
      target='_blank'
      rel='noreferrer'
      conferenceType={validType}
    >
      Join {nameMap[validType]?.label || ''}
    </ConfButton>
  )
}

const ConfButton = classed('a', {
  base: `
   select-none px-2 py-1 text-xs rounded
   border border-solid
   font-semibold
   focus-visible:outline-2 focus-visible:outline outline-offset-1 text-semantic-neutral-text-onDark
  `,
  variants: {
    conferenceType: {
      hangoutsMeet: `
        bg-semantic-meet-bg-default hover:bg-semantic-meet-bg-hover
        border-semantic-meet-bg-default hover:border-semantic-meet-bg-hover
      `,
      zoom: `
        bg-semantic-zoom-bg-default hover:bg-semantic-zoom-bg-hover
        border-semantic-zoom-bg-default hover:border-semantic-zoom-bg-hover
      `,
      teamsForBusiness: `
        bg-semantic-teams-bg-default hover:bg-semantic-teams-bg-hover
        border-semantic-teams-bg-default hover:border-semantic-teams-bg-hover
        `,
    },
  },
  defaultVariants: {
    conferenceType: 'zoom',
  },
})
