import { type SvgIcon } from '@motion/icons'
import { FieldTypes, type FieldTypeSchema } from '@motion/shared/custom-fields'
import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'

import { type BadgeSize } from '~/global/components/badges'
import {
  getLabelComponent,
  type TaskFieldLabelProps,
} from '~/global/components/labels'
import {
  type AppWorkspaceDataContext,
  type AppWorkspaceDataKeys,
  useAppWorkspaceContext,
} from '~/global/contexts'
import { type FC } from 'react'

type MultiSelectFilterValueProps = {
  type: AppWorkspaceDataKeys | FieldTypeSchema
  ids: string[] | null
  Icon: SvgIcon
  customField?: CustomFieldSchemaByType<'multiSelect' | 'select'>
  getAllItems?: () => { id: string; name: string }[]
  labelSize?: BadgeSize
}

export const MultiSelectFilterValue = (props: MultiSelectFilterValueProps) => {
  const [ctx] = useAppWorkspaceContext()
  if (props.ids == null) {
    return 'None'
  }

  const { type, ids, labelSize = 'normal' } = props
  const cacheKey = FieldTypes.includes(type)
    ? 'customFields'
    : (type as AppWorkspaceDataKeys)
  const cache = ctx[cacheKey]
  const allItems = props.getAllItems?.()

  if (ids.length === 1) {
    const labelType = getLabelItemType(props.type)()
    const Label = getLabelComponent(labelType) as FC<TaskFieldLabelProps<any>>
    const value = getSingleValue(cache, ids[0], type, allItems)
    if (value == null) {
      return 'None'
    }
    return <Label value={value} keyProp='' size={labelSize} />
  }

  const text =
    ids.length === 0 ||
    ids.length === cache.all.length ||
    (allItems != null && allItems.length === ids.length)
      ? 'All'
      : String(ids.length)
  return (
    <div className='flex gap-1 items-center'>
      <props.Icon
        width={12}
        height={12}
        className='text-semantic-neutral-icon-default'
      />
      {text} values
    </div>
  )
}

const getLabelItemType = createLookup({
  users: () => 'user',
  statuses: () => 'status',
  stageDefinitions: () => 'stage',
  projectDefinitions: () => 'projectDefinition',
  labels: () => 'label',
  projects: () => 'project',
  folders: () => 'folder',
  priorities: () => 'priority',
  multiSelect: () => 'multiSelect',
  select: () => 'select',
  default: () => 'unknown',
})

const getSingleValue = (
  cache: AppWorkspaceDataContext[AppWorkspaceDataKeys],
  id: string,
  type: MultiSelectFilterValueProps['type'],
  allItems?: ReturnType<NonNullable<MultiSelectFilterValueProps['getAllItems']>>
) => {
  if (FieldTypes.includes(type)) {
    return allItems?.find((o) => o.id === id)
  }
  return cache.byId[id] ?? allItems?.find((o) => o.id === id)
}
