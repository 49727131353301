import { PlusOutline } from '@motion/icons'
import {
  type UploadedFileSchema,
  type UploadFileRequest,
} from '@motion/rpc-types'
import { SUPPORTED_MIME_TYPES } from '@motion/shared/files'
import { Button, type ButtonProps, showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useI18N } from '~/global/contexts'
import { useRef } from 'react'

import { useUploadAttachments } from './hooks'

const FILE_SIZE_LIMIT_IN_MEGBYTES = 50

const FILE_SIZE_LIMIT_IN_BYTES = FILE_SIZE_LIMIT_IN_MEGBYTES * 1024 * 1024

export type AttachmentUploadButtonProps = ButtonProps & {
  targetId: Required<UploadFileRequest>['targetId']
  targetType: Required<UploadFileRequest>['targetType']
  workspaceId: UploadFileRequest['workspaceId']
  label?: string
  onUpload?: (files: File[]) => void
  onUploadSettled?: (uploadedFile: UploadedFileSchema) => void
}

export const AttachmentUploadButton = ({
  targetId,
  targetType,
  workspaceId,
  label = 'Add attachment',
  onUpload,
  onUploadSettled,
  ...rest
}: AttachmentUploadButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { pluralize } = useI18N()

  const uploadAttachments = useUploadAttachments({
    targetId,
    targetType,
    workspaceId,
    onUploadSettled,
  })

  const handleAttachmentUploadClick = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleAttachmentUploadChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(e.target.files || [])

    // We want to log an event for all files selected regardless
    // of wether they pass the size check or not
    files.forEach((file) => {
      recordAnalyticsEvent('ATTACHMENT_FILE_UPLOAD', {
        file_size: file.size,
        mime_type: file.type,
        target_type: targetType ?? 'none',
      })
    })

    const filesOverSizeLimit: File[] = []
    const filesUnderSizeLimit: File[] = []

    // Check all files are under the size limit
    files.forEach((file) =>
      file.size > FILE_SIZE_LIMIT_IN_BYTES
        ? filesOverSizeLimit.push(file)
        : filesUnderSizeLimit.push(file)
    )

    if (filesOverSizeLimit.length > 0) {
      if (filesOverSizeLimit.length === files.length) {
        showToast(
          'error',
          `Attachments must be under ${FILE_SIZE_LIMIT_IN_MEGBYTES}MB.`
        )
      } else {
        filesOverSizeLimit.forEach((file) => {
          showToast(
            'error',
            `${file.name} exceeds the ${FILE_SIZE_LIMIT_IN_MEGBYTES}MB limit.`
          )
        })
      }
    }

    if (filesUnderSizeLimit.length === 0) return

    onUpload?.(filesUnderSizeLimit)

    // Upload attachments
    const { failedFileUploads } = await uploadAttachments(filesUnderSizeLimit)

    if (filesUnderSizeLimit.length === failedFileUploads.length) {
      showToast('error', 'Upload failed. Please try again.')
    } else if (failedFileUploads.length > 0) {
      showToast('neutral', 'Some attachments failed to upload.')
    } else {
      showToast(
        'success',
        `${pluralize(files.length, 'Attachment', 'Attachments')} uploaded successfully.`
      )
    }

    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <>
      <input
        ref={fileInputRef}
        type='file'
        onChange={handleAttachmentUploadChange}
        accept={SUPPORTED_MIME_TYPES.join(',')}
        multiple
        hidden
      />
      <Button
        onClick={handleAttachmentUploadClick}
        variant='muted'
        sentiment='neutral'
        aria-label='Upload attachment'
        {...rest}
      >
        <PlusOutline className='size-16' />
        {label}
      </Button>
    </>
  )
}
