import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { useIsFlowsM4Enabled } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

import { useCancelProject, useCompleteProject } from '../actions'
import { useProjectAnalytics } from '../use-project-analytics'

export function useProjectStatusUpdater() {
  const updateProject = useProjectUpdater()
  const completeProject = useCompleteProject()
  const cancelProject = useCancelProject()

  const isFlowsM4Enabled = useIsFlowsM4Enabled()

  const getProjectAnalyticContext = useProjectAnalytics()

  const { getStatusById, getWorkspaceProjectById } = useWorkspaceFns()

  return useCallback(
    async (
      projectOrId: ProjectSchema | ProjectSchema['id'],
      statusId: string
    ) => {
      const status = getStatusById(statusId)
      if (status == null) {
        throw new Error('Invalid status', {
          cause: {
            statusId,
          },
        })
      }

      const project =
        typeof projectOrId === 'string'
          ? getWorkspaceProjectById(projectOrId)
          : projectOrId
      if (project == null) {
        throw new Error('Invalid project')
      }

      if (status && isCompletedStatus(status)) {
        const completed = await completeProject(project.id)
        if (!completed) {
          return false
        }

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PROJECT_CONTEXT', {
          ...getProjectAnalyticContext(project.id),
        })

        showToast('success', 'Project saved')
        return true
      }

      if (isFlowsM4Enabled && status && isCanceledStatus(status)) {
        const canceled = await cancelProject(project.id, statusId)
        if (!canceled) {
          return false
        }

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PROJECT_CONTEXT', {
          ...getProjectAnalyticContext(project.id),
        })

        showToast('success', 'Project saved')
        return true
      }

      // If not, update the project status as usual
      await updateProject(projectOrId, {
        statusId,
      })
      return true
    },
    [
      getStatusById,
      isFlowsM4Enabled,
      updateProject,
      getWorkspaceProjectById,
      completeProject,
      getProjectAnalyticContext,
      cancelProject,
    ]
  )
}
