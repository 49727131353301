import { type Calendar, CalendarAccessRole } from '@motion/rpc/types'

import { useMemo } from 'react'

import { useEventForm } from './use-event-form-hook'

import { selectMyCalendars } from '../../../../../../state/calendar-list/calendar-list-slice'
import { selectEmailAccountsMap } from '../../../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../../../state/hooks'

export function useMyCalendarsWithEvent(hostCalendar: Calendar) {
  const myCalendars = useAppSelector(selectMyCalendars)
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const form = useEventForm()
  const event = form.getValues()

  return useMemo(
    () =>
      myCalendars
        .reduce(
          (acc: Calendar[], otherCalendar) => {
            const email = emailAccountsMap.get(
              otherCalendar.emailAccountId
            )?.email

            if (
              email != null &&
              event.attendees?.find(
                (a) =>
                  a.email === email &&
                  otherCalendar.providerId !== hostCalendar.providerId
              )
            ) {
              return [...acc, otherCalendar]
            }
            return acc
          },
          [hostCalendar]
        )
        .filter((calendar) =>
          [CalendarAccessRole.EDITOR, CalendarAccessRole.OWNER].includes(
            calendar.accessRole
          )
        ),
    [myCalendars, event.attendees, emailAccountsMap, hostCalendar]
  )
}
