import { ArrowRightSolid } from '@motion/icons'
import { FormModal } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  type ProjectsV2AdvanceStageRequestSchema,
  type ProjectsV2SetStageRequestSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { StageLabel } from '~/global/components/labels'
import { useStageDefinition } from '~/global/hooks'
import { useState } from 'react'

import { MultiStageSkipContent } from './multi-stage-skip-content'
import { NextStageSkipContent } from './next-stage-skip-content'

export type SkipStagesResponse =
  | ProjectsV2SetStageRequestSchema['strategy']
  | ProjectsV2AdvanceStageRequestSchema['strategy']

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'skip-stages-modal': PromptCallbacks<SkipStagesResponse> & {
      enabledStages: StageSchema[]
      currentStageDefinitionId: StageDefinitionSchema['id']
      newStageDefinitionId: StageDefinitionSchema['id']
    }
  }
}

export const SkipStagesModal = ({
  close,
  enabledStages,
  currentStageDefinitionId,
  newStageDefinitionId,
}: ModalTriggerComponentProps<'skip-stages-modal'>) => {
  const [value, setValue] = useState<SkipStagesResponse>('VISIT')

  const currentStageIndex = enabledStages.findIndex(
    (s) => s.stageDefinitionId === currentStageDefinitionId
  )
  const newStageIndex = enabledStages.findIndex(
    (s) => s.stageDefinitionId === newStageDefinitionId
  )

  const currentStageDef = useStageDefinition(currentStageDefinitionId)
  const newStageDef = useStageDefinition(newStageDefinitionId)

  if (enabledStages.length === 0) {
    throw new Error('No enabled stages found')
  }

  if (currentStageIndex < 0 || newStageIndex < 0) {
    throw new Error('Stage definitions cannot be found', {
      cause: {
        enabledStages,
        currentStageIndex,
        newStageIndex,
      },
    })
  }

  const isNextStage = currentStageIndex + 1 === newStageIndex

  const handleSubmit = () => {
    close(value)
  }

  return (
    <FormModal
      visible
      onClose={() => close()}
      submitAction={{
        onAction: handleSubmit,
        text: 'Continue',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      title={
        <div className='flex items-start flex-col gap-2'>
          <span>Advance project from</span>
          <div className='flex gap-2'>
            {templateStr('{{stage}} {{ arrow }} {{newStage}}', {
              stage: <StageLabel value={currentStageDef} />,
              arrow: <ArrowRightSolid className='size-[18px]' />,
              newStage: <StageLabel value={newStageDef} />,
            })}
          </div>
        </div>
      }
    >
      {isNextStage ? (
        <NextStageSkipContent value={value} setValue={setValue} />
      ) : (
        <MultiStageSkipContent value={value} setValue={setValue} />
      )}
    </FormModal>
  )
}
