import { RadioButton } from '@motion/ui/forms'

import React from 'react'

import { type SkipStagesResponse } from './skip-stages-modal'
import { Container, LabelContainer, Question } from './styled'

interface MultiStageSkipContentProps {
  value: SkipStagesResponse
  setValue: (value: SkipStagesResponse) => void
}

export const MultiStageSkipContent: React.FC<MultiStageSkipContentProps> = ({
  value,
  setValue,
}) => {
  return (
    <Container>
      <Question>What do you want to do for the stages in-between?</Question>

      <RadioButton
        checked={value === 'COMPLETE'}
        onChange={() => setValue('COMPLETE')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>
              Complete tasks in stages in-between
            </span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will complete any tasks that are not in the Completed or
              Canceled status
            </span>
          </LabelContainer>
        )}
      />

      <RadioButton
        checked={value === 'CANCEL'}
        onChange={() => setValue('CANCEL')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>Cancel tasks in stages in-between</span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will cancel any tasks that are not in the Completed or
              Canceled status
            </span>
          </LabelContainer>
        )}
      />

      <RadioButton
        checked={value === 'VISIT'}
        onChange={() => setValue('VISIT')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>
              Auto-schedule tasks in stages in-between
            </span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will auto-schedule any tasks that are not in the Completed or
              Canceled status
            </span>
          </LabelContainer>
        )}
      />
    </Container>
  )
}
