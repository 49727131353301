import { type AmplitudeExperimentName } from '@motion/web-common/flags'
import {
  type ActivitySchema,
  type ActivityTaskOrProjectCommentCreatedSchema,
  type ActivityTaskOrProjectCreatedSchema,
  type FeedEntrySchema,
} from '@motion/zod/client'

import { type FeedEntryViewSchema } from '../../types'

/**
 * Check if the activity is a creation activity.
 * This means that the activity is either ActivityType.PROJECT_CREATED or ActivityType.TASK_CREATED.
 * @param activity
 * @returns
 */
export function isCreationActivity(
  activity: ActivitySchema
): activity is ActivityTaskOrProjectCreatedSchema {
  return (
    activity.activityType === 'PROJECT_CREATED' ||
    activity.activityType === 'TASK_CREATED'
  )
}

function isCommentActivity(
  activity: FeedEntrySchema
): activity is ActivityTaskOrProjectCommentCreatedSchema {
  return (
    activity.activityType === 'TASK_COMMENT_CREATED' ||
    activity.activityType === 'PROJECT_COMMENT_CREATED'
  )
}

/**
 * Check if the activity is a valid activity that the frontend current supports.
 * This means that the activity is not ActivityType.TASK_COMMENT_CREATED and ActivityType.PROJECT_COMMENT_CREATED.
 * @param activity
 * @returns
 */
export function isValidActivity(
  activity: FeedEntrySchema,
  options?: {
    featureFlags?: {
      [key in AmplitudeExperimentName]?: boolean
    }
  }
): activity is FeedEntryViewSchema {
  if (isCommentActivity(activity)) {
    return false
  }

  const isAttachmentsEnabled = !!options?.featureFlags?.['webapp-attachments']
  const isAttachmentsActivity = activity.activityType.startsWith('ATTACHMENT')

  if (isAttachmentsActivity) return isAttachmentsEnabled

  return true
}
