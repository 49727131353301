import {
  createStateKey,
  type StateKey,
  useSharedState,
} from '@motion/react-core/shared-state'
import { cloneDeep } from '@motion/utils/core'
import { safeJsonParse } from '@motion/utils/object'

import { DateTime } from 'luxon'

import { type TeamScheduleView } from '../types'

export type TeamSchedulePivotColumn = {
  field: 'scheduledStart' | 'dueDate'
  from: string
}

export type TeamScheduleViewState = {
  hideWeekends: boolean
  rowOrder: string[] | null

  column: TeamSchedulePivotColumn
  viewId: string
}

export const DEFAULT_VIEW_ID = '<default!team-view>'
export const DEFAULT_VIEW: TeamScheduleView = {
  id: DEFAULT_VIEW_ID,
  isPrivate: false,
  name: 'Default View',
  type: 'team-schedule',
  definition: {
    $version: 2,
    type: 'team-schedule',
    itemType: 'tasks',
    layout: 'list',
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'include',
        },
      },
      projects: {
        ordered: [],
        filters: {
          completed: 'include',
        },
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    grouping: {
      fields: [
        {
          key: 'assignee',
        },
      ],
      dateRange: 'month',
      hideEmptyGroups: false,
      order: {
        assignee: [],
      },
    },
    sort: [{ field: 'scheduled-date', direction: 'ASC' }],
    options: {
      hideWeekends: false,
    },
  },
}

const DEFAULT_VIEW_OPTIONS: TeamScheduleViewState = {
  hideWeekends: false,
  rowOrder: null,

  column: {
    field: 'scheduledStart',
    from: DateTime.now().startOf('day').toString(),
  },
  viewId: DEFAULT_VIEW_ID,
}

function deserializeViewState(text: string): TeamScheduleViewState {
  try {
    const value = safeJsonParse(text, DEFAULT_VIEW_OPTIONS)
    if (value === DEFAULT_VIEW_OPTIONS) {
      return cloneDeep(value)
    }

    value.column.from = DateTime.local().startOf('day').toISO()
    value.viewId ??= DEFAULT_VIEW_ID

    return value
  } catch (ex) {
    return DEFAULT_VIEW_OPTIONS
  }
}

export const TeamScheduleActiveViewOptionsKey =
  createStateKey<TeamScheduleViewState>('active-view-options', {
    defaultValue: DEFAULT_VIEW_OPTIONS,
    deserialize: deserializeViewState,
    serialize: (value) => JSON.stringify(value),
  })

export const useViewOptions = <T extends object>(key: StateKey<T>) => {
  return useSharedState(key)
}
