import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useInNoExternalCalendarsMode } from '../../../../hooks/use-in-no-external-calendars-mode'

export type SharedValues = {
  name?: string
}

type Mode = 'task' | 'event'
export type PeekModalCommonContextState = {
  mode: Mode
  setMode: (mode: Mode) => void
  sharedValues: SharedValues
  setSharedValues: (sharedValues: SharedValues) => void
}

export const PeekModalCommonContext = createContext<
  PeekModalCommonContextState | undefined
>(undefined)

export const PeekModalCommonContextProvider: React.FC<{
  children: ReactNode
  id: string | undefined
}> = ({ children, id }) => {
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  // This is a temporary solution until we refactor this code when creating the
  // Task Peek Modal. Ideally, the caller that opens this modal should pass a
  // param for which mode to initialize to.
  const [mode, setMode] = useState<Mode>(
    noExternalCalendarsMode ? 'task' : 'event'
  )
  const [sharedValues, setSharedValues] = useState<SharedValues>({})

  const reset = useCallback(() => {
    setMode(noExternalCalendarsMode ? 'task' : 'event')
    setSharedValues({})
  }, [noExternalCalendarsMode])

  useEffect(() => {
    reset()
  }, [id, reset])

  const contextValue: PeekModalCommonContextState = useMemo(() => {
    return { mode, setMode, sharedValues, setSharedValues }
  }, [mode, sharedValues])

  return (
    <PeekModalCommonContext.Provider value={contextValue}>
      {children}
    </PeekModalCommonContext.Provider>
  )
}
