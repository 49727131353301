import { classed } from '@motion/theme'
import {
  formatToShortTaskDuration,
  formatToTaskDuration,
} from '@motion/ui-logic'

import { type BadgeSize, DurationBadge } from '~/global/components/badges'

type DurationLabelProps = {
  value: number | null
  size?: BadgeSize
  longMode?: boolean
}

export const DurationLabel = ({
  value,
  size,
  longMode,
}: DurationLabelProps) => {
  const formattedDuration = longMode
    ? formatToTaskDuration(value)
    : formatToShortTaskDuration(value)

  return (
    <DurationContainer>
      <DurationBadge value={value} size={size} hideTooltip />
      <span className='text-xs'>{formattedDuration}</span>
    </DurationContainer>
  )
}

const DurationContainer = classed('div', {
  base: `
    flex flex-row items-center overflow-hidden leading-4 gap-1
  `,
})
