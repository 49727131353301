import { type SetupProjectFormFields } from '@motion/ui-logic/pm/project'
import { pick } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import {
  type PriorityLevelSchema,
  type ProjectsV2ApplyDefinitionRequestSchema,
  type ProjectsV2CreateFromTaskRequestSchema,
  type ProjectsV2CreateRequestSchema,
} from '@motion/zod/client'

import {
  useApplyDefinitionToProject,
  useCreateProject,
  useRedirectToProject,
} from '~/areas/project/hooks'
import { showErrorToast } from '~/global/toasts'

import { useSetupProjectModalUrlParams } from './use-setup-project-modal-url'

import { useTaskPrefillData } from '../../project-modal/hooks'
import { type SetupProjectSearchParams } from '../utils'

export function useSubmitSetupProjectForm() {
  const createProject = useCreateProject()
  const applyDefinitionToProject = useApplyDefinitionToProject()
  const redirectToProject = useRedirectToProject()
  const setupModalUrlParams = useSetupProjectModalUrlParams()
  const getTaskData = useTaskPrefillData()
  const { forProjectId } = setupModalUrlParams

  return async (data: SetupProjectFormFields) => {
    try {
      if (forProjectId != null) {
        const projectCandidate = setupProjectFormToApplyDefinitionCandidate(
          data,
          forProjectId
        )

        const project = await applyDefinitionToProject(projectCandidate)
        if (project == null) {
          return
        }

        redirectToProject(project.id)
      } else {
        const taskData =
          setupModalUrlParams.forTaskId != null
            ? await getTaskData(setupModalUrlParams.forTaskId)
            : null

        const projectCandidate = setupProjectFormToProjectCandidate(
          data,
          taskData,
          setupModalUrlParams
        )

        const project = await createProject(projectCandidate)

        if (project == null) {
          return
        }

        redirectToProject(project.id)
      }
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitSetupProjectForm',
        },
      })

      showErrorToast(e)
    }
  }
}

function setupProjectFormToProjectCandidate(
  data: SetupProjectFormFields,
  taskData: ProjectsV2CreateFromTaskRequestSchema | null,
  forParams: SetupProjectSearchParams
): ProjectsV2CreateRequestSchema {
  if (data.projectDefinition == null) {
    throw new Error('Project definition is missing')
  }

  const projectDefinitionFields = pick(data.projectDefinition, [
    'description',
    'labelIds',
    'managerId',
    'priorityLevel',
    'color',
  ])

  const baseFields: (keyof ProjectsV2CreateFromTaskRequestSchema)[] = [
    'taskId',
    'description',
    'color',
    'managerId',
    'priorityLevel',
  ]
  const additionalFields: (keyof ProjectsV2CreateRequestSchema)[] =
    data.workspaceId === taskData?.workspaceId
      ? ['statusId', 'labelIds', 'customFieldValues']
      : []

  const taskDataFields = taskData
    ? pick(taskData, [...baseFields, ...additionalFields])
    : null

  return {
    ...pick(data, [
      'workspaceId',
      'name',
      'startDate',
      'dueDate',
      'projectDefinitionId',
    ]),
    ...projectDefinitionFields,
    ...taskDataFields,
    variableInstances: [...data.roleAssignees, ...data.textReplacements],
    stages: data.stageDueDates,

    // Set the "for" params value, if it's not set, use the task's value
    statusId: forParams.forStatus ?? taskDataFields?.statusId,
    priorityLevel:
      (forParams.forPriority as PriorityLevelSchema | undefined) ??
      taskDataFields?.priorityLevel ??
      projectDefinitionFields.priorityLevel,
    managerId:
      forParams.forManager ??
      taskDataFields?.managerId ??
      projectDefinitionFields.managerId,
    labelIds: forParams.forLabel
      ? [forParams.forLabel]
      : (taskDataFields?.labelIds ?? projectDefinitionFields.labelIds),
    folderId: forParams.forFolder,
  }
}

function setupProjectFormToApplyDefinitionCandidate(
  data: SetupProjectFormFields,
  forProjectId: string
): ProjectsV2ApplyDefinitionRequestSchema & {
  projectDefinitionId: string
  projectId: string
} {
  if (data.projectDefinitionId == null) {
    throw new Error('Project definition id is missing')
  }

  return {
    name: data.name,
    projectDefinitionId: data.projectDefinitionId,
    startDate: data.startDate,
    dueDate: data.dueDate,
    projectId: forProjectId,
    variableInstances: [...data.roleAssignees, ...data.textReplacements],
    stages: data.stageDueDates,
  }
}
