import { ExternalLinkSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import {
  ProjectDropdown,
  type ProjectDropdownProps,
} from '~/areas/project-management/components'
import { useTaskProjectUpdater } from '~/areas/tasks/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction } from './components'

type ProjectCellProps = {
  task: TaskWithRelations
}

export const ProjectCell = ({ task }: ProjectCellProps) => {
  const updateTaskProject = useTaskProjectUpdater()

  const onChange: ProjectDropdownProps['onChange'] = (projectId) => {
    updateTaskProject(task, projectId)
  }

  const disabled =
    task.type === 'RECURRING_INSTANCE' || task.archivedTime != null

  return (
    <ProjectDropdown
      selectedProjectId={task.projectId}
      workspaceId={task.workspaceId}
      onChange={onChange}
      hideNoProject={isMeetingTask(task)}
    >
      <CellAction disabled={disabled}>
        <span className='truncate'>
          {task.project ? task.project.name : 'No project'}
        </span>
      </CellAction>
    </ProjectDropdown>
  )
}

type OpenProjectButtonProps = {
  id: ProjectSchema['id']
}

export const OpenProjectButton = ({ id }: OpenProjectButtonProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  return (
    <Button
      sentiment='neutral'
      variant='muted'
      size='xsmall'
      url={buildProjectModalUrl({ project: id })}
      onClick={() => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_OPEN_PROJECT_CLICK')
      }}
    >
      <ExternalLinkSolid />
      Open project
    </Button>
  )
}
