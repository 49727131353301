import { ArrowLeftSolid, ArrowRightSolid, CheckSolid } from '@motion/icons'
import { GradientButton, IconButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type TutorialLesson } from '../types'

type TutorialViewerProps = {
  lesson: TutorialLesson | undefined
  sectionTitle: string
  completed: boolean
  onNextLesson: () => void
  onPrevLesson: () => void
  onCompleteLesson: () => void
}

export const TutorialViewer = ({
  lesson,
  sectionTitle,
  completed,
  onNextLesson,
  onPrevLesson,
  onCompleteLesson,
}: TutorialViewerProps) => {
  if (!lesson) return null

  return (
    <div className='flex flex-col gap-6'>
      <div>
        <p className='text-semantic-neutral-text-subtle text-xs pb-2'>
          {sectionTitle}
        </p>
        <p className='text-xl font-semibold'>{lesson.title}</p>
        <div
          className='text-semantic-neutral-text-subtle text-sm pt-4'
          dangerouslySetInnerHTML={{ __html: lesson.description }}
        />
      </div>
      <TutorialEmbeddedVideo
        videoUrl={lesson.video}
        onVideoPlay={() => {
          recordAnalyticsEvent('TUTORIALS_VIDEO_PLAYED', {
            lessonId: lesson.id,
          })
        }}
      />
      <div className='flex justify-between'>
        <GradientButton
          sentiment={completed ? 'promotion' : 'purple'}
          disabled={completed}
          onClick={onCompleteLesson}
        >
          {completed ? (
            <>
              <CheckSolid /> Completed
            </>
          ) : (
            'Complete'
          )}
        </GradientButton>
        <div className='flex flex-row items-center gap-2'>
          <IconButton
            icon={ArrowLeftSolid}
            sentiment='neutral'
            onClick={onPrevLesson}
          />
          <IconButton
            icon={ArrowRightSolid}
            sentiment='neutral'
            onClick={onNextLesson}
          />
        </div>
      </div>
    </div>
  )
}

type TutorialEmbeddedVideoProps = {
  videoUrl: string
  onVideoPlay: () => void
}

const TutorialEmbeddedVideo = ({
  videoUrl,
  onVideoPlay,
}: TutorialEmbeddedVideoProps) => {
  return (
    <div
      className='flex w-full rounded-lg overflow-hidden'
      onClick={onVideoPlay}
    >
      <iframe
        className='w-full aspect-video'
        frameBorder='0'
        allowFullScreen
        src={videoUrl}
      />
    </div>
  )
}
