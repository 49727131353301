import {
  formatDateTime,
  getAutoscheduleInfo,
  type ScheduleInfo,
  templateStr,
} from '@motion/ui-logic'
import { isTaskSchema } from '@motion/ui-logic/pm/task'
import type {
  NormalTaskSchema,
  RecurringTaskSchema,
  TaskSchema,
} from '@motion/zod/client'

import {
  composeGhostAutoScheduleTooltipMessage,
  type GhostAutoScheduleTooltipVars,
} from '~/areas/flows'
import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments/use-autoschedule-range'
import { useLookup } from '~/global/cache'
import { useProject, useProjectDefinition } from '~/global/hooks'
import { createTaskProxy } from '~/global/proxies'
import { type ReactNode, useMemo } from 'react'

import { useBlockerOverrideTooltip } from './use-blocker-override-tooltip'

export function useAutoScheduleTooltip(
  scheduleDateInfo: ScheduleInfo,
  task: TaskSchema | RecurringTaskSchema | undefined
): ReactNode {
  const { isUnvisitedStage, projectId, stageDefinitionId } =
    task?.type === 'NORMAL' ? task : ({} as NormalTaskSchema)

  const project = useProject(projectId)
  const projectDefinition = useProjectDefinition(
    project?.projectDefinitionId ?? ''
  )

  const taskStage = projectDefinition?.stages.find(
    (s) => s.id === stageDefinitionId
  )

  const baseTooltipMessage = useBaseAutoScheduleTooltipMessage(
    scheduleDateInfo,
    task
  )

  if (isUnvisitedStage) {
    const ghostTooltipVars =
      taskStage != null
        ? ({
            taskStage,
          } satisfies GhostAutoScheduleTooltipVars)
        : undefined

    return composeGhostAutoScheduleTooltipMessage(
      baseTooltipMessage,
      isUnvisitedStage,
      ghostTooltipVars
    )
  }

  return baseTooltipMessage
}

function useBaseAutoScheduleTooltipMessage(
  scheduleDateInfo: ScheduleInfo,
  task: TaskSchema | RecurringTaskSchema | undefined
): ReactNode {
  const autoScheduleRange = useAutoScheduleRange()

  const lookup = useLookup()
  const taskProxy = useMemo(() => {
    if (task?.type !== 'NORMAL') {
      return undefined
    }
    return createTaskProxy(task, lookup)
  }, [task, lookup])

  const blockerOverrideTooltip = useBlockerOverrideTooltip(taskProxy)
  if (blockerOverrideTooltip) {
    return blockerOverrideTooltip
  }

  const isAutoScheduled = task?.isAutoScheduled ?? false
  const isCompleted = isTaskSchema(task) && task.completedTime != null

  const formattedDueDate =
    isTaskSchema(task) && task.dueDate != null
      ? formatDateTime(task.dueDate)
      : ''

  const { formattedScheduledDate, formattedScheduledDateTime } =
    getAutoscheduleInfo(task, isAutoScheduled, isCompleted)

  const baseTooltipMessage = scheduleDateInfo.tooltip
    ? templateStr(scheduleDateInfo.tooltip, {
        date: formattedScheduledDate,
        datetime: formattedScheduledDateTime,
        deadline: formattedDueDate,
        autoScheduleRange,
      })
    : undefined

  return baseTooltipMessage
}
