import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { ProjectPalette } from '@motion/ui/project'

import { StageTooltip } from '~/areas/flows'
import { useLookup } from '~/global/cache'
import { StageBadge } from '~/global/components/badges'
import { useStageDefinition } from '~/global/hooks'
import { createProjectProxy } from '~/global/proxies'

import { useResizeStages, useStageLocation } from './hooks'
import { type ResizeableProjectStageItemProps } from './types'

import { usePlannerProps } from '../../../context'

export const ResizeableProjectStageItem = (
  props: ResizeableProjectStageItemProps
) => {
  const { stageWithDates, project } = props
  const lookup = useLookup()
  const projectProxy = createProjectProxy(project, lookup)

  const { stage } = stageWithDates
  const [plannerProps] = usePlannerProps()
  const stageDefinition = useStageDefinition(stage.stageDefinitionId)

  const {
    resizingStagesState: { stageDetails, isResizing: isResizingStages },
    ref,
  } = useResizeStages(stageWithDates, projectProxy)

  const {
    stageLeft,
    stageProgressWidth,
    maxStageWidth,
    adjustedEndDate,
    adjustedStartDate,
  } = useStageLocation({
    ...props,
    stageDetails,
  })

  const isProjectResizing = project.id === plannerProps.resizingId
  const isProjectStagesResizing =
    stageDetails?.projectId === project.id && isResizingStages
  const isResizing = isProjectResizing || isProjectStagesResizing

  return (
    <StageTooltip
      stage={stage}
      start={adjustedStartDate.toISODate()}
      end={adjustedEndDate.toISODate()}
    >
      <StageItemContainer
        style={{
          left: stageLeft,
          width: maxStageWidth,
        }}
      >
        {stageDefinition && (
          <div className='relative flex items-center w-full h-full'>
            <ProjectPalette color={stageDefinition.color}>
              <StageCompletionBar
                style={{
                  width: stageProgressWidth,
                }}
              />
              <StageBadgeContainer
                // Force show the badge when resizing
                showing={isResizing}
                ref={ref}
              >
                {isResizing ? (
                  <div className='w-[calc(100%-1px)] flex items-center bg-palette-highlight-subtle'>
                    <p className='mr-auto pl-0.5 text-[8px]'>
                      {adjustedStartDate.toFormat('M/d')}
                    </p>
                    <p className='ml-auto pr-0.5 text-[8px]'>
                      {adjustedEndDate.toFormat('M/d')}
                    </p>
                  </div>
                ) : (
                  <div
                    className='cursor-ew-resize w-auto h-auto absolute'
                    style={{
                      right: 0,
                    }}
                  >
                    <StageBadge
                      value={stageDefinition}
                      size='xxsmall'
                      hideTooltip
                    />
                  </div>
                )}
              </StageBadgeContainer>
            </ProjectPalette>
            <ProjectPalette color={project.color as COLOR}>
              <StageInitialCircle
                // Force hide the initial palette when resizing
                hidden={isResizing}
                style={{
                  right: 0,
                }}
              >
                <div className='rounded-full bg-palette-text-default opacity-40 w-full h-full' />
              </StageInitialCircle>
            </ProjectPalette>
          </div>
        )}
      </StageItemContainer>
    </StageTooltip>
  )
}

const StageItemContainer = classed('div', {
  base: `
    absolute flex items-center h-full
    min-w-[9px]
  `,
})

const StageCompletionBar = classed('div', {
  base: `
    bg-white dark:bg-opacity-20 bg-opacity-70 h-full absolute left-0 rounded-none
  `,
})

const StageBadgeContainer = classed('div', {
  base: `
    relative hidden group-hover/planner-project-item:flex transition-all
    h-full
    w-full
    -top-[0.5px]
    overflow-hidden
  `,
  variants: {
    showing: {
      true: `flex`,
    },
  },
  defaultVariants: {
    showing: false,
  },
})

const StageInitialCircle = classed('div', {
  base: `
    absolute w-1.5 h-1.5 flex group-hover/planner-project-item:hidden transition-all
  `,
  variants: {
    hidden: {
      true: `hidden`,
    },
  },
  defaultVariants: {
    hidden: false,
  },
})
