import type { Calendar } from '@motion/rpc/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type CalendarSchema } from '@motion/zod/client'

import { useCalendarChanges } from '~/components/Calendar/components/Sidebar/SidebarMode/sidebar-calendars/use-calendar-changes'
import { selectMyCalendars } from '~/state/calendar-list/calendar-list-slice'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useCallback } from 'react'

export const useCalendarActions = () => {
  const myCalendars = useAppSelector(selectMyCalendars)
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const { setCalendarChanges } = useCalendarChanges()
  const modalApi = useModalApi()

  const handleToggleCalendar = useCallback(
    (calendar: Calendar | CalendarSchema, isEnabled: boolean) => {
      setCalendarChanges(calendar.id, {
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isEnabled,
      })
    },
    [setCalendarChanges]
  )

  const handleUpdateCalendar = useCallback(
    (calendar: Calendar | CalendarSchema, changes: Partial<Calendar>) => {
      setCalendarChanges(calendar.id, {
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        ...changes,
      })
    },
    [setCalendarChanges]
  )

  const handleAddToMyCalendars = useCallback(
    async (calendar: Calendar | CalendarSchema) => {
      if (calendar.isInMyCalendars) return

      setCalendarChanges(calendar.id, {
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInMyCalendars: true,
        isEnabled: true,
      })
    },
    [setCalendarChanges]
  )

  const handleRemoveFromMyCalendars = useCallback(
    (calendar: Calendar | CalendarSchema) => {
      if (myCalendars.length === 1) return

      setCalendarChanges(calendar.id, {
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInMyCalendars: false,
      })
    },
    [myCalendars.length, setCalendarChanges]
  )

  const handleRemoveCalendar = useCallback(
    async (calendar: Calendar | CalendarSchema) => {
      if (calendar === null) return

      const account = emailAccounts.find(
        (account) => calendar.emailAccountId === account.id
      )

      if (!account) return

      const confirmed = await modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-calendar',
        },
        title: `Delete ${calendar.title} from Motion`,
        description: (
          <div>
            This will remove the calendar <b>&quot;{calendar.title}&quot;</b>{' '}
            permanently. To add it back you&apos;ll need to disconnect and
            reconnect your email account: {account.email}
          </div>
        ),
        destructive: true,
        confirmButtonText: 'Delete Calendar',
      })

      if (confirmed !== true) return

      setCalendarChanges(calendar.id, {
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isDeleted: true,
        isEnabled: false,
      })

      recordAnalyticsEvent('CALENDAR_DELETE')
    },
    [emailAccounts, modalApi, setCalendarChanges]
  )

  const handleRemoveFromFrequentlyMet = useCallback(
    async (calendar: Calendar | CalendarSchema) => {
      if (calendar === null) return

      setCalendarChanges(calendar.id, {
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInFrequentlyMet: false,
      })
    },
    [setCalendarChanges]
  )

  return {
    toggleCalendar: handleToggleCalendar,
    updateCalendar: handleUpdateCalendar,
    removeCalendar: handleRemoveCalendar,
    addToMyCalendars: handleAddToMyCalendars,
    removeFromMyCalendars: handleRemoveFromMyCalendars,
    removeFromFrequentlyMet: handleRemoveFromFrequentlyMet,
  } as const
}
