import { Button } from '@motion/ui/base'

import { EventFormJoinConferenceButton } from './event-form-join-conference-button'
import { EventFormRSVPField } from './event-form-rsvp-field'

import { FormSaveButton, PeekModalFooter } from '../../common'
import { useClosePeekModal } from '../../hooks'
import { useEventFormOptions } from '../event-form-options-context'
import { useEventForm } from '../hooks'
import { useEventFormSubmit } from '../hooks/use-event-form-submit'

export const EventFormFooter = () => {
  const closePeekModal = useClosePeekModal()
  const { loading, isReadOnly } = useEventFormOptions()
  const { handleSubmit } = useEventFormSubmit()
  const form = useEventForm()

  const {
    formState: { isDirty },
  } = form

  const id = form.watch('id')

  return (
    <PeekModalFooter>
      <div className='grow flex items-center'>
        <EventFormRSVPField />
      </div>
      {(!isDirty || isReadOnly) && (
        <div className='flex shrink-0 gap-1'>
          <EventFormJoinConferenceButton />
        </div>
      )}
      {!isReadOnly && (isDirty || !id) && (
        <div className='flex shrink-0 gap-1'>
          <Button
            onClick={closePeekModal}
            sentiment='neutral'
            variant='muted'
            size='small'
            shortcut='esc'
          >
            Cancel
          </Button>
          <FormSaveButton
            onSubmit={handleSubmit}
            loading={loading}
            disabled={!isDirty}
            mode={id ? 'edit' : 'create'}
          />
        </div>
      )}
    </PeekModalFooter>
  )
}
