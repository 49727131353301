import { useDebouncedCallback } from '@motion/react-core/hooks'
import { API } from '@motion/rpc'
import { type UpdateCalendarDto } from '@motion/rpc/types'

import { useQueryClient } from '@tanstack/react-query'

import { useStateObject } from '../../../../../../hooks/use-state-object'
import { optimisticallyUpdateCalendar } from '../../../../../../state/calendar-list/calendar-list-slice'
import { updateCalendars } from '../../../../../../state/calendar-list/calendar-list-thunks'
import { useAppDispatch } from '../../../../../../state/hooks'

/**
 * Custom hook for handling calendar changes. This hook will handle debouncing
 * multiple changes, as well as optimistically updating the redux state
 * (where applicable)
 * @returns
 */
export function useCalendarChanges() {
  // State object for buffering calendar changes
  const [changes, setChanges] = useStateObject<
    Record<string, UpdateCalendarDto>
  >({})
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const debouncedApplyChanges = useDebouncedCallback(
    async function doUpdateCalendars() {
      await dispatch(updateCalendars(Object.values(changes)))

      // Invalidate the scheduled entities query to refetch the updated data
      queryClient.invalidateQueries(API.scheduledEntities.queryKeys.root)
      setChanges({})
    },
    1_000
  )

  return {
    calendarChanges: changes,
    setCalendarChanges: (
      id: string,
      changes: UpdateCalendarDto,
      optimisticUpdate = true
    ) => {
      setChanges(id, changes)
      void debouncedApplyChanges()

      if (optimisticUpdate) {
        dispatch(optimisticallyUpdateCalendar(changes))
      }
    },
  }
}
