import { PlusSolid, QuestionMarkCircleSolid } from '@motion/icons'
import {
  type ActionItem,
  PopoverTrigger,
  SearchableList,
  Tooltip,
} from '@motion/ui/base'
import { isUnassignedUser } from '@motion/ui-logic'
import { type FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'
import { useModalApi } from '@motion/web-common/modals'
import { type TaskSchema, type WorkspaceSchema } from '@motion/zod/client'

import {
  AssigneeDropdownBottomSection,
  useAssigneeDropdownSortedOptions,
} from '~/areas/project-management/components'
import { UserLabel } from '~/global/components/labels'
import { useWorkspaceById } from '~/global/hooks'
import { type ReactNode } from 'react'
import { useFieldArray } from 'react-hook-form'

import { isFlowTemplateRoleAssignee } from './utils'

import { useFlowTemplateForm } from '../../../hooks'
import { createNewRole } from '../../../utils'
import { RoleLabel } from '../../role-label'

type StageTaskAssigneeDropdownProps<T = TaskSchema['assigneeUserId']> = {
  onChange: (assigneeId: T) => void
  selectedUserId: T
  workspaceId: WorkspaceSchema['id']
  dropdownRoles: FlowTemplateRoleAssignee[]
  children: ReactNode
}

export const StageTaskAssigneeDropdown = ({
  children,
  ...rest
}: StageTaskAssigneeDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <StageTaskAssigneeDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

const StageTaskAssigneeDropdownContent = ({
  close,
  workspaceId,
  onChange,
  dropdownRoles,
  selectedUserId,
}: Omit<StageTaskAssigneeDropdownProps, 'children'> & {
  close: () => void
}) => {
  const modalApi = useModalApi()
  const workspace = useWorkspaceById(workspaceId)
  const listItems = useAssigneeDropdownSortedOptions({
    workspaceId,
    additionalOptions: dropdownRoles,
    removeUnassignedOption: true,
  })

  const {
    form: { control },
  } = useFlowTemplateForm()
  const { append } = useFieldArray({
    control,
    name: 'roles',
  })

  const isUnassignedSelected = selectedUserId === null

  if (workspace == null) return null

  const addRoleItem: ActionItem[] = [
    {
      prefix: <PlusSolid />,
      suffix: (
        <Tooltip
          asChild
          content='Roles allow you to choose who the assignee will be when the project is created'
        >
          <QuestionMarkCircleSolid className='!w-4 !h-4 text-dropdown-item-icon-default' />
        </Tooltip>
      ),
      content: 'Add role',

      onAction: () => {
        close()
        void modalApi.prompt('add-flow-template-role-modal', {
          onValue({ roleColor, roleName }) {
            const newRole = createNewRole(roleName, roleColor)
            append(newRole)
            onChange(newRole.key)
          },
        })
      },
    },
  ]

  return (
    <>
      <SearchableList
        itemType='select'
        items={listItems}
        computeKey={(item) => item.id}
        computeSearchValue={(item) => item.user.name}
        computeSelected={(item) => {
          if (isFlowTemplateRoleAssignee(item)) {
            return selectedUserId === item.userId
          }

          return (
            selectedUserId === item.userId ||
            (isUnassignedSelected && isUnassignedUser(item))
          )
        }}
        renderItem={(item) => (
          <div className='max-w-xs'>
            {isFlowTemplateRoleAssignee(item) ? (
              <RoleLabel role={item} />
            ) : (
              <UserLabel value={isUnassignedUser(item) ? null : item.user} />
            )}
          </div>
        )}
        inputProps={{ placeholder: 'Choose assignee...' }}
        onSelect={(item) => {
          if (isFlowTemplateRoleAssignee(item)) {
            onChange(item.key)
          } else if (isUnassignedUser(item)) {
            onChange(null)
          } else {
            onChange(item.userId)
          }
          close()
        }}
      />

      <AssigneeDropdownBottomSection
        close={close}
        modalApi={modalApi}
        onChange={onChange}
        workspace={workspace}
        workspaceId={workspaceId}
        additionalItems={addRoleItem}
      />
    </>
  )
}
