import { BanOutline, CalendarPlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { AutoScheduleToggle, IconButton, Tooltip } from '@motion/ui/base'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import {
  isAutoScheduledToggleEnabled,
  isMeetingTask,
  isUnscheduledSchedulingTask,
} from '@motion/ui-logic/pm/task'

import { CalendarBlockersHoverCard } from '~/areas/calendar/components'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { ConnectedAssigneeBadge, StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { twMerge } from 'tailwind-merge'

import { TaskName } from './task-name'

import {
  useAssigneeDropdown,
  useAutoSchedule,
  useStatusDropdown,
} from '../../../hooks'
import { SchedulingEventDropdown } from '../../events'
import { useSidebarTaskContext } from '../context'
import { BlockedArrow, BlockerLine, BlockingArrow } from '../svg'
import { TaskLineMenu } from '../task-line-menu'

type TaskPanelTaskLineProps = {
  task: NormalTaskWithRelations
  isBlocked: boolean
  showBlockingArrow: boolean
  showBlockedArrow: boolean
  showLine: boolean
  onBlockedHover: () => void
  onBlockedLeave: () => void
}

export function TaskPanelTaskLine({
  task,
  isBlocked,
  showBlockingArrow,
  showBlockedArrow,
  showLine,
  onBlockedHover,
  onBlockedLeave,
}: TaskPanelTaskLineProps) {
  const { currentTaskId } = useSidebarTaskContext()

  const {
    onChange: onChangeStatus,
    value: statusDropdownValue,
    disabled: statusDisabled,
    iconVariant: statusIconVariant,
    taskVariant: statusTaskVariant,
    shouldExcludeResolved,
  } = useStatusDropdown(task)
  const {
    onChange: onChangeAssignee,
    removeUnassignedOption,
    selectedAssignee,
    disabled: assigneeDisabled,
  } = useAssigneeDropdown(task)
  const {
    isAutoScheduled,
    formattedScheduledDate,
    scheduledDateInfo,
    toggleChange: toggleChangeAutoSchedule,
    isError,
  } = useAutoSchedule(task)

  const status = useWorkspaceStatusById(task.statusId)

  const { assigneeUserId } = task

  const isGhost = isGhostTask(task)

  const scheduledDate =
    formattedScheduledDate ??
    (['unfit', 'unfitPastDue'].includes(scheduledDateInfo.type)
      ? scheduledDateInfo.shortText
      : undefined)

  const { disabled: isAutoScheduleDisabled } = isAutoScheduledToggleEnabled({
    status,
    isAutoScheduled,
  })

  return (
    <TaskLineContainer
      onMouseEnter={onBlockedHover}
      onMouseLeave={onBlockedLeave}
      isGhostTask={isGhost}
    >
      {showBlockingArrow && (
        <div className='absolute top-[14px] left-[6px] text-semantic-neutral-border-hover'>
          <BlockingArrow width='10' height='18' />
        </div>
      )}

      {showLine && (
        <div className='absolute top-[-2px] left-[6px] text-semantic-neutral-border-hover'>
          <BlockerLine width='2' height='34' />
        </div>
      )}

      {showBlockedArrow && (
        <div className='absolute top-[-2px] left-[6px] text-semantic-neutral-border-hover'>
          <BlockedArrow width='10' height='21' />
        </div>
      )}

      {isBlocked ? (
        <CalendarBlockersHoverCard taskId={task.id}>
          <BanOutline
            className='w-3 h-3 shrink-0'
            style={{ color: statusDropdownValue.color }}
          />
        </CalendarBlockersHoverCard>
      ) : (
        <TaskStatusDropdown
          isUnvisitedStage={isGhost}
          selectedStatusId={task.statusId}
          onChange={onChangeStatus}
          workspaceId={task.workspaceId}
          taskVariant={statusTaskVariant}
          excludeResolved={shouldExcludeResolved}
        >
          <button type='button' className='flex' disabled={statusDisabled}>
            <StatusBadge
              value={statusDropdownValue}
              size='small'
              iconVariant={statusIconVariant}
              taskVariant={statusTaskVariant}
            />
          </button>
        </TaskStatusDropdown>
      )}

      <TaskName currentTaskId={currentTaskId} task={task} />

      {isUnscheduledSchedulingTask(task) && (
        <SchedulingEventDropdown taskId={task.id}>
          <Tooltip asChild content='Schedule event'>
            <IconButton
              icon={CalendarPlusSolid}
              variant='muted'
              sentiment='primary'
              size='xsmall'
            />
          </Tooltip>
        </SchedulingEventDropdown>
      )}

      {scheduledDate && (
        <Tooltip
          renderContent={() => (
            <AutoScheduleTooltip info={scheduledDateInfo} task={task} />
          )}
          asChild
        >
          <span
            className={twMerge(
              'text-2xs text-semantic-neutral-text-subtle text-nowrap',
              isError && 'text-semantic-error-text-default'
            )}
          >
            {scheduledDate}
          </span>
        </Tooltip>
      )}

      {!isMeetingTask(task) && (
        <AutoScheduleToggle
          checked={isAutoScheduled}
          onChange={toggleChangeAutoSchedule}
          labelHidden
          size='xsmall'
          disabled={isAutoScheduleDisabled}
        />
      )}

      <AssigneeDropdown
        onChange={onChangeAssignee}
        removeUnassignedOption={removeUnassignedOption}
        selectedUserId={selectedAssignee.userId}
        workspaceId={task.workspaceId}
      >
        <button type='button' disabled={assigneeDisabled}>
          <ConnectedAssigneeBadge
            userId={assigneeUserId ?? null}
            size='small'
          />
        </button>
      </AssigneeDropdown>

      <TaskLineMenu task={task} />
    </TaskLineContainer>
  )
}

const TaskLineContainer = classed(
  'div',
  'group/task-line flex flex-row items-center grow gap-1.5',
  'truncate h-[30px] pl-4 pr-2',
  'relative',
  {
    variants: {
      isGhostTask: {
        true: 'opacity-50',
        false: '',
      },
    },
    defaultVariants: {
      isGhostTask: false,
    },
  }
)
