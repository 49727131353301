import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'

import {
  getVariableKeyByName,
  type PresetTemplateOptions,
  type PresetWithoutWorkspaceFields,
} from './utils'

import { createNewRole, DEFAULT_TEXT_VARIABLES } from '../../utils'

export const marketingPreset = (opt?: PresetTemplateOptions) => {
  const useEventType = opt?.useEventType ?? false

  const roles = [
    createNewRole('Account Manager'),
    createNewRole('Project Lead'),
    createNewRole('Designer'),
    createNewRole('Copywriter'),
    createNewRole('Media Planner'),
    createNewRole('Video Producer'),
  ]

  const eventScheduleMeetingWithinDays = useEventType
    ? DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS
    : null

  return {
    hasError: false,
    isLoading: false,
    labelIds: [],
    name: 'Marketing Campaign',
    description: '<p>Use this template if you are a marketing agency</p>',
    managerId: null,
    priorityLevel: 'MEDIUM' as const,
    color: 'gray' as const,
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Client Onboarding',
        color: 'green',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: 'Receive and review client brief',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Market and competitor analysis',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 960,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(960),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Develop initial marketing strategy',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 960,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(960),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType ? 'Strategy review' : 'Schedule strategy review',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: 'Approve marketing strategy',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Creative Development',
        color: 'yellow',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: 'Brainstorm creative concepts',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Design initial mockups and prototypes',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 960,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(960),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Copywriting for campaign elements',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Copywriter', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? 'Internal concept review and selection'
              : 'Schedule internal review and selection of concepts',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: useEventType
              ? 'Client concept review and selection'
              : 'Schedule client review and selection of concepts',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: 'Revision and finalization of creative assets',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Media Planning and Buying',
        color: 'blue',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: 'Develop media buying strategy',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Media Planner', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Select appropriate channels and platforms',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Media Planner', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Negotiate and purchase media space',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Media Planner', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Approve final media plan with client',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Set up tracking and analytics',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Production and Implementation',
        color: 'cyan',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: 'Produce digital assets',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Produce print assets',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Radio and TV Assets',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Video Producer', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Final Review',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: 'Launch Campaign',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Campaign Optimization',
        color: 'red',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: 'Create campaign optimization tasks',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Create daily monitoring dashboards',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Lead', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? 'Campaign review with customer'
              : 'Schedule review meeting with customer',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: useEventType ? DEFAULT_EVENT_DURATION : 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(
              useEventType ? DEFAULT_EVENT_DURATION : 30
            ),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Close out',
        color: 'purple',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: 'Compile campaign performance data',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Post campaign analysis',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: useEventType
              ? 'Client presentation'
              : 'Schedule client presentation',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: eventScheduleMeetingWithinDays,
          },
          {
            name: 'Archive materials and data',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: 'Follow up with client for future projects',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
    ],
    roles,
    textVariables: DEFAULT_TEXT_VARIABLES,
  } satisfies PresetWithoutWorkspaceFields
}
