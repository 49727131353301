import { usePrevious } from '@motion/react-core/hooks'
import { type ProjectSchema } from '@motion/rpc-types'

import { Attachments } from '~/areas/attachments'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { UncontrolledCollapsableContainer } from '~/areas/task-project/components/collapsable-container'
import { useEffect, useMemo, useState } from 'react'

import { AttachmentsTabGroupTitle } from './attachments-tab-group-title'

import { useFilterAttachments } from '../hooks/use-filter-attachments'

type AttachmentsTabProjectTargetItemProps = {
  project: ProjectSchema
  searchQuery?: string
}

export function AttachmentsTabProjectTargetItem({
  project,
  searchQuery,
}: AttachmentsTabProjectTargetItemProps) {
  const [expanded, setExpanded] = useState(false)

  const attachmentIds = project.uploadedFileIds || []

  const { activeFileUploads } = useFileUploadState()

  const filteredActiveFileUploads = useMemo(
    () =>
      activeFileUploads.filter(
        (activeFileUpload) =>
          activeFileUpload.targetId === project.id &&
          activeFileUpload.targetType === 'PROJECT'
      ),
    [activeFileUploads, project.id]
  )

  const prevFilteredActiveFileUploads = usePrevious(filteredActiveFileUploads)

  const { filteredAttachmentIds } = useFilterAttachments({
    attachmentIds,
    query: searchQuery,
  })

  const prevSearchQuery = usePrevious(searchQuery)

  useEffect(() => {
    // Expand the collapsable list when the search query changes
    // and there are matching attachments
    if (searchQuery !== prevSearchQuery && filteredAttachmentIds.length > 0) {
      setExpanded(true)
    }
  }, [searchQuery, prevSearchQuery, filteredAttachmentIds])

  useEffect(() => {
    if (filteredActiveFileUploads.length > 0) {
      setExpanded(true)
    }
  }, [filteredActiveFileUploads])

  const handleToggle = () => setExpanded((prev) => !prev)

  if (
    !filteredAttachmentIds.length &&
    !filteredActiveFileUploads.length &&
    !prevFilteredActiveFileUploads?.length
  )
    return null

  return (
    <UncontrolledCollapsableContainer
      title={
        <AttachmentsTabGroupTitle
          title={project.name}
          project={project}
          attachmentCount={filteredAttachmentIds.length}
        />
      }
      initialExpanded={!!attachmentIds.length}
      chevronPosition='right'
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='px-3 pb-3'>
        <Attachments
          targetId={project.id}
          targetType='PROJECT'
          attachmentIds={filteredAttachmentIds}
          variant='compact'
          emptyMessage={
            searchQuery != null &&
            searchQuery.length > 0 &&
            attachmentIds.length
              ? 'No matching attachments found.'
              : undefined
          }
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
