import { type SearchResultsSchema } from '@motion/zod/client'

import { type FC, forwardRef, type ReactNode } from 'react'

import { ConnectedEventResult } from './event-result'
import { ConnectedProjectResult } from './project-result'
import { ConnectedTaskResult } from './task-result'

import { type CalendarEventSearchSchema } from '../utils'

export interface SearchResultsProps {
  items: (SearchResultsSchema | CalendarEventSearchSchema)[]
  activeIndex: number
  onItemClick: () => void
}

export const SearchResults = forwardRef<HTMLDivElement, SearchResultsProps>(
  function SearchResults(props, ref) {
    const { items, activeIndex, onItemClick } = props

    return (
      <div ref={ref} role='list' className='flex flex-col'>
        {items.map((item, idx) => {
          return (
            <ResultSpacingWrapper key={item.id}>
              {item.entityType === 'task' ||
              item.entityType === 'recurringTask' ||
              item.entityType === 'archivedTask' ? (
                <ConnectedTaskResult
                  item={item}
                  onItemClick={onItemClick}
                  active={idx === activeIndex}
                />
              ) : item.entityType === 'project' ? (
                <ConnectedProjectResult
                  item={item}
                  onItemClick={onItemClick}
                  active={idx === activeIndex}
                />
              ) : item.entityType === 'event' ? (
                <ConnectedEventResult
                  item={item}
                  onItemClick={onItemClick}
                  active={idx === activeIndex}
                />
              ) : null}
            </ResultSpacingWrapper>
          )
        })}
      </div>
    )
  }
)

const ResultSpacingWrapper: FC<{ children: ReactNode }> = (props) => {
  return (
    <div
      className='py-1 first:pt-0 first:pb-1 last:pt-1 last:pb-0'
      {...props}
    />
  )
}
