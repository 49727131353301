import { AlertDialog } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type AmplitudeExperimentName,
  useHasTreatment,
} from '@motion/web-common/flags'

import { useNeedsUpdate } from '~/areas/update/use-needs-update'
import { useHasSeenCta } from '~/global/hooks'
import { useUtilizedSubscription } from '~/global/rpc'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useExperiment } from '~/localServices/experiments'
import { useEffect } from 'react'

export const ReleaseNotesAlert = ({
  open,
  setOpen = () => {},
}: {
  open?: boolean
  setOpen?: (open: boolean) => void
}) => {
  const releaseNotes = useExperiment('release-notes')
  const hasSeenCta = useHasSeenCta()
  const { daysBehind } = useNeedsUpdate()
  const needsUpdate = daysBehind > 0

  const { mutateAsync: updateCtaSettings } = useUpdateMyCtaSettings()

  const ctaId = releaseNotes.payload?.id ?? ''
  const associatedFeatureFlag =
    releaseNotes.payload?.associatedFeatureFlag ?? ''

  const isAssociatedFlagEnabled = useHasTreatment(
    associatedFeatureFlag as AmplitudeExperimentName
  )

  const sub = useUtilizedSubscription()

  const showCta = hasSeenCta(`WEB_RELEASE_NOTES_${ctaId}`)

  useEffect(
    function openReleaseNoteAlert() {
      if (!showCta.loaded) return
      if (!!associatedFeatureFlag && !isAssociatedFlagEnabled) return

      // If we have logged that the user has seen the release notes, don't show them again
      if (showCta.seen) return

      // If the user is still trialing, don't show them the release notes
      if (!sub || sub.status === 'trialing') return

      // If we have release notes and they are not open, open them
      if (releaseNotes.payload) {
        // Log that the user has been shown the notes
        recordAnalyticsEvent('RELEASE_NOTES_SHOWN', { ctaId })

        // Open notes
        setOpen(true)

        return
      }
    },
    [
      releaseNotes.payload,
      setOpen,
      ctaId,
      associatedFeatureFlag,
      isAssociatedFlagEnabled,
      showCta.loaded,
      showCta.seen,
      sub,
    ]
  )

  if (!open) return null

  const handleCtaClick = (openBlog?: boolean) => {
    // Save that the user has seen the release notes
    const releanseNotesCTA = 'WEB_RELEASE_NOTES_' + ctaId
    if (openBlog) {
      window.open(releaseNotes.payload?.blogUrl, '_blank')
    }

    // Log that the user has seen the release notes
    if (openBlog) {
      recordAnalyticsEvent('RELEASE_NOTES_OPENED_URL', { ctaId })
    } else {
      recordAnalyticsEvent('RELEASE_NOTES_DISMISSED', { ctaId })
    }

    void updateCtaSettings({
      ctaSettings: {
        [releanseNotesCTA]: true,
      },
    }).then((res) => {
      // We want to make sure the user has the latest version of the app
      // so they can use the new features called out in the release notes
      if (needsUpdate) {
        window.location.reload()
      }

      return res
    })

    setOpen(false)
  }

  // If user joined before the release notes experiment was created, don't show the alert
  return (
    <AlertDialog
      visible
      title={releaseNotes.payload?.title}
      description={releaseNotes.payload?.description}
      banner={releaseNotes.payload?.imageUrl}
      footer={
        needsUpdate
          ? '*updating will take no longer than 10 seconds'
          : undefined
      }
      onClose={() => {}}
      actionBarOrientation='horizontal'
      actions={[
        {
          label: needsUpdate ? 'Close & update' : 'Close',
          'data-testid': 'close-release-notes',
          sentiment: 'neutral',
          value: false,
          variant: 'outlined',
          onClick: handleCtaClick,
        },
        {
          'data-testid': 'learn-more-release-notes',
          label: needsUpdate ? 'Learn more & update' : 'Learn more',
          sentiment: 'primary',
          value: true,
          variant: 'solid',
          onClick: handleCtaClick,
        },
      ]}
    />
  )
}
