import { useDependantState } from '@motion/react-core/hooks'
import { classed } from '@motion/theme'
import { PopoverButton } from '@motion/ui/base'
import { NumberInput } from '@motion/ui/forms'
import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import {
  type SupportedCustomFieldFilterNumberOperators,
  type SupportedNumberFilterSchema,
} from '@motion/ui-logic/pm/data'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'
import { BaseDropdown } from '~/global/components'

import { ALLOWED_NUMBER_OPERATORS } from './types'

import { type DropdownFilterProps } from '../../../../types'
import { FilterActionBar } from '../../action-bar'
import { type ConditionOption } from '../../filter-item'
import { getNarrowedFilter } from '../utils'

type Props = {
  customField: CustomFieldSchemaByType<'number'>
  onSubmit: (schema: SupportedNumberFilterSchema) => void
  close: () => void
} & Pick<DropdownFilterProps, 'target'>

const isRange = (
  op: SupportedCustomFieldFilterNumberOperators
): op is Extract<SupportedCustomFieldFilterNumberOperators, 'range'> =>
  op === 'range'

const isEmpty = (
  op: SupportedCustomFieldFilterNumberOperators
): op is Extract<SupportedCustomFieldFilterNumberOperators, 'empty'> =>
  op === 'empty'

export function NumberFilterDropdown({
  onSubmit,
  customField,
  close,
  target,
}: Props) {
  const [filters, setFilterValue] = useCustomFieldFilter(target, customField)
  const filter = getNarrowedFilter(filters)

  const [selectedOperator, setSelectedOperator] = useDependantState<
    ConditionOption<(typeof ALLOWED_NUMBER_OPERATORS)[number]['id']>
  >(
    () =>
      ALLOWED_NUMBER_OPERATORS.find((op) => op.id === filter?.operator) ??
      ALLOWED_NUMBER_OPERATORS[0],
    [filter]
  )

  const [inputValue, setInputValue] = useDependantState(() => {
    return filter == null
      ? NaN
      : filter.operator === 'range'
        ? filter.value.min
        : 'value' in filter
          ? filter.value
          : NaN
  }, [filter])

  const [maxValue, setMaxValue] = useDependantState(() => {
    return filter == null
      ? NaN
      : filter.operator === 'range'
        ? filter.value.max
        : 'value' in filter
          ? filter.value
          : NaN
  }, [filter])

  const isDisabled = isRange(selectedOperator.id)
    ? isNaN(inputValue) || isNaN(maxValue) || inputValue > maxValue
    : !isEmpty(selectedOperator.id)
      ? isNaN(inputValue)
      : false

  function handleSubmit() {
    if (isDisabled) {
      return
    }

    if (isRange(selectedOperator.id)) {
      return onSubmit({
        ...filter,
        value: { min: inputValue, max: maxValue },
        operator: selectedOperator.id,
      })
    }

    if (isEmpty(selectedOperator.id)) {
      return onSubmit({
        ...filter,
        operator: selectedOperator.id,
      })
    }

    return onSubmit({
      ...filter,
      value: inputValue,
      operator: selectedOperator.id,
    })
  }

  return (
    <form className='w-[220px] flex flex-col' onSubmit={handleSubmit}>
      <div className='flex flex-col gap-2 m-2'>
        <BaseDropdown
          items={ALLOWED_NUMBER_OPERATORS}
          renderItem={(item) => <div>{item.label}</div>}
          selectedItem={selectedOperator}
          onChange={(item) => {
            setSelectedOperator(item)
          }}
        >
          <PopoverButton>{selectedOperator.label}</PopoverButton>
        </BaseDropdown>

        {selectedOperator.id !== 'empty' && (
          <InputContainer isRange={isRange(selectedOperator.id)}>
            <NumberInput
              value={inputValue}
              onChange={setInputValue}
              variant='default'
              showClearButton
              placeholder='Number'
            />
            {isRange(selectedOperator.id) && (
              <>
                <span className='flex items-center'>-</span>

                <NumberInput
                  value={maxValue}
                  onChange={setMaxValue}
                  variant='default'
                  showClearButton
                  placeholder='Number'
                />
              </>
            )}
          </InputContainer>
        )}
      </div>
      <FilterActionBar
        disabled={isDisabled}
        onApply={handleSubmit}
        onClear={() => {
          setFilterValue(null)
          close()
        }}
      />
    </form>
  )
}

const InputContainer = classed('div', {
  base: 'gap-2',
  variants: {
    isRange: {
      true: 'flex [&>label]:flex-1',
    },
  },
  defaultVariants: {
    isRange: false,
  },
})
