import { classed } from '@motion/theme'
import { byProperty, Compare } from '@motion/utils/array'
import { type TaskSchema } from '@motion/zod/client'

import { TaskRow } from './task-row'

export type TaskListProps = {
  tasks: TaskSchema[]
  selected: TaskSchema[]
  onChange(tasks: TaskSchema[]): void
}

export const TaskList = (props: TaskListProps) => {
  const sorted = [...props.tasks].sort(
    byProperty('name', Compare.caseInsensitive)
  )

  return (
    <TaskListContainer>
      {sorted.map((task) => {
        return (
          <TaskRow
            key={task.id}
            task={task}
            selected={props.selected.includes(task)}
            onChecked={(checked) => {
              if (checked) {
                props.onChange([...props.selected, task])
              } else {
                props.onChange(props.selected.filter((t) => t !== task))
              }
            }}
          />
        )
      })}
    </TaskListContainer>
  )
}

const TaskListContainer = classed('div', {
  base: `
    max-h-[300px] overflow-y-scroll
    border-t border-b 
    border-button-neutral-outlined-border-disabled
  `,
})
