import { isNoneId } from '@motion/shared/identifiers'
import { PopoverTrigger, SearchableList, type Section } from '@motion/ui/base'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { type FolderSchema } from '@motion/zod/client'

import { FolderLabel } from '~/global/components/labels'
import { useWorkspacesTree, type WorkspacesTreeItem } from '~/global/hooks'
import { type ReactNode, useMemo } from 'react'

import { useBulkFilteredData } from '../../hooks'
import { DropdownContentTopSection } from '../dropdown-content-top-section'
import { DropdownContainer } from '../styled'

export type BulkFolderDropdownProps = {
  children: ReactNode
} & Omit<BulkFolderDropdownContentProps, 'close'>

const mapTreeItemToSection = (
  node: WorkspacesTreeItem
): Section<FolderSchema> => {
  return {
    key: node.itemId,
    label: node.label,
    initialExpanded: true,
    items: node.children
      .filter((child) => child.type === 'FOLDER')
      .map(
        (child) =>
          ({
            id: child.itemId,
            name: child.label,
            color: child.color,
            targetId: child.workspaceId,
            targetType: 'WORKSPACE',
          }) as FolderSchema
      ),
  }
}

const filterItems = (search: string, section: Section<FolderSchema>) => {
  if (!search.trim()) {
    return section.items
  }

  const workspaceNameScore = computeSearchScore(section.label, search)
  return section.items
    .reduce<
      {
        score: number
        item: FolderSchema
      }[]
    >((acc, item) => {
      const score =
        workspaceNameScore > 0 && isNoneId(item.id)
          ? workspaceNameScore
          : computeSearchScore(item.name || '', search)

      if (score > 0) {
        acc.push({
          score,
          item,
        })
      }

      return acc
    }, [])
    .sort(byProperty('score', Compare.numeric.desc))
    .map((s) => s.item)
}

export const BulkFolderDropdown = ({
  children,
  ...rest
}: BulkFolderDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkFolderDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export type BulkFolderDropdownContentProps = {
  close: () => void
  onSelect: (folderId: FolderSchema['id'] | null) => void
  selectedFolderId: FolderSchema['id'] | null
}

export const BulkFolderDropdownContent = ({
  close,
  onSelect,
  selectedFolderId,
}: BulkFolderDropdownContentProps) => {
  const workspaceTree = useWorkspacesTree()

  const filteredWorkspaceTree = useBulkFilteredData<WorkspacesTreeItem>(
    workspaceTree,
    (w) => w.workspaceId
  )

  const sections = useMemo(() => {
    return filteredWorkspaceTree
      .map((bulkItem) => bulkItem?.[0])
      .filter(Boolean)
      .map(mapTreeItemToSection)
  }, [filteredWorkspaceTree])

  return (
    <DropdownContainer>
      <DropdownContentTopSection>
        Bulk operations for projects are only available for projects within the
        same workspace
      </DropdownContentTopSection>
      <SearchableList
        itemType='sectioned-select'
        sections={sections}
        expandMode='full-width'
        hideEmptySections
        computeKey={(item) => item.id ?? ''}
        computeSelected={(item) => item.id === selectedFolderId}
        filter={filterItems}
        onSelect={(item) => {
          const newFolderId = item.id ?? null
          onSelect(newFolderId)
          close()
        }}
        renderItem={(item) => (
          <div className='max-w-xs'>
            <FolderLabel value={item} />
          </div>
        )}
        inputProps={{ placeholder: 'Choose workspace or folder...' }}
      />
    </DropdownContainer>
  )
}
