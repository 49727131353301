import api from './chromeApi/chromeApiBackground'
import { firebase } from '@motion/web-common/firebase'

import { Sentry } from '@motion/web-base/sentry'
import { throttle } from '@motion/utils/core'
import { get as getStripeSubscription } from './services/userService/userSubscriptionsService'
import { logInDev } from '@motion/web-base/logging'
import { getTimeZoneShortName } from '@motion/utils/dates'
import { removeLocalStorageValue } from '@motion/web-common/storage'

export const timeStringToMin = (timeString) => {
  return parseInt(timeString.split(' ')[0])
}

export const getTzAbbr = (timezoneName) => {
  try {
    return getTimeZoneShortName(timezoneName)
  } catch (ex) {
    Sentry.captureException(
      new Error('Unable to parse timezone', { cause: ex }),
      {
        extra: {
          timezoneName,
        },
      }
    )
    return timezoneName
  }
}

export const checkStripeSubscription = async () => {
  logInDev('checkStripeSubscription')
  try {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) return false

    const hasSubscription = await getStripeSubscription()
    if (hasSubscription) {
      await api.storage.local.get('stripeSubscription')
    }
    return hasSubscription
  } catch (e) {
    logInDev(e)
    Sentry.captureException(e, {
      tags: { position: 'checkStripeSubscription' },
    })
  }
  return false
}

/**
 * Checks the user's stripe subscription (which is throttled to reduce stripe
 * load)
 * @param force Force stripe subscription to be recalculated from server
 * @returns {Promise<*>}
 */
export const doCheckStripeSubscription = async (force = false) => {
  if (force) {
    checkStripeSubscriptionThrottled.cancel()
  }
  const res = await checkStripeSubscriptionThrottled()
  return res
}

/**
 * Important: do not call directly unless you know what you are doing. Instead,
 * call doCheckStripeSubscription() in loadedBackground
 */
export const checkStripeSubscriptionThrottled = throttle(
  checkStripeSubscription,
  10000,
  { leading: true, trailing: false }
)

export const context = {
  logger: {
    log: logInDev,
    debug: logInDev,
    warn: logInDev,
    error: logInDev,
  },
  delayConfig: {
    maxAttempts: 3, // first attempt + 2 retries
    delayMs: 1000,
    maxDelayMs: 20000,
  },
}

export function cleanupOldLocalStorageKeys() {
  const oldKeys = [
    'projectExpanded',
    'showSidebar',
    'showTeamAnnualUpgradeTag',
    'showIndividualAnnualUpgradeTag',
    'motionPmState',
    'projectManagementHash',
    'motionOnboarding',
  ]

  oldKeys.forEach((k) => {
    removeLocalStorageValue(k)
  })
}
