import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'

type EventFormDescriptionEditorProps = {
  value: string
  placeholder: string
  onChange: (value: string) => void
  readOnly?: boolean
}

export const EventFormDescriptionEditor = (
  props: EventFormDescriptionEditorProps
) => {
  const { value, placeholder, onChange, readOnly } = props
  const editor = useEditor({
    editable: !readOnly,
    content: value,
    onUpdate: ({ editor }) => {
      if (readOnly) return
      const html = editor.isEmpty ? '' : editor.getHTML()
      onChange(html)
    },
    editorProps: {
      attributes: {
        class:
          'focus:outline-none text-xs px-2 !py-1 border border-transparent rounded [&[contenteditable=true]]:hover:border-field-border-hover [&[contenteditable=true]]:focus:border-field-border-focus',
      },
    },
    extensions: [
      Placeholder.configure({
        placeholder,
      }),
      StarterKit.configure({
        heading: {
          levels: [],
        },
      }),
      Underline,
      Link.configure({
        // Autolink conflicts with copy/paste links with `text/html` type
        autolink: false,
      }),
    ],
  })

  useEffect(() => {
    if (!editor) return
    if (editor.getHTML() !== value) {
      editor.commands.setContent(value, false)
    }

    if (editor.isEditable !== !readOnly) {
      editor.setEditable(!readOnly, false)
    }
  }, [value, editor, readOnly])

  return <EditorContent editor={editor} className='w-full border-field' />
}
