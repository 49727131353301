import { useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useNavigate } from 'react-router'

import { useProjectModalUrl, useTaskModalUrl } from '../navigation'

export const useGlobalKeyboardShortcuts = () => {
  const modalApi = useModalApi()
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const hasEventModalEnabled = useHasTreatment('event-modal')

  const { isElectron, isMac } = getWindowData()

  if (!__IS_PROD__) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const devPanelEnabled = useHasTreatment('development-panel')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useShortcut(
      'mod+/',
      () => {
        modalApi.open('motion-dev-modal')
      },
      {
        enabled: devPanelEnabled,
      }
    )
  }

  useShortcut(
    isMac ? 'Alt+Space' : 'Control+Space',
    () => {
      recordAnalyticsEvent('SHORTCUT_OPT_SPACE', {
        domain: urlToSite(window.location.href),
      })
      navigate(buildTaskModalUrl({ task: 'new' }))
    },
    {
      enabled: !isElectron,
    }
  )

  useShortcut(
    'e',
    () => {
      recordAnalyticsEvent('SHORTCUT_GLOBAL', {
        shortcut: 'e',
        domain: urlToSite(window.location.href),
      })

      modalApi.open('event-modal', {})
    },
    { ignoreInput: true, enabled: hasEventModalEnabled }
  )

  useShortcut(
    't',
    () => {
      recordAnalyticsEvent('SHORTCUT_GLOBAL', {
        shortcut: 't',
        domain: urlToSite(window.location.href),
      })
      navigate(buildTaskModalUrl())
    },
    { ignoreInput: true }
  )

  useShortcut(
    'p',
    () => {
      recordAnalyticsEvent('SHORTCUT_GLOBAL', {
        shortcut: 'p',
        domain: urlToSite(window.location.href),
      })
      navigate(buildProjectModalUrl())
    },
    { ignoreInput: true }
  )
}

// full=false strips everything from the first "/" onwards, remove trailing slash
const urlToSite = (url: string, full = false) => {
  if (!url) {
    return null
  }
  let result = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
  result = full ? result : result.split('/')[0]
  return result.endsWith('/') ? result.slice(0, -1) : result
}
