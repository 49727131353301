import {
  type ChangeHandler,
  type FieldValues,
  type Path,
  type PathValue,
  type RegisterOptions,
  type UseFormRegister,
} from 'react-hook-form'

export const useRegisterFieldArray = <T extends FieldValues, N extends Path<T>>(
  name: N,
  register: UseFormRegister<T>,
  options: RegisterOptions<T> = {}
) => {
  const { onChange, ...rest } = register(name, options)
  return {
    onChange: getFieldArrayChangeHandler(onChange, name),
    ...rest,
  }
}

export const getFieldArrayChangeHandler =
  <T extends FieldValues, N extends Path<T>, TType extends PathValue<T, N>>(
    onChange: ChangeHandler,
    name: Path<T>
  ): ((value: TType) => void) =>
  (s) =>
    onChange({
      target: { value: s, name },
    })
