import { useFileById } from '~/global/rpc/v2/files'
import { useSearchParams } from '~/routing'

import { AttachmentErrorModal } from './components/attachment-error-modal'
import { ProjectAttachmentModal } from './components/project-attachment-modal'
import { TaskAttachmentModal } from './components/task-attachment-modal'
import { useCloseAttachmentModal } from './hooks/use-close-attachment-modal'
import { type AttachmentSearchParams } from './utils/attachment-url-params'

export type AttachmentModalProps = {
  open: boolean
}

export function ConnectedAttachmentModal({ open }: AttachmentModalProps) {
  if (!open) return null

  return <AttachmentModal open />
}

function AttachmentModal({ open }: AttachmentModalProps) {
  const closeModal = useCloseAttachmentModal()

  const { attachment: attachmentId } = useSearchParams<AttachmentSearchParams>()

  const {
    data: attachment,
    isLoading: isLoadingAttachment,
    error,
  } = useFileById({
    id: attachmentId,
  })

  if (error)
    return <AttachmentErrorModal error={error} closeModal={closeModal} />

  if (!attachment || isLoadingAttachment) return null

  const isTaskTarget = attachment.targetType === 'TEAM_TASK'
  const isProjectTarget = attachment.targetType === 'PROJECT'

  if (isTaskTarget) {
    return (
      <TaskAttachmentModal
        visible={open}
        onClose={closeModal}
        attachment={attachment}
      />
    )
  }

  if (isProjectTarget) {
    return (
      <ProjectAttachmentModal
        visible={open}
        onClose={closeModal}
        attachment={attachment}
      />
    )
  }

  return null
}
