import { DotsHorizontalSolid } from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectActionSections } from '~/areas/project/hooks/render'

type ProjectActionMenuProps = {
  project: ProjectSchema
  moveActionDisabled: { up: boolean; down: boolean }
}

export const ProjectActionMenu = ({
  project,
  moveActionDisabled,
}: ProjectActionMenuProps) => {
  const projectActionSections = useProjectActionSections(project, {
    showColors: true,
    showCompleteProject: true,
    showMoves: true,
    disabledMoveActions: moveActionDisabled,
  })

  return (
    <ActionDropdown sections={projectActionSections}>
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
      />
    </ActionDropdown>
  )
}
