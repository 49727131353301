import { CalendarSolid } from '@motion/icons'
import { StageAdjuster } from '@motion/shared/flows'
import { Button } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'
import { mapStageAdjusterOutputToStageArgs } from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'
import { adjustStartDateBeforeEnd, parseDate } from '@motion/utils/dates'

import { DeadlineDropdown } from '~/areas/project-management/components'
import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export const ControlledDeadlineField = () => {
  const { form } = useSetupProjectForm()

  const { control, watch, setValue } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const projectId = watch('projectId')
  const projectStartDate = watch('startDate')
  const projectDueDate = watch('dueDate')
  const stageDueDates = watch('stageDueDates')

  const dateButtonString = getDateButtonText(field.value, {
    placeholder: 'None',
  })

  const onChange = useCallback(
    (value: string | null): void => {
      const date = value ? parseDate(value) : null
      const dateStr = date ? date.toISODate() : null
      field.onChange(dateStr)

      const currentStartDate = adjustStartDateBeforeEnd(
        parseDate(projectStartDate),
        date
      )

      const adjustedStages = new StageAdjuster({
        start: projectStartDate,
        due: projectDueDate,
        stages: stageDueDates.map((stage, i) => ({
          ...stage,
          active: i === 0,
        })),
      }).prepareAdjustments({ start: currentStartDate, due: date })

      const mappedStages = mapStageAdjusterOutputToStageArgs(
        adjustedStages,
        stageDueDates
      )

      setValue('stageDueDates', mappedStages)
    },
    [projectStartDate, stageDueDates, projectDueDate, setValue, field]
  )

  return (
    <FieldLabel label='Deadline' fullWidth>
      <div className='mt-1'>
        <DeadlineDropdown
          value={field.value}
          onChange={onChange}
          hideTimeOptions
          disableClearing
          contextProps={{ projectId }}
          dropdownTarget='project'
        >
          <Button
            variant='outlined'
            sentiment='neutral'
            fullWidth
            alignment='left'
            size='small'
          >
            <CalendarSolid />
            {dateButtonString}
          </Button>
        </DeadlineDropdown>
      </div>
    </FieldLabel>
  )
}
