import { PlusSolid } from '@motion/icons'
import { logEvent } from '@motion/web-base/analytics'

import { useCallback, useEffect, useRef, useState } from 'react'

import {
  AvailabilityQuestion,
  type AvailabilityQuestionType,
} from './AvailabilityQuestion/AvailabilityQuestion'

import { Events } from '../../../../../analyticsEvents'
import { TextButton } from '../../../../Common'
import { ActionDialog } from '../../../../Common/Dialog/ActionDialog/ActionDialog'

export interface AvailabilityQuestionEditorModalProps {
  visible: boolean
  questions?: AvailabilityQuestionType[]
  visibleHandler: (visible: boolean) => void
  saveHandler: (questions: AvailabilityQuestionType[]) => void
}

export const AvailabilityQuestionEditorModal = ({
  visible,
  questions,
  saveHandler,
  visibleHandler,
}: AvailabilityQuestionEditorModalProps) => {
  const [error, setError] = useState('')

  // Set default questions
  const [questionList, setQuestionList] = useState<AvailabilityQuestionType[]>([
    {
      required: false,
      text: "Anything else you'd like to share for our meeting?",
      type: 'text-multiple',
    },
  ])

  useEffect(
    function updateQuestionData() {
      if (questions && questions.length) {
        setQuestionList(questions)
      }
    },
    [questions]
  )

  const questionContainerRef = useRef<HTMLDivElement | null>(null)

  const questionSaveHandler = useCallback(() => {
    if (questionList.filter((x) => !x.text).length) {
      setError('Please make sure to fill in every question')
      return
    }
    const mcQuestions = questionList.filter((x) => x.type === 'multiple-choice')
    if (
      !mcQuestions.every(
        (question) => (question.choices ?? []).filter((x) => x).length > 1
      )
    ) {
      setError(
        'Please make sure all multiple choice questions have multiple options'
      )
      return
    }
    saveHandler(questionList)
    logEvent(Events.CALENDAR_AVAILABILITIES_QUESTIONS_SAVE)
    visibleHandler(false)
  }, [questionList, saveHandler, visibleHandler])

  return (
    <ActionDialog
      className='min-h-[60vh] min-w-[50vw]'
      size='large'
      isOpen={visible}
      title='Invitee questions'
      onClose={() => visibleHandler(false)}
      actionButtonHandler={questionSaveHandler}
      actionButtonText='Save'
      errorMessage={error}
    >
      <div className='flex h-full min-h-[50vh] flex-col justify-between gap-2'>
        <div className='flex h-full flex-col gap-2' ref={questionContainerRef}>
          {questionList.map((question, idx) => (
            <AvailabilityQuestion
              key={`question-${idx}`}
              idx={idx}
              question={question}
              questionList={questionList}
              setQuestionList={setQuestionList}
            />
          ))}
        </div>
        <TextButton
          className='self-start'
          onClick={() => {
            setQuestionList((prev) => [
              ...prev,
              { required: false, text: '', type: 'text-multiple' },
            ])
            setTimeout(
              () =>
                questionContainerRef.current?.scrollTo({
                  behavior: 'smooth',
                  top: (questionList.length + 1) * 135,
                }),
              300
            )
          }}
          icon={PlusSolid}
        >
          Add Question
        </TextButton>
      </div>
    </ActionDialog>
  )
}
