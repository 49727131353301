import { UserAddSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

export function ManageTeamButtons() {
  const modalApi = useModalApi()

  return (
    <div className='flex items-center gap-2'>
      <Button onClick={() => modalApi.open('invite-teammate')}>
        <UserAddSolid />
        Invite Member
      </Button>
    </div>
  )
}
