import { PlusSolid } from '@motion/icons'
import {
  Button,
  FieldButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { CalendarColorIcon, CalendarTitle } from '@motion/ui/calendar'
import { getAccountOptions, getColorClassForColorId } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { EventFormRowContainer } from './event-form-row-container'
import { EventFormRowIconContainer } from './event-form-row-icon-container'

import { addAccount } from '../../../../../../utils/auth'
import { resolveCalendarId } from '../../../../../../utils/calendarUtils'
import { useEventFormOptions } from '../event-form-options-context'
import { useEventHostField } from '../hooks'

export const EventFormHostField = () => {
  const { onChange, isEditable } = useEventHostField()
  const { hostCalendar, editableCalendars, emailAccountsMap } =
    useEventFormOptions()

  const accountOptions = getAccountOptions(
    editableCalendars,
    emailAccountsMap,
    resolveCalendarId
  )

  const onAddEmailAccount = () => {
    addAccount()
    void recordAnalyticsEvent('CALENDAR_ADD_ACCOUNT_CLICK')
  }

  return (
    <EventFormRowContainer className='px-2 mb-2'>
      <EventFormRowIconContainer>
        <CalendarColorIcon
          colorId={getColorClassForColorId(hostCalendar.colorId)}
          size='small'
        />
      </EventFormRowIconContainer>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <div>
            <SearchableList
              inputProps={{ placeholder: 'Choose calendar...' }}
              items={accountOptions}
              renderItem={(item) => (
                <div className='flex items-center gap-2 min-w-0 max-w-[368px]'>
                  <CalendarColorIcon
                    colorId={getColorClassForColorId(item.colorId)}
                  />
                  <CalendarTitle calendar={item} email={item.email} />
                </div>
              )}
              computeKey={(item) => item.title + item.email}
              computeSelected={(item) => item.id === hostCalendar.id}
              onSelect={(item) => {
                onChange({ ...item, organizerEmail: item.email })
                close()
              }}
            />
            <div className='border-t border-dropdown-border'>
              <Button
                variant='muted'
                sentiment='neutral'
                fullWidth
                onClick={onAddEmailAccount}
              >
                <PlusSolid />
                Add calendar
              </Button>
            </div>
          </div>
        )}
      >
        <FieldButton
          size='small'
          variant='muted'
          fullWidth
          readOnly={!isEditable}
        >
          <CalendarTitle
            calendar={hostCalendar}
            size='xsmall'
            emailAccountsMap={emailAccountsMap}
          />
        </FieldButton>
      </PopoverTrigger>
    </EventFormRowContainer>
  )
}
