import {
  DotsHorizontalSolid,
  DuplicateOutline,
  PuzzleSolid,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverTrigger,
  Tooltip,
  useShortcut,
} from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { LastSavedIndicator } from '~/areas/task-project/components'
import {
  ColorDropdown,
  type ColorDropdownProps,
} from '~/global/components/dropdowns'
import {
  useCopyProjectModalLinkToClipboard,
  useProject,
  useProjectDefinition,
} from '~/global/hooks'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { FlowTemplateDropdown, TemplateDropdown } from './template-dropdown'

import {
  ProjectActionList,
  type ProjectActionListProps,
} from '../../../components/project-action-list'
import { useCloseProjectModal, useProjectForm } from '../hooks'

export const ProjectHeader = () => {
  const { form } = useProjectForm()
  const { watch } = form

  const projectId = watch('id')
  const color = watch('color')
  const projectDefinitionId = watch('projectDefinitionId')

  const projectDefinition = useProjectDefinition(projectDefinitionId)

  return (
    <header className='grid grid-cols-[1fr_auto] auto-rows-max electron-drag'>
      <div className='flex flex-row items-center gap-0.5 text-semantic-neutral-text-disabled text-xs truncate -ml-2'>
        <ProjectNameDropdown color={color as COLOR} projectId={projectId} />
        {projectId != null && projectDefinitionId == null && (
          <>
            <div>/</div>
            <FlowTemplateDropdown projectId={projectId} />
          </>
        )}

        {projectDefinition != null && (
          <>
            <div>/</div>
            <div className='flex flex-row items-center gap-1.5 min-w-0 px-[7px]'>
              <PuzzleSolid className='w-4 h-4 shrink-0' />
              <span className='truncate'>{projectDefinition.name}</span>
            </div>
          </>
        )}

        {projectId != null && <LastSavedIndicator />}
      </div>
      {projectId == null ? (
        <TemplateDropdown />
      ) : (
        <EditProjectHeaderActions projectId={projectId} />
      )}
    </header>
  )
}

type EditProjectHeaderActionsProps = {
  projectId: ProjectSchema['id']
}
function EditProjectHeaderActions({
  projectId,
}: EditProjectHeaderActionsProps) {
  const { form } = useProjectForm()
  const copyProjectLinkToClipboard = useCopyProjectModalLinkToClipboard()

  const copyProjectLink = () => {
    copyProjectLinkToClipboard({
      workspaceId: form.getValues('workspaceId'),
      projectName: form.getValues('name'),
      projectId,
    })
  }

  useShortcut('mod+l', copyProjectLink)

  return (
    <ButtonGroup size='small' nowrap>
      <Tooltip content='Copy project link'>
        <IconButton
          icon={DuplicateOutline}
          onClick={copyProjectLink}
          variant='muted'
          sentiment='neutral'
        />
      </Tooltip>
      <PopoverTrigger
        placement='bottom-end'
        renderPopover={({ close }) => (
          <DotsMenuContent close={close} projectId={projectId} />
        )}
      >
        <IconButton
          size='small'
          variant='muted'
          sentiment='neutral'
          icon={DotsHorizontalSolid}
        />
      </PopoverTrigger>
    </ButtonGroup>
  )
}

type DotsMenuContentProps = {
  close: () => void
  projectId: ProjectSchema['id']
}
const DotsMenuContent = ({ close, projectId }: DotsMenuContentProps) => {
  const project = useProject(projectId)
  const closeModal = useCloseProjectModal()

  const actionOptions = useMemo<ProjectActionListProps['options']>(
    () =>
      ({
        hideEdit: true,
        copyShortcutLabel: 'mod+l',
        onDeleteConfirmed: () => {
          closeModal({ force: true })
        },
      }) satisfies ProjectActionListProps['options'],
    [closeModal]
  )

  if (project == null) {
    return null
  }

  return (
    <div className='scrollbar-none w-full scroll-py-1 overflow-x-hidden max-h-[400px]'>
      <ProjectActionList
        close={close}
        project={project}
        options={actionOptions}
      />
    </div>
  )
}

type ProjectNameDropdownProps = {
  color: COLOR
  projectId?: ProjectSchema['id']
}
const ProjectNameDropdown = ({
  color,
  projectId,
}: ProjectNameDropdownProps) => {
  const updateProject = useProjectUpdater()
  const { form } = useProjectForm()
  const { control, watch } = form

  const { field } = useController({
    name: 'color',
    control,
  })
  const id = watch('id')

  const onChange: ColorDropdownProps['onChange'] = async (color) => {
    if (id != null) {
      return await updateProject(id, { color })
    }

    field.onChange(color)
  }

  return (
    <ColorDropdown selectedColor={color} onChange={onChange}>
      <Button
        size='small'
        variant='muted'
        sentiment='neutral'
        disabled={projectId == null}
      >
        <ProjectColoredIcon color={color} className='w-4 h-4' />
        Project
      </Button>
    </ColorDropdown>
  )
}
