import {
  ActionList,
  PopoverTrigger,
  type PopoverTriggerProps,
  SearchableList,
  type SearchableListProps,
} from '@motion/ui/base'

import { type ReactNode } from 'react'

export type BaseDropdownProps<T> = {
  searchPlaceholder?: string
  searchable?: SearchableListProps<T>['searchable']
  computeSearchValue?: SearchableListProps<T>['computeSearchValue']
  computeDisabled?: SearchableListProps<T>['computeDisabled']
  selectedItem?: T
  renderFooter?: (close: () => void) => ReactNode
  items: T[]
  onChange(item: T): void
  renderItem: (item: T) => ReactNode
  renderEmptyContainer?: () => ReactNode
  children?: ReactNode
  onClose?: () => void
  placement?: PopoverTriggerProps['placement']
}

export function BaseDropdown<
  T extends { id: string } | { id?: string } | string | number,
>(props: BaseDropdownProps<T>) {
  const {
    searchPlaceholder,
    searchable = false,
    computeDisabled,
    computeSearchValue,
    renderFooter,
    items,
    selectedItem,
    onChange,
    renderItem,
    renderEmptyContainer,
    children,
    onClose,
    placement = 'bottom-start',
  } = props

  function getId(item?: T) {
    if (typeof item === 'string') return item
    if (typeof item === 'number') return item.toString()
    return item?.id ?? ''
  }

  return (
    <PopoverTrigger
      onClose={onClose}
      placement={placement}
      renderPopover={({ close }) => (
        <>
          {items.length === 0 ? (
            (renderEmptyContainer?.() ?? (
              <ActionList
                items={[
                  {
                    content: 'No items found',
                    onAction: close,
                    disabled: true,
                  },
                ]}
              />
            ))
          ) : (
            <SearchableList
              searchable={searchable}
              items={items}
              computeDisabled={computeDisabled}
              computeKey={(item) => getId(item)}
              computeSearchValue={computeSearchValue}
              computeSelected={(item) => getId(item) === getId(selectedItem)}
              onSelect={(item) => {
                close()
                onChange(item)
              }}
              renderItem={renderItem}
              inputProps={{ placeholder: searchPlaceholder }}
            />
          )}
          {renderFooter?.(close)}
        </>
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
