import { FilledChevronDownSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { useI18N } from '~/global/contexts'
import { useProjectModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

export const ProjectsNoDatesDropdown = ({
  projects,
}: {
  projects: ProjectSchema[]
}) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const { pluralize } = useI18N()

  if (!projects.length) return null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <div className='relative'>
          <SearchableList
            searchable
            items={projects}
            computeSearchValue={(project) => project.name}
            renderItem={(project) => (
              <div className='flex items-center gap-2 max-w-[200px]'>
                <ProjectColoredIcon
                  color={project.color as COLOR}
                  className='w-4 h-4'
                />
                <p className='truncate'>{project.name}</p>
              </div>
            )}
            computeKey={(project) => project.id}
            computeSelected={() => false}
            onSelect={(project, clearSearch) => {
              navigate(
                buildProjectModalUrl({
                  project: project.id,
                })
              )
              clearSearch()
            }}
          />
        </div>
      )}
    >
      <Button
        onClick={() => {
          recordAnalyticsEvent(
            'PROJECT_MANAGEMENT_PLANNER_NO_DATE_PROJECT_DROPDOWN'
          )
        }}
        sentiment='neutral'
        variant='muted'
        size='small'
      >
        <div className='text-2xs flex gap-1 items-center'>
          {pluralize(
            projects.length,
            '1 project with no dates',
            '{{count}} projects with no dates'
          )}
          <FilledChevronDownSolid className='w-3 h-3' />
        </div>
      </Button>
    </PopoverTrigger>
  )
}
