import { SearchSolid } from '@motion/icons'
import { Shortcut, useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useTheme } from '~/state/hooks'
import { useRef } from 'react'

import { useGlobalSidebarContext } from '../hooks'

type WorkspaceProjectSearchFieldProps = {
  placeholder?: string
}

export function WorkspaceProjectSearchField({
  placeholder = 'Workspace or Project',
}: WorkspaceProjectSearchFieldProps) {
  const { searchQuery, setSearchQuery } = useSidebarSearchContext()
  const { selectHighlighted, updateHighlightedByDirection, clearHighlighted } =
    useGlobalSidebarContext()

  const theme = useTheme()
  const ref = useRef<HTMLLabelElement>(null)
  const modalApi = useModalApi()

  useShortcut(
    'Alt+KeyS',
    (event) => {
      if (!ref.current) return

      recordAnalyticsEvent('SIDEBAR_NAV_SHORTCUT', {
        shortcut: 'search',
      })
      event.preventDefault()
      ref.current.focus()
    },
    {
      ignoreInput: true,
    }
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      e.preventDefault()

      return void updateHighlightedByDirection(key === 'ArrowUp' ? -1 : 1)
    }

    if (key === 'Enter') {
      e.preventDefault()

      return void selectHighlighted()
    }

    if (key === 'Escape') {
      e.preventDefault()
      e.currentTarget.blur()

      return void setSearchQuery('')
    }
  }

  return (
    <div>
      <TextField
        ref={ref}
        size='normal'
        rounded='full'
        showClearButton
        onKeyDown={handleKeyDown}
        prefix={<SearchSolid />}
        suffix={
          !searchQuery ? (
            <span className='mr-1 inline-flex'>
              <Shortcut
                shortcut='alt+S'
                sentiment={theme === 'dark' ? 'onDark' : 'default'}
                renderTooltip={() =>
                  'Press {{ shortcut }} to quickly search the sidebar'
                }
              />
            </span>
          ) : undefined
        }
        placeholder={placeholder}
        value={searchQuery}
        onChange={(value) => {
          setSearchQuery(value)
        }}
        onBlur={() => clearHighlighted()}
      />

      {!!searchQuery && (
        <a
          onClick={() => modalApi.open('search')}
          className='flex justify-center items-center gap-2 pt-1'
        >
          <span className='text-semantic-neutral-text-disabled underline text-xs'>
            Open task & event search
          </span>
          <Shortcut shortcut='mod+K' />
        </a>
      )}
    </div>
  )
}
