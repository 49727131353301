import { DuplicateOutline } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { Button, ButtonGroup, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type VersionedViewV2 } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useEffect } from 'react'

import { usePageData } from '../../routes'
import {
  isDefaultView,
  SelectViewButton,
  useDeleteView,
  useEffectiveView,
  useSelectedView,
} from '../../views'

export const ConnectedViewButton = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const modalApi = useModalApi()

  const deleteView = useDeleteView()
  const [selected, selectView] = useSelectedView()
  const info = useEffectiveView()
  const data = usePageData()

  const clipboard = useCopyHtmlLinkToClipboard()

  useEffect(() => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_LOAD_SAVED_VIEW', {
      isPrivate: selected.isPrivate,
      isDefault: isDefaultView(selected),
      type: selected.type,
      schemaVersion: selected.schemaVersion,
    })
  }, [selected])

  const onCopyViewLink = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_COPY_SAVED_VIEW_CLICK')

    const newUri = new URL(window.location.toString())
    newUri.searchParams.set('view', selected.id)
    clipboard(newUri.toString(), selected.name)
  }

  const computeDisabled = (view: VersionedViewV2) => {
    if (data.page !== 'project') return false
    return view.definition.itemType === 'projects'
  }

  return (
    <ButtonGroup segmented size='small'>
      <SelectViewButton
        views={ctx.views.all}
        selected={selected}
        computeDisabled={computeDisabled}
        onSelect={(view) => {
          selectView(view)
        }}
        onDelete={(view) => {
          void deleteView(view)
        }}
        onCreate={() => {
          void modalApi.prompt('save-view-v2', {
            asNew: true,
            view: info.view,
          })
        }}
        onEdit={(view) => {
          void modalApi.prompt('save-view-v2', {
            asNew: false,
            view,
          })
        }}
      />
      <Tooltip asChild renderContent={() => 'Copy saved view link'}>
        <Button
          sentiment='neutral'
          variant='mutedBg'
          iconOnly
          size='small'
          onClick={onCopyViewLink}
        >
          <DuplicateOutline width={16} height={16} />
        </Button>
      </Tooltip>
    </ButtonGroup>
  )
}
