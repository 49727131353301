import { getUpdatedStages } from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import { getInitialStageLocation } from './get-initial-stage-location'

import { getProjectStartAndEnd, pixelToDate } from '../../../../../utils'
import { type Side } from '../../../resize-handle'
import { type StageWithDates } from '../../types'

type getStageLocationProjectResize = {
  dayPx: number
  currentSide?: Side
  stageWithDates: StageWithDates
  stageLeft: number
  maxStageLeft: number
  maxStageWidth: number
  projectDeltaWidth: number
  project: ProjectSchema
}
export const getStageLocationProjectResize = ({
  dayPx,
  currentSide,
  stageWithDates,
  projectDeltaWidth,
  stageLeft,
  maxStageLeft,
  maxStageWidth,
  project,
}: getStageLocationProjectResize) => {
  const { start: projectStart, end: projectEnd } = getProjectStartAndEnd(
    project,
    dayPx
  )
  const projectLeft =
    currentSide === 'left' ? projectStart - projectDeltaWidth : projectStart
  const projectRight =
    currentSide === 'right' ? projectEnd + projectDeltaWidth : projectEnd
  const projectStartDate = pixelToDate(projectLeft, dayPx).toISODate()
  const projectDueDate = pixelToDate(projectRight, dayPx).toISODate()

  const defaults = {
    maxStageLeft,
    stageLeft,
    maxStageWidth,
  }
  if (projectStartDate > projectDueDate || projectDeltaWidth === 0) {
    return defaults
  }
  const { stagesWithDates: adjustedStagesWithDates } = getUpdatedStages({
    project,
    startDate: projectStartDate,
    dueDate: projectDueDate,
  })

  const newStageDates = adjustedStagesWithDates.find(
    (stage) =>
      stage.stage.stageDefinitionId === stageWithDates.stage.stageDefinitionId
  )

  if (!newStageDates) {
    return defaults
  }

  const {
    maxStageWidth: adjustedMaxStageWidth,
    pixelsFromProjectStart: adjustedStageLeft,
    initialProjectWidth: adjustedMaxStageLeft,
  } = getInitialStageLocation({
    dayPx,
    stageWithDates: {
      ...newStageDates,
      stage: {
        ...stageWithDates.stage,
        ...newStageDates.stage,
      },
    },
    project: {
      ...project,
      startDate: projectStartDate,
      dueDate: projectDueDate,
    },
  })

  return {
    stageLeft: adjustedStageLeft,
    maxStageWidth: Math.min(
      adjustedMaxStageWidth,
      maxStageWidth + Math.abs(projectDeltaWidth)
    ),
    maxStageLeft:
      projectDeltaWidth < 0
        ? Math.min(adjustedMaxStageLeft, maxStageLeft)
        : Math.min(adjustedMaxStageLeft, maxStageLeft + projectDeltaWidth),
  }
}
