import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { formatAppend, formatPrepend } from '../../utils'

export type TaskNameVariableUpdater = (args: {
  wrappedKey: string
  operation: 'prepend' | 'append' | 'replace'
  replacementKey?: string
  stageIndex?: number
  taskIndex?: number
}) => void

export function useTaskNameVariableUpdater(): TaskNameVariableUpdater {
  const { watch, setValue } = useFormContext<FlowTemplateFormFields>()
  const stages = watch('stages')

  const updater: TaskNameVariableUpdater = useCallback(
    ({ wrappedKey, operation, replacementKey, stageIndex, taskIndex }) => {
      for (const [currentStageIndex, stage] of stages.entries()) {
        if (stageIndex != null && currentStageIndex !== stageIndex) continue
        for (const [currentTaskIndex, task] of stage.tasks.entries()) {
          if (taskIndex != null && currentTaskIndex !== taskIndex) continue
          let name = task.name.trim()
          switch (operation) {
            case 'prepend':
              if (!name.startsWith(formatPrepend(wrappedKey))) {
                name = formatPrepend(wrappedKey) + name
              }
              break
            case 'append':
              if (!name.endsWith(formatAppend(wrappedKey))) {
                name = name + formatAppend(wrappedKey)
              }
              break
            case 'replace':
              if (replacementKey == null) {
                throw new Error(
                  'replacementKey is required for replace operation'
                )
              }
              name = name.replaceAll(wrappedKey, replacementKey)
              break
          }
          if (name !== task.name) {
            setValue(
              `stages.${currentStageIndex}.tasks.${currentTaskIndex}.name`,
              name,
              {
                shouldDirty: true,
                shouldValidate: true,
              }
            )
          }
        }
      }
    },
    [stages, setValue]
  )

  return updater
}
