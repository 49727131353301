import { XSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type NormalTaskSchema } from '@motion/zod/client'

import {
  AssigneeDropdown,
  DurationDropdown,
  StatusDropdown,
} from '~/areas/project-management/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import {
  useTaskDeadlineData,
  useTaskDurationData,
  useTaskPriorityData,
} from '~/areas/tasks/hooks'
import {
  AssigneeBadge,
  DeadlineBadge,
  DurationBadge,
  PriorityBadge,
  StatusBadge,
} from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'
import { useTaskModalUrl } from '~/global/navigation'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useAssigneeDropdown, useStatusDropdown } from '../hooks'

export type SidebarTaskLineProps = {
  task: NormalTaskSchema
  onRemove?: (taskBlocker: NormalTaskSchema) => void
  size?: 'small' | 'xsmall'
}

export function SidebarTaskLine(props: SidebarTaskLineProps) {
  const { task, onRemove, size = 'small' } = props

  const buildTaskModalUrl = useTaskModalUrl()

  const {
    onChange: onChangeStatus,
    value: statusDropdownValue,
    disabled,
    iconVariant: statusIconVariant,
    taskVariant: statusTaskVariant,
  } = useStatusDropdown(task)
  const {
    onChange: onChangePriority,
    priorities,
    disabled: priorityDisabled,
    hasValidData: hasValidPriorityData,
  } = useTaskPriorityData(task)
  const { onChange: onChangeDuration, disabled: durationDisabled } =
    useTaskDurationData(task)
  const {
    onChange: onChangeAssignee,
    removeUnassignedOption,
    selectedAssignee,
    disabled: assigneeDisabled,
  } = useAssigneeDropdown(task)
  const {
    onChange: onChangeDeadline,
    isValidDateOption,
    disabled: deadlineDisabled,
    hasValidData: hasValidDeadlineData,
  } = useTaskDeadlineData(task)

  const { name, priorityLevel } = task

  return (
    <div className='group/task-line flex flex-row items-center grow gap-2 truncate'>
      {statusDropdownValue && (
        <StatusDropdown
          selectedStatusId={task.statusId}
          onChange={onChangeStatus}
          workspaceId={task.workspaceId}
        >
          <button type='button' className='flex' disabled={disabled}>
            <StatusBadge
              value={statusDropdownValue}
              size={size}
              iconVariant={statusIconVariant}
              taskVariant={statusTaskVariant}
            />
          </button>
        </StatusDropdown>
      )}
      <Link
        to={buildTaskModalUrl({
          task: task.id,
          isMeeting: isMeetingTask(task),
        })}
        className={twMerge(
          'truncate grow text-semantic-neutral-text-default',
          size === 'small' && 'text-sm',
          size === 'xsmall' && 'text-xs'
        )}
      >
        {name}
      </Link>
      <div className='flex flex-row items-center gap-1'>
        <DurationDropdown value={task.duration} onChange={onChangeDuration}>
          <button type='button' disabled={durationDisabled}>
            <DurationBadge value={task.duration} size={size} />
          </button>
        </DurationDropdown>
        {hasValidDeadlineData && (
          <TaskDeadlineDropdown
            value={task.dueDate}
            onChange={onChangeDeadline}
            isValidDateOption={isValidDateOption}
            contextProps={{ taskId: task.id }}
          >
            <button type='button' disabled={deadlineDisabled}>
              <DeadlineBadge task={task} size={size} />
            </button>
          </TaskDeadlineDropdown>
        )}
        {hasValidPriorityData && (
          <PriorityDropdown
            items={priorities}
            selectedItem={task.priorityLevel}
            onChange={onChangePriority}
          >
            <button type='button' disabled={priorityDisabled}>
              <PriorityBadge value={priorityLevel} size={size} />
            </button>
          </PriorityDropdown>
        )}

        <AssigneeDropdown
          onChange={(userId) => onChangeAssignee(userId)}
          removeUnassignedOption={removeUnassignedOption}
          selectedUserId={selectedAssignee?.userId ?? null}
          workspaceId={task.workspaceId}
        >
          <button type='button' disabled={assigneeDisabled}>
            <AssigneeBadge value={selectedAssignee} size={size} />
          </button>
        </AssigneeDropdown>
        {onRemove && (
          <div className='invisible group-hover/task-line:visible'>
            <Button
              iconOnly
              variant='muted'
              sentiment='neutral'
              size='xsmall'
              onClick={() => onRemove(task)}
            >
              <XSolid />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
