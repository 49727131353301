import { QuestionMarkCircleSolid } from '@motion/icons'
import { Toggle, Tooltip } from '@motion/ui/base'
import { useLocalStorage } from '@motion/web-common/storage'

import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { selectTheme, setTheme } from '~/state/settingsSlice'

import { type DevToolsPages, type ItemsHookReturn } from './types'

export const useMainDevToolsItems = (
  setCurrentPage: (page: DevToolsPages) => void
): ItemsHookReturn => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(selectTheme)
  const [debugMode, setDebugMode] = useLocalStorage('motion:debug-mode', false)

  return [
    [
      {
        onSelect: () => {
          dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'))
        },
        label: (
          <Toggle checked={theme === 'light'} onChange={() => void 0}>
            Change theme (Light mode on)
          </Toggle>
        ),
        key: 'Change theme (Light mode on)',
      },
      {
        onSelect: () => {
          setCurrentPage('feature-flags')
        },
        label: 'Feature flags',
        key: 'Feature flags',
        clearSearch: true,
      },
      {
        onSelect: () => {
          setCurrentPage('alerts-trigger')
        },
        label: 'Alerts trigger',
        key: 'Alerts trigger',
        clearSearch: true,
      },
      {
        onSelect: () => {
          setCurrentPage('firebase-settings')
        },
        label: 'Firebase settings',
        key: 'Firebase settings',
        clearSearch: true,
      },
      {
        onSelect: () => {
          setCurrentPage('user-agent')
        },
        label: 'User agent',
        key: 'User agent',
        clearSearch: true,
      },
      {
        onSelect: () => {
          setDebugMode(!debugMode)
        },
        label: (
          <Toggle checked={Boolean(debugMode)} onChange={() => void 0}>
            Debug mode{' '}
            <Tooltip content='Show additional contextual information inside of the app'>
              <QuestionMarkCircleSolid className='size-4' />
            </Tooltip>
          </Toggle>
        ),
        key: 'Debug mode',
      },
    ],
  ]
}
