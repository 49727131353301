import { type COLOR } from '@motion/shared/common'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { memo } from 'react'

import { SelectableProjectDatesRow } from '../../selectable-project-dates-row'

type NoProjectDatesRowProps = {
  project: ProjectSchema
}

export const NoProjectDatesRow = memo(function NoProjectDatesRow(
  props: NoProjectDatesRowProps
) {
  const { project } = props
  const updateProject = useProjectUpdater()

  const updateProjectDates = (startDate: string, dueDate: string) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_SET_MISSING_DATES', {
      stacked: false,
    })
    updateProject(project, { startDate, dueDate })
  }

  return (
    <SelectableProjectDatesRow
      color={project.color as COLOR}
      onSelectDates={updateProjectDates}
      stacked={false}
    />
  )
})
