import { type ProjectSchema } from '@motion/zod/client'

import { NoProjectDatesRow } from './no-project-dates-row'
import { OffscreenDateIndicator } from './offscreen-date-indicator'

import { usePlannerProps, useScrollPosition } from '../../../context'
import { useScreenValues, useSidebarSize } from '../../../hooks'
import {
  LEFT_PROJECT_OFFSET,
  OFFSCREEN_DATE_PADDING,
  ZIndexMap,
} from '../../../shared-constants'
import { getProjectStartAndEnd } from '../../../utils'
import { ResizeableProjectItem } from '../../resizeable-project-item'

type SingleItemLaneProps = {
  project: ProjectSchema
}

// The lane where planner items are placed
// Support only one project
export const SingleItemLane = (props: SingleItemLaneProps) => {
  const { project } = props
  const [plannerProps] = usePlannerProps()
  const [scrollPosition] = useScrollPosition()
  const { positionOffset, screenSize } = useScreenValues()
  const sidebarSize = useSidebarSize()

  // Get start and end date of the project and position the item accordingly
  if (plannerProps.resizingId === project.id) {
    return (
      <div
        className='absolute inset-0'
        style={{
          transform: `translateX(${scrollPosition}px)`,
          zIndex: ZIndexMap.projectItem,
        }}
      >
        <ResizeableProjectItem project={project} />
      </div>
    )
  }

  const itemStartAndEnd = getProjectStartAndEnd(project, plannerProps.dayPx)

  if (!project.startDate && !project.dueDate) {
    return <NoProjectDatesRow project={project} />
  }

  // If the item is to the left of the screen, render the offscreen date indicator to the left
  if (
    itemStartAndEnd.end <
    -scrollPosition - positionOffset + LEFT_PROJECT_OFFSET
  ) {
    return (
      <OffscreenDateIndicator
        style={{ left: sidebarSize + OFFSCREEN_DATE_PADDING }}
        project={project}
        direction='left'
      />
    )
  }

  // If the item is to the right of the screen, render the offscreen date indicator to the right
  if (
    itemStartAndEnd.start >
    -scrollPosition + screenSize - positionOffset - 60
  ) {
    return (
      <OffscreenDateIndicator
        style={{ right: OFFSCREEN_DATE_PADDING }}
        project={project}
        direction='right'
      />
    )
  }

  return (
    <div
      className='absolute inset-0'
      style={{
        transform: `translateX(${scrollPosition}px)`,
        zIndex: ZIndexMap.projectItem,
      }}
    >
      <ResizeableProjectItem project={project} />
    </div>
  )
}
