import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useState } from 'react'

export interface LinkModalProps {
  onDismiss: () => void
  onChange?: (value: string) => void
  initialLink?: string
}

export const LinkModal = ({
  onDismiss,
  onChange,
  initialLink = '',
}: LinkModalProps) => {
  const [linkInput, setLinkInput] = useState(initialLink)

  return (
    <FormModal
      title='Link'
      visible
      onClose={onDismiss}
      submitAction={{
        onAction: () => onChange?.(linkInput),
        text: 'Add',
      }}
    >
      <div className='w-[350px] py-2'>
        <TextField
          placeholder='https://google.com'
          label='Link'
          labelHidden
          value={linkInput}
          autoFocus
          onChange={(value) => setLinkInput(value)}
        />
      </div>
    </FormModal>
  )
}
