import { type CalendarSchema } from '@motion/zod/client'

import { useGetCalendars } from '~/global/rpc/v2'
import { useMemo } from 'react'

export type MainCalendarIdResponse = {
  mainCalendarId: CalendarSchema['id'] | null
  isLoading: boolean
}

export function useMainCalendarId(): MainCalendarIdResponse {
  const { data: calendarsData, isInitialLoading } = useGetCalendars()

  return useMemo(() => {
    return {
      mainCalendarId: calendarsData?.mainCalendarId ?? null,
      isLoading: isInitialLoading,
    }
  }, [calendarsData?.mainCalendarId, isInitialLoading])
}
