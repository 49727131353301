import { useTaskForm } from '~/areas/tasks/modals/task-modal/hooks'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { NameTextField } from '../common'

export const TaskFormNameField = () => {
  const { form } = useTaskForm()

  const { control, watch } = form

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })

  const id = watch('id')

  return (
    <div className='py-2 px-1'>
      <NameTextField
        value={field.value}
        onChange={(value) => field.onChange(value)}
        error={error}
        autoFocus={!id}
        placeHolder='Task name'
      />
    </div>
  )
}
