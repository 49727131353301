import { type ProjectSchema } from '@motion/zod/client'

import { useProjectDeadlineData } from '~/areas/project/hooks/data'
import {
  type DateDropdownTarget,
  DeadlineDropdown,
  type DeadlineDropdownProps,
  DeadlineToggle,
  type ProjectAwareContextProps,
} from '~/areas/project-management/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'
import { type DateTime } from 'luxon'

import { CellAction, CellText } from './components'

type TaskDeadlineCellProps = {
  task: TaskWithRelations
}
export const TaskDeadlineCell = ({ task }: TaskDeadlineCellProps) => {
  const {
    onChange,
    onChangeDeadlineType,
    disabled,
    formattedValue,
    isValidDateOption,
    hasValidData,
  } = useTaskDeadlineData(task)

  if (!hasValidData) {
    return <CellText>-</CellText>
  }

  return (
    <BaseTaskDeadlineCell
      disabled={disabled}
      onChange={onChange}
      value={task.dueDate}
      formattedValue={formattedValue}
      isValidDateOption={isValidDateOption}
      renderDropdownContentFooter={() => (
        <DeadlineToggle
          onChange={onChangeDeadlineType}
          deadlineType={task.deadlineType}
        />
      )}
      contextProps={{ taskId: task.id }}
      dropdownTarget='task'
    />
  )
}

type BaseTaskDeadlineCellProps = BaseDeadlineCellProps

const BaseTaskDeadlineCell = ({
  disabled = false,
  value,
  formattedValue,
  onChange,
  isValidDateOption,
  hideTimeOptions,
  renderDropdownContentFooter,
  contextProps,
}: BaseTaskDeadlineCellProps) => {
  return (
    <TaskDeadlineDropdown
      contextProps={contextProps}
      value={value}
      onChange={onChange}
      isValidDateOption={isValidDateOption}
      hideTimeOptions={hideTimeOptions}
      renderCalendarFooter={renderDropdownContentFooter}
    >
      <CellAction disabled={disabled}>
        <span className='truncate'>{formattedValue}</span>
      </CellAction>
    </TaskDeadlineDropdown>
  )
}

type ProjectDeadlineCellProps = {
  project: ProjectSchema
}
export const ProjectDeadlineCell = ({ project }: ProjectDeadlineCellProps) => {
  const { onChange, hideTimeOptions, disableClearing, formattedValue } =
    useProjectDeadlineData(project)

  return (
    <BaseDeadlineCell
      onChange={onChange}
      value={project.dueDate}
      hideTimeOptions={hideTimeOptions}
      disableClearing={disableClearing}
      formattedValue={formattedValue}
      contextProps={{ projectId: project.id }}
      dropdownTarget='project'
    />
  )
}

export type BaseDeadlineCellProps = {
  disabled?: boolean
  value: TaskWithRelations['dueDate']
  formattedValue: string
  onChange: (dueDate: TaskWithRelations['dueDate']) => void
  isValidDateOption?: (date: DateTime) => boolean
  hideTimeOptions?: DeadlineDropdownProps['hideTimeOptions']
  disableClearing?: DeadlineDropdownProps['disableClearing']
  renderDropdownContentFooter?: DeadlineDropdownProps['renderCalendarFooter']
  contextProps: ProjectAwareContextProps
  dropdownTarget: DateDropdownTarget
}

export const BaseDeadlineCell = ({
  disabled = false,
  value,
  formattedValue,
  onChange,
  isValidDateOption,
  hideTimeOptions,
  disableClearing,
  renderDropdownContentFooter,
  contextProps,
  dropdownTarget,
}: BaseDeadlineCellProps) => {
  return (
    <DeadlineDropdown
      value={value}
      onChange={onChange}
      isValidDateOption={isValidDateOption}
      hideTimeOptions={hideTimeOptions}
      disableClearing={disableClearing}
      renderCalendarFooter={renderDropdownContentFooter}
      contextProps={contextProps}
      dropdownTarget={dropdownTarget}
    >
      <CellAction disabled={disabled}>
        <span className='truncate'>{formattedValue}</span>
      </CellAction>
    </DeadlineDropdown>
  )
}
