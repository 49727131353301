import { useDependantState } from '@motion/react-core/hooks'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useWorkspacesTree, type WorkspacesTreeItem } from '~/global/hooks'
import { createContext, type ReactNode, useCallback, useMemo } from 'react'

import { useGlobalSidebarContext } from '../hooks/use-global-sidebar-context'
import { type SidebarWorkspacesTreeItem } from '../types'

type FolderTreeviewContextApi = {
  items: SidebarWorkspacesTreeItem[]
}

export const FolderTreeviewContext = createContext<FolderTreeviewContextApi>({
  items: [],
})

type FolderTreeviewContextProviderProps = {
  children: ReactNode
}

export const FolderTreeviewContextProvider = ({
  children,
}: FolderTreeviewContextProviderProps) => {
  const items = useWorkspacesTree()
  const { folderState } = useGlobalSidebarContext()
  const { searchQuery } = useSidebarSearchContext()

  const filterItems = useCallback(
    (items: WorkspacesTreeItem[]) =>
      items
        .reduce<{ score: number; item: SidebarWorkspacesTreeItem }[]>(
          (acc, item) => {
            const filteredChildren: SidebarWorkspacesTreeItem[] = []

            if (item.isContainer) {
              filteredChildren.push(...filterItems(item.children))
            }

            const hasChildren = item.isContainer && filteredChildren.length > 0
            const score = computeSearchScore(item.label, searchQuery)

            const isManuallyExpanded =
              folderState[item.itemId]?.expanded ?? false
            const shouldExpandForSearch = searchQuery && hasChildren
            const isExpanded =
              item.isContainer && (shouldExpandForSearch || isManuallyExpanded)

            if (!searchQuery || hasChildren || score > 0) {
              acc.push({
                score,
                item: {
                  ...item,
                  children: filteredChildren,
                  expanded: isExpanded,
                },
              })
            }

            return acc
          },
          []
        )
        .sort(byProperty('score', Compare.numeric.desc))
        .map((i) => i.item),
    [folderState, searchQuery]
  )

  const [cachedState] = useDependantState<FolderTreeviewContextApi['items']>(
    () => filterItems(items),
    [filterItems, items]
  )

  const contextValue = useMemo<FolderTreeviewContextApi>(
    () => ({
      items: cachedState,
    }),
    [cachedState]
  )

  return (
    <FolderTreeviewContext.Provider value={contextValue}>
      {children}
    </FolderTreeviewContext.Provider>
  )
}
