import { useAppWorkspaceContext } from '~/global/contexts'
import {
  useMatchedRouteData,
  useMatchId,
  useSearchParams,
  useUriByRouteId,
} from '~/routing'
import { useMemo } from 'react'

import { type MatchedV3RouteData } from '../types'

/**
 * Checks if the current route is valid and returns the URI of the fallback route if it is not.
 * @returns {string | null} Returns the URI of the fallback route if the current route is invalid, otherwise returns `null`
 */
export function useIsValidRoute(): string | null {
  const getUri = useUriByRouteId()
  const matchId = useMatchId()
  const route = useMatchedRouteData<MatchedV3RouteData>()
  const [ctx] = useAppWorkspaceContext()
  const query = useSearchParams<Record<string, string>>()

  return useMemo(() => {
    const {
      data: {
        filter: { projectId, workspaceId },
      },
    } = route

    let defaultFallbackUri = getUri('my-tasks', {}, query)

    if (matchId === 'workspace-project') {
      if (projectId == null || workspaceId == null) return defaultFallbackUri

      const project = ctx.projects.byId[projectId]
      const workspace = ctx.workspaces.byId[workspaceId]

      if (project == null || workspace == null) {
        return defaultFallbackUri
      }

      // Check if the project's workspace matches the route's workspace
      return project.workspaceId === workspaceId
        ? null
        : getUri(
            'workspace-project',
            {
              workspaceId: project.workspaceId,
              projectId: project.id,
            },
            query
          )
    }

    const checks = [
      projectId != null ? ctx.projects.byId[projectId] != null : true,
      workspaceId != null ? ctx.workspaces.byId[workspaceId] != null : true,
    ]

    return checks.every(Boolean) ? null : defaultFallbackUri
  }, [ctx.projects.byId, ctx.workspaces.byId, getUri, matchId, query, route])
}
