import { ChartThemeProvider } from '@motion/web-charts/theme'

import { GridLayout } from './components/grid-layout'
import { ConnectedScheduledStatusPieChart } from './task-charts/scheduled-status-pie-chart'
import { TaskCount } from './task-charts/task-count'
import { TasksByAssigneeAndStatusChart } from './task-charts/tasks-by-assignee-status'

export const DashboardPage = () => {
  return (
    <ChartThemeProvider>
      <div className='p-4 overflow-scroll'>
        <GridLayout>
          <TaskCount />
          <TaskCount />
          <TaskCount />
          <TaskCount />
          <TaskCount />
          <TaskCount />

          <ConnectedScheduledStatusPieChart y={2} />
          <TasksByAssigneeAndStatusChart width={6} height={4} />
          <ConnectedScheduledStatusPieChart y={4} />
          <ConnectedScheduledStatusPieChart />
        </GridLayout>
      </div>
    </ChartThemeProvider>
  )
}
