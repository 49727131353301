import { createContext, useContext } from 'react'

import { type ActivityFeedType } from '../types'

export type ActivityFeedContextApi = {
  workspaceId: string
  onUploadImage: (file: File) => Promise<string | undefined>
  editingCommentId: string | null
  setEditingCommentId: (id: string | null) => void
} & ActivityFeedType

export const ActivityFeedContext = createContext<ActivityFeedContextApi | null>(
  null
)

export const useActivityFeedContext = () => {
  const context = useContext(ActivityFeedContext)
  if (context == null) {
    throw new Error(
      'useActivityFeedContext must be used within an ActivityFeedContextProvider'
    )
  }
  return context
}
