import { getChunkDurations, SHORT_TASK_DURATION } from '@motion/ui-logic'
import type { TaskDefaults } from '@motion/ui-logic/pm/task'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useWorkspaceStatusById } from '~/global/hooks'

export function useHiddenDisabledFields(selectedTaskDefaults: TaskDefaults) {
  const { uid: currentUserId } = useAuthenticatedUser()
  const status = useWorkspaceStatusById(selectedTaskDefaults.statusId)

  const hiddenFields: Set<keyof TaskDefaults> = new Set()
  const disabledFields: Set<keyof TaskDefaults> = new Set()

  const { duration, isAutoScheduled, assigneeUserId, priorityLevel } =
    selectedTaskDefaults

  const isOwnTask = currentUserId != null && currentUserId === assigneeUserId

  if (!isOwnTask || !isAutoScheduled) {
    hiddenFields.add('scheduleId')
  }

  if (duration === SHORT_TASK_DURATION) {
    hiddenFields.add('startDate')
    hiddenFields.add('scheduleId')
  }

  const chunkDurations = getChunkDurations(duration)

  if (chunkDurations.length === 0 || !isAutoScheduled) {
    hiddenFields.add('minimumDuration')
  }

  if (!status.autoScheduleEnabled) {
    disabledFields.add('isAutoScheduled')
  }

  if (priorityLevel === 'ASAP') {
    disabledFields.add('startDate')
    disabledFields.add('dueDate')
    disabledFields.add('deadlineType')
  }

  return { hiddenFields, disabledFields }
}
