import { areTimezonesOffsetsEqual } from '@motion/ui-logic'
import { useModalApi } from '@motion/web-common/modals'

import { useTimezoneSettings } from '~/global/hooks'
import { useUpdateMyTimezonesMutation } from '~/global/rpc/v2'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import api from '../../../../chromeApi/chromeApiContentScript'
import { dangerouslyTriggerUpdateFunction } from '../../../../state/calendar/calendarThunks'
import { useAppDispatch } from '../../../../state/hooks'
import { setSecondaryTimezone } from '../../../../state/timezone-slice'
import { getTzAbbr } from '../../../../utils'
import { SubParagraph } from '../../../Common'
import { DefaultSelectTrigger } from '../../../Common/Select/Select'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

export const TimezoneSettings = () => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()

  // we don't use defaults so that the drop down will be empty if no timezone is returned
  const { defaultTimezone, secondaryTimezone } = useTimezoneSettings({
    useDefaults: false,
  })
  const { mutate: updateTimezoneSettings } = useUpdateMyTimezonesMutation()

  const getNewDefaultTimezone = useCallback(async () => {
    const newDefaultTimezone = await new Promise<string | null>((resolve) => {
      modalApi.prompt('timezone-picker', {
        onTimezoneChange: resolve,
      })
    })

    if (!newDefaultTimezone) return

    const localTimezone = DateTime.local().zoneName
    const newTimezone = DateTime.local().setZone(newDefaultTimezone).zoneName

    let lastCheckedTimezone = newDefaultTimezone

    if (!areTimezonesOffsetsEqual(localTimezone, newTimezone)) {
      // Ensure we don't prompt user again when changing default timezone when they're in a different timezone
      lastCheckedTimezone = localTimezone
    }

    void updateTimezoneSettings({
      defaultTimezone: newDefaultTimezone,
      latestClientTimezoneDetected: lastCheckedTimezone,
    })

    void dispatch(dangerouslyTriggerUpdateFunction())
  }, [dispatch, modalApi, updateTimezoneSettings])

  const getNewSecondaryTimezone = useCallback(async () => {
    const newSecondaryTimezone = await new Promise<string | null>((resolve) => {
      modalApi.prompt('timezone-picker', {
        clearableTimezoneValue: secondaryTimezone,
        onTimezoneChange: (tz: string | null) => resolve(tz ?? ''), // If they clear, set as empty string
      })
    })

    if (newSecondaryTimezone == null) return

    dispatch(setSecondaryTimezone(newSecondaryTimezone))
    void api.storage.local.set({ calendarTimezone: newSecondaryTimezone })

    // Dual write timezone settings to postgres
    void updateTimezoneSettings({
      secondaryTimezone: newSecondaryTimezone,
    })
  }, [dispatch, updateTimezoneSettings, modalApi, secondaryTimezone])

  return (
    <SettingPage title='Timezone' className='gap-6'>
      <div className='flex flex-col gap-2'>
        <SubParagraph className='dark:text-dark-400 font-semibold'>
          Default timezone
        </SubParagraph>
        <DefaultSelectTrigger
          selectedLabel={defaultTimezone ? getTzAbbr(defaultTimezone) : ''}
          onClick={getNewDefaultTimezone}
        />
        <SubParagraph className='dark:text-dark-400'>
          Default timezone is applied toward both booking links and task
          manager.
        </SubParagraph>
      </div>
      <div className='flex flex-col gap-2'>
        <SubParagraph className='dark:text-dark-400 font-semibold'>
          Secondary timezone column on calendar
        </SubParagraph>
        <DefaultSelectTrigger
          selectedLabel={
            secondaryTimezone ? getTzAbbr(secondaryTimezone) : 'None'
          }
          onClick={getNewSecondaryTimezone}
        />
      </div>
    </SettingPage>
  )
}
