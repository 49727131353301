import { type OOOEventSchema, type ProjectSchema } from '@motion/zod/client'

import { type ComponentRef, forwardRef } from 'react'

import { LaneName } from './lane-name'
import { MultiProjectLane } from './multi-project-lane'

import { useOOOEventsForRowFn } from '../../../hooks'
import { ZIndexMap } from '../../../shared-constants'
import { type PlannerRowProps, type StyledPlannerRow } from '../../common'
import { StyledPlannerRowContainer } from '../../container'
import { OOOEventsRow } from '../ooo-event-column/ooo-events-row'

export const ConnectedStackedPlannerRow = forwardRef<
  ComponentRef<typeof StyledPlannerRow>,
  PlannerRowProps<any> & {
    stackedProjects: ProjectSchema[][]
    gapHeight: number
  }
>(function ConnectedStackedPlannerRow(
  { gapHeight, stackedProjects, ...props },
  ref
) {
  const getOOOEventsForRow = useOOOEventsForRowFn()

  if (!props.visible || !props.row)
    return <StyledPlannerRowContainer ref={ref} role='row' {...props} />

  const eventsToShow = getOOOEventsForRow(props.row)

  return (
    <StackedPlannerRow
      gapHeight={gapHeight}
      eventsToShow={eventsToShow}
      ref={ref}
      stackedProjects={stackedProjects}
      {...props}
    />
  )
})

export const StackedPlannerRow = forwardRef<
  ComponentRef<typeof StyledPlannerRow>,
  PlannerRowProps<any> & {
    stackedProjects: ProjectSchema[][]
    eventsToShow: OOOEventSchema[]
    gapHeight: number
  }
>(function StackedPlannerRow(
  {
    row,
    toggleExpandAllRows,
    visible,
    stackedProjects,
    gapHeight,
    eventsToShow,
    ...props
  },
  ref
) {
  return (
    <StyledPlannerRowContainer
      className='w-full left-0 flex flex-col'
      ref={ref}
      role='row'
      {...props}
    >
      <div className='relative h-full w-full max-h-full flex flex-row items-center'>
        <LaneName row={row} />
        <div className='w-full h-full'>
          {eventsToShow.length ? <OOOEventsRow events={eventsToShow} /> : null}
          {stackedProjects.length > 0 ? (
            stackedProjects.map((projects, index) => (
              <div key={index} className='flex flex-row items-center'>
                <MultiProjectLane
                  hasOOOEvents={eventsToShow.length > 0 && index === 0}
                  row={row!}
                  projects={projects}
                />
              </div>
            ))
          ) : (
            // To allow adding even with no projects
            <div className='flex flex-row items-center'>
              <MultiProjectLane row={row!} projects={[]} />
            </div>
          )}
        </div>
      </div>
      <div
        className='w-full relative flex bg-semantic-neutral-surface-overlay-bg-subtle'
        style={{
          height: gapHeight,
          zIndex: ZIndexMap.gapRow,
        }}
      />
    </StyledPlannerRowContainer>
  )
})
