import { type User } from '@motion/rpc-types'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { type User as ReduxUser } from '~/state/userSlice'
import { useEffect, useState } from 'react'

import { useGetProfilePicture } from '../rpc/user'

export const useProfilePictureUrl = (user?: User | ReduxUser) => {
  const { uid, photoURL } = useAuthenticatedUser()

  const userId = user?.id ?? uid
  const enabled = Boolean(userId)
  const { data: uploadedPicture } = useGetProfilePicture(
    {
      id: userId,
    },
    {
      enabled,
    }
  )
  const [timestamp, setTimestamp] = useState(Date.now())

  useEffect(() => {
    setTimestamp(Date.now())
  }, [uploadedPicture])

  if (user && user.id !== uid) {
    return user.picture
  }
  const fallbackPicture = user?.picture ?? photoURL

  return uploadedPicture && enabled
    ? `${__BACKEND_HOST__}/users/${uid}/profile/picture?ts=${timestamp}`
    : fallbackPicture
}
