import { type ProjectSchema } from '@motion/rpc-types'
import { ProjectColoredIcon } from '@motion/ui/project'

import { StatusBadge } from '~/global/components/badges'
import { type NormalTaskWithRelations } from '~/global/proxies'

type AttachmentsTabGroupTitleProps = {
  title: string
  task?: NormalTaskWithRelations
  project?: ProjectSchema
  attachmentCount: number
}

export function AttachmentsTabGroupTitle({
  title,
  attachmentCount,
  task,
  project,
}: AttachmentsTabGroupTitleProps) {
  return (
    <div className='flex items-center gap-2 [&_[data-icon]]:!size-4'>
      {project && (
        <ProjectColoredIcon color={project.color} className='!size-4' />
      )}
      {task && <StatusBadge value={task.status} size='normal' />}
      <span className='max-w-[205px] inline-flex'>
        <span className='text-ellipsis whitespace-nowrap overflow-hidden'>
          {title}
        </span>
        &nbsp;({attachmentCount})
      </span>
    </div>
  )
}
