import { useDependantState } from '@motion/react-core/hooks'
import { PopoverButton } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type RelativeDateFilterSchema } from '@motion/zod/client'

import { BaseDropdown } from '~/global/components'
import { Duration } from 'luxon'
import { useMemo } from 'react'

import { type DatePickerContentProps } from './types'

import { DisplayValue } from '../format'
import { parseRelativeDate, type RelativeDateInfo } from '../utils'

const DIRECTION_OPTIONS: {
  id: RelativeDateInfo['direction']
  label: string
}[] = [
  { id: 'next', label: 'Next' },
  { id: 'last', label: 'Last' },
]

const UNIT_OPTIONS: { id: RelativeDateInfo['unit']; label: string }[] = [
  { id: 'days', label: 'Days' },
  { id: 'weeks', label: 'Weeks' },
  { id: 'months', label: 'Months' },
]

export const RelativeDatePickerContent = (
  props: DatePickerContentProps<RelativeDateFilterSchema>
) => {
  const local = useMemo((): RelativeDateInfo => {
    return parseRelativeDate(props.value)
  }, [props.value])

  const setLocal = (fn: (prev: RelativeDateInfo) => RelativeDateInfo) => {
    const current = fn(local)
    props.onSelect(toFilter(current))
  }

  const [value, setValue] = useDependantState<string>(
    (prev) => {
      if (prev == null) return local.value
      if (safeParse(prev) === safeParse(local.value)) return prev
      return String(Math.abs(safeParse(local.value)))
    },
    [local.value]
  )

  const display = toFilter({ ...local, value })

  const selectedDirection =
    DIRECTION_OPTIONS.find((x) => x.id === local.direction) ??
    DIRECTION_OPTIONS[0]
  const selectedUnit =
    UNIT_OPTIONS.find((x) => x.id === local.unit) ?? UNIT_OPTIONS[0]

  return (
    <div className='flex flex-col gap-2'>
      <div className='grid [grid-template-columns:1fr_48px_1fr] gap-2'>
        <BaseDropdown
          items={DIRECTION_OPTIONS}
          renderItem={(item) => item.label}
          onChange={(item) =>
            setLocal((prev) => ({
              ...prev,
              direction: item.id,
              value: String(safeParse(prev.value) * -1),
            }))
          }
          selectedItem={selectedDirection}
        >
          <PopoverButton>{selectedDirection.label}</PopoverButton>
        </BaseDropdown>
        <TextField
          value={value}
          onChange={(value) => setValue(value)}
          onBlur={() => {
            setLocal((prev) => ({ ...prev, value }))
            setValue(String(safeParse(value)))
          }}
        />
        <BaseDropdown
          items={UNIT_OPTIONS}
          renderItem={(item) => item.label}
          onChange={(item) => setLocal((prev) => ({ ...prev, unit: item.id }))}
          selectedItem={selectedUnit}
        >
          <PopoverButton>{selectedUnit.label}</PopoverButton>
        </BaseDropdown>
      </div>
      <DisplayValue value={display} />
    </div>
  )
}

function toFilter(value: RelativeDateInfo): RelativeDateFilterSchema {
  const multiplier = value.direction === 'last' ? -1 : 1
  const duration = Duration.fromObject({
    [value.unit]: multiplier * Math.abs(safeParse(value.value)),
  })

  return {
    operator: 'relative',
    duration: duration.toISO(),
  }
}
function safeParse(text: string) {
  const parsed = parseInt(text)
  return isNaN(parsed) ? 0 : parsed
}
