import {
  ArrowDownSolid,
  ArrowUpSolid,
  DotsHorizontalSolid,
  DuplicateOutline,
  TrashOutline,
} from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { type COLOR } from '@motion/shared/common'
import {
  ActionList,
  IconButton,
  PopoverTrigger,
  showToast,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  useDeleteFolder,
  useMoveFolderItemInDirection,
} from '~/areas/folders/hooks'
import { ColorDropdownContent } from '~/global/components/dropdowns'
import { useUpdateFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useClipboard } from '~/localServices/clipboard'
import { useEffect, useState } from 'react'

import type { FlattenedSidebarWorkspacesTreeItem } from '../types'

type WorkspaceActionMenuProps = {
  folder: FlattenedSidebarWorkspacesTreeItem['item']
  moveActionAllowed: {
    up: boolean
    down: boolean
  }
}

export const FolderActionsMenu = (props: WorkspaceActionMenuProps) => {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <PopoverContents close={close} {...props} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'FOLDER',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}

type PopoverContentsProps = WorkspaceActionMenuProps & {
  close: () => void
}

const PopoverContents = ({
  folder,
  moveActionAllowed,
  close,
}: PopoverContentsProps) => {
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const deleteFolder = useDeleteFolder()
  const moveFolderItemInDirection = useMoveFolderItemInDirection()
  const clipboard = useClipboard()

  const [isNameDirty, setNameDirty] = useState(false)
  const [folderName, setFolderName] = useDependantState(
    () => folder.label,
    [folder.label],
    { freezeDependencyUpdates: isNameDirty }
  )

  useEffect(() => {
    if (!isNameDirty) {
      setFolderName(folder.label)
    }
  }, [folder.label, isNameDirty, setFolderName])

  const handleUpdateFolderName = async () => {
    if (!isNameDirty) return

    if (!folderName.trim() || folderName.trim() === folder.label.trim()) {
      return void setNameDirty(false)
    }

    const folderId = folder.itemId
    const name = folderName.trim()

    recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
      updated: 'NAME',
      location: 'SIDEBAR',
    })

    try {
      await updateFolder({ folderId, name })

      showToast('success', 'Folder name updated')
      setNameDirty(false)
    } catch (e) {
      showErrorToast(e, 'Could not update folder name')

      Sentry.captureException(
        new Error('Failed to update folder name', { cause: e }),
        {
          extra: {
            folderId,
            name,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleUpdateFolderColor = async (color: COLOR) => {
    const folderId = folder.itemId

    recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
      updated: 'COLOR',
      location: 'SIDEBAR',
    })

    try {
      await updateFolder({ folderId, color })

      showToast('success', 'Folder color updated')
    } catch (e) {
      showErrorToast(e, 'Could not update folder color')

      Sentry.captureException(
        new Error('Failed to update folder color', { cause: e }),
        {
          extra: {
            folderId,
            color,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleDeleteFolder = async () => {
    recordAnalyticsEvent('FOLDERS_DELETED_FOLDER', {
      location: 'SIDEBAR',
    })

    try {
      await deleteFolder(folder.itemId)
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to delete folder', { cause: e }),
        {
          extra: {
            folderId: folder.itemId,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleCopyLink = () => {
    recordAnalyticsEvent('FOLDERS_COPIED_LINK', {
      location: 'SIDEBAR',
    })

    clipboard.write({
      text: new URL(folder.url, window.location.origin).toString(),
    })
  }

  return (
    <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden'>
      <div className='p-2 border-b border-dropdown-border space-y-2'>
        <TextField
          size='small'
          value={folderName}
          onChange={(val) => {
            setNameDirty(true)
            setFolderName(val)
          }}
          placeholder={folder.label}
          onBlur={handleUpdateFolderName}
          onKeyDown={({ code }) => {
            if (code === 'Enter') {
              return handleUpdateFolderName()
            }
          }}
        />

        <div className='-mx-1 w-[192px]'>
          <ColorDropdownContent
            selectedColor={folder.color as COLOR}
            onChange={handleUpdateFolderColor}
          />
        </div>
      </div>

      <ActionList
        onActionAnyItem={close}
        sections={[
          {
            items: [
              {
                prefix: <DuplicateOutline />,
                content: 'Copy link',
                onAction: handleCopyLink,
              },
              // {
              //   prefix: <ArrowRightOutline />,
              //   content: 'Move to',
              //   onAction: () => {
              //     //
              //   },
              // },
            ],
          },
          {
            items: [
              {
                prefix: <ArrowUpSolid />,
                content: 'Move up',
                disabled: !moveActionAllowed.up,
                onAction: () => {
                  recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                    itemType: 'FOLDER',
                    direction: 'UP',
                  })

                  moveFolderItemInDirection(folder.id, -1)
                },
              },
              {
                prefix: <ArrowDownSolid />,
                content: 'Move down',
                disabled: !moveActionAllowed.down,
                onAction: () => {
                  recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                    itemType: 'FOLDER',
                    direction: 'DOWN',
                  })

                  moveFolderItemInDirection(folder.id, 1)
                },
              },
            ],
          },
          {
            items: [
              {
                prefix: <TrashOutline />,
                content: 'Delete',
                destructive: true,
                onAction: handleDeleteFolder,
              },
            ],
          },
        ]}
      />
    </div>
  )
}
