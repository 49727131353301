import { createSelector } from '@reduxjs/toolkit'

import { calendarSelectors } from './calendar-list-slice'

import { resolveCalendarId } from '../../utils/calendarUtils'
import { emailAccountsSelectors } from '../email-accounts/email-accounts-slice'
import { type RootState } from '../store'

export const selectSelectedTeammateContacts = (state: RootState) =>
  state.calendarList.selectedTeammateContacts

/**
 * Helper selector for resolving the correct team search values depending on
 * the feature flag.
 * @param state
 */
export const selectNewTeamSearchValues = createSelector(
  [selectSelectedTeammateContacts],
  (newValue) => {
    return newValue.map((c) => c.email)
  }
)

export const selectCalendarByEmail =
  (email?: string, calendarId?: string) => (state: RootState) => {
    const emailAccount = emailAccountsSelectors
      .selectAll(state)
      .find((e) => e.email === email)

    if (!emailAccount) {
      return null
    }

    const calendars = calendarSelectors
      .selectAll(state)
      .filter(
        (c) =>
          c.emailAccountId === emailAccount.id &&
          resolveCalendarId(c) === calendarId
      )

    return calendars
  }
