import { useClosure } from '@motion/react-core/hooks'
import {
  type SocketEventData,
  type SocketEventType,
} from '@motion/shared/websockets'
import { throttle } from '@motion/utils/core'
import { makeLog } from '@motion/web-base/logging'
import { websocketsEventSubscriber } from '@motion/web-common/websockets'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useEffect } from 'react'

import { setEmailAccountAddedModal } from './email-accounts-slice'
import { fetchAllEmailAccounts } from './email-accounts-thunks'

import { fetchAllCalendars } from '../calendar-list/calendar-list-thunks'
import { store } from '../proxy'

const EventsToSubscribe: SocketEventType[] = [
  'emailAccount.created',
  'emailAccount.updated',
  'emailAccount.deleted',
  'emailAccount.needsRefresh',
  'emailAccount.errorOccurred',
] as const

const log = makeLog('[EmailAccountWebsocketSubscribers]')
let isRegistered = false

type StateData = {
  isOnboarding: boolean
  isInLinkCalendarsFlow: boolean
}

const handleMessage = async <T extends SocketEventType>(
  data: SocketEventData<T>,
  event: T,
  getState: () => StateData
) => {
  log('Received websockets message:', event, data)

  if (event === 'emailAccount.created') {
    await Promise.all([
      store.dispatch(fetchAllEmailAccounts()),
      store.dispatch(fetchAllCalendars()),
    ])

    // When onboarding, the step for choosing calendars/main calendar is done
    // after adding all email accounts, so we don't need to show the modal
    const { isOnboarding, isInLinkCalendarsFlow } = getState()

    if (isOnboarding || isInLinkCalendarsFlow) {
      return
    }
    const emailAccount = data as SocketEventData<'emailAccount.created'>

    store.dispatch(
      setEmailAccountAddedModal({
        visible: true,
        emailAccountId: emailAccount.emailAccountId,
      })
    )
    return
  }

  refreshEmailAccounts()
}

const refreshEmailAccounts = throttle(() => {
  void store.dispatch(fetchAllEmailAccounts())
  void store.dispatch(fetchAllCalendars())
}, 2_500)

export const useEmailAccountWebSocketSubscription = () => {
  const { selectIsOnboarding, selectIsInLinkCalendarsFlow } = useOnboardingApi()
  const getState = useClosure(() => ({
    isOnboarding: selectIsOnboarding(),
    isInLinkCalendarsFlow: selectIsInLinkCalendarsFlow(),
  }))

  useEffect(() => {
    if (isRegistered) return
    log('register')
    isRegistered = true
    const subscriptions = EventsToSubscribe.map((event) =>
      websocketsEventSubscriber.on(event, (data, event) => {
        void handleMessage(data, event, getState)
      })
    )
    return () => {
      log('unregister')
      subscriptions.forEach((sub) => sub())
      isRegistered = false
    }
  }, [getState])
}
