import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useDeleteWorkspace as useDeleteWorkspaceCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteWorkspace = () => {
  const {
    mutateAsync: deleteWorkspace,
    isLoading,
    isError,
  } = useDeleteWorkspaceCall()
  const modalApi = useModalApi()

  return {
    deleteWorkspace: useCallback(
      async (workspace: WorkspaceSchema): Promise<boolean> => {
        try {
          const confirmResponse = await modalApi.prompt('confirm-delete-item', {
            analytics: {
              name: 'delete-workspace',
            },
            titleValue: 'this workspace',
            description:
              'This workspace and its tasks, projects, task default settings, and all other information will be deleted permanently.',

            deleteMessage: workspace.name,
            deleteButtonLabelValue: 'workspace',
          })

          if (confirmResponse === true) {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_WORKSPACE')

            await deleteWorkspace({
              workspaceId: workspace.id,
            })

            showToast('success', 'Workspace removed')

            return true
          }
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'delete-workspace',
            },
          })

          showErrorToast(e)
        }

        return false
      },
      [deleteWorkspace, modalApi]
    ),
    isLoading,
    isError,
  }
}
