import { Button, useShortcut } from '@motion/ui/base'

type FormSaveButtonProps = {
  loading: boolean
  disabled: boolean
  onSubmit: () => void
  mode: 'create' | 'edit'
}

export const FormSaveButton = (props: FormSaveButtonProps) => {
  const { loading, disabled, onSubmit, mode } = props
  useShortcut('mod+enter', () => onSubmit(), { enabled: !disabled })
  useShortcut('mod+s', () => onSubmit(), { enabled: !disabled })

  return (
    <Button
      type='submit'
      size='small'
      loading={loading}
      disabled={disabled}
      shortcut={mode === 'edit' ? 'mod+s' : 'mod+enter'}
    >
      {mode === 'edit' ? 'Save' : 'Create'}
    </Button>
  )
}
