import { type CreateCalendarEventSchema as CreateCalendarEventRequest } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type EventFormFields } from '../hooks/use-event-form-initial-data'

export function formFieldsToCalendarV3(
  formFields: EventFormFields
): CreateCalendarEventRequest & { calendarId: string } {
  const today = DateTime.now()

  return {
    calendarId: formFields.calendarId,
    title: formFields.title,
    start: formFields.start,
    end: formFields.end,
    isAllDay: formFields.isAllDay,
    status: formFields.status,
    organizer: {
      email: formFields.organizerEmail,
    },
    attendees: formFields.attendees as CreateCalendarEventRequest['attendees'],
    visibility: formFields.visibility,
    colorId: formFields.colorId ?? undefined,
    // Computer's timezone
    timezone: today.zoneName,
    conferenceType: formFields.conferenceType,
    description: formFields.description,
    location: formFields.location,
    conferenceLink: formFields.conferenceLink || undefined,
    sendUpdates: true,
    recurrence: formFields.recurrence,
    travelTimeBefore: formFields.travelTimeBefore,
    travelTimeAfter: formFields.travelTimeAfter,
  }
}
