import { sortByDueDate, sortByPriority } from '@motion/ui-logic/pm/data'
import { byProperty, byValue, Compare } from '@motion/utils/array'

import { type ProjectWithRelations } from '~/global/proxies'

import { DEFAULT_STRING_SORT } from './sorting'
import { type FieldDefinition } from './types'

export type ProjectFieldId = keyof typeof PROJECT_FIELDS
export const PROJECT_FIELDS = {
  name: {
    id: 'name',
    name: 'Name',
    size: { min: 300, default: 650 },
    sortFn: byProperty('name', DEFAULT_STRING_SORT),
    disableReordering: true,
    disableHiding: true,
    enablePinning: true,
  },
  deadline: {
    id: 'deadline',
    name: 'Deadline',
    size: { min: 100, default: 130 },
    sortFn: sortByDueDate,
  },
  stage: {
    id: 'stage',
    name: 'Stage',
    size: { min: 100, default: 115 },
    sortFn: byValue(
      (project) => project.activeStageDefinition?.name,
      DEFAULT_STRING_SORT
    ),
  },
  status: {
    id: 'status',
    name: 'Status',
    size: { min: 100, default: 115 },
    sortFn: byValue((project) => project.status.name, DEFAULT_STRING_SORT),
  },
  priority: {
    id: 'priority',
    name: 'Priority',
    size: { min: 100, default: 110 },
    sortFn: sortByPriority,
  },
  assignee: {
    id: 'assignee',
    name: 'Assignee',
    size: { min: 100, default: 175 },
    sortFn: byValue((project) => project.manager?.name, DEFAULT_STRING_SORT),
  },
  labels: {
    id: 'labels',
    name: 'Labels',
    size: { min: 100, default: 110 },
  },
  workspace: {
    id: 'workspace',
    name: 'Workspace',
    size: { min: 100, default: 150 },
    sortFn: byValue((project) => project.workspace?.name, DEFAULT_STRING_SORT),
  },
  startDate: {
    id: 'startDate',
    name: 'Start date',
    size: { min: 100, default: 180 },
    sortFn: byProperty('startDate', Compare.string),
  },
  projectDefinition: {
    id: 'projectDefinition',
    name: 'Template',
    size: { min: 100, default: 180 },
    sortFn: byValue(
      (project) => project.projectDefinition?.name,
      DEFAULT_STRING_SORT
    ),
  },
  createdAt: {
    id: 'createdAt',
    name: 'Created at',
    size: { min: 100, default: 180 },
    sortFn: byProperty('createdTime', Compare.string),
  },
  updatedAt: {
    id: 'updatedAt',
    name: 'Updated at',
    size: { min: 100, default: 180 },
    sortFn: byProperty('updatedTime', Compare.string),
  },
  createdBy: {
    id: 'createdBy',
    name: 'Created by',
    size: { min: 100, default: 125 },
    sortFn: byValue((project) => project.createdByUser.name, Compare.string),
  },
  folder: {
    id: 'folder',
    name: 'Folder',
    size: { min: 100, default: 200 },
    sortFn: byValue((project) => project.folder?.name, DEFAULT_STRING_SORT),
  },
} satisfies Record<string, FieldDefinition<ProjectWithRelations>>

export const PROJECT_FIELD_LIST = Object.values(
  PROJECT_FIELDS
) as FieldDefinition<ProjectWithRelations>[]
