import { ChevronDownSolid } from '@motion/icons'
import { Portal, Tooltip } from '@motion/ui/base'
import { isDefined } from '@motion/utils/guards'

// eslint-disable-next-line no-restricted-imports
import { Combobox, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'

import {
  chevronClasses,
  dropdownContainerClasses,
  dropdownContainerColorClasses,
  generalDropdownItemClasses,
  inputContainerDisabledClasses,
  inputDisabledClasses,
  inputFocusClasses,
  inputTextClasses,
  selectedDropdownItemClasses,
} from '../../../../components/Common/GeneralComponentStyles'
import {
  createDurationChoices,
  toTaskDurationWithReminders,
} from '../../../../utils/optionSpaceUtils'

const durationOptions = [5, 15, 30, 60, 60 * 2, 60 * 4]

interface DurationOptionsDropdownProps {
  durationChoicesBeforeTyping?: (number | null | undefined)[]
  value?: number | null
  disabled?: boolean
  placeholder?: string
  onChange?: (value: number) => void
  onKeyPress?: (e: any) => void
  className?: string
  inputContainerClassName?: string
  showArrow?: boolean
  /**
   * Controls the alignment of the dropdown
   */
  dropdownAlign?: 'left' | 'right'
  /**
   * Control the width of the dropdown. When set to 'normal' (the default value),
   * the dropdown will be the same width as the control. Setting to 'large' will
   * fix the dropdown width.
   */
  dropdownWidth?: 'normal' | 'large'
  showTooltip?: boolean
  tooltipTitle?: string
  allowZero?: boolean
  nullIsValue?: boolean
  emptyText?: string

  Icon?: React.ComponentType<React.ComponentProps<'svg'>>
}

/* eslint react-refresh/only-export-components: ["warn"] */
export function getTextForDuration(duration: number) {
  return toTaskDurationWithReminders(duration, {
    allowZero: false,
    emptyText: '',
  })
}

export function DurationOptionsDropdown(props: DurationOptionsDropdownProps) {
  const {
    durationChoicesBeforeTyping = durationOptions,
    value,
    disabled,
    onChange = () => {},
    showArrow = false,
    className = '',
    inputContainerClassName = '',
    dropdownAlign,
    dropdownWidth,
    showTooltip = false,
    tooltipTitle = '',
    placeholder,
    allowZero = true,
    emptyText,
    Icon,
  } = props
  const [durationPickerChoices, setDurationPickerChoices] = useState<
    (number | null | undefined)[]
  >(durationChoicesBeforeTyping)

  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] =
    React.useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'bottom',
  })

  const renderDisplayValue = (
    value: DurationOptionsDropdownProps['value'],
    emptyText = placeholder
  ): string => {
    return toTaskDurationWithReminders(value, { allowZero, emptyText })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) {
      setIsOpen(true)
    }

    setDurationPickerChoices(
      createDurationChoices(
        e.target.value,
        durationChoicesBeforeTyping.filter(isDefined),
        {
          allowZero,
          emptyText,
        }
      )
    )
  }

  const placeholderDisplayValue = renderDisplayValue(value, '')

  const [isOpen, setIsOpen] = React.useState(false)
  const [shouldOpenCombobox, setShouldOpenCombobox] = React.useState(true)
  const [shouldClearOnFocus, setShouldClearOnFocus] = React.useState(true)

  function onInputFocus() {
    if (shouldOpenCombobox) {
      setIsOpen(true)
    }
  }

  function onInputBlur() {
    setIsOpen(false)
    setShouldOpenCombobox(true)
    setShouldClearOnFocus(true)
  }

  const useNullValue = isOpen && shouldClearOnFocus

  return (
    <div className={twMerge('w-full max-w-sm', className)}>
      <Combobox
        value={useNullValue ? null : value}
        onChange={(value) => {
          // @ts-ignore
          onChange(value)
          setIsOpen(false)
          setShouldClearOnFocus(false)
          setShouldOpenCombobox(false)
          setDurationPickerChoices(
            createDurationChoices(
              '',
              durationChoicesBeforeTyping.filter(isDefined),
              {
                allowZero,
                emptyText,
              }
            )
          )
        }}
        disabled={disabled}
        multiple={false}
      >
        <div className='relative'>
          <div
            ref={setTargetElement}
            className={twMerge(
              'relative flex w-full cursor-pointer items-center',
              disabled && inputContainerDisabledClasses,
              inputContainerClassName
            )}
          >
            <Tooltip content={showTooltip && tooltipTitle}>
              <IconContainer Icon={Icon} />

              <Combobox.Input
                onClick={() => {
                  setIsOpen(true)
                  setShouldOpenCombobox(true)
                }}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                className={twMerge(
                  inputFocusClasses,
                  'dark:placeholder:text-dark-400 placeholder:text-light-1100 border-light-400 border-1  relative flex w-full cursor-pointer rounded bg-inherit bg-white py-1 px-2 pl-2',
                  showArrow ? 'truncate pr-10' : 'pr-2',
                  inputTextClasses,
                  disabled && inputDisabledClasses
                )}
                placeholder={placeholderDisplayValue}
                displayValue={(value) => {
                  return value ? placeholderDisplayValue : ''
                }}
                onChange={handleInputChange}
                disabled={disabled}
              />
            </Tooltip>

            {showArrow && (
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownSolid
                  className={chevronClasses}
                  aria-hidden='true'
                />
              </span>
            )}
          </div>
          <Portal container={targetElement}>
            <div
              className='z-20'
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Transition
                as={Fragment}
                show={isOpen}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                afterLeave={() => {
                  setPopperElement(null)
                }}
                beforeEnter={() => setPopperElement(popperElRef.current)}
              >
                <Combobox.Options
                  static
                  className={twMerge(
                    dropdownContainerClasses,
                    dropdownContainerColorClasses,
                    dropdownAlign === 'right' ? 'right-0' : '',
                    dropdownWidth === 'large' ? 'w-64' : 'w-full'
                  )}
                  style={{
                    left: -(targetElement?.clientWidth || 0) / 2,
                    width: targetElement?.clientWidth,
                  }}
                >
                  {durationPickerChoices
                    .filter(Boolean)
                    .map((choice, optionIdx) => {
                      const option = toTaskDurationWithReminders(choice, {
                        allowZero,
                        emptyText,
                      })

                      return (
                        <Combobox.Option
                          key={optionIdx}
                          className={({ active }) =>
                            twMerge(
                              generalDropdownItemClasses,
                              active && selectedDropdownItemClasses
                            )
                          }
                          value={choice}
                        >
                          {() => (
                            <div className='flex items-center gap-2'>
                              <span className='flex items-center gap-2 truncate'>
                                {option}
                              </span>
                            </div>
                          )}
                        </Combobox.Option>
                      )
                    })}
                </Combobox.Options>
              </Transition>
            </div>
          </Portal>
        </div>
      </Combobox>
    </div>
  )
}

type IconContainerProps = {
  Icon?: React.ComponentType<React.ComponentProps<'svg'>>
}
const IconContainer = (props: IconContainerProps) => {
  if (props.Icon == null) return null

  return (
    <div style={{ paddingRight: '8px' }}>
      <props.Icon className='text-light-1100 dark:text-dark-400 h-4 w-4 min-w-[16px]' />
    </div>
  )
}
