import { Checkbox } from '@motion/ui/forms'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { usePastDueTasksBySchedule } from '~/areas/calendar/hooks'
import { useI18N } from '~/global/contexts'
import { useEffect, useMemo } from 'react'

import { ActionsContainer } from './common'
import {
  usePastDueState,
  useSelectedTasksById,
  useTasksLoadingState,
} from './hooks'

export const SelectTasksRow = () => {
  const { hardDeadlineMissed, softDeadlineMissed, recurringTasksMissed } =
    usePastDueTasksBySchedule({ shouldSeparateRecurring: true })
  const [{ selectedTasks }, setPastDueState] = usePastDueState()

  const [isLoading] = useTasksLoadingState(selectedTasks)

  const { pluralize } = useI18N()
  const selectedTasksById = useSelectedTasksById()

  // Combine all past deadline tasks (these also have relations)
  const allPastDeadlineTasks = useMemo(() => {
    return [
      ...hardDeadlineMissed,
      ...softDeadlineMissed,
      ...Object.values(recurringTasksMissed).flat(),
    ]
  }, [hardDeadlineMissed, recurringTasksMissed, softDeadlineMissed])

  const areAllTasksSelected = useMemo(() => {
    return allPastDeadlineTasks.every((task) => selectedTasksById[task.id])
  }, [allPastDeadlineTasks, selectedTasksById])

  // Check if any of the selected tasks have been removed when the past due tasks change
  // If so, unselect them and remove them from the selected tasks
  useEffect(
    function unselectRemovedPastDeadlineTasks() {
      const allPastDeadlineTaskIds = new Set(
        allPastDeadlineTasks.map((t) => t.id)
      )
      const removedTasks = selectedTasks.filter(
        (task) => !allPastDeadlineTaskIds.has(task.id)
      )

      if (removedTasks.length === 0) return

      setPastDueState((state) => {
        const taskLoadingState = { ...state.taskLoadingState }
        const selectedTasks = [...state.selectedTasks]

        removedTasks.forEach((task) => {
          if (taskLoadingState[task.id]) {
            delete state.taskLoadingState[task.id]
          }

          const index = selectedTasks.findIndex((t) => t.id === task.id)
          if (index !== -1) {
            selectedTasks.splice(index, 1)
          }
        })

        return {
          selectedTasks,
          taskLoadingState,
        }
      })
    },
    [allPastDeadlineTasks, selectedTasks, setPastDueState]
  )

  const selectAllTasks = () => {
    if (areAllTasksSelected) {
      recordAnalyticsEvent('PAST_DUE_MODAL_SELECT_ALL', { checked: false })
      return setPastDueState({
        selectedTasks: [],
        taskLoadingState: {},
      })
    }

    recordAnalyticsEvent('PAST_DUE_MODAL_SELECT_ALL', { checked: true })
    setPastDueState({
      selectedTasks: allPastDeadlineTasks,
      taskLoadingState: {},
    })
  }

  if (allPastDeadlineTasks.length <= 1) return null

  return (
    <div className='w-full px-5 py-2 bg-semantic-neutral-surface-raised-bg-subtlest flex items-center justify-between h-9'>
      <div className='flex items-center gap-2'>
        <Checkbox
          disabled={isLoading}
          checked={areAllTasksSelected}
          onChange={selectAllTasks}
        />
        {selectedTasks.length > 0 ? (
          <span className='text-sm text-semantic-neutral-text-subtle'>
            {templateStr('{{count}} {{item}} selected', {
              count: selectedTasks.length,
              item: pluralize(selectedTasks.length, 'task', 'tasks'),
            })}
          </span>
        ) : (
          <span className='text-sm text-semantic-neutral-text-subtle'>
            Select all
          </span>
        )}
      </div>
      {selectedTasks.length > 0 && (
        <ActionsContainer type='SELECT_TASKS' tasks={selectedTasks} />
      )}
    </div>
  )
}
