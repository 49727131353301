import { isCompletedStatus } from '@motion/shared/common'
import { type NormalTaskSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

export const useIsTaskBlockedFn = (tasks: NormalTaskSchema[]) => {
  const { getStatusById } = useWorkspaceFns()

  return useCallback(
    (currentTask: NormalTaskSchema) => {
      return currentTask.blockedByTaskIds?.reduce((isTaskBlocked, id) => {
        const blockingTask = tasks.find((task) => task.id === id)

        if (
          blockingTask != null &&
          !isCompletedStatus(getStatusById(blockingTask.statusId))
        ) {
          isTaskBlocked = true
        }

        return isTaskBlocked
      }, false)
    },
    [tasks, getStatusById]
  )
}
