import { PlusSolid } from '@motion/icons'
import { ActionList, Button, SearchableList } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { useModalApi } from '@motion/web-common/modals'

import { VariableLabel } from '~/global/components/labels'
import { useFieldArray } from 'react-hook-form'

import { type FlowVariableFormDropdownProps } from './flow-variable-form-dropdown'

import { useFlowTemplateForm } from '../../../../hooks'
import { createNewTextVariable } from '../../../../utils'

export type FlowVariableFormSearchableListProps<
  T extends FlowTemplateFormFields['textVariables'][number],
> = Omit<FlowVariableFormDropdownProps<T>, 'children'> & {
  close?: () => void
}

export function FlowVariableFormSearchableList<
  T extends FlowTemplateFormFields['textVariables'][number],
>({
  items,
  close,
  onChange,
  selectedItem,
}: FlowVariableFormSearchableListProps<T>) {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const { append } = useFieldArray({
    control,
    name: 'textVariables',
  })

  const modalApi = useModalApi()

  const addNewHandler = async () => {
    void modalApi.prompt('add-flow-template-text-variable-modal', {
      onValue({ name }) {
        const newTextVariable = createNewTextVariable(name)
        append(newTextVariable)
        onChange(newTextVariable as T)
      },
    })
  }

  return (
    <>
      {items.length === 0 ? (
        <ActionList
          items={[
            {
              content: 'No items found',
              onAction: close,
              disabled: true,
            },
          ]}
        />
      ) : (
        <SearchableList
          searchable
          itemType='select'
          items={items}
          onSelect={(item) => {
            close?.()
            onChange(item)
          }}
          renderItem={(item) => (
            <VariableLabel
              value={{
                name: item.name,
                color: 'blue',
              }}
            />
          )}
          computeKey={(item) => item.key}
          computeSearchValue={(item) => item.name}
          computeSelected={(item) => item.id === selectedItem?.id}
          inputProps={{ placeholder: 'Choose text variable...' }}
        />
      )}

      <div className='border-t border-dropdown-border p-1.5'>
        <Button
          variant='muted'
          sentiment='neutral'
          size='small'
          onClick={addNewHandler}
        >
          <PlusSolid />
          New text variable
        </Button>
      </div>
    </>
  )
}
