import { type FlowProject, isEnabledStage } from '@motion/ui-logic/pm/project'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { type SkipStagesResponse } from '../../modals/skip-stages-modal'

type SkipStageConfirmModalFn = (
  project: FlowProject,
  newStageDefinitionId: StageDefinitionSchema['id']
) => Promise<SkipStagesResponse | false>

export function useSkipStagesConfirmModalFn(): SkipStageConfirmModalFn {
  const modalApi = useModalApi()

  return useCallback(
    async (project, newStageDefinitionId) => {
      let promptResponse: SkipStagesResponse = 'NOOP'

      const enabledStages = project.stages.filter(isEnabledStage)

      const currentStageIndex = enabledStages.findIndex(
        (s) => s.stageDefinitionId === project.activeStageDefinitionId
      )
      const newStageIndex = enabledStages.findIndex(
        (s) => s.stageDefinitionId === newStageDefinitionId
      )

      if (currentStageIndex < newStageIndex) {
        const hasUnvisitedOrIncompleteStages = enabledStages
          .slice(currentStageIndex + 1, newStageIndex + 1) // +1 to include the new stage
          .some(
            (s) =>
              !s.visited && s.canceledTime == null && s.completedTime == null
          )

        if (hasUnvisitedOrIncompleteStages) {
          const response = await modalApi.prompt('skip-stages-modal', {
            enabledStages,
            currentStageDefinitionId: project.activeStageDefinitionId,
            newStageDefinitionId,
          })
          if (response === ModalDismissed) return false
          promptResponse = response
        }
      }

      return promptResponse
    },
    [modalApi]
  )
}
