import { CalendarPlusSolid, TaskSolid } from '@motion/icons'
import { ActionList } from '@motion/ui/base'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useTaskModalUrl } from '~/global/navigation'
import { type ReduxCalendarEvent } from '~/state/calendarEvents/calendarEventsTypes'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { useClosePeekModal } from './hooks'
import { useSelectedCalendarEvent } from './hooks/use-selected-calendar-event'
import { PeekModalCommonContextProvider } from './peek-modal-common-context'
import { PeekModalPopover } from './peek-modal-popover'
import { TaskOrEventPeekForm } from './task-or-event-form'

import { useCalendarState } from '../../hooks'

export const CalendarPeekModal = () => {
  const hasEventModalEnabled = useHasTreatment('event-modal')

  if (hasEventModalEnabled) {
    return <PeekTaskEventPopover />
  }

  return <LegacyPeekModal />
}

function PeekTaskEventPopover() {
  const [calendarState] = useCalendarState()
  const { isDraggingEvent = false } = calendarState
  const { targetId, event } = useSelectedCalendarEvent()

  const modalApi = useModalApi()
  const buildTaskModalUrl = useTaskModalUrl()
  const closePeekModal = useClosePeekModal()
  const navigate = useNavigate()

  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const refActionTriggered = useRef(false)

  useEffect(() => {
    if (!targetId || isDraggingEvent) return

    const element: HTMLElement | null =
      document.querySelector(`[data-event-id="${targetId}"]`) ?? null
    setAnchor(element)

    return () => {
      setAnchor(null)
    }
  }, [targetId, event, isDraggingEvent])

  useEffect(() => {
    if (!targetId) return

    // Resetting action
    refActionTriggered.current = false

    return () => {
      // When it closes and no action has triggered
      if (!refActionTriggered.current) {
        recordAnalyticsEvent('CALENDAR_DRAG_CREATE', { option: 'none' })
      }
    }
  }, [targetId])

  if (!event || !anchor || isDraggingEvent) return null

  return (
    <PeekModalPopover
      anchor={anchor}
      onClose={() => {
        closePeekModal()
      }}
    >
      <ActionList
        onActionAnyItem={async () => {
          refActionTriggered.current = true
          closePeekModal()
          // Sleeping a tiny bit to let the peek modal close before navigating to the actions
          await sleep(10)
        }}
        items={[
          {
            prefix: <CalendarPlusSolid />,
            content: 'Create event',
            onAction: () => {
              recordAnalyticsEvent('CALENDAR_DRAG_CREATE', { option: 'event' })
              modalApi.open('event-modal', {
                start: event.start,
                end: event.end,
                isAllDay: event.isAllDay,
                attendees: event.attendees,
              })
            },
          },
          event.isAllDay && {
            prefix: <TaskSolid />,
            content: 'Create reminder task',
            onAction: () => {
              recordAnalyticsEvent('CALENDAR_DRAG_CREATE', {
                option: 'reminder_task',
              })
              navigate(
                buildTaskModalUrl({
                  forStartDate: event.start,
                }),
                {
                  state: {
                    reminderTask: true,
                  },
                }
              )
            },
          },
          !event.isAllDay && {
            prefix: <TaskSolid />,
            content: 'Create task (fixed time)',
            onAction: () => {
              recordAnalyticsEvent('CALENDAR_DRAG_CREATE', {
                option: 'fixed_task',
              })
              navigate(
                buildTaskModalUrl({
                  forStartDate: event.start,
                  forDueDate: event.end,
                }),
                {
                  state: {
                    fixedTask: true,
                  },
                }
              )
            },
          },
        ]}
      />
    </PeekModalPopover>
  )
}

/**
 * @deprecated Remove when `event-modal` is shipped
 */
function LegacyPeekModal() {
  const [calendarState] = useCalendarState()
  const { isDraggingEvent = false } = calendarState
  const { targetId, event, recurringEventParent } = useSelectedCalendarEvent()

  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!targetId) return

    const element: HTMLElement | null =
      document.querySelector(`[data-event-id="${targetId}"]`) ?? null
    setAnchor(element)

    return () => {
      setAnchor(null)
    }
  }, [targetId, event, isDraggingEvent])

  if (!event || !anchor) return null

  // We only hide it instead of unmounting it because we want to keep the form state
  let visible = !isDraggingEvent
  const bounds = anchor.getBoundingClientRect()
  if (
    bounds.x === 0 &&
    bounds.y === 0 &&
    bounds.width === 0 &&
    bounds.height === 0
  ) {
    visible = false
  }

  return (
    <PeekModalCommonContextProvider id={targetId}>
      <TaskOrEventPeekForm
        event={event as ReduxCalendarEvent}
        recurringEventParent={recurringEventParent as ReduxCalendarEvent}
        visible={visible}
        anchor={anchor}
      />
    </PeekModalCommonContextProvider>
  )
}
