import { type COLOR, COLORS } from '@motion/shared/common'
import {
  type RenderItem,
  ScrollArea,
  type SortableItem,
  SortableTreeviewItem,
  SortableTreeviewVirtualizedDnd,
  type SortableTreeviewVirtualizedDndProps,
  TreeviewItem,
} from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'

import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { ExpandableSection, SearchField } from './components'

import { SidebarExpandablePanelHeader } from '../components/sidebar-expandable-panel'
import { HIGHLIGHTABLE_DATA_ATTRIBUTE } from '../constants'
import { useGlobalSidebarContext, useGlobalSidebarState } from '../hooks'

type TestView = {
  color: COLOR
  id: string
  children: TestView[]
  label: string
  url: string
}

export const TeamViewsSection = () => {
  const { openedPanel, setOpenedPanel } = useGlobalSidebarContext()

  const [views, _setViews] = useState(
    () =>
      Array(20)
        .fill({})
        .map((_, i) => ({
          id: String(i),
          label: `Team View #${i + 1}`,
          children: [],
          url: '#',
          color:
            COLORS[Math.floor(Math.random() * COLORS.length) % COLORS.length],
        })) satisfies TestView[]
  )

  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()

  const renderItem: SortableTreeviewVirtualizedDndProps<TestView>['renderItem'] =
    useCallback((item, activeId, onClick, hovering) => {
      const selected = false

      return (
        <SortableTreeviewItem
          item={item}
          activeId={activeId}
          expanded={false}
          onClick={onClick}
          hovering={hovering}
          selected={selected}
        >
          <Link
            to={item.url}
            className='h-full min-w-0'
            draggable='false'
            {...{ [HIGHLIGHTABLE_DATA_ATTRIBUTE]: item.id }}
          >
            <TreeviewItem
              label={item.label}
              selected={selected}
              highlighted={false}
              icon={ProjectColoredIcon}
            />
          </Link>
        </SortableTreeviewItem>
      )
    }, [])

  return (
    <ExpandableSection
      title="Team's Pinned Views"
      isExpanded={sidebarState.sections.teamPinnedViews}
      shouldRenderPanel={openedPanel === 'teamPinnedViews'}
      onToggleExpand={(state) =>
        void toggleSidebarSection('teamPinnedViews', state)
      }
      onToggleRenderPanel={(shouldOpen) =>
        void setOpenedPanel(shouldOpen ? 'teamPinnedViews' : null)
      }
      renderPanelContent={
        views.length > 3 &&
        (() => (
          <div className='w-full flex flex-col'>
            <div className='p-4 space-y-4'>
              <SidebarExpandablePanelHeader title="Team's Pinned Views" />

              <div>
                <SearchField />
              </div>
            </div>

            <ScrollArea className='w-full'>
              <SortableTreeviewVirtualizedDnd
                items={views}
                onDrop={() => false}
                onClickExpand={() => {}}
                itemHeight={34}
                renderItem={renderItem as RenderItem<SortableItem>}
                disableDragToAnotherGroup
              />
            </ScrollArea>
          </div>
        ))
      }
    >
      <SortableTreeviewVirtualizedDnd
        items={views.slice(0, 3)}
        onDrop={() => false}
        onClickExpand={() => {}}
        itemHeight={34}
        renderItem={renderItem as RenderItem<SortableItem>}
        disableDragToAnotherGroup
      />
    </ExpandableSection>
  )
}
