import { type NormalTaskSchema } from '@motion/zod/client'

import { useCachedItem } from '~/global/cache'
import { useTaskByIdV2 } from '~/global/rpc/v2'

type Options = {
  taskId: string | undefined
}

type ParsedData = {
  blockedBy: NormalTaskSchema[]
  blocking: NormalTaskSchema[]
}
type Value = ParsedData & { isLoading: boolean }

export const useBlockerTasks = (opts: Options): Value => {
  const { taskId } = opts

  const { data: task, isLoading } = useTaskByIdV2(
    {
      id: taskId as string,
    },
    {
      enabled: !!taskId,
    }
  )

  const blockedByTasks = useCachedItem(
    'tasks',
    task?.type === 'NORMAL' ? task.blockedByTaskIds : []
  ) as NormalTaskSchema[]
  const blockingTasks = useCachedItem(
    'tasks',
    task?.type === 'NORMAL' ? task.blockingTaskIds : []
  ) as NormalTaskSchema[]

  return { isLoading, blockedBy: blockedByTasks, blocking: blockingTasks }
}
