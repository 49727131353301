import { type TaskDefinitionSchema } from '@motion/zod/client'

import { useFlowTemplateForm } from '../../../../hooks'
import { stripVariableKeysFromTask } from '../../../../utils'

export function useNonEmptyTasksAbove(
  stageTasksPath: `stages.${number}.tasks`,
  task: TaskDefinitionSchema
) {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const variables = watch('textVariables')
  const variableKeys = variables.map((v) => v.key)

  const stageTasks = watch(stageTasksPath)
  const taskIndex = stageTasks.findIndex((t) => t.id === task.id)
  const nonEmptyTasksAbove = stageTasks
    .slice(0, taskIndex)
    .map((t) => stripVariableKeysFromTask(t, variableKeys))
    .filter((t) => t.name !== '')

  return nonEmptyTasksAbove
}
