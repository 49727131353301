import {
  type CalendarEventSchemaV2,
  type UpdateCalendarEventSchema,
} from '@motion/zod/client'

import { useUpdateCalendarEvent as useUpdateCalendarEventCall } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useUpdateEventPrompts } from './use-update-event-prompts'

export const useUpdateEvent = () => {
  const { mutateAsync: updateCalendarEvent } = useUpdateCalendarEventCall()
  const promptUpdateCalendarEvent = useUpdateEventPrompts()

  return useCallback(
    async (
      id: string,
      updates: UpdateCalendarEventSchema
    ): Promise<CalendarEventSchemaV2 | undefined> => {
      const promptResponse = await promptUpdateCalendarEvent(id, updates)
      if (!promptResponse) return

      const { sendUpdates, recurrenceUpdateType } = promptResponse
      try {
        const response = await updateCalendarEvent({
          id,
          ...updates,
          sendUpdates,
          recurrenceUpdateType,
        })

        return response.calendarEvent
      } catch (err) {
        showErrorToast('Could not update event')
        return
      }
    },
    [promptUpdateCalendarEvent, updateCalendarEvent]
  )
}
