import {
  isFlowProject,
  isNextActiveProjectStage,
} from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useIsFlowsM4Enabled } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import { useSetProjectToStage } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useAdvanceProjectStage } from './use-advance-project-stage'
import { useOldSkipStagesConfirmModalFn } from './use-old-skip-stages-confirm-modal-fn'
import { useSkipStagesConfirmModalFn } from './use-skip-stages-confirm-modal-fn'

export const useMoveProjectToStage = () => {
  const { mutateAsync: setProjectToStage } = useSetProjectToStage()
  const { getStageDefinition } = useWorkspaceFns()
  const isFlowsM4Enabled = useIsFlowsM4Enabled()
  const skipStagesConfirmModalFn = useSkipStagesConfirmModalFn()
  const oldSkipStagesConfirmModalFn = useOldSkipStagesConfirmModalFn()

  const advanceProjectStage = useAdvanceProjectStage()

  return useCallback(
    async (
      project: ProjectSchema,
      stageDefinitionId: StageDefinitionSchema['id'],
      onConfirmed?: () => void
    ) => {
      try {
        if (
          isFlowsM4Enabled &&
          isNextActiveProjectStage(project, stageDefinitionId)
        ) {
          return advanceProjectStage(project, stageDefinitionId, onConfirmed)
        }

        if (!isFlowProject(project)) {
          throw new Error(`Moving stages can only be done in project workflows`)
        }

        const stage = getStageDefinition(stageDefinitionId)
        if (stage == null) {
          throw new Error('Stage definition not found')
        }

        if (isFlowsM4Enabled) {
          const strategy = await skipStagesConfirmModalFn(
            project,
            stageDefinitionId
          )

          if (strategy === false) return false

          await setProjectToStage({
            projectId: project.id,
            stageDefinitionId: stageDefinitionId,
            strategy,
          })
        } else {
          const promptResponse = await oldSkipStagesConfirmModalFn(
            project,
            stageDefinitionId
          )

          if (promptResponse === false) return false

          await setProjectToStage({
            projectId: project.id,
            stageDefinitionId: stageDefinitionId,
            visitSkippedStages: promptResponse === 'create-all',
          })
        }

        onConfirmed?.()

        return true
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'move-project-to-stage' },
        })

        showErrorToast(
          e,
          'There was an error moving the project to another stage'
        )
      }

      return false
    },
    [
      advanceProjectStage,
      getStageDefinition,
      isFlowsM4Enabled,
      oldSkipStagesConfirmModalFn,
      setProjectToStage,
      skipStagesConfirmModalFn,
    ]
  )
}
