import { TrashSolid } from '@motion/icons'
import { IconButton } from '@motion/ui/base'
import { shouldShowPastDueDeleteButton } from '@motion/ui-logic'
import { type TaskSchema } from '@motion/zod/client'

export type DeleteTaskButtonProps = {
  task: TaskSchema
  disabled?: boolean
  force?: boolean
  onClick(): void
}

export const DeleteTaskButton = (props: DeleteTaskButtonProps) => {
  if (!props.force && !shouldShowPastDueDeleteButton(props.task)) {
    return null
  }

  return (
    <IconButton
      variant='outlined'
      sentiment='error'
      neutralBase
      size='small'
      onClick={props.onClick}
      icon={TrashSolid}
      disabled={props.disabled}
      // @ts-expect-error - will change when we get new button
      className='hover:themevar-[b-icon=buttonMuted-error-icon-default]'
    />
  )
}
