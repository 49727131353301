import { isNoneId } from '@motion/shared/identifiers'
import { isCustomFieldKey } from '@motion/ui-logic'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import {
  isMeetingTask,
  isUnscheduledSchedulingTask,
} from '@motion/ui-logic/pm/task'

import { useCallback } from 'react'

import {
  type GroupedNode,
  TaskGroupableFields,
  type Tree,
} from '../../grouping'
import { isDisabledMeetingTaskField } from '../utils'

type CanDragItemToGroupResult = {
  canDrag: boolean
  errorMessage?: string
}

export const useCanDragItemToGroup = () => {
  /**
   * Check if an item can be dragged to a group
   * @param item The item being dragged
   * @param group The group being dragged to
   */
  return useCallback(
    <T extends GroupedNode>({
      item,
      group,
    }: {
      item: GroupedNode
      group: Tree<T> | undefined
    }): CanDragItemToGroupResult => {
      if (group == null)
        return {
          canDrag: false,
        }

      const groupType = group.item?.value?.type

      // Always allow dropping back to start
      if (isStartingGroup(item, group)) {
        return {
          canDrag: true,
        }
      }

      // Don't allow dropping into a group it already exists in
      if (isItemInGroup(item, group)) {
        return {
          canDrag: false,
        }
      }

      // Disable dragging for meeting tasks
      if (isMeetingTask(item.value.value)) {
        if (isCompleteGroup(group)) {
          return {
            canDrag: false,
            errorMessage:
              'The event will automatically be completed when it ends.',
          }
        }

        if (groupType != null && isDisabledMeetingTaskField(groupType)) {
          const groupName = TaskGroupableFields.find(
            (field) => field.id === groupType
          )?.label.toLowerCase()

          return {
            canDrag: false,
            errorMessage: `Updating the ${groupName ?? 'field'} of an event is not supported. Try updating via the Event modal.`,
          }
        }
      }

      // If it's a scheduling task, don't allow drag from/to resolved status if it's not yet scheduled
      if (isUnscheduledSchedulingTask(item?.value?.value)) {
        if (isCompleteGroup(group)) {
          return {
            canDrag: false,
            errorMessage:
              'A scheduling task can only be completed by scheduling an event.',
          }
        }
      }

      // If ghost task, keep in the same workspace and project as the group
      if (isGhostTask(item.value.value)) {
        if (!isGhostTaskInSameWorkspaceOrProject(item, group)) {
          return {
            canDrag: false,
            errorMessage:
              "Ghost tasks can't be moved to a different workspace or project.",
          }
        }
      }

      // If its a 'no workspace' group (like no Label, Unassigned) always allow a drag
      if (isNoWorkspaceGroup(group)) {
        return {
          canDrag: true,
        }
      }

      // If custom field, check that the item's workspaceId is in the group's workspaceIds
      if (
        isCustomFieldKey(item.parent?.value.type ?? '') &&
        !isCustomFieldWorkspaceIdValid(item, group)
      ) {
        return {
          canDrag: false,
          errorMessage: getDifferentWorkspaceErrorMessage(item, group),
        }
      }

      // Check if the group has no workspace IDs
      if (group.item?.workspaceIds == null)
        return {
          canDrag: true,
        }

      const isItemInGroupWorkspace = group.item.workspaceIds.includes(
        item.value.value.workspaceId
      )

      // Check if the group's workspace IDs include the item's workspace ID
      return {
        canDrag: isItemInGroupWorkspace,
        errorMessage: isItemInGroupWorkspace
          ? undefined
          : getDifferentWorkspaceErrorMessage(item, group),
      }
    },
    []
  )
}

const isStartingGroup = (item: GroupedNode, group: Tree<GroupedNode>) => {
  return item.parent?.qualifiedKey === group.qualifiedKey
}

const isItemInGroup = (item: GroupedNode, group: Tree<GroupedNode>) => {
  return group.values.some(({ key }) => key === item.key)
}

const isCustomFieldWorkspaceIdValid = (
  item: GroupedNode,
  group: Tree<GroupedNode>
) => {
  const itemWorkspaceIds = [
    ...new Set(group.values.map((item) => item.value.value.workspaceId)),
  ]

  if (itemWorkspaceIds.length > 0) {
    return itemWorkspaceIds.includes(item.value.value.workspaceId)
  }

  return true
}

const isGhostTaskInSameWorkspaceOrProject = (
  item: GroupedNode,
  group: Tree<GroupedNode>
) => {
  if (
    group.item?.value?.type === 'workspace' &&
    group.item?.value?.value?.workspaceId !== item.value.value.workspaceId
  ) {
    return false
  }

  if (
    group.item?.value?.type === 'project' &&
    group.item?.value?.value?.id !== item.value.value.projectId
  ) {
    return false
  }

  return true
}

const isNoWorkspaceGroup = (group: Tree<GroupedNode>) => {
  return isNoneId(group.key)
}

const isCompleteGroup = (group: Tree<GroupedNode>) => {
  return (
    group.item?.value.type === 'status' &&
    group.item?.value.value.isResolvedStatus === true
  )
}

const getDifferentWorkspaceErrorMessage = (
  item: GroupedNode,
  group: Tree<GroupedNode>
) => {
  const groupName = group.item?.value?.value?.name
  const itemType = item.value.type

  return groupName
    ? `This ${itemType} can't be moved here because ${groupName} is not in the same workspace.`
    : `This ${itemType} can't be moved here because the group is not in the same workspace.`
}
