import { type ReactNode } from 'react'

type EventFormRowIconContainerProps = {
  children?: ReactNode
}
export const EventFormRowIconContainer = ({
  children,
}: EventFormRowIconContainerProps) => {
  return (
    <div className='text-semantic-neutral-icon-subtle w-4 h-[26px] flex items-center justify-center self-start shrink-0 mr-1'>
      {children}
    </div>
  )
}
