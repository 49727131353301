import { useState } from 'react'

import { CreateTeam } from './components/CreateTeam/create-team'
import { TeamPageWithTabs } from './team-page-with-tabs'

import { useAppSelector } from '../../state/hooks'
import { selectPMTeamFetched } from '../../state/projectManagementSlice'

export const ConnectedTeamLandingPage = () => {
  const pmTeamFetched = useAppSelector(selectPMTeamFetched)
  const teamId = useAppSelector((state) => state.projectManagement.teamId)
  const [isInProgress, setIsInProgress] = useState(false)

  if (!pmTeamFetched && !isInProgress) {
    return null
  }

  if (teamId) {
    return <TeamPageWithTabs />
  }
  return <CreateTeam setIsInProgress={setIsInProgress} />
}
