import { CalendarSolid, PlusSolid } from '@motion/icons'
import { Button, IconButton } from '@motion/ui/base'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic'
import { type StageVariant } from '@motion/ui-logic/pm/project'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { useIsFlowsM4Enabled } from '~/areas/flows'
import { useUpdateProjectStageDueDate } from '~/areas/project/hooks'
import { DeadlineDropdown } from '~/areas/project-management/components'

import { GoToStageButton } from './go-to-stage-button'
import { type LookupArgs } from './lookup-header'
import { StageHeaderContextMenu } from './stage-header-context-menu'
import { EndContentContainer } from './styled'

import { useSidebarTaskContext } from '../context'

export function StageHeaderEndContent({
  toggle,
  projectStage,
  project,
  stageDefinition,
  stageVariant,
  isActive,
}: Pick<LookupArgs, 'toggle'> & {
  projectStage: StageSchema
  project: ProjectSchema
  stageDefinition: StageDefinitionSchema
  stageVariant: StageVariant
  isActive: boolean
}) {
  const isFlowsM4Enabled = useIsFlowsM4Enabled()

  const { enableInlineAdd } = useSidebarTaskContext()
  const updateStageDueDate = useUpdateProjectStageDueDate()

  const canSwitchToStage = !isActive
  const showGoToStageButton = !isFlowsM4Enabled && canSwitchToStage

  if (stageVariant === 'skipped') {
    return (
      <EndContentContainer>
        <em className='text-semantic-neutral-text-subtle text-2xs'>Canceled</em>
      </EndContentContainer>
    )
  }

  return (
    <EndContentContainer>
      <DeadlineDropdown
        hideTimeOptions
        disableClearing
        value={projectStage.dueDate}
        onChange={(dueDate) => {
          if (dueDate == null) return
          updateStageDueDate(project.id, stageDefinition.id, dueDate)
        }}
        isValidDateOption={() => true}
        contextProps={{
          projectId: project.id,
          stageDefinitionId: projectStage.id,
        }}
        dropdownTarget='stage'
      >
        <Button size='xsmall' variant='muted' sentiment='neutral'>
          <CalendarSolid />
          <span className='whitespace-nowrap'>
            {formatToReadableWeekDayMonth(projectStage.dueDate)}
          </span>
        </Button>
      </DeadlineDropdown>

      {showGoToStageButton && (
        <GoToStageButton project={project} stageDefinition={stageDefinition} />
      )}

      {enableInlineAdd && (
        <IconButton
          icon={PlusSolid}
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          onClick={() => toggle({ stageDefinitionId: stageDefinition.id })}
        />
      )}

      {isFlowsM4Enabled && (
        <StageHeaderContextMenu
          project={project}
          projectStage={projectStage}
          stageDefinition={stageDefinition}
        />
      )}
    </EndContentContainer>
  )
}
