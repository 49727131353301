import { twMerge } from 'tailwind-merge'

export interface SettingListProps {
  items: React.ReactNode[]
  className?: string
}

export const SettingList = ({
  items = [],
  className = '',
}: SettingListProps) => {
  if (items.length > 0) {
    return (
      <div
        className={twMerge(
          'dark:bg-dark-1100 bg-light-100 border-light-500 dark:border-dark-1000 divide-light-500 dark:divide-dark-1000 w-full divide-y border',
          className
        )}
      >
        {items.map((item) => {
          return item
        })}
      </div>
    )
  }

  return null
}
