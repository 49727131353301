import { XSolid } from '@motion/icons'
import {
  Button,
  IconButton,
  LoadingSpinner,
  showToast,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalTitle } from '@motion/web-common/html'

import {
  ModalErrorState,
  PreviousNextModalButtons,
  type PreviousNextModalButtonsProps,
  ProjectPanel,
  SharedFormShell,
  ShellActions,
  ShellVars,
  SidebarContent,
  SidebarSection,
  SidebarWorkspaceSection,
} from '~/areas/task-project/components'
import { AutoSaveProvider } from '~/areas/task-project/contexts'
import { TutorialButtonLink } from '~/global/components'
import { useModalListOrder } from '~/global/contexts'
import { useSubcribeToFeed } from '~/global/hooks/websockets'
import {
  useProjectModalUrl,
  useRouteConfirmationPromptBeforeLeaving,
} from '~/global/navigation'
import { type FormEvent, memo } from 'react'

import {
  GridShell,
  MainHeader,
  MainScrollableContent,
  ProjectActivityCollapsable,
  ProjectAttachmentsCollapsable,
  ProjectDebugInfo,
  ShellMainFooter,
  SidebarDetails,
  SidebarDetailsFooter,
  SidebarList,
  SidebarListFooter,
} from './components'
import { CustomFieldsProjectSidebarSection } from './components/custom-fields-sidebar-section'
import { ProjectModalStateProvider, useProjectModalState } from './contexts'
import {
  ControlledAssigneeField,
  ControlledDeadlineField,
  ControlledDescriptionField,
  ControlledLabelsField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledStartDateField,
  ControlledStatusField,
  ControlledWorkspaceField,
  ControlledWorkspaceFolderField,
  ProjectHeader,
} from './fields'
import { useCloseProjectModal, useProjectForm, useSubmitForm } from './hooks'
import { ProjectForm } from './project-form'

import { ProjectPrevNextTooltipContent } from '../../components/project-prev-next-tooltip-content'

type ConnectedProjectModalProps = {
  open: boolean
}

export function ConnectedProjectModal({ open }: ConnectedProjectModalProps) {
  const closeModal = useCloseProjectModal()

  return (
    <UnstyledModal
      data-testid='project-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <AutoSaveProvider suppressSaveToast>
        <ProjectForm>
          <ProjectModalStateProvider>
            <ProjectModalContent close={closeModal} />
          </ProjectModalStateProvider>
        </ProjectForm>
      </AutoSaveProvider>
    </UnstyledModal>
  )
}

type ProjectModalContentProps = {
  close: () => void
}
const ProjectModalContent = memo(function ProjectModalContent({
  close,
}: ProjectModalContentProps) {
  const { form } = useProjectForm()
  const { hasPendingComment } = useProjectModalState()
  const submitForm = useSubmitForm()

  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const projectId = watch('id')
  const projectName = watch('name')
  const isLoading = watch('isLoading')
  const hasError = watch('hasError')
  const workspaceId = watch('workspaceId')
  const projectDefinitionId = watch('projectDefinitionId')

  const hasDirtyFormFields = isDirty && !isSubmitting
  const hasFormFooterButtons = projectId == null

  useSubcribeToFeed('project', projectId)
  useModalTitle(projectId ? projectName : 'New project')
  useRouteConfirmationPromptBeforeLeaving({
    when: (hasDirtyFormFields && hasFormFooterButtons) || hasPendingComment,
    message:
      hasPendingComment && !hasDirtyFormFields
        ? 'Your comment has not been saved'
        : undefined,
  })

  const projectType = projectDefinitionId ? 'flow' : 'regular'
  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_PROJECT', {
    enabled: projectId != null,
    properties: {
      project_type: projectType,
    },
  })
  useShortcut('mod+s', () => onSubmit(), {
    enabled: hasFormFooterButtons,
  })

  const showAttachmentsSection = useHasTreatment('webapp-attachments')
  const hasWorkspaceFolders = useHasTreatment('workspace-folders')

  const {
    projects: { getPrevId: getPrevProjectId, getNextId: getNextProjectId },
  } = useModalListOrder()

  const buildProjectModalUrl = useProjectModalUrl()

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string' || !message) return

      showToast('error', message)
    })(e)
  }

  const SidebarActionButtonsFooter =
    projectId != null ? SidebarListFooter : SidebarDetailsFooter

  const renderPrevNextButtonTooltip: PreviousNextModalButtonsProps['renderTooltip'] =
    (id, shortcut, isNext) => (
      <ProjectPrevNextTooltipContent
        id={id}
        shortcut={shortcut}
        isNext={isNext}
      />
    )

  const numColumns = projectId == null ? 2 : 3

  return (
    <ShellVars columns={numColumns}>
      <SharedFormShell columns={numColumns} onSubmit={onSubmit}>
        <ShellActions>
          <IconButton
            icon={XSolid}
            sentiment='onDark'
            size='small'
            variant='muted'
            onClick={close}
          />
          {!isLoading && !hasError && projectId && (
            <PreviousNextModalButtons
              currentId={projectId}
              getPrevId={getPrevProjectId}
              getNextId={getNextProjectId}
              buildUrl={(id: string) => buildProjectModalUrl({ project: id })}
              renderTooltip={renderPrevNextButtonTooltip}
            />
          )}
        </ShellActions>
        <GridShell withFooter={hasFormFooterButtons}>
          {hasError ? (
            <ModalErrorState close={close} className='col-span-2 row-span-4'>
              This project doesn&apos;t exist
            </ModalErrorState>
          ) : isLoading ? (
            <div className='col-span-2 row-span-4 w-full h-full grid place-items-center'>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <MainHeader>
                <ProjectHeader />
                <ControlledNameField />
              </MainHeader>

              <SidebarDetails>
                <SidebarContent>
                  <SidebarWorkspaceSection>
                    {hasWorkspaceFolders ? (
                      <ControlledWorkspaceFolderField />
                    ) : (
                      <ControlledWorkspaceField />
                    )}
                  </SidebarWorkspaceSection>

                  <SidebarSection className='pt-3 modal-lg:pt-5'>
                    <ControlledAssigneeField />
                    <ControlledStatusField />
                    <ControlledStartDateField />
                    <ControlledDeadlineField />
                    <ControlledPriorityField />
                  </SidebarSection>

                  <SidebarSection>
                    <ControlledLabelsField />

                    <CustomFieldsProjectSidebarSection
                      workspaceId={workspaceId}
                      projectId={projectId}
                    />
                  </SidebarSection>

                  {!__IS_PROD__ && <ProjectDebugInfo />}
                </SidebarContent>
              </SidebarDetails>

              <MainScrollableContent>
                <ControlledDescriptionField />
                {projectId != null && showAttachmentsSection && (
                  <ProjectAttachmentsCollapsable projectId={projectId} />
                )}
                {projectId != null && (
                  <ProjectActivityCollapsable projectId={projectId} />
                )}
              </MainScrollableContent>

              {projectId != null && (
                <SidebarList>
                  <ProjectPanel
                    projectId={projectId}
                    workspaceId={workspaceId}
                    enableInlineAdd={projectId != null}
                    isProjectModal
                  />
                </SidebarList>
              )}

              <ShellMainFooter>
                <TutorialButtonLink
                  lesson='managing-tasks'
                  rounded
                  onClick={() => {
                    recordAnalyticsEvent('PROJECT_MANAGEMENT_TUTORIAL_CLICK', {
                      target: 'v3-tutorial',
                      source: 'project-modal',
                    })
                  }}
                />
              </ShellMainFooter>

              {hasFormFooterButtons && (
                <SidebarActionButtonsFooter>
                  <Button
                    sentiment='neutral'
                    variant='muted'
                    shortcut='esc'
                    onClick={close}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    sentiment='primary'
                    variant='solid'
                    shortcut='mod+s'
                    disabled={!isDirty || projectName.trim().length < 1}
                    loading={isSubmitting}
                  >
                    Save project
                  </Button>
                </SidebarActionButtonsFooter>
              )}
            </>
          )}
        </GridShell>
      </SharedFormShell>
    </ShellVars>
  )
})
