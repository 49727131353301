import { getTaskStatusChangedFields } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

import { useResolveTask } from '../actions/use-resolve-task'

export function useTaskStatusUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const {
    getWorkspaceStatuses,
    getWorkspaceCompletedStatus,
    getWorkspaceCanceledStatus,
  } = useWorkspaceFns()
  const { completeTask, cancelTask } = useResolveTask({
    supressRecalcToast: true,
  })

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      statusId: TaskSchema['statusId']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the status of a Chunk task')
      }

      let updates = {
        statusId,
        ...getTaskStatusChangedFields(
          { ...task, statusId },
          { statuses: getWorkspaceStatuses(task.workspaceId) }
        ),
      }

      const isCompletedStatus =
        statusId === getWorkspaceCompletedStatus(task.workspaceId).id

      if (isCompletedStatus) {
        return completeTask(task.id)
      }

      const isCanceledStatus =
        statusId === getWorkspaceCanceledStatus(task.workspaceId).id

      if (isCanceledStatus) {
        return cancelTask(task.id)
      }

      await updateTask(task, updates)
    },
    [
      cancelTask,
      completeTask,
      getWorkspaceCanceledStatus,
      getWorkspaceCompletedStatus,
      getWorkspaceStatuses,
      readTask,
      updateTask,
    ]
  )
}
