import * as v from 'valibot'

import { type SortField } from '../../fields'

const SortBySchema = v.object({
  field: v.special<SortField>((val) => typeof val === 'string'),
  direction: v.picklist(['asc', 'desc']),
})

const ColumnSchema = v.object({
  id: v.string(),
  width: v.optional(v.number()),
  visible: v.optional(v.boolean(), true),
  pinned: v.optional(v.boolean(), false),
})

const CardFieldSchema = v.object({
  id: v.string(),
  visible: v.optional(v.boolean()),
})

const GroupByField = v.object({
  key: v.string(),
  by: v.optional(
    v.picklist(['day', 'week', 'month', 'quarter', 'half', 'year'])
  ),
})

const GroupByOptionsSchema = v.object({
  fields: v.array(GroupByField),
  order: v.record(v.array(v.string())),
  hideEmpty: v.boolean(),
  stackProjects: v.optional(v.boolean(), true),
  dateRange: v.optional(v.picklist(['month', 'quarter', 'year']), 'quarter'),
})

export const ViewStateSchema = v.object({
  $version: v.literal(6),
  groupBy: GroupByOptionsSchema,

  search: v.string(),
  sortBy: v.nullable(SortBySchema),
  page: v.coerce(v.picklist(['list', 'kanban', 'gantt']), (data) =>
    data === 'planner' ? 'gantt' : data
  ),
  columns: v.array(ColumnSchema),
  cardFields: v.optional(v.array(CardFieldSchema), []),
  providerIds: v.optional(v.array(v.string()), []),
  showOOOEventsByAssignee: v.optional(v.boolean(), false),
  viewId: v.optional(v.nullable(v.string()), null),
})
