import { type PMCommentType } from '@motion/rpc/types'
import { Button } from '@motion/ui/base'
import { RichTextContent, RichTextImageBubbleMenu } from '@motion/ui/rte'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'

import { UserBadge } from '~/global/components/badges'
import { useRef } from 'react'

import { useCommentBoxRte } from '../../hooks'

interface CommentBoxProps {
  author: WorkspaceMemberWithUser
  editorText?: string
  onSubmit: (comment: Partial<PMCommentType>) => void
  onCancel?: () => void
}

export const CommentBox = ({
  author,
  onSubmit,
  onCancel,
  editorText = '',
}: CommentBoxProps) => {
  const commentBoxRef = useRef<HTMLDivElement>(null)

  const { editor, handleSubmit } = useCommentBoxRte({
    commentBoxRef,
    onSubmit,
    initialText: editorText,
  })

  return (
    <div className='flex gap-4'>
      <div className='shrink-0'>
        <UserBadge size='small' value={author.user} />
      </div>
      <div className='flex flex-col gap-2 min-w-0 grow' ref={commentBoxRef}>
        <RichTextImageBubbleMenu editor={editor} />
        <RichTextContent
          editor={editor}
          className='px-2.5 py-2 bg-field-bg-default border-field-border-default min-h-[65px] rounded border text-sm'
        />
        <div className='flex justify-end gap-2'>
          {onCancel && (
            <Button sentiment='neutral' onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button
            onClick={() => editor && handleSubmit(editor)}
            shortcut='mod+enter'
            disabled={!editor || editor.isEmpty}
            variant='outlined'
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  )
}
