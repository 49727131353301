import { ChevronRightSolid, XSolid } from '@motion/icons'
import { IconButton } from '@motion/ui/base'
import { CalendarPalette } from '@motion/ui/calendar'

import { PeekModalHeader } from '../common'
import { useClosePeekModal } from '../hooks'

export const TaskFormHeader = () => {
  const closePeekModal = useClosePeekModal()

  return (
    <CalendarPalette colorId='0'>
      <PeekModalHeader>
        <div className='grow text-calendar-palette-text-default pl-2'>
          <span className='flex items-center'>
            My Tasks
            <ChevronRightSolid className='h-3.5 text-semantic-neutral-icon-subtle' />
            No Project
          </span>
        </div>
        <div className='shrink-0 flex items-center gap-px'>
          <IconButton
            icon={XSolid}
            onClick={closePeekModal}
            sentiment='neutral'
            variant='muted'
            size='xsmall'
          />
        </div>
      </PeekModalHeader>
    </CalendarPalette>
  )
}
