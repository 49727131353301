import { ScheduleSolid } from '@motion/icons'
import { API, useQuery } from '@motion/rpc'
import { PopoverTrigger } from '@motion/ui/base'
import { CUSTOM_SCHEDULE_ID } from '@motion/ui-logic/pm/task'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import {
  ScheduleDropdownContent,
  type ScheduleDropdownContentProps,
} from '~/global/components/dropdowns'
import { useController } from 'react-hook-form'

import {
  CustomSchedulePanel,
  type CustomSchedulePanelProps,
} from '../components'
import { useTaskForm } from '../hooks'

export const ScheduleField = () => {
  const { form, disabledFields } = useTaskForm()
  const { data: firestoreSettings, refetch } = useQuery(
    API.userSettings.getFirestoreSettings,
    undefined
  )

  const fsSchedules = firestoreSettings?.schedules ?? {}

  const { watch } = form

  const taskType = watch('type')
  const selectedScheduleId = watch('scheduleId')

  const isRecurring = taskType === 'RECURRING_TASK'
  const isRecurringTaskInstance = taskType === 'RECURRING_INSTANCE'
  const isRecurringTaskOrInstance = isRecurring || isRecurringTaskInstance

  const schedules = isRecurringTaskOrInstance
    ? { ...fsSchedules, [CUSTOM_SCHEDULE_ID]: { title: 'Custom' } }
    : fsSchedules
  const selectedSchedule =
    selectedScheduleId != null
      ? schedules[selectedScheduleId as keyof typeof schedules]
      : null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ScheduleFieldContent
          close={close}
          onUpdate={refetch}
          isRecurringTaskOrInstance={isRecurringTaskOrInstance}
        />
      )}
    >
      <ModalFieldButton
        label='Schedule'
        disabled={disabledFields.has('scheduleId')}
      >
        <ScheduleSolid />
        {selectedSchedule?.title}
        <span className='whitespace-nowrap'>
          {selectedScheduleId === CUSTOM_SCHEDULE_ID &&
            isRecurringTaskOrInstance && (
              <>
                ({watch('timeStart')} - {watch('timeEnd')})
              </>
            )}
        </span>
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

type ScheduleFieldContentProps = {
  close: () => void
  onUpdate: () => void
  isRecurringTaskOrInstance: boolean
}

function ScheduleFieldContent({
  close,
  onUpdate,
  isRecurringTaskOrInstance,
}: ScheduleFieldContentProps) {
  const { form } = useTaskForm()

  const { watch } = form

  const {
    scheduleIdController: { field },
    startTimeController: { field: startTimeField },
    endTimeController: { field: endTimeField },
    idealTimeController: { field: idealTimeField },
  } = useScheduleController()
  const updateTask = useTaskUpdater()

  const taskId = watch('id')
  const selectedScheduleId = watch('scheduleId')

  const scheduleStartTime = startTimeField.value
  const scheduleEndTime = endTimeField.value
  const scheduleIdealTime = idealTimeField.value

  const onChange: ScheduleDropdownContentProps['onChange'] = (scheduleId) => {
    if (taskId != null) {
      return updateTask(taskId, {
        scheduleId,
      })
    }

    field.onChange(scheduleId)
  }

  const timeOnChange: CustomSchedulePanelProps['onChange'] = (values) => {
    if (taskId != null) {
      return updateTask(taskId, {
        timeStart: values.start,
        timeEnd: values.end,
        idealTime: values.ideal,
      })
    }

    startTimeField.onChange(values.start)
    endTimeField.onChange(values.end)
    idealTimeField.onChange(values.ideal)
  }

  return (
    <ScheduleDropdownContent
      close={close}
      selectedScheduleId={selectedScheduleId ?? ''}
      showCustomSchedule={isRecurringTaskOrInstance}
      onChange={onChange}
      onUpdate={onUpdate}
      renderCustomSchedulePanel={() => (
        <div className='border-l border-dropdown-border p-3'>
          <CustomSchedulePanel
            startTime={scheduleStartTime}
            endTime={scheduleEndTime}
            idealTime={scheduleIdealTime}
            onChange={timeOnChange}
          />
        </div>
      )}
    />
  )
}

function useScheduleController() {
  const {
    form: { control },
  } = useTaskForm()

  return {
    scheduleIdController: useController({
      name: 'scheduleId',
      control,
      rules: {
        required: true,
      },
    }),
    startTimeController: useController({
      name: 'timeStart',
      control,
    }),
    endTimeController: useController({
      name: 'timeEnd',
      control,
    }),
    idealTimeController: useController({
      name: 'idealTime',
      control,
    }),
  }
}
